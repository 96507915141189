import { Nav } from "react-bootstrap";
import styled from "styled-components";

const TabColor = "white";

const StyledNavBar = styled(Nav)`
  justify-content: start;
  background: transparent;
  border-bottom: 1px solid #00000020;

  .nav-item {
    .nav-link.active {
      color: $navbar-dark-active-color;
      border-bottom: 4px solid ${TabColor};
    }
  }

  // When we collapse to need scrolling
  @media (max-width: 48rem) {
    position: relative;
    overflow: hidden;
    background: #00000040;

    // Show a shadow to hint at scrolling
    ::after {
      pointer-events: none;
      content: "";
      position: absolute;
      right: 0rem;
      top: 0;
      bottom: 0;
      width: 1rem;

      background: linear-gradient(90deg, #00000000, #00000020);
    }
  }
`;

const InternalScrollBar = styled.div`
  display: flex;
  flex: row;
  flex-wrap: nowrap;
  justify-content: start;
  overflow-x: scroll;

  padding: 0 1rem;

  // Allow horizontal scroll but hide scrollbar
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const NavBar = ({ sport, era, setValues }) => {
  let activeKey = era !== "" ? era : sport;

  const keyLabels = [
    { key: "", label: "All Sports" },
    { key: "baseball", label: "Baseball" },
    { key: "basketball", label: "Basketball" },
    { key: "football", label: "Football" },
    { key: "soccer", label: "Soccer" },
    { key: "other", label: "Other Sports" },
  ];

  return (
    <StyledNavBar
      activeKey={activeKey}
      className="secondary-nav align-items-stretch justify-content-stretch"
    >
      <InternalScrollBar>
        {keyLabels.map(({ key, label }) => (
          <Nav.Item key={key}>
            <Nav.Link
              eventKey={key}
              onSelect={(eventKey) => setValues(eventKey, "")}
              className="font-weight-bold text-nowrap"
            >
              {label}
            </Nav.Link>
          </Nav.Item>
        ))}

        {/* Hack to workaround last item margin being stripped from overflow */}
        <div>&nbsp;</div>
      </InternalScrollBar>
    </StyledNavBar>
  );
};

export default NavBar;
