import _ from "lodash";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Modal } from "react-bootstrap";
import { number } from "yup";

import { EXPIRES_AFTER_OPTIONS } from "../../utils/forms";

import {
  CustomCurrencyFormat,
  CustomNumberFormat,
} from "../../utils/formatters";
import { financial } from "../../utils/common";
import {
  displayNameForProductType,
  pluralize,
  productQuantityString,
} from "../../utils/content";
import { getValidAffiliateCode } from "../../utils/affiliateTracking";

const BuyModalFooter = ({
  setShow,
  isSubmitting,
  setSubmitting,
  buyItems,
  gradeId,
  cardParallelId,
  cardParallelProductType,
  quantity,
  setQuantity,
  maxBidPerCard,
  setMaxBidPerCard,
  expiresAfter,
  setExpiresAfter,
  sellOrdersToBuy,
  bidsToCreateCount,
  saleTotal,
  bidTotal,
  total,
}) => {
  const purchasesAllowed = () => {
    if (isSubmitting) return false;
    if (total === 0) return false;
    return !(bidsToCreateCount > 0 && bidTotal === 0);
  };

  const quantitySchema = number().required().integer().min(0).max(1000000);
  const maxBidPerCardSchema = number().positive().min(0.1).max(1000000);

  const castAndSetQuantity = (value) => {
    quantitySchema.isValid(value).then((valid) => {
      if (valid) {
        setQuantity(quantitySchema.cast(value));
      }
    });
  };

  const castAndSetMaxBidPerCard = (value) => {
    if (value === "") {
      setMaxBidPerCard(value);
    }

    maxBidPerCardSchema.isValid(value).then((valid) => {
      if (valid) {
        setMaxBidPerCard(maxBidPerCardSchema.cast(value));
      }
    });
  };

  const buy = () => {
    setSubmitting(true);

    // Figure out maximumBuyPrice
    let maximumBuyPrice = maxBidPerCard;
    if (maximumBuyPrice === "") {
      if (sellOrdersToBuy.length === 0) {
        setSubmitting(false);
        return;
      }
      maximumBuyPrice = _.maxBy(sellOrdersToBuy, "askPrice").askPrice;
    }

    const affiliateCode = getValidAffiliateCode();

    buyItems({
      variables: {
        cardParallelId: cardParallelId,
        gradeId: gradeId,
        maximumBuyPrice: maximumBuyPrice,
        quantity: quantity,
        affiliateCode: affiliateCode,
        expiresAfter: expiresAfter,
      },
    });
  };

  return (
    <Modal.Footer>
      <div className="container-fluid px-0">
        <FormControl fullWidth>
          <TextField
            id="quantity"
            name="quantity"
            label="Quantity to buy"
            value={quantity}
            onChange={(event) => castAndSetQuantity(event.target.value)}
            variant="outlined"
            margin="dense"
            className="mb-3"
            InputProps={{
              inputComponent: CustomNumberFormat,
            }}
          />
          <TextField
            id="expires-after"
            select
            label="Offer expires after"
            variant="outlined"
            margin="dense"
            className="mb-3"
            value={expiresAfter}
            onChange={(event) => setExpiresAfter(event.target.value)}
          >
            {EXPIRES_AFTER_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="max-bid"
            label={`Max offer per ${displayNameForProductType(
              cardParallelProductType,
            )}`}
            value={maxBidPerCard}
            onChange={(event) => castAndSetMaxBidPerCard(event.target.value)}
            helperText="Minimum amount is $0.10"
            variant="outlined"
            margin="dense"
            className="mb-3"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="text-speak">
                  $
                </InputAdornment>
              ),
              inputComponent: CustomCurrencyFormat,
            }}
          />
        </FormControl>

        <div className="mb-3">
          <div className="row mb-2">
            <div className="col-6 font-weight-bold text-left">
              Instant purchases
            </div>
            <div className="col-3 font-weight-bold text-center">
              {productQuantityString(
                sellOrdersToBuy.length,
                cardParallelProductType,
              )}
            </div>
            <div className="col-3 font-weight-bold text-right">
              ${financial(saleTotal)}
            </div>
          </div>
          <div className="row">
            <div className="col-6 text-left">Placed as bids</div>
            <div className="col-3 text-center">
              {pluralize("offer", bidsToCreateCount)}
            </div>
            <div className="col-3 text-right">${financial(bidTotal)}</div>
          </div>
        </div>

        <hr />

        <div className="d-flex flex-row justify-content-between font-weight-bold text-shout mb-3">
          <div className="text-uppercase mr-3">Total Cost</div>
          <div className="text-right">${financial(total)}</div>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-center">
          <button
            className="btn btn-secondary btn-block text-body"
            disabled={isSubmitting}
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-success btn-block font-weight-bold mt-0 ml-3"
            disabled={!purchasesAllowed()}
            onClick={() => buy()}
          >
            Submit order
          </button>
        </div>
      </div>
    </Modal.Footer>
  );
};
export default BuyModalFooter;
