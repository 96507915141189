import { rgba } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";

import playerPlaceholder from "../images/player-outline.png";
import { financial } from "../utils/common";
import { abbrTeamName } from "../utils/content";
import LazyLoadImage from "./LazyLoadImage";

const Danger = "#EB7373";
const Mantis = "#58B55C";

export const StyledPlayerInfoList = styled.ol`
  padding: 0;
  margin: 0;
  ::last-child {
    margin-bottom: 0;
  }

  li {
    list-style-type: none;
    margin-bottom: 1rem;
  }
  li:last-child {
    margin-bottom: 0;
  }
`;

const StyledPlayerInfoListItem = styled.li``;

const StyledContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const StyledHeadshotContainer = styled.a`
  flex-grow: 0;
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.colorPrimary};
  background: linear-gradient(
    to bottom right,
    #${(props) => props.colorPrimary} 50%,
    #${(props) => props.colorSecondary} 50% 100%
  );
  overflow: hidden;

  @media (min-width: 28rem) {
    margin-right: 1rem;
  }
`;

const StyledComponentPlayerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
`;

const StyledPlayerNameLabel = styled.p`
  color: white;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledPlayerInfoLabel = styled.p`
  color: white;
  font-size: 0.75rem;
  font-weight: normal;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledComponentValueInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
`;

const StyledPriceChangeLabelVariant = {
  neutral: "neutral",
  positive: "positive",
  negative: "negative",
};

const StyledPriceChangeLabel = styled.p`
  color: ${(props) =>
    props.variant === StyledPriceChangeLabelVariant.positive
      ? rgba(Mantis, 1.0)
      : props.variant === StyledPriceChangeLabelVariant.negative
      ? rgba(Danger, 1.0)
      : rgba("white", 1.0)};

  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
`;

const StyledPriceLabel = styled.p`
  color: ${rgba("white", 0.8)};
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0;
`;

export const PlayerInfoListItem = ({ children }) => {
  return (
    <StyledPlayerInfoListItem>
      <StyledContainer>{children}</StyledContainer>
    </StyledPlayerInfoListItem>
  );
};

export const PlayerHeadshot = ({ player, team, linkUrl, onClick }) => {
  return (
    <StyledHeadshotContainer
      href={linkUrl}
      colorPrimary={team ? team.colorPrimary : "lightgrey"}
      colorSecondary={team ? team.colorSecondary : "lightgrey"}
      onClick={onClick}
    >
      <LazyLoadImage
        src={player.headshotPhoto || playerPlaceholder}
        placeholder={playerPlaceholder}
        alt=""
      />
    </StyledHeadshotContainer>
  );
};

export const PlayerInfo = ({ player, linkUrl, onClick }) => {
  return (
    <StyledComponentPlayerInfoContainer>
      <StyledPlayerNameLabel>
        <Link
          className="text-white font-weight-bold"
          to={linkUrl}
          onClick={onClick}
        >
          {player.name}
        </Link>
      </StyledPlayerNameLabel>

      <StyledPlayerInfoLabel>
        <span className="font-weight-bold" style={{ opacity: 0.6 }}>
          {abbrTeamName(player.currentTeam)}
        </span>
        <span className="px-1" style={{ opacity: 0.25 }}>
          /
        </span>
        <span style={{ opacity: 0.4 }}>{player.position}</span>
        <span className="px-1" style={{ opacity: 0.25 }}>
          /
        </span>
        <span style={{ opacity: 0.4 }}>{player.startYear}</span>
      </StyledPlayerInfoLabel>
    </StyledComponentPlayerInfoContainer>
  );
};

export const PlayerIndexValues = ({ componentSnapshot }) => {
  const priceChangeLabelVariant =
    componentSnapshot.priceChangePercentage > 0
      ? StyledPriceChangeLabelVariant.positive
      : componentSnapshot.priceChangePercentage < 0
      ? StyledPriceChangeLabelVariant.negative
      : StyledPriceChangeLabelVariant.neutral;

  return (
    <StyledComponentValueInfoContainer>
      <StyledPriceChangeLabel variant={priceChangeLabelVariant}>
        {componentSnapshot.priceChangePercentage > 0 ? "+" : null}
        {(componentSnapshot.priceChangePercentage * 100).toFixed(1)}%
      </StyledPriceChangeLabel>
      <StyledPriceLabel>${financial(componentSnapshot.price)}</StyledPriceLabel>
    </StyledComponentValueInfoContainer>
  );
};
