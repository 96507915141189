import ErrorNotice from "../../ErrorNotice";

const StepInvalid = () => {
  return (
    <ErrorNotice
      error={
        "There was a problem submitting this shipping request. Please close and try again."
      }
    />
  );
};

export default StepInvalid;
