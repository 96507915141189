import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const PricePerSpotLabel = styled.p`
  color: ${Colors.Astronaut};
  font-size: 0.875rem;
  margin-bottom: 0;
  opacity: 0.8;

  @media (min-width: 36rem) {
    font-size: 1rem;
  }
`;

export const PriceLabel = styled.p`
  color: ${Colors.Havelock};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;

  @media (min-width: 36rem) {
    font-size: 1.25rem;
  }
`;

export const PriceSeparator = styled.span`
  color: ${Colors.Astronaut};
  opacity: 0.6;
`;
