import { BOX_BREAK_STATE_GROUPS } from "../../../../api/types";

import { BreaksWidget } from "../BreaksWidget";

interface Props {
  era?: string;
  sport?: string;
}

export const UpcomingBreaksWidget = ({ era, sport }: Props) => (
  <BreaksWidget
    id="upcoming-breaks-widget"
    options={{
      variables: {
        era,
        orderBy: "scheduled_start_time_asc,id",
        sport,
        stateGroup: [BOX_BREAK_STATE_GROUPS.UPCOMING],
      },
    }}
    subtitle="Explore our upcoming schedule of NFT reMints and breaks"
    themeColor="#9948C3"
    title="⏱ Join an event"
    widgetName="UpcomingBreaksList"
  />
);
