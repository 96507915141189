import { useApolloClient } from "@apollo/client";

// import GET_CARD_PARALLEL_COUNTS from "../../api/queries/GET_CARD_PARALLEL_COUNTS";

import ProductTypeFilter from "../Filters/ProductTypeFilter";
import AvailabilityFilter from "../Filters/AvailabilityFilter";
import GraderFilter from "../Filters/GraderFilter";
import SerialLimitFilter from "../Filters/SerialLimitFilter";
import YearFilter from "../Filters/YearFilter";
// import CardSetFilter from "../Filters/CardSetFilter";

const Filters = ({
  // sport,
  productType,
  setProductType,
  minListings,
  setMinListings,
  minOffers,
  setMinOffers,
  grader,
  setGrader,
  minSerialLimit,
  setMinSerialLimit,
  maxSerialLimit,
  setMaxSerialLimit,
  yearOption,
  setYearOption,
  setStartYear,
  setEndYear,
  // cardSet,
  // setCardSet
}) => {
  // Temporary workaround for our caching issues, caused by non-argument-based
  // object fields being able to return distinct values determined by arguments
  // on the parent query. We need to address that, but for the time being we can
  // work around it by deleting all the relevant objects from the cache every
  // time we change the argument that make the field dynamic.
  const client = useApolloClient();
  const resetCachedCardParallels = () => {
    Object.keys(client.cache.data.data).forEach((key) => {
      if (key.match(/^CardParallelNode:/)) {
        client.cache.data.delete(key);
      }
    });
  };

  return (
    <>
      <ProductTypeFilter
        selectedValue={productType}
        setSelectedValue={(productType) => {
          resetCachedCardParallels();
          setProductType(productType, { method: "push" });
        }}
      />

      <AvailabilityFilter
        minListings={minListings}
        setMinListings={setMinListings}
        minOffers={minOffers}
        setMinOffers={setMinOffers}
      />

      {grader !== undefined && setGrader !== undefined ? (
        <GraderFilter
          selectedGrader={grader}
          setSelectedGrader={(grader) => {
            resetCachedCardParallels();
            setGrader(grader, { method: "push" });
          }}
        />
      ) : null}

      <SerialLimitFilter
        serialLimitRange={[minSerialLimit, maxSerialLimit]}
        setSerialLimitRange={(serialLimitRange) => {
          setMinSerialLimit(serialLimitRange[0]);
          setMaxSerialLimit(serialLimitRange[1], { method: "push" });
        }}
      />

      <YearFilter
        yearOption={yearOption}
        setYearOption={(year) => {
          setYearOption(year, { method: "push" });
        }}
        setStartYear={setStartYear}
        setEndYear={setEndYear}
      />

      {/*<CardSetFilter*/}
      {/*  cardSet={cardSet}*/}
      {/*  setCardSet={cardSet => {*/}
      {/*    setCardSet(cardSet, { method: "push" });*/}
      {/*  }}*/}
      {/*  sport={sport}*/}
      {/*  query={GET_CARD_PARALLEL_COUNTS}*/}
      {/*  filterAttribute={"cardParallelCounts"}*/}
      {/*/>*/}
    </>
  );
};

export default Filters;
