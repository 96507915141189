import { gql } from "@apollo/client";

const WITHDRAWAL_CONFIRMATION = gql`
  mutation withdrawalConfirmation(
    $userIdB64: String!
    $withdrawalRequestId: String!
    $token: String!
  ) {
    withdrawalConfirmation(
      userIdB64: $userIdB64
      withdrawalRequestId: $withdrawalRequestId
      token: $token
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export default WITHDRAWAL_CONFIRMATION;
