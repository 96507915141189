import { PageSection } from "../../PageSection";
import PlayerListing from "../../PlayerListing";

const BreakDetailTargetPlayersSection = ({ targetPlayers }) => {
  const players = targetPlayers.edges.map(({ node: player }) => player);

  return players && players.length > 0 ? (
    <PageSection addMarginBottom>
      <div className="mb-4">
        <h4 className="font-weight-bold mb-2">Our top targets</h4>
        <p className="mb-0">
          Every team has potential hits, but these are some of the players we
          would be most excited to pull from these packs!
        </p>
      </div>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 mt-3 mx-n2">
        {players.map((player) => (
          <div key={player.id} className="col px-2 mb-3 mb-sm-3 h-100">
            <PlayerListing player={player} />
          </div>
        ))}
      </div>
    </PageSection>
  ) : null;
};

export default BreakDetailTargetPlayersSection;
