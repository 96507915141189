import { gql } from "@apollo/client";

const SEND_USER_OWNERSHIPS_CSV = gql`
  mutation sendUserOwnershipsCsv {
    sendUserOwnershipsCsv {
      ok
    }
  }
`;

export default SEND_USER_OWNERSHIPS_CSV;
