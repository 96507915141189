import Skeleton from "@material-ui/lab/Skeleton";
import { isBrowser } from "react-device-detect";
import styled, { css } from "styled-components";
import { rgba } from "polished";

import { pluralize } from "../utils/content";
import { displayNameForGrade } from "../utils/grades";

const Astronaut = "#2c4d76";
const Havelock = "#4e8ad4";
const Mantis = "#58B55C";

const GradeTab = styled.button`
  cursor: pointer;
  flex-shrink: 0;
  transition: all 200ms ease-in-out;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.75rem;
  font-weight: bold;
  border: 0;
  padding: 0 0 0.25rem 0;
  margin-right: 0.75rem;
  color: ${rgba(Astronaut, 0.4)};
  background: transparent;
  z-index: 1;
  border-bottom: 2px solid ${rgba(Mantis, 0)};

  ${(props) =>
    props.selected &&
    css`
      color: ${rgba(Astronaut, 1)};
      border-bottom: 2px solid ${rgba(Mantis, 1)};
    `};

  &:focus,
  &:hover {
    outline: 0;
  }
`;

const HiddenGradesFade = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  background-color: white;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 1rem,
    rgba(255, 255, 255, 1) 100%
  );
  padding-left: 1.25rem;
  // padding-top: 0.125rem;
  z-index: 2;
  font-size: 0.75rem;
  color: ${Havelock};
`;

export const CardParallelGradeTabContainerSkeleton = ({ children }) => {
  return (
    <div className="d-flex flex-column justify-content-end h-100">
      <div className="position-relative">
        <div className="btn-toolbar d-flex flex-row flex-nowrap justify-content-start align-items-baseline overflow-hidden border-bottom mb-2 position-relative">
          <GradeTab selected={false}>
            <Skeleton variant="text">
              <p className="mb-0">Grade name</p>
            </Skeleton>
          </GradeTab>
        </div>
      </div>

      {children}
    </div>
  );
};

const CardParallelGradeTabContainer = ({
  gradedCardParallels,
  selectedGradedCardParallelId,
  setSelectedGradedCardParallelId,
  hiddenGradesCount,
  linkPath,
  children,
}) => {
  return (
    <div className="d-flex flex-column justify-content-end h-100">
      <div className="position-relative">
        <div className="btn-toolbar d-flex flex-row flex-nowrap justify-content-start align-items-baseline overflow-hidden border-bottom mb-2 position-relative">
          {gradedCardParallels.map((gcp) => (
            <GradeTab
              key={gcp.id}
              onClick={() =>
                // Support tap-to-preview for mobile, but if tapping the
                // currently selected grade, navigate to detail page
                gcp.id === selectedGradedCardParallelId
                  ? (window.location.href = linkPath)
                  : setSelectedGradedCardParallelId(gcp.id)
              }
              selected={gcp.id === selectedGradedCardParallelId}
              onMouseEnter={() =>
                // Support hover-to-preview on desktop, but not mobile where
                // it interferes with the subsequent "click"
                isBrowser ? setSelectedGradedCardParallelId(gcp.id) : null
              }
            >
              {displayNameForGrade(gcp.grade)}
            </GradeTab>
          ))}

          <HiddenGradesFade>
            {hiddenGradesCount > 0 &&
              "+" + pluralize("grade", hiddenGradesCount)}
          </HiddenGradesFade>
        </div>
      </div>

      {children}
    </div>
  );
};

export default CardParallelGradeTabContainer;
