import { Nav } from "react-bootstrap";
import styled from "styled-components";

const StyledNavBar = styled(Nav)`
  justify-content: center;
  background: transparent;

  // When we collapse to need scrolling
  @media (max-width: 48rem) {
    position: relative;
    overflow: hidden;
    background: #11284880;

    // Show a shadow to hint at scrolling
    ::after {
      pointer-events: none;
      content: "";
      position: absolute;
      right: 0rem;
      top: 0;
      bottom: 0;
      width: 1rem;

      background: linear-gradient(90deg, #11284800, #112848);
    }
  }
`;

const InternalScrollBar = styled.div`
  display: flex;
  flex: row;
  flex-wrap: nowrap;
  justify-content: start;
  overflow-x: scroll;

  padding: 0 1rem;

  // Allow horizontal scroll but hide scrollbar
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const NavBar = ({ sport, era, setValues }) => {
  let activeKey = sport === "" ? "trending" : sport;
  if (era !== "") {
    activeKey = "vintage";
  }

  return (
    <StyledNavBar activeKey={activeKey} className="secondary-nav">
      <InternalScrollBar>
        <Nav.Item>
          <Nav.Link
            eventKey="trending"
            onSelect={() => setValues("", "")}
            className="font-weight-bold text-nowrap"
          >
            All Sports
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="baseball"
            onSelect={(eventKey) => setValues(eventKey, "")}
            className="font-weight-bold text-nowrap"
          >
            Baseball
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="basketball"
            onSelect={(eventKey) => setValues(eventKey, "")}
            className="font-weight-bold text-nowrap"
          >
            Basketball
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="football"
            onSelect={(eventKey) => setValues(eventKey, "")}
            className="font-weight-bold text-nowrap"
          >
            Football
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="soccer"
            onSelect={(eventKey) => setValues(eventKey, "")}
            className="font-weight-bold text-nowrap"
          >
            Soccer
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="hockey"
            onSelect={(eventKey) => setValues(eventKey, "")}
            className="font-weight-bold text-nowrap"
          >
            Hockey
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="vintage"
            onSelect={(eventKey) => setValues("", eventKey)}
            className="font-weight-bold text-nowrap"
          >
            Vintage
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="other"
            onSelect={(eventKey) => setValues(eventKey, "")}
            className="font-weight-bold text-nowrap"
          >
            Other Sports
          </Nav.Link>
        </Nav.Item>

        {/* Hack to workaround last item margin being stripped from overflow */}
        <div>&nbsp;</div>
      </InternalScrollBar>
    </StyledNavBar>
  );
};

export default NavBar;
