import { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";

import GET_ACCOUNT_DETAILS from "../api/queries/GET_ACCOUNT_DETAILS";
import IS_LOGGED_IN from "../api/queries/IS_LOGGED_IN";

export const useFetchAccountData = () => {
  const [getAccountDetails, { data: accountData, error: accountError }] =
    useLazyQuery(GET_ACCOUNT_DETAILS);
  const { data: loginData } = useQuery(IS_LOGGED_IN);

  useEffect(() => {
    if (loginData && loginData.isLoggedIn) {
      getAccountDetails();
    }
  }, [loginData, getAccountDetails]);

  return {
    accountData: accountData?.myAccount,
    error: accountError,
    getAccountDetails,
    loginData,
  };
};
