import moment from "moment";

const AFFILIATE_CODE_KEY_PREFIX = "affiliateCode:";
const AFFILIATE_URL_PARAM = "utm_campaign";
const AFFILIATE_VALID_FOR_HOURS = 2 * 24;

const affiliateKeyForPage = () => {
  const card_path = window.location.pathname;
  return AFFILIATE_CODE_KEY_PREFIX + card_path;
};

const readAffiliateCodeFromQueryString = () => {
  const url_params = new URLSearchParams(window.location.search);

  if (url_params.has(AFFILIATE_URL_PARAM)) {
    return url_params.get(AFFILIATE_URL_PARAM);
  }
};

const setAffiliateCode = (affiliateCode) => {
  localStorage.setItem(
    affiliateKeyForPage(),
    JSON.stringify({
      code: affiliateCode,
      createdAt: moment.now(),
    }),
  );
};

const collectGarbage = () => {
  for (var i = 0, len = localStorage.length; i < len; ++i) {
    const key = localStorage.key(i);
    if (key && key.startsWith(AFFILIATE_CODE_KEY_PREFIX)) {
      try {
        const { createdAt } = JSON.parse(localStorage.getItem(key));
        if (createdAt < moment().subtract(AFFILIATE_VALID_FOR_HOURS, "hours")) {
          localStorage.removeItem(key);
        }
      } catch (err) {
        localStorage.removeItem(key);
      }
    }
  }
};

const deleteAffiliateCode = () => {
  localStorage.removeItem(affiliateKeyForPage());
};

export const setAffiliateCodeFromQueryString = () => {
  const code = readAffiliateCodeFromQueryString();

  if (code) setAffiliateCode(code);
  collectGarbage();
};

export const getValidAffiliateCode = () => {
  try {
    const { createdAt, code } = JSON.parse(
      localStorage.getItem(affiliateKeyForPage()),
    );

    const now = moment();
    // If someone is mucking about with Local Storage …
    if (createdAt > now) {
      deleteAffiliateCode();
    }

    if (createdAt >= now.subtract(AFFILIATE_VALID_FOR_HOURS, "hours")) {
      return code;
    }
  } catch (err) {
    deleteAffiliateCode();
    return;
  }
};
