import NumberFormat from "react-number-format";

export const CustomNumberFormat = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalSeparator={false}
      thousandSeparator
      isNumericString
    />
  );
};

export const CustomCurrencyFormat = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      inputMode="decimal"
      allowNegative={false}
      thousandSeparator
      isNumericString
      fixedDecimalScale={true}
      decimalScale={2}
    />
  );
};
