import { ReactNode } from "react";

import { Container } from "./styles";

export type Status =
  | "Danger"
  | "Dark"
  | "Info"
  | "Light"
  | "Primary"
  | "Secondary"
  | "Success"
  | "Warning";

interface Props {
  children: ReactNode;
  status?: Status;
}

export const WarningAlert = ({ children, status = "Primary" }: Props) => (
  <Container status={status}>{children}</Container>
);
