import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 66rem) {
    min-height: 27.5rem;
  }
`;

export const Header = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  span {
    font-weight: normal;
  }
`;

export const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

export const Subtitle = styled.p`
  margin-bottom: 1rem;
`;
