import { MouseEvent, useState, useEffect } from "react";
import Modal from "react-modal";

import { LotNodeEdge, LotState } from "../../../../generated/schema";

import { LotCard } from "../LotCard";
import { LotSummary } from "../LotSummary";
import {
  Header,
  LotScheduleContainer,
  Panel,
  Subtitle,
  Tab,
  TabLink,
  TabsList,
} from "./styles";

interface Props {
  lots: (LotNodeEdge | null)[];
}

const modalStyles = {
  content: {
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 0,
    bottom: "auto",
    left: "50%",
    maxHeight: "80vh",
    maxWidth: "50rem",
    padding: 0,
    right: "auto",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
  },
  /* stylelint-disable */
  overlay: {
    zIndex: 100,
  },
  /* stylelint-enable */
};

export const LotsSchedule = ({ lots }: Props) => {
  const [activeTab, setActiveTab] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLotId, setSelectedLotId] = useState("");

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setActiveTab(e.currentTarget.id);
  };

  const openModal = (id: string) => {
    setSelectedLotId(id);
    setIsOpen(true);
  };
  const closeModal = () => setIsOpen(false);

  const [upcomingLots, concludedLots] = lots.reduce(
    (result, lot) => {
      const { BIDDING, STARTING, UPCOMING } = LotState;

      if (lot?.node)
        result[
          [BIDDING, STARTING, UPCOMING].includes(lot.node.state) ? 0 : 1
        ].push(lot);
      return result;
    },
    [[] as LotNodeEdge[], [] as LotNodeEdge[]],
  );

  const auctionHasConcluded =
    upcomingLots.length === 0 && concludedLots.length > 0;

  useEffect(() => {
    auctionHasConcluded
      ? setActiveTab("tab-concluded")
      : setActiveTab("tab-upcoming");
  }, [auctionHasConcluded]);

  return (
    <>
      <LotScheduleContainer>
        <Header>🛒 All lots</Header>
        <Subtitle>Browse every lot up for grabs in this auction.</Subtitle>

        <TabsList role="tablist">
          {upcomingLots.length > 0 && (
            <Tab role="presentation">
              <TabLink
                id="tab-upcoming"
                href="#upcoming"
                role="tab"
                aria-selected={activeTab === "tab-upcoming"}
                onClick={handleClick}
              >
                Upcoming
              </TabLink>
            </Tab>
          )}

          {concludedLots.length > 0 && (
            <Tab role="presentation">
              <TabLink
                id="tab-concluded"
                href="#concluded"
                role="tab"
                aria-selected={activeTab === "tab-concluded"}
                onClick={handleClick}
              >
                Concluded
              </TabLink>
            </Tab>
          )}
        </TabsList>

        {upcomingLots.length > 0 && (
          <Panel
            role="tabpanel"
            id="panel-upcoming"
            aria-labelledby="tab-upcoming"
            hidden={activeTab !== "tab-upcoming"}
            tabIndex={0}
          >
            {upcomingLots.map((lot, idx) => {
              if (!lot.node) return null;

              const {
                highestBidPrice,
                id,
                lotphotoSet: { edges: photoEdges },
                name,
                openingPrice,
                state,
              } = lot.node;
              const firstItemIsBidding =
                upcomingLots[0].node?.state === LotState.BIDDING;

              // Ensures only the first Upcoming item is marked as nextLot
              const nextLot =
                idx === 0
                  ? state === LotState.UPCOMING
                  : state === LotState.UPCOMING &&
                    idx <= 1 &&
                    firstItemIsBidding;

              return (
                <LotCard
                  key={id}
                  heading={name}
                  imageUrl={photoEdges[0]?.node?.photoMd || ""}
                  nextLot={nextLot || state === LotState.STARTING}
                  onClick={() => openModal(id)}
                  price={highestBidPrice ?? openingPrice}
                  state={state}
                />
              );
            })}
          </Panel>
        )}

        {concludedLots.length > 0 && (
          <Panel
            role="tabpanel"
            id="panel-concluded"
            aria-labelledby="tab-concluded"
            hidden={activeTab !== "tab-concluded"}
            tabIndex={0}
          >
            {concludedLots.map((lot) => {
              if (!lot.node) return null;

              const {
                highestBidPrice,
                highestBid,
                id,
                lotphotoSet: { edges: photoEdges },
                name,
                state,
              } = lot.node;

              return (
                <LotCard
                  key={id}
                  heading={name}
                  imageUrl={photoEdges[0]?.node?.photoMd || ""}
                  onClick={() => openModal(id)}
                  price={highestBidPrice}
                  state={state}
                  winner={highestBid?.user.username}
                />
              );
            })}
          </Panel>
        )}
      </LotScheduleContainer>

      <Modal
        appElement={document.body}
        contentLabel={"Lot Details"}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <LotSummary close={closeModal} id={selectedLotId} />
      </Modal>
    </>
  );
};
