import { useQuery } from "@apollo/client";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import styled from "styled-components";

import IS_LOGGED_IN from "../../api/queries/IS_LOGGED_IN";
import SearchInput from "../../components/Activity/SearchInput";
import BreaksSearch from "../../components/Breaks/Home/BreaksSearch";
import BreaksStorefront from "../../components/Breaks/Home/BreaksStorefront";
import NavBar from "../../components/Breaks/NavBar";
import RequestBreakForm from "../../components/Breaks/RequestBreakForm";
import LoginModal from "../../components/CardDetails/LoginModal";
import Background from "../../images/backgrounds/vector/live-breaks-pattern-light.svg";
import BreaksStandaloneLogo from "../../images/breaks-logo-standalone-white.svg";
import { pathWithSearch } from "../../utils/common";

// Slightly darkened
const HeaderBaseColor = "#EE9165";
const HeaderGradientStart = "#d5784a";
const HeaderGradientEnd = "#9544cd";

const HeaderContainer = styled.div`
  background: url(${Background}) center bottom repeat-x,
    linear-gradient(135deg, ${HeaderGradientStart}, ${HeaderGradientEnd}),
    ${HeaderBaseColor};
`;

const BreaksLogo = styled.img`
  width: 144px;
  height: auto;
  margin-bottom: 1rem;

  @media (min-width: 36rem) {
    width: 288px;
  }
`;

const RequestBreakButton = styled.button`
  border: 0;
  background-color: ${HeaderBaseColor};
  border-radius: 4px;
  color: white;
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  overflow: hidden;
  padding: 0.375rem 0.5rem;
  position: absolute;
  top: 0;
  right: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 36rem) {
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
  }

  &:active,
  &:link,
  &:hover,
  &:visited {
    background-color: ${HeaderGradientStart};
    border-color: ${HeaderGradientStart};
    -color: ${HeaderGradientStart};
    text-decoration: none;
  }
`;

const Breaks = () => {
  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [sport, setSport] = useQueryState("sport", "");
  const [era, setEra] = useQueryState("era", "");
  const [showRequestBreak, setShowRequestBreak] = useState(false);

  const { data: loggedInData } = useQuery(IS_LOGGED_IN);
  const isLoggedIn = loggedInData && loggedInData.isLoggedIn;

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Breaks</title>
      </Helmet>

      <HeaderContainer className="row text-left">
        <div className="col inner-container-wide mx-auto d-flex flex-column mt-4">
          <div className="d-flex flex-row align-items-baseline justify-content-start">
            <BreaksLogo src={BreaksStandaloneLogo} alt="Breaks logo" />
          </div>

          <p className="text-white mb-3">
            <span className="mr-2" style={{ opacity: 0.8 }}>
              Explore our live events schedule, with daily NFT reMints, card
              breaks and fast-paced live auctions!
            </span>
            <Link
              to="/breaks/faq"
              className="text-white text-nowrap d-block d-sm-inline-block mt-2 mt-sm-0"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
              Learn more
            </Link>
          </p>

          <div className="w-100 max-width-40 mb-3">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder={"Search for upcoming events"}
              configMaxWidth="60rem"
              configSize="large"
            />
          </div>

          <RequestBreakButton onClick={() => setShowRequestBreak(true)}>
            Break from collection
          </RequestBreakButton>

          <NavBar
            sport={sport}
            era={era}
            setValues={(sport, era) => {
              setEra(era);
              setSport(sport);
              navigate(pathWithSearch());
            }}
          />
        </div>
      </HeaderContainer>

      {searchQuery ? (
        <BreaksSearch searchQuery={searchQuery} sport={sport} era={era} />
      ) : (
        <BreaksStorefront sport={sport} era={era} />
      )}

      {showRequestBreak && isLoggedIn ? (
        <RequestBreakForm
          show={showRequestBreak}
          setShow={setShowRequestBreak}
        />
      ) : (
        <LoginModal show={showRequestBreak} setShow={setShowRequestBreak} />
      )}
    </>
  );
};

export default Breaks;
