import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_LISTING_FRAGMENT,
  COLLECTION_LISTING_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
  PLAYER_LISTING_FRAGMENT,
} from "../fragments";

const GET_PLAYER_DETAILS = gql`
  ${CARD_PARALLEL_LISTING_FRAGMENT}
  ${COLLECTION_LISTING_FRAGMENT}
  ${PLAYER_LISTING_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  ${GRADE_FRAGMENT}
  fragment teamFields on TeamNode {
    id
    name
    abbrName
    colorPrimary
    colorSecondary
  }
  query GET_PLAYER_DETAILS(
    $slug: String
    $cardParallelCursor: String
    $myCardParallelCursor: String
  ) {
    player(slug: $slug) {
      ...playerListingFields
      headshotPhoto: headshotPhotoMd
      tradeStats {
        tradeCount
        minTradePrice
        maxTradePrice
      }
      seasonStats {
        statName
        statValue
      }
      cardParallels(first: 48, after: $cardParallelCursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...cardParallelListingFields
            gradedcardparallelSet(first: 3) {
              totalCount
              edges {
                node {
                  ...gradedCardParallelFields
                  grade {
                    ...gradeFields
                  }
                }
              }
            }
          }
        }
      }
      myCardParallels(first: 48, after: $myCardParallelCursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
        edges {
          node {
            ...collectionListingFields
            gradedcardparallelSet(first: 3) {
              totalCount
              edges {
                node {
                  ...gradedCardParallelFields
                  myItemCount
                  myOpenSellOrderCount
                  grade {
                    ...gradeFields
                  }
                }
              }
            }
          }
        }
      }
      fixtureTeamPlayers(first: 100) {
        totalCount
        edges {
          node {
            id
            fixture {
              startDate
              homeTeam {
                ...teamFields
              }
              awayTeam {
                ...teamFields
              }
            }
            teamPlayer {
              team {
                ...teamFields
              }
            }
            fixtureStats {
              statName
              statValue
            }
          }
        }
      }
    }
  }
`;

export default GET_PLAYER_DETAILS;
