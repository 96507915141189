import { pluralize } from "../../../../utils/content";

import { Container, Row } from "./styles";

interface Props {
  availableCount: number | null;
  hasEnded: boolean;
  totalCount: number | null;
}

export const SpotsAvailable = ({
  availableCount = 0,
  hasEnded,
  totalCount = 0,
}: Props) => {
  const hasNoSpots = availableCount === 0 && totalCount === 0;
  const pluralCount = pluralize("spot", totalCount);
  const spotsAvailableInnerLabel = `${availableCount} / ${pluralCount}`;
  const showAlmostGone =
    availableCount && totalCount && availableCount <= totalCount / 5;

  if (hasEnded)
    return hasNoSpots ? null : (
      <Container>
        <span>{totalCount} spot break</span>
      </Container>
    );

  if (hasNoSpots) return <Container>Spots to be confirmed</Container>;

  return (
    <Container>
      <Row>
        {availableCount === 0 ? (
          <>
            <span>{spotsAvailableInnerLabel} left</span>
            <small className="badge badge-pill badge-danger ml-2">
              SOLD OUT
            </small>
          </>
        ) : showAlmostGone ? (
          <>
            <span>{spotsAvailableInnerLabel} left</span>
            <small className="badge badge-pill badge-warning ml-2">
              ALMOST GONE!
            </small>
          </>
        ) : (
          <span>{spotsAvailableInnerLabel} remaining</span>
        )}
      </Row>
    </Container>
  );
};
