import styled from "styled-components";
import { Link } from "react-router-dom";
import { Checkbox } from "@material-ui/core";

import { Colors } from "../../../../../themes/Default";

export const Error = styled.div`
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  color: #721c24;
  margin: 1rem 1.5rem 0;
  padding: 0.75rem 1.25rem;
`;

export const CardDetails = styled.div`
  border-bottom: 1px solid #dcddde;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  .input {
    background-color: transparent;
    border: 1px solid #dcddde;
    border-radius: 0.5rem;
    height: 4rem;
    padding: 1.25rem 1.5rem;
    width: 100%;
  }
  /* stylelint-disable */
  .StripeElement--focus {
    /* stylelint-enable */
    background-color: #fff;
    border: 2px solid #6086ce;
    border-radius: 8px;
  }
`;

export const Label = styled.label`
  color: ${Colors.Astronaut};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 0;
  text-align: left;
`;

export const LabelCardDetailsExtended = styled(Label)`
  padding-top: 1rem;
  width: 100%;

  .input {
    @media (min-width: 48rem) {
      width: 100%;
    }
  }
`;

export const AdditionalCardDetails = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 48rem) {
    flex-direction: row;
    gap: 1rem;
  }
`;

export const MakeDefaultCard = styled.div`
  border-bottom: 1px solid #dcddde;
  display: flex;
  padding: 1.25rem 0 1.25rem 1.5rem;
`;

export const MakeDefaultCardText = styled.span`
  margin: 0.375rem 0 0.375rem 1rem;
`;

export const LegalTermsAndConditions = styled.div`
  padding: 1.5rem 1.5rem 0;
`;

export const LegalText = styled.p`
  color: ${Colors.Astronaut};
  font-size: 1rem;
  text-align: left;
`;

export const LegalLinkContainer = styled.div`
  display: flex;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

export const LegalCheckBox = styled(Checkbox)`
  && {
    color: #b1c5e8;
    padding: 0.875rem;
  }
  /* stylelint-disable */
  &.Mui-checked {
    &.Mui-checked {
      color: ${Colors.Blue};
    }
  }
  /* stylelint-enable */
`;

export const LegalLink = styled(Link)`
  color: ${Colors.Blue};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.875rem;
  margin-top: 0.875rem;
  text-align: left;
`;

export const UnderLined = styled.span`
  border-bottom: 1px solid ${Colors.Blue};
  display: inline-block;
  height: 1.5rem;
`;

export const LegalTermsLink = styled(LegalLink)`
  :link {
    text-decoration: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.25rem 1.5rem 1.5rem;

  @media (min-width: 48rem) {
    flex-direction: row;
  }
`;

export const SubmitButton = styled.button<{ disabled?: boolean }>`
  background: #77a8e5;
  border: 1px solid rgb(0 0 0 / 12.5%);
  border-radius: 0.3rem;
  color: #fff;
  display: block;
  font-size: 1.25rem;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  padding: 0.5rem 1rem;
  width: 100%;
`;

export const CancelButton = styled(SubmitButton)`
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
  color: ${Colors.Astronaut};
  font-size: 1rem;
  font-weight: bold;
  opacity: 1;
  text-align: center;
`;
