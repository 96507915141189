import styled from "styled-components";
import BillboardChart from "react-billboardjs";

import "billboard.js/dist/billboard.css";
import "./SalesCharts/billboard-overrides.css";

import { financial } from "../../utils/common";
import {
  averagePriceFromRecentPurchases,
  groupRecentPurchasesByDate,
  groupRecentPurchasesByGradeName,
} from "../../utils/recentPurchases";

const orderedGrades = [
  "PSA 10",
  "BGS 10",
  "BGS 9.5",
  "PSA 9",
  "BGS 9",
  "STARSTOCK A",
  "BGS 8.5",
  "STARSTOCK B",
  "PSA 8",
  "BGS 8",
  "STARSTOCK C",
  "Ungraded",
];

const ChartContainer = styled.div`
  position: relative;
  width: 100%;

  height: 18rem;
  @media (min-width: 992px) {
    height: 24rem;
  }
`;

const SalesChart = ({ sales }) => {
  // Charts are currently using a package called Billboard.js.
  // Examples: https://naver.github.io/billboard.js/demo/
  // API docs: https://naver.github.io/billboard.js/release/latest/doc/index.html

  // Transform our sales data into the format Billboard charts requires.
  // Billboard requires X values and Y values in distinct arrays, with a map
  // defining how they pair up.
  const salesByGrade = groupRecentPurchasesByGradeName(sales);
  const gradeKeys = Object.keys(salesByGrade).sort(function (a, b) {
    return orderedGrades.indexOf(a) > orderedGrades.indexOf(b) ? 1 : -1;
  });

  // Build array of average sale prices for each grade, prefixed by a name
  const averagePricesForGrades = gradeKeys.map((grade) =>
    [grade].concat(
      Object.values(groupRecentPurchasesByDate(salesByGrade[grade])).map(
        (sales) => averagePriceFromRecentPurchases(sales),
      ),
    ),
  );

  // Build array of unique dates with sales for each grade, prefixed by a name
  const datesSuffix = "dates";
  const datesForGrades = gradeKeys.map((grade) =>
    [`${grade} ${datesSuffix}`].concat(
      Object.keys(groupRecentPurchasesByDate(salesByGrade[grade])),
    ),
  );

  // Map average price (Y value) arrays to their associated date (X value) arrays
  const averagePricesToDatesMap = Object.fromEntries(
    gradeKeys.map((gradeKey) => [gradeKey, `${gradeKey} ${datesSuffix}`]),
  );

  // Force all chart items to use the y2 (right hand side) axis
  const averagePricesToYAxisMap = Object.fromEntries(
    gradeKeys.map((gradeKey) => [gradeKey, "y2"]),
  );

  // Determine the highest price we need to display on the chart
  const highestAveragePrice = Object.values(averagePricesForGrades)
    .map((sale) => sale.price)
    .reduce(function (previous, current) {
      return current > previous ? current : previous;
    });

  // Determine the earliest date we need to display on the chart
  const earliestSaleDate = sales
    .map((sale) => new Date(sale.date))
    .reduce(function (previous, current) {
      return current < previous ? current : previous;
    });

  const dateRange = {
    min: Math.min(
      earliestSaleDate,
      new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
    ),
    max: new Date().getTime(),
  };

  return (
    <ChartContainer>
      <BillboardChart
        className="position-absolute w-100 h-100"
        style={{ top: 0, left: 0 }}
        data={{
          columns: averagePricesForGrades.concat(datesForGrades),
          xs: averagePricesToDatesMap,
          type: "area",
          axes: averagePricesToYAxisMap,
        }}
        grid={{
          x: { show: true },
          y: { show: true },
        }}
        axis={{
          x: {
            type: "timeseries",
            min: dateRange["min"],
            max: dateRange["max"],
            tick: {
              fit: false,
              rotate: 20,
              format: function (x) {
                return x.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                });
              },
            },
          },
          // Use the right-hand axis rather than left
          y: { show: false },
          y2: {
            show: true,
            min: 0,
            max: highestAveragePrice,
            padding: {
              bottom: 0,
              top: 32,
            },
            tick: {
              format: function (value) {
                return `$${financial(value)}`;
              },
            },
          },
        }}
        legend={{
          position: "inset",
          item: {
            tile: {
              width: 12,
              height: 12,
            },
          },
        }}
        tooltip={{
          format: {
            value: function (value) {
              return `$${financial(value)}`;
            },
          },
        }}
        point={{ r: 4, type: "circle" }}
        area={{
          linearGradient: {
            y: [0.2, 1.2],
          },
        }}
      />
    </ChartContainer>
  );
};

export default SalesChart;
