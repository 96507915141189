import { gql } from "@apollo/client";

const ADD_ITEMS_TO_DRAFT_SHIPPING_REQUEST = gql`
  mutation addItemsToDraftShippingRequest($itemsToAdd: [String]!) {
    addItemsToDraftShippingRequest(itemsToAdd: $itemsToAdd) {
      itemCount
      addedItems
      errors {
        field
        message
      }
    }
  }
`;

export default ADD_ITEMS_TO_DRAFT_SHIPPING_REQUEST;
