import styled, { css, keyframes } from "styled-components";

interface ChildrenWrapperProps {
  isStarting: boolean;
  lotHasItems: boolean;
  maxHeight: string;
  sticky: boolean;
  stickyOffset: number;
}

interface GridProps {
  hasChildren: boolean;
}

interface LotContainerProps {
  hasEnded: boolean;
  isStarting: boolean;
}

const animateLotIncoming = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.1) translateY(2rem);
  }

  50% {
    opacity: 0;
    transform: scale(1.1) translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

const animateBidPanelIncoming = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 1; }
`;

export const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  ${({ maxHeight, lotHasItems }) =>
    lotHasItems ? `max-height: ${maxHeight};` : `max-height: 32rem;`}

  ${({ sticky, stickyOffset }) =>
    sticky &&
    stickyOffset &&
    css`
      bottom: 0;
      left: ${stickyOffset}px;
      position: fixed;
      width: 24rem;
      z-index: 2;
    `}

  ${({ isStarting }) =>
    isStarting &&
    css`
      animation-duration: 2s;
      animation-iteration-count: 1;
      animation-name: ${animateBidPanelIncoming};
      animation-timing-function: ease-in-out;
      opacity: 1;
    `}
`;

export const Contents = styled.section`
  margin-top: 1.5rem;
`;

export const ContentsHeading = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
`;

export const ContentsSubheading = styled.p`
  margin: 0;
`;

export const ContentsItems = styled.div`
  display: grid;
  grid-auto-flow: initial;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  padding: 1rem 0 0;

  @media (min-width: 60rem) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }

  /* To style the MarketCardParallel */
  & > div {
    border-left-width: 1px;
    border-radius: 0.25rem;
    border-right-width: 1px;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Grid = styled.div<GridProps>`
  @media (min-width: 66rem) {
    display: grid;
    grid-column-gap: 2rem;

    ${({ hasChildren }) =>
      hasChildren &&
      css`
        grid-template-columns: 1fr 24rem;
      `}
  }
`;

export const Information = styled.section<{ hasImage: boolean }>`
  @media (min-width: 48rem) {
    margin-top: 0;

    ${({ hasImage }) =>
      hasImage &&
      css`
        display: grid;
        grid-column-gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
      `}
  }
`;

export const LotContainer = styled.div<LotContainerProps>`
  @media (min-width: 66rem) {
    min-height: 27.5rem;
  }

  ${({ isStarting }) =>
    isStarting &&
    css`
      animation-duration: 2s;
      animation-iteration-count: 1;
      animation-name: ${animateLotIncoming};
      animation-timing-function: ease-in-out;
      opacity: 1;
    `}
`;

export const LotHeading = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0 0 1rem;
`;

export const LotInformation = styled.div`
  margin-top: 1rem;

  @media (min-width: 48rem) {
    margin-top: 0;
  }
`;

export const Paragraph = styled.p`
  margin: 1rem 0 0;
`;
