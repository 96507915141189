import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const Panel = styled.div`
  background: ${Colors.Porcelain};
  border-left: 0.25rem solid ${Colors.Havelock};
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Label = styled.p`
  margin-bottom: 0;
`;

export const Value = styled(Label)`
  font-weight: bold;
  text-align: right;
`;
