import { debounce } from "lodash";

import Loader from "../../Loader";
import Error from "./components/Error";
import Form from "./components/Form";
import Success from "./components/Success";
import useFetchPersonalBreaks from "./hooks/useFetchPersonalBreaks";
import useRequestBreak from "./hooks/useRequestBreak";
import useRequestForm from "./hooks/useRequestForm";
import { Payload } from "./types";

const RequestBreakForm = () => {
  const { state, dispatch } = useRequestForm();
  const { data } = useFetchPersonalBreaks(dispatch);
  const { createPersonalBreakRequest } = useRequestBreak(dispatch, state, data);
  const setFormDataFields = (payload: Payload) =>
    dispatch({ type: "update", payload });
  const resetForm = () => {
    dispatch({ type: "reset" });
  };

  const submitForm = () => {
    if (!state.formComplete) return;

    dispatch({ type: "submit" });

    createPersonalBreakRequest({
      variables: {
        addressId: state.form.addressId,
        boxBreakId: state.form.boxBreakId,
        itemOwnershipId: state.form.itemOwnershipId,
        preferShipHome: state.form.preferShipHome,
        additionalInstructions: state.form.additionalInstructions,
        requestDetails: state.form.requestDetails,
      },
      update(cache) {
        const id = cache.identify({
          id: state.form.itemOwnershipId,
          __typename: "ItemOwnershipNode",
        });

        cache.evict({ id });
        cache.gc();
      },
    });
  };

  const selectedBreak = data?.boxBreaks.edges.filter(
    ({ node }: any) => node.id === state.form.boxBreakId,
  )[0];

  return (
    <>
      {state.step === "LOADING" && <Loader message="Checking breaks…" />}

      {state.step === "ERROR" && <Error errors={state.errors} />}

      {state.step === "FORM" && (
        <Form
          breakFee={state.form.breakFee}
          boxes={data.myCollection.edges}
          breaks={data.boxBreaks.edges}
          formData={state.form}
          updateForm={debounce(setFormDataFields, 100)}
          onNext={submitForm}
          onCancel={resetForm}
          formCompleted={state.formComplete}
        />
      )}

      {state.step === "SUBMIT" && (
        <Loader message="Adding your box to the break…" />
      )}

      {state.step === "SUCCESS" && (
        <Success
          requestCost={state.result.purchasedSpotsCost}
          onClose={resetForm}
          slug={selectedBreak.node.slug}
        />
      )}
    </>
  );
};

export default RequestBreakForm;
