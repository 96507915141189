import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";

import { productName, productNameSuffix } from "../utils/content";
import { logAmplitudeEvent } from "../utils/analytics";
import {
  MARKETPLACE_EVENTS,
  WATCHLIST_EVENTS,
} from "../utils/analytics/events";

import { PRODUCT_TYPES } from "../api/types";
import IS_LOGGED_IN from "../api/queries/IS_LOGGED_IN";
import GET_CARD_PARALLEL from "../api/queries/GET_CARD_PARALLEL";
import GET_MY_WISHLIST_METADATA from "../api/queries/GET_MY_WISHLIST_METADATA";
import DELETE_WISHLIST_ITEM from "../api/mutations/DELETE_WISHLIST_ITEM";
import CREATE_WISHLIST_ITEM from "../api/mutations/CREATE_WISHLIST_ITEM";

import ErrorNotice from "../components/ErrorNotice";
import Loader from "../components/Loader";
import CardDetailSection from "../components/CardDetails/CardDetailSection";
import CardAvailabilitySection from "../components/CardDetails/CardAvailabilitySection";
import SalesHistorySection from "../components/CardDetails/SalesHistorySection";
import OtherCardParallels from "../components/CardDetails/OtherCardParallels";
import { PageSection, PageSectionTypes } from "../components/PageSection";

const CardDetail = () => {
  const { slug } = useParams();

  useEffect(
    () =>
      logAmplitudeEvent(MARKETPLACE_EVENTS.CARD.PAGE_VIEWED, { Slug: slug }),
    [slug],
  );

  const { data: loginData } = useQuery(IS_LOGGED_IN);
  const { data, loading, error } = useQuery(GET_CARD_PARALLEL, {
    variables: { slug },
  });

  const [createWishlistItem] = useMutation(CREATE_WISHLIST_ITEM, {
    refetchQueries: [
      { query: GET_CARD_PARALLEL, variables: { slug } },
      { query: GET_MY_WISHLIST_METADATA },
    ],
    onCompleted({ _response }) {
      logAmplitudeEvent(WATCHLIST_EVENTS.CARDS.CARD_ADDED);
    },
  });

  const [deleteWishlistItem] = useMutation(DELETE_WISHLIST_ITEM, {
    refetchQueries: [
      { query: GET_CARD_PARALLEL, variables: { slug } },
      { query: GET_MY_WISHLIST_METADATA },
    ],
    onCompleted({ _response }) {
      logAmplitudeEvent(WATCHLIST_EVENTS.CARDS.CARD_REMOVED);
    },
  });

  const [selectedGradeId, setSelectedGradeId] = useQueryState("grade", "");

  if (loading) return <Loader />;
  if (error || !data.cardParallel) return <ErrorNotice error={error} />;

  const {
    cardParallel,
    cardParallel: {
      id,
      productType,
      sellOrderCount,
      parallel,
      frontSidePhoto,
      frontSidePhotoSmall,
      frontSidePhotoMedium,
      reverseSidePhoto,
      boxProductPhoto,
      boxProductPhotoSmall,
      boxProductPhotoMedium,
      serialLimit,
      playerCard,
      itemCount,
      myWishlistItems,
      recentPurchases,
      gradedcardparallelSet,
    },
  } = data;

  const { grades } = data;

  // Restrict grades by product type. This is a hacky way for us to remove the
  // Box-specific grades from cards, and remove all _other_ grades from boxes,
  // since our MVP launch shares the same models.
  const filteredGrades = grades.edges
    .map(({ node: grade }) => grade)
    .filter((grade) =>
      productType === PRODUCT_TYPES.BOX
        ? grade.name.includes("Box")
        : !grade.name.includes("Box"),
    );

  const wishlistItemId = myWishlistItems.length ? myWishlistItems[0].id : null;

  return (
    <>
      <Helmet>
        <title>{cardParallel?.name}</title>
      </Helmet>

      <PageSection>
        <CardDetailSection
          cardParallelId={id}
          productType={cardParallel.productType}
          parallel={parallel}
          cardName={productName(cardParallel)}
          cardNameSuffix={productNameSuffix(cardParallel)}
          playerCard={playerCard}
          serialLimit={serialLimit}
          itemCount={itemCount}
          listingsCount={sellOrderCount}
          frontSidePhotoPreview={frontSidePhotoMedium}
          frontSidePhotoFull={frontSidePhoto}
          reverseSidePhotoFull={reverseSidePhoto}
          boxProductPhotoPreview={boxProductPhotoMedium}
          boxProductPhotoFull={boxProductPhoto}
          wishlistItemId={wishlistItemId}
          createWishlistItem={createWishlistItem}
          deleteWishlistItem={deleteWishlistItem}
        />
      </PageSection>

      <PageSection type={PageSectionTypes.background}>
        <CardAvailabilitySection
          id="card-availability-section"
          slug={slug}
          cardParallelId={id}
          cardParallelProductType={productType}
          cardParallelPhoto={
            productType === PRODUCT_TYPES.BOX
              ? boxProductPhotoSmall
              : frontSidePhotoSmall
          }
          parallelName={parallel.name}
          serialLimit={serialLimit}
          playerCard={playerCard}
          grades={filteredGrades}
          gradedCardParallels={gradedcardparallelSet.edges.map(
            ({ node }) => node,
          )}
          selectedGradeId={selectedGradeId}
          setSelectedGradeId={setSelectedGradeId}
        />
      </PageSection>

      <PageSection borderTop>
        <SalesHistorySection
          loginData={loginData}
          recentPurchases={recentPurchases}
        />
      </PageSection>

      {productType !== PRODUCT_TYPES.BOX ? (
        <PageSection type={PageSectionTypes.background}>
          <OtherCardParallels currentParallelId={id} playerCard={playerCard} />
        </PageSection>
      ) : null}
    </>
  );
};

export default CardDetail;
