import { gql } from "@apollo/client";

const GET_SHIPPING_METADATA = gql`
  query GET_SHIPPING_METADATA(
    $sport: String
    $era: String
    $searchQuery: String
    $orderBy: String
  ) {
    myCollection(
      first: 0
      sport: $sport
      era: $era
      orderBy: $orderBy
      searchQuery: $searchQuery
    ) {
      totalCount
    }
    myInDraftShippingRequestCollection(
      first: 0
      sport: $sport
      era: $era
      orderBy: $orderBy
      searchQuery: $searchQuery
    ) {
      totalCount
    }
    entireBasket: myInDraftShippingRequestCollection(first: 0) {
      totalCount
    }
  }
`;

export default GET_SHIPPING_METADATA;
