import { useEffect } from "react";
import { logAmplitudeEvent } from "../../utils/analytics";
import { MARKETPLACE_EVENTS } from "../../utils/analytics/events";
import SearchInterface from "./SearchInterface";
import { FiltersDrawerButtonVariants } from "../Filters/FiltersDrawerButton";
import { SortControlVariants } from "./SortControl";

const Search = ({ sport, era, searchQuery }) => {
  useEffect(
    () =>
      logAmplitudeEvent(MARKETPLACE_EVENTS.SEARCH.PAGE_VIEWED, {
        Sport: sport,
        Era: era,
      }),
    [era, sport],
  );

  return (
    <div className="row">
      <div className="col inner-container-wide mx-auto">
        <SearchInterface
          sport={sport}
          era={era}
          searchQuery={searchQuery}
          showSearchInput={false}
          showSearchInputPlaceholder={true}
          filterDrawerButtonVariant={FiltersDrawerButtonVariants.iconAndText}
          sortControlVariant={SortControlVariants.full}
        />
      </div>
    </div>
  );
};

export default Search;
