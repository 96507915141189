import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const Container = styled.div`
  color: ${Colors.Astronaut};
  flex: 0;
  font-size: 0.875rem;
  margin-bottom: 0;
  opacity: 0.8;

  @media (min-width: 66rem) {
    font-size: 1rem;
  }
`;

export const Row = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
