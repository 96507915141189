import { gql } from "@apollo/client";

const GET_MY_DRAFT_SHIPPING_REQUEST = gql`
  query GET_MY_DRAFT_SHIPPING_REQUEST {
    myDraftShippingRequest {
      createdAt
      status
      id
      shippingRequestFee
      shippingitemSet {
        edges {
          node {
            item {
              id
              cachedName
              cardParallel {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_MY_DRAFT_SHIPPING_REQUEST;
