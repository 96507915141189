import styled from "styled-components";
import { Link } from "react-router-dom";

const introContent = [
  {
    title: "Instant trading",
    subtitle:
      "Instant ownership on purchase. Day-trading on game day is now possible!",
  },
  {
    title: "Save time selling",
    subtitle:
      "We photograph and categorise your collection. You just list your price.",
  },
  {
    title: "Invest at scale",
    subtitle:
      "Purchase from multiple sellers in a single transaction. No fees for buyers.",
  },
  {
    title: "Skip on shipping",
    subtitle:
      "Cards stay secure and insured in our vault until you want them shipped home.",
  },
];

const Tagline = styled.h1`
  padding: 0 1rem;
  font-size: 1.5rem;

  @media (min-width: 992px) {
    font-size: 2rem;
  }
`;

const TaglineRegular = styled.span`
  color: #4e8ad4;
`;
const TaglineEmphasis = styled.span`
  color: white;
  font-weight: bold;
  white-space: nowrap;
`;

const IntroScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;

  // Allow horizontal scroll but hide scrollbar
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const IntroItemContainer = styled.div`
  min-width: 66%;
  @media (min-width: 512px) {
    min-width: 45%;
  }
  @media (min-width: 992px) {
    min-width: 0;
  }
`;

const IntroItem = styled.div`
  text-align: left;
  height: 100%;
  background: rgba(11, 31, 60, 0.5);
  border-left: 4px solid #58b55c;
  border-radius: 0.25rem;

  &:hover {
    background: rgba(11, 31, 60, 0.75); //#2c4d76;
    cursor: pointer;
    transform: translateY(-1px);
  }
`;

const Intro = ({ expanded }) => {
  return (
    <>
      <Tagline className="text-uppercase mt-3 mb-4 text-center">
        <TaglineRegular>The stock market </TaglineRegular>
        <TaglineEmphasis>For sports cards</TaglineEmphasis>
      </Tagline>

      {expanded && (
        <IntroScrollContainer className="row" style={{}}>
          {introContent.map(({ title, subtitle }) => (
            <IntroItemContainer className="col-lg-3" key={title}>
              <IntroItem className="d-block p-3 p-lg-4">
                <Link to={`/faq`} className="stretched-link m-0" />
                <h5 className="text-white font-weight-bold">{title}</h5>
                <p className="text-white-50 mb-0">{subtitle}</p>
              </IntroItem>
            </IntroItemContainer>
          ))}
        </IntroScrollContainer>
      )}
    </>
  );
};

export default Intro;
