import { useEffect } from "react";
import { Helmet } from "react-helmet";

import Page from "../../components/Contentful/Page";
import ContentWrapper from "../../components/ContentWrapper";
import BreaksStandaloneLogo from "../../images/breaks-logo-color.svg";
import { logAmplitudeEvent } from "../../utils/analytics";
import { BREAKS_EVENTS } from "../../utils/analytics/events";
import { markdown } from "../../utils/contentful";

const BreaksFaq = () => {
  useEffect(() => logAmplitudeEvent(BREAKS_EVENTS.FAQ.PAGE_VIEWED), []);

  return (
    <>
      <Helmet>
        <title>Breaks FAQ</title>
      </Helmet>

      <ContentWrapper>
        <Page slug="breaks-faq">
          {(page) => (
            <>
              <div className="d-flex justify-content-center pb-4 border-bottom mb-5">
                <img
                  src={BreaksStandaloneLogo}
                  style={{ width: "320px", height: "auto" }}
                  alt="Breaks logo"
                />
              </div>
              {markdown(page.body)}
            </>
          )}
        </Page>
      </ContentWrapper>
    </>
  );
};

export default BreaksFaq;
