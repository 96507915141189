import { Children, ReactNode } from "react";

import EmptySection from "../Breaks/EmptySection";
import { Carousel } from "../Carousel";
import ErrorNotice from "../ErrorNotice";
import Loader from "../Loader";

interface Props {
  children?: ReactNode;
  error: boolean;
  loading: boolean;
  themeColor?: string;
}

export const EventCarousel = ({
  children,
  error,
  loading,
  themeColor = "red",
}: Props) => {
  const childrenCount = Children.toArray(children).length;

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;
  if (childrenCount === 0) return <EmptySection />;

  return (
    <Carousel
      themeColor={themeColor}
      customSettings={{
        dots: childrenCount > 4,
        arrows: childrenCount > 4,
        infinite: childrenCount > 4,
        speed: 400,
        initialSlide: 0,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            settings: {
              slidesToShow: 1.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 399,
          },
          {
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 499,
          },
          {
            settings: {
              slidesToShow: 1.75,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 649,
          },
          {
            settings: {
              slidesToShow: 2.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 799,
          },
          {
            settings: {
              infinite: childrenCount > 3,
              arrows: childrenCount > 3,
              slidesToShow: 3,
              slidesToScroll: 3,
            },
            breakpoint: 1199,
          },
          {
            settings: {
              infinite: childrenCount > 4,
              arrows: childrenCount > 4,
              slidesToShow: 4,
              slidesToScroll: 4,
            },
            // This might be too early to go to 5; means just 185px per card
            breakpoint: 1200,
          },
        ],
      }}
    >
      {children}
    </Carousel>
  );
};
