import { AuctionState } from "../../../../generated/schema";

import { AuctionsCarouselWidget } from "../AuctionsCarouselWidget";

interface Props {
  era?: string;
  sport?: string;
}

export const RecentAuctionsCarouselWidget = ({ era, sport }: Props) => (
  <AuctionsCarouselWidget
    id="recent-auctions-widget"
    options={{
      variables: {
        era,
        first: 24,
        orderBy: "scheduled_start_time_desc",
        sport,
        state: [AuctionState.ENDED],
      },
    }}
    subtitle="Review sales from the latest auctions"
    themeColor="#da4d14"
    title={"Recent auctions"}
    widgetName="RecentAuctionsList"
  />
);
