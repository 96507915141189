import { gql } from "@apollo/client";

const UPDATE_NOTIFICATION_PREFERENCES = gql`
  mutation UPDATE_NOTIFICATION_PREFERENCES(
    $buyTargetPercentage: Int
    $sellTargetPercentage: Int
    $notificationPreferences: [UpdateNotificationPreferencesInput!]
  ) {
    updateNotificationPreferences(
      buyTargetPercentage: $buyTargetPercentage
      sellTargetPercentage: $sellTargetPercentage
      notificationPreferences: $notificationPreferences
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export default UPDATE_NOTIFICATION_PREFERENCES;
