import { ChangeEvent, FormEvent, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  CardCvcElement,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { ConfirmCardPaymentData } from "@stripe/stripe-js";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { logAmplitudeEvent } from "../../../../utils/analytics";
import {
  CreatePaymentIntent,
  StripePaymentMethodNode,
} from "../../../../generated/schema";
import DELETE_PAYMENT_METHOD from "../../../../api/mutations/DELETE_PAYMENT_METHOD";
import CREATE_PAYMENT_INTENT from "../../../../api/mutations/CREATE_PAYMENT_INTENT";
import Loader from "../../../../components/Loader";

import GET_MY_PAYMENT_METHODS from "../../api/GET_MY_PAYMENT_METHODS";
import { EditPaymentMethodsModal } from "../EditPaymentMethodsModal";
import { PaymentMethodInfo } from "../PaymentMethodInfo";

const CARD_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

type MyPaymentMethods = (StripePaymentMethodNode | null)[] | null;

interface QueryResponse {
  myPaymentMethods: MyPaymentMethods;
}
interface MutationResponse {
  createPaymentIntent: CreatePaymentIntent;
}

interface Props {
  amount: number;
  setError: (errorMessage: string) => void;
  successPath: string;
  depositSuccessEventName: string;
  depositFailureEventName: string;
}

export const PaymentCardDepositForm = ({
  amount,
  setError,
  successPath,
  depositSuccessEventName,
  depositFailureEventName,
}: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [paymentMethodValue, setPaymentMethodValue] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const { data, loading } = useQuery<QueryResponse>(GET_MY_PAYMENT_METHODS);
  const [createPaymentIntent] = useMutation<MutationResponse>(
    CREATE_PAYMENT_INTENT,
    {
      onCompleted({ createPaymentIntent }) {
        if (
          createPaymentIntent.paymentIntentToken &&
          createPaymentIntent.paymentSequence
        ) {
          takeCardPayment(
            createPaymentIntent.paymentIntentToken,
            createPaymentIntent.paymentSequence,
          );
        }
      },
    },
  );
  const [deletePaymentMethod] = useMutation(DELETE_PAYMENT_METHOD, {
    refetchQueries: [{ query: GET_MY_PAYMENT_METHODS }],
  });

  if (loading) {
    return <Loader />;
  }

  const onDeletePaymentMethod = (paymentMethodId: string) => {
    handleModalClose();
    return deletePaymentMethod({
      variables: {
        paymentMethodId: paymentMethodId,
      },
    });
  };

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const hasStripeLoaded = !stripe || !elements || isSubmitting;
    if (hasStripeLoaded) {
      // Disable form submission until Stripe.js has loaded
      return;
    }

    setSubmitting(true);

    createPaymentIntent({
      variables: {
        // Stripe deals with cents/pennies
        amount: amount * 100,
        saveCard: saveCard,
      },
    });
  };
  const handlePaymentMethodSelection = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setPaymentMethodValue(event.target.value);
  };

  const paymentMethods: MyPaymentMethods =
    (!loading && data && data.myPaymentMethods) || [];

  const totalPaymentMethods = paymentMethods.length;

  const takeCardPayment = async (
    paymentIntentToken: string,
    paymentSequence: number | null,
  ) => {
    if (!elements) {
      setError("Please input card details");
      setSubmitting(false);
      return;
    }

    if (!stripe) {
      setError("Error with card payment provider");
      setSubmitting(false);
      return;
    }

    const cvc = elements.getElement(CardCvcElement);
    const card = elements.getElement(CardElement);

    if (!card && !paymentMethodValue) {
      setError("Please input card details");
      setSubmitting(false);
    }

    let paymentDetails = {
      payment_method: { card },
    } as ConfirmCardPaymentData;

    const paymentMethod = paymentMethods[parseInt(paymentMethodValue)];
    const canCardBeUsed =
      paymentMethodValue &&
      paymentMethodValue !== "addNewCard" &&
      cvc &&
      paymentMethods &&
      paymentMethod;

    if (canCardBeUsed) {
      paymentDetails = {
        payment_method: paymentMethod.paymentMethodId,
        payment_method_options: {
          card: { cvc },
        },
      };
    }

    const result = await stripe.confirmCardPayment(
      paymentIntentToken,
      paymentDetails,
    );

    const { error, paymentIntent } = result;
    if (error) {
      const { message, type, code, decline_code, payment_intent } = error;

      logAmplitudeEvent(depositFailureEventName, {
        Type: type,
        Code: code,
        "Decline Code": decline_code,
        "Payment Method":
          paymentMethods && paymentMethods[parseInt(paymentMethodValue)]
            ? "Saved Card"
            : "New Card",
        Message: message,
        Amount: payment_intent ? payment_intent.amount / 100 : null,
      });

      message && setError(message);
      setSubmitting(false);
    } else {
      if (paymentIntent && paymentIntent.status === "succeeded") {
        logAmplitudeEvent(depositSuccessEventName, {
          Amount: paymentIntent.amount / 100,
          "Payment Sequence": paymentSequence,
          "Payment Method":
            paymentMethods && paymentMethods[parseInt(paymentMethodValue)]
              ? "Saved Card"
              : "New Card",
        });

        navigate(successPath);
      }
    }
  };

  const toggleSaveCard = () => {
    setSaveCard(!saveCard);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {totalPaymentMethods > 0 && (
          <div className="text-left">
            <FormControl>
              <FormLabel>Select payment card:</FormLabel>
              <RadioGroup
                aria-label="payment methods"
                name="paymentMethods"
                value={paymentMethodValue}
                onChange={handlePaymentMethodSelection}
              >
                {paymentMethods.map((paymentMethod, index) => {
                  if (!paymentMethod) return null;
                  return (
                    <FormControlLabel
                      key={index}
                      value={index.toString()}
                      control={<Radio />}
                      label={
                        <PaymentMethodInfo paymentDetails={paymentMethod} />
                      }
                    />
                  );
                })}
                {paymentMethodValue && paymentMethodValue !== "addNewCard" && (
                  <div className="my-2">
                    <FormLabel>Input card CVC:</FormLabel>
                    <CardCvcElement />
                  </div>
                )}
                <FormControlLabel
                  value={"addNewCard"}
                  control={<Radio />}
                  label={"Add a new card"}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        {(totalPaymentMethods === 0 || paymentMethodValue === "addNewCard") && (
          <>
            <div className="form-group">
              <CardElement options={CARD_OPTIONS} />
            </div>

            <div className="text-left">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={toggleSaveCard}
                    checked={saveCard}
                    name="saveCard"
                  />
                }
                label="Save for future payments"
              />
            </div>
          </>
        )}
        <button
          className="btn btn-success btn-block btn-lg mt-4"
          disabled={!stripe || isSubmitting}
        >
          <FontAwesomeIcon icon={faCreditCard} size="xs" className="mr-2" />
          Pay by card
        </button>
      </form>

      {totalPaymentMethods > 0 && (
        <>
          <button
            className="btn btn-outline-primary btn-block btn-lg mt-4"
            disabled={!stripe || isSubmitting}
            onClick={handleModalShow}
          >
            <FontAwesomeIcon icon={faEdit} size="xs" className="mr-2" />
            Edit saved cards
          </button>
          <EditPaymentMethodsModal
            show={modalShow}
            handleClose={handleModalClose}
            paymentMethods={paymentMethods}
            onDelete={onDeletePaymentMethod}
          />
        </>
      )}
    </>
  );
};
