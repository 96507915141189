import { gql } from "@apollo/client";

export const GET_ANNOUNCEMENT_BANNERS = gql`
  query GET_ANNOUNCEMENT_BANNERS {
    announcementBanners {
      id
      name
      slug
      state
      prefixText
      bodyText
      linkUrl
      linkTitle
      style {
        badgeGradientEnd
        badgeGradientStart
        badgeTextColor
      }
    }
  }
`;
