import { gql } from "@apollo/client";

const REQUEST_BOX_BREAK = gql`
  mutation createPersonalBreakRequest(
    $addressId: ID!
    $boxBreakId: ID!
    $itemOwnershipId: ID!
    $preferShipHome: Boolean
    $additionalInstructions: String
    $requestDetails: String
  ) {
    personalBreakRequestCreate(
      addressId: $addressId
      boxBreakId: $boxBreakId
      itemOwnershipId: $itemOwnershipId
      preferShipHome: $preferShipHome
      additionalInstructions: $additionalInstructions
      requestDetails: $requestDetails
    ) {
      purchasedSpotsCost
      errors {
        field
        message
      }
    }
  }
`;

export default REQUEST_BOX_BREAK;
