import { gql } from "@apollo/client";

const GET_ACCOUNT_DETAILS = gql`
  query GET_ACCOUNT_DETAILS {
    myAccount {
      balance
      username
      isStaff
    }
  }
`;

export default GET_ACCOUNT_DETAILS;
