import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_ID_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
  PARALLEL_FRAGMENT,
  PLAYER_CARD_FRAGMENT,
} from "../fragments";

const GET_GRADED_CARD_PARALLEL_FOR_SELLING = gql`
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_FRAGMENT}
  ${GRADE_FRAGMENT}
  query GET_GRADED_CARD_PARALLEL_FOR_SELLING($id: ID!) {
    gradedCardParallel(id: $id) {
      ...gradedCardParallelFields
      myItemCount
      myOpenSellOrderCount
      cardParallel {
        ...cardParallelIdFields
        parallel {
          ...parallelFields
        }
        playerCard {
          ...playerCardFields
        }
      }
      grade {
        ...gradeFields
      }
      openBuyOrders {
        id
        state
        buyPrice
      }
    }
  }
`;

export default GET_GRADED_CARD_PARALLEL_FOR_SELLING;
