import styled from "styled-components";

export const AddressFormContainer = styled.div`
  padding: 1.5rem;
  text-align: center;
`;

export const Header = styled.h1`
  border-bottom: 1px solid rgb(0 0 0/ 10%);
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
`;
