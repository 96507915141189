import styled from "styled-components";

import { financial } from "../../utils/common";

const Badge = styled.div`
  font-size: 0.75rem;
  border-radius: 2px;
  border: ${(props) =>
    props.listingsCount > 0
      ? "1px solid #4e8ad4;"
      : "1px solid rgba(0, 0, 0, 0.1);"} // havelock / border-color
  color: ${(props) =>
    props.listingsCount > 0
      ? "#4e8ad4;"
      : "rgba(44, 77, 118, 0.4);"} ; // havelock / text-whisper
  padding: 0 0.25rem;
`;

export const MyListingsBadgeVariants = {
  requiresContext: "requiresContext",
  doesNotRequireContext: "doesNotRequireContext",
};

const MyListingsBadge = ({
  ownedCount,
  listingsCount,
  minPrice,
  variant = MyListingsBadgeVariants.requiresContext,
}) => {
  return (
    <Badge listingsCount={listingsCount} className="badge-row-item mt-2">
      {variant === MyListingsBadgeVariants.requiresContext ? (
        <>
          You listed {listingsCount || 0}
          {ownedCount && ` of ${ownedCount}`}
          {minPrice &&
            (listingsCount === 1
              ? ` for $${financial(minPrice)}`
              : ` from $${financial(minPrice)}`)}
        </>
      ) : (
        <>
          {listingsCount || 0}
          {ownedCount && ` of ${ownedCount}`} listed
          {minPrice &&
            (listingsCount === 1
              ? ` for $${financial(minPrice)}`
              : ` from $${financial(minPrice)}`)}
        </>
      )}
    </Badge>
  );
};

export default MyListingsBadge;
