import { useState } from "react";

import ErrorNotice from "../../../../components/ErrorNotice";
import Loader from "../../../../components/Loader";
import { PageSection } from "../../../../components/PageSection";

import { AuctionAnalytics } from "../../types";
import { useFetchLot } from "../../hooks/useFetchLot";
import { ActiveLot } from "../ActiveLot";
import { BidPanel } from "../BidPanel";

import { Header, LoaderContainer, Subtitle } from "./styles";

interface Props {
  auctionAnalytics: AuctionAnalytics;
  lotId: string;
}

const Component = ({ auctionAnalytics, lotId }: Props) => {
  const { data, error, loading } = useFetchLot(lotId);
  const [showSticky, setShowSticky] = useState(false);

  if (loading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  if (error || !data?.lot) return <ErrorNotice error={error} />;

  const {
    lot: {
      bidPriceIncrement,
      biddingEndsAfter,
      bidSet: { edges: bidSetEdges },
      description,
      highestBidPrice,
      lotitemSet: { edges: lotItemEdges },
      lotphotoSet: { edges: lotPhotoEdges },
      minimumNextBidPrice,
      name,
      newBidMinimumDuration,
      order,
      openingPrice,
      state,
    },
  } = data;

  const lotPhoto = lotPhotoEdges[0]?.node?.photoMd ?? "";

  return (
    <ActiveLot
      auctionAnalytics={auctionAnalytics}
      closingPrice={highestBidPrice}
      imageUrl={lotPhoto}
      lotContents={lotItemEdges}
      lotDescription={description}
      lotId={lotId}
      lotName={name}
      lotOrder={order}
      openingPrice={openingPrice}
      setShowSticky={setShowSticky}
      state={state}
    >
      <BidPanel
        auctionAnalytics={auctionAnalytics}
        bidPriceIncrement={bidPriceIncrement}
        bids={bidSetEdges}
        endTime={biddingEndsAfter}
        lotId={lotId}
        lotName={name}
        minimumNextBidPrice={minimumNextBidPrice}
        newBidMinimumDuration={newBidMinimumDuration || 10}
        openingPrice={openingPrice}
        state={state}
        sticky={showSticky}
      />
    </ActiveLot>
  );
};

export const ActiveLotSection = (props: Props) => (
  <PageSection id="active" addMarginBottom>
    <Header>👀 Current lot</Header>
    <Subtitle>The bidding window doesn’t last long, so act fast!</Subtitle>

    <Component {...props} />
  </PageSection>
);
