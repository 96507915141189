import { gql } from "@apollo/client";

const DELETE_PAYMENT_METHOD = gql`
  mutation DELETE_PAYMENT_METHOD($paymentMethodId: String!) {
    deletePaymentMethod(paymentMethodId: $paymentMethodId) {
      paymentMethodId
      errors {
        message
      }
    }
  }
`;
export default DELETE_PAYMENT_METHOD;
