import ErrorNotice from "../../ErrorNotice";
import ItemSaleCarousel from "../../ItemSaleCarousel";
import Loader from "../../Loader";
import Widget, { WidgetThemes } from "./Widget";

const Astronaut = "#2c4d76";
const Havelock = "#4e8ad4";
const DefaultTheme = WidgetThemes.light;
const DefaultThemeColor = Havelock;

const ItemSaleCarouselWidget = ({
  id,
  widgetName,
  title,
  subtitle,
  linkTitle,
  linkUrl,
  theme = DefaultTheme,
  themeColor = DefaultThemeColor,
  loading,
  error,
  children,
}) => {
  return (
    <Widget
      id={id}
      widgetName={widgetName}
      title={title}
      subtitle={subtitle}
      linkTitle={linkTitle}
      linkUrl={linkUrl}
      theme={theme}
      themeColor={themeColor}
      background={Astronaut}
    >
      {error ? (
        <ErrorNotice error={error} />
      ) : loading ? (
        <div
          className="d-flex align-items-center w-100"
          style={{ height: "367px", marginBottom: "24px" }}
        >
          <Loader message={null} className={"text-center flex-fill"} />
        </div>
      ) : (
        <ItemSaleCarousel id={`${id}-carousel`} themeColor={themeColor}>
          {children}
        </ItemSaleCarousel>
      )}
    </Widget>
  );
};

export default ItemSaleCarouselWidget;
