import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify, faTh } from "@fortawesome/free-solid-svg-icons";

const ToggleSection = ({ showGrid, setShowGrid }) => {
  return (
    <div className="d-flex flex-row flex-nowrap justify-content-end ml-3">
      <FontAwesomeIcon
        icon={faTh}
        size="lg"
        className={classNames("mr-3 pointer-cursor text-shout", {
          "text-deselected": !showGrid,
        })}
        onClick={() => setShowGrid(true)}
      />
      <FontAwesomeIcon
        icon={faAlignJustify}
        size="lg"
        className={classNames("pointer-cursor text-shout", {
          "text-deselected": showGrid,
        })}
        onClick={() => setShowGrid(false)}
      />
    </div>
  );
};

export default ToggleSection;
