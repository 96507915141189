import { gql } from "@apollo/client";
import { PLAYER_LISTING_FRAGMENT } from "../fragments";

const GET_MY_WISHLIST_PLAYERS = gql`
  ${PLAYER_LISTING_FRAGMENT}
  query GET_MY_WISHLIST_PLAYERS($first: Int = 24, $cursor: String) {
    myWishlistPlayers(first: $first, after: $cursor)
      @connection(key: "myWishlistPlayers") {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...playerListingFields
          headshotPhoto: headshotPhotoSm
        }
      }
    }
  }
`;

export default GET_MY_WISHLIST_PLAYERS;
