import styled, { css } from "styled-components";

import { Colors } from "../../../../../../themes/Default";

interface BadgeProps {
  type: string;
}

interface ButtonProps {
  light?: boolean;
  danger?: boolean;
}

export const Container = styled.div`
  margin-bottom: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
`;

export const Card = styled.div`
  background-clip: border-box;
  background-color: #fff;
  border: 1px solid rgb(0 0 0/ 12.5%);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 0;
  position: relative;
  width: 100%;
  word-wrap: break-word;
`;

export const CardBody = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 1px;
  padding: 1.25rem;
`;

export const Badge = styled.span<BadgeProps>`
  border-color: 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 3px;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  margin-right: 0.4rem;
  padding: 0.2rem 0.4rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  vertical-align: baseline;
  white-space: nowrap;

  ${({ type }) => {
    switch (type) {
      case "dark":
        return css`
          background-color: ${Colors.Astronaut};
          color: #fff;
        `;
      case "primary":
        return css`
          background-color: ${Colors.Havelock};
          color: #fff;
        `;
      case "secondary":
        return css`
          background-color: ${Colors.Athens};
          color: #212529;
        `;
    }
  }}
`;

export const ButtonContainer = styled.div`
  margin-top: auto;

  &:first-child {
    display: inline-flex;
    position: relative;
    vertical-align: middle;
  }
`;

export const Button = styled.button<ButtonProps>`
  background-color: transparent;
  border: 1px solid rgb(0 0 0 /10%);
  border-radius: 0.25rem;
  color: rgb(44 77 118/ 80%);
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 5px;
  padding: 0.375rem 0.75rem;
  text-align: center;

  ${({ light }) =>
    light &&
    css`
      background: ${Colors.Porcelain}
        linear-gradient(180deg, #f7f8f9, ${Colors.Porcelain}) repeat-x;
      color: #212529;

      :hover {
        background: #d0d2d5
          linear-gradient(180deg, ${Colors.Porcelain}, #d0d2d5) repeat-x;
        border-color: #d0d2d5;
      }
    `}

  ${({ danger }) =>
    danger &&
    css`
      background: #dc3545 linear-gradient(180deg, #e15361, #dc3545) repeat-x;
      border-color: #dc3545;
      color: #fff;

      &:hover {
        background: #b81728 linear-gradient(180deg, #e15361, #b81728) repeat-x;
        border-color: #b81728;
      }
    `}
`;
