import { useEffect } from "react";

import { useMutation } from "@apollo/client";

import GET_CARD_PARALLEL from "../../api/queries/GET_CARD_PARALLEL";
import GET_GRADED_CARD_PARALLEL from "../../api/queries/GET_GRADED_CARD_PARALLEL";
import SELL_ITEMS from "../../api/mutations/SELL_ITEMS";

import Loader from "../Loader";

const StepSubmitting = ({ formData, setFormDataFields, onComplete }) => {
  const [sellItems] = useMutation(SELL_ITEMS, {
    refetchQueries: [
      { query: GET_CARD_PARALLEL, variables: { slug: formData.slug } },
      {
        query: GET_GRADED_CARD_PARALLEL,
        variables: { id: formData.gradedCardParallelId },
      },
    ],
    onCompleted({ sellItems }) {
      setFormDataFields({
        completedSalesCount: sellItems.soldCount || 0,
        completedListingsCount: sellItems.listedCount || 0,
        completedErrors: sellItems.errors || [],
      });
      onComplete(sellItems.errors);
    },
  });

  // Disable dependencies warning as this is setup to be a one-time useEffect
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    sellItems({
      variables: {
        cardParallelId: formData.cardParallelId,
        gradeId: formData.gradeId,
        minimumSalePrice: formData.minimumSalePrice,
        quantity: formData.quantityToSell,
        expiresAfter: formData.expiresAfter,
        closeSellOrders: formData.closeSellOrders,
      },
    });
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return <Loader message={"Creating listings…"} />;
};

export default StepSubmitting;
