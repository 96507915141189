import { Link } from "react-router-dom";

import placeholder from "../../images/placeholder.png";

const GridItem = ({ cardParallel, onClick }) => {
  const { slug, frontSidePhotoSmall, myItemsCount } = cardParallel;

  const linkPath = onClick ? null : `/marketplace/cards/${slug}`;
  const imageSrc = frontSidePhotoSmall || placeholder;

  return (
    <>
      {linkPath && <Link to={linkPath} className="stretched-link" />}

      {!linkPath && onClick && (
        <button
          onClick={onClick}
          className="btn btn-link rounded-0 border-0 stretched-link p-0 m-0"
        />
      )}

      <div className="shadow border overlap-item w-100">
        {myItemsCount > 1 && (
          <span className="badge badge-primary overlap-badge">
            {myItemsCount}x
          </span>
        )}

        <img
          className="img-fluid w-100"
          src={imageSrc}
          alt="Product frontside"
        />
      </div>
    </>
  );
};

export default GridItem;
