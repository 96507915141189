import { gql } from "@apollo/client";

import {
  CARD_PARALLEL_LISTING_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
} from "../../../api/fragments";

export const GET_LOT = gql`
  ${CARD_PARALLEL_LISTING_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}

  query lot($id: ID!) {
    lot(id: $id) {
      id
      name
      description
      openingPrice
      state
      biddingEndedAt
      biddingEndsAfter
      biddingOpenedAt
      highestBidPrice
      bidPriceIncrement
      minimumNextBidPrice
      newBidMinimumDuration
      lotphotoSet {
        edges {
          node {
            photoMd
          }
        }
      }
      highestBid {
        id
        bidPrice
        user {
          id
          username
        }
      }
      bidSet {
        edges {
          node {
            id
            state
            bidPrice
            createdAt
            user {
              id
              username
            }
          }
        }
      }
      lotitemSet {
        edges {
          node {
            id
            gradedCardParallel {
              ...gradedCardParallelFields
              cardParallel {
                ...cardParallelListingFields
              }
              grade {
                ...gradeFields
              }
            }
          }
        }
      }
    }
  }
`;
