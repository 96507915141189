import {
  Button,
  Title,
  ActionsContainer,
  ButtonContainer,
  EditButton,
  Subtitle,
  TitleContainer,
} from "./styles";

interface Props {
  handleAdd: () => void;
  handleEdit: () => void;
}
export const ManagePaymentActions = ({ handleEdit, handleAdd }: Props) => {
  return (
    <ActionsContainer>
      <TitleContainer>
        <Title>Manage payment methods</Title>
        <Subtitle>Add, remove and edit your payment methods.</Subtitle>
      </TitleContainer>
      <ButtonContainer>
        <Button onClick={handleAdd}>+ Add new</Button>
        <EditButton onClick={handleEdit}>Edit</EditButton>
      </ButtonContainer>
    </ActionsContainer>
  );
};
