import styled, { css } from "styled-components";

import { Colors } from "../../../../themes/Default";

export const AddAddressContainer = styled.div<{ addressLength: boolean }>`
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  position: relative;
  width: 100%;

  ${({ addressLength }) =>
    addressLength &&
    css`
      flex-basis: 0;
      flex-grow: 1;
      margin-bottom: 1.5rem;
      max-width: 100%;
    `}
`;

export const AddressButton = styled.button`
  background: ${Colors.Mantis}
    linear-gradient(180deg, #71c074, ${Colors.Mantis}) repeat-x;
  background-color: transparent;
  border: 1px solid transparent;
  border-color: 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0.25rem;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  :hover {
    background: #47a04b linear-gradient(180deg, #62ae66, #47a04b) repeat-x;
    border-color: #439747;
  }
`;

export const Card = styled.div`
  align-items: center;
  background-clip: border-box;
  background-color: #fff;
  border: 1px dashed rgb(0 0 0/ 20%);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-width: 0;
  position: relative;
  width: 100%;
  word-wrap: break-word;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

export const InnerContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  margin-left: auto;
  max-width: 100%;
  min-width: 0;

  h2 {
    color: ${Colors.Astronaut};
    font-weight: bold;
    margin-bottom: 1.5rem;
    margin-right: 0.5rem;
  }
`;

export const Row = styled.div`
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: 50rem) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const InnerCard = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
`;
