import moment from "moment";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { WeeklyView } from "../components/WeeklyView";

export const Upcoming = () => {
  const { date } = useParams();

  const validDate = date && moment(date).isValid();
  const startingDate = validDate ? new Date(date) : new Date();

  return (
    <>
      <Helmet>
        <title>Upcoming | Live Events</title>
      </Helmet>

      <WeeklyView startingDate={startingDate} />
    </>
  );
};
