const CloseButton = ({ onClose }) => {
  return (
    <div className="d-flex flex-row justify-content-center">
      <button className="btn btn-primary px-5" onClick={() => onClose()}>
        Close
      </button>
    </div>
  );
};

const ErrorConfirmation = ({ errors, onClose }) => {
  return (
    <div className="p-3">
      <div className="alert-danger text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">There was a problem</h3>
        {errors.map((error, i) => (
          <p key={i} className="my-1">
            {error.message}
          </p>
        ))}
      </div>
      <CloseButton onClose={() => onClose()} />
    </div>
  );
};

const SuccessConfirmation = ({ onClose }) => {
  return (
    <div className="p-3">
      <div className="alert-primary text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">Success!</h3>
        <p className="mb-3">The expiry date has been extended.</p>
        <CloseButton onClose={() => onClose()} />
      </div>
    </div>
  );
};

const StepComplete = ({ formData, onClose }) => {
  return (
    <>
      {formData.completedErrors && formData.completedErrors.length ? (
        <ErrorConfirmation
          errors={formData.completedErrors}
          onClose={() => onClose()}
        />
      ) : (
        <SuccessConfirmation onClose={() => onClose()} />
      )}
    </>
  );
};

export default StepComplete;
