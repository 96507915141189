import { gql } from "@apollo/client";

const GET_PUBLIC_COLLECTION_VISIBILITY = gql`
  query GET_PUBLIC_COLLECTION_VISIBILITY {
    myAccount {
      username
      isStaff
      userprofile {
        isCollectionPublic
      }
    }
  }
`;

export default GET_PUBLIC_COLLECTION_VISIBILITY;
