import { Modal } from "react-bootstrap";
import classNames from "classnames";

interface Props {
  copy: string;
  showClose: boolean;
  title: string;
}

const Header = ({ copy, showClose, title }: Props) => (
  <Modal.Header className="border-bottom pb-0" closeButton={showClose}>
    <div className={classNames("container-fluid", { "mr-n4": showClose })}>
      <div className="text-center">
        <h3 className="font-weight-bold">{title}</h3>
        <p>{copy}</p>
      </div>
    </div>
  </Modal.Header>
);

export default Header;
