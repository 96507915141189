import { Children, ReactNode } from "react";

import ErrorNotice from "../ErrorNotice";
import EmptySection from "../Breaks/EmptySection";
import Loader from "../Loader";
import { TwitchEmbed } from "../TwitchEmbed";
import { YouTubeEmbed } from "../YouTubeEmbed";

import { CardContainer, Grid, VideoContainer } from "./styles";

interface Props {
  children?: ReactNode;
  emptySectionTitle?: string;
  error?: boolean;
  loading?: boolean;
  twitchChannelName?: string;
  twitchVideoEmbedId?: string;
  youtubeVideoId?: string;
}

export const EventLiveVideo = ({
  children,
  emptySectionTitle = "Nothing right now",
  error = false,
  loading = false,
  twitchChannelName,
  twitchVideoEmbedId,
  youtubeVideoId,
}: Props) => {
  const noVideoAdded = !twitchChannelName && !youtubeVideoId;
  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;
  if (Children.toArray(children).length === 0 || noVideoAdded)
    return <EmptySection title={emptySectionTitle} />;

  return (
    <Grid>
      <VideoContainer>
        {twitchChannelName ? (
          <TwitchEmbed
            id={twitchVideoEmbedId}
            autoplay={true}
            channel={twitchChannelName}
            showChat={false}
          />
        ) : (
          youtubeVideoId && (
            <YouTubeEmbed
              autoplay={true}
              showChat={false}
              videoId={youtubeVideoId}
            />
          )
        )}
      </VideoContainer>

      <CardContainer>{children}</CardContainer>
    </Grid>
  );
};
