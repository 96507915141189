import { useState } from "react";

import ErrorNotice from "../../components/ErrorNotice";
import Loader from "../../components/Loader";
import LoadMoreButton from "../../components/LoadMoreButton";
import EmptySection from "./EmptySection";
import MarketCardParallel from "../../components/MarketCardParallel";

const SearchResults = ({ error, loading, data, fetchMore }) => {
  const [loadingMore, setLoadingMore] = useState(false);

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;

  return (
    <>
      {data.cardParallels.edges.length ? (
        <div className="row no-gutters row-cols-1 row-cols-xl-2 mx-n2">
          {data.cardParallels.edges.map(({ node }) => (
            <div key={node.id} className="col px-2 mb-2 mb-sm-3">
              <MarketCardParallel cardParallel={node} />
            </div>
          ))}
        </div>
      ) : (
        <EmptySection />
      )}

      {data.cardParallels.pageInfo.hasNextPage && (
        <LoadMoreButton
          loadingMore={loadingMore}
          setLoadingMore={setLoadingMore}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: data.cardParallels.pageInfo.endCursor,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                setLoadingMore(false);
                if (!fetchMoreResult) return prev;

                fetchMoreResult.cardParallels.edges = [
                  ...prev.cardParallels.edges,
                  ...fetchMoreResult.cardParallels.edges,
                ];
                return fetchMoreResult;
              },
            })
          }
        />
      )}
    </>
  );
};

export default SearchResults;
