import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const EmptySection = () => {
  return (
    <div className="jumbotron text-center mx-auto">
      <h1 className="display-4">No watchlist items found</h1>
      <p className="h5 mb-3">Want to see some?</p>
      <ul className="list-group h5 mb-5 text-left max-width-30 mx-auto">
        <li className="list-group-item d-flex align-items-center">
          <FontAwesomeIcon icon={faStar} className="mr-3" />
          Click the star icon on a listing you want to follow
        </li>
        <li className="list-group-item d-flex align-items-center">
          <FontAwesomeIcon icon={faEdit} className="mr-3" />
          Adjust your search filters
        </li>
      </ul>
    </div>
  );
};

export default EmptySection;
