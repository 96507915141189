import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import { detailedCardInfo, fullCardFamilyName } from "../../utils/content";
import { displayNameForGrade } from "../../utils/grades";

import GET_GRADED_CARD_PARALLEL_FOR_SELLING from "../../api/queries/GET_GRADED_CARD_PARALLEL_FOR_SELLING";

import Loader from "../Loader";

const StepLoading = ({ formData, setFormDataFields, onLoaded, onFailed }) => {
  const { data, loading, error } = useQuery(
    GET_GRADED_CARD_PARALLEL_FOR_SELLING,
    {
      variables: { id: formData.gradedCardParallelId },
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (error) {
      onFailed(error);
    } else if (data) {
      setFormDataFields({
        cardParallelId: data.gradedCardParallel.cardParallel.id,
        gradeId: data.gradedCardParallel.grade.id,
        cardName: `${fullCardFamilyName(
          data.gradedCardParallel.cardParallel.playerCard,
        )} ${detailedCardInfo(
          data.gradedCardParallel.cardParallel.parallel.name,
          data.gradedCardParallel.cardParallel.playerCard,
          data.gradedCardParallel.cardParallel.serialLimit,
        )} (${displayNameForGrade(data.gradedCardParallel.grade)})`,
        slug: data.gradedCardParallel.cardParallel.slug,
        openBuyOrders: data.gradedCardParallel.openBuyOrders,
        quantityAvailable:
          data.gradedCardParallel.myItemCount -
          data.gradedCardParallel.myOpenSellOrderCount,
      });
      onLoaded(data);
    }
  });

  return loading ? <Loader message={"Checking marketplace…"} /> : <></>;
};

export default StepLoading;
