import { gql } from "@apollo/client";

export const GET_MY_PAYMENT_METHODS = gql`
  query GET_MY_PAYMENT_METHODS {
    myPaymentMethods {
      paymentMethodId
      brand
      lastFourDigits
      expiryMonth
      expiryYear
      defaultPaymentMethod
    }
  }
`;

export default GET_MY_PAYMENT_METHODS;
