import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { css } from "styled-components";

import { logAmplitudeEvent } from "../utils/analytics";
import { MARKETPLACE_EVENTS } from "../utils/analytics/events";

import GET_PLAYER_DETAILS from "../api/queries/GET_PLAYER_DETAILS";

import ErrorNotice from "../components/ErrorNotice";
import Loader from "../components/Loader";
import DetailSection from "../components/PlayerDetail/DetailSection";
import FixtureStatSection from "../components/PlayerDetail/FixtureStatSection";
import PlayerCardsNavBar from "../components/PlayerDetail/NavBar";
import CardSection from "../components/PlayerDetail/CardSection";
import MyCardsSection from "../components/PlayerDetail/MyCardsSection";
import SeasonStatsSection from "../components/PlayerDetail/SeasonStatsSection";
import {
  PageSection,
  PageSectionTypes,
  PageSectionWidths,
} from "../components/PageSection";

const backgroundGradientForTeam = (team) => {
  const backgroundStart =
    team && team.colorPrimary ? `#${team.colorPrimary}` : "#e88343";
  const backgroundEnd =
    team && team.colorSecondary ? `#${team.colorSecondary}` : "#66449d";

  return css`
          linear-gradient(
            180deg,
            rgba(11, 31, 60, 0.1) 0%,
            rgba(11, 31, 60, 0.0) 10%,
            rgba(11, 31, 60, 0.0) 90%,
            rgba(11, 31, 60, 0.1) 100%
          ),
          linear-gradient(
          120deg, ${backgroundStart}, ${backgroundEnd})
        `;
};

const PlayerDetail = () => {
  const { slug } = useParams();

  useEffect(
    () =>
      logAmplitudeEvent(MARKETPLACE_EVENTS.PLAYER.PAGE_VIEWED, { Slug: slug }),
    [slug],
  );

  const [visibleSection, setVisibleSection] = useState("CARDS_AVAILABLE");

  const { data, loading, error, fetchMore } = useQuery(GET_PLAYER_DETAILS, {
    variables: { slug },
  });

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;

  if (!data.player)
    return <p className="mt-3 mx-auto font-weight-bold">Nada</p>;

  const {
    player: {
      name,
      position,
      headshotPhoto,
      currentTeam,
      tradeStats,
      seasonStats,
      fixtureTeamPlayers,
      cardParallels,
      myCardParallels,
    },
  } = data;

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>

      <PageSection removePaddingTop removePaddingBottom borderBottom={false}>
        <DetailSection
          name={name}
          position={position}
          currentTeam={currentTeam}
          headshotPhoto={headshotPhoto}
          tradeStats={tradeStats}
        />
      </PageSection>

      {seasonStats && (
        <PageSection
          borderBottom={false}
          type={PageSectionTypes.foreground}
          width={PageSectionWidths.full}
          background={backgroundGradientForTeam(currentTeam)}
          removePaddingTop
          removePaddingBottom
        >
          <SeasonStatsSection seasonStats={seasonStats} />
        </PageSection>
      )}

      <PageSection removePaddingTop removePaddingBottom>
        <PlayerCardsNavBar
          visibleSection={visibleSection}
          setVisibleSection={setVisibleSection}
          fixtureTeamPlayersCount={fixtureTeamPlayers.totalCount}
        />
      </PageSection>

      <PageSection type={PageSectionTypes.background} borderBottom={false}>
        {visibleSection === "CARDS_AVAILABLE" && (
          <CardSection data={cardParallels} fetchMore={fetchMore} />
        )}
        {visibleSection === "MY_CARDS" && (
          <MyCardsSection data={myCardParallels} fetchMore={fetchMore} />
        )}

        {visibleSection === "GAME_LOG" &&
          fixtureTeamPlayers.totalCount !== 0 && (
            <FixtureStatSection fixtureTeamPlayers={fixtureTeamPlayers} />
          )}
      </PageSection>
    </>
  );
};

export default PlayerDetail;
