import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { Modal } from "react-bootstrap";

import LoadMoreButton from "../../LoadMoreButton";
import Loader from "../../Loader";
import CardParallelContainer from "../../CardParallelContainer";

import { logAmplitudeEvent } from "../../../utils/analytics";
import { MANAGE_COLLECTION_EVENTS } from "../../../utils/analytics/events";
import { financial } from "../../../utils/common";
import {
  productName,
  productNameSuffix,
  pluralize,
} from "../../../utils/content";

import GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION from "../../../api/queries/GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION";

const StepSummary = ({ onFailed, onCancel, onNext, shippingRequestFee }) => {
  useEffect(() => {
    logAmplitudeEvent(
      MANAGE_COLLECTION_EVENTS.SHIP_HOME.SUBMIT_REQUEST.SUMMARY_VIEWED,
    );
  }, []);

  const [loadingMore, setLoadingMore] = useState(false);

  const { data, loading, error, fetchMore } = useQuery(
    GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: "true",
      variables: {
        orderBy: "oldest",
      },
    },
  );

  useEffect(() => {
    if (error) {
      onFailed(error);
    }
  });

  return !loading || !!loadingMore ? (
    <>
      <Modal.Body className="py-0">
        <div className="row bg-light">
          <div className="col p-3 pb-0">
            <p>
              You have selected{" "}
              {pluralize(
                "item",
                data.myInDraftShippingRequestCollection.totalCount,
              )}
              . Shipment for US addresses will cost $
              {financial(shippingRequestFee)}. If shipping internationally,
              additional fees may apply.
            </p>
            {!!data.myInDraftShippingRequestCollection.totalCount && (
              <div>
                {data.myInDraftShippingRequestCollection.edges.map(
                  ({
                    node: {
                      id,
                      item: { cardParallel, grade },
                    },
                  }) => (
                    <CardParallelContainer
                      key={id}
                      id={cardParallel.id}
                      slug={cardParallel.slug}
                      productType={cardParallel.productType}
                      photo={cardParallel.frontSidePhotoSmall}
                      cardProgram={
                        cardParallel.playerCard.cardFamily.program.name
                      }
                      cardFamily={cardParallel.playerCard.cardFamily.name}
                      cardName={productName(cardParallel)}
                      cardSetNumber={productNameSuffix(cardParallel)}
                      parallel={cardParallel.parallel}
                      serialLimit={cardParallel.serialLimit}
                      grade={grade}
                      overflow
                      configInsetChildren
                      showGrade
                      customClasses="mb-sm-2"
                    />
                  ),
                )}
              </div>
            )}

            {data.myInDraftShippingRequestCollection.pageInfo.hasNextPage && (
              <LoadMoreButton
                loadingMore={loadingMore}
                setLoadingMore={setLoadingMore}
                onLoadMore={() =>
                  fetchMore({
                    variables: {
                      cursor:
                        data.myInDraftShippingRequestCollection.pageInfo
                          .endCursor,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      setLoadingMore(false);
                      if (!fetchMoreResult) return prev;

                      fetchMoreResult.myInDraftShippingRequestCollection.edges =
                        [
                          ...prev.myInDraftShippingRequestCollection.edges,
                          ...fetchMoreResult.myInDraftShippingRequestCollection
                            .edges,
                        ];
                      return fetchMoreResult;
                    },
                  })
                }
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container-fluid px-0">
          <div className="mb-3">
            <p className="mb-2">
              By continuing this you confirm these are the items you wish to
              send home.
            </p>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center">
            <button
              className="btn btn-secondary btn-block text-body"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary btn-block font-weight-bold mt-0 ml-3"
              onClick={() => onNext()}
              disabled={!data.myInDraftShippingRequestCollection.totalCount}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal.Footer>
    </>
  ) : (
    <Loader message={"Loading your basket summary..."} />
  );
};
export default StepSummary;
