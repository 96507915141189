import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const WalletContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const WalletDetailContainer = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const WalletLabel = styled.span`
  font-weight: bold;
`;

export const WalletBalance = styled.span`
  align-items: center;
  display: flex;
`;

export const Icon = styled(FontAwesomeIcon)`
  padding: 0.25rem;
`;
export const WalletIcon = styled(Icon)`
  margin-left: 1rem;
  margin-right: 1rem;
`;
