import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const ActionsContainer = styled.div`
  border-bottom: 1px solid #dcddde;
  margin-top: 1.5rem;
  width: 100%;
`;

export const TitleContainer = styled.div`
  margin-left: 1.5rem;
`;

export const Title = styled.h1`
  color: ${Colors.Astronaut};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  text-align: left;
`;

export const Subtitle = styled.p`
  color: ${Colors.Astronaut};
  font-size: 1.125rem;
  line-height: 2rem;
  text-align: left;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1.5rem;

  @media (max-width: 23.44rem) {
    flex-direction: column;
    margin: 0;
    padding-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
  }
`;

export const Button = styled.button`
  background: #fff linear-gradient(135deg, #77a8e5 0%, #4177b9 100%);
  border: 1px solid ${Colors.Havelock};
  border-radius: 0.25rem;
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: bold;
  height: 3rem;
  line-height: 1.2rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
  width: 100%;

  @media (max-width: 23.44rem) {
    margin-right: 0;
    width: 100%;
  }
`;

export const EditButton = styled(Button)`
  background: #fff;
  border: 1px solid #efefef;
  color: ${Colors.Astronaut};
  margin-left: 0.5rem;
  margin-right: 0;

  @media (max-width: 23.44rem) {
    margin-bottom: 1.5rem;
    margin-left: 0;
    margin-top: 1rem;
  }
`;
