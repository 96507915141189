import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import {
  AdditionalCardDetails,
  CardDetails,
  Label,
  LabelCardDetailsExtended,
} from "./styles";

interface Props {
  clearError: () => void;
}
export const CardDetailsInputs = ({ clearError }: Props) => {
  const options = {
    style: {
      base: {
        fontSize: "1rem",
        color: "#32325d",
        fontFamily: "SFProRounded, sans-serif",
        fontWeight: "regular",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "rgb(44, 77, 118, 0.6)",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <CardDetails>
      <Label htmlFor={"card-number"}>
        Card number
        <CardNumberElement
          id={"card-number"}
          className={"input"}
          onChange={clearError}
          onFocus={clearError}
          options={{
            ...options,
            placeholder: "Enter card number...",
          }}
        />
      </Label>
      <AdditionalCardDetails>
        <LabelCardDetailsExtended htmlFor={"card-expiry"}>
          Expiry date (MM/YY)
          <CardExpiryElement
            id={"card-expiry"}
            className={"input"}
            onChange={clearError}
            options={{
              ...options,
              placeholder: "Enter expiry date...",
            }}
          />
        </LabelCardDetailsExtended>
        <LabelCardDetailsExtended htmlFor={"card-cvc"}>
          CVC
          <CardCvcElement
            id={"card-cvc"}
            className={"input"}
            onChange={clearError}
            options={{
              ...options,
              placeholder: "Enter security code...",
            }}
          />
        </LabelCardDetailsExtended>
      </AdditionalCardDetails>
    </CardDetails>
  );
};
