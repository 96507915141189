import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { MetadataParam } from "@stripe/stripe-js";

import { CreateSetupIntent } from "../../../../generated/schema";

import { CREATE_SETUP_INTENT } from "../../api/CREATE_SETUP_INTENT";

import {
  Buttons,
  CardDetailsInputs,
  LegalCheck,
  LegalTerms,
  MakeDefaultToggle,
} from "./components";
import { Error } from "./styles";

interface Props {
  firstPaymentMethod: boolean;
  handleModalClose: () => void;
  setShowSuccess: (showSuccess: boolean) => void;
  isOpen: boolean;
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
}

export const AddCardForm = ({
  firstPaymentMethod,
  handleModalClose,
  setShowSuccess,
  isOpen,
  errorMessage,
  setErrorMessage,
}: Props) => {
  const [agreeTermsAndConditions, setAgreeTermsAndConditions] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const [createSetupIntent] = useMutation<{
    createSetupIntent: CreateSetupIntent;
  }>(CREATE_SETUP_INTENT, {
    onCompleted({ createSetupIntent }) {
      if (createSetupIntent.setupIntentClientSecret) {
        setClientSecret(createSetupIntent.setupIntentClientSecret);
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      createSetupIntent();
      setDefaultPaymentMethod(false);
    }
  }, [createSetupIntent, isOpen]);

  const handleSubmit = async (event: FormEvent, clientSecret: string) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // disable form submission until Stripe has loaded
      return;
    }

    if (!agreeTermsAndConditions) {
      setErrorMessage("You must agree to the terms and conditions");
      return;
    }

    toggleAgreeTermsAndConditions();

    const card = elements.getElement(CardNumberElement);
    const metadata: MetadataParam = defaultPaymentMethod
      ? { default_payment_method: "true" }
      : {};

    if (card) {
      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card,
            metadata,
          },
        },
      );

      if (error) {
        setErrorMessage(error.message || "There was an error");
        setAgreeTermsAndConditions(true);
      } else {
        setupIntent?.status === "succeeded" && setShowSuccess(true);
      }
    }
  };

  const toggleAgreeTermsAndConditions = () => {
    setAgreeTermsAndConditions(!agreeTermsAndConditions);
  };

  const toggleDefaultPaymentMethod = () => {
    setDefaultPaymentMethod(!defaultPaymentMethod);
  };

  const clearError = () => setErrorMessage("");

  return (
    <form onSubmit={(event) => handleSubmit(event, clientSecret)}>
      {errorMessage && <Error>{errorMessage}</Error>}

      <CardDetailsInputs clearError={clearError} />

      {!firstPaymentMethod && (
        <MakeDefaultToggle
          toggleDefaultPaymentMethod={toggleDefaultPaymentMethod}
          defaultPaymentMethod={defaultPaymentMethod}
        />
      )}

      <LegalTerms />

      <LegalCheck
        toggleAgreeTermsAndConditions={toggleAgreeTermsAndConditions}
      />

      <Buttons
        disabled={!stripe || !agreeTermsAndConditions}
        handleModalClose={handleModalClose}
      />
    </form>
  );
};
