import { useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Modal } from "react-bootstrap";

import { logAmplitudeEvent } from "../../utils/analytics";
import { BUYING_EVENTS, SELLING_EVENTS } from "../../utils/analytics/events";

import { EXPIRES_AFTER_OPTIONS } from "../../utils/forms";

const StepEdit = ({ formData, setFormDataFields, onCancel, onNext }) => {
  useEffect(() => {
    if (formData.groupedBidId) {
      logAmplitudeEvent(BUYING_EVENTS.EXISTING_OFFER.EXTEND_EXPIRY.FORM_VIEWED);
    } else if (formData.groupedListingId) {
      logAmplitudeEvent(
        SELLING_EVENTS.EXISTING_LISTING.EXTEND_EXPIRY.FORM_VIEWED,
      );
    }
  }, [formData.groupedBidId, formData.groupedListingId]);

  const formInvalid = () => {
    return !(
      (formData.groupedListingId || formData.groupedBidId) &&
      formData.extendExpiry
    );
  };

  return (
    <Modal.Body className="py-0">
      <div className="container-fluid px-0 my-4">
        <FormControl fullWidth>
          <TextField
            id="expires-after"
            select
            label="Extend expiry date from today by:"
            variant="outlined"
            margin="dense"
            className="mb-3"
            value={formData.extendExpiry}
            onChange={(event) =>
              setFormDataFields({ extendExpiry: event.target.value })
            }
          >
            {EXPIRES_AFTER_OPTIONS.map(
              (option) =>
                option.value !== "NO_EXPIRY" && (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ),
            )}
          </TextField>
        </FormControl>

        <div className="d-flex flex-row justify-content-between align-items-center my-3">
          <button
            className="btn btn-secondary btn-block text-body"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-block font-weight-bold mt-0 ml-3"
            disabled={formInvalid()}
            onClick={() => onNext()}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal.Body>
  );
};

export default StepEdit;
