import { useState } from "react";

import { useMutation } from "@apollo/client";
import moment from "moment";

import { financial } from "../../utils/common";
import { displayNameForGrade } from "../../utils/grades";
import {
  displayNameForProductType,
  productQuantityString,
} from "../../utils/content";
import { logAmplitudeEvent } from "../../utils/analytics";
import { SELLING_EVENTS } from "../../utils/analytics/events";

import SELL_ITEMS from "../../api/mutations/SELL_ITEMS";

import CardParallelGradeContainer from "../CardParallelGradeContainer";
import SummaryDataRow from "../SummaryDataRow";
import ListingListGradeItemMenu from "./ListingListGradeItemMenu";
import BestOffer, { variants as bestOfferVariants } from "./BestOffer";
import ConfirmationDialog from "./ConfirmationDialog";

const ListingListGradeItem = ({
  groupedListing,
  groupedListingClose,
  acceptOfferRefetches,
}) => {
  const {
    id,
    openSellOrderCount,
    createdAt,
    sellorderSet,
    highestBidPrice,
    highestBidCount,
  } = groupedListing;
  const {
    askPrice,
    expiresAfter,
    item: { cardParallel, grade },
  } = sellorderSet.edges[0].node;

  const [showAcceptOfferDialog, setShowAcceptOfferDialog] = useState(false);
  const [acceptingOffer, setAcceptingOffer] = useState(false);
  const acceptOffer = () => {
    setShowAcceptOfferDialog(false);
    setAcceptingOffer(true);
    acceptOfferMutation({
      variables: {
        cardParallelId: cardParallel.id,
        gradeId: grade.id,
        minimumSalePrice: highestBidPrice,
        quantity: 1,
        expiresAfter: "NO_EXPIRY",
        closeSellOrders: true,
      },
    });
  };

  const [acceptOfferMutation] = useMutation(SELL_ITEMS, {
    refetchQueries: acceptOfferRefetches,
    onCompleted({ sellItems }) {
      if (sellItems.errors && sellItems.errors.length > 0) {
        logAmplitudeEvent(SELLING_EVENTS.EXISTING_LISTING.ACCEPT_OFFER.FAILED, {
          Error: sellItems.errors[0],
        });
      } else {
        logAmplitudeEvent(
          SELLING_EVENTS.EXISTING_LISTING.ACCEPT_OFFER.OFFER_ACCEPTED,
        );
      }
      setAcceptingOffer(false);
    },
  });

  return (
    <>
      <CardParallelGradeContainer
        gradeTitle={displayNameForGrade(grade)}
        subtitle={`Listing added ${moment(createdAt).format("MM/DD/YYYY")}`}
        sidebandColor={"#4e8ad4"}
        accessoryView={
          groupedListingClose ? (
            <ListingListGradeItemMenu
              groupedListingId={id}
              groupedListingClose={groupedListingClose}
              groupedListingQuantity={openSellOrderCount}
              refreshQueries={acceptOfferRefetches}
              expiresAfter={expiresAfter}
            />
          ) : null
        }
      >
        <div className="d-flex flex-row flex-wrap align-items-end justify-content-between mt-n2">
          <div className="mt-2" style={{ flex: "2 1 8rem" }}>
            <SummaryDataRow
              summaryDataItems={[
                {
                  label: "Quantity",
                  value: productQuantityString(
                    openSellOrderCount,
                    cardParallel.productType,
                  ),
                  valueColor: "text-shout",
                },
                {
                  label: "Listing expires",
                  value: `${
                    expiresAfter ? moment(expiresAfter).fromNow() : "No expiry"
                  }`,
                  valueColor: "text-shout",
                },
                {
                  label: "Listing price",
                  value: `$${financial(askPrice)}`,
                  valueColor: "text-primary",
                },
              ]}
            />
          </div>

          <div className="mt-2" style={{ flex: "1 0 8rem" }}>
            {!!highestBidPrice && !!highestBidCount ? (
              <BestOffer
                variant={bestOfferVariants.offer}
                quantity={highestBidCount}
                productType={cardParallel.productType}
                price={highestBidPrice}
                onClick={() => setShowAcceptOfferDialog(true)}
                isAccepting={acceptingOffer}
              />
            ) : (
              <div className="text-whisper text-center border rounded px-3 py-2">
                No offers received
              </div>
            )}
          </div>
        </div>
      </CardParallelGradeContainer>
      <ConfirmationDialog
        show={showAcceptOfferDialog}
        titleText={"Accept best offer"}
        bodyText={`Reduce 1 listing from $${financial(
          askPrice,
        )} to $${financial(
          highestBidPrice,
        )} to sell this ${displayNameForProductType(
          cardParallel.productType,
        )}?`}
        confirmText={`Sell ${displayNameForProductType(
          cardParallel.productType,
        )}`}
        onConfirm={acceptOffer}
        onCancel={() => setShowAcceptOfferDialog(false)}
      />
    </>
  );
};

export default ListingListGradeItem;
