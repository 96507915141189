import styled, { css } from "styled-components";

export const Container = styled.div<{ hasImage: boolean }>`
  @media (min-width: 48rem) {
    margin-top: 0;

    ${({ hasImage }) =>
      hasImage &&
      css`
        display: grid;
        grid-column-gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
      `}
  }
`;

export const Heading = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0 0 1rem;
`;

export const Information = styled.div`
  margin-top: 1rem;

  @media (min-width: 48rem) {
    margin-top: 0;
  }
`;

export const Paragraph = styled.p`
  margin: 1rem 0 0;
`;
