import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { logAmplitudeEvent } from "../utils/analytics";
import { MANAGE_COLLECTION_EVENTS } from "../utils/analytics/events";

const SubmitCardsForGrading = () => {
  useEffect(
    () =>
      logAmplitudeEvent(
        MANAGE_COLLECTION_EVENTS.SUBMIT_FOR_GRADING.PAGE_VIEWED,
      ),
    [],
  );

  return (
    <>
      <Helmet>
        <title>Submit For Grading</title>
      </Helmet>

      <div className="row bg-light py-md-5">
        <div
          className="col card shadow p-md-4 mx-auto"
          style={{ maxWidth: "48rem" }}
        >
          <div className="card-body">
            <h2 className="font-weight-bold border-bottom pb-3 mb-4">
              Grading submissions are suspended
            </h2>
            <p>
              We no longer offer a service to submit your cards for grading from
              your StarStock collection.
            </p>
            <p className="mb-0">
              If you have already StarStock cards submitted to graders, these
              will be fulfilled as usual. Any newly submitted requests which
              have not yet been processed will be cancelled and fully refunded.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitCardsForGrading;
