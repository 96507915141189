import { ButtonContainer, CancelButton, SubmitButton } from "./styles";

interface Props {
  handleModalClose: () => void;
  disabled: boolean;
}
export const Buttons = ({ handleModalClose, disabled }: Props) => {
  return (
    <ButtonContainer>
      <CancelButton onClick={handleModalClose}>Cancel</CancelButton>
      <SubmitButton type="submit" disabled={disabled}>
        Submit
      </SubmitButton>
    </ButtonContainer>
  );
};
