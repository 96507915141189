import styled from "styled-components";

import { pluralize } from "../../utils/content";
import { Colors } from "../../themes/Default";

const MySpotsLabel = styled.p`
  position: absolute;
  top: 0rem;
  right: 0rem;
  font-size: 0.875rem;
  font-weight: bold;
  z-index: 1;
  color: white;
  background: ${Colors.Havelock};
  border-radius: 0 0 0 0.5rem;
  margin-bottom: 0;
  z-index: 2;
`;

const BreakMySpotsCountBadge = ({ mySpotsCount }) => {
  const mySpotsLabel =
    mySpotsCount > 0 ? `You bought ${pluralize("spot", mySpotsCount)}` : null;

  return mySpotsLabel ? (
    <MySpotsLabel className="shadow px-3 py-1">{mySpotsLabel}</MySpotsLabel>
  ) : null;
};

export default BreakMySpotsCountBadge;
