import classNames from "classnames";
import { Button } from "react-bootstrap";

import { formatCurrency } from "../../utils/getCurrency";

const BreakSpotListItem = ({
  boxBreak,
  spot,
  spotTypeName,
  mySpot = false,
  handleClick = null,
}) => {
  const priceLabel = formatCurrency(spot.price, spot.priceCurrency);
  const { purchaseOverrideUrl } = boxBreak;

  const renderCta = () => {
    if (!handleClick)
      return (
        <p className="text-speak mb-0 ml-2 mt-2">
          {spot.isPersonalBreakRequest ? "Owned" : priceLabel}
        </p>
      );

    if (purchaseOverrideUrl)
      return (
        <Button
          className="btn btn-lg btn-primary px-4"
          href={purchaseOverrideUrl}
          rel="noreferrer"
          target="_blank"
        >
          {priceLabel}
        </Button>
      );

    return (
      <Button
        className={classNames(
          "btn btn-sm d-block d-sm-inline-block ml-0 ml-sm-2 mt-2 mt-sm-0",
          {
            "btn-primary": !spot.purchasedAt,
            "disabled text-deselected btn-light opacity-50": spot.purchasedAt,
          },
        )}
        disabled={!!spot.purchasedAt}
        onClick={() => handleClick()}
      >
        {priceLabel}
      </Button>
    );
  };

  return (
    <div
      className={classNames("card p-2 h-100", {
        "clickable-card ": !!handleClick,
      })}
    >
      <div
        className={classNames(
          "d-flex flex-column flex-sm-row align-items-end justify-content-between h-100",
        )}
      >
        <div className="d-flex flex-column align-items-start overflow-hidden mw-100 align-self-start">
          <small className="text-whisper">
            {spotTypeName} #{spot.rank}
            {mySpot ? (
              <span className="text-primary text-uppercase ml-2">
                Your spot
              </span>
            ) : spot.purchasedAt ? (
              <span className="text-danger text-uppercase ml-2">Sold</span>
            ) : null}
          </small>

          <p
            className="d-inline-block text-speak mb-0 mw-100"
            style={{ lineHeight: "1.25", opacity: spot.purchasedAt ? 0.5 : 1 }}
          >
            {spot.name || `???`}
          </p>
        </div>

        {renderCta()}
      </div>
    </div>
  );
};

export default BreakSpotListItem;
