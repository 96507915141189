import { gql } from "@apollo/client";

export const CREATE_BID = gql`
  mutation createBid($lotId: ID!, $bidPrice: Float!) {
    bidCreate(lotId: $lotId, bidPrice: $bidPrice) {
      errors {
        field
        message
      }
      bid {
        id
        bidPrice
        state
        createdAt
        user {
          id
          username
        }
      }
      lot {
        biddingEndsAfter
        bidPriceIncrement
        highestBidPrice
        id
        minimumNextBidPrice
        name
        order
      }
    }
  }
`;
