import ToggleSwitch from "../../../../../components/ToggleSwitch";

import { Label, MakeDefaultCard, MakeDefaultCardText } from "./styles";

interface Props {
  defaultPaymentMethod: boolean;
  toggleDefaultPaymentMethod: () => void;
}

export const MakeDefaultToggle = ({
  defaultPaymentMethod,
  toggleDefaultPaymentMethod,
}: Props) => {
  return (
    <MakeDefaultCard>
      <Label htmlFor={"default-payment-method"}>
        <ToggleSwitch
          id={"default-payment-method"}
          checked={defaultPaymentMethod}
          onChange={toggleDefaultPaymentMethod}
          name="defaultPaymentMethod"
        />

        <MakeDefaultCardText>Make default card?</MakeDefaultCardText>
      </Label>
    </MakeDefaultCard>
  );
};
