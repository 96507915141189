import { useState } from "react";

import classNames from "classnames";

import { Menu, MenuItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faTimes } from "@fortawesome/free-solid-svg-icons";
import { rgba } from "polished";
import styled, { css } from "styled-components";

export const SortControlVariants = {
  auto: "auto",
  fixed: "fixed",
  full: "full",
  iconOnly: "iconOnly",
};

export const SortControlPositions = {
  left: "left",
  right: "right",
};

const BorderColor = rgba(0, 0, 0, 0.1);
const PassiveColor = "rgba(44, 77, 118, 0.8)";
const ActiveColor = "#4e8ad4";

const Prefix = styled.span`
  display: inline;
  margin-right: 0.5rem;
  white-space: nowrap;
  font-size: 0.875rem;

  ${(props) =>
    props.configVariant === SortControlVariants.iconOnly &&
    css`
      display: none;
      margin: 0;
    `}

  ${(props) =>
    props.configVariant === SortControlVariants.auto &&
    css`
      @media (max-width: 52rem) {
        display: none;
        margin: 0;
      }
    `}

  ${(props) =>
    props.configVariant === SortControlVariants.fixed &&
    css`
      display: none;
      margin-right: 0;
    `}

  @media (max-width: 23rem) {
    display: none;
    margin: 0;
  }
`;

const Title = styled.span`
  display: inline;
  margin-right: 0.5rem;
  white-space: nowrap;
  font-size: 0.875rem;

  ${(props) =>
    props.configVariant === SortControlVariants.iconOnly &&
    css`
      display: none;
      margin-right: 0;
    `}

  ${(props) =>
    props.configVariant === SortControlVariants.auto &&
    css`
      @media (max-width: 32rem) {
        display: none;
        margin-right: 0;
      }
    `}
`;

const StyledButton = styled.button`
  height: 2.5rem;
  background: white;
  color: ${PassiveColor};
  border: 1px solid ${BorderColor};
  border-radius: 2rem;
  padding: 0 0.75rem;
  width: auto;

  ${(props) =>
    props.configVariant === SortControlVariants.iconOnly &&
    css`
      padding: 0;
      width: 2.5rem;
    `}

  ${(props) =>
    props.configVariant === SortControlVariants.auto &&
    css`
      @media (max-width: 32rem) {
        padding: 0;
        width: 2.5rem;
      }
    `}

  ${(props) =>
    props.configVariant === SortControlVariants.fixed &&
    css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}

  ${(props) =>
    props.active &&
    css`
      color: ${ActiveColor};
      border-color: ${ActiveColor};
    `}
`;

const SortControl = ({
  className,
  sortOptions,
  selectedSortOptionKey,
  setSelectedSortOptionKey,
  sortIsApplied,
  configVariant = SortControlVariants.auto,
  configPosition = SortControlPositions.right,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const sortOrder =
    sortOptions.find((o) => o.key === selectedSortOptionKey) || sortOptions[0];

  return (
    <>
      <div className={"d-flex flex-row align-items-baseline " + className}>
        <Prefix configVariant={configVariant}>Sort by</Prefix>

        <StyledButton
          className="btn flex-shrink-0"
          onClick={openMenu}
          active={sortIsApplied}
          configVariant={configVariant}
        >
          <Title configVariant={configVariant}>{sortOrder.label}</Title>
          <FontAwesomeIcon icon={faSort} />
        </StyledButton>
      </div>

      <Menu
        id="sort-options-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal:
            configPosition === SortControlPositions.left ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal:
            configPosition === SortControlPositions.left ? "left" : "right",
        }}
      >
        <div className="d-flex flex-row flex-nowrap justify-content-between align-items-baseline">
          <h5 className="font-weight-bold px-3 py-2 mb-0">Sort by</h5>
          <button onClick={() => closeMenu()} className="btn btn-link">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {sortOptions.map((sortOption) => (
          <MenuItem
            key={sortOption.key}
            onClick={(e) => {
              setSelectedSortOptionKey(sortOption.key);
              setMenuAnchorEl(null);
            }}
            className={classNames(
              "flex-column align-items-baseline justify-content-center py-2 m-0 border-top",
              {
                "bg-primary": sortOption.key === selectedSortOptionKey,
              },
            )}
          >
            <p
              className={classNames("mb-0", {
                "text-primary": sortOption.key !== selectedSortOptionKey,
                "text-white": sortOption.key === selectedSortOptionKey,
              })}
            >
              {sortOption.label}
            </p>
            {sortOption.description && (
              <small
                className={classNames("mb-0", {
                  "text-speak": sortOption.key !== selectedSortOptionKey,
                  "text-white-50": sortOption.key === selectedSortOptionKey,
                })}
              >
                {sortOption.description}
              </small>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SortControl;
