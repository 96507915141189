import { debounce } from "lodash";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";

import { BidCreatedResponse } from "../types";
import { useUpdateCache } from "./useUpdateCache";

export const usePusher = (pusherChannelId: string | null | undefined) => {
  const [pusherNotConnected, setPusherNotConnected] = useState(false);
  const { addBid, refetchAuction, refetchLot } = useUpdateCache();

  useEffect(() => {
    const { REACT_APP_PUSHER_APP_KEY, REACT_APP_PUSHER_CLUSTER } = process.env;
    const isMissingPusher =
      !REACT_APP_PUSHER_APP_KEY ||
      !REACT_APP_PUSHER_CLUSTER ||
      !pusherChannelId;

    if (isMissingPusher) {
      return setPusherNotConnected(true);
    }

    const pusher = new Pusher(REACT_APP_PUSHER_APP_KEY, {
      cluster: REACT_APP_PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe(pusherChannelId);

    const refetchAuctionAndLot = () => {
      refetchAuction();
      refetchLot();
    };

    const debouncedRefetchAuctionAndLot = debounce(refetchAuctionAndLot, 200, {
      trailing: true,
    });

    channel.bind("pusher:subscription_succeeded", () => refetchAuctionAndLot());
    channel.bind("auction_refetch", () => debouncedRefetchAuctionAndLot());
    channel.bind("bid_created", (data: BidCreatedResponse) => addBid(data));

    setPusherNotConnected(false);

    return function cleanup() {
      channel.unbind();
      pusher.unsubscribe(pusherChannelId);
      pusher.disconnect();
    };
  }, [addBid, pusherChannelId, refetchAuction, refetchLot]);

  return { pusherNotConnected };
};
