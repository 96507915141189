import styled from "styled-components";

const TextSpeakLight = "#ffffffa0";

const StyledSeasonStatsHeader = styled.h4`
  color: ${TextSpeakLight};
  font-size: 0.75rem;
  font-weight: regular;
  text-transform: uppercase;
  text-align: center;
  background: #00000040;
  border-bottom: 1px solid #00000020;
  padding: 0.5rem 0;
  margin-bottom: 0;
`;

const StyledSeasonStatsScroller = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  overflow-x: auto;
  overflow-y: hidden;

  background: #00000010;

  // Allow horizontal scroll but hide scrollbar
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const StyledSeasonStatsScrollerInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;
  padding: 0.5rem 1rem;
`;

const StyledStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  text-align: center;
  align-items: center;

  @media (min-width: 48rem) {
    flex-direction: row;
    margin-left: 2rem;
  }

  &:first-child {
    margin-left: 0;
  }
`;

const StyledStatNameLabel = styled.p`
  color: ${TextSpeakLight};
  font-size: 0.75rem;
  margin-bottom: 0;

  @media (min-width: 48rem) {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`;

const StyledStatValueLabel = styled.p`
  font-size: 1.125rem;
  color: white;
  font-weight: bold;
  margin-bottom: 0;

  @media (min-width: 48rem) {
    font-size: 1.25rem;
  }
`;

const formatStat = (sport, statName, value) => {
  const parsedValue = parseFloat(value);
  const formattedStatName = statName.toUpperCase();
  const isPreciseStat = ["AVG", "OPS", "WHIP"].includes(formattedStatName);

  if (isPreciseStat) {
    if (parsedValue < 1) {
      return parsedValue.toFixed(3).toString().substring(1);
    }

    return parsedValue.toFixed(3);
  }

  if (formattedStatName.includes("W-L")) return value;

  if (formattedStatName.includes("%")) {
    return `${(parsedValue * 100).toFixed(1)}%`;
  }

  if (formattedStatName === "ERA") {
    return parsedValue.toFixed(2);
  }

  return parsedValue;
};

const SeasonStatsSection = ({ seasonStats }) => {
  return (
    <div className="row">
      <div className="col px-0">
        <StyledSeasonStatsHeader>Latest season stats</StyledSeasonStatsHeader>
        <StyledSeasonStatsScroller>
          <StyledSeasonStatsScrollerInner>
            {seasonStats.map((seasonStat, i) => (
              <StyledStatContainer key={i}>
                <StyledStatNameLabel>{seasonStat.statName}</StyledStatNameLabel>
                <StyledStatValueLabel>
                  {formatStat(
                    undefined,
                    seasonStat.statName,
                    seasonStat.statValue,
                  )}
                </StyledStatValueLabel>
              </StyledStatContainer>
            ))}
          </StyledSeasonStatsScrollerInner>
        </StyledSeasonStatsScroller>
      </div>
    </div>
  );
};

export default SeasonStatsSection;
