import { displayNameForGrade } from "../../utils/grades";

import GradeListItem from "./GradeListItem";

import LogoPSA from "../../images/graders/psa-small.png";
import LogoBGS from "../../images/graders/bgs-small.png";
import LogoSGC from "../../images/graders/sgc-small.png";

const logoForGraderString = (graderString) => {
  switch (graderString) {
    case "PSA":
      return LogoPSA;
    case "BGS":
      return LogoBGS;
    case "SGC":
      return LogoSGC;
    default:
      return null;
  }
};

const GradeList = ({
  grades,
  selectedGradeId,
  setSelectedGradeId,
  productType,
  configAlwaysExpanded = false,
}) => {
  return (
    <div className="row">
      {grades.map((grade) => (
        <div key={grade.id} className="col-12 mb-3">
          <GradeListItem
            gradeTitle={displayNameForGrade(grade)}
            gradeDescription={grade.description}
            graderLogo={logoForGraderString(grade.grader)}
            productType={productType}
            totalItemCount={grade.gradedCardParallel.itemCount}
            latestSalePrice={grade.gradedCardParallel.lastSalePrice}
            listingsCount={grade.gradedCardParallel.openSellOrderCount}
            listingsMinPrice={grade.gradedCardParallel.openSellOrderMinPrice}
            bidsCount={grade.gradedCardParallel.openBuyOrderCount}
            bidsMaxPrice={grade.gradedCardParallel.openBuyOrderMaxPrice}
            myItemsCount={grade.gradedCardParallel.myItemCount}
            myListingsCount={grade.gradedCardParallel.myOpenSellOrderCount}
            myBidsCount={
              grade.gradedCardParallel.myOpenBuyOrderCount +
              grade.gradedCardParallel.mySuspendedBuyOrderCount
            }
            selected={grade.id === selectedGradeId}
            setSelected={() => setSelectedGradeId(grade.id)}
            configAlwaysExpanded={configAlwaysExpanded}
          />
        </div>
      ))}
    </div>
  );
};

export default GradeList;
