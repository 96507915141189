import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_LISTING_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
} from "../fragments";

export const GET_CARD_PARALLELS_DEFAULT_PARAMS = {
  searchQuery: "",
  sport: null,
  era: null,
  productType: null,
  grader: null,
  minSerialLimit: null,
  maxSerialLimit: null,
  minItems: 1, // Require 1+ card in vault
  maxItems: null,
  minListings: null,
  maxListings: null,
  minOffers: null,
  maxOffers: null,
  startYear: null,
  endYear: null,
  cardSet: null,
  orderBy: "trending_rank_desc,id",
  first: 48,
};

const GET_CARD_PARALLELS = gql`
  ${CARD_PARALLEL_LISTING_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  query GET_CARD_PARALLELS(
    $featuredResultSet: String
    $searchQuery: String!
    $sport: String
    $era: String
    $productType: String
    $minItems: Decimal
    $maxItems: Decimal
    $minListings: Decimal
    $maxListings: Decimal
    $minOffers: Decimal
    $maxOffers: Decimal
    $startYear: Decimal
    $endYear: Decimal
    $cardSet: String
    $minSerialLimit: Decimal
    $maxSerialLimit: Decimal
    $grader: String
    $orderBy: String!
    $first: Int = 48
    $cursor: String
  ) {
    cardParallels(
      featuredResultSet: $featuredResultSet
      searchQuery: $searchQuery
      sport: $sport
      era: $era
      productType: $productType
      minItems: $minItems
      maxItems: $maxItems
      minListings: $minListings
      maxListings: $maxListings
      minOffers: $minOffers
      maxOffers: $maxOffers
      startYear: $startYear
      endYear: $endYear
      cardSet: $cardSet
      minSerialLimit: $minSerialLimit
      maxSerialLimit: $maxSerialLimit
      grader: $grader
      orderBy: $orderBy
      first: $first
      after: $cursor
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      cardParallelStats {
        totalItemCount
        totalListingCount
      }
      edges {
        node {
          ...cardParallelListingFields
          gradedcardparallelSet(first: 3) {
            totalCount
            edges {
              node {
                ...gradedCardParallelFields
                grade {
                  ...gradeFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_CARD_PARALLELS;
