import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const internalLinks = [
    {
      name: "Careers",
      path: "/careers",
    },
    {
      name: "Terms",
      path: "/terms",
    },
    {
      name: "Privacy",
      path: "/privacy-policy",
    },
    {
      name: "FAQs",
      path: "/faq",
    },
  ];

  const socialLinks = [
    {
      name: "Instagram",
      icon: faInstagram,
      url: "https://www.instagram.com/starstock",
    },
    {
      name: "Twitter",
      icon: faTwitter,
      url: "https://twitter.com/starstock",
    },
  ];

  return (
    <footer className="w-100 mt-auto">
      <div className="d-flex flex-column flex-md-row justify-content-start align-items-center bg-light border-top p-3">
        <div className="small mb-3 mb-md-0">
          Copyright © 2024 StarStock.com. All rights reserved
        </div>

        <div className="small mb-3 mb-md-0">
          {internalLinks.map((link) => (
            <span key={link.name}>
              <span className="text-deselected d-none d-md-inline ml-3">•</span>
              <Link to={link.path} className="text-speak ml-3">
                {link.name}
              </Link>
            </span>
          ))}
        </div>

        <div className="ml-0 ml-md-auto">
          {socialLinks.map((link) => (
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              key={link.name}
              className="text-speak"
            >
              <FontAwesomeIcon icon={link.icon} className="ml-4" />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
