import { Dispatch } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

import GET_REQUEST_BOX_BREAK from "../../../../api/queries/breaks/GET_REQUEST_BOX_BREAK";
import { logAmplitudeEvent } from "../../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../../utils/analytics/events";
import { Action, ErrorDetail } from "../types";

const useFetchPersonalBreaks = (dispatch: Dispatch<Action>) => {
  const location = useLocation();
  const { data, loading, error } = useQuery(GET_REQUEST_BOX_BREAK, {
    fetchPolicy: "network-only",
    onError(err) {
      logAmplitudeEvent(BREAKS_EVENTS.PERSONALS_REQUEST.FAILED, {
        "Error Fields": (err as unknown as ErrorDetail[])?.map((e) => e?.field),
        "Error Messages": (err as unknown as ErrorDetail[])?.map(
          (e) => e?.message,
        ),
      });

      dispatch({ type: "error" });
    },
    onCompleted() {
      const noAvailableBreaks = data.boxBreaks.totalCount === 0;
      const noAvailableBoxes = data.myCollection.edges.length === 0;
      const dataLoadedWithoutError = data && !loading && !error;

      if (noAvailableBoxes) {
        logAmplitudeEvent(BREAKS_EVENTS.PERSONALS_REQUEST.FORM_VIEWED, {
          "Boxes Available Count": data.myCollection.edges.length,
          "Breaks Available Count": data.boxBreaks.totalCount,
          "Page Viewed": location.pathname,
        });

        return dispatch({
          type: "error",
          payload: [
            { message: "You currently have no boxes that can be opened." },
          ],
        });
      }

      if (noAvailableBreaks) {
        logAmplitudeEvent(BREAKS_EVENTS.PERSONALS_REQUEST.FORM_VIEWED, {
          "Boxes Available Count": data.myCollection.edges.length,
          "Breaks Available Count": data.boxBreaks.totalCount,
          "Page Viewed": location.pathname,
        });

        return dispatch({
          type: "error",
          payload: [{ message: "There are no available breaks." }],
        });
      }

      if (dataLoadedWithoutError) {
        logAmplitudeEvent(BREAKS_EVENTS.PERSONALS_REQUEST.FORM_VIEWED, {
          "Boxes Available Count": data.myCollection.edges.length,
          "Breaks Available Count": data.boxBreaks.totalCount,
          "Page Viewed": location.pathname,
        });
        return dispatch({ type: "form" });
      }

      if (error) {
        logAmplitudeEvent(BREAKS_EVENTS.PERSONALS_REQUEST.FAILED, {
          "Error Fields": (error as unknown as ErrorDetail[])?.map(
            (e) => e?.field,
          ),
          "Error Messages": (error as unknown as ErrorDetail[])?.map(
            (e) => e?.message,
          ),
        });
      }

      return dispatch({ type: "error" });
    },
  });

  return { data, error, loading };
};

export default useFetchPersonalBreaks;
