import React, { KeyboardEvent } from "react";

import { EventEventType } from "../../generated/schema";
import PlaceholderBG from "../../images/breaks-logo-color.svg";

import {
  Container,
  Content,
  FeaturedBanner,
  Heading,
  HeroImage,
  HeroImageContainer,
  LowerContent,
  StyledTimeBadge,
  UpperContent,
} from "./styles";

interface Props {
  children?: React.ReactNode;
  event?: EventEventType;
  featuredLabel?: string;
  imageUrl?: string | null | undefined;
  imageBackgroundUrl?: string | null | undefined;
  isFeatured?: boolean;
  onClick(): void;
  scheduledStartTime: Date | null;
  state: string;
  STATES: {
    [key: string]: string;
  };
  subtitle?: string;
  title: string;
}

export const EventCard = ({
  children,
  event,
  featuredLabel = "Featured",
  imageUrl,
  imageBackgroundUrl = PlaceholderBG,
  isFeatured = false,
  onClick,
  scheduledStartTime,
  state,
  STATES,
  subtitle,
  title,
}: Props) => {
  const heroComponents = React.Children.map(children, (child) => {
    if (!child) return null;

    return (child as any).type.displayName.includes("HeroComponents")
      ? child
      : null;
  });

  const lowerComponents = React.Children.map(children, (child) => {
    if (!child) return null;

    return (child as any).type.displayName.includes("LowerComponents")
      ? child
      : null;
  });

  const upperComponents = React.Children.map(children, (child) => {
    if (!child) return null;

    return (child as any).type.displayName.includes("UpperComponents")
      ? child
      : null;
  });

  const handleKeyDown = ({ key }: KeyboardEvent) => {
    if (key === "Enter") onClick();
  };

  const hasLowerComponents = lowerComponents && lowerComponents?.length !== 0;

  return (
    <Container
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <HeroImageContainer backgroundImage={imageBackgroundUrl}>
        {imageUrl && <HeroImage src={imageUrl} alt="" />}
        {isFeatured && <FeaturedBanner>{featuredLabel}</FeaturedBanner>}
        {heroComponents}
      </HeroImageContainer>

      <Content>
        <UpperContent>
          <StyledTimeBadge
            event={event}
            state={state}
            STATES={STATES}
            scheduledStartTime={scheduledStartTime}
          />
          <Heading>
            {subtitle && <span>{subtitle}</span>}
            {title}
          </Heading>

          {upperComponents}
        </UpperContent>

        {hasLowerComponents && <LowerContent>{lowerComponents}</LowerContent>}
      </Content>
    </Container>
  );
};

const HeroComponents = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);
HeroComponents.displayName = "HeroComponents";
EventCard.HeroComponents = HeroComponents;

const LowerComponents = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);
LowerComponents.displayName = "LowerComponents";
EventCard.LowerComponents = LowerComponents;

const UpperComponents = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);
UpperComponents.displayName = "UpperComponents";
EventCard.UpperComponents = UpperComponents;
