import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { object, string } from "yup";

const PasswordResetRequestForm = ({ passwordResetRequest, setShowSuccess }) => {
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={object({
        email: string()
          .email("Email must be a valid email")
          .required("Required")
          .max(254, "Email must be at most 254 characters")
          .trim(),
      })}
      onSubmit={(values) => {
        setShowSuccess(true);
        passwordResetRequest({
          variables: {
            username: values.email,
          },
        });
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <Form>
          <h1 className="card-title h5 my-3">Forgot your password?</h1>

          <p>
            Enter the email address you registered with below to receive
            password reset instructions
          </p>

          <TextField
            id="email"
            name="email"
            type="email"
            label="Email"
            autoComplete="email"
            value={values.email}
            error={errors.email && touched.email}
            helperText={errors.email && touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-2"
          />

          <button
            type="submit"
            className="btn btn-success btn-block btn-lg my-3"
          >
            Send
          </button>

          <Link className="card-link" to="/login">
            Back to Log in
          </Link>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordResetRequestForm;
