import { useNavigate } from "react-router-dom";

import { EventCard } from "../../../../components/EventCard";
import { AuctionState } from "../../../../generated/schema";
import { financial } from "../../../../utils/common";
import { pluralize } from "../../../../utils/content";

import PlaceholderBG from "../../assets/auctions-logo-color.svg";
import { LotCount, PriceLabel, StartingPriceLabel } from "./styles";

interface Props {
  featured?: boolean;
  imageUrl: string | null | undefined;
  lotCount?: number | null;
  lotsRemaining?: number | null;
  openingMaxPrice?: number | null;
  openingMinPrice?: number | null;
  scheduledStartTime: Date | null;
  slug: string;
  state: AuctionState;
  title: string;
}

export const AuctionCard = ({
  featured = false,
  imageUrl,
  lotCount = 0,
  lotsRemaining,
  openingMaxPrice,
  openingMinPrice,
  scheduledStartTime,
  slug,
  state,
  title,
}: Props) => {
  const navigate = useNavigate();

  const formattedMax = `$${financial(openingMaxPrice, 0)}`;
  const formattedMin = `$${financial(openingMinPrice, 0)}`;
  const auctionIsLive =
    state === AuctionState.LIVE && lotsRemaining && lotCount;
  const hasConcluded = [AuctionState.CANCELED, AuctionState.ENDED].includes(
    state,
  );
  const hasOpeningPrices = openingMaxPrice && openingMinPrice;
  const hasDifferentOpeningPrices = openingMaxPrice !== openingMinPrice;
  const maxPriceString = hasDifferentOpeningPrices ? `- ${formattedMax}` : "";

  const renderLotCount = () => {
    if (auctionIsLive) return `${lotsRemaining} / ${lotCount} Lots remaining`;
    if (hasConcluded) return `Included ${pluralize("Lot", lotCount)}`;
    if (lotCount) return `Includes ${pluralize("Lot", lotCount)}`;

    return "Lot lineup to be confirmed";
  };

  return (
    <EventCard
      featuredLabel="Featured Auction"
      imageUrl={imageUrl}
      imageBackgroundUrl={PlaceholderBG}
      isFeatured={featured}
      onClick={() => navigate(`/auctions/${slug}`)}
      scheduledStartTime={scheduledStartTime}
      state={state}
      STATES={AuctionState}
      subtitle="Live Auction"
      title={title}
    >
      <EventCard.UpperComponents>
        <LotCount>{renderLotCount()}</LotCount>
      </EventCard.UpperComponents>

      <EventCard.LowerComponents>
        <StartingPriceLabel>Opening bids</StartingPriceLabel>
        <PriceLabel>
          {hasOpeningPrices
            ? `From ${formattedMin} ${maxPriceString}`
            : "Coming soon…"}
        </PriceLabel>
      </EventCard.LowerComponents>
    </EventCard>
  );
};
