import { useMemo } from "react";
import { BidNodeEdge, BidState, LotState } from "../../../../generated/schema";

import { Bid } from "../Bid";
import { BidPlaceholder } from "../BidPlaceholder";
import { Container } from "./styles";

interface Props {
  bids: (BidNodeEdge | null)[];
  className?: string;
  currentUser?: string | undefined;
  hidden?: boolean;
  openingPrice?: number | null;
  placeholder?: boolean;
  state: LotState;
}

export const BidHistory = ({
  bids,
  className,
  currentUser,
  hidden = false,
  openingPrice,
  placeholder = true,
  state,
}: Props) => {
  const sortedBids = useMemo(
    () =>
      bids
        .filter((bid) => bid?.node?.bidPrice)
        .sort((a, b) => b?.node?.bidPrice! - a?.node?.bidPrice!),
    [bids],
  );

  const showPlaceholder =
    sortedBids.length === 0 && openingPrice && placeholder;
  const lotEndedStates = [LotState.SOLD, LotState.UNSOLD, LotState.CANCELED];

  return (
    <Container className={className} hidden={hidden}>
      {showPlaceholder ? (
        <BidPlaceholder state={state} openingPrice={openingPrice} />
      ) : (
        sortedBids.map((bid, idx) => (
          <Bid
            key={bid?.node?.id}
            amount={bid?.node?.bidPrice}
            bidUser={bid?.node?.user.username || `User #${bid?.node?.user.id}`}
            currentUser={currentUser}
            ended={lotEndedStates.includes(state)}
            state={bid?.node?.state || BidState.SUBMITTED}
            winning={idx === 0 && state !== LotState.UNSOLD}
          />
        ))
      )}
    </Container>
  );
};
