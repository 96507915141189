export const Borders = {
  Live: "1px solid #c9d8f1",
};

export const Colors = {
  // A dark navy. Used as primary text color and dark background color.
  Astronaut: "#2c4d76",

  // This needs a proper name
  Blue: "#2a5dbd",

  // A strong, punchy blue. Suitable for primary actions.
  Havelock: "#4e8ad4",

  // A strong, punchy green. Suitable for primary actions that feel "positive"
  // or "completing".
  Mantis: "#58B55c",

  // A soft, blue-tinged, light grey. Suitable for non-primary actions.
  Athens: "#e1e6ea",

  // A subtle off-white. Suitable for contrasting sections vs. white.
  Porcelain: "#f6f7f8",

  // Used for borders on cards and similar forms, and horizontal line dividers
  Border: "#00000020",
};

export const Gradients = {
  Auctions: "linear-gradient(154.32deg, #FFF1D2 0%, #FFE0F8 100%)",
  Breaks:
    "linear-gradient(#ffffffc0, #ffffffc0), linear-gradient(135deg, #ee9165, #b56ce7)",
};
