import { gql } from "@apollo/client";

const BUY_BOX_BREAK_SPOTS = gql`
  mutation buyBoxBreakSpots(
    $boxBreakId: ID!
    $spotIds: [ID!]!
    $paymentMethod: String!
    $addressId: ID!
    $preferShipHome: Boolean
    $additionalInstructions: String
  ) {
    buyBoxBreakSpots(
      boxBreakId: $boxBreakId
      spotIds: $spotIds
      preferShipHome: $preferShipHome
      additionalInstructions: $additionalInstructions
      paymentMethod: $paymentMethod
      addressId: $addressId
    ) {
      purchasedSpotsCount
      purchasedSpotsCost
      errors {
        field
        message
      }
    }
  }
`;

export default BUY_BOX_BREAK_SPOTS;
