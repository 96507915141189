import { useEffect, useRef, useCallback } from "react";

import styled from "styled-components";
import BillboardChart from "react-billboardjs";
import "billboard.js/dist/billboard.css";

import { financial } from "../../utils/common";

const ChartContainer = styled.div`
  position: relative;
  width: 100%;

  height: 10rem;
  @media (min-width: 36rem) {
    height: 12rem;
  }
  @media (min-width: 48rem) {
    height: 16rem;
  }

  .bb svg {
    font: 9px sans-serif;

    @media (min-width: 36rem) {
      font: 10px sans-serif;
    }
  }

  /* Chart text color (e.g. axis labels) */
  .bb text,
  .bb .bb-button {
    fill: #ffffff;
    opacity: 0.4;
  }

  /* Lighten axis line color */
  .bb path,
  .bb line {
    stroke: #ffffff20;
  }

  /* Lighten axis tick color */
  .bb .tick line {
    stroke: #ffffff20;
  }

  /* Lighten grid line color */
  .bb-grid line {
    stroke: #ffffff11;
  }

  /* Increase line chart stroke width */
  .bb-line {
    stroke-width: 2px;
  }
  .value {
    stroke-width: 2px;
  }
  .invested {
    stroke-width: 1px;
    stroke-dasharray: 4px 2px;
  }

  /* Increase legend font size */
  .bb-legend-item {
    font-size: 12px;
  }
`;

const PortfolioValueChart = ({ portfolioValues, timePeriodUnit }) => {
  // Charts are currently using a package called Billboard.js.
  // Examples: https://naver.github.io/billboard.js/demo/
  // API docs: https://naver.github.io/billboard.js/release/latest/doc/index.html

  // Grab a ref for the chart so we can manually update it after first draw
  const chartRef = useRef();

  // Extract various values of note into arrays for use in chart
  const closeDates = portfolioValues.map((pv) => new Date(pv.closeDate));
  const closeEstimatedValues = portfolioValues.map((pv) => pv.closeValue);
  const closeInvestedValues = portfolioValues.map((pv) => pv.closeInvested);
  const closeRoiValues = portfolioValues.map((pv) => pv.closeRoiValue);

  // Find our max value to display on the Y axis from both value and invested
  const maxCloseEstimatedValue = Math.max(...closeEstimatedValues);
  const maxCloseInvestedValue = Math.max(...closeInvestedValues);
  const maxCloseValue = Math.max(maxCloseInvestedValue, maxCloseEstimatedValue);

  // Floor the max Y to a minimum of $10, or higher based on user data max
  const maxY = Math.max(maxCloseValue, 10);

  // Create a callback method for the date labels for each period unit type
  const dateLabelForTimePeriodUnit = useCallback((date, timePeriodUnit) => {
    return date < Date.now()
      ? date.toLocaleDateString("en-US", { day: "2-digit", month: "short" })
      : "Now";
  }, []);

  // Billboard doesn't always reassign certain customised options when we modify
  // the data it displays. To solve this we manually reassign them and force a
  // redraw when those data values which influence it as modified
  useEffect(() => {
    if (chartRef.current.chart) {
      chartRef.current.chart.axis.max({ y: maxY, y2: maxY });
      chartRef.current.chart.show();
    }
  }, [dateLabelForTimePeriodUnit, timePeriodUnit, maxY]);

  // Billboard uses the left Y axis values to determine where to draw gridlines.
  // But we want the labels on the _right_ Y2 axis, so we need to setup both Y
  // axes the same, so that grid lines align with the Y2 axis ticks and labels.
  const yAxisConfig = {
    show: true,
    min: 0,
    max: maxY,
    padding: {
      top: 8,
      bottom: 0,
    },
    tick: {
      show: false,
      text: {
        show: false,
      },
      culling: {
        max: 8,
      },
    },
  };
  // Override the same y Axis config so this one actually displays
  const y2AxisConfig = {
    ...yAxisConfig,
    ...{
      tick: {
        show: true,
        format: function (value) {
          return value >= 10000
            ? `$${financial(value / 1000, 0)}k`
            : `$${financial(value, 0)}`;
        },
        culling: {
          max: 8,
        },
      },
    },
  };

  return (
    <ChartContainer>
      <BillboardChart
        ref={chartRef}
        className="position-absolute w-100 h-100"
        style={{ top: 0, left: 0 }}
        data={{
          x: "date",
          columns: [
            ["date"].concat(closeDates),
            ["Est. value"].concat(closeEstimatedValues),
            ["Invested"].concat(closeInvestedValues),
            ["Est. ROI"].concat(closeRoiValues),
          ],
          types: {
            "Est. value": "area",
            Invested: "line",
            "Est. ROI": "none",
          },
          colors: {
            "Est. value": "#4e8ad4",
            Invested: "#c0c0c0c0",
            "Est. ROI": "#ffffff",
          },
          axes: {
            "Est. value": "y",
            Invested: "y",
          },
          order: null,
        }}
        point={{
          r: 0,
          type: "circle",
          focus: {
            expand: {
              enabled: true,
              r: 4,
            },
          },
        }}
        line={{
          classes: ["value", "invested"],
        }}
        grid={{
          x: { show: true },
          y: { show: true },
        }}
        area={{
          linearGradient: true,
        }}
        legend={{
          show: false,
        }}
        axis={{
          x: {
            type: "timeseries",
            tick: {
              show: true,
              format: function (date) {
                return date >= Date.now()
                  ? "Now"
                  : date.toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "short",
                    });
              },
              culling: {
                max: 8,
              },
            },
          },
          y: yAxisConfig,
          y2: y2AxisConfig,
        }}
        clipPath={false}
        tooltip={{
          format: {
            value: function (value, ratio, id) {
              return `$${financial(value)}`;
            },
          },
        }}
      />
    </ChartContainer>
  );
};

export default PortfolioValueChart;
