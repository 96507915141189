import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { useFromLocation } from "../hooks/useFromLocation";
import { BackgroundImageWrapper } from "../utils/wrappers";

import logo from "../images/logo-light.svg";

const SignUpSuccess = () => {
  const { from } = useFromLocation();

  return (
    <>
      <Helmet>
        <title>Sign Up Deposit</title>
      </Helmet>

      <div className="container-fluid">
        <BackgroundImageWrapper />

        <div className="row">
          <div className="col">
            <div className="text-center">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid my-5" />
              </Link>
            </div>

            <div className="card mx-auto max-width-26 mb-5">
              <div className="card-body text-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="6x"
                  className="text-mantis mt-5 mb-3"
                />

                <h1 className="card-title h3 mt-3 mb-4">Deposit successful</h1>

                <Link className="card-link" to={from}>
                  <button className="btn btn-primary btn-block btn-lg my-3">
                    Continue
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpSuccess;
