import { PageSection } from "../../PageSection";
import BreakSpotListItem from "../BreakSpotListItem";

const BreakDetailAllSpotsSection = ({
  id,
  allowPurchase = false,
  spots,
  boxBreak,
  mySpots,
  spotTypeName,
  setSelectedSpotIds,
  setShowBuyForm,
}) => {
  const availableSpots = spots.filter((spot) => !spot.purchasedAt);
  const availableSpotIds = availableSpots.map((spot) => spot.id);
  const mySpotIds = mySpots.map((spot) => spot.id);

  const otherSpots = spots.filter(
    (spot) =>
      !mySpotIds.includes(spot.id) && !availableSpotIds.includes(spot.id),
  );

  return (
    <PageSection id={id} addMarginBottom>
      <div className="mb-4">
        <h4 className="font-weight-bold mb-2">Claim your spots</h4>
        <p className="mb-0">
          Grab one spot or take them all. The choice is yours.
        </p>
      </div>

      {mySpots.length ? (
        <>
          <h6 className="text-uppercase font-weight-bold my-3">My spots</h6>
          <div className="row row-cols-2 row-cols-xl-3 mx-n2">
            {mySpots.map((mySpot) => (
              <div className="col px-2 mb-3" key={mySpot.id}>
                <BreakSpotListItem
                  boxBreak={boxBreak}
                  spot={mySpot}
                  spotTypeName={spotTypeName}
                  mySpot={true}
                  handleClick={null}
                />
              </div>
            ))}
          </div>
        </>
      ) : null}

      {availableSpots.length ? (
        <>
          <h6 className="text-uppercase font-weight-bold my-3">
            Available spots
          </h6>
          <div className="row row-cols-2 row-cols-xl-3 mx-n2">
            {availableSpots.map((spot) => (
              <div className="col px-2 mb-3" key={spot.id}>
                <BreakSpotListItem
                  boxBreak={boxBreak}
                  spot={spot}
                  spotTypeName={spotTypeName}
                  handleClick={
                    allowPurchase
                      ? () => {
                          setSelectedSpotIds([spot.id]);
                          setShowBuyForm(true);
                        }
                      : null
                  }
                />
              </div>
            ))}
          </div>
        </>
      ) : null}

      {otherSpots.length ? (
        <>
          <h6 className="text-uppercase font-weight-bold my-3">
            Occupied spots
          </h6>
          <div className="row row-cols-2 row-cols-xl-3 mx-n2">
            {otherSpots.map((spot) => (
              <div className="col px-2 mb-3" key={spot.id}>
                <BreakSpotListItem
                  boxBreak={boxBreak}
                  spot={spot}
                  spotTypeName={spotTypeName}
                  handleClick={null}
                />
              </div>
            ))}
          </div>
        </>
      ) : null}
    </PageSection>
  );
};

export default BreakDetailAllSpotsSection;
