import { useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { PaymentCardDepositForm } from "../PaymentCardDepositForm";

import { ErrorContainer } from "./styles";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = stripePublicKey ? loadStripe(stripePublicKey) : null;

interface Props {
  amount: number;
  successPath: string;
  depositSuccessEventName: string;
  depositFailureEventName: string;
}
export const PaymentCardDepositSection = ({
  amount,
  successPath,
  depositSuccessEventName,
  depositFailureEventName,
}: Props) => {
  const [error, setError] = useState("");

  return (
    <>
      {error && <ErrorContainer role="alert">{error}</ErrorContainer>}

      <Elements stripe={stripePromise}>
        <PaymentCardDepositForm
          amount={amount}
          setError={setError}
          successPath={successPath}
          depositSuccessEventName={depositSuccessEventName}
          depositFailureEventName={depositFailureEventName}
        />
      </Elements>
    </>
  );
};
