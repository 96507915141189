import { ReactNode } from "react";
import { useQuery } from "@apollo/client";

import IS_LOGGED_IN from "../api/queries/IS_LOGGED_IN";

interface Props {
  additionalChecks?: boolean;
  children: ReactNode;
  fallback?: ReactNode;
}

const LoggedIn = ({
  additionalChecks = true,
  children,
  fallback = null,
}: Props) => {
  const { data: loginData } = useQuery(IS_LOGGED_IN);

  const canSeeChildren = additionalChecks && loginData && loginData.isLoggedIn;

  if (canSeeChildren) return <>{children}</>;

  return <>{fallback}</>;
};

export default LoggedIn;
