const EmptySection = ({
  title = "No results",
  subtitle = "Try adjusting your search term or filters",
}) => {
  return (
    <div
      className="jumbotron text-center mx-auto"
      style={{ background: "#00000007" }}
    >
      <h1 className="display-5 text-whisper">{title}</h1>
      {subtitle ? <p className="text-whisper mb-0">{subtitle}</p> : null}
    </div>
  );
};

export default EmptySection;
