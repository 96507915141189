import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import GET_BOX_BREAK from "../../api/queries/breaks/GET_BOX_BREAK";
import IS_LOGGED_IN from "../../api/queries/IS_LOGGED_IN";
import { BOX_BREAK_STATES, BOX_BREAK_TYPE } from "../../api/types";
import BreakDetailBoxesSection from "../../components/Breaks/BreakDetail/BreakDetailBoxesSection";
import BreakDetailAllSpotsSection from "../../components/Breaks/BreakDetail/BreakDetailBuySpotsSection";
import BreakDetailHeaderSection from "../../components/Breaks/BreakDetail/BreakDetailHeaderSection";
import BreakDetailHitsSection from "../../components/Breaks/BreakDetail/BreakDetailHitsSection";
import BreakDetailTargetPlayersSection from "../../components/Breaks/BreakDetail/BreakDetailTargetPlayersSection";
import BuyBreakSpotsForm, {
  BuyBreakSpotsFormSteps,
} from "../../components/Breaks/BuyBreakSpotsForm/BuyBreakSpotsForm";
import RequestBreakForm from "../../components/Breaks/RequestBreakForm";
import LoginModal from "../../components/CardDetails/LoginModal";
import ErrorNotice from "../../components/ErrorNotice";
import { LiveStreamSection } from "../../components/LiveStreamSection";
import Loader from "../../components/Loader";
import { EventEventType } from "../../generated/schema";
import Background from "../../images/backgrounds/vector/live-breaks-pattern-light.svg";
import { logAmplitudeEvent } from "../../utils/analytics";
import { BREAKS_EVENTS } from "../../utils/analytics/events";

const BreakDetail = () => {
  const { slug } = useParams();

  const { data: loggedInData } = useQuery(IS_LOGGED_IN);
  const { data, loading, error } = useQuery(GET_BOX_BREAK, {
    variables: { slug },
  });

  if (loading) return <Loader />;
  if (error || !data.boxBreak) return <ErrorNotice error={error} />;

  return <BreakDetailLoaded data={data} loggedInData={loggedInData} />;
};

const BreakDetailLoaded = ({ data, loggedInData }) => {
  const {
    boxBreak,
    boxBreak: {
      format,
      boxSet,
      spotSet,
      hitSet,
      maxSpotsPerUser,
      targetPlayers,
      myParticipant,
      purchaseOverrideUrl,
    },
  } = data;
  const isRemint = !!purchaseOverrideUrl;
  const isGroupBreak =
    !isRemint && format.breakCategory === BOX_BREAK_TYPE.GROUP;

  useEffect(
    () =>
      logAmplitudeEvent(BREAKS_EVENTS.BREAK.PAGE_VIEWED, {
        "Break Slug": boxBreak.slug,
        "Break Format": boxBreak.format.name,
        "Break State": boxBreak.format.state,
      }),
    [boxBreak],
  );

  const allSpots = spotSet.edges.map(({ node: spot }) => spot);
  const mySpots = myParticipant
    ? myParticipant.spotSet.edges.map(({ node }) => node)
    : [];
  const availableSpots = allSpots.filter((spot) => !spot.purchasedAt);

  const defaultSelectedSpot = availableSpots[0] || allSpots[0];

  const [selectedSpotIds, setSelectedSpotIds] = useState([
    defaultSelectedSpot ? defaultSelectedSpot.id : "",
  ]);

  const selectedSpots = allSpots.filter((spot) =>
    selectedSpotIds.includes(spot.id),
  );

  const [showBuyForm, setShowBuyForm] = useState(false);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const isLoggedIn = loggedInData && loggedInData.isLoggedIn;

  const spotPurchasedCount = myParticipant
    ? myParticipant.spotSet.totalCount
    : 0;
  const canPurchaseSpot =
    !maxSpotsPerUser || spotPurchasedCount < maxSpotsPerUser;
  const allowPurchase =
    canPurchaseSpot &&
    [BOX_BREAK_STATES.PUBLISHED_OPEN, BOX_BREAK_STATES.LIVE_OPEN].includes(
      boxBreak.state,
    );

  return (
    <>
      <Helmet>
        <title>{boxBreak.name}</title>
      </Helmet>

      <BreakDetailHeaderSection
        boxBreak={boxBreak}
        availableSpots={availableSpots}
        selectedSpotIds={selectedSpotIds}
        userPurchasedMaxSpots={!canPurchaseSpot}
        mySpotsCount={mySpots.length}
        setSelectedSpotIds={setSelectedSpotIds}
        setShowBuyForm={setShowBuyForm}
        setShowRequestForm={setShowRequestForm}
      />

      <LiveStreamSection
        autoplay={true}
        backgroundImg={Background}
        event={EventEventType.BREAK}
        eventState={boxBreak.state}
        scheduledStartTime={boxBreak.scheduledStartTime}
        showChat={[
          BOX_BREAK_STATES.STARTED,
          BOX_BREAK_STATES.LIVE_OPEN,
          BOX_BREAK_STATES.LIVE_LOCKED,
        ].includes(boxBreak.state)}
        youtubeVideoId={boxBreak.youtubeVideoId}
        twitchVideoId={boxBreak.twitchVideoId}
        twitchChannelName={boxBreak.twitchChannelName}
      />

      {isGroupBreak && <BreakDetailBoxesSection boxSet={boxSet} />}

      {isGroupBreak && (
        <BreakDetailTargetPlayersSection targetPlayers={targetPlayers} />
      )}

      {allSpots.length > 0 && (
        <BreakDetailAllSpotsSection
          boxBreak={boxBreak}
          id={"buy-spots-section"}
          allowPurchase={allowPurchase}
          spots={allSpots}
          mySpots={mySpots}
          spotTypeName={format.name}
          setSelectedSpotIds={setSelectedSpotIds}
          setShowBuyForm={setShowBuyForm}
        />
      )}

      {isGroupBreak && (
        <BreakDetailHitsSection
          hitSet={hitSet}
          spotTypeName={boxBreak.format.name}
        />
      )}

      {showBuyForm && isLoggedIn ? (
        <BuyBreakSpotsForm
          show={!!showBuyForm}
          setShow={setShowBuyForm}
          initialStep={BuyBreakSpotsFormSteps.loading}
          boxBreak={boxBreak}
          boxBreakId={boxBreak.id}
          boxBreakName={boxBreak.name}
          availableSpots={availableSpots}
          selectedSpots={selectedSpots}
          preferShipHome={
            myParticipant ? myParticipant.preferShipHome : "false"
          }
          additionalInstructions={
            myParticipant ? myParticipant.additionalInstructions : ""
          }
          onComplete={() => {
            setSelectedSpotIds([]);
          }}
        />
      ) : (
        <LoginModal show={showBuyForm} setShow={setShowBuyForm} />
      )}

      {showRequestForm && isLoggedIn ? (
        <RequestBreakForm
          boxBreakId={boxBreak.id}
          setShow={setShowRequestForm}
          show={showRequestForm}
        />
      ) : (
        <LoginModal show={showRequestForm} setShow={setShowRequestForm} />
      )}
    </>
  );
};

export default BreakDetail;
