import styled, { keyframes, css } from "styled-components";
import { LoadingButton } from "../../../../components/LoadingButton";

import { Colors, Gradients } from "../../../../themes/Default";
import { BidHistory } from "../BidHistory";
import { Countdown } from "../Countdown";

interface StickyProps {
  sticky: boolean;
}

interface InputProps {
  hasInput: boolean;
}

const borderRadius = "0.5rem";
const borderColor = "#C410A32D";
const gradientEnd = "#D94827";
const gradientStart = "#E4AB30";
const panelInsetBackground = "#00000010";
const acceptedColor = "#a500d6";
const extendedColor = Colors.Astronaut;
const failedColor = "#b22415";

const animateSubtitleIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
`;

const animateToast = keyframes`
  0% {
    opacity: 0;
    transform: scale(3);
  }

  20% {
    opacity: 1;
    transform: scale(1);
  }

  80% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(3);
  }
`;

export const BidButton = styled(LoadingButton)`
  align-items: center;
  background: linear-gradient(167.2deg, ${gradientStart}, ${gradientEnd});
  color: white;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const BidAcceptedMessage = styled.span`
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-name: ${animateSubtitleIn};
  animation-timing-function: ease-in-out;
  color: ${acceptedColor};
  display: inline-block;
  opacity: 0;
`;

export const BiddingExtendedMessage = styled.span`
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-name: ${animateSubtitleIn};
  animation-timing-function: ease-in-out;
  color: ${extendedColor};
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0.875rem;
  margin-left: 1rem;
  opacity: 0;
`;

export const Container = styled.div<StickyProps>`
  background: ${Gradients.Auctions};
  border: 1px solid ${borderColor};
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  bottom: 0;
  box-shadow: 0 0.25rem 1rem rgb(0 0 0 / 25%);
  left: 0;
  overflow: hidden;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 10;

  @media (min-width: 66rem) {
    border-radius: ${borderRadius};
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    ${({ sticky }) =>
      sticky &&
      css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: auto;
        width: 24rem;
      `}
  }
`;

export const Controls = styled.div`
  margin-top: 0.5rem;
`;

export const ControlsPlaceholder = styled.div`
  background: ${panelInsetBackground};
  border-radius: ${borderRadius};
  padding: 0.75rem;
  text-align: center;
`;

export const CustomBidEntry = styled.div`
  border-radius: ${borderRadius};
  box-shadow: 0 0.04rem 0.3rem rgb(0 0 0 / 25%);
  display: flex;
  flex-grow: 3;
  margin-right: 0.5rem;
`;

export const ErrorMessage = styled.span`
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-name: ${animateSubtitleIn};
  animation-timing-function: ease-in-out;
  color: ${failedColor};
  display: inline-block;
  opacity: 0;
`;

export const Form = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderWrapper = styled.div`
  align-items: center;
`;

export const Input = styled.input<InputProps>`
  border: none;
  border-bottom-right-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  height: 100%;
  padding: ${(props) =>
    props.hasInput ? "0.25rem 0.75rem 0.25rem 1.5rem" : "0.25rem 0.75rem"};
  text-align: left;
  width: 100%;
`;

export const InputWrapper = styled.div<InputProps>`
  flex-grow: 1;
  position: relative;

  ${(props) =>
    props.hasInput &&
    css`
      ::after {
        color: ${Colors.Astronaut}a0;
        content: "$";
        font-size: 1rem;
        font-weight: bold;
        left: 0.75rem;
        position: absolute;
        top: 0.75rem;
      }
    `}
`;

export const SetPriceButton = styled.button`
  background: #a5cfce;
  border: none;
  border-radius: ${borderRadius};
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  color: ${Colors.Astronaut};
  flex-shrink: 0;
  font-weight: bold;
  padding: 0.25rem 1rem;
`;

export const StyledBidHistory = styled(BidHistory)<StickyProps>`
  background: ${panelInsetBackground};
  border-radius: ${borderRadius};
  height: 100%;
  margin-top: 1rem;
  padding: 0.75rem;

  @media (min-width: 66rem) {
    flex-grow: 1;
    overflow: auto;
  }

  & > *:not(:first-child) {
    display: none;

    ${({ sticky }) =>
      !sticky &&
      css`
        @media (min-width: 66rem) {
          display: flex;
        }
      `}
  }
`;

export const StyledCountdown = styled(Countdown)`
  margin-left: 0.5rem;
  width: 4.375rem;

  @media (min-width: 66rem) {
    width: 6.5rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SubtitleContainer = styled.div`
  align-items: baseline;
  display: flex;
  height: 1.5rem;
  justify-content: space-between;
  margin-top: 0.25rem;
  max-height: 1.5rem;
  overflow: hidden;
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h3`
  flex-grow: 1;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0;

  @media (min-width: 66rem) {
    font-size: 1.5rem;
  }
`;

export const Gif = styled.img`
  height: 3rem;
  width: 5rem;
`;

export const Congratulations = styled.p`
  font-weight: bold;
  margin-bottom: -0.2rem;
  margin-top: 0.25rem;
`;

export const Paragraph = styled.p`
  margin-bottom: 0;
`;

export const Toast = styled.div`
  align-items: center;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-name: ${animateToast};
  animation-timing-function: ease-in-out;
  background-color: rgb(255 255 255 / 90%);
  border-radius: 0.5rem;
  color: ${Colors.Astronaut};
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: center;
  padding: 1rem;
  position: absolute;
  text-align: center;
  width: 100%;
`;
