import { useFormik } from "formik";
import { object, string } from "yup";

import { AddressValues } from "../../../../types";
import {
  Button,
  ButtonContainer,
  CheckboxContainer,
  Fields,
  FormCheckbox,
  FormField,
  Label,
  Paragraph,
  PostalRegion,
} from "./styles";

const EMPTY_VALUES: AddressValues = {
  id: "",
  description: "",
  name: "",
  line1: "",
  line2: "",
  locality: "",
  region: "",
  postalCode: "",
  country: "",
};

interface Props {
  values?: AddressValues;
  onSubmit(address: AddressValues): Promise<void>;
  onCancel?(): void;
  onSubmitForm?(): void;
  isSubmitting?: boolean;
  setSubmitting(isSubmitting: boolean): void;
  buttonLabel: string;
  cancelButtonLabel?: string;
  serverError: boolean;
  checkBoxValue?: boolean;
  handleCheckbox?(): void;
}

export const AddressForm = ({
  values,
  onSubmit,
  onCancel,
  onSubmitForm,
  isSubmitting,
  setSubmitting,
  buttonLabel,
  cancelButtonLabel,
  serverError,
  checkBoxValue,
  handleCheckbox,
}: Props) => {
  const formik = useFormik({
    initialValues: values || EMPTY_VALUES,
    validationSchema: object({
      description: string().required("Required").trim(),
      name: string().required("Required").trim(),
      line1: string().required("Required").trim(),
      line2: string().trim(),
      locality: string().required("Required").trim(),
      region: string().required("Required").trim(),
      postalCode: string().required("Required").trim(),
      country: string().required("Required").trim(),
    }),
    onSubmit: (values, actions) => {
      setSubmitting(true);

      onSubmit(values).then(() => {
        actions.resetForm({
          values: EMPTY_VALUES,
        });

        if (onSubmitForm) onSubmitForm();
      });
    },
  });

  const fieldId = `${formik.values.id}`;
  const areAllValuesPresent =
    formik.values.name &&
    formik.values.description &&
    formik.values.line1 &&
    formik.values.locality &&
    formik.values.country &&
    formik.values.postalCode &&
    formik.values.region;

  return (
    <form onSubmit={formik.handleSubmit}>
      {serverError && (
        <Paragraph>There was an error with your submission.</Paragraph>
      )}

      <Fields>
        <FormField
          id="description"
          name="description"
          label="Description"
          value={formik.values.description}
          error={
            (formik.errors.description as unknown as boolean) &&
            (formik.touched.description as boolean)
          }
          helperText={formik.touched.description && formik.errors.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter text here..."
          variant="outlined"
          fullWidth
          margin="dense"
        />

        <FormField
          id="name"
          name="name"
          label="Full Name"
          value={formik.values.name}
          error={
            (formik.errors.name as unknown as boolean) &&
            (formik.touched.name as boolean)
          }
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter text here..."
          variant="outlined"
          fullWidth
          margin="dense"
        />

        <FormField
          id="line1"
          name="line1"
          label="Address line 1"
          value={formik.values.line1}
          error={
            (formik.errors.line1 as unknown as boolean) &&
            (formik.touched.line1 as boolean)
          }
          helperText={formik.touched.line1 && formik.errors.line1}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter text here..."
          variant="outlined"
          fullWidth
          margin="dense"
        />

        <FormField
          id="line2"
          name="line2"
          label="Address line 2"
          value={formik.values.line2}
          error={
            (formik.errors.line2 as unknown as boolean) &&
            (formik.touched.line2 as boolean)
          }
          helperText={formik.touched.line2 && formik.errors.line2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter text here..."
          variant="outlined"
          fullWidth
          margin="dense"
        />

        <FormField
          id="locality"
          name="locality"
          label="City"
          value={formik.values.locality}
          error={
            (formik.errors.locality as unknown as boolean) &&
            (formik.touched.locality as boolean)
          }
          helperText={formik.touched.locality && formik.errors.locality}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter text here..."
          variant="outlined"
          fullWidth
          margin="dense"
        />

        <PostalRegion>
          <FormField
            id="region"
            name="region"
            label="State"
            value={formik.values.region}
            error={
              (formik.errors.region as unknown as boolean) &&
              (formik.touched.region as boolean)
            }
            helperText={formik.touched.region && formik.errors.region}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter text here..."
            variant="outlined"
            fullWidth
            margin="dense"
          />

          <FormField
            id="postalCode"
            name="postalCode"
            label="Zip code"
            value={formik.values.postalCode}
            error={
              (formik.errors.postalCode as unknown as boolean) &&
              (formik.touched.postalCode as boolean)
            }
            helperText={formik.touched.postalCode && formik.errors.postalCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter text here..."
            variant="outlined"
            fullWidth
            margin="dense"
          />
        </PostalRegion>

        <FormField
          id="country"
          name="country"
          label="Country"
          value={formik.values.country}
          error={
            (formik.errors.country as unknown as boolean) &&
            (formik.touched.country as boolean)
          }
          helperText={formik.touched.country && formik.errors.country}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter text here..."
          variant="outlined"
          fullWidth
          margin="dense"
        />
      </Fields>

      <CheckboxContainer>
        <FormCheckbox
          id={fieldId}
          onChange={handleCheckbox}
          checked={checkBoxValue}
          name="saveAddress"
        />
        <Label htmlFor={fieldId}>
          Save this address for future transactions?
        </Label>
      </CheckboxContainer>

      <ButtonContainer>
        {onCancel && (
          <Button cancel={true} onClick={onCancel}>
            {cancelButtonLabel}
          </Button>
        )}

        <Button type="submit" disabled={!areAllValuesPresent}>
          {buttonLabel}
        </Button>
      </ButtonContainer>
    </form>
  );
};
