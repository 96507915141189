import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { object, string } from "yup";

const LoginForm = ({ login, isSubmitting, setSubmitting }) => {
  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validationSchema={object({
        username: string()
          .required("Required")
          .max(150, "Username is at most 150 characters")
          .trim(),
        password: string()
          .required("Required")
          .max(128, "Password is at most 128 characters")
          .trim(),
      })}
      onSubmit={(values) => {
        setSubmitting(true);
        login({
          variables: {
            username: values.username,
            password: values.password,
          },
        });
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <Form>
          <TextField
            id="username"
            name="username"
            label="Email or username"
            autoComplete="username"
            value={values.username}
            error={errors.username && touched.username}
            helperText={errors.username && touched.username && errors.username}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-2"
          />

          <TextField
            id="password"
            name="password"
            type="password"
            label="Password"
            autoComplete="current-password"
            value={values.password}
            error={errors.password && touched.password}
            helperText={errors.password && touched.password && errors.password}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-2"
          />

          <Link className="card-link" to="/account/password-reset">
            <small>Forgotten your password?</small>
          </Link>

          <p className="mt-4">
            We have updated our{" "}
            <a target="_blank" href="/terms">
              Terms of Service
            </a>
            . By logging in you are agreeing to the updated terms.
          </p>

          <button
            type="submit"
            className="btn btn-success btn-block btn-lg my-3"
            disabled={isSubmitting}
          >
            Log in
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
