import styled from "styled-components";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { IndexSummaryStats } from "./IndexSummaryStats";
import IndicesChart from "./IndicesChart";

const Astronaut = "#2c4d76";

const TitleContainer = styled.div`
  color: ${Astronaut};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #00000010;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 0.5rem;
  @media (min-width: 28rem) {
    font-size: 1.25rem;
  }
`;

const LearnMorePopover = (
  <Popover id="learn-more-popover">
    <Popover.Content>
      <p>This index is now archived and is no longer updated.</p>
      <p>
        StarStock indices track each weeks&apos; latest sale prices across a
        portfolio of related cards.
      </p>
      <p className="mb-0">
        The index has an overall value based on a calculation from the card
        prices, giving an overview of how the index as a whole has performed.
      </p>
    </Popover.Content>
  </Popover>
);

const IndexSummary = ({ marketIndex }) => {
  const { latestSnapshot } = marketIndex;

  return (
    <>
      <TitleContainer>
        <Title>Index summary</Title>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          overlay={LearnMorePopover}
          placement={"auto"}
          flip
        >
          <button className="btn btn-sm btn-link p-0 ml-2">
            Learn more
            <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
          </button>
        </OverlayTrigger>
      </TitleContainer>

      <div className="row">
        <div className="col-md mb-3 mb-md-0">
          <p className="text-speak">
            Last updated {moment(latestSnapshot.date).format("ddd Do MMM")}
          </p>
          <IndexSummaryStats marketIndex={marketIndex} />
        </div>
        <div className="col-md">
          <IndicesChart indices={[marketIndex]} />
        </div>
      </div>
    </>
  );
};

export default IndexSummary;
