import styled from "styled-components";

import { financial } from "../../../utils/common";

const ErrorConfirmation = ({ errors, onClose }) => {
  return (
    <div className="p-3">
      <div className="alert alert-danger text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">There was a problem</h3>
        {errors.map((error, i) => (
          <p key={i} className="my-1">
            {error.message}
          </p>
        ))}
      </div>

      <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary px-5" onClick={() => onClose()}>
          Close
        </button>
      </div>
    </div>
  );
};

const GradientStart = "#d5784a";
const GradientEnd = "#9544cd";

const SuccessAlert = styled.div`
  padding: 1rem;
  background: linear-gradient(135deg, ${GradientStart}, ${GradientEnd});
`;

const SuccessConfirmation = ({
  purchasedSpotsCount,
  purchasedSpotsCost,
  onClose,
}) => {
  return (
    <div className="p-3">
      <SuccessAlert className="alert alert-primary text-center p-4">
        <h3 className="text-white font-weight-bold">
          You bought {purchasedSpotsCount === 1 ? "a" : purchasedSpotsCount}{" "}
          spot for ${financial(purchasedSpotsCost)}.
        </h3>
        <p className="text-white mb-0">
          The live stream will be available on the break page shortly before the
          scheduled start time. See you there!
        </p>
      </SuccessAlert>

      <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary px-5" onClick={() => onClose()}>
          Continue
        </button>
      </div>
    </div>
  );
};

const StepComplete = ({ formData, onClose }) => {
  return (
    <>
      {formData.completedErrors && formData.completedErrors.length ? (
        <ErrorConfirmation
          errors={formData.completedErrors}
          onClose={() => onClose()}
        />
      ) : (
        <SuccessConfirmation
          purchasedSpotsCount={formData.purchasedSpotsCount}
          purchasedSpotsCost={formData.purchasedSpotsCost}
          onClose={() => onClose()}
        />
      )}
    </>
  );
};

export default StepComplete;
