export const PRODUCT_TYPES = {
  CARD: "CARD",
  BOX: "BOX",
};

export const BOX_BREAK_STATES = {
  DRAFT: "DRAFT",
  DELETED_DRAFT: "DELETED_DRAFT",
  PUBLISHED_LOCKED: "PUBLISHED_LOCKED",
  PUBLISHED_OPEN: "PUBLISHED_OPEN",
  LIVE_OPEN: "LIVE_OPEN",
  LIVE_LOCKED: "LIVE_LOCKED",
  ENDED: "ENDED",
  SETTLED: "SETTLED",
  CANCELED: "CANCELED",
};

export const BOX_BREAK_STATE_GROUPS = {
  PUBLISHED: "PUBLISHED",
  UPCOMING: "UPCOMING",
  LIVE: "LIVE",
  CONCLUDED: "CONCLUDED",
};

export const BOX_BREAK_TYPE = {
  GROUP: "GROUP",
  PERSONAL: "PERSONAL",
};
