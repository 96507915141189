import { Modal } from "react-bootstrap";
import classNames from "classnames";

import { detailedCardInfo, fullCardFamilyName } from "../../utils/content";

const ModalHeaderSection = ({
  title,
  gradeName,
  playerCard,
  parallelName,
  serialLimit,
  showClose,
}) => {
  return (
    <Modal.Header className="border-bottom pb-0" closeButton={showClose}>
      <div className={classNames("container-fluid", { "mr-n4": showClose })}>
        <div className="text-center">
          <h3 className="font-weight-bold">{title}</h3>
          <p className="mb-0">{fullCardFamilyName(playerCard)}</p>
          <p>
            {detailedCardInfo(parallelName, playerCard, serialLimit)} (
            {gradeName})
          </p>
        </div>
      </div>
    </Modal.Header>
  );
};
export default ModalHeaderSection;
