import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";

import LabelTooltip, { LabelTooltipVariants } from "./LabelTooltip";

export const SummaryDataItemWidths = {
  narrow: "5rem", // Supports values to $9999.99 before truncation
  regular: "5.75rem", // Supports values up to $99999.99 before truncation
  wide: "8rem",
  auto: "auto",
};

export const SummaryDataItemThemes = {
  light: "light",
  dark: "dark",
};

const StyledSummaryDataItem = styled.div`
  width: ${(props) => props.width};
  margin-right: ${(props) => props.configSpacing || "0.5rem"};

  @media (min-width: 36rem) {
    margin-right: ${(props) => props.configSpacing || "1rem"};
  }

  &:last-child {
    margin-right: 0;
  }
`;

const SummaryDataItemSkeleton = ({ textAlignment = "text-left" }) => {
  return (
    <div className={textAlignment}>
      <Skeleton variant="text">
        <p className="small mb-0">Labelstring</p>
      </Skeleton>
      <Skeleton variant="text">
        {" "}
        <p className="mb-0 mt-n1">Value</p>
      </Skeleton>
    </div>
  );
};

const StyledValueLabelPrefix = styled.span`
  margin-right: 0.25rem;
`;

const StyledTitleLabel = styled.span`
  font-size: 0.75rem;

  color: ${(props) =>
    props.configTheme === SummaryDataItemThemes.dark ? "#ffffff" : "#2c4d76"};
  opacity: ${(props) =>
    props.configTheme === SummaryDataItemThemes.dark ? 0.6 : 0.8};
`;

const StyledTitleLabelSuffix = styled.span`
  font-size: 0.625rem;
  margin-left: 0.25rem;

  opacity: ${(props) =>
    props.configTheme === SummaryDataItemThemes.dark ? 0.4 : 0.6};
`;

const StyledValueLabel = styled.span`
  font-size: 1rem;
  font-weight: bold;

  color: ${(props) =>
    props.configTheme === SummaryDataItemThemes.dark ? "#ffffff" : "#2c4d76"};
`;

const StyledValueLabelSuffix = styled.span`
  font-size: 0.875rem;
  margin-left: 0.25rem;

  opacity: ${(props) =>
    props.configTheme === SummaryDataItemThemes.dark ? 0.4 : 0.6};
`;

const SummaryDataItem = ({
  label,
  labelSuffix,
  labelTooltip,
  valuePrefix,
  value,
  valueSuffix,
  valueColor,
  textAlignment = "text-left",
  width = SummaryDataItemWidths.auto,
  configTheme = SummaryDataItemThemes.light,
  configValuesFirst = false,
  configSpacing,
}) => {
  const titleLabel = (
    <StyledTitleLabel className={`text-truncate`} configTheme={configTheme}>
      {label}
      {labelSuffix && (
        <StyledTitleLabelSuffix>{labelSuffix}</StyledTitleLabelSuffix>
      )}
      {labelTooltip && (
        <LabelTooltip
          className="ml-1"
          tooltipContent={labelTooltip}
          variant={
            configTheme === SummaryDataItemThemes.light
              ? LabelTooltipVariants.light
              : LabelTooltipVariants.dark
          }
        />
      )}
    </StyledTitleLabel>
  );

  const valueLabel = (
    <StyledValueLabel
      className={`text-truncate ${valueColor}`}
      configTheme={configTheme}
    >
      {valuePrefix && (
        <StyledValueLabelPrefix configTheme={configTheme}>
          {valuePrefix}
        </StyledValueLabelPrefix>
      )}
      {value}
      {valueSuffix && (
        <StyledValueLabelSuffix configTheme={configTheme}>
          {valueSuffix}
        </StyledValueLabelSuffix>
      )}
    </StyledValueLabel>
  );

  return (
    <StyledSummaryDataItem
      className={`d-flex flex-column ${textAlignment} text-nowrap mt-2`}
      width={width}
      configSpacing={configSpacing}
    >
      {!configValuesFirst && titleLabel}
      {valueLabel}
      {configValuesFirst && titleLabel}
    </StyledSummaryDataItem>
  );
};

export const SummaryDataRowSkeleton = ({
  configAlignItems = "justify-content-start",
}) => {
  return (
    <div
      className={`d-flex flex-row flex-wrap ${configAlignItems} mt-n1 mb-n1`}
    >
      <SummaryDataItemSkeleton />
    </div>
  );
};

const SummaryDataRow = ({
  summaryDataItems,
  configTheme = SummaryDataItemThemes.light,
  configAlignItems = "justify-content-start",
  configValuesFirst = false,
  configSpacing,
  className,
}) => {
  return (
    <div
      className={`d-flex flex-row flex-wrap ${configAlignItems} mt-n2 mb-n1 ${className}`}
    >
      {summaryDataItems.map((summaryDataItem, index) => (
        <SummaryDataItem
          key={index}
          label={summaryDataItem.label}
          labelSuffix={summaryDataItem.labelSuffix}
          labelTooltip={summaryDataItem.labelTooltip}
          valuePrefix={summaryDataItem.valuePrefix}
          value={summaryDataItem.value}
          valueSuffix={summaryDataItem.valueSuffix}
          valueColor={summaryDataItem.valueColor}
          textAlignment={summaryDataItem.textAlignment}
          width={summaryDataItem.width}
          configTheme={summaryDataItem.configTheme || configTheme}
          configValuesFirst={
            summaryDataItem.configValuesFirst || configValuesFirst
          }
          configSpacing={configSpacing}
        />
      ))}
    </div>
  );
};

export default SummaryDataRow;
