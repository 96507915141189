import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NavBar = ({
  showWatchlistItems,
  wishlistItemCount,
  wishlistPlayerCount,
}) => {
  const navigate = useNavigate();
  const activeKey = showWatchlistItems
    ? "WATCHLIST_ITEMS"
    : "WATCHLIST_PLAYERS";

  return (
    <Nav
      activeKey={activeKey}
      className="secondary-nav-light bg-white no-gutters justify-content-center"
    >
      <Nav.Item>
        <Nav.Link
          eventKey="WATCHLIST_ITEMS"
          onSelect={() => navigate("/watchlist/me")}
        >
          <span className="font-weight-bold">
            <FontAwesomeIcon icon={faLayerGroup} className="mr-2" />
            Cards
          </span>
          <span className="badge badge-secondary ml-2">
            {wishlistItemCount}
          </span>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          eventKey="WATCHLIST_PLAYERS"
          onSelect={() => navigate("/watchlist/me/players")}
        >
          <span className="font-weight-bold">
            <FontAwesomeIcon icon={faUserFriends} className="mr-2" />
            Players
            <span className="badge badge-secondary ml-2">
              {wishlistPlayerCount}
            </span>
          </span>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default NavBar;
