import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faGavel } from "@fortawesome/free-solid-svg-icons";

import { Colors } from "../../../../themes/Default";
import { pluralize } from "../../../../utils/content";

import { Event } from "../../types";
import { Container, Label } from "./styles";

interface Props {
  count?: number;
  event: Event;
}

export const EventPill = ({ count, event }: Props) => {
  const icon: { [key in Event]: IconDefinition } = {
    Auction: faGavel,
    Break: faBoxes,
  };

  return (
    <Container>
      <FontAwesomeIcon color={Colors.Blue} icon={icon[event]} />
      <Label>{count ? pluralize(event, count) : event}</Label>
    </Container>
  );
};
