import styled from "styled-components";
import { rgba } from "polished";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

const Astronaut = "#2c4d76";
const Mantis = "#58B55C";
const Danger = "#A93B3B";

export const IndexSummaryStatsThemes = {
  light: "light",
  dark: "dark",
};

const ValuesRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
`;

const ValueContainer = styled.div`
  height: 4rem;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  justify-content: flex-end;
  align-items: baseline;
  flex-shrink: 0;

  @media (min-width: 28rem) {
    height: 5rem;
    margin-right: 1.5rem;
  }
`;

const ValueSizes = {
  small: "small",
  large: "large",
};

const ValueVariants = {
  neutral: "neutral",
  positive: "positive",
  negative: "negative",
};

const Value = styled.p`
  color: ${(props) =>
    props.variant === ValueVariants.positive
      ? rgba(Mantis, 1.0)
      : props.variant === ValueVariants.negative
      ? rgba(Danger, 1.0)
      : props.theme === IndexSummaryStatsThemes.dark
      ? rgba("white", 1.0)
      : rgba(Astronaut, 1.0)};

  font-size: ${(props) =>
    props.size === ValueSizes.large ? "2.5rem" : "1.5rem"};
  font-weight: bold;
  margin-bottom: ${(props) =>
    props.size === ValueSizes.large ? "-0.4rem" : "0"};
  align-items: baseline;
  vertical-align: baseline;
  white-space: nowrap;

  .suffix {
    font-size: ${(props) =>
      props.size === ValueSizes.large ? "1.5rem" : "1rem;"};
  }

  @media (min-width: 28rem) {
    font-size: ${(props) =>
      props.size === ValueSizes.large ? "3rem" : "2rem"};

    margin-bottom: ${(props) =>
      props.size === ValueSizes.large ? "-0.5rem" : "0"};

    .suffix {
      font-size: ${(props) =>
        props.size === ValueSizes.large ? "2rem" : "1rem"};
    }
  }
`;

const Label = styled.p`
  font-size: 0.75rem;
  color: ${(props) =>
    props.theme === IndexSummaryStatsThemes.dark
      ? rgba("white", 0.6)
      : rgba(Astronaut, 0.6)};
  margin-bottom: 0;
  margin-top: -0.25rem;

  @media (min-width: 28rem) {
    font-size: 1rem;
  }
`;

export const IndexSummaryStats = ({
  marketIndex,
  theme = IndexSummaryStatsThemes.light,
}) => {
  const { latestSnapshot } = marketIndex;

  const value = latestSnapshot.normalisedValue;
  const valueParts = value
    .toString()
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    .split(".");
  const valueWhole = valueParts[0];
  const valueDecimal = valueParts[1];

  const percentageChange = (
    latestSnapshot.normalisedValueChangePercentage * 100
  ).toFixed(1);

  const percentageChangeVariant =
    latestSnapshot.normalisedValueChange > 0
      ? ValueVariants.positive
      : latestSnapshot.normalisedValueChange < 0
      ? ValueVariants.negative
      : ValueVariants.neutral;

  return (
    <ValuesRow>
      <ValueContainer>
        <Value
          size={ValueSizes.large}
          variant={ValueVariants.neutral}
          theme={theme}
        >
          {valueWhole}
          <span className="suffix">.{valueDecimal}</span>
        </Value>
        <Label theme={theme}>Index value</Label>
      </ValueContainer>

      <ValueContainer>
        <Value
          size={ValueSizes.small}
          variant={percentageChangeVariant}
          theme={theme}
        >
          {percentageChangeVariant !== ValueVariants.neutral && (
            <FontAwesomeIcon
              size="lg"
              icon={
                percentageChangeVariant === ValueVariants.positive
                  ? faCaretUp
                  : faCaretDown
              }
              className="mr-1 mb-0"
            />
          )}
          {Math.abs(latestSnapshot.normalisedValueChange).toFixed(1)}{" "}
          <span className="suffix">({Math.abs(percentageChange)}%)</span>
        </Value>
        <Label theme={theme}>Week on week</Label>
      </ValueContainer>
    </ValuesRow>
  );
};

export default IndexSummaryStats;
