import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import styled from "styled-components";

import GET_BOX_BREAKS_STOREFRONT from "../../../api/queries/breaks/GET_BOX_BREAKS_STORERONT";
import { logAmplitudeEvent } from "../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../utils/analytics/events";
import {
  AuctionsVideoWidget,
  UpcomingAuctionsWidget,
} from "../../../features/auctions";
import {
  BreaksVideoWidget,
  UpcomingBreaksWidget,
} from "../../../features/breaks";
import ErrorNotice from "../../ErrorNotice";
import Loader from "../../Loader";

import RecentBreaksCarouselWidget from "./Widgets/RecentBreaksCarouselWidget";
import { RecentAuctionsCarouselWidget } from "../../../features/auctions/components/RecentAuctionsCarouselWidget";

const StyledWidgetWrapper = styled.div`
  background: ${(props) => props.background || "#ffffff"};

  margin-bottom: ${(props) => props.marginBottom || "1rem"};
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.05);
`;

const WidgetWrapper = ({ children, background, marginBottom }) => {
  return (
    <StyledWidgetWrapper
      className="row"
      background={background}
      marginBottom={marginBottom}
    >
      <div className="col inner-container-wide mx-auto">
        <div className="row">{children}</div>
      </div>
    </StyledWidgetWrapper>
  );
};

const BreaksStorefront = ({ sport, era }) => {
  useEffect(
    () =>
      logAmplitudeEvent(BREAKS_EVENTS.STOREFRONT.PAGE_VIEWED, {
        Sport: sport,
        Era: era,
      }),
    [era, sport],
  );

  const { data, loading, error } = useQuery(GET_BOX_BREAKS_STOREFRONT, {
    variables: {
      sport: sport,
      era: era,
    },
  });

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;

  const showLiveBreaks = data.liveBoxBreaks.totalCount > 0;
  const showLiveAuctions = data.liveAuctions.totalCount > 0;

  return (
    <>
      {showLiveAuctions && (
        <WidgetWrapper>
          <AuctionsVideoWidget sport={sport} era={era} />
        </WidgetWrapper>
      )}

      {showLiveBreaks && (
        <WidgetWrapper>
          <BreaksVideoWidget sport={sport} era={era} />
        </WidgetWrapper>
      )}

      <WidgetWrapper>
        <UpcomingBreaksWidget sport={sport} era={era} />
      </WidgetWrapper>

      <WidgetWrapper>
        <UpcomingAuctionsWidget sport={sport} era={era} />
      </WidgetWrapper>

      <WidgetWrapper>
        <RecentBreaksCarouselWidget sport={sport} era={era} />
      </WidgetWrapper>

      <WidgetWrapper>
        <RecentAuctionsCarouselWidget sport={sport} era={era} />
      </WidgetWrapper>
    </>
  );
};

export default BreaksStorefront;
