import moment from "moment";

import { productName, productNameSuffix } from "../../utils/content";
import { financial } from "../../utils/common";
import { displayNameForGrade } from "../../utils/grades";

import CardParallelContainer from "../CardParallelContainer";
import SummaryDataRow from "../SummaryDataRow";

const SoldListing = ({ saleData }) => {
  const {
    boughtPrice,
    soldPrice,
    endedAt,
    item: { cardParallel, grade },
  } = saleData;

  const {
    slug,
    serialLimit,
    frontSidePhotoSmall,
    parallel,
    playerCard: { cardFamily },
  } = cardParallel;

  return (
    <CardParallelContainer
      linkPath={`/marketplace/cards/${slug}`}
      photo={frontSidePhotoSmall}
      productType={cardParallel.productType}
      cardProgram={cardFamily.program.name}
      cardFamily={cardFamily.name}
      cardName={productName(cardParallel)}
      cardSetNumber={productNameSuffix(cardParallel)}
      parallel={parallel}
      serialLimit={serialLimit}
      grade={grade}
      configCard
      configAllowWide
      configInsetChildren
    >
      <div className="d-flex flex-row flex-wrap justify-content-between">
        <SummaryDataRow
          configValuesFirst
          summaryDataItems={[
            {
              label: `Sold ${moment(endedAt).format("MM/DD/YYYY")}`,
              value: displayNameForGrade(grade),
            },
          ]}
        />
        <SummaryDataRow
          configValuesFirst
          configAlignItems="justify-content-end"
          summaryDataItems={[
            {
              label: "Bought price",
              value: boughtPrice ? `$${financial(boughtPrice)}` : "-",
              valueColor: boughtPrice ? "text-shout" : "text-deselected",
              textAlignment: "text-right",
            },
            {
              label: "Sale price",
              value: `$${financial(soldPrice)}`,
              textAlignment: "text-right",
            },
          ]}
        />
      </div>
    </CardParallelContainer>
  );
};

export default SoldListing;
