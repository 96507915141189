import styled from "styled-components";
import { rgba } from "polished";
import { logAmplitudeEvent } from "../../../utils/analytics";
import { MARKETPLACE_EVENTS } from "../../../utils/analytics/events";

const Astronaut = "#2c4d76";
const DefaultThemeColor = "#ffffff";
const DefaultBackground = "#ffffff";

export const WidgetThemes = {
  light: "light",
  dark: "dark",
};

const OuterContainer = styled.div`
  width: 100%;
  padding: 2rem 1rem;
  background: ${(props) => props.background || DefaultBackground};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 0.25rem;
`;

const Title = styled.h2`
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 0.5rem;
  color: ${(props) => props.themeColor || DefaultThemeColor};

  @media (min-width: 28rem) {
    font-size: 1.75rem;
  }
`;

const Link = styled.a`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.125rem 0.375rem;
  border: 1px solid ${(props) => props.themeColor || DefaultThemeColor};
  border-radius: 4px;
  font-size: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(props) => props.themeColor || DefaultThemeColor};

  &:link {
    color: ${(props) => props.themeColor || DefaultThemeColor};
    text-decoration: none;
  }
  &:visited {
    color: ${(props) => props.themeColor || DefaultThemeColor};
    text-decoration: none;
  }
  &:hover {
    color: ${(props) =>
      props.theme === WidgetThemes.light ? "white" : Astronaut};
    background: ${(props) => props.themeColor || DefaultThemeColor};
    text-decoration: none;
  }
  &:active {
    color: ${(props) =>
      props.theme === WidgetThemes.light ? "white" : Astronaut};
    background: ${(props) => props.themeColor || DefaultThemeColor};
    text-decoration: none;
  }

  @media (min-width: 28rem) {
    font-size: 0.875rem;
  }
`;

const Subtitle = styled.p`
  color: ${(props) =>
    rgba(props.theme === WidgetThemes.light ? Astronaut : "white", 0.8)};
  margin-bottom: 1.5rem;
  font-size: 0.75rem;

  @media (min-width: 28rem) {
    font-size: 1rem;
  }
`;

const Widget = ({
  id,
  widgetName,
  title,
  subtitle,
  linkTitle,
  linkUrl,
  theme = WidgetThemes.light,
  themeColor = DefaultThemeColor,
  background = DefaultBackground,
  analyticsEventName = MARKETPLACE_EVENTS.STOREFRONT.WIDGET_LINK_CLICKED,
  children,
}) => {
  return (
    <OuterContainer id={id} background={background}>
      <TitleContainer>
        <Title themeColor={themeColor}>{title}</Title>
        {linkUrl && linkTitle ? (
          <Link
            className={"ml-2"}
            theme={theme}
            themeColor={themeColor}
            href={linkUrl}
            onClick={() =>
              logAmplitudeEvent(analyticsEventName, {
                "Widget Name": widgetName,
              })
            }
          >
            {linkTitle}
          </Link>
        ) : null}
      </TitleContainer>
      <Subtitle theme={theme}>{subtitle}</Subtitle>

      {children}
    </OuterContainer>
  );
};

export default Widget;
