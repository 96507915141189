import { QueryHookOptions, useQuery } from "@apollo/client";

import GET_BOX_BREAKS from "../../../../api/queries/breaks/GET_BOX_BREAKS";
import { EventCardGrid } from "../../../../components/EventCardGrid";
import Widget from "../../../../components/Marketplace/Widgets/Widget";
import { BoxBreakNodeConnection } from "../../../../generated/schema";

import { BreakCard } from "../BreakCard";

interface Props {
  background?: string;
  id: string;
  options?: QueryHookOptions;
  subtitle: string;
  linkTitle?: string;
  linkUrl?: string;
  themeColor?: string;
  title: string;
  widgetName: string;
}

interface QueryResponse {
  boxBreaks: BoxBreakNodeConnection;
}

export const BreaksWidget = ({
  background,
  id,
  linkTitle,
  linkUrl,
  options,
  subtitle,
  themeColor,
  title,
  widgetName,
}: Props) => {
  const { data, error, fetchMore, loading } = useQuery<QueryResponse>(
    GET_BOX_BREAKS,
    options,
  );

  const boxBreaks = data?.boxBreaks;

  const fetchMoreBreaks = () =>
    fetchMore({
      variables: { cursor: boxBreaks?.pageInfo?.endCursor },
    });

  return (
    <Widget
      background={background}
      id={id}
      linkTitle={linkTitle}
      linkUrl={linkUrl}
      subtitle={subtitle}
      themeColor={themeColor}
      title={title}
      widgetName={widgetName}
    >
      <EventCardGrid
        error={!!error}
        fetchMore={fetchMoreBreaks}
        hasNextPage={boxBreaks?.pageInfo.hasNextPage}
        loading={loading}
      >
        {boxBreaks &&
          boxBreaks.edges.map((boxBreak) => {
            if (!boxBreak?.node) return null;

            return (
              <BreakCard boxBreak={boxBreak.node} key={boxBreak.node.id} />
            );
          })}
      </EventCardGrid>
    </Widget>
  );
};
