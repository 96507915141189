import { useQuery } from "@apollo/client";
import { faRedoAlt, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import GET_MY_CARD_PARALLEL_ITEMS from "../../api/queries/GET_MY_CARD_PARALLEL_ITEMS";
import { PRODUCT_TYPES } from "../../api/types";
import placeholderBox from "../../images/placeholder-box.png";
import placeholderCard from "../../images/placeholder-card.png";
import { productName, productNameSuffix } from "../../utils/content";
import { displayNameForGrade } from "../../utils/grades";

import CardParallelBadges from "../CardParallelBadges";
import Loader from "../Loader";
import ErrorNotice from "../ErrorNotice";
import Lightbox from "../Lightbox";

const ItemCarouselOuter = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ItemCarouselInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  overflow: auto;
  max-width: 100%;
  padding: 0 1rem;

  // Allow horizontal scroll but hide scrollbar
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  // Display a white shadow overlay to hint at scrolling
  ::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 100%;
    background: linear-gradient(90deg, #f6f7f800, #f6f7f8ff);

    @media (min-width: 992px) {
      width: 3rem;
    }
  }
`;

const StyledItem = styled.div`
  text-align: center;
  flex-shrink: 0;
  padding: 1rem 2rem;
  margin: 0 -1rem;

  @media (min-width: 992px) {
    padding: 2rem;
  }
`;

const ItemPhoto = styled.button`
  background-image: url(\"${(props) => props.photo}\");
  background-position: center;
  background-repeat: no-repeat;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgba(44, 77, 118, 0.05); // Astronaut

  display: block;
  flex-shrink: 0;
  margin-bottom: 1rem;

  width: 100px;
  height: 140px;
  background-size: 100px 140px;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        border: 1px solid #4e8ad4; // Havelock
        cursor: pointer;
        transform: translateY(-1px);
      }
    `}
`;

const Item = ({ image, label, onClick }) => {
  return (
    <StyledItem>
      <ItemPhoto photo={image} onClick={onClick} />
      <h5 className="font-weight-bold text-speak mb-0">{label}</h5>
    </StyledItem>
  );
};

const ItemCarousel = ({ items, productType, showReverse, setShowReverse }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const placeholder =
    productType === PRODUCT_TYPES.BOX ? placeholderBox : placeholderCard;

  const itemThumbnails = items.map(
    (item) =>
      (showReverse
        ? item.reverseSidePhotoThumbnail
        : item.frontSidePhotoSmall) || placeholder,
  );
  const itemImages = items.map(
    (item) =>
      (showReverse ? item.reverseSidePhoto : item.frontSidePhoto) ||
      placeholder,
  );

  return (
    <div className="text-center">
      <ItemCarouselOuter>
        <ItemCarouselInner>
          {items.map((item, index) => (
            <Item
              key={item.id}
              image={itemThumbnails[index]}
              label={displayNameForGrade(item.grade)}
              onClick={() => {
                setSelectedIndex(index);
              }}
            />
          ))}
        </ItemCarouselInner>
      </ItemCarouselOuter>

      <p className="small text-whisper px-3 mt-n1 mb-3">
        Questions or feedback on card photos? Let us know at{" "}
        <a href="mailto:photos@starstock.com">photos@starstock.com</a>
      </p>

      {selectedIndex !== null && (
        <Lightbox
          images={itemImages}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          onFlipCards={() => setShowReverse(!showReverse)}
          onClose={() => setSelectedIndex(null)}
        />
      )}
    </div>
  );
};

const MyCardsModal = ({ slug, show, onHide }) => {
  const { data, loading, error } = useQuery(GET_MY_CARD_PARALLEL_ITEMS, {
    variables: { slug: slug },
  });

  const [showReverse, setShowReverse] = useState(false);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="my-card-parallel-cards-modal-title"
      scrollable
      centered
    >
      <Modal.Header
        id="my-card-parallel-cards-modal-title"
        className="d-flex flex-row justify-content-between"
        closeButton
      >
        <h4 className="font-weight-bold flex-grow-1 flex-shrink-0 mb-0">
          My Cards
        </h4>
      </Modal.Header>

      <Modal.Body className="bg-light p-0">
        {loading ? (
          <Loader />
        ) : error || !data.cardParallel ? (
          <ErrorNotice error={error} />
        ) : (
          <>
            <div className="text-center d-flex flex-column align-items-center p-3 bg-white shadow">
              <h6 className="text-speak mb-1">
                {data.cardParallel.playerCard.cardFamily.program.name}{" "}
                <span className="text-deselected px-1">•</span>{" "}
                {data.cardParallel.playerCard.cardFamily.name}
              </h6>
              <h4 className="text-shout">
                <span className="font-weight-bold">
                  {productName(data.cardParallel)}
                </span>{" "}
                {productNameSuffix(data.cardParallel)}
              </h4>
              <div className="d-flex justify-content-center">
                <CardParallelBadges
                  name={data.cardParallel.parallel.name}
                  badgeTextColor={data.cardParallel.parallel.badgeTextColor}
                  badgeBorderColor={data.cardParallel.parallel.badgeBorderColor}
                  badgeGradientStart={
                    data.cardParallel.parallel.badgeGradientStart
                  }
                  badgeGradientEnd={data.cardParallel.parallel.badgeGradientEnd}
                  badgeGradientAngle={
                    data.cardParallel.parallel.badgeGradientAngle
                  }
                  serialLimit={data.cardParallel.serialLimit}
                />
              </div>

              <button
                onClick={() => setShowReverse(!showReverse)}
                aria-label="Flip card over"
                className="btn btn-link mt-2"
              >
                <FontAwesomeIcon icon={faRedoAlt} className="fa-lg mr-2" />
                Flip to show {showReverse ? "front" : "back"}
              </button>
            </div>
            <ItemCarousel
              items={data.cardParallel.myItems}
              productType={data.cardParallel.productType}
              showReverse={showReverse}
              setShowReverse={setShowReverse}
            />
          </>
        )}
      </Modal.Body>

      <Modal.Footer className="justify-content-between px-2 py-2">
        <button className="btn btn-secondary text-dark" onClick={onHide}>
          Cancel
        </button>
        <Link to={`/marketplace/cards/${slug}`} className="btn btn-primary">
          <FontAwesomeIcon icon={faTag} className="mr-2" />
          View on marketplace
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default MyCardsModal;
