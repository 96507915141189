import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { ACCOUNT_EVENTS } from "../utils/analytics/events";
import { BackgroundImageWrapper } from "../utils/wrappers";
import { logAmplitudeEvent } from "../utils/analytics";

import PASSWORD_RESET_REQUEST from "../api/mutations/PASSWORD_RESET_REQUEST";

import PasswordResetRequestForm from "../components/PasswordResetRequestForm";

import logo from "../images/logo-light.svg";

const PasswordResetRequest = () => {
  useEffect(
    () => logAmplitudeEvent(ACCOUNT_EVENTS.PASSWORD_RESET.REQUEST.FORM_VIEWED),
    [],
  );

  const [showSuccess, setShowSuccess] = useState(false);

  const [passwordResetRequest] = useMutation(PASSWORD_RESET_REQUEST, {
    onCompleted({ _response }) {
      logAmplitudeEvent(ACCOUNT_EVENTS.PASSWORD_RESET.REQUEST.SUBMITTED);
    },
  });

  return (
    <>
      <Helmet>
        <title>Password Reset Request</title>
      </Helmet>

      <div className="container-fluid">
        <BackgroundImageWrapper />

        <div className="row">
          <div className="col">
            <div className="text-center">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid my-5" />
              </Link>
            </div>

            <div className="card mx-auto max-width-26 mb-5">
              <div className="card-body text-center">
                {showSuccess ? (
                  <>
                    <h1 className="card-title h5 my-3">Email Sent!</h1>
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      size="6x"
                      className="mb-3"
                    />
                    <p>
                      Please check your inbox and click the link to reset your
                      password
                    </p>
                  </>
                ) : (
                  <PasswordResetRequestForm
                    passwordResetRequest={passwordResetRequest}
                    setShowSuccess={setShowSuccess}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetRequest;
