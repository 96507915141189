import { Navigate, Route, Routes } from "react-router-dom";

import { NavBarWrapper } from "../utils/wrappers";

import Activity from "./Activity";
import AllCards from "./AllCards";
import BreakDetail from "./breaks/BreakDetail";
import CardDetail from "./CardDetail";
import ContentPage from "./ContentPage";
import Faq from "./Faq";
import JobList from "./JobList";
import SubmitCardsForGrading from "./SubmitCardsForGrading";
import Login from "./Login";
import MarketIndex from "./MarketIndex";
import MarketIndexes from "./MarketIndexes";
import Marketplace from "./Marketplace";
import MyCollection from "./MyCollection";
import NotificationPreferences from "./NotificationPreferences";
import PasswordReset from "./PasswordReset";
import PasswordResetRequest from "./PasswordResetRequest";
import PaymentCardDeposit from "./PaymentCardDeposit";
import PlayerDetail from "./PlayerDetail";
import PrivacyPolicy from "./PrivacyPolicy";
import Sibbc2020 from "./Sibbc2020";
import SifcLandingPage from "./SifcLandingPage";
import SignUp from "./SignUp";
import SignUpDeposit from "./SignUpDeposit";
import SignUpDepositSuccess from "./SignUpDepositSuccess";
import Support from "./Support";
import UserCollection from "./UserCollection";
import WatchList from "./WatchList";
import WithdrawalRequest from "./WithdrawalRequest";
import WithdrawalRequestSubmitSuccess from "./WithdrawalRequestSubmitSuccess";
import BreaksFaq from "./breaks/BreaksFaq";
import Breaks from "./breaks/Breaks";
import RequestPersonalBreak from "./breaks/RequestPersonalBreak";
import UpcomingBreaks from "./breaks/UpcomingBreaks";
import ShipHomeDraft from "./ShipHomeDraft";

import AffiliateCodeWrapper from "../components/AffiliateCodeWrapper";
import PleaseLogIn from "../components/PleaseLogin";
import PleaseLogOut from "../components/PleaseLogOut";
import Sibbc2021 from "./Sibbc2021";
import JobDetail from "./JobDetail";
import ContentWrapper from "../components/ContentWrapper";

import { AccountRoutes } from "../features/account";
import { AuctionsRoutes } from "../features/auctions";
import { LiveRoutes } from "../features/live";
import {
  ManagePaymentMethods,
  WithdrawalConfirmation,
} from "../features/payments";

const Pages = () => (
  <Routes>
    <Route path="account/*" element={<AccountRoutes />} />
    <Route path="auctions/*" element={<AuctionsRoutes />} />
    <Route path="live/*" element={<LiveRoutes />} />

    <Route
      path="/marketplace/cards/:slug"
      element={
        <NavBarWrapper>
          <AffiliateCodeWrapper>
            <CardDetail />
          </AffiliateCodeWrapper>
        </NavBarWrapper>
      }
    />

    <Route
      path="/marketplace/players/:slug"
      element={
        <NavBarWrapper>
          <PlayerDetail />
        </NavBarWrapper>
      }
    />

    <Route
      path="/marketplace/cards"
      element={
        <NavBarWrapper>
          <AllCards />
        </NavBarWrapper>
      }
    />

    {["/marketplace/indices/:slug", "/marketplace/indexes/:slug"].map(
      (path, index) => (
        <Route
          key={path}
          path={path}
          element={
            <NavBarWrapper>
              <MarketIndex />
            </NavBarWrapper>
          }
        />
      ),
    )}

    {["/marketplace/indices", "/marketplace/indexes"].map((path, index) => (
      <Route
        key={path}
        path={path}
        element={
          <NavBarWrapper>
            <MarketIndexes />
          </NavBarWrapper>
        }
      />
    ))}

    <Route
      path="/marketplace"
      element={
        <NavBarWrapper>
          <Marketplace />
        </NavBarWrapper>
      }
    />

    <Route
      path="/breaks/faq"
      element={
        <NavBarWrapper>
          <BreaksFaq />
        </NavBarWrapper>
      }
    />

    <Route
      path="/breaks/request/new"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <RequestPersonalBreak />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/breaks/upcoming"
      element={
        <NavBarWrapper>
          <UpcomingBreaks />
        </NavBarWrapper>
      }
    />

    <Route
      path="/breaks/:slug"
      element={
        <NavBarWrapper>
          <BreakDetail />
        </NavBarWrapper>
      }
    />

    <Route
      path="/breaks"
      element={
        <NavBarWrapper>
          <Breaks />
        </NavBarWrapper>
      }
    />

    <Route
      path="/collection/me"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <MyCollection />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/users/:username"
      element={
        <NavBarWrapper>
          <UserCollection />
        </NavBarWrapper>
      }
    />

    {/* Watchlist Routes - https://reactrouter.com/docs/en/v6/upgrading/v5#note-on-route-path-patterns */}
    <Route
      path="/watchlist/me/"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <WatchList />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/watchlist/me/:players"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <WatchList />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    {/* Activity Routes - https://reactrouter.com/docs/en/v6/upgrading/v5#note-on-route-path-patterns */}
    <Route
      path="/activity/me"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <Activity />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/activity/me/:category"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <Activity />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/login"
      element={
        <PleaseLogOut>
          <Login />
        </PleaseLogOut>
      }
    />

    <Route
      path="/signup/deposit/success"
      element={
        <PleaseLogIn>
          <SignUpDepositSuccess />
        </PleaseLogIn>
      }
    />

    <Route
      path="/signup/deposit"
      element={
        <PleaseLogIn>
          <SignUpDeposit />
        </PleaseLogIn>
      }
    />

    <Route
      path="/signup"
      element={
        <PleaseLogOut>
          <SignUp />
        </PleaseLogOut>
      }
    />

    {/* Password Reset - https://reactrouter.com/docs/en/v6/upgrading/v5#note-on-route-path-patterns */}
    <Route
      path="/account/password-reset/:uidb64/:token"
      element={<PasswordReset />}
    />

    <Route
      path="/accounts/password-reset/:uidb64/:token"
      element={<PasswordReset />}
    />

    <Route path="/account/password-reset" element={<PasswordResetRequest />} />
    <Route path="/accounts/password-reset" element={<PasswordResetRequest />} />

    <Route
      path="/account/deposit"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <PaymentCardDeposit />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/account/manage-payment-methods"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <ManagePaymentMethods />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/account/notification-preferences"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <NotificationPreferences />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/account/withdraw/success"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <WithdrawalRequestSubmitSuccess />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/account/withdraw"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <WithdrawalRequest />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/terms"
      element={
        <NavBarWrapper>
          <ContentWrapper>
            <ContentPage slug="terms" />
          </ContentWrapper>
        </NavBarWrapper>
      }
    />

    <Route
      path="/privacy-policy"
      element={
        <NavBarWrapper>
          <PrivacyPolicy />
        </NavBarWrapper>
      }
    />

    <Route
      path="/accepted-cards"
      element={
        <NavBarWrapper>
          <ContentWrapper>
            <ContentPage slug="accepted-cards" />
          </ContentWrapper>
        </NavBarWrapper>
      }
    />

    <Route
      path="/faq"
      element={
        <NavBarWrapper>
          <Faq />
        </NavBarWrapper>
      }
    />

    <Route
      path="/careers/:slug"
      element={
        <NavBarWrapper>
          <JobDetail />
        </NavBarWrapper>
      }
    />

    <Route
      path="/careers"
      element={
        <NavBarWrapper>
          <JobList />
        </NavBarWrapper>
      }
    />

    <Route
      path="/jobs/:slug"
      element={<Navigate replace to="/careers/:slug" />}
    />
    <Route path="/jobs" element={<Navigate replace to="/careers" />} />

    <Route
      path="/support"
      element={
        <NavBarWrapper>
          <Support />
        </NavBarWrapper>
      }
    />

    <Route
      path="/submit-cards-for-grading"
      element={
        <NavBarWrapper>
          <SubmitCardsForGrading />
        </NavBarWrapper>
      }
    />

    <Route
      path="/sibbc"
      element={
        <NavBarWrapper>
          <Sibbc2021 />
        </NavBarWrapper>
      }
    />

    <Route
      path="/sibbc-2020"
      element={
        <NavBarWrapper>
          <Sibbc2020 />
        </NavBarWrapper>
      }
    />

    <Route
      path="/sibbc-2021"
      element={
        <NavBarWrapper>
          <Sibbc2021 />
        </NavBarWrapper>
      }
    />

    <Route
      path="/sifc"
      element={
        <NavBarWrapper>
          <SifcLandingPage />
        </NavBarWrapper>
      }
    />

    <Route
      path="/ship-home/draft"
      element={
        <PleaseLogIn>
          <NavBarWrapper>
            <ShipHomeDraft />
          </NavBarWrapper>
        </PleaseLogIn>
      }
    />

    <Route
      path="/withdrawal/confirmation/:uidb64/:withdrawalId/:token"
      element={<WithdrawalConfirmation />}
    />

    <Route path="*" element={<Navigate replace to="/marketplace" />} />
  </Routes>
);

export default Pages;
