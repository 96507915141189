import styled from "styled-components";
import Title from "react-bootstrap/ModalTitle";
import Body from "react-bootstrap/ModalBody";

import { Colors } from "../../../../themes/Default";

export const PaymentMethodsContainer = styled.div`
  width: 75%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 25%;
`;

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ModalBody = styled(Body)`
  padding: 0;
`;

export const Button = styled.button`
  background-color: transparent;
  border: 1px solid ${Colors.Havelock};
  border-radius: 0.2rem;
  color: ${Colors.Havelock};
  font-size: 0.875rem;
  margin: 0.5rem;
  padding: 0.25rem 0.5rem;

  &:hover {
    background-color: ${Colors.Havelock};
    color: #fff;
  }
`;

export const DeleteButton = styled(Button)`
  background-color: #fff;
  border-color: #fff;
  border-radius: 4px;
  color: #2c4d76;
  font-size: 16px;
  height: 48px;
  width: 100px;

  &:hover {
    background-color: #ebf2fb;
    border-color: #ebf2fb;
    color: #2c4d76;
  }
`;

export const TitleContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 15px;
  width: 100%;
`;

export const StyledModalTitle = styled(Title)`
  color: #2c4d76;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
`;

export const DefaultPaymentSelectContainer = styled.div`
  background-color: #f6f7f8;
  border: 1px solid #dcddde;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const PaymentMethodList = styled.div`
  padding: 0;
`;
