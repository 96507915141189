import { ErrorDetail } from "../types";

interface Props {
  errors: ErrorDetail[];
  onClose?: () => void;
}

const Error = ({ errors, onClose }: Props) => {
  return (
    <div className="p-3">
      <div className="alert alert-warning text-center p-4" role="alert">
        {errors.length > 0
          ? errors.map(({ message }, i) => (
              <p key={i} className="my-1">
                {message}
              </p>
            ))
          : "Something has went wrong."}
        {}
      </div>

      {onClose && (
        <div className="d-flex flex-row justify-content-center">
          <button className="btn btn-primary px-5" onClick={() => onClose()}>
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default Error;
