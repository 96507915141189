import { useState } from "react";

import EmptySection from "./EmptySection";
import MarketCardParallel from "../MarketCardParallel";
import LoadMoreButton from "../LoadMoreButton";

const CardSection = ({ cardParallels, deleteWishlistItem, fetchMore }) => {
  const [loadingMore, setLoadingMore] = useState(false);

  if (!cardParallels.edges.length) return <EmptySection />;

  return (
    <>
      <div className="row no-gutters row-cols-1 row-cols-lg-2 mx-n2">
        {cardParallels.edges.map(({ node }) =>
          node.myWishlistItems.length > 0 ? (
            <div key={node.id} className="col px-2 mb-2 mb-sm-3">
              <MarketCardParallel
                cardParallel={node}
                watchlistId={node.myWishlistItems[0].id}
                removeFromWatchlist={deleteWishlistItem}
              />
            </div>
          ) : null,
        )}
      </div>

      {cardParallels.pageInfo.hasNextPage && (
        <LoadMoreButton
          loadingMore={loadingMore}
          setLoadingMore={setLoadingMore}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: cardParallels.pageInfo.endCursor,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                setLoadingMore(false);
                if (!fetchMoreResult) return prev;

                fetchMoreResult.myWishlistCards.edges = [
                  ...prev.myWishlistCards.edges,
                  ...fetchMoreResult.myWishlistCards.edges,
                ];
                return fetchMoreResult;
              },
            })
          }
        />
      )}
    </>
  );
};

export default CardSection;
