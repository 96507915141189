import { rgba } from "polished";
import styled, { css } from "styled-components";

const Astronaut = "#2c4d76";

const StyledPanel = styled.div`
  background: ${(props) => props.background || rgba(Astronaut, 0.5)};
  padding: 1rem;
  border-radius: ${(props) => (props.rounded ? "1rem" : "0.25rem")};
`;

const StyledPanelLabel = styled.h6`
  font-size: 0.875rem;
  color: #ffffffaa;
`;

const StyledPanelTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${(props) => props.color || "#ffffff"};
  ${(props) =>
    props.underlined &&
    css`
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #ffffff10;
    `}
`;

const StyledPanelSubTitle = styled.h6`
  font-size: 0.75rem;
  color: #ffffffaa;
  text-transform: uppercase;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ffffff10;
`;

export const Panel = ({
  background,
  tintColor = Astronaut,
  rounded = false,
  label,
  title,
  subtitle,
  children,
}) => {
  return (
    <StyledPanel rounded={rounded} background={background}>
      {label && <StyledPanelLabel>{label}</StyledPanelLabel>}
      <StyledPanelTitle color={tintColor} underlined={!subtitle}>
        {title}
      </StyledPanelTitle>
      {subtitle && <StyledPanelSubTitle>{subtitle}</StyledPanelSubTitle>}

      {children}
    </StyledPanel>
  );
};

export default Panel;
