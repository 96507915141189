import { useState } from "react";

import { AddressNode } from "../../../../../../generated/schema";

import { AddressValues, FetchResposeType } from "../../../../types";
import { EditAddressModal } from "../EditAddressModal";
import {
  Badge,
  ButtonContainer,
  Button,
  Card,
  CardBody,
  Container,
} from "./styles";

interface Props {
  address: AddressNode | null;
  onDefault(): void;
  onDelete(): void;
  onEdit: (id: string, values: AddressValues) => FetchResposeType;
}

export const Address = ({ address, onDefault, onDelete, onEdit }: Props) => {
  const splitAddressLines = address?.addressLines.split("\n");

  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [serverError, setServerError] = useState(false);

  const editAddressAndCloseModal = (id: string, values: AddressValues) => {
    return onEdit(id, values)
      .then((result) => {
        const errors = result.data.updateAddress.errors;
        if (errors && errors.length > 0) {
          setServerError(true);
        } else {
          setShow(false);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const roleString =
    {
      SHIPPING: "Shipping",
    }[address!.role] || address?.role;

  return (
    <Container>
      <Card>
        <CardBody>
          <p>
            <Badge type="dark">{address?.description}</Badge>
            {address?.default && <Badge type="primary">Default</Badge>}
            <Badge type="secondary">{roleString}</Badge>
          </p>

          <address>
            {address?.name}
            <br />
            {splitAddressLines?.map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
            {address?.locality}
            <br />
            {address?.region}
            <br />
            {address?.postalCode}
            <br />
            {address?.country}
          </address>

          <ButtonContainer>
            <div role="group">
              {!address?.default && (
                <Button light={true} onClick={onDefault}>
                  Make default
                </Button>
              )}

              <EditAddressModal
                address={address}
                onEditAddress={editAddressAndCloseModal}
                show={show}
                setShow={setShow}
                isSubmitting={submitting}
                setSubmitting={setSubmitting}
                serverError={serverError}
              />

              <Button light={true} onClick={() => setShow(true)}>
                Edit
              </Button>

              {!address?.default && (
                <Button danger={true} onClick={onDelete}>
                  Delete
                </Button>
              )}
            </div>
          </ButtonContainer>
        </CardBody>
      </Card>
    </Container>
  );
};
