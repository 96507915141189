import { useState } from "react";

import EmptySection from "../Marketplace/EmptySection";
import LoadMoreButton from "../LoadMoreButton";
import MarketCardParallel from "../MarketCardParallel";

const CardSection = ({ data, fetchMore }) => {
  const [loadingMore, setLoadingMore] = useState(false);

  return (
    <>
      {data.edges.length ? (
        <div className="row no-gutters row-cols-1 row-cols-lg-2 mx-n2">
          {data.edges.map(({ node }) => (
            <div key={node.id} className="col px-2 mb-2 mb-sm-3">
              <MarketCardParallel cardParallel={node} />
            </div>
          ))}
        </div>
      ) : (
        <EmptySection />
      )}

      {data.pageInfo.hasNextPage && (
        <LoadMoreButton
          loadingMore={loadingMore}
          setLoadingMore={setLoadingMore}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cardParallelCursor: data.pageInfo.endCursor,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                setLoadingMore(false);
                if (!fetchMoreResult) return prev;

                fetchMoreResult.player.cardParallels.edges = [
                  ...prev.player.cardParallels.edges,
                  ...fetchMoreResult.player.cardParallels.edges,
                ];
                return fetchMoreResult;
              },
            })
          }
        />
      )}
    </>
  );
};

export default CardSection;
