import { useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { number } from "yup";

import { financial } from "../../utils/common";
import { EXPIRES_AFTER_OPTIONS } from "../../utils/forms";

import {
  CustomCurrencyFormat,
  CustomNumberFormat,
} from "../../utils/formatters";
import { pluralize, productQuantityString } from "../../utils/content";

const StepEdit = ({ formData, setFormDataFields, onCancel, onNext }) => {
  const groupedByPrice = _.groupBy(formData.openBuyOrders, function (buyOrder) {
    return buyOrder.buyPrice;
  });

  const priceKeys = _.keys(groupedByPrice).sort((a, b) => b - a);

  useEffect(() => {
    if (formData.quantityAvailable <= 0)
      setFormDataFields({ quantityToSell: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.quantityAvailable]);

  const quantitySchema = number()
    .required()
    .integer()
    .min(0)
    .max(formData.quantityAvailable);

  const castAndSetQuantity = (value) => {
    quantitySchema.isValid(value).then((valid) => {
      if (valid) {
        setFormDataFields({ quantityToSell: quantitySchema.cast(value) });
      }
    });
  };

  const formValid = () => {
    return (
      formData.quantityToSell > 0 &&
      formData.quantityAvailable > 0 &&
      formData.minimumSalePrice >= 0.1
    );
  };

  return (
    <>
      <Modal.Body className="py-0">
        <div className="row bg-light">
          {formData.openBuyOrders.length ? (
            <table className="table table-borderless table-sm mb-0">
              <thead>
                <tr>
                  <th className="text-center w-50" scope="col">
                    Offer price
                  </th>
                  <th className="text-center w-50" scope="col">
                    Open offers
                  </th>
                </tr>
              </thead>
              <tbody className="border-top">
                {priceKeys.map((priceKey) => (
                  <tr key={priceKey}>
                    <th className="text-center" scope="row">
                      ${financial(priceKey)}
                    </th>
                    <td className="text-center">
                      {groupedByPrice[priceKey].length}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center lead mx-auto my-3">No open offers</p>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="container-fluid px-0">
          <FormControl fullWidth>
            <TextField
              id="quantity"
              name="quantity"
              label="Quantity to sell"
              value={formData.quantityToSell}
              onChange={(event) => castAndSetQuantity(event.target.value)}
              variant="outlined"
              margin="dense"
              className="mb-3"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="text-speak">
                    of {formData.quantityAvailable}
                  </InputAdornment>
                ),
                inputComponent: CustomNumberFormat,
              }}
            />
            <TextField
              id="expires-after"
              select
              label="Listing expires after"
              variant="outlined"
              margin="dense"
              className="mb-3"
              value={formData.expiresAfter}
              onChange={(event) =>
                setFormDataFields({ expiresAfter: event.target.value })
              }
            >
              {EXPIRES_AFTER_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="min-sale-price"
              label="Minimum sale price"
              value={formData.minimumSalePrice}
              onChange={(event) =>
                setFormDataFields({ minimumSalePrice: event.target.value })
              }
              helperText="Minimum amount is $0.10"
              variant="outlined"
              margin="dense"
              className="mb-4"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="text-speak">
                    $
                  </InputAdornment>
                ),
                inputComponent: CustomCurrencyFormat,
              }}
            />
          </FormControl>

          <div className="mb-3">
            <div className="row mb-2">
              <div className="col-5 font-weight-bold text-left">
                Instant sales
              </div>
              <div className="col-auto font-weight-bold text-left">
                {formData.expectedSalesCount
                  ? productQuantityString(
                      formData.expectedSalesCount,
                      formData.cardParallelProductType,
                    )
                  : "–"}
              </div>
              <div className="col-auto font-weight-bold text-right flex-fill">
                {formData.expectedSalesValue
                  ? `$${financial(formData.expectedSalesValue)}`
                  : "–"}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-5 text-left">New listings</div>
              <div className="col-auto text-left">
                {formData.expectedListingsCount
                  ? pluralize("listing", formData.expectedListingsCount)
                  : "–"}
              </div>
              <div className="col-auto text-right flex-fill">
                {formData.expectedListingsValue
                  ? `$${financial(formData.expectedListingsValue)}`
                  : "–"}
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-7 text-left">Seller transaction fee</div>
              <div className="col-auto text-right flex-fill">
                {formData.expectedTotalValue && formData.expectedTransactionFees
                  ? `-$${financial(formData.expectedTransactionFees)}`
                  : "–"}
              </div>
            </div>
            <small className="text-whisper">
              Transaction fees automatically deducted when a card sale completes
            </small>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center">
            <button
              className="btn btn-secondary btn-block text-body"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary btn-block font-weight-bold mt-0 ml-3"
              disabled={!formValid()}
              onClick={() => onNext()}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default StepEdit;
