import _ from "lodash";

import { PageSection } from "../../PageSection";
import MarketCardParallel from "../../MarketCardParallel";

const BreakDetailBoxesSection = ({ boxSet }) => {
  const allGCPs = boxSet.edges.map(({ node: box }) => box.gradedCardParallel);
  const groupedGCPs = _(allGCPs)
    .groupBy((gcp) => gcp.id)
    .map((value) => ({
      gcp: value[0],
      boxCount: value.length,
    }))
    .sort((a, b) => b.boxCount - a.boxCount)
    .value();

  return groupedGCPs && groupedGCPs.length > 0 ? (
    <PageSection addMarginBottom>
      <div className="mb-4">
        <h4 className="font-weight-bold mb-2">What weʼre breaking</h4>
        <p className="mb-0">
          See whatʼs included in the break and check out the going rate on the
          StarStock marketplace. Donʼt wanna share? Grab a whole box of your
          own!
        </p>
      </div>

      <div className="row row-cols-1 row-cols-lg-2 mt-3 mx-n2">
        {groupedGCPs.map((groupedGCP) => (
          <div key={groupedGCP.gcp.id} className="col px-2 mb-2 mb-sm-3">
            <MarketCardParallel
              cardParallel={groupedGCP.gcp.cardParallel}
              gradedCardParallels={[groupedGCP.gcp]}
              hiddenGradesCount={0}
              badgeCount={groupedGCP.boxCount}
            />
          </div>
        ))}
      </div>
    </PageSection>
  ) : null;
};

export default BreakDetailBoxesSection;
