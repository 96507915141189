import { useEffect } from "react";

import ReactImageLightbox from "react-image-lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

const Lightbox = ({
  images,
  thumbnails,
  selectedIndex,
  setSelectedIndex,
  onFlipCards,
  onClose,
}) => {
  const imageCount = images.length;
  const nextImageIndex = (selectedIndex + 1) % imageCount;
  const prevImageIndex = (selectedIndex + imageCount - 1) % imageCount;

  // ReactImageLightbox is meant to support keyboard navigation, but there
  // is an open issue flagging that it is
  // https://github.com/frontend-collective/react-image-lightbox/issues/314
  // This adds some quick support for it.
  useEffect(() => {
    function keydownHandler({ key }) {
      switch (key) {
        case "ArrowLeft":
          setSelectedIndex(prevImageIndex);
          break;
        case "ArrowRight":
          setSelectedIndex(nextImageIndex);
          break;
        default:
      }
    }

    window.addEventListener("keydown", keydownHandler);
    return () => {
      window.removeEventListener("keydown", keydownHandler);
    };
  }, [setSelectedIndex, nextImageIndex, prevImageIndex]);

  const toolbarButtons = onFlipCards
    ? [
        <button
          key={1}
          onClick={onFlipCards}
          aria-label="Flip card over"
          className="btn bg-transparent"
          style={{ color: "#B2B2B2" }}
        >
          <FontAwesomeIcon icon={faRedoAlt} className="fa-lg mr-2" />
          Flip card over
        </button>,
      ]
    : [];

  return (
    <ReactImageLightbox
      mainSrc={images[selectedIndex]}
      mainSrcThumbnail={thumbnails && thumbnails[selectedIndex]}
      nextSrc={images[nextImageIndex]}
      nextSrcThumbnail={thumbnails && thumbnails[nextImageIndex]}
      prevSrc={images[prevImageIndex]}
      prevSrcThumbnail={thumbnails && thumbnails[prevImageIndex]}
      onMoveNextRequest={() => setSelectedIndex(nextImageIndex)}
      onMovePrevRequest={() => setSelectedIndex(prevImageIndex)}
      onCloseRequest={() => onClose()}
      toolbarButtons={toolbarButtons}
      animationOnKeyInput={true}
      reactModalStyle={{
        overlay: {
          zIndex: 2000, // Display above general content modals (z-index 1000)
        },
      }}
    />
  );
};

export default Lightbox;
