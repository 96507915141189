import { Route, Routes } from "react-router-dom";

import { NavBarWrapper } from "../../../utils/wrappers";
import PleaseLogIn from "../../../components/PleaseLogin";

import { AddressBookPage } from "./AddressBookPage";

export const AccountRoutes = () => (
  <Routes>
    <Route
      path="/address-book"
      element={
        <NavBarWrapper>
          <PleaseLogIn>
            <AddressBookPage />
          </PleaseLogIn>
        </NavBarWrapper>
      }
    />
  </Routes>
);
