import { faGavel } from "@fortawesome/free-solid-svg-icons";

import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { LotState } from "../../../../generated/schema";
import { financial } from "../../../../utils/common";

import {
  Container,
  Gavel,
  GavelContainer,
  Message,
  NoBidsMessage,
  Price,
} from "./styles";

interface Props {
  openingPrice: number | null;
  state: LotState;
}

export const BidPlaceholder = ({ openingPrice, state }: Props) => {
  const isBidding = state === LotState.BIDDING;
  const isPreBidding = [LotState.UPCOMING, LotState.STARTING].includes(state);

  return (
    <Container>
      {isBidding ? (
        <>
          <LoadingSpinner size={1.5} />
          <Message>Awaiting opening bid</Message>
          <Price>${financial(openingPrice, 0)}</Price>
        </>
      ) : isPreBidding ? (
        <>
          <GavelContainer>
            <Gavel icon={faGavel} />
          </GavelContainer>
          <Message>Bidding opens at…</Message>
          <Price>${financial(openingPrice, 0)}</Price>
        </>
      ) : (
        <NoBidsMessage>No bids</NoBidsMessage>
      )}
    </Container>
  );
};
