import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import {
  AnnouncementBannerState,
  AnnouncementNode,
} from "../../../generated/schema";
import { GET_ANNOUNCEMENT_BANNERS } from "../../../api/queries/GET_ANNOUCEMENT_BANNERS";

import StaffOnly from "../../StaffOnly";
import { AnnouncementBanner } from "../../AnnouncementBanner";

type AnnouncementBanners = (AnnouncementNode | null)[] | null;
interface QueryResponse {
  announcementBanners: AnnouncementBanners;
}

export const Announcements = () => {
  const { data } = useQuery<QueryResponse>(GET_ANNOUNCEMENT_BANNERS);
  const [banners, setBanners] = useState<AnnouncementBanners | undefined>();

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const isPreview = params.get("preview") === "true";

  useEffect(() => {
    setBanners(data?.announcementBanners);
  }, [data]);

  return (
    <>
      {banners &&
        banners.map((banner) => {
          if (!banner) return null;
          const { state } = banner;
          if (state === AnnouncementBannerState.LIVE) {
            return <AnnouncementBanner banner={banner} key={banner.id} />;
          }
          if (state === AnnouncementBannerState.PREVIEW && isPreview) {
            return (
              <StaffOnly key={banner.id}>
                <AnnouncementBanner banner={banner} />
              </StaffOnly>
            );
          }
          return null;
        })}
    </>
  );
};
