import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_ID_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
  GROUPED_BID_FRAGMENT,
  GROUPED_LISTING_FRAGMENT,
} from "../fragments";

const GET_GRADED_CARD_PARALLEL = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  ${GROUPED_BID_FRAGMENT}
  ${GROUPED_LISTING_FRAGMENT}
  query GET_GRADED_CARD_PARALLEL($id: ID!) {
    gradedCardParallel(id: $id) {
      ...gradedCardParallelFields
      myItemCount
      myOpenSellOrderCount
      myOpenBuyOrderCount
      mySuspendedBuyOrderCount
      grade {
        ...gradeFields
      }
      openSellOrders {
        id
        askPrice
      }
      myGroupedBids {
        ...groupedBidFields
        buyorderSet(first: 1) {
          edges {
            node {
              id
              state
              buyPrice
              expiresAfter
              cardParallel {
                ...cardParallelIdFields
              }
              grade {
                ...gradeFields
              }
            }
          }
        }
      }
      myGroupedListings {
        ...groupedListingFields
        sellorderSet(first: 1) {
          edges {
            node {
              id
              askPrice
              expiresAfter
              item {
                cardParallel {
                  ...cardParallelIdFields
                }
                grade {
                  ...gradeFields
                }
              }
            }
          }
        }
      }
      myItems {
        id
        openSellOrderCount
        grade {
          ...gradeFields
        }
      }
    }
  }
`;

export default GET_GRADED_CARD_PARALLEL;
