import { useState } from "react";

import EmptySection from "./EmptySection";
import PlayerListing from "../PlayerListing";
import LoadMoreButton from "../LoadMoreButton";

const PlayerSection = ({ players, fetchMore }) => {
  const [loadingMore, setLoadingMore] = useState(false);

  return (
    <>
      {players.edges.length ? (
        <div className="row no-gutters mx-n2">
          {players.edges.map((player) => (
            <div
              className="col-12 col-md-6 col-lg-4 px-2 mb-2 mb-sm-3"
              key={player.node.id}
            >
              <PlayerListing player={player.node} />
            </div>
          ))}
        </div>
      ) : (
        <EmptySection />
      )}

      {players.pageInfo.hasNextPage && (
        <LoadMoreButton
          loadingMore={loadingMore}
          setLoadingMore={setLoadingMore}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: players.pageInfo.endCursor,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                setLoadingMore(false);
                if (!fetchMoreResult) return prev;

                fetchMoreResult.myWishlistPlayers.edges = [
                  ...prev.myWishlistPlayers.edges,
                  ...fetchMoreResult.myWishlistPlayers.edges,
                ];
                return fetchMoreResult;
              },
            })
          }
        />
      )}
    </>
  );
};

export default PlayerSection;
