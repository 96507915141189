import styled from "styled-components";

import { Colors } from "../../../themes/Default";

export const AddressButton = styled.button`
  align-items: center;
  background: none;
  border: 1px dashed rgba(0 0 0 / 20%);
  border-radius: 4px;
  color: ${Colors.Astronaut};
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 1rem;
  padding-bottom: 3rem;
  padding-top: 3rem;
  text-align: center;
  width: 100%;

  :active {
    color: ${Colors.Havelock};
  }

  :hover {
    border: 1px dashed rgba(0 0 0 / 87%);
  }
`;

export const Label = styled.p`
  font: inherit;
`;

export const Select = styled.select`
  border: 1px solid rgba(0 0 0 / 20%);
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  width: 100%;

  :hover {
    border: 1px solid rgba(0 0 0 / 87%);
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;
