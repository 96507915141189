import { useQuery } from "@apollo/client";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GET_MY_PAYMENT_METHODS from "../../api/GET_MY_PAYMENT_METHODS";
import { PaymentMethodsResponse } from "../../types";
import ErrorNotice from "../../../../components/ErrorNotice";
import Loader from "../../../../components/Loader";

import { PaymentMethodInfo } from "../PaymentMethodInfo";

import {
  CardListContainer,
  PaymentContainer,
  IconContainer,
  Text,
} from "./styles";

export const PaymentCardList = () => {
  const { data, error, loading } = useQuery<PaymentMethodsResponse>(
    GET_MY_PAYMENT_METHODS,
  );
  const paymentMethods = data?.myPaymentMethods!;

  if (loading) return <Loader />;
  if (error) return <ErrorNotice error={error} />;

  if (paymentMethods?.length === 0) {
    return (
      <CardListContainer>
        <IconContainer>
          <FontAwesomeIcon icon={faCreditCard} size="lg" />
        </IconContainer>
        <Text>You have not added any payment methods yet!</Text>
      </CardListContainer>
    );
  }

  return (
    <CardListContainer>
      <PaymentContainer>
        {paymentMethods &&
          paymentMethods.map((paymentMethod) => {
            if (!paymentMethod) return null;
            return (
              <PaymentMethodInfo
                paymentDetails={paymentMethod}
                key={paymentMethod?.paymentMethodId}
                showDefault
              />
            );
          })}
      </PaymentContainer>
    </CardListContainer>
  );
};
