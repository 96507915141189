import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 36rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 62rem) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 75rem) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
