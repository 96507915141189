import { gql } from "@apollo/client";

const CREATE_WISHLIST_ITEM = gql`
  mutation CREATE_WISHLIST_ITEM($cardParallelId: ID!) {
    wishlistItemCreate(cardParallelId: $cardParallelId) {
      errors {
        field
        message
      }
      wishlistItem {
        id
        cardParallel {
          id
        }
      }
    }
  }
`;

export default CREATE_WISHLIST_ITEM;
