import { Modal } from "react-bootstrap";

import { LocationLink } from "../LocationLink";

const LoginModal = ({ show, setShow }) => {
  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <h5 className="modal-title text-center">
          Create a free StarStock account to start trading instantly
        </h5>
      </Modal.Header>

      <Modal.Footer>
        <LocationLink className="btn btn-primary ml-auto" to="/login">
          Log in to account
        </LocationLink>

        <LocationLink className="btn btn-success mr-auto" to="/signup">
          Sign up now
        </LocationLink>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModal;
