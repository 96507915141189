import { gql } from "@apollo/client";

const PASSWORD_RESET_CHANGE = gql`
  mutation passwordResetChange(
    $newPassword: String!
    $token: String!
    $userIdB64: String!
  ) {
    passwordResetChange(
      newPassword: $newPassword
      token: $token
      userIdB64: $userIdB64
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export default PASSWORD_RESET_CHANGE;
