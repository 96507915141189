import { Formik, Form } from "formik";
import {
  Checkbox,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  Radio,
} from "@material-ui/core";
import { object, number, string, boolean } from "yup";

const WithdrawalRequestForm = ({
  isSubmitting,
  setSubmitting,
  createWithdrawalRequest,
}) => {
  return (
    <Formik
      initialValues={{
        amount: "",
        paymentMethod: "PAYPAL",
        paypalEmail: "",
        checkRecipient: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipCode: "",
        state: "",
        country: "",
        postalTracking: false,
      }}
      validationSchema={object({
        amount: number()
          .required("Required")
          .min(0.01, "Withdrawal amount must be 0.01 or more"),
        paymentMethod: string().required("Required").trim(),
        paypalEmail: string()
          .email("You must provide a valid email address")
          .max(100, "Must be less than 100 characters")
          .when("paymentMethod", {
            is: "PAYPAL",
            then: string().required("Required"),
          })
          .trim(),
        checkRecipient: string()
          .max(100, "Must be less than 100 characters")
          .when("paymentMethod", {
            is: "CHECK",
            then: string().required("Required"),
          })
          .trim(),
        addressLine1: string()
          .max(100, "Must be less than 100 characters")
          .when("paymentMethod", {
            is: "CHECK",
            then: string().required("Required"),
          })
          .trim(),
        addressLine2: string()
          .max(100, "Must be less than 100 characters")
          .trim(),
        city: string()
          .max(100, "Must be less than 100 characters")
          .when("paymentMethod", {
            is: "CHECK",
            then: string().required("Required"),
          })
          .trim(),
        zipCode: string()
          .max(20, "Must be less than 20 characters")
          .when("paymentMethod", {
            is: "CHECK",
            then: string().required("Required"),
          })
          .trim(),
        state: string()
          .max(100, "Must be less than 100 characters")
          .when("paymentMethod", {
            is: "CHECK",
            then: string().required("Required"),
          })
          .trim(),
        country: string()
          .max(100, "Must be less than 100 characters")
          .when("paymentMethod", {
            is: "CHECK",
            then: string().required("Required"),
          })
          .trim(),
        postalTracking: boolean(),
      })}
      onSubmit={(values) => {
        setSubmitting(true);
        createWithdrawalRequest({
          variables: {
            amount: values.amount,
            paymentMethod: values.paymentMethod,
            paypalEmail: values.paypalEmail,
            checkRecipient: values.checkRecipient,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            zipCode: values.zipCode,
            state: values.state,
            country: values.country,
            postalTracking: values.postalTracking,
          },
        });
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <Form>
          <FormControl
            error={errors.paymentMethod ? true : false}
            className="mt-2"
          >
            <FormLabel>Payment Method:</FormLabel>
            <RadioGroup
              row
              aria-label="payment-method"
              name="paymentMethod"
              value={values.paymentMethod}
              onChange={handleChange}
            >
              <FormControlLabel
                value="PAYPAL"
                control={<Radio />}
                label="PayPal"
              />
              <FormControlLabel
                value="CHECK"
                control={<Radio />}
                label="Check"
              />
            </RadioGroup>
          </FormControl>

          {values.paymentMethod === "CHECK" && (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  checked={values.postalTracking}
                  name="postalTracking"
                />
              }
              label="Add USPS tracking"
            />
          )}

          <TextField
            id="amount"
            name="amount"
            type="number"
            label="Withdrawal Amount"
            value={values.amount}
            error={errors.amount && touched.amount}
            helperText={errors.amount && touched.amount && errors.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-2"
          />

          {values.paymentMethod === "PAYPAL" && (
            <TextField
              id="paypalEmail"
              name="paypalEmail"
              type="email"
              label="PayPal Email Address"
              autoComplete="email"
              value={values.paypalEmail}
              error={errors.paypalEmail && touched.paypalEmail}
              helperText={
                errors.paypalEmail && touched.paypalEmail && errors.paypalEmail
              }
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              fullWidth
              margin="dense"
              className="mb-2"
            />
          )}
          {values.paymentMethod === "CHECK" && (
            <>
              <TextField
                id="checkRecipient"
                name="checkRecipient"
                label="Name of Check Recipient"
                autoComplete="given-name"
                value={values.checkRecipient}
                error={errors.checkRecipient && touched.checkRecipient}
                helperText={
                  errors.checkRecipient &&
                  touched.checkRecipient &&
                  errors.checkRecipient
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                margin="dense"
                className="mb-2"
              />

              <TextField
                id="addressLine1"
                name="addressLine1"
                label="Address Line 1"
                autoComplete="address-line1"
                value={values.addressLine1}
                error={errors.addressLine1 && touched.addressLine1}
                helperText={
                  errors.addressLine1 &&
                  touched.addressLine1 &&
                  errors.addressLine1
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                margin="dense"
                className="mb-2"
              />

              <TextField
                id="addressLine2"
                name="addressLine2"
                label="Address Line 2"
                autoComplete="address-line2"
                value={values.addressLine2}
                error={errors.addressLine2 && touched.addressLine2}
                helperText={
                  errors.addressLine2 &&
                  touched.addressLine2 &&
                  errors.addressLine2
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                margin="dense"
                className="mb-2"
              />

              <TextField
                id="city"
                name="city"
                label="City"
                autoComplete="address-level2"
                value={values.city}
                error={errors.city && touched.city}
                helperText={errors.city && touched.city && errors.city}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                margin="dense"
                className="mb-2"
              />

              <TextField
                id="zipCode"
                name="zipCode"
                label="Zip Code"
                autoComplete="postal-code"
                value={values.zipCode}
                error={errors.zipCode && touched.zipCode}
                helperText={errors.zipCode && touched.zipCode && errors.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                margin="dense"
                className="mb-2"
              />

              <TextField
                id="state"
                name="state"
                label="State"
                autoComplete="address-level1"
                value={values.state}
                error={errors.state && touched.state}
                helperText={errors.state && touched.state && errors.state}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                margin="dense"
                className="mb-2"
              />

              <TextField
                id="country"
                name="country"
                label="Country"
                autoComplete="country-name"
                value={values.country}
                error={errors.country && touched.country}
                helperText={errors.country && touched.country && errors.country}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                margin="dense"
                className="mb-2"
              />
            </>
          )}

          <button
            type="submit"
            className="btn btn-success btn-block btn-lg mb-3 mt-3"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default WithdrawalRequestForm;
