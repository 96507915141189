import styled, { css } from "styled-components";
import { TextField, Checkbox } from "@material-ui/core";

import { Colors } from "../../../../../../themes/Default";

export const Paragraph = styled.p`
  background-color: #dc3545;
  color: #fff;
`;

export const Fields = styled.div`
  border-bottom: 1px solid rgb(0 0 0/ 10%);
  padding: 0 0.5rem 1rem 0.25rem;
`;

export const Label = styled.label`
  color: ${Colors.Blue};
  font-weight: bold;
`;

export const CheckboxContainer = styled.div`
  margin-left: 0.5rem;
  margin-top: 1.5rem;
`;

export const PostalRegion = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const Button = styled.button<{ cancel?: boolean }>`
  background: ${Colors.Havelock}
    linear-gradient(180deg, #699cda, ${Colors.Havelock}) repeat-x;
  border: 1px solid transparent;
  border-color: ${Colors.Havelock};
  border-radius: 0.25rem;
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  margin-left: 1rem;
  margin-top: 0;
  padding: 0.375rem 0.75rem;
  text-align: center;
  width: 100%;

  :disabled {
    background-color: ${Colors.Havelock};
    background-image: none;
    border-color: ${Colors.Havelock};
    color: #fff;
    cursor: not-allowed;
    opacity: 0.65;
  }

  :hover {
    background: #2b68b3 linear-gradient(180deg, #699cda, #2b68b3) repeat-x;
  }

  ${({ cancel }) =>
    cancel &&
    css`
      background: ${Colors.Athens}
        linear-gradient(180deg, #e6eaed, ${Colors.Athens}) repeat-x;
      border-color: ${Colors.Athens};
      color: rgb(44 77 118/ 80%);

      &:hover {
        background: #d0d2d5
          linear-gradient(180deg, ${Colors.Porcelain}, #d0d2d5) repeat-x;
        border-color: #d0d2d5;
        text-decoration: none;
      }
    `}
`;

export const FormField = styled(TextField)`
  margin-bottom: 0.5rem !important;
`;

export const FormCheckbox = styled(Checkbox)`
  color: ${Colors.Blue} !important;
  margin-top: -0.2rem !important;
  padding-right: 0.4rem !important;
`;
