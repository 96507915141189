import { gql } from "@apollo/client";

const USER_CREATE = gql`
  mutation userCreate(
    $firstName: String!
    $lastName: String!
    $email: String!
    $username: String!
    $password: String!
    $marketingEmails: Boolean!
    $promoCode: String
  ) {
    userCreate(
      firstName: $firstName
      lastName: $lastName
      email: $email
      username: $username
      password: $password
      marketingEmails: $marketingEmails
      promoCode: $promoCode
    ) {
      user {
        id
      }
      refreshToken
      errors {
        field
        message
      }
    }
  }
`;

export default USER_CREATE;
