import moment from "moment";

export const EXPIRES_AFTER_OPTIONS = [
  { label: "No expiry", value: "NO_EXPIRY", dayIncrement: 0 },
  { label: "3 days", value: "THREE_DAYS", dayIncrement: 3 },
  { label: "7 days", value: "SEVEN_DAYS", dayIncrement: 7 },
  { label: "14 days", value: "FOURTEEN_DAYS", dayIncrement: 14 },
  { label: "30 days", value: "THIRTY_DAYS", dayIncrement: 30 },
  { label: "60 days", value: "SIXTY_DAYS", dayIncrement: 60 },
];

export const prettyExpiresAfter = (expiry) => {
  return EXPIRES_AFTER_OPTIONS.find(({ value }) => value === expiry).label;
};

export const getUpdatedExpiryDate = (extendedExpiry) => {
  const increment = EXPIRES_AFTER_OPTIONS.find(
    ({ value }) => value === extendedExpiry,
  ).dayIncrement;

  return moment().add(increment, "days").format("MM/DD/YYYY");
};
