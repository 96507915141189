import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import styled from "styled-components";

import IS_LOGGED_IN from "../api/queries/IS_LOGGED_IN";

import { pathWithSearch } from "../utils/common";

import { Announcements } from "../components/Marketplace/Announcements";
import Intro from "../components/Marketplace/Intro";
import MarketplaceNavBar from "../components/Marketplace/NavBar";
import SearchInput from "../components/Activity/SearchInput";
import Storefront from "../components/Marketplace/Storefront";
import Search from "../components/Marketplace/Search";

import background from "../images/background.png";

const HeaderContainer = styled.div`
  background: linear-gradient(
      180deg,
      rgb(11 31 60 / 80%) 0%,
      rgb(11 31 60 / 33%) 33%,
      rgb(11 31 60 / 33%) 75%,
      rgb(11 31 60 / 80%) 100%
    ),
    url(${background}) no-repeat center fixed;
  background-size: cover;
`;
const Marketplace = () => {
  const { data: loginData } = useQuery(IS_LOGGED_IN);

  const [era, setEra] = useQueryState("era", "");
  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [sport, setSport] = useQueryState("sport", "");

  const navigate = useNavigate();

  return (
    <>
      <HeaderContainer className="row text-left">
        <Announcements />
        <div className="col inner-container-wide mx-auto pt-3">
          <Intro expanded={loginData && !loginData.isLoggedIn} />

          <div className="max-width-40 mt-4 mb-3 mx-auto">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder={"Search by card, player or team"}
              configMaxWidth="100%"
              configSize="large"
            />
            <p
              className="text-center mt-3"
              style={{ color: "rgb(255 255 255 / 60%)" }}
            >
              …or <a href={"/marketplace/cards"}>explore the full vault</a> of
              over 1,300,000 cards
            </p>
          </div>

          <MarketplaceNavBar
            sport={sport}
            era={era}
            setValues={(sport: string, era: string) => {
              setEra(era);
              setSport(sport);
              navigate(pathWithSearch());
            }}
          />
        </div>
      </HeaderContainer>

      {!searchQuery ? (
        <Storefront sport={sport} era={era} />
      ) : (
        <Search sport={sport} era={era} searchQuery={searchQuery} />
      )}
    </>
  );
};

export default Marketplace;
