import { useEffect } from "react";

import styled, { css } from "styled-components";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { logAmplitudeEvent } from "../utils/analytics";
import { CONTENT_EVENTS } from "../utils/analytics/events";
import { pluralize } from "../utils/content";
import { ContentPageHelmet } from "../utils/wrappers";

import background from "../images/sifc/background.png";

import PrizeOne from "../images/sifc/prizes/Prize1.jpg";
import PrizeTwo from "../images/sifc/prizes/Prize2.jpg";
import PrizeThree from "../images/sifc/prizes/Prize3.jpg";
import PrizeFour from "../images/sifc/prizes/Prize4.jpg";

import JoeBryant from "../images/sifc/contestants/01-JoeBryant.jpg";
import BrettMcGrath from "../images/sifc/contestants/02-BrettMcGrath.jpg";
import NandoDiFino from "../images/sifc/contestants/03-NandoDiFino.jpg";
import AdamPalmer from "../images/sifc/contestants/04-AdamPalmer.jpg";
import MattBerry from "../images/sifc/contestants/05-MattBerry.png";
import TylerSchmitt from "../images/sifc/contestants/06-TylerSchmitt.png";
import AdamLevitan from "../images/sifc/contestants/07-AdamLevitan.jpg";
import PaulCharchian from "../images/sifc/contestants/08-PaulCharchian.png";
import MatthewDellavedova from "../images/sifc/contestants/09-MatthewDellavedova.png";
import ChadBleznick from "../images/sifc/contestants/10-ChadBleznick.png";
import Ducky from "../images/sifc/contestants/11-Ducky.jpg";
import Oz from "../images/sifc/contestants/12-Oz.jpg";

import {
  PageSection,
  PageSectionTypes,
  PageSectionWidths,
} from "../components/PageSection";

const updates = [
  {
    title: "Week 14 update",
    date: Date.UTC(2020, 11, 17),
    body: (
      <>
        <p>
          It was a big selling week for the SIFC as all transactions this week
          were sales. With the season coming to an end, some contestants are
          getting an earlier jump on the selling train compared to the rest of
          the field in an attempt to maximize portfolio value and cash in the
          highest they can, both in both cash and prizes. At this point, the
          SIFC has become a fight for second. Blez is in his 11th consecutive
          week in first place with a portfolio value of $580.16. Blez was quiet
          this week transaction wise but still holds a $89.37 lead over second
          place Nando, who sits at $490.79. Nando capitalized on how well David
          Montgomery has played the last few weeks and sold his prizm base for
          $4.50. Nando has a significantly higher card value than Blez, so maybe
          he can make a big jump once he begins to sell off the majority of his
          collection.
        </p>
        <p>
          Third place belongs to Ducky who is currently projected to get the
          CMAC Prizm Base PSA 10 as his prize, with a portfolio value of
          $421.04. Ducky made four small sales this week, offloading three
          Gabriel Davis Donruss Base cards for $.85, and one Miles Boykin Prizm
          Base for $1.30. Ducky is coming in $23.22 above our most active
          contestant last week: Tyler Schmit, who had eight sales this week. He
          sold four Parris Campbell Prizm Base cards for $1.10, two Carson Wentz
          Donruss Base cards for $.52 and $.20, and two StarStock B Amari Cooper
          Topps Chrome Base cards for $.50.
        </p>
        <p>
          Finally, the biggest sale of the week belongs to Twitter Wildcard Oz,
          who dumped a Deshaun Watson Optic Base for $58, along with a Noah Fant
          Prizm Base for $2. Oz is currently in 6th place, but if he continues
          to sell along the lines of his Deshaun Watson sale, he is more than
          capable of making up some ground.
        </p>
        <p>
          We have three weeks left of this competition which will involve a ton
          of sales as competitors try and offload their collection.
        </p>
        <p>
          To stay up to date with all SIFC trades and happenings, be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) and continue to check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Weeks 12 and 13 update",
    date: Date.UTC(2020, 11, 9),
    body: (
      <>
        <p>
          It is becoming abundantly clear that the SIFC is becoming a race for
          second as Blez enters his 10th consecutive week in first place, and is
          continuing to sell off his product to increase portfolio value.
          Although Nando was drifting close the past few weeks, Blez has
          increased his lead to $61.51, thanks to five sales he made the past
          two weeks. Blez was able to dump five Jalen Hurts Prizm Draft Picks
          Base ($7). To max out his portfolio value potential and end up with
          the most money possible at the end, Blez should continue to look to
          sell off his collection.
        </p>
        <p>
          Nando still remains at second place in the competition and maintains a
          sizable lead over third place. Nando is looking to cash in on a Jalen
          Hurts flip in the coming weeks now that he is starting for
          Philadelphia, and bought a StarStock B Panini Contenders Draft Picks
          Diamond Gameday Ticket Jalen Hurts ($29.99). While Blez has offloaded
          a portion of his Jalen Hurts and Nando maintains this card, he could
          potentially close the gap to what it once was and attempt to take over
          the top spot.
        </p>
        <p>
          The fight for third place is the most contentious race as of now, with
          Ducky in third at $423.56, Tyler Schmitt in fourth with $398.30, and
          Adam Levitan in fifth with $395.67. These three are currently in line
          for a 2017 CMAC Prizm Base PSA 10 (~$1,000), a 2019 Daniel Jones Prizm
          Base PSA 10 (~$500) and a 2018 Saquon Optic Base PSA 10 (~$250)
          respectively, so there is a lot riding on making the jump to third for
          Tyler and Adam.
        </p>
        <p>
          The end of the regular season is fast approaching as teams are making
          their final playoff pushes, which means the SIFC is slowly coming to
          an end as well. Make sure to keep up with all things SIFC as
          contestants will begin to start selling off and maximizing their
          potential portfolio value.
        </p>
        <p>
          To stay up to date with all SIFC trades and happenings, be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) and continue to check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Week 11 update",
    date: Date.UTC(2020, 10, 25),
    body: (
      <>
        <p>
          The SIFC is starting to shape into a two horse race with Blez and
          Nando continuing their fight atop the leaderboard. Blez is now in his
          eighth week in first place, but Nando is not letting him go quietly
          into the night. Blez did not make any transactions last week, and
          Nando has continued to make pickups across the board to shrink the gap
          to $14.30 this week, making up $1.70. Nando grabbed three D’Onta
          Foreman cards this week: two Prizm Silver Rookie Autos ($3), and one
          Prizm Disco ($1). With Darrynton Evans out with a hamstring injury the
          backup role in Tennessee is wide open. Foreman has gotten some action
          recently, so if Evans stays on the injured report, Foreman could
          become a viable option backing up Henry.
        </p>
        <p>
          After the top two, the rest of the standings experienced a big
          shakeup. Ducky now sits in third with a $6 lead over new fourth place
          competitor, Paul Charchian of Guillotine Leagues. Ducky made three
          pickups to catapult him up the standings: a Miles Boykin Prizm Base
          ($.99), a Ryan Finley Prizm Base ($3), and a Justin Jefferson Donruss
          Base StarStock B ($.50). Unfortunately for Ducky, Brandon Allen will
          get the start for the Bengals over Finley, but that could certainly
          change barring a bad start for Allen.
        </p>
        <p>
          Fifth and sixth places belong to Adam Levitan and Twitter winner Oz
          respectively, followed by Tyler Schmitt in seventh. All three of these
          guys were active and making purchases this week. Adam bought a Justin
          Herbert Draft Picks Prizm Orange ($16), Oz got a Derrick Henry Select
          Concourse Base ($8), and Schmitt made two good receiver purchases this
          week. Schmitt snagged a Deandre Hopkins Topps Chrome Base ($12) and a
          Sammy Watkins Topps Chrome Refractor ($4).
        </p>
        <p>
          The SIFC is starting to wind down, so look for our contestants to
          start ramping up their purchases to build up their portfolio for their
          final push, and eventually sell off to maximize their portfolio value.
          With Thanksgiving games coming up, there’s a big opportunity for
          purchases with three turkey day games, and good matchups all around
          the board!
        </p>
        <p>
          To stay up to date with all SIFC trades and happenings, be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) and continue to check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Week 10 update",
    date: Date.UTC(2020, 10, 20),
    body: (
      <>
        <p>
          Can anyone stop Blez? Now in his seventh week in first place, it seems
          he continuously finds ways to maintain atop the SIFC leaderboard. Blez
          made two purchases this week, buying another two Jalen Hurts Prizm
          Draft Base cards for $3 each, which makes it nine base and six silver
          draft picks of Jalen Hurts in Blez’s collection. Unfortunately for the
          rest of the pack, Blez also holds a significant amount of Tua cards,
          and now that he is performing well and winning games for the Dolphins,
          that price could rise quickly, along with Blez’s account value.
        </p>
        <p>
          The narrative stays the same from last week, “Can Nando catch Blez?”
          Nando closed the gap by $1 and now trails by $16. Nando did not make
          any moves this week but is still riding the momentum from his Week 8
          transactions. If he can sell a few cards here and there, or one big
          card, Nando could possibly take over first place, a spot that hasn’t
          changed since Week 4!
        </p>
        <p>
          Adam Levitan remains in third place, but Matthew Dellavedova works his
          way back into the top 5 coming in at fourth. Delly was able to make
          his move thanks to two sales, offloading two Joe Burrow base Prizm
          Draft Picks for $20 each. These two are separated by $39 in portfolio
          value, and if Delly keeps this up he could find himself back into some
          of the top spots.
        </p>
        <p>
          Fifth and sixth place belong to Tyler Schmitt and Ducky respectively,
          and this is the tightest gap between two standings we have, with Tyler
          holding a $1.59 lead over Ducky, who was this week’s biggest seller by
          volume. Ducky sold five cards in total: two Michael Pittman Mosaics
          base cards for $1.75 each, and three KJ Hamler Donruss base cards for
          .$42 each. While they weren’t the biggest sales, Ducky leads the SIFC
          in terms of sales with nine, and should be looking for more as the
          regular season nears its end.
        </p>
        <p>
          To stay up to date with all SIFC trades and happenings, be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) and continue to check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Week 9 update",
    date: Date.UTC(2020, 10, 12),
    body: (
      <>
        <p>
          The NFL season is now over it’s halfway mark and the playoff picture
          is slowly starting to take form, with the Chiefs and Steelers far and
          away Super Bowl favorites, with plenty of competition slightly below
          them. The SIFC is shaping up in a similar way, now that Chad Bleznick
          is in his sixth week at the top of the leaderboard. With only one
          purchase this week, a Devonta Freeman Topps Chrome Purple Refractor
          ($8.50), Blez holds a $17 lead over second place Nando, a lead that
          shrunk from $65 last week due to a dip in some card prices. Nando
          comes in at second again after making a monster move in the standings
          last week. Like most of our contestants, Nando experienced a dip in
          portfolio value, but his was only $17. Now that the gap is
          significantly smaller for Nando to get to first, look for him to make
          some sales to increase his account value.
        </p>
        <p>
          Third place remained the same, with Adam Levitan clocking in for
          another consecutive week, however the rest of the standings
          experienced a bit of a shakeup. Fourth place now belongs to Matthew
          Berry, who jumped one place from fifth. The biggest jump of the week
          belongs to Tyler Schmitt, who came into fifth place all the way from
          tenth. Tyler is the proud owner of several DK Metcalf cards, which
          definitely contributed to his increase in portfolio value from $386,
          to $436.50.
        </p>
        <p>
          Finally, Ducky follows up his big Week 8 spending spree with a big
          Week 9 spending spree. He still sits at seventh place, but picked up
          some more 2020 rookies, getting several Van Jefferson cards, both
          Mosaic base ($.48), and some Donruss Press Proofs. He also added a few
          Gabriel Davis Donruss Base cards ($.75), among many other cards that
          should boom his portfolio value in the coming weeks.
        </p>
        <p>
          To stay up to date with all SIFC trades and happenings, be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) and continue to check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Week 8 update",
    date: Date.UTC(2020, 10, 5),
    body: (
      <>
        <p>
          Unlike the uneventful NFL trade deadline, Week 8 of the SIFC had
          movement. There was some leaderboard shakeup and several transactions
          made as our contestants are gearing up for the back half of the
          season. One thing hasn’t changed, which is Chad Bleznick entering his
          fifth week in first place. Chad is still holding on to Tua, hoping he
          builds on his average performance last week against the Rams and
          starts to jump up in card value. Coming up right behind him, with the
          biggest jump of the week, is Nando, who moves from sixth to second.
          His portfolio value increased $66 after a big selling week. Nando sold
          a Dede Westbrook Prizm Pink ($2.50) and a Chris Carson PSA 9 Prizm
          Silver ($75). Now with some extra cash in his balance, Nando is
          looking to make a big move into first to dethrone the first-half king
          Blez, and close the $65.60 gap.
        </p>
        <p>
          The rest of the leaderboard stays pretty much the same, just bumped
          down one spot. Adam Levitan now holds the third place spot, followed
          by NBA Champ Matthew Dellavedova, and ESPN Fantasy Guru Matthew Berry
          for fourth and fifth places. All three of these guys are neck and neck
          in the standings, with third and fifth places separated by only
          $34.70. Look for these three to start making some sales soon in order
          to gain a little extra balance back, and get back into the top three
          spots of the standings.
        </p>
        <p>
          One contestant had a phenomenal week buying, which was
          DuckySportsCards. For the second week in a row he comes in at seventh
          place, but after these purchases, expect him to make a move up the
          ladder. Ducky bought all Donruss Football Base cards, which include JK
          Dobbins StarStock A’s ($.99), Zach Moss B’s ($.15), Justin Jefferson
          B’s ($1.99), and Chase Claypool A’s ($4.99). Ducky saw some great
          value in players and took advantage of low prices, and will wait to
          see how they perform in the coming weeks.
        </p>
        <p>
          To stay up to date with all SIFC trades and happenings, be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) and continue to check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Week 7 update",
    date: Date.UTC(2020, 9, 29),
    body: (
      <>
        <p>
          The NFL Season is approaching its halfway point, as is the SIFC. NFL
          teams are beginning to round out their rosters and identify any
          possible issues that they will address with trades prior to the trade
          deadline. Our SIFC contestants are patiently sitting on their
          portfolios, playing the long game and waiting for player values to
          skyrocket. The standings have mostly stayed the same since last week,
          with Chad Bleznick entering his third week in the top spot. Blez is
          holding onto four Tua Draft Pick Prizms (One silver, three bases)
          which could help further distance him over the rest of the pack, now
          that Tua is primed to make his first start this Sunday against the
          Rams. He currently holds an $89 lead over second place Adam Levitan,
          who is also primed for a big week. Levitan is in possession of a huge
          Chase Edmonds collection. Now that Kenyan Drake is officially out for
          4-6 weeks, Chase Edmonds is about to get primary ball carrying duty in
          the potent Cardinals offense. Watch out for Adam making a big jump in
          portfolio value next week, when the Cardinals play the Dolphins.
        </p>
        <p>
          For the third week in a row, the Matthew’s continue to be within
          dollars of each other. NBA Matthew Dellavedova holds strong at third
          place, and ESPN Fantasy Guru Matthew Berry follows him at fourth
          place. For Delly, the key to a jump into the top two spots is the
          continuing success of rookie sensation Joe Burrow. For Berry, he’s in
          a very similar spot, with the addition of Rashard Higgins. Both the
          Matthews are going to be big fans of the Bengals going forward.
        </p>
        <p>
          Our big seller of the week was OZsportsfan, making two sales this
          week. Oz sold a Phillip Lindsay Concourse Select Base ($6.50) and a
          Dak Prescott Concourse Select Base ($12). Smart move by Oz to dump
          some of his injured capital to make room for some possible new
          breakout players. Oz currently is in 5th place, and these sales are
          big to catapult him up the rankings.
        </p>
        <p>
          To stay up to date with all SIFC trades and happenings, be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) and continue to check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Week 6 update",
    date: Date.UTC(2020, 9, 22),
    body: (
      <>
        <p>
          Week 6 of the SIFC was slow going for our contestants, as many saw a
          decrease in portfolio value and the standings adjusted ever so
          slightly. Our leader, however, has started to create a sizable gap
          between him and the rest of the competition. Chad Bleznick enters his
          third week in first place. He continues to go all in on Jalen Hurts,
          scooping up another $3 Jalen Hurts Draft Picks Prizm Base. Bleznick
          also made two big sales this week, dumping two A.J. Brown Prizm Base
          for $18.50 each. He is now enjoying a $72 lead over reigning second
          place contestant Adam Levitan, compared to a $12.53 lead last week.
          While the gap has widened between first and second, Levitan saw a
          $16.75 portfolio value increase.
        </p>
        <p>
          The two Matthew’s continue to stay neck and neck at third and fourth
          places, both for the second week. After both experiencing big weeks
          last week in terms of portfolio increase, NBA Champion Matthew
          Dellavedova and ESPN Fantasy Guru Matthew Berry’s portfolios took dips
          this past week. Delly lost $25.60, and Berry lost $28.96. Both of them
          have exhausted their balances thus far, and in order to continue their
          climbs back up the leaderboard and to recoup for this week’s losses,
          they should be looking to sell off some of their inventory.
        </p>
        <p>
          Even though it was a fairly slow week in terms of transactions, Tyler
          Schmitt made the biggest purchase of the week. Schmitt, who currently
          sits at 10th place, bought a Ryan Tannehill Topps Chrome Refractor
          ($27.99).
        </p>
        <p>
          The wave of COVID has slowly dissipated from the league, and soon most
          players will be back and ready to play off the COVID reserve list. As
          more and more teams begin to clear their virus reserve list,
          anticipate an influx of trades, both buys and sells, from the SIFC
          contestants. We are heading into the thick of the NFL season, which
          starts the heart of the SIFC.
        </p>
        <p>
          To stay up to date with all SIFC trades and happenings, be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) and continue to check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Week 5 update",
    date: Date.UTC(2020, 9, 16),
    body: (
      <>
        <p>
          Week 5 of the NFL was a wild one, with the third Tuesday Night
          Football game since 1946, with the Titans toppling the Bills. The SIFC
          had a less wild week comparatively, with first and second places still
          holding strong from Week 4. Chad Bleznick retains his top spot, with
          Adam Levitan filing in at second. Bleznick followed up his big
          purchasing week last week with a one purchase week, grabbing a $3
          Jalen Hurts Prizm Draft Picks Base. He saw a $1 decrease in portfolio
          value, but Levitan saw a $3 increase which helped decrease his gap
          from the top spot. Adam also had a slow buying week after a big Week
          4, adding a Jonathan Taylor Prizm Draft Picks Blue ($14).
        </p>
        <p>
          The two Matthews continue to make jumps in the standings, with NBA
          Champion Matthew Dellavedova moving to third from sixth, and ESPN
          Fantasy Guru Matthew Berry moving to fourth from fifth. Neither of
          them made any purchases this week but saw significant portfolio value
          growth. Matthew Berry’s portfolio value increased from $483 to $515.
          Delly’s portfolio value increased from $478 to $527.
        </p>
        <p>
          Fernando was the biggest buyer of the week as he added six new cards
          to his portfolio. Fernando added a Kenyan Drake Prizm Pink ($9), four
          David Montgomery Prizm Base cards ($3.75), and a Dede Westbrook Select
          Maroon ($3). Even though Fernando is sitting in ninth place, he is
          positioning himself for a big jump up the leaderboards, as only $15.65
          separates himself from fifth place Tyler Schmitt.
        </p>
        <p>
          Week 5 was a slow one for our SIFC contestants. Hopefully once the NFL
          straightens itself out with COVID protocols, the SIFC will heat back
          up again and the competition will be fierce.
        </p>
        <p>
          To stay up to date with all SIFC trades and happenings, be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) and continue to check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Week 4 update",
    date: Date.UTC(2020, 9, 8),
    body: (
      <>
        <p>
          The SIFC standings continue to shuffle around, just like the NFL
          schedule amidst the continuing rise of COVID-19 cases in the league.
          With the Titans continuing to produce positive tests, and the Patriots
          also dealing with a few positives, the SIFC contestants are attempting
          to shape their portfolios around it. Making a big jump from fourth to
          first is Chad Bleznick. Chad is banking on AJ Brown making his return
          from injury and making an immediate impact with Corey Davis on the
          COVID list, snagging two of his Panini Prizm Base cards ($9). Chad
          also acquired a few more receiver cards later in the week, a DJ Moore
          Panini Prizm Green ($7) and two OBJ Topps Chrome Base cards ($10).
          Adam Levitan falls down one spot to second place after a huge jump in
          week 3, but only trails Chad by $9 in portfolio value. Adam was this
          week’s biggest buyer, highlighting the week with a Carson Wentz
          Donruss Optic Base ($20.50), a Deshaun Watson Donruss Base ($20), and
          Tee Higgins Prizm Draft Picks Blue ($15), among others.
        </p>
        <p>
          After first and second there is a decent dropoff to our Twitter
          wildcard winner Oz, who finds himself in third place, a two place
          improvement over last week. Despite the jump, Oz only made a small $2
          sale, dumping his Bryan Edwards Prizm Draft Picks Pink Pulsar. Paul
          Charcharian of Guillotine leagues also made a two spot jump from sixth
          to fourth, making the biggest purchase of the week, a Gardner Minshew
          Select Base ($51).
        </p>
        <p>
          The two Matthews fall in at fifth and sixth place, with ESPN’s Matthew
          Berry in fifth, and NBA Champion Matthew Dellavedova in sixth. Neither
          of them made any purchases this week, which positions themselves in
          prime spots to become sellers and rapidly increase their portfolio
          value in order to make big jumps in the leaderboard to reclaim their
          once owned top spots.
        </p>
        <p>
          With COVID finding it’s anticipated way into NFL locker rooms, it will
          be interesting to see how the SIFC contestants respond. Will they look
          to sell cards of players on COVID affected teams, specifically those
          who are positive, or do some deep diving into depth charts and start
          looking for players who can make a quick impact with players out with
          the virus?
        </p>
        <p>
          Make sure to stay tuned on all the SIFC happenings by following us on
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock), and check back on the website for all the updates.
        </p>
      </>
    ),
  },
  {
    title: "Week 3 update",
    date: Date.UTC(2020, 8, 30),
    body: (
      <>
        <p>
          Another NFL Week has come and gone, and another SIFC competition week
          has passed as well, which included several big changes in the
          standings. Last week, Paul Charchian of Guillotine Leagues was leading
          the pack after making several strategic purchases, but now Adam
          Levitan has jumped from sixth place all the way to the top spot after
          a huge Monday buying spree. Adam stocked up big on Chase Edmonds with
          six Donruss Base cards ($.95), and one Prizm Base ($5). Levitan holds
          a $16 lead over another big jumper, Matthew Dellavedova, who after
          jumping from sixth to third has climbed into second place, pushing
          fantasy guru Matthew Berry down to third place. Neither of them have
          made recent purchases, however Delly saw a portfolio value increase of
          $3, and Berry saw a portfolio value decrease of $3.
        </p>
        <p>
          Coming in at fourth and fifth place respectively are Chad Bleznick and
          Twitter Winner Mick Wardrop, Oz. Bleznick doubled down on Jalen Hurts,
          grabbing two more of his Draft Picks Base ($3). Chad also stocked up
          on a couple Odell Beckham Jr Topps Chrome ($10). Oz clearly was quick
          to see the David Montgomery injury, and bought a Tarik Cohen Prizm
          Silver ($2).
        </p>
        <p>
          Sixth and seventh places belong to Paul Charchian of Guillotine
          Leagues and Joe Bryant. Paul had the biggest fall from first to sixth
          after making no purchases or completing any sales this week. Hopefully
          Drew Lock makes a speedy recovery from injury, because Paul has a
          significant stockpile of Lock Optic Base cards. Joe Bryant made one of
          the biggest purchases of the week, buying in on the Josh Allen hype
          train and buying his Select Silver card ($120).
        </p>
        <p>
          Instagram wildcard winner Ducky is the biggest buyer on the week after
          he bought two graded cards. He currently sits in 8th place, and this
          week bought a PSA 9 Josh Allen Optic Red and Yellow ($159) and a PSA 9
          DK Metcalf Prizm Silver ($185). Both players have been having great
          seasons as of now, so look for Ducky to climb the standings if their
          card values climb and continue playing well.
        </p>
        <p>
          To continue to keep up on all trades and updates, make sure to follow
          us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock), and check back on the website.
        </p>
      </>
    ),
  },
  {
    title: "Week 2 update",
    date: Date.UTC(2020, 8, 23),
    body: (
      <>
        <p>
          Week Two of the NFL saw injuries all across the board, with big names
          in both the league and card world such as Saqoun Barkley, Christian
          McCaffrey, Courtland Sutton, Drew Lock and several other players going
          down with serious injuries. With all the player shuffling that occured
          in the league, the SIFC Standings saw some shuffling as well.
        </p>
        <p>
          Paul Charchian of Guillotine Leagues jumped from the 2nd spot into 1st
          place, with his Zach Moss Prizm Draft ($4), Daniel Jones Prizm Red Ice
          ($38), and Baker Mayfield Select ($51) purchases pushing him over the
          goal line to pass ESPN Fantasy Guru Matthew Berry, who dropped to 2nd
          place. Berry boasts a portfolio that leans on rookies such as Joe
          Burrow, Zach Moss, Jalen Raegor and Jonathon Taylor. Even though he
          dropped in the standings, Berry saw a $3 increase in portfolio value.
        </p>
        <p>
          The biggest jump of the week belongs to NBA Champion Matthew
          Dellavedova, coming in at third place in Week 2 after a sixth place
          ranking after Week 1. Delly will have to depend on his strong base of
          rookie Prizm Draft Picks including Joe Burrow, CeeDee Lamb, Chase
          Claypool and Jonathon Taylor to recoup the loss of Saqoun Barkley to
          injury. Twitter winner Oz comes in at fourth place. His QB heavy
          portfolio gained more value this week, acquiring Cam Newton Topps Base
          ($50), and buying Jimmy Garoppolo Topps Base ($8) on the dip after his
          injury.
        </p>
        <p>
          Chad Bleznick and Adam Levitan come in at fifth and sixth place,
          separated by $7 in portfolio value. Bleznick, who had not made any
          purchases in the first week, jumped up to fifth with 27 purchases this
          week. Bleznick bought several Jalen Hurts Prizm Draft Picks ($3 base,
          $5 silver), Tua Tagovailoa Prizm Draft Picks ($7 base, $12 silver) and
          Jacob Eason Prizm Draft Picks Silver ($6). Bleznick also jumped on the
          Devonta Freeman train as he was signed by the Giants to replace
          Saquon, anticipating an uptick in value as the season progresses. Adam
          Levitan also made some rookie QB purchases, picking up Justin Herbert
          Prizm Draft Base ($9) and Silver ($42).
        </p>
        <p>
          Unfortunately, as players fell to injuries, as did some of our
          contestants. Brett McGrath from Stacking Slabs experienced a dip in
          standings from third to 12th. Although he fell in the standings, Brett
          maintains a promising portfolio of players who performed well this
          past week, including Kyler Murray, Terry McLaurin and Tyler Lockett.
        </p>
        <p>
          Make sure to follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          to keep up all on the SIFC Trades and Portfolio updates as they
          happen, and any and all other StarStock news!
        </p>
      </>
    ),
  },
  {
    title: "Week 1 update",
    date: Date.UTC(2020, 8, 16),
    body: (
      <>
        <p>
          The first week of both the NFL season and the SIFC are in the books!
          It’s great to have football back, and even better to have started our
          competition. The first standings update has ESPN Fantasy Analyst
          Matthew Berry in first place. His portfolio is headlined by a Kyler
          Murray Optic Base, two Joe Burrow Prizm Draft Picks, and a Dak
          Prescott Donruss BGS 9.
        </p>
        <p>
          Next up on the leaderboard are Paul Charchian of Guillotine Leagues
          and Brett McGrath from Stacking Slabs. Paul’s strategy has been to
          pick only a few players he likes, and go all-in on them. He’s only
          invested in three players so far: five Drew Lock Optic Base at $48
          apiece, ten Zack Moss Prizm Draft Picks for under $4 each, and four
          Jonnu Smith Optic Base for $2 each. Brett has a much more diverse
          portfolio, including names such as Marquise Brown, Kyler Murray, Terry
          McLaurin, Devin Singletary, Marlon Mack (tough), and Tyler Lockett
          among a few others.
        </p>
        <p>
          Tyler Schmitt of VaynerMedia is currently in fourth place. He’s made
          six purchases so far including a DK Metcalf Prizm and Optic Base, two
          Carson Wentz Donruss Base, and a Chris Godwin Optic Base. In fifth and
          sixth place we have our Instagram wildcard Ducky and NBA Champion
          Matthew Dellavedova. Ducky became the first competitor to make a flip
          when he sold a Tyreek Hill Select Concourse for a $2 profit. He also
          flipped an Alvin Kamara Optic Base, profiting over $8 after fees.
          Dellavedova has spent the majority of his $500 on just six cards. He
          picked up a Saquon Donruss PSA 10 for $180 and a Cooper Kupp Prizm
          Silver PSA 10 for $160! Delly also bought four Joe Burrow Prizm Draft
          Picks at an average cost of $34.50.
        </p>
        <p>
          Adam Palmer from the Heros For Sale Podcast was the only other
          competitor to make a quick flip in Week 1. He sold two Will Fuller
          Optic Base cards at a price of $9.50 and profited over 50% on that
          investment! Next in the standings is Nando Di Fino of The Athletic.
          Nando’s portfolio took a hit when Michael Thomas went down with an
          injury. Hopefully the injury isn’t too serious, but the investment in
          Thomas’s Select Silver isn’t looking great at the moment. Our Twitter
          wildcard winner OZ is putting his faith in the quarterback market.
          He’s purchased a Carson Wentz Prizm Silver PSA 9 ($200), Russell
          Wilson Topps Base ($80), and Dak Prescott Select Concourse ($60).
        </p>
        <p>
          Follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock) for more updates on portfolios and trades as they happen!
        </p>
      </>
    ),
  },
];

const contestants = [
  {
    id: 1,
    photo: JoeBryant,
    name: "Joe Bryant",
    role: "Football Guys",
    bio: (
      <>
        Joe Bryant is Football Guys founder and CEO. Football Guys is one of the
        leading fantasy football advice sites in the market – the one none of
        your league mates want to tell you about!
      </>
    ),
    twitter: "Football_Guys",
    instagram: "",
    balance: 130,
    cards_sold: 0,
    cards_owned: 4,
    account_value: 325.0,
  },
  {
    id: 2,
    photo: BrettMcGrath,
    name: "Brett McGrath",
    role: "Stacking Slabs",
    bio: (
      <>
        Stacking Slabs host Brett McGrath is our second SIFC contestant.
        Stacking Slabs is a show that shares stories to help you become an
        educated sports cards investor and maximize those gains.
      </>
    ),
    twitter: "StackingSlabs",
    instagram: "StackingSlabs",
    balance: 0.31,
    cards_sold: 0,
    cards_owned: 30,
    account_value: 235.13,
  },
  {
    id: 3,
    photo: NandoDiFino,
    name: "Nando Di Fino",
    role: "The Athletic",
    bio: (
      <>
        Nando is a fantasy sports analyst and has advised on pickups in the WSJ,
        MLB Network, CBS Sports, ESPN, the New York Post, and the Associated
        Press.
      </>
    ),
    twitter: "nandodifino",
    instagram: "",
    balance: 36.56,
    cards_sold: 2,
    cards_owned: 35,
    account_value: 490.79,
  },
  {
    id: 4,
    photo: AdamPalmer,
    name: "Adam Palmer",
    role: "Heros for Sale",
    bio: (
      <>
        Adam Palmer is the host of one of the leading sports cards podcasts,
        Heros for Sale, so is going to be using that inside card knowledge.
      </>
    ),
    twitter: "herosforsale",
    instagram: "herosforsale",
    balance: 222.48,
    cards_sold: 6,
    cards_owned: 40,
    account_value: 311.08,
  },
  {
    id: 5,
    photo: MattBerry,
    name: "Matthew Berry",
    role: "ESPN",
    bio: (
      <>
        Matthew Berry is ESPN’s senior fantasy football analyst, NYT best
        selling author and Avengers End Game agent.
      </>
    ),
    twitter: "MatthewBerryTMR",
    instagram: "matthewberrytmr",
    balance: 0.46,
    cards_sold: 0,
    cards_owned: 26,
    account_value: 327.32,
  },
  {
    id: 6,
    photo: TylerSchmitt,
    name: "Tyler Schmitt",
    role: "Vayner Media",
    bio: (
      <>
        Our sixth SIFC contestant is Tyler Schmitt. Tyler runs One37pm and is
        host of Card Talk podcast.
      </>
    ),
    twitter: "tySchmitt5",
    instagram: "tyler",
    balance: 7.23,
    cards_sold: 8,
    cards_owned: 19,
    account_value: 397.22,
  },
  {
    id: 7,
    photo: AdamLevitan,
    name: "Adam Levitan",
    role: "Establish the Run",
    bio: (
      <>
        Adam is founder and lead analyst of premier fantasy football advice site
        Establish the Run (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/EstablishTheRun"
        >
          @EstablishTheRun
        </a>
        ). With over 100,000 followers on Twitter Adam’s picks are widely
        followed.
      </>
    ),
    twitter: "adamlevitan",
    instagram: "",
    balance: 4.86,
    cards_sold: 0,
    cards_owned: 37,
    account_value: 380.49,
  },
  {
    id: 8,
    photo: PaulCharchian,
    name: "Paul Charchian",
    role: "Guillotine Leagues",
    bio: (
      <>
        Paul Charchian is Chief Execution Officer of{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://guillotineleagues.com/"
        >
          Guillotine Leagues
        </a>{" "}
        and host of Fantasy Football Weekly and CHOP podcasts.
      </>
    ),
    twitter: "PaulCharchian",
    instagram: "",
    balance: 26.55,
    cards_sold: 0,
    cards_owned: 25,
    account_value: 237.55,
  },
  {
    id: 9,
    photo: MatthewDellavedova,
    name: "Matthew Dellavedova",
    role: "Cleveland Cavaliers",
    bio: (
      <>
        Matthew Dellavedova plays point guard with the Cleveland Cavaliers,
        including winning the NBA Championship in 2016.
      </>
    ),
    twitter: "matthewdelly",
    instagram: "matthewdelly",
    balance: 38.26,
    cards_sold: 2,
    cards_owned: 9,
    account_value: 177.91,
  },
  {
    id: 10,
    photo: ChadBleznick,
    name: "Chad Bleznick",
    role: "Blez Cards",
    bio: (
      <>
        Our tenth SIFC contestant is Chad Bleznick. Chad is a 20 year veteran of
        the sports card hobby and runs Blez Sports Cards.
      </>
    ),
    twitter: "cblez",
    instagram: "cblez",
    balance: 314.58,
    cards_sold: 7,
    cards_owned: 36,
    account_value: 580.16,
  },
  {
    id: 11,
    photo: Ducky,
    name: "Ducky",
    role: "Instagram Wildcard",
    bio: (
      <>
        Ducky picked up a wildcard entry to the contest from our Instagram
        giveaway. Has he got what it takes to challenge the pros?
      </>
    ),
    twitter: "",
    instagram: "duckysportcards",
    balance: 3.86,
    cards_sold: 13,
    cards_owned: 50,
    account_value: 421.04,
  },
  {
    id: 12,
    photo: Oz,
    name: "Oz",
    role: "Twitter Wildcard",
    bio: (
      <>
        Oz snagged the final spot in the contest via our Twitter giveaway. Can
        he build on that luck to reach to the top of the leaderboard?
      </>
    ),
    twitter: "Ozsportsfan1",
    instagram: "",
    balance: 137.51,
    cards_sold: 11,
    cards_owned: 5,
    account_value: 353.01,
  },
];

const prizes = [
  {
    rank: "1st",
    name: "2017 Prizm Pat Mahomes Silver PSA 10",
    value: "$7,000 + the trophy!",
    photo: PrizeOne,
  },
  {
    rank: "2nd",
    name: "2018 Prizm Lamar Jackson PSA 10",
    value: "$2,000",
    photo: PrizeTwo,
  },
  {
    rank: "3rd",
    name: "2017 Prizm Christian McCaffrey Silver PSA 10",
    value: "$1,000",
    photo: PrizeThree,
  },
  {
    rank: "4th",
    name: "2019 Prizm Daniel Jones PSA 10",
    value: "$500",
    photo: PrizeFour,
  },
  {
    rank: "5th",
    name: "2018 Donruss Optic Saquon Barkley PSA 10",
    value: "$250",
  },
  {
    rank: "6th",
    name: "2019 Prizm Kyler Murray Raw",
    value: "$125",
  },
  {
    rank: "7th",
    name: "2019 Prizm Drew Lock RAW",
    value: "$60",
  },
  { rank: "8th", name: "2015 Topps Chrome Todd Gurley PSA 10", value: "$30" },
  { rank: "9th", name: "2019 Prizm Gardner Minshew RAW", value: "$15" },
  { rank: "10th", name: "2017 Prizm Nate Peterman Silver RAW", value: "$5" },
  { rank: "11th", name: "???", value: "$2.50" },
  { rank: "12th", name: "???", value: "$1" },
];

const SifcBrandColorLight = "#7dc681";
const SifcBrandColor = "#58B55C";
const SifcBrandColorDark = "#20b025";

const BannerStart = "#79ca7c";
const BannerEnd = "#2f8a32";

const StyledBanner = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  background: ${(props) =>
    props.background ||
    css`
    linear-gradient(170deg, ${BannerStart}, ${BannerEnd});
    `};

  @media (min-width: 36rem) {
    padding: 0.5rem 4rem;
  }
`;

export const SifcAnnouncementBanner = () => {
  return (
    <StyledBanner className="shadow text-center">
      <p className="mb-0">
        Check out the latest from the SIFC as we get closer to the end of the
        contest!{" "}
        <a href="/sifc" className="font-weight-bold text-white ml-2">
          Read more
          <FontAwesomeIcon
            icon={faAngleDoubleRight}
            size={"sm"}
            className="ml-1"
          />
        </a>
      </p>
    </StyledBanner>
  );
};

const HeaderContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0b1f3c;
  background-position: 70% 20%;

  height: 12rem;
  @media (min-width: 48rem) {
    height: 20rem;
  }
`;

const ContestTitle = styled.h1`
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 5rem;
  line-height: 4rem;
  font-style: italic;
  text-transform: uppercase;
  color: white;
  display: block;
  margin: 0 -0.25rem;
  padding-bottom: 1rem;

  @media (max-width: 28rem) {
    font-size: 4rem;
    line-height: 4rem;
  }

  @media (min-width: 48rem) {
    font-size: 10rem;
    line-height: 8rem;
  }

  border-bottom: 4px solid ${SifcBrandColor};
`;

const ContestTagline = styled.h2`
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: white;
  display: block;
  margin-top: 2px;
  padding-top: 1rem;

  @media (min-width: 48rem) {
    font-size: 1.5rem;
    line-height: 1.25rem;
  }

  border-top: 2px solid ${SifcBrandColor};
`;

const SectionHeading = styled.h3`
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.5rem;
  border-bottom: 2px solid ${SifcBrandColor};
  padding-bottom: 1rem;
  margin: 2px -0.125rem;

  @media (min-width: 48rem) {
    font-size: 2rem;
  }
`;

const SectionBodyText = styled.div`
  border-top: 1px solid ${SifcBrandColor};
  padding-top: 1rem;
  margin-bottom: 0;
`;

const NameLabel = styled.h4`
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 1.75rem;
  text-align: center;
`;

const RoleLabel = styled.h5`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1.5rem;
`;

const RankLabel = styled.h5`
  font-family: "Barlow", sans-serif;
  font-weight: "Black";
  font-size: "1.25rem";
  width: 2.5rem;
`;

const ScoreNameLabel = styled.h5`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1.25rem;
  margin-bottom: 0;

  @media (max-width: 72rem) {
    font-size: 1.125rem;
  }
`;

const ScoreCardsLabel = styled.p`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1rem;
  margin-bottom: 0;
`;

const ScoreLabel = styled.h4`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1.5rem;
  text-align: right;
  margin-bottom: 0;
`;

const BulletList = styled.ul`
  list-style: none;
  line-height: 1.75rem;

  li::before {
    content: "\\25CB"; /* CSS Code/unicode for a bullet */
    color: ${SifcBrandColor};
    font-weight: bold;
    display: inline-block;
    width: 2em;
    margin-left: -2rem;
  }
`;

const CountdownSection = styled.div`
  background: linear-gradient(
    135deg,
    ${SifcBrandColorLight},
    ${SifcBrandColorDark}
  );
`;

const SifcLandingPage = () => {
  useEffect(
    () =>
      logAmplitudeEvent(CONTENT_EVENTS.INVITATIONAL_CHAMPIONSHIP.PAGE_VIEWED, {
        Championship: "SIFC 2020",
      }),
    [],
  );

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <ContentPageHelmet />

      <HeaderContainer className="row text-left">
        <div className="col p-3 inner-container-wide mx-auto d-flex flex-column justify-content-end">
          <ContestTitle>SIFC 2020</ContestTitle>
          <ContestTagline>
            StarStock Invitational Football Championship 2020
          </ContestTagline>
        </div>
      </HeaderContainer>

      <PageSection
        width={PageSectionWidths.wide}
        type={PageSectionTypes.background}
        removePaddingBottom={true}
      >
        <div className="row">
          <div className="col-lg order-lg-2 d-flex flex-column">
            <CountdownSection className="shadow px-4 py-3 rounded mb-4">
              <SectionHeading
                className="text-center"
                style={{ color: "white", borderBottom: "2px solid white" }}
              >
                CONTEST LIVE!
              </SectionHeading>
              <SectionBodyText
                className="text-center text-deselected py-2"
                style={{
                  fontFamily: "Barlow, sans-serif",
                  fontWeight: "Black",
                  fontStyle: "italic",
                  fontSize: "2rem",
                  color: "white",
                  borderTop: "1px solid white",
                }}
              >
                Week 15 of 17
              </SectionBodyText>
            </CountdownSection>

            <div
              className="bg-white shadow px-3 px-sm-4 py-3 rounded mb-5 d-flex flex-column justify-content-start align-items-stretch"
              style={{ border: `1px solid ${SifcBrandColor}` }}
            >
              <SectionHeading>Leaderboard</SectionHeading>
              <SectionBodyText />
              <p className="small text-speak text-uppercase text-center mb-0">
                Ranked by portfolio value + balance
              </p>
              <div className="mt-3">
                {contestants
                  .sort((a, b) => b.account_value - a.account_value)
                  .map((contestant, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row align-items-center border-top py-2"
                    >
                      <RankLabel className="text-whisper mb-1 mr-3 text-center flex-shrink-0 d-none d-sm-block">
                        {moment.localeData().ordinal(index + 1)}
                      </RankLabel>
                      <div
                        className="bg-light rounded-circle border overflow-hidden mr-3 flex-shrink-0"
                        style={{ width: "3rem", height: "3rem" }}
                      >
                        <img
                          src={contestant.photo}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="flex-shrink-1 flex-grow-1 text-truncate mr-4">
                        <ScoreNameLabel className="text-truncate">
                          {contestant.name}
                        </ScoreNameLabel>
                        <ScoreCardsLabel className="text-whisper text-truncate">
                          {pluralize("card", contestant.cards_owned)},{" "}
                          {pluralize("sale", contestant.cards_sold)}
                        </ScoreCardsLabel>
                      </div>
                      <ScoreLabel>
                        {currencyFormatter.format(contestant.account_value)}
                      </ScoreLabel>
                    </div>
                  ))}
              </div>
              <p className="small text-speak text-uppercase text-center pt-3 mb-0 border-top">
                Last updated Thursday, December 17th
              </p>
            </div>
          </div>

          <div className="col-lg-7 order-lg-1">
            <div className="mb-5">
              <SectionHeading>Introducing the SIFC</SectionHeading>
              <SectionBodyText>
                <p>
                  Welcome to the StarStock Invitational Football Championship!
                  Over the next 3.5 months 12 contestants will compete to see
                  who is the football card trading champion with a first place
                  prize of a Patrick Mahomes II rookie PSA 10 worth over $7,000.
                </p>
                <p>
                  We have chosen 10 leading names from the fields of sports
                  writing, fantasy sports and trading cards to compete. Each
                  contestant will be given $500 at the start of the season to
                  trade rookies on StarStock.
                </p>
                <p>
                  Taking up the final two spots are wildcard entries given away
                  to our Twitter and Instagram followers. Can this lucky pair
                  can give the pros a run for their money?
                </p>
                <p className="mb-0">
                  At the end of the NFL regular season the contestant who has
                  built the most valuable portfolio wins the prize. Follow the
                  action with our weekly updates here and on social media to see
                  the contestants’ portfolios and big trades!
                </p>
                <p></p>
              </SectionBodyText>
            </div>

            {updates.length > 0 && (
              <div className="mb-5">
                <SectionHeading>Latest updates</SectionHeading>
                <SectionBodyText />

                {updates.map((update) => (
                  <div
                    key={update.title}
                    className="mb-3 py-3"
                    style={{ borderBottom: `1px solid ${SifcBrandColor}` }}
                  >
                    <h5 className="font-weight-bold">{update.title}</h5>
                    <p className="small text-speak text-uppercase mb-3">
                      {moment(update.date).format("dddd, MMMM Do")}
                    </p>
                    <div className="mb-3">{update.body}</div>
                  </div>
                ))}
              </div>
            )}

            <div className="mb-5">
              <SectionHeading>Contest rules</SectionHeading>
              <SectionBodyText>
                <p>
                  The trades are theirs to decide, but that doesn’t mean
                  anything goes! To keep everyone playing fair there’s a few
                  simple rules everyone’s gotta stick to.
                </p>
                <BulletList>
                  <li>Trades are for NFL cards only</li>
                  <li>
                    No deposits – the $500 starting fund is all they have to
                    play with
                  </li>
                  <li>
                    No sending in additional cards to boost their collection
                  </li>
                  <li>
                    No related party trades – e.g. getting a friend to pay over
                    the odds
                  </li>
                  <li>We may review transactions to ensure they are fair</li>
                  <li>
                    Promoting listed cards on social media is allowed and part
                    of the game!
                  </li>
                </BulletList>
              </SectionBodyText>
            </div>

            <div className="mb-5">
              <SectionHeading>Prizes</SectionHeading>
              <SectionBodyText>
                <p>
                  These trades are with real cards and real money, so everyone
                  can come away a winner! But it isn’t a competition without
                  some prizes to fight over, so lets see what bonus awards are
                  at stake!
                </p>
                <div className="row">
                  {prizes
                    .filter((prize) => prize.photo)
                    .map((prize) => (
                      <div
                        key={prize.rank}
                        className="col-sm-3 text-center mb-3"
                      >
                        <h3
                          style={{
                            fontFamily: "Barlow, sans-serif",
                            fontWeight: "Black",
                            fontStyle: "italic",
                            fontSize: "2rem",
                          }}
                        >
                          {prize.rank}
                        </h3>
                        <img
                          src={prize.photo}
                          className="img-fluid mb-2"
                          alt=""
                        />
                        <p className="mb-1">{prize.name}</p>
                        <p
                          style={{
                            fontFamily: "Barlow, sans-serif",
                            fontWeight: "Black",
                            fontStyle: "italic",
                            fontSize: "1rem",
                          }}
                        >
                          Approx value {prize.value}
                        </p>
                      </div>
                    ))}
                </div>
                <table className="table table-sm table-borderless">
                  <thead className="border-bottom">
                    <tr>
                      <th>Rank</th>
                      <th>Prize</th>
                      <th className="text-right">Approx value</th>
                    </tr>
                  </thead>
                  <tbody style={{ lineHeight: "1.5rem" }}>
                    {prizes
                      .filter((prize) => !prize.photo)
                      .map((prize) => (
                        <tr key={prize.rank}>
                          <td className="font-weight-bold">{prize.rank}</td>
                          <td>{prize.name}</td>
                          <td className="text-right">{prize.value}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </SectionBodyText>
            </div>
          </div>
        </div>
      </PageSection>

      <PageSection
        width={PageSectionWidths.wide}
        type={PageSectionTypes.foreground}
        removePaddingBottom={true}
      >
        <div>
          <SectionHeading>The contestants</SectionHeading>
          <SectionBodyText />
          <div className="row mt-3 d-flex flex-row">
            {contestants.map((contestant) => (
              <div
                key={contestant.id}
                className="col-sm-6 col-md-4 col-xl-3 d-flex flex-column justify-content-start align-items-center pb-4 mb-4 border-bottom"
              >
                <div
                  className="bg-light rounded-circle border mb-3 overflow-hidden"
                  style={{ width: "8rem", height: "8rem" }}
                >
                  <img src={contestant.photo} className="img-fluid" alt="" />
                </div>
                <NameLabel>{contestant.name}</NameLabel>
                <RoleLabel>{contestant.role}</RoleLabel>
                <p
                  className="pt-3 mb-2"
                  style={{ borderTop: `1px solid ${SifcBrandColor}` }}
                >
                  {contestant.bio}
                </p>
                <div className="btn-toolbar justify-content-center w-100 mt-auto">
                  {contestant.twitter && (
                    <a
                      href={`https://twitter.com/${contestant.twitter}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm bg-light border text-primary mt-2 mx-1"
                    >
                      <FontAwesomeIcon
                        icon={faTwitter}
                        className="fa fa-lg mr-2"
                      />
                      @{contestant.twitter}
                    </a>
                  )}
                  {contestant.instagram && (
                    <a
                      href={`https://instagram.com/${contestant.instagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm bg-light border text-primary mt-2 mx-1"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="fa fa-lg mr-2"
                      />
                      {contestant.instagram}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </PageSection>
    </>
  );
};

export default SifcLandingPage;
