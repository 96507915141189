import { ChangeEvent } from "react";
import { useQuery } from "@apollo/client";
import { MenuItem } from "@material-ui/core";

import Loader from "../../../../components/Loader";

import GET_MY_PAYMENT_METHODS from "../../api/GET_MY_PAYMENT_METHODS";
import { PaymentMethodsResponse } from "../../types";
import { PaymentMethodInfo } from "../PaymentMethodInfo";
import { WalletInfo } from "../WalletInfo";
import { StyledSelect, StyledFormControl, StyledInputLabel } from "./styles";
import { checkCardIsExpired } from "../../utils/checkCardIsExpired";

export interface PaymentMethodSelectProps {
  selectedMethod: string;
  handleChange: (e: ChangeEvent<{ value: unknown }>) => void;
  hideWallet?: boolean;
  inputLabel?: string;
  hideExpired?: boolean;
}

export const PaymentMethodSelect = ({
  selectedMethod,
  handleChange,
  hideWallet = false,
  inputLabel = "Payment Method",
  hideExpired = false,
}: PaymentMethodSelectProps) => {
  const { data, loading, error } = useQuery<PaymentMethodsResponse>(
    GET_MY_PAYMENT_METHODS,
  );

  const paymentMethods = data?.myPaymentMethods!;

  if (loading) return <Loader />;

  return (
    <StyledFormControl variant={"outlined"}>
      <StyledInputLabel id="payment-method-select-label-id">
        {inputLabel}
      </StyledInputLabel>

      <StyledSelect
        id="payment-method-select"
        labelId="payment-method-select-label-id"
        onChange={handleChange}
        value={selectedMethod}
        // all these MenuProps do is make the dropdown list open below the dropdown
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {!hideWallet && (
          <MenuItem key="wallet" value="wallet">
            <WalletInfo />
          </MenuItem>
        )}

        {error && (
          <MenuItem key="error" value="error">
            Unable to retrieve payment methods
          </MenuItem>
        )}

        {paymentMethods &&
          paymentMethods
            .filter((paymentMethod) => {
              return (
                !hideExpired ||
                !(
                  paymentMethod &&
                  checkCardIsExpired(
                    paymentMethod.expiryMonth,
                    paymentMethod.expiryYear,
                  )
                )
              );
            })
            .map((method) => (
              <MenuItem
                key={method?.paymentMethodId}
                value={method?.paymentMethodId}
              >
                <PaymentMethodInfo
                  paymentMethodSelect={true}
                  paymentDetails={method!}
                />
              </MenuItem>
            ))}
      </StyledSelect>
    </StyledFormControl>
  );
};
