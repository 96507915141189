import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const CloseButton = styled.button`
  background: none;
  border: none;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);

  svg {
    fill: rgb(220 221 222 / 100%);
    transform: rotate(45deg);
    width: 1.5rem;
  }

  :active,
  :focus,
  :hover {
    svg {
      fill: ${Colors.Astronaut};
    }
  }
`;

export const Container = styled.div`
  background: white;
  border: 1px solid rgb(220 221 222 / 50%);
  border-radius: 0.5rem;
  max-width: 50rem;
  width: 100%;
`;

export const Contents = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin-top: 1rem;

  /* To style the MarketCardParallel */
  & > div {
    border-left-width: 1px;
    border-radius: 0.25rem;
    border-right-width: 1px;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Heading = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.11;
  margin: 0;
`;

export const Section = styled.section`
  padding: 1.25rem;
  position: relative;

  & + & {
    border-top: 1px solid rgb(220 221 222 / 50%);
  }
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  padding: 0;
`;

export const ToggleButton = styled.button`
  all: inherit;
  display: flex;
  justify-content: space-between;
  width: 100%;

  svg {
    fill: ${Colors.Astronaut};
    width: 1.25rem;
  }

  :focus svg {
    outline: 2px solid;
  }

  [aria-expanded] rect {
    fill: currentColor;
  }

  [aria-expanded="true"] .vert {
    display: none;
  }
`;
