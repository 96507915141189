import { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import classNames from "classnames";

import StepLoading from "./StepLoading";
import StepSummary from "./StepSummary";
import StepEdit from "./StepEdit";
import StepSubmitting from "./StepSubmitting";
import StepComplete from "./StepComplete";
import StepInvalid from "./StepInvalid";

export const ShipHomeSubmitFormSteps = {
  invalid: "invalid",
  loading: "loading",
  summary: "summary",
  edit: "edit",
  submitting: "submitting",
  complete: "complete",
};

const Header = ({ title, showClose }) => {
  return (
    <Modal.Header className="border-bottom pb-0" closeButton={showClose}>
      <div className={classNames("container-fluid", { "mr-n4": showClose })}>
        <div className="text-center">
          <h3 className="font-weight-bold">{title}</h3>
        </div>
      </div>
    </Modal.Header>
  );
};

const ShipHomeSubmitForm = ({
  show,
  setShow,
  initialStep = ShipHomeSubmitFormSteps.loading,
  onComplete,
}) => {
  // Merges given form field values into the existing form data
  const setFormDataFields = (updatedFields) =>
    setFormData((current) => ({ ...current, ...updatedFields }));

  const closeModal = () => {
    setShow(false);
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };

  const closeAndResetForm = () => {
    setStep(ShipHomeSubmitFormSteps.loading);
    resetForm();
    closeModal();
  };

  const onCloseSuccessful = () => {
    closeAndResetForm();
    onComplete();
  };

  const initialFormData = {
    recipient: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
    errors: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [step, setStep] = useState(initialStep);
  const [draftShippingRequest, setDraftShippingRequest] = useState(null);

  // Catch cases where the current step might not be accurate and step back as necessary.
  useEffect(() => {
    switch (step) {
      case ShipHomeSubmitFormSteps.complete:
        // Indication that the form hasn't been submitted but the user is on the completed page. Need to step back.
        if (formData.errors === null && !formData.completedErrors) {
          setStep(ShipHomeSubmitFormSteps.submitting);
        }
        break;
      case ShipHomeSubmitFormSteps.submitting:
        // On the submit step but there are errors. Step back and display them.
        if (formData.errors) {
          setStep(ShipHomeSubmitFormSteps.edit);
        }
        break;
      case ShipHomeSubmitFormSteps.edit:
        // Draft shipping request hasn't been loaded, return to loading step.
        if (!draftShippingRequest) {
          setStep(ShipHomeSubmitFormSteps.loading);
        }
        break;
      case ShipHomeSubmitFormSteps.summary:
        // Draft shipping request hasn't been loaded, return to loading step.
        if (!draftShippingRequest) {
          setStep(ShipHomeSubmitFormSteps.loading);
        }
        break;
      case ShipHomeSubmitFormSteps.loading:
        // The draft shipping request has loaded but there are errors
        if (draftShippingRequest && formData.errors) {
          setStep(ShipHomeSubmitFormSteps.invalid);
        }
        break;
      case ShipHomeSubmitFormSteps.invalid:
      default:
        break;
    }
  }, [step, setStep, formData, draftShippingRequest]);

  return (
    <Modal
      show={show}
      onHide={() => closeAndResetForm()}
      onExited={() => closeAndResetForm()}
      backdrop={"static"}
      keyboard={step !== ShipHomeSubmitFormSteps.submitting}
      centered
      scrollable={true}
    >
      <Header
        title={"Submit ship home request"}
        showClose={step !== ShipHomeSubmitFormSteps.submitting}
      />

      {step === ShipHomeSubmitFormSteps.invalid && <StepInvalid />}
      {step === ShipHomeSubmitFormSteps.loading && (
        <StepLoading
          setFormData={setFormData}
          setDraftShippingRequest={setDraftShippingRequest}
          onLoaded={() => setStep(ShipHomeSubmitFormSteps.summary)}
          onFailed={() => setStep(ShipHomeSubmitFormSteps.invalid)}
        />
      )}
      {step === ShipHomeSubmitFormSteps.summary && (
        <StepSummary
          shippingRequestFee={draftShippingRequest.shippingRequestFee}
          onNext={() => setStep(ShipHomeSubmitFormSteps.edit)}
          onCancel={() => closeAndResetForm()}
          onFailed={() => setStep(ShipHomeSubmitFormSteps.invalid)}
        />
      )}
      {step === ShipHomeSubmitFormSteps.edit && (
        <StepEdit
          formData={formData}
          draftShippingRequest={draftShippingRequest}
          setFormDataFields={setFormDataFields}
          onNext={() => setStep(ShipHomeSubmitFormSteps.submitting)}
          onBack={() => {
            setStep(ShipHomeSubmitFormSteps.summary);
          }}
          onFailed={() => setStep(ShipHomeSubmitFormSteps.invalid)}
        />
      )}
      {step === ShipHomeSubmitFormSteps.submitting && (
        <StepSubmitting
          formData={formData}
          setFormDataFields={setFormDataFields}
          onComplete={() => setStep(ShipHomeSubmitFormSteps.complete)}
          onError={() => setStep(ShipHomeSubmitFormSteps.edit)}
        />
      )}
      {step === ShipHomeSubmitFormSteps.complete && (
        <StepComplete
          draftShippingRequest={draftShippingRequest}
          formData={formData}
          onClose={() => closeAndResetForm()}
          onComplete={() => onCloseSuccessful()}
        />
      )}
    </Modal>
  );
};

export default ShipHomeSubmitForm;
