import { useState } from "react";

import classNames from "classnames";
import { Modal } from "react-bootstrap";

const CardImageModal = ({ show, setShow, frontImage, reverseSidePhoto }) => {
  const [showFrontImage, setShowFrontImage] = useState(true);
  const flipText = showFrontImage ? "Flip to back" : "Flip to front";

  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      <Modal.Body className="text-center">
        <div>
          <img
            className={classNames("mb-3 img-fluid", {
              "d-none": !showFrontImage,
            })}
            src={frontImage}
            alt="Product front side"
          />
          <img
            className={classNames("mb-3 img-fluid", {
              "d-none": showFrontImage,
            })}
            src={reverseSidePhoto}
            alt="Product reverse side"
          />
        </div>
        {reverseSidePhoto ? (
          <button
            type="button"
            className="btn btn-outline-primary mb-3"
            onClick={() => setShowFrontImage(!showFrontImage)}
          >
            {flipText}
          </button>
        ) : null}
        <div>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setShow(false)}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CardImageModal;
