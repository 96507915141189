import { Link } from "react-router-dom";
import styled from "styled-components";

import { financial } from "../../../../utils/common";

interface Props {
  onClose: () => void;
  requestCost: number;
  slug?: string;
}

const GradientStart = "#d5784a";
const GradientEnd = "#9544cd";

const SuccessAlert = styled.div`
  background: linear-gradient(135deg, ${GradientStart}, ${GradientEnd});
  padding: 1rem;
`;

const Success = ({ onClose, requestCost, slug }: Props) => {
  return (
    <div className={slug ? "p-0" : "p-3"}>
      <SuccessAlert className="alert alert-primary text-center p-4">
        <h3 className="text-white font-weight-bold">
          You bought a break for ${financial(requestCost)}.
        </h3>
        <p className="text-white mb-0">
          The live stream will be available on the break page shortly before the
          scheduled start time. See you there!
        </p>
      </SuccessAlert>

      {slug ? (
        <div className="d-flex flex-row justify-content-center">
          <button className="btn btn-primary px-5" onClick={() => onClose()}>
            Start another request
          </button>

          <Link to={`/breaks/${slug}`} className="btn btn-primary px-5 ml-1">
            View the break
          </Link>
        </div>
      ) : (
        <div className="d-flex flex-row justify-content-center">
          <button className="btn btn-primary px-5" onClick={() => onClose()}>
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default Success;
