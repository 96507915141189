import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import GET_PROMOTION from "../api/queries/GET_PROMOTION";
import DepositPrices from "../components/DepositPrices";
import DepositSection from "../components/DepositSection";
import { useFromLocation } from "../hooks/useFromLocation";
import { BackgroundImageWrapper } from "../utils/wrappers";
import { logAmplitudeEvent } from "../utils/analytics";
import { ONBOARDING_EVENTS } from "../utils/analytics/events";

import logo from "../images/logo-light.svg";

const SignUpDeposit = () => {
  const { from } = useFromLocation();
  const { data } = useQuery(GET_PROMOTION);
  const [amount, setAmount] = useState(25);
  const promoMessage = data && data.promotion && data.promotion.description;

  useEffect(() => {
    logAmplitudeEvent(ONBOARDING_EVENTS.DEPOSIT.PAGE_VIEWED);
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign Up Deposit</title>
      </Helmet>

      <div className="container-fluid">
        <BackgroundImageWrapper />

        <div className="row">
          <div className="col">
            <div className="text-center">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid my-3 my-sm-5" />
              </Link>
            </div>

            <div className="card mx-auto max-width-26 mb-5">
              <div className="card-body text-center">
                <h1 className="card-title h5 mt-2 mb-3">
                  <span className="font-weight-bold">
                    Start trading instantly
                  </span>{" "}
                  by adding funds to your StarStock wallet
                </h1>

                <p className="text-center mb-4">
                  StarStock is offering free deposits as a special launch
                  promotion! You can withdraw funds for free at any time.
                </p>

                {promoMessage ? (
                  <p className="alert alert-success text-center mb-4">
                    {promoMessage}
                  </p>
                ) : null}

                <DepositPrices amount={amount} setAmount={setAmount} />

                <DepositSection
                  amount={amount}
                  successPath={"/signup/deposit/success"}
                  depositSuccessEventName={ONBOARDING_EVENTS.DEPOSIT.SUCCEEDED}
                  depositFailureEventName={ONBOARDING_EVENTS.DEPOSIT.FAILED}
                />

                <Link
                  className="card-link d-inline-block mt-3"
                  to={from}
                  onClick={() =>
                    logAmplitudeEvent(ONBOARDING_EVENTS.DEPOSIT.SKIPPED)
                  }
                >
                  Or continue without adding funds
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpDeposit;
