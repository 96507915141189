import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const Container = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
`;

export const Label = styled.span`
  color: ${Colors.Blue};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
  margin-left: 0.5rem;
`;
