import { useQuery } from "@apollo/client";

import { ID, LotNode } from "../../../generated/schema";

import { GET_LOT } from "../api/GET_LOT";
import { LotParams } from "../types";

interface QueryResponse {
  lot: LotNode;
}

export const useFetchLot = (id: ID) => {
  const { data, error, loading } = useQuery<QueryResponse, LotParams>(GET_LOT, {
    variables: { id },
    fetchPolicy: "cache-first",
  });

  return { data, error, loading };
};
