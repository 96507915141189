import { useQuery } from "@apollo/client";
import moment from "moment";

import { logAmplitudeEvent } from "../../../utils/analytics";
import { MARKETPLACE_EVENTS } from "../../../utils/analytics/events";

import GET_MARKET_INDEX_LATEST_SNAPSHOT from "../../../api/queries/GET_MARKET_INDEX_LATEST_SNAPSHOT";

import ErrorNotice from "../../ErrorNotice";
import Loader from "../../Loader";
import Widget, { WidgetThemes } from "./Widget";
import { Carousel, CarouselThemes } from "../../Carousel";
import Panel from "../../Panel";
import IndexSummaryStats, {
  IndexSummaryStatsThemes,
} from "../../MarketIndex/IndexSummaryStats";
import {
  PlayerIndexValues,
  PlayerInfo,
  PlayerInfoListItem,
  PlayerHeadshot,
  StyledPlayerInfoList,
} from "../../PlayerInfoList";

const Havelock = "#4e8ad4";
const Mantis = "#58B55C";
const Danger = "#EB7373";
const DefaultThemeColor = "#ffffff";
const DefaultWidgetTheme = WidgetThemes.dark;
const widgetName = "MarketIndex";

const MarketIndexComponentSnapshotList = ({
  indexSlug,
  listName,
  componentSnapshots,
}) => {
  return (
    <StyledPlayerInfoList>
      {componentSnapshots.map((componentSnapshot, i) => (
        <MarketIndexComponentSnapshotListItem
          key={i}
          indexSlug={indexSlug}
          listName={listName}
          listPosition={i}
          component={componentSnapshot.marketIndexComponent}
          componentSnapshot={componentSnapshot}
        />
      ))}
    </StyledPlayerInfoList>
  );
};

export const MarketIndexComponentSnapshotListItem = ({
  indexSlug,
  component,
  componentSnapshot,
  listName,
  listPosition,
}) => {
  const { player } = component;
  const { currentTeam: team } = player;
  const { gradedCardParallel } = component;

  const getCardLink = (cardSlug, gradeId) => {
    return `/marketplace/cards/${cardSlug}?grade=${gradeId}`;
  };

  return gradedCardParallel ? (
    <PlayerInfoListItem>
      <PlayerHeadshot
        player={player}
        team={team}
        linkUrl={getCardLink(
          gradedCardParallel.cardParallel.slug,
          gradedCardParallel.grade.id,
        )}
      />
      <PlayerInfo
        player={player}
        linkUrl={getCardLink(
          gradedCardParallel.cardParallel.slug,
          gradedCardParallel.grade.id,
        )}
        onClick={() =>
          logAmplitudeEvent(MARKETPLACE_EVENTS.STOREFRONT.WIDGET_ITEM_CLICKED, {
            "Widget Name": `${widgetName}-${indexSlug}-${listName}`,
            "Widget Item Type": "Index component",
            "Widget Item Name": component.player.name,
            "Widget Item Position": listPosition,
          })
        }
      />
      <PlayerIndexValues componentSnapshot={componentSnapshot} />
    </PlayerInfoListItem>
  ) : (
    <div className="text-white-50">There was a problem loading this player</div>
  );
};

const WinnersAndLosersCarousel = ({
  indexSlug,
  winners,
  losers,
  topValued,
}) => {
  return (
    <Carousel
      theme={CarouselThemes.dark}
      themeColor={DefaultThemeColor}
      customSettings={{
        initialSlide: 0,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        arrows: false,
        infinite: false,
        speed: 400,
        responsive: [
          {
            settings: {
              slidesToShow: 1.1,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 500,
          },
          {
            settings: {
              slidesToShow: 1.4,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 600,
          },
          {
            settings: {
              slidesToShow: 1.66,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 700,
          },
          {
            settings: {
              slidesToShow: 2.1,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 1023,
          },
        ],
      }}
    >
      <Panel
        background={"#00000040"}
        tintColor={Mantis}
        title={"Last week's top performers"}
        rounded
      >
        <MarketIndexComponentSnapshotList
          indexSlug={indexSlug}
          listName="TopPerformers"
          componentSnapshots={winners}
        />
      </Panel>
      <Panel
        background={"#00000040"}
        tintColor={Danger}
        title={"Last week's worst performers"}
        rounded
      >
        <MarketIndexComponentSnapshotList
          indexSlug={indexSlug}
          listName="WorstPerformers"
          componentSnapshots={losers}
        />
      </Panel>
      <Panel
        background={"#00000040"}
        tintColor={Havelock}
        title={"Last week's top valued"}
        rounded
      >
        <MarketIndexComponentSnapshotList
          indexSlug={indexSlug}
          listName="TopValued"
          componentSnapshots={topValued}
        />
      </Panel>
    </Carousel>
  );
};

const WidgetDataSection = ({ data }) => {
  const { marketIndex } = data;
  const { latestSnapshot } = marketIndex;
  const componentSnapshots = latestSnapshot.componentSnapshots.edges.map(
    ({ node }) => node,
  );

  const componentsByPriceChange = componentSnapshots.sort(
    (a, b) => b.priceChangePercentage - a.priceChangePercentage,
  );
  const winners = componentsByPriceChange.slice(0, 3);
  const losers = componentsByPriceChange.slice(-3).reverse();

  const componentsByValue = componentSnapshots.sort(
    (a, b) => b.price - a.price,
  );
  const topValued = componentsByValue.slice(0, 3);

  return (
    <>
      <div className="mt-n3 mb-3 d-flex flex-wrap">
        <div className="flex-fill">
          <IndexSummaryStats
            marketIndex={marketIndex}
            theme={IndexSummaryStatsThemes.dark}
          />
        </div>
        <p className="small text-white-50 mt-3 mb-0 align-self-end text-left">
          Last updated{" "}
          {moment(marketIndex.latestSnapshot.date).format("ddd Do MMM")}
        </p>
      </div>
      <WinnersAndLosersCarousel
        indexSlug={marketIndex.slug}
        winners={winners}
        losers={losers}
        topValued={topValued}
      />
    </>
  );
};

const MarketIndexWinnersAndLosersWidget = ({
  slug,
  title,
  subtitle,
  themeColor = DefaultThemeColor,
  widgetTheme = DefaultWidgetTheme,
}) => {
  const linkUrl = `/marketplace/indices/${slug}/`;

  const { data, loading, error } = useQuery(GET_MARKET_INDEX_LATEST_SNAPSHOT, {
    variables: { slug: slug },
  });

  return (
    <Widget
      id={`${widgetName}-${slug}`}
      widgetName={`${widgetName}-${slug}`}
      title={title}
      subtitle={subtitle}
      linkTitle={"View the index"}
      linkUrl={linkUrl}
      theme={widgetTheme}
      themeColor={themeColor}
      background={"transparent"}
    >
      {error ? (
        <ErrorNotice error={error} />
      ) : loading ? (
        <Loader message={null} />
      ) : (
        <WidgetDataSection data={data} />
      )}
    </Widget>
  );
};

export default MarketIndexWinnersAndLosersWidget;
