import { Navigate, Route, Routes } from "react-router-dom";

import { NavBarWrapper } from "../../../utils/wrappers";

import { Auction } from "./Auction";

export const AuctionsRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/breaks" />} />

    <Route
      path=":slug"
      element={
        <NavBarWrapper>
          <Auction />
        </NavBarWrapper>
      }
    />
  </Routes>
);
