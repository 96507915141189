import SlabPSA from "../images/slabs/psa.png";
import SlabBGS from "../images/slabs/bgs.png";
import SlabSGC from "../images/slabs/sgc.png";
import SlabCSG from "../images/slabs/csg.png";

const graderConfig = {
  PSA: {
    displayName: "PSA",
    slabImage: SlabPSA,
  },
  BGS: {
    displayName: "BGS",
    slabImage: SlabBGS,
  },
  SGC: {
    displayName: "SGC",
    slabImage: SlabSGC,
  },
  CSG: {
    displayName: "CSG",
    slabImage: SlabCSG,
  },
  STARSTOCK: {
    displayName: "StarStock",
    slabImage: null,
  },
  SEALED: {
    displayName: "Sealed",
    slabImage: null,
  },
};

export const displayNameForGrade = (grade) => {
  return grade && grade.grader && graderConfig[grade.grader]
    ? `${graderConfig[grade.grader].displayName || grade.grader} ${grade.name}`
    : "Ungraded";
};

export const slabImageForGrade = (grade) => {
  return grade && grade.grader && graderConfig[grade.grader]
    ? graderConfig[grade.grader].slabImage
    : null;
};

export const pickTopAvailableGrade = (grades) => {
  for (let grade of grades) {
    if (
      grade.gradedCardParallel &&
      (grade.gradedCardParallel.itemCount ||
        grade.gradedCardParallel.listingsCount)
    ) {
      return grade;
    }
  }
  return grades[0];
};
