import CircularProgress from "@material-ui/core/CircularProgress";

const LoadMoreButton = ({ loadingMore, setLoadingMore, onLoadMore }) => {
  return (
    <div className="d-flex justify-content-center my-3">
      <button
        type="button"
        className="btn btn-primary px-4"
        disabled={loadingMore}
        onClick={() => {
          setLoadingMore(true);
          onLoadMore();
        }}
      >
        {loadingMore ? (
          <div className="d-flex flex-row align-items-center">
            <CircularProgress size={16} className="text-white mr-2" />
            Loading…
          </div>
        ) : (
          "Load more"
        )}
      </button>
    </div>
  );
};

export default LoadMoreButton;
