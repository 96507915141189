import { useQuery } from "@apollo/client";
import { faPlus, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";

import GET_MY_ADDRESSES from "../../../api/queries/GET_MY_ADDRESSES";
import StaffOnly from "../../../components/StaffOnly";
import { AddAddress } from "../../../features/account";
import {
  PaymentMethodSelect,
  ManagePaymentOptionsButton,
} from "../../../features/payments";
import { logAmplitudeEvent } from "../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../utils/analytics/events";

import BreakSpotListItem from "../BreakSpotListItem";
import { AddressDropdown } from "./AddressDropdown";
import { AddressButton, Label } from "./styles";

const Header = ({ title, boxBreakName, showClose }) => {
  return (
    <Modal.Header className="border-bottom pb-0" closeButton={showClose}>
      <div className={classNames("container-fluid", { "mr-n4": showClose })}>
        <div className="text-center">
          <h3 className="font-weight-bold">{title}</h3>
          <p>{boxBreakName}</p>
        </div>
      </div>
    </Modal.Header>
  );
};
const StepEdit = ({ formData, setFormDataFields, onCancel, onNext }) => {
  const totalPrice = _.reduce(
    formData.selectedSpots,
    function (sum, spot) {
      return sum + spot.price;
    },
    0,
  );

  const [formValid, setFormValid] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [selectSaveAddress, setSelectSaveAddress] = useState(false);

  const toggleAddressFormModal = () => setShowAddressForm(!showAddressForm);
  const toggleSaveAddress = () => setSelectSaveAddress(!selectSaveAddress);

  const { data, loading } = useQuery(GET_MY_ADDRESSES);

  const addresses = useMemo(
    () => (!loading && data.myAddresses) || [],
    [loading, data],
  );

  useEffect(() => {
    if (
      addresses.length > 0 &&
      (formData.addressId === null || formData.addressId === "")
    ) {
      formData.addressId = addresses[0].id;
    }
    setFormValid(!!formData.addressId);
  }, [addresses, formData]);

  // Disable dependencies warning as this is setup to be a one-time useEffect
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    logAmplitudeEvent(BREAKS_EVENTS.BUY_SPOTS.FORM_VIEWED, {
      "Break Slug": formData.boxBreak.slug,
      "Break Format": formData.boxBreak.format.name,
      "Selected Spots": formData.selectedSpots.map((spot) => spot.name),
      "Selected Spots Count": formData.selectedSpots.length,
      "Selected Spots Price": totalPrice,
    });
  }, []);

  return (
    <>
      {showAddressForm ? (
        <>
          <Modal.Header className="border-bottom pb-0">
            <h3 className="font-weight-bold">
              <FontAwesomeIcon
                onClick={toggleAddressFormModal}
                icon={faArrowLeft}
                aria-hidden={true}
                className="mr-3 fa-sm"
                style={{
                  opacity: "0.5",
                  color: "#2C4D76",
                }}
              />
              Add new address
            </h3>
          </Modal.Header>

          <Modal.Footer className="py-0">
            <div className="mb-3 pt-3 ">
              <AddAddress
                onCancel={toggleAddressFormModal}
                checkBoxValue={selectSaveAddress}
                handleCheckbox={toggleSaveAddress}
                onSubmit={toggleAddressFormModal}
              />
            </div>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Header
            title={"Buy into the break"}
            boxBreakName={formData.boxBreakName}
            showClose={true}
          />

          <Modal.Body className="py-0">
            <div className="row bg-light">
              <div className="col p-3 pb-0">
                <Label>Selected spots:</Label>
                {formData.selectedSpots.map((spot) => (
                  <div key={spot.id}>
                    <BreakSpotListItem
                      boxBreak={formData.boxBreak}
                      spot={spot}
                      spotTypeName={formData.boxBreak.format.name}
                      handleClick={null}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="container-fluid px-0">
              <div className="mb-3">
                <Label>Shipping details (required):</Label>
                {addresses.length > 0 && (
                  <>
                    <AddressDropdown
                      value={formData.addressId}
                      onChange={(e) =>
                        setFormDataFields({ addressId: e.target.value })
                      }
                      options={addresses}
                    />
                    <Link
                      className="btn btn-secondary btn-block font-weight-bold text-dark mb-3"
                      to="/account/address-book"
                    >
                      Manage saved addresses
                    </Link>
                  </>
                )}

                {!addresses.length && (
                  <AddressButton onClick={toggleAddressFormModal} tabIndex={0}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      aria-hidden={true}
                      style={{ marginRight: "5px" }}
                    />
                    Add new address
                  </AddressButton>
                )}

                <TextField
                  id="additional-shipping-instructions"
                  label="Additional shipping instructions"
                  aria-label="additional shipping instructions"
                  value={formData.additionalInstructions}
                  onChange={(e) =>
                    setFormDataFields({
                      additionalInstructions: e.target.value,
                    })
                  }
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  margin="dense"
                  className="mb-3 mt-0"
                  placeholder="Optional additional instructions for our team (e.g. discard non-RC cards, etc…)."
                />

                <p className="mb-2">
                  Cards not eligible for StarStock will be shipped free to the
                  above address. Cards eligible for StarStock can be added to
                  your account, or shipped home.
                </p>

                <FormControl>
                  <RadioGroup
                    row
                    aria-label="hit preference"
                    name="hitPreference"
                    value={formData.preferShipHome ? "true" : "false"}
                    onChange={(e) => {
                      setFormDataFields({
                        preferShipHome: e.target.value === "true",
                      });
                    }}
                    className="mb-2"
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio size={"medium"} color={"primary"} />}
                      label="Add to StarStock account"
                      className="mb-n2"
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio size={"medium"} color={"primary"} />}
                      label="Ship home"
                      className="mb-n2"
                    />
                  </RadioGroup>
                </FormControl>
                <StaffOnly>
                  <PaymentMethodSelect
                    selectedMethod={formData.paymentMethod}
                    handleChange={(e) => {
                      setFormDataFields({ paymentMethod: e.target.value });
                    }}
                  />
                  <ManagePaymentOptionsButton />
                </StaffOnly>
              </div>

              <div className="d-flex flex-row justify-content-between align-items-center">
                <button
                  className="btn btn-secondary btn-block font-weight-bold text-dark"
                  onClick={() => onCancel()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary btn-block font-weight-bold mt-0 ml-3"
                  disabled={!formValid}
                  onClick={() => onNext()}
                >
                  Confirm purchase
                </button>
              </div>
            </div>
          </Modal.Footer>
        </>
      )}
    </>
  );
};

export default StepEdit;
