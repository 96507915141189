import styled, { css } from "styled-components";
import { CircularProgress } from "@material-ui/core";

import { financial } from "../../utils/common";
import { productQuantityString } from "../../utils/content";

import { PRODUCT_TYPES } from "../../api/types";

export const variants = {
  listing: "listing",
  offer: "offer",
};

const variantColors = {
  listing: "#58b55c",
  offer: "#4e8ad4",
};

const Container = styled.button`
  background: ${(props) => `${variantColors[props.variant]}20`};
  padding: 0;
  width: 100%;
  overflow: hidden;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => `${variantColors[props.variant]}80`};
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);

  ${(props) =>
    !props.disabled &&
    props.onClick &&
    css`
      &:hover,
      &:active {
        border-color: ${(props) => variantColors[props.variant]};
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
        transform: translate(0px, -1px);
        cursor: pointer;
      }
    `}
`;

const HeadingContainer = styled.div`
  background: ${(props) => `${variantColors[props.variant]}30`};
  padding: 0.25rem 0.5rem;
  text-align: center;
`;

const Heading = styled.h6`
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.25rem 0.5rem;
  height: 2rem;
`;

const QuantityLabel = styled.span`
  font-size: 0.875rem;
`;

const PriceLabel = styled.span`
  color: ${(props) => variantColors[props.variant]};
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
  margin-left: 0.5rem;
`;

const SubmittingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
`;

const BestOffer = ({
  variant = variants["listing"],
  quantity,
  productType = PRODUCT_TYPES.CARD,
  price,
  onClick,
  isAccepting,
}) => {
  const headingText = {
    listing: isAccepting ? "Buying listing…" : "Buy lowest listing",
    offer: isAccepting ? "Accepting offer…" : "Accept best offer",
  }[variant];

  const priceText = `$${financial(price)}`;

  return (
    <Container variant={variant} disabled={isAccepting} onClick={onClick}>
      <HeadingContainer variant={variant}>
        <Heading>{headingText}</Heading>
      </HeadingContainer>
      {isAccepting ? (
        <SubmittingContainer>
          <CircularProgress
            size={20}
            style={{ color: variantColors[variants] }}
          />
        </SubmittingContainer>
      ) : (
        <BodyContainer variant={variant}>
          <QuantityLabel className="text-dark">
            {productQuantityString(quantity, productType)}
          </QuantityLabel>
          <PriceLabel variant={variant}>{priceText}</PriceLabel>
        </BodyContainer>
      )}
    </Container>
  );
};

export default BestOffer;
