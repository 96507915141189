import { AddressNode } from "../../../../../generated/schema";

import { AddressValues, FetchResposeType } from "../../../types";
import { Address } from "./Address/Address";

interface Props {
  addresses: (AddressNode | null)[] | null;
  onDeleteAddress(id: string | undefined): void;
  onMakeDefaultAddress(id: string | undefined): void;
  onEditAddress: (id: string, values: AddressValues) => FetchResposeType;
}

export const AddressList = ({
  addresses,
  onDeleteAddress,
  onMakeDefaultAddress,
  onEditAddress,
}: Props) => {
  return (
    <>
      {addresses &&
        addresses.map((address) => (
          <Address
            address={address}
            key={address?.id}
            onDelete={() => onDeleteAddress(address?.id)}
            onDefault={() => onMakeDefaultAddress(address?.id)}
            onEdit={(id, values) => onEditAddress(id, values)}
          />
        ))}
    </>
  );
};
