import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFromLocation } from "../hooks/useFromLocation";
import CREATE_PAYMENT_INTENT from "../api/mutations/CREATE_PAYMENT_INTENT";
import { logAmplitudeEvent } from "../utils/analytics";

import "./DepositForm.css";

const CARD_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const DepositForm = ({
  amount,
  setError,
  successPath,
  depositSuccessEventName,
  depositFailureEventName,
}) => {
  const elements = useElements();
  const { from } = useFromLocation();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const stripe = useStripe();

  const [createPaymentIntent] = useMutation(CREATE_PAYMENT_INTENT, {
    onCompleted({ createPaymentIntent }) {
      takeCardPayment(
        createPaymentIntent.paymentIntentToken,
        createPaymentIntent.paymentSequence,
      );
    },
  });

  const takeCardPayment = async (paymentIntentToken, paymentSequence) => {
    const result = await stripe.confirmCardPayment(paymentIntentToken, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      logAmplitudeEvent(depositFailureEventName, {
        Type: result.error.type,
        Code: result.error.code,
        "Decline Code": result.error.decline_code,
        Message: result.error.message,
        Amount: result.error.payment_intent
          ? result.error.payment_intent.amount / 100
          : null,
      });

      setError(result.error.message);
      setSubmitting(false);
    } else {
      // Successful payment
      if (result.paymentIntent.status === "succeeded") {
        logAmplitudeEvent(depositSuccessEventName, {
          Amount: result.paymentIntent.amount / 100,
          "Payment Sequence": paymentSequence,
        });

        navigate(successPath, { state: { from } });
      }
    }
  };

  const handleSubmit = async (event) => {
    // Don't let default form submission happen
    event.preventDefault();

    if (!stripe || !elements || isSubmitting) {
      // Disable form submission until Stripe.js has loaded
      return;
    }

    // Disable submit button
    setSubmitting(true);

    createPaymentIntent({
      variables: {
        // Stripe deals with cents/pennies
        amount: amount * 100,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <CardElement options={CARD_OPTIONS} />
      </div>

      <button
        className="btn btn-success btn-block btn-lg mt-4"
        disabled={!stripe || isSubmitting}
      >
        <FontAwesomeIcon icon={faCreditCard} size="xs" className="mr-2" />
        Pay by card
      </button>
    </form>
  );
};

export default DepositForm;
