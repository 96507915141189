import { useApolloClient, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

import { setAccessTokenExpiry, setRefreshTokenExpiry } from "../utils/auth";
import { BackgroundImageWrapper } from "../utils/wrappers";
import { amplitudeIdentify, logAmplitudeEvent } from "../utils/analytics";
import { ONBOARDING_EVENTS } from "../utils/analytics/events";
import { isLoggedInVar } from "../utils/apolloConfig";

import REFRESH_TOKEN from "../api/mutations/REFRESH_TOKEN";
import USER_CREATE from "../api/mutations/USER_CREATE";
import SignUpForm from "../components/SignUpForm";
import { useFromLocation } from "../hooks/useFromLocation";

import logo from "../images/logo-light.svg";

const SignUp = () => {
  const client = useApolloClient();
  const { from } = useFromLocation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const [refreshToken] = useMutation(REFRESH_TOKEN, {
    onCompleted({ refreshToken }) {
      amplitudeIdentify(refreshToken.payload);
      logAmplitudeEvent(ONBOARDING_EVENTS.SIGN_UP.SUCCEEDED);

      localStorage.clear();
      setAccessTokenExpiry(refreshToken.payload.exp);
      setRefreshTokenExpiry(refreshToken.refreshExpiresIn);

      client.clearStore().then(() => {
        isLoggedInVar(true);
        navigate("/signup/deposit", { state: { from } });
      });
    },
  });

  const [userCreate] = useMutation(USER_CREATE, {
    onCompleted({ userCreate }) {
      if (userCreate.errors && userCreate.errors.length > 0) {
        logAmplitudeEvent(ONBOARDING_EVENTS.SIGN_UP.FAILED, {
          Error: userCreate.errors[0],
        });
        setError(userCreate.errors[0]);
        setSubmitting(false);
      } else {
        refreshToken({
          variables: { refreshToken: userCreate.refreshToken },
        });
      }
    },
  });

  useEffect(() => {
    logAmplitudeEvent(ONBOARDING_EVENTS.SIGN_UP.PAGE_VIEWED);
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>

      <div className="container-fluid">
        <BackgroundImageWrapper />

        <div className="row">
          <div className="col">
            <div className="text-center">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid my-3 my-sm-5" />
              </Link>
            </div>

            <div className="card mx-auto max-width-26 mb-5">
              <div className="card-body text-center">
                <h1 className="card-title h5 mt-2 mb-3">
                  Sign up free to the first
                  <br />
                  <span className="font-weight-bold">
                    instant-trading marketplace
                  </span>
                  <br />
                  for sports trading cards
                </h1>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error.message || error.field}
                  </div>
                )}

                <SignUpForm
                  userCreate={userCreate}
                  isSubmitting={isSubmitting}
                  setSubmitting={setSubmitting}
                />

                <Link className="card-link" to="/login" state={{ from }}>
                  Already registered? Sign in here
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
