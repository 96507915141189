import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_ID_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
  PARALLEL_FRAGMENT,
  PLAYER_CARD_FRAGMENT,
} from "../fragments";

const GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  query GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION(
    $sport: String
    $era: String
    $searchQuery: String
    $orderBy: String
    $cursor: String
  ) {
    myInDraftShippingRequestCollection(
      first: 48
      after: $cursor
      sport: $sport
      era: $era
      orderBy: $orderBy
      searchQuery: $searchQuery
    )
      @connection(
        key: "myInDraftShippingRequestCollection"
        filter: ["sport", "era", "orderBy", "searchQuery"]
      ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          item {
            id
            cardParallel {
              ...cardParallelIdFields
              frontSidePhotoMedium
              boxProductPhotoMedium
              frontSidePhotoSmall
              boxProductPhotoSmall
              parallel {
                ...parallelFields
              }
              playerCard {
                ...playerCardFields
              }
            }
            grade {
              ...gradeFields
            }
            gradedCardParallel {
              ...gradedCardParallelFields
            }
          }
        }
      }
    }
  }
`;

export default GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION;
