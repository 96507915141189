import html from "remark-html";
import parse from "html-react-parser";
import remark from "remark";
import gfm from "remark-gfm";
import footnotes from "remark-footnotes";

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/`;

export const getContentful = (query) => {
  return window
    .fetch(CONTENTFUL_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
      body: JSON.stringify({ query }),
    })
    .then((response) => response.json());
};

export const markdown = (md) => {
  return parse(
    remark().use(gfm).use(footnotes).use(html).processSync(md).toString(),
  );
};
