import { useState } from "react";

import ErrorNotice from "../../components/ErrorNotice";
import Loader from "../../components/Loader";
import LoadMoreButton from "../../components/LoadMoreButton";

import ItemOwnershipItemButton from "../ItemOwnership/ItemOwnershipItemButton";
import ItemOwnershipGridContainer from "../ItemOwnership/ItemOwnershipGridContainer";

const BasketSection = ({
  data,
  loading,
  error,
  fetchMore,
  handleRemoveItem,
}) => {
  const [loadingMore, setLoadingMore] = useState(false);

  return (
    <div className="inner-container-wide mx-auto">
      <div className="row">
        <div className="col mt-4 mt-md-4 mb-3">
          {error && <ErrorNotice />}
          {loading && <Loader />}
          {!error && !loading && data?.myInDraftShippingRequestCollection && (
            <>
              <ItemOwnershipGridContainer
                itemOwnershipCollection={
                  data?.myInDraftShippingRequestCollection
                }
                actionView={
                  <ItemOwnershipItemButton
                    buttonAction={handleRemoveItem}
                    buttonLabel={"Remove"}
                  />
                }
                showMarketData={false}
              />

              {data?.myInDraftShippingRequestCollection?.pageInfo
                ?.hasNextPage && (
                <LoadMoreButton
                  loadingMore={loadingMore}
                  setLoadingMore={setLoadingMore}
                  onLoadMore={() =>
                    fetchMore({
                      variables: {
                        cursor:
                          data?.myInDraftShippingRequestCollection?.pageInfo
                            ?.endCursor,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        setLoadingMore(false);
                        if (!fetchMoreResult) return prev;

                        fetchMoreResult.myInDraftShippingRequestCollection.edges =
                          [
                            ...prev.myInDraftShippingRequestCollection.edges,
                            ...fetchMoreResult
                              .myInDraftShippingRequestCollection.edges,
                          ];
                        return fetchMoreResult;
                      },
                    })
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasketSection;
