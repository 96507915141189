import styled from "styled-components";

export const LiveContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const LiveCopy = styled.p`
  color: white;
  margin: 0;
`;

export const LiveHeading = styled.h2`
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const WaitingCopy = styled.p`
  color: rgb(255 255 255 / 50%);
  margin: 0;
`;

export const NoReplay = styled.p`
  color: white;
  margin: 0;
`;

export const WaitingContainer = styled.div`
  color: white;
  padding: 0 1rem;
  text-align: center;
`;

export const WaitingHeading = styled.h2`
  color: rgb(255 255 255 / 50%);
  font-size: 1.25rem;

  @media (min-width: 32rem) {
    font-size: 1.75rem;
  }

  span {
    color: white;
    display: block;
    font-size: 1.75rem;
    font-weight: bold;
    margin: 0.5rem 0;

    @media (min-width: 32rem) {
      font-size: 2.5rem;
    }
  }
`;
