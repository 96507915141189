import { useEffect } from "react";

import { Helmet } from "react-helmet";
import styled, { css } from "styled-components";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { logAmplitudeEvent } from "../utils/analytics";
import { CONTENT_EVENTS } from "../utils/analytics/events";
import { pluralize } from "../utils/content";
import { ContentPageHelmet } from "../utils/wrappers";

import {
  PageSection,
  PageSectionTypes,
  PageSectionWidths,
} from "../components/PageSection";

import background from "../images/sibbc/2021/background.jpg";

import KendrickPerkins from "../images/sibbc/2021/contestants/01 - Kendrick Perkins.jpg";
import DJSkee from "../images/sibbc/2021/contestants/02- DJ Skee.jpg";
import JackSettleman from "../images/sibbc/2021/contestants/03 - Jack Settleman.jpg";
import BenLyons from "../images/sibbc/2021/contestants/04 - Ben Lyons.jpg";
import CoachDuggs from "../images/sibbc/2021/contestants/05 - Coach Duggs.jpg";
import KOT4Q from "../images/sibbc/2021/contestants/06 - KOT4Q (Kenny Beecham).jpg";
import JoePompliano from "../images/sibbc/2021/contestants/07 - Joe Pompliano.jpg";
import SashaT from "../images/sibbc/2021/contestants/08 - Sasha T.jpg";
import TyWilson from "../images/sibbc/2021/contestants/09 - Ty Wilson - Breaker Culture.jpg";
import Keenan from "../images/sibbc/2021/contestants/10 - Keenan - High Upside Show.jpg";
import Ziggy from "../images/sibbc/2021/contestants/11 - Ziggy.jpg";
import GavinLee from "../images/sibbc/2021/contestants/12 - Gavin Lee.jpg";

import PrizeOne from "../images/sibbc/2021/prizes/PrizeOne.png";
import PrizeTwo from "../images/sibbc/2021/prizes/PrizeTwo.png";
import PrizeThree from "../images/sibbc/2021/prizes/PrizeThree.png";
import PrizeFour from "../images/sibbc/2021/prizes/PrizeFour.png";
import { Link } from "react-router-dom";

const updates = [
  {
    title: "Weeks 7 and 8 update",
    date: Date.UTC(2021, 1, 19),
    body: (
      <>
        <p>
          It has been a busy last two weeks at StarStock, and the SIBBC was just
          as busy. While it seemed for a while that DJ Skee had a chokehold on
          the competition, however in the past two weeks, Keenan from The High
          Upside Show has made a gigantic leap into first with a portfolio
          valued at $1,228.62, while Instagram wild card winner Gavin Lee is
          behind in second with $777.53, Ty from Breaker Culture in third with
          $552.32, and Ben Lyons in fourth with $513.59. The rest of the
          competitors are in the red with portfolios valued at under $500, but
          DJ Skee is now in fifth with $492.73, only slightly in the red. The
          market dip has contributed heavily to the decrease in value across the
          board for most contestants, but with a possible recorrection coming,
          look for these values to jump.
        </p>
        <p>
          Keenan takes over first place thanks to a multitude of transactions
          both selling and buying. He recently sold two Gary Trent Jr Prizm Base
          A’s for $18.75 each after buying some for $15, and also sold a
          StarStock B Topps Kevin Durant B for $185. He also has bought 5 Malik
          Monk Prizm A’s from $14.50-$16.50. Monk has been hot recently and
          playing great basketball, and is now trading at $17, so he is looking
          at a decent flip if he sells soon.
        </p>
        <p>
          Second place is Gavin Lee,{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/bayareacardinvestor"
          >
            @bayareacardinvestor
          </a>{" "}
          on instagram. Gavin has been quieter than the beginning of the
          competition but is still making moves. He has recently bought a Darius
          Bazley Prizm A for $17, a Kevin Porter Jr Prizm A for $25, and a Sekou
          Doumbouya Prizm A for $11.75. Two prizm investments and one former
          prizm investment are good buys at the moment, because basketball seems
          to be picking back up, so expect these purchases to pay off in the
          long run.
        </p>
        <p>
          Third place belongs to Ty from Breaker Culture, who is sitting at
          $552.32, just above the green line. He has also been quiet, only
          making one purchase recently which was a Coby White Donruss A for
          $3.50. Ty doesn’t have much wiggle room in terms of balance, so to
          make a move he’ll need to work on selling cards off to close the gap
          between him and the top two places.
        </p>
        <p>
          The All-Star Break is coming up soon, with the game and it’s
          festivities scheduled for March 7th. At that moment we will be
          eliminating our bottom two contestants, which at the moment would be
          Kenny Beecham (KOT4Q) and defending champ Jack Settleman. Both are
          going to need to make some moves in order to remain in the competition
          longer, and with a few weeks left in the competition there is plenty
          of time to get out of the hot seats.
        </p>
        <p>
          For all things SIBBC and the card market, make sure to follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          (@starstock)!
        </p>
      </>
    ),
  },
  {
    title: "Weeks 5 and 6 update",
    date: Date.UTC(2021, 1, 3),
    body: (
      <>
        <p>
          Weeks 5 and 6 were rough weeks for everyone but DJ Skee (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/djskee"
          >
            @djskee
          </a>
          ), as he now remains the only contestant with a portfolio over $1,000
          at $1,399.79. All other portfolios took a much more significant hit,
          and while remaining active, are clearly feeling the market dip we are
          currently experiencing.
        </p>
        <p>
          Gavin Lee (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/bayareacardinvestor"
          >
            @bayareacardinvestor
          </a>
          ), our Instagram wildcard winner, is still in second place with a
          portfolio at $899.71. He has made a good number of pickups throughout
          the past two weeks, including two Rui Hachimura Prizm Base B’s for
          $5.40 each, a Lonzo Ball Optic Base A for $8, and two Anfernee Simons
          Prizm Base B’s for $2.10. Gavin also made some solid sales during this
          time, which include an Anfernee Simons Optic Base A for $8.80, a Bam
          Adebayo Optic Base B for $7.50, and seven Jalen McDaniels Prizm Base
          B’s for $.48. While Gavin remains incredibly active, he is slowly
          falling behind DJ Skee, so look for Gavin to start selling off some of
          his 238 cards to attempt to close the gap.
        </p>
        <p>
          Third place belongs to Keenan from The High Upside Show with $888.44.
          He has been a big time seller over the past two weeks, trying to
          offload some of his cards in an effort to increase his value. He
          recently sold seven Kevin Porter Jr Mosaic Base A’s for $2 each, a
          Lonzo Ball Prizm Base A for $27, and four Lonnie Walker Prizm Base A’s
          for $20 each, among other transactions. Keenan has a high balance of
          $219.04, so his sales have given him room to start to make more
          purchases and climb up the ladder.
        </p>
        <p>
          There is a slight drop off from third to fourth with Ty coming in at
          $719.10. Ty recently bought three cards: a Collin Sexton Prizm Base A
          for $81, a Sexton Optic Base A for for $32, and a Sexton Prizm Luck of
          the Lottery A for $12. Sexton’s prizm has decreased slightly in value
          to $75, but has been a volatile card for most of the season and could
          certainly increase in value again.
        </p>
        <p>
          The season is slowly lurching to the first elimination point, where
          the 11th and 12th place contestants will be eliminated from
          contention. The rest of the contest shapes out as Ben Lyons in 5th,
          ZiggyNo in 6th, Kendrick Perkins in 7th, Coach Duggs in 8th, Jack
          Settleman in 9th, Joe Pomp in 10th, Sasha T in 11th and Kenny Beecham
          in 12th. If the all star break was today, Sasha and Kenny would be
          eliminated, and Joe Pomp would be on the hot seat for next week. Make
          sure you keep up with all things SIBBC on social, (@starstock)!
        </p>
      </>
    ),
  },
  {
    title: "Week 4 update",
    date: Date.UTC(2021, 0, 20),
    body: (
      <>
        <p>
          The Basketball market took a slight hit this week, which is evident in
          our NBA Emerging 30 Index, and also in our SIBBC contest. Some
          contestants experienced a slight decrease in portfolio value this
          week, but almost everyone remains in the green and profiting
          exponentially in this tight contest. Three contestants remain in the
          four digit club, and the standings continue to shuffle around like a
          deck of cards.
        </p>
        <p>
          DJ Skee (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/djskee"
          >
            @djskee
          </a>
          ) retakes the top spot of the competition, boasting a portfolio valued
          at $1,425.18 – an increase of $233.30. He continues to go down the
          StarStock B route, grabbing four Kevin Knox Optic Base B’s for $1
          each, and 12 Nickeil Alexander-Walker Donruss Base B’s for .$75 each,
          among other purchases. While Skee is not buying cards for very high
          value, the volume at which he is buying the cards, and the potential
          high yield of profit from flips makes up for the lower value in the
          cards.
        </p>
        <p>
          Second place again belongs to our Instagram wild card winner Gavin Lee
          (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/bayareacardinvestor"
          >
            @bayareacardinvestor
          </a>
          ) with a portfolio valued at $1,199.18, which is down slightly from
          last week. Gavin is another one of our contestants who has explored
          the StarStock B route, however his week is highlighted by selling six
          Chimezie Metu Prizm Base A’s for $1.50 each. Along the same lines of
          his strategy, he bought a Keldon Johnson Prizm Base B for $11, and a
          Donovan Mitchell Optic Base B for $14.
        </p>
        <p>
          Our third 1k+ contestant and third place contestant is Keenan from The
          High Upside Show, with $1,144.95, only $54.23 behind Gavin. Keenan
          made several sales this past week. He sold a Lonnie Walker Prizm Base
          A for $40.50 and four Kevin Porter Mosaic Base A cards for $6. Keenan
          has the highest balance currently of the contestants, so he has an
          opportunity to execute several flips in order to break into the top 2.
        </p>
        <p>
          Ty from Breaker Culture remains in fourth place with $932.66, down $42
          in value. Ty spent the week selling off many cards, which include two
          Harry Giles Prizm Base A cards, one for $21 and one for $26. He also
          sold four Nickeil Alexander-Walker Donruss Base A’s for $5.50 each,
          and then three more later in the week for $6 each.
        </p>
        <p>
          The rest of the competition is tight and looks like it will come down
          to the wire when the time comes! Fifth place belongs to Ben Lyons of
          ESPN and NBA TV with $707.39, sixth is Twitter Wildcard Winner ZiggyNo
          with $635.14, and seventh is Coach Duggs of Barstool Sports with
          $633.15. Defending Champ Jack Settleman comes in at eighth place with
          $588.72, followed by Joe Pompliano in ninth with $577.89, NBA Legend
          Kendrick Perkins in 10th with $566.64, Kenny Beecham (KOT4Q) in 11th
          with $546.92, and finally, the only contestant in the red, Sasha T in
          12th with $473.02.
        </p>
        <p>
          Basketball season is in full swing, and so is the SIBBC. We are
          nearing the halfway point until our first two eliminations, so make
          sure you keep up with all things SIBBC on social (@starstock)!
        </p>
      </>
    ),
  },
  {
    title: "Week 3 update",
    date: Date.UTC(2021, 0, 13),
    body: (
      <>
        <p>
          As we finish our third week of the competition, a third competitor has
          entered the 1K club, as Keenan from The High Upside Show eclipses the
          four digit mark in the competition. Basketball is still hot, as is the
          SIBBC with our competitors combining for hundreds of transactions this
          past week. Again, our competitors are making so many transactions that
          we can’t highlight them all, so those featured in the updates are some
          of the best of the bunch.
        </p>
        <p>
          Gavin Lee, our Instagram wild card winner has jumped back into first
          place with a portfolio valued at $1,264.27. Gavin is one of our
          contestants who has embraced the StarStock B strategy, however this
          week has started to dip his toes back into the StarStock A market
          thanks to his success thus far in the competition. Some of his best
          purchases this week include a Porzingis Donruss Base A for $39, a Cam
          Reddish Prizm Base A for $54, and even got into some slabs this week,
          selling off a PSA 9 Brandon Clarke Prizm Base for $42.
        </p>
        <p>
          DJ Skee is close behind after dropping one spot from last week, and is
          now in second place with $1,191.98. Skee is our biggest proponent of
          the StarStock B market, and has bought dozens of smaller value cards,
          looking to flip those for smaller profits compared to A’s, but his
          volume is so extreme that this looks like it certainly can pay off. Of
          his highest value purchases, Skee bought a Mikal Bridges Prizm Base A
          for $42, and also sold two Caris LaVert Donruss Base B’s for $19 each.
        </p>
        <p>
          Keenan from The High Upside Show moved up a spot from fourth to third,
          (taking over the spot previously held by our defending champ Jack
          Settleman), and now holds a portfolio valued at $1,122.23. Keenan made
          big purchases this week which clearly helped his value, buying 10
          Lonnie Walker Prizm Base A cards, with prices ranging from $35 to $39.
          That card is now trading at $41, and is having a breakout year. We
          expect this card to continue jumping up in price, which bodes well
          with Keenan.
        </p>
        <p>
          Fourth and fifth places belong to Ty from Breaker Culture ($961.37)
          and Coach Duggs from Barstool Sports ($880.79). Ty had a nice selling
          week, selling five Nickeil Alexander-Walker Donruss Base cards for
          $2.90 each, and then a Alexander-Walker Optic Base for $7.90. Duggs
          has been on FIRE recently, and earlier in the week stocked up heavy on
          2020 Prizm Draft Picks cards, taking advantage of the fact that they
          are the only cards available for the new draft class on StarStock
          currently. Of those cards, he later flipped 15 Payton Pritchards for
          $12.75 each after buying them for $10.57 each, and 10 Tyrese
          Haliburtons for $10.06 that he sold for $13.50 each. Duggs then took
          his cash and invested it into Tyler Herro Prizm Base As for $111 each.
        </p>
        <p>
          The rest of the competition is as follows. Ben Lyons of ESPN and NBA
          TV is in sixth place $813.75, right behind Duggs. After that there is
          a slight dropoff, with defending champ Jack Settleman falling to
          seventh place with $682.72. Twitter Wildcard Winner ZiggyNo is in
          eighth with $665.24, followed by Joe Pompliano who is in ninth with
          $597.47. Kenny Beecham (KOT4Q on YouTube) is in 10th place with
          $590.69, and only leads 11th place Kendrick Perkins ($589.20) by
          $1.40. And finally, Sasha T is in 12th place, and the only contestant
          in the red at $499.57.
        </p>
        <p>
          The competition remains hot, and there is so much happening every day.
          Make sure to follow us on social (@starstock) for all your latest
          SIBBC news!
        </p>
      </>
    ),
  },
  {
    title: "Week 2 update",
    date: Date.UTC(2021, 0, 6),
    body: (
      <>
        <p>
          If you want any evidence of how hot the basketball card market is
          currently, look no further than the SIBBC standings. We now have not
          one, but TWO contestants in 1k+ territory with the rest of the
          competition not so far behind.
        </p>
        <p>
          DJ Skee (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/djskee"
          >
            @djskee
          </a>
          ) remains in first place with a portfolio valued at $1,135.31. Skee
          continues on his StarStock B strategy, with dozens of transactions
          throughout the past week. Some of his best include selling off two
          Colin Sexton Prizm Base B’s for $24 each, and buying three Caris
          LaVert Optic Base B’s for $14 each. Skee has a ton of smaller
          transactions for less than $1, which are a mix of A’s and B’s. This
          method has clearly paid off for multiple contestants, because not only
          do those purchases drive up the value of the card, but the volume of
          purchases and then flips thereafter help increase portfolio value and
          cash balance, allowing them more room to make bigger purchases.
        </p>
        <p>
          Instagram wild card winner Gavin Lee (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/bayareacardinvestor"
          >
            @bayareacardinvestor
          </a>
          ) remains in second place, but had an increase in value of $198.32!
          Gavin is another one of the contestants going down the StarStock B
          route and like Skee, has made multiple transactions throughout the
          course of the week. Headlining those transactions is selling a Kevin
          Durant Topps Rookie Set B for $74, along with buying a Rui Hachimura
          Prizm Base B for $19, and a Coby White Prizm Base B for $25. Gavin’s
          big week helped propel him close to Skee in the standings, and their
          race is getting tighter as the competition progresses. With how active
          these two have been and how well their strategies have been working,
          this could turn into a two horse race if they keep up this pace.
        </p>
        <p>
          Jack Settleman of SnapbackSports – and the defending SIBBC champ –
          also stays in the same slot, coming in at third place for the second
          week in a row. Although Jack hasn’t made any purchases this week, his
          portfolio increased over $50 in value thanks to the purchases he has
          made in the past. Right on his tail in fourth place is Keenan from The
          High Upside Show, who is bullish on the Kevin Huerter hype train. This
          week, he bought 8 Kevin Huerter Prizm Base A cards for $13.75 each.
          This investment has more than paid off, as Huerter’s Prizm Base A is
          now trading for $19!
        </p>
        <p>
          Fifth, sixth and seventh places continue to remain a tight race, with
          Twitter wild card winner ZiggyNo in fifth ($841.24), Ty from Breaker
          Culture in sixth ($832.86) and Coach Duggs from Barstool Sports in
          seventh ($796.35). The busiest of the bunch has been ZiggyNo, buying 7
          Jarrett Culver Prizm Base A cards, from $31 to $40 in value each. He
          also sold a Ja Morant Mosaic Base A for $74 this week. Ty recently
          bought four Jamaal Murray Donruss Base A’s for $29.99 each, and
          flipped one of the four for $44 later in the week, a 46.7% profit!
          Duggs again was quiet this week with no transactions, but had a slight
          portfolio increase of $6.
        </p>
        <p>
          Again, there are hundreds of more transactions that we can fit in
          these updates, from up and down the leaderboard. Make sure to keep an
          eye on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          (@starstock) for all your latest SIBBC news!
        </p>
      </>
    ),
  },
  {
    title: "Week 1 update",
    date: Date.UTC(2020, 11, 31),
    body: (
      <>
        <p>
          We are in full swing of the NBA season now and the Basketball card
          market shows no signs of slowing down, along with our contestants
          portfolios, which are skyrocketing. They are experiencing excellent
          profits and portfolio values well above breaking even, as they
          continue to buy and sell cards daily.
        </p>
        <p>
          We have a new contestant in first place, DJ Skee (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/djskee"
          >
            @djskee
          </a>
          ). Skee now owns a portfolio valued at $1,069.73 consisting of 207
          cards. Skee has a very interesting strategy for this contest, and is
          buying up StarStock B’s and holding onto them. StarStock B’s have seen
          increases in value as their StarStock A’s shoot up in price. For cards
          that people believe their A’s may be valued too high, a StarStock B
          still holds significant value for a flip. Some of Skee’s StarStock B
          purchases include several Prizm Base Colin Sextons from between $15
          and $18, a Donruss Base Darius Garland for $9, and a StarStock A
          Lonnie Walker Prizm Base for $21. He has made some sales here or
          there, but most of his value remains in his cards, and will be looking
          for B values to jump as the season progresses.
        </p>
        <p>
          Our previous first place contestant and Instagram wild card winner,
          Gavin Lee (@bayareacardinvestor) falls one spot to second with an
          account value of $894.37. Gavin, like Skee, has been big on the buying
          and selling StarStock B, strategy, and has offloaded several cards
          this week. He has sold 15 Kyle Guy Mosaic base B’s for $.60, a Colin
          Sexton Prizm Base B for $19.75, and a Darius Garland Prizm Base B for
          $11. He owns the most cards in the competition as of now with 213, so
          keep an eye out for him to continue to offload his assets quickly and
          often.
        </p>
        <p>
          Defending champ Jack Settleman has found his way into third place this
          week with an account value of $862.68. Recently, he’s snagged three KZ
          Okpala Prizm Base A’s: two for $16 and one for $16.50, and sold a
          Jaren Jackson Jr Prizm Base A for $47. Jack’s experience is not to be
          overlooked as he has climbed the ladder quickly in just one week from
          8th place last week.
        </p>
        <p>
          The contest gets incredibly tight below third place, with fourth and
          seventh place being separated by just $31.54. Ty from Breaker Culture
          is in fourth with $806.73, and has recently cashed in on the Lonnie
          Walker IV hype train, selling 10 of his Donruss Base A’s for $4 each
          after buying them for $2 each. In fifth place we have Keenan from the
          High Upside Show at $804.26. Although his portfolio increased this
          week thanks to purchases like four Wendell Carter Jr Prizm Base A’s
          for $18 each, he drops 3 spots from 2nd.
        </p>
        <p>
          In sixth place we have Coach Duggs of Barstool Sports with an account
          valued at $790.91. Duggs has watched his portfolio add $206.47 in just
          a week! His biggest increase in value was Ja Morant. He bought a Ja
          Morant Prizm Base A for $170 back in the beginning of the contest,
          which is now selling for $350, doubling his purchase! Twitter Wildcard
          winner ZiggyNo comes in at seventh place with a value of $775.19,
          jumping up over $100! Ziggy has sold several cards this past week,
          including a Rui Hachimura Prizm Base A for $39, a Brandon Clarke Prizm
          Base A for $29, and a Kevin Porter Jr Prizm Base for $24.50, while
          also buying a Ja Morant Optic Base A for $85!
        </p>
        <p>
          The contest is certainly heating up and is incredibly active as we
          progress throughout the beginning weeks of the regular season. Cards
          are flying off the shelves left and right for these contestants, and
          all had many more transactions than those that were featured. Be sure
          to keep an eye out for more updates as the season progresses, and
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          (@starstock) for all the latest SIBBC news!
        </p>
      </>
    ),
  },
  {
    title: "Start of the Season!",
    date: Date.UTC(2020, 11, 21),
    body: (
      <>
        <p>
          Finally… the NBA regular season begins{" "}
          <span className="font-weight-bold">TOMORROW!</span> The Basketball
          card market has been absolutely exploding in recent weeks thanks to
          the NBA Draft, offseason, and short but sweet preseason, and now it’s
          time for all the hype leading up to the season is going to start
          paying off. Similarly, the SIBBC has been heating up as well, with
          almost all of our contestants incredibly active and taking advantage
          of said preseason hype, while just a couple are still studying the
          market and waiting for inflated prices to drop down and pounce on the
          dips.
        </p>
        <p>
          Instagram wild card winner Gavin Lee (@bayareacardinvestor) remains in
          the top spot after another week of preseason with a portfolio value of
          $779.83. Gavin is continuing his strategy of buying and selling lower
          valued cards and again, seems to be paying off. He sold three Markelle
          Fultz Optic Base B’s for $3.33 each, a KZ Okpala Prizm Red White and
          Blue B for $4, and also bought a De’Andre Hunter Prizm Base B for
          $4.40, and a Colin Sexton Prizm Base B for $7. Gavin is finding money
          in the crumbs with the StarStock B market and is taking advantage of
          their rising prices as A’s rise as well.
        </p>
        <p>
          Keenan from The High Upside Show remains in second place with a
          portfolio valued at $730.45. He had a HUGE selling week, which
          included four Coby White Mosaic Base cards (2x $15, 2x $16) after
          buying them for around $13, and eight Hamidou Diallo Prizm Base cards
          ($6) among many others. Keenan has a great mix of lower and higher
          value cards that he has been flipping like crazy, so it wouldn’t be
          surprising to see him eventually jump into the top spot.
        </p>
        <p>
          Twitter wildcard ZiggyNo comes in at third place again, with a
          portfolio valued at $616.51 to complete the unchanged top three, with
          a several good sales like a Wendell Carter Jr Prizm Base for $12.25, a
          Kevin Porter Jr Prizm Base for $23.50, and cashed in on the Kyle Guy
          hype with a $7.99 sale of his Prizm Base. Ziggy also bought several
          good cards, like a Keldon Johnson Prizm Base for $19.89.
        </p>
        <p>
          Fourth, fifth and sixth places are incredibly tight, and belong to Ben
          Lyons of ESPN/NBA TV ($589.25), DJ Skee ($587.58) and Coach Duggs of
          Barstool Sports ($584.44). All three had a very active week,
          specifically DJ Skee, who this week took advantage of waiting on the
          market, and broke into the competition taking the fourth place spot.
          All also had great purchases, which include Ben buying a Kevin Porter
          Jr Prizm Base for $18, DJ Skee buying an RJ Barrett Prizm Base for
          $53, and Coach Duggs with three Kyle Guy Prizm Base cards for $7.
        </p>
        <p>
          Our biggest purchases of the week both belong to Kendrick Perkins, who
          is coming in at ninth place. Perk bought two Marvin Bagley PSA 10’s,
          one Prizm Silver for $275, and one Prizm Base for $100. These look
          like sound investments going into the season, as Bagley is primed for
          a breakout year. Look out for Perk’s portfolio value to jump if Bagley
          comes out of the gates hot!
        </p>
        <p>
          These were just some of the many trades made by our contestants this
          week. Two are currently waiting patiently for their time to pounce,
          but the rest of the field is busy making their moves and positioning
          themselves for the start of the regular season. With how active the
          first two weeks have been, the SIBBC is certainly shaping up to be an
          action packed competition!
        </p>
        <p>
          Be sure to keep an eye out for more updates as the season progresses,
          and follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          (@starstock) for all the latest SIBBC news!
        </p>
      </>
    ),
  },
  {
    title: "One Week Away Update",
    date: Date.UTC(2020, 11, 15),
    body: (
      <>
        <p>
          Basketball season is slowly approaching, and we find ourselves one
          week away from the tip off of the 2020-2021 NBA Regular Season.
          Several of our contestants have started to build out their portfolios
          and are taking advantage of this red hot basketball market, as
          indicated in our{" "}
          <Link to={"/marketplace/indices/nba-emerging-30"}>
            NBA Emerging 30 Index
          </Link>
          . This past week the index went up 11.3%, and is now sitting at a
          value of 1,684.38. Now that every team has played at least once in the
          preseason our contestants have been able to evaluate all of the
          players and build out a preliminary portfolio. Here is how the
          standings are looking so far.
        </p>
        <p>
          A commanding lead is starting to shape up for our Instagram Wildcard
          Winner Gavin Lee (@bayareacardinvestor), who currently holds the top
          spot with a portfolio value of $764.41. Gavin has an interesting
          strategy currently, as his average card price is $3.50. Some of his
          more interesting flips have been Talen Horton Tucker B’s, specifically
          an Optic Hyper Pink, which he bought for $1.50 and flipped for $7.50.
          Buying up a large amount of smaller valued cards and anticipating
          price jumps could certainly pay off, however he does hold a couple
          stud cards in his portfolio, including some Bam Adebayo and Donovan
          Mitchell. Gavin is in a moderate risk/reward scenario and will be
          interesting to follow as the season progresses.
        </p>
        <p>
          In second place is Keenan from The High Upside show at $601.71 in
          value. Keenan, like Gavin, has been active so far both buying cards,
          and selling them. He recently sold several Darius Garland Prizm Base
          A’s for $7.50, and picked up several Coby White Mosaic Base A’s for
          $10 right before. Keenan’s portfolio is more highly valued with an
          average card price of $8.66, but he has been incredibly active and has
          big selling potential as the season progresses.
        </p>
        <p>
          Third and fourth place are in a tight race already as the contest
          opens up. Twitter Wildcard ZiggyNo (@ZiggysBreaks) holds onto third
          place with $556.82, and Coach Duggs of Barstool Sports in fourth with
          $548.17. Ziggy’s portfolio is anchored by a Zion Prizm Base ($285),
          and several other prime assets like three Rui Hachimura Prizm Base
          cards (2x $30, 1x $39), and a Cam Reddish Prizm Base ($39). Coach
          Duggs is big on Brandon Clarke to start off the season, and bought
          five of his Prizm Base cards, with prices ranging from $16.99 to
          $19.59. Duggs also is holding onto a Ja Morant Prizm Base which he
          bought for $170. Both Ziggy and Duggs have big growth potential in
          their portfolios and hold onto players who have the chance to
          experience price increases as the season progresses.
        </p>
        <p>
          Fifth and sixth places belong to Ben Lyons of ESPN/NBA TV ($534.69)
          and Jack Settleman of Snapback Sports ($519.01). Ben has a very
          interesting strategy that certainly pay off, and is buying up
          2020-2021 Prizm Draft Picks. Since Prizm isn’t coming out until around
          mid-march, these rookies won’t have a ton of cards available in the
          market, so the draft picks could absolutely jump up in price once they
          start their rookie campaigns. Jack on the other hand has a vastly
          different approach. Jack has only four cards: 2 RJ Barrett Prizm PSA
          10’s ($189.99) and 2 RJ Barrett Prizm A’s ($40). Jack is doubling down
          on the Knicks and RJ, and with the improvement they have showed so far
          in the first two games.
        </p>
        <p>
          As a reminder, these are very early updates based on activity prior to
          the regular season. Accounts have been funded for a little over a
          week, and some contestants are still mulling over some decisions prior
          to pulling the trigger on certain purchases.
        </p>

        <p>
          Be sure to keep an eye out for more updates as the season progresses,
          and follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          (@starstock) for all your latest SIBBC news!
        </p>
      </>
    ),
  },
  {
    title: "Announcing SIBBC 2021!",
    date: Date.UTC(2020, 11, 3),
    body: (
      <>
        <p>
          Another NBA season is almost upon us! And you didn’t think we’d let
          that happen without a championship of our own, right? Yup, it’s time
          for our sophomore SIBBC! Once again, we’ve recruited 10 contestants
          from the world of sports and sports cards to demonstrate their
          expertise. Who can take an initial $500 an amass the biggest wallet
          from trading on StarStock alone?
        </p>
        <p>
          Added to the mix will be 2 lucky individuals picked from our social
          media followers. We’ll be picking them soon, so keep your eyes on our
          Twitter and Instagram accounts to be in with a shot!
        </p>
        <p>
          As usual we’ll be highlighting key trades and sharing people’s
          portfolios on social media throughout the contest, so be sure to
          follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          to keep up with the latest happenings.
        </p>
      </>
    ),
  },
];

const contestants = [
  {
    id: 1,
    photo: KendrickPerkins,
    name: "Kendrick Perkins",
    role: "NBA Champion / ESPN Analyst",
    bio: (
      <>
        Kendrick Perkins is a former NBA Champion with the Boston Celtics, and a
        current NBA TV Analyst for ESPN with The Jump, Get Up and First Take. He
        is also an avid card collector.
      </>
    ),
    twitter: "KendrickPerkins",
    instagram: "kendrickperkins",
    balance: 8.2,
    cards_sold: 3,
    cards_owned: 18,
    account_value: 462.19,
  },
  {
    id: 2,
    photo: DJSkee,
    name: "DJ Skee",
    role: "DJ / Cards and Coffee",
    bio: (
      <>
        DJ Skee is a DJ, entrepreneur, and avid card collector. Skee has played
        sets all over the country, including at Vikings games this season. He is
        the founder of Skee Sports and Cards and Coffee, a new card shop in LA.
      </>
    ),
    twitter: "djskee",
    instagram: "djskee",
    balance: 9.19,
    cards_sold: 171,
    cards_owned: 588,
    account_value: 492.73,
  },
  {
    id: 3,
    photo: JackSettleman,
    name: "Jack Settleman",
    role: "SnapBack Sports",
    bio: (
      <>
        Jack is a sports content creator with SnapBack Sports. He’s leading the
        charge in sports cards and ready to compete.
      </>
    ),
    twitter: "jacksettleman",
    instagram: "jacksettleman",
    balance: 0.25,
    cards_sold: 2, // Subtract 67 cards from previous contest!
    cards_owned: 13,
    account_value: 333.65,
  },
  {
    id: 4,
    photo: BenLyons,
    name: "Ben Lyons",
    role: "ESPN / NBA TV",
    bio: (
      <>
        Ben Lyons is a contributor for ESPN LA and NBA TV! Ben’s looking to put
        his NBA knowledge to the test in the card game against some big card
        collectors
      </>
    ),
    twitter: "Iambenlyons",
    instagram: "iambenlyons",
    balance: 0.16,
    cards_sold: 60,
    cards_owned: 134,
    account_value: 513.59,
  },
  {
    id: 5,
    photo: CoachDuggs,
    name: "Coach Duggs",
    role: "Barstool Sports",
    bio: (
      <>
        Coach Duggs is one of Barstool Sports’ newest hires. Duggs is bringing
        card content to Barstool with @DuggsDen and is making a splash in the
        industry.
      </>
    ),
    twitter: "CoachDuggs",
    instagram: "barstoolduggs",
    balance: 232.7,
    cards_sold: 119,
    cards_owned: 2,
    account_value: 352.7,
  },
  {
    id: 6,
    photo: KOT4Q,
    name: 'Kenny "KOT4Q" Beecham',
    role: "NBA 2K YouTuber",
    bio: (
      <>
        Kenny is a NBA 2K YouTuber (KOT4Q) who’s 2K rebuilds are some of the
        best out there! Check him out there, and along the contest in the SIBBC.
      </>
    ),
    twitter: "KOT4Q",
    instagram: "kennybeecham_",
    balance: 60.31,
    cards_sold: 5,
    cards_owned: 67,
    account_value: 287.18,
  },
  {
    id: 7,
    photo: JoePompliano,
    name: "Joe Pompliano",
    role: "Huddle Up",
    bio: (
      <>
        Joe Pompliano runs the Daily sports newsletter Huddle Up. Joe writes
        awesome daily emails breaking down the business and money behind sports.
      </>
    ),
    twitter: "JoePompliano",
    instagram: "readhuddleup",
    balance: 48.41,
    cards_sold: 0,
    cards_owned: 10,
    account_value: 339.41,
  },
  {
    id: 8,
    photo: SashaT,
    name: "Sasha Tamaddon",
    role: "CardHops / The Sasha T Show",
    bio: (
      <>
        Sasha is the founder of @cardhops and The Sasha T Show. Sasha puts out
        great card content across all his platforms and is one to watch in the
        SIBBC
      </>
    ),
    twitter: "sasha_tamaddon",
    instagram: "sashatamaddon",
    balance: 30.02,
    cards_sold: 0,
    cards_owned: 4,
    account_value: 378.02,
  },
  {
    id: 9,
    photo: TyWilson,
    name: "Ty Wilson",
    role: "Breaker Culture",
    bio: (
      <>
        Ty Wilson runs BreakerCulture and is the founder of the Bench Clear
        Sports Card Network. Bench Clear is a great place for Podcasts,
        Analytics, Interviews, and much more!
      </>
    ),
    twitter: "BreakerCulture",
    instagram: "breakerculture",
    balance: 1.9,
    cards_sold: 44,
    cards_owned: 100,
    account_value: 552.32,
  },
  {
    id: 10,
    photo: Keenan,
    name: "Keenan",
    role: "High Upside Show",
    bio: (
      <>
        Keenan runs the High Upside Show. He has a great newsletter and other
        content for daily insight on the hobby
      </>
    ),
    twitter: "HighUpsideShow",
    instagram: "highupsideshow",
    balance: 64.76,
    cards_sold: 186,
    cards_owned: 42,
    account_value: 1228.62,
  },
  {
    id: 11,
    photo: Ziggy,
    name: "ZiggyNo",
    role: "Twitter Wildcard",
    bio: (
      <>
        ZiggyNo is a long time collector/investor. He’s an Admin to several
        Facebook groups including Ballers (high-end basketball vouch only).
        Ziggy created{" "}
        <a href="https://www.youtube.com/user/whyxocai">Sports Cards Daily</a>{" "}
        (140 consecutive days strong) and co-hosts Hobby Hotline with some of
        the biggest names in the hobby.
      </>
    ),
    twitter: "ZiggysBreaks",
    instagram: "ziggynosportscardsdaily",
    balance: 3.01,
    cards_sold: 25,
    cards_owned: 21,
    account_value: 442.51,
  },
  {
    id: 12,
    photo: GavinLee,
    name: "Gavin Lee",
    role: "Instagram Wildcard",
    bio: (
      <>
        Gavin Lee is a sports cards investor/collector who runs
        Bayareacardinvestor on Instagram. He collects primarily basketball cards
        and is ready to take the competition by storm and show the community
        what he’s capable of!
      </>
    ),
    twitter: "",
    instagram: "bayareacardinvestor",
    balance: 4.4,
    cards_sold: 227,
    cards_owned: 252,
    account_value: 777.53,
  },
];

const prizes = [
  {
    rank: "1st",
    name: "2020-21 Prizm Basketball Hobby Box",
    value: "$3,000 + the title!",
    photo: PrizeOne,
  },
  {
    rank: "2nd",
    name: "2012-13 Jimmy Butler Prizm PSA 10",
    value: "$1,100",
    photo: PrizeTwo,
  },
  {
    rank: "3rd",
    name: "2018-19 Luka Doncic Donruss Base PSA 10",
    value: "$550",
    photo: PrizeThree,
  },
  {
    rank: "4th",
    name: "2017-18 De’Aaron Fox Prizm Base PSA 10",
    value: "$270",
    photo: PrizeFour,
  },
  {
    rank: "5th",
    name: "2017-18 Bam Adebayo Optic Red & Yellow PSA 10",
    value: "$150",
  },
  {
    rank: "6th",
    name: "2018-19 Lonnie Walker Prizm Base PSA 10",
    value: "$80",
  },
  {
    rank: "7th",
    name: "2017-18 OG Anunoby Prizm Red/White/Blue",
    value: "$35",
  },
  { rank: "8th", name: "2019-20 Darius Garland Prizm Base", value: "$10" },
  { rank: "9th", name: "2018-19 Marvin Bagley Optic Base", value: "$7.50" },
  {
    rank: "10th",
    name: "2019-20 Talen Horton Tucker Mosaic Base",
    value: "$3",
  },
  { rank: "11th", name: "2018-19 Jevon Carter Prizm Base", value: "$1" },
  { rank: "12th", name: "Mfiondu Kabengele NBA Hoops Base", value: "$0.10" },
];

const SibbcOrangeLight = "#f4a179";
const SibbcOrange = "#e88343";
const SibbcOrangeDark = "#dd832c";

const BannerStart = SibbcOrange;
const BannerEnd = "#66449d";

const StyledBanner = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  background: ${(props) =>
    props.background ||
    css`
    linear-gradient(170deg, ${BannerStart}, ${BannerEnd});
    `};

  @media (min-width: 36rem) {
    padding: 0.5rem 4rem;
  }
`;

export const SibbcAnnouncementBanner = () => {
  return (
    <StyledBanner className="shadow text-center">
      <p className="mb-0">
        The SIBBC has a new leader as Keenan makes some huge gains through the
        market dip! 🚀{" "}
        <a href="/sibbc" className="font-weight-bold text-white text-nowrap">
          Read more{" "}
          <FontAwesomeIcon
            icon={faAngleDoubleRight}
            size={"sm"}
            className="ml-1"
          />
        </a>
      </p>
    </StyledBanner>
  );
};

const HeaderContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0b1f3c;
  background-position: 70% 20%;

  height: 13rem;
  @media (min-width: 48rem) {
    height: 20rem;
  }
`;

const ContestTitle = styled.h1`
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 4rem;
  line-height: 3rem;
  font-style: italic;
  text-transform: uppercase;
  color: white;
  display: block;
  margin: 0 -0.25rem;
  padding-bottom: 1rem;

  @media (min-width: 48rem) {
    font-size: 8rem;
    line-height: 7rem;
  }

  border-bottom: 4px solid ${SibbcOrange};
`;

const ContestTagline = styled.h2`
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: white;
  display: block;
  margin-top: 2px;
  padding-top: 1rem;

  @media (min-width: 48rem) {
    font-size: 1.5rem;
    line-height: 1.25rem;
  }

  border-top: 2px solid ${SibbcOrange};
`;

const SectionHeading = styled.h3`
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.5rem;
  border-bottom: 2px solid ${SibbcOrange};
  padding-bottom: 1rem;
  margin: 2px -0.125rem;

  @media (min-width: 48rem) {
    font-size: 2rem;
  }
`;

const SectionBodyText = styled.div`
  border-top: 1px solid ${SibbcOrange};
  padding-top: 1rem;
  margin-bottom: 0;
`;

const NameLabel = styled.h4`
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 1.75rem;
  text-align: center;
`;

const RoleLabel = styled.h5`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1.5rem;
  text-align: center;
`;

const RankLabel = styled.h5`
  font-family: "Barlow", sans-serif;
  font-weight: "Black";
  font-size: "1.25rem";
  width: 2.5rem;
`;

const ScoreNameLabel = styled.h5`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 0;
`;

const ScoreCardsLabel = styled.p`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1rem;
  margin-bottom: 0;
`;

const ScoreLabel = styled.h4`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1.5rem;
  text-align: right;
  margin-bottom: 0;
`;

const BulletList = styled.ul`
  list-style: none;
  line-height: 1.75rem;

  li::before {
    content: "\\25CB"; /* CSS Code/unicode for a bullet */
    color: ${SibbcOrange};
    font-weight: bold;
    display: inline-block;
    width: 2em;
    margin-left: -2rem;
  }
`;

const CountdownSection = styled.div`
  background: linear-gradient(135deg, ${SibbcOrangeLight}, ${SibbcOrangeDark});
`;

const Sibbc2021 = () => {
  useEffect(
    () =>
      logAmplitudeEvent(CONTENT_EVENTS.INVITATIONAL_CHAMPIONSHIP.PAGE_VIEWED, {
        Championship: "SIBBC 2021",
      }),
    [],
  );

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>
          SIBBC 2021 - StarStock Invitational Basketball Championship 2021
        </title>
      </Helmet>
      <ContentPageHelmet />

      <HeaderContainer className="row text-left">
        <div className="col p-3 inner-container-wide mx-auto d-flex flex-column justify-content-end">
          <ContestTitle>SIBBC 2021</ContestTitle>
          <ContestTagline>
            StarStock invitational Basketball Championship 2021
          </ContestTagline>
        </div>
      </HeaderContainer>

      <PageSection
        width={PageSectionWidths.wide}
        type={PageSectionTypes.background}
        removePaddingBottom={true}
      >
        <div className="row">
          <div className="col-lg-5 order-lg-2 d-flex flex-column">
            <CountdownSection className="shadow px-4 py-3 rounded mb-4">
              <SectionHeading
                className="text-center"
                style={{ color: "white", borderBottom: "1px solid white" }}
              >
                Week 9 of 19
              </SectionHeading>
              <SectionBodyText
                className="text-center text-deselected py-2"
                style={{
                  fontFamily: "Barlow, sans-serif",
                  fontWeight: "Black",
                  fontStyle: "italic",
                  fontSize: "2rem",
                  color: "white",
                }}
              >
                2 weeks until first elimination!
              </SectionBodyText>
            </CountdownSection>

            <div
              className="bg-white shadow px-3 px-sm-4 py-3 rounded mb-5 d-flex flex-column justify-content-start align-items-stretch"
              style={{ border: `1px solid ${SibbcOrange}` }}
            >
              <SectionHeading>Leaderboard</SectionHeading>
              <SectionBodyText />
              <p className="small text-speak text-uppercase text-center mb-0">
                Ranked by portfolio value + balance
              </p>
              <div className="mt-3">
                {contestants
                  .sort((a, b) => b.account_value - a.account_value)
                  .map((contestant, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row align-items-center border-top py-2"
                    >
                      <RankLabel className="text-whisper mb-1 mr-3 text-center flex-shrink-0 d-none d-sm-block">
                        {moment.localeData().ordinal(index + 1)}
                      </RankLabel>
                      <div
                        className="bg-light rounded-circle border overflow-hidden mr-3 flex-shrink-0"
                        style={{ width: "3rem", height: "3rem" }}
                      >
                        <img
                          src={contestant.photo}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="flex-shrink-1 flex-grow-1 text-truncate mr-4">
                        <ScoreNameLabel className="text-truncate">
                          {contestant.name}
                        </ScoreNameLabel>
                        <ScoreCardsLabel className="text-whisper text-truncate">
                          {pluralize("card", contestant.cards_owned)},{" "}
                          {pluralize("sale", contestant.cards_sold)}
                        </ScoreCardsLabel>
                      </div>
                      <ScoreLabel>
                        {currencyFormatter.format(contestant.account_value)}
                      </ScoreLabel>
                    </div>
                  ))}
              </div>
              <p className="small text-speak text-uppercase text-center pt-3 mb-0 border-top">
                Last updated Friday, February 19th
              </p>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="mb-5">
              <SectionHeading>
                The biggest championship in Basketball… cards
              </SectionHeading>
              <SectionBodyText>
                <p>
                  Basketball is back, baby! To celebrate, we’re bringing back
                  the SIBBC for a second time in 2020! Once again, we’ve invited
                  10 of the sharpest minds in sports to prove their knowledge,
                  along with a couple of wildcard entrants to keep them on their
                  toes! With a starting deposit of $500, who can bank the
                  biggest stash by trading basketball cards on StarStock?
                </p>
                <p>
                  This year we’ve added a wrinkle into the tournament to make it
                  a more competitive season.{" "}
                  <span className="font-weight-bold">
                    Once the season reaches the All-Star Break (March 5th-10th)
                    we will begin eliminating contestants from the competition.
                  </span>{" "}
                  At the conclusion of the All-Star Break (March 10th), the
                  bottom two contestants (places 11 and 12) will be eliminated
                  from the SIBBC. After that, one contestant will be eliminated
                  each week until there are only two contestants left.
                  Eliminated contestants will still receive their prizes, based
                  on the position they finish.
                </p>
                <p className="mb-0">
                  They’ve got until midnight on March 10th to maximize their
                  account balance before the cuts begin to be made!
                </p>
              </SectionBodyText>
            </div>

            <div className="mb-5">
              <SectionHeading>Latest updates</SectionHeading>
              <SectionBodyText />

              {updates.map((update) => (
                <div
                  key={update.title}
                  className="mb-3 py-3"
                  style={{ borderBottom: `1px solid ${SibbcOrange}` }}
                >
                  <h5 className="font-weight-bold">{update.title}</h5>
                  <p className="small text-speak text-uppercase mb-3">
                    {moment(update.date).format("dddd, MMMM Do")}
                  </p>
                  <div className="mb-3">{update.body}</div>
                </div>
              ))}
            </div>

            <div className="mb-5">
              <SectionHeading>Contest rules</SectionHeading>
              <SectionBodyText>
                <p>
                  The trades are theirs to decide, but that doesn’t mean
                  anything goes! To keep everyone playing fair there’s a few
                  simple rules everyone’s gotta stick to.
                </p>
                <BulletList>
                  <li>Trades are for NBA cards only (obv!)</li>
                  <li>
                    No deposits – the $500 starting fund is all they have to
                    play with
                  </li>
                  <li>
                    No sending in additional cards to boost their collection
                  </li>
                  <li>
                    No related party trades – e.g. getting a friend to pay over
                    the odds
                  </li>
                  <li>We may review transactions to ensure they are fair</li>
                  <li>
                    Promoting listed cards on social media is allowed and part
                    of the game!
                  </li>
                </BulletList>
              </SectionBodyText>
            </div>

            <div className="mb-5">
              <SectionHeading>Prizes</SectionHeading>
              <SectionBodyText>
                <p>
                  These trades are with real cards and real money, so everyone
                  can come away a winner! But it isn’t a competition without
                  some prizes to fight over, so lets see what bonus awards are
                  at stake!
                </p>
                {prizes.length ? (
                  <>
                    <div className="row">
                      {prizes
                        .filter((prize) => prize.photo)
                        .map((prize) => (
                          <div
                            key={prize.rank}
                            className="col-sm-3 text-center mb-3"
                          >
                            <h3
                              style={{
                                fontFamily: "Barlow, sans-serif",
                                fontWeight: "Black",
                                fontStyle: "italic",
                                fontSize: "2rem",
                              }}
                            >
                              {prize.rank}
                            </h3>
                            <img
                              src={prize.photo}
                              className="img-fluid mb-2"
                              alt=""
                            />
                            <p className="mb-1">{prize.name}</p>
                            <p
                              style={{
                                fontFamily: "Barlow, sans-serif",
                                fontWeight: "Black",
                                fontStyle: "italic",
                                fontSize: "1rem",
                              }}
                            >
                              Approx value {prize.value}
                            </p>
                          </div>
                        ))}
                    </div>
                    <table className="table table-sm table-borderless">
                      <thead className="border-bottom">
                        <tr>
                          <th>Rank</th>
                          <th>Prize</th>
                          <th className="text-right">Approx value</th>
                        </tr>
                      </thead>
                      <tbody style={{ lineHeight: "1.5rem" }}>
                        {prizes
                          .filter((prize) => !prize.photo)
                          .map((prize) => (
                            <tr key={prize.rank}>
                              <td className="font-weight-bold">{prize.rank}</td>
                              <td>{prize.name}</td>
                              <td className="text-right">{prize.value}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <h3 className="font-weight-bold text-deselected text-center text-uppercase mt-4 p-3 border rounded">
                    Prizes announced soon!
                  </h3>
                )}
              </SectionBodyText>
            </div>
          </div>
        </div>
      </PageSection>

      <PageSection
        width={PageSectionWidths.wide}
        type={PageSectionTypes.foreground}
        removePaddingBottom={true}
      >
        <div>
          <SectionHeading>The contestants</SectionHeading>
          <SectionBodyText />
          <div className="row mt-3 d-flex flex-row">
            {contestants.map((contestant) => (
              <div
                key={contestant.id}
                className="col-sm-6 col-md-4 col-xl-3 d-flex flex-column justify-content-start align-items-center pb-4 mb-4 border-bottom"
              >
                <div
                  className="bg-light rounded-circle border mb-3 overflow-hidden"
                  style={{ width: "8rem", height: "8rem" }}
                >
                  <img src={contestant.photo} className="img-fluid" alt="" />
                </div>
                <NameLabel>{contestant.name}</NameLabel>
                <RoleLabel>{contestant.role}</RoleLabel>
                <p
                  className="pt-3 mb-2"
                  style={{ borderTop: `1px solid ${SibbcOrange}` }}
                >
                  {contestant.bio}
                </p>
                <div className="btn-toolbar justify-content-center w-100 mt-auto">
                  {contestant.twitter && (
                    <a
                      href={`https://twitter.com/${contestant.twitter}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm bg-light border text-primary mt-2 mx-1"
                    >
                      <FontAwesomeIcon
                        icon={faTwitter}
                        className="fa fa-lg mr-2"
                      />
                      @{contestant.twitter}
                    </a>
                  )}
                  {contestant.instagram && (
                    <a
                      href={`https://instagram.com/${contestant.instagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm bg-light border text-primary mt-2 mx-1"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="fa fa-lg mr-2"
                      />
                      {contestant.instagram}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </PageSection>
    </>
  );
};

export default Sibbc2021;
