import { useMutation, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";

import GET_MY_ADDRESSES from "../../../api/queries/GET_MY_ADDRESSES";
import DELETE_ADDRESS from "../../../api/mutations/DELETE_ADDRESS";
import CREATE_ADDRESS from "../../../api/mutations/CREATE_ADDRESS";
import MAKE_ADDRESS_DEFAULT from "../../../api/mutations/MAKE_ADDRESS_DEFAULT";
import UPDATE_ADDRESS from "../../../api/mutations/UPDATE_ADDRESS";

import { AddressBook } from "../components/AddressBook";
import { getformValues } from "../utils/getFormValues";
import { AddressValues } from "../types";
import { PageSection } from "../../../components/PageSection";

export const AddressBookPage = () => {
  const { data, loading } = useQuery(GET_MY_ADDRESSES, {
    variables: {},
  });

  const [deleteAddress] = useMutation(DELETE_ADDRESS, {
    refetchQueries: [{ query: GET_MY_ADDRESSES }],
  });

  const [createAddress] = useMutation(CREATE_ADDRESS, {
    refetchQueries: [{ query: GET_MY_ADDRESSES }],
  });

  const [makeDefault] = useMutation(MAKE_ADDRESS_DEFAULT, {
    refetchQueries: [{ query: GET_MY_ADDRESSES }],
  });

  const [updateAddress] = useMutation(UPDATE_ADDRESS, {
    refetchQueries: [{ query: GET_MY_ADDRESSES }],
  });

  const onCreateAddress = (values: AddressValues) => {
    return createAddress({
      variables: getformValues(values),
    });
  };

  const onDeleteAddress = (id: string | undefined) => {
    return deleteAddress({
      variables: {
        id: id,
      },
    });
  };

  const onMakeDefaultAddress = (id: string | undefined) => {
    return makeDefault({ variables: { id: id } });
  };

  const onEditAddress = (id: string, values: AddressValues) => {
    let variables = getformValues({ ...values, id });
    return updateAddress({ variables });
  };

  const addresses = (!loading && data.myAddresses) || [];

  return (
    <>
      <Helmet>
        <title>Address Book</title>
      </Helmet>

      <PageSection>
        <AddressBook
          addresses={addresses}
          loading={loading}
          onCreateAddress={onCreateAddress}
          onDeleteAddress={onDeleteAddress}
          onMakeDefaultAddress={onMakeDefaultAddress}
          onEditAddress={onEditAddress}
        />
      </PageSection>
    </>
  );
};
