import { gql } from "@apollo/client";

const UPDATE_ADDRESS = gql`
  mutation UPDATE_ADDRESS(
    $id: ID!
    $description: String!
    $name: String!
    $addressLines: String!
    $locality: String!
    $region: String!
    $postalCode: String!
    $country: String!
    $role: String!
  ) {
    updateAddress(
      id: $id
      description: $description
      name: $name
      addressLines: $addressLines
      locality: $locality
      region: $region
      postalCode: $postalCode
      country: $country
      role: $role
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export default UPDATE_ADDRESS;
