export const sortOptions = [
  {
    key: "ask-price-value",
    label: "Best value listings",
    value: "ask_price_value,ask_price_desc,id",
    description: "Their best value listings vs. latest sale price",
  },
  {
    key: "ask-price-asc",
    label: "Lowest ask price",
    value: "ask_price_asc,id",
    description: "Their lowest priced cards",
  },
  {
    key: "ask-price-desc",
    label: "Highest ask price",
    value: "ask_price_desc,id",
    description: "Their highest priced cards",
  },
  {
    key: "latest-sale-price-asc",
    label: "Lowest latest sale price",
    value: "last_sale_price_asc,id",
    description: "Cards with the lowest latest sale price",
  },
  {
    key: "latest-sale-price-desc",
    label: "Highest latest sale price",
    value: "last_sale_price_desc,id",
    description: "Cards with the highest latest sale price",
  },
  {
    key: "newest",
    label: "Newest cards",
    value: "newest,id",
    description: "The latest cards added to their collection",
  },
  {
    key: "oldest",
    label: "Oldest cards",
    value: "oldest,id",
    description: "The oldest cards in their collection",
  },
];
