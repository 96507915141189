import { useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import DepositForm from "./DepositForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const DepositSection = ({
  amount,
  successPath,
  depositSuccessEventName,
  depositFailureEventName,
}) => {
  const [error, setError] = useState("");

  return (
    <>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <Elements stripe={stripePromise}>
        <DepositForm
          amount={amount}
          setError={setError}
          successPath={successPath}
          depositSuccessEventName={depositSuccessEventName}
          depositFailureEventName={depositFailureEventName}
        />
      </Elements>
    </>
  );
};

export default DepositSection;
