import { gql } from "@apollo/client";

const ADD_ALL_ITEMS_TO_DRAFT_SHIPPING_REQUEST = gql`
  mutation addAllItemsToDraftShippingRequest {
    addAllItemsToDraftShippingRequest {
      itemCount
      errors {
        field
        message
      }
    }
  }
`;

export default ADD_ALL_ITEMS_TO_DRAFT_SHIPPING_REQUEST;
