import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faChartPie,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Nav } from "react-bootstrap";

const NavBar = ({
  visibleSection,
  setVisibleSection,
  fixtureTeamPlayersCount,
}) => {
  return (
    <Nav
      activeKey={visibleSection}
      className="secondary-nav-light bg-white justify-content-center border-top"
    >
      <Nav.Item>
        <Nav.Link
          eventKey="CARDS_AVAILABLE"
          onSelect={() => setVisibleSection("CARDS_AVAILABLE")}
        >
          <span className="font-weight-bold">
            <FontAwesomeIcon
              icon={faLayerGroup}
              className="text-primary mr-2"
            />
            All cards
          </span>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          eventKey="MY_CARDS"
          onSelect={() => setVisibleSection("MY_CARDS")}
        >
          <span className="font-weight-bold">
            <FontAwesomeIcon icon={faArchive} className="text-primary mr-2" />
            Your cards
          </span>
        </Nav.Link>
      </Nav.Item>

      {fixtureTeamPlayersCount !== 0 && (
        <Nav.Item>
          <Nav.Link
            eventKey="GAME_LOG"
            onSelect={() => setVisibleSection("GAME_LOG")}
          >
            <span className="font-weight-bold">
              <FontAwesomeIcon
                icon={faChartPie}
                className="text-primary mr-2"
              />
              Game stats
            </span>
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  );
};

export default NavBar;
