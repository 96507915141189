import { gql } from "@apollo/client";

const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($amount: Int!, $saveCard: Boolean) {
    createPaymentIntent(amount: $amount, saveCard: $saveCard) {
      paymentIntentToken
      paymentSequence
    }
  }
`;

export default CREATE_PAYMENT_INTENT;
