import { BreakCard } from "../../features/breaks";

import ErrorNotice from "../ErrorNotice";
import Loader from "../Loader";
import EmptySection from "./EmptySection";
import BreakCarousel from "./BreakCarousel";

const BreakCarouselContainer = ({ error, loading, data }) => {
  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;

  return data.boxBreaks.edges.length ? (
    <BreakCarousel themeColor={"red"}>
      {data.boxBreaks.edges.map(({ node: boxBreak }) => (
        <BreakCard key={boxBreak.id} boxBreak={boxBreak} />
      ))}
    </BreakCarousel>
  ) : (
    <EmptySection />
  );
};

export default BreakCarouselContainer;
