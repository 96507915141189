import { Dispatch } from "react";
import { useMutation } from "@apollo/client";

import REQUEST_BOX_BREAK from "../../../../api/mutations/breaks/REQUEST_BOX_BREAK";
import { logAmplitudeEvent } from "../../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../../utils/analytics/events";
import { Action, ErrorDetail, State } from "../types";

const useRequestBreak = (
  dispatch: Dispatch<Action>,
  state: State,
  data: any,
) => {
  const [createPersonalBreakRequest] = useMutation(REQUEST_BOX_BREAK, {
    onCompleted({ personalBreakRequestCreate }) {
      const { errors, purchasedSpotsCost } = personalBreakRequestCreate;
      const hasError = errors && errors.length > 0;

      if (hasError) {
        logAmplitudeEvent(BREAKS_EVENTS.PERSONALS_REQUEST.FAILED, {
          "Error Fields": (errors as unknown as ErrorDetail[])?.map(
            (e) => e?.field,
          ),
          "Error Messages": (errors as unknown as ErrorDetail[])?.map(
            (e) => e?.message,
          ),
        });

        return dispatch({ type: "error", payload: errors });
      }

      const selectedBox = data.myCollection.edges.filter(
        ({ node }: any) => node.id === state.form.itemOwnershipId,
      )[0];
      const selectedBreak = data.boxBreaks.edges.filter(
        ({ node }: any) => node.id === state.form.boxBreakId,
      )[0];

      logAmplitudeEvent(BREAKS_EVENTS.PERSONALS_REQUEST.SUCCEEDED, {
        "Break Fee": state.form.breakFee,
        "Break Slug": selectedBreak.node.slug,
        "Box Name": selectedBox.node.item.cardParallel.name,
        "Box Sport": selectedBox.node.item.cardParallel.sportId,
      });

      return dispatch({ type: "success", payload: purchasedSpotsCost });
    },
    onError(errors) {
      logAmplitudeEvent(BREAKS_EVENTS.PERSONALS_REQUEST.FAILED, {
        "Error Fields": (errors as unknown as ErrorDetail[])?.map(
          (e) => e?.field,
        ),
        "Error Messages": (errors as unknown as ErrorDetail[])?.map(
          (e) => e?.message,
        ),
      });

      dispatch({ type: "error" });
    },
  });

  return { createPersonalBreakRequest };
};

export default useRequestBreak;
