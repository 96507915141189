import { useState } from "react";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

import { Modal } from "../../../../components/Modal";
import { InfoNotice } from "../../../../components/InfoNotice";

import { Refetch } from "../../types";
import { AddCardForm } from "../AddCardForm";

import { CloseButton, customStyles, Success } from "./styles";

interface Props {
  firstPaymentMethod: boolean;
  handleModalClose: () => void;
  isOpen: boolean;
  refetchQuery: Refetch;
  showSuccess: boolean;
  setShowSuccess: (showSuccess: boolean) => void;
}

export const AddCardModal = ({
  firstPaymentMethod,
  handleModalClose,
  isOpen,
  refetchQuery,
  showSuccess,
  setShowSuccess,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");

  const closeModal = () => {
    refetchQuery();
    handleModalClose();
    setShowSuccess(false);
  };

  return (
    <Modal
      handleModalClose={closeModal}
      isOpen={isOpen}
      title={"Add a new payment card"}
      customStyles={customStyles}
    >
      {showSuccess && (
        <>
          <Success>
            <InfoNotice
              icon={faCreditCard}
              message={["Your card has been added to your account"]}
              state={"positive"}
              title={"Success!"}
            />
          </Success>

          <CloseButton onClick={closeModal}>Close</CloseButton>
        </>
      )}

      {!showSuccess && (
        <AddCardForm
          handleModalClose={handleModalClose}
          isOpen={isOpen}
          firstPaymentMethod={firstPaymentMethod}
          setShowSuccess={setShowSuccess}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
    </Modal>
  );
};
