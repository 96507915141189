import { useQuery } from "@apollo/client";

import { AuctionNode } from "../../../generated/schema";
import { logAmplitudeEvent } from "../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../utils/analytics/events";

import { GET_AUCTION } from "../api/GET_AUCTION";
import { AuctionParams } from "../types";

interface QueryResponse {
  auction: AuctionNode;
}

export const useFetchAuction = (slug: string) => {
  const { data, error, loading } = useQuery<QueryResponse, AuctionParams>(
    GET_AUCTION,
    {
      variables: { slug },
      fetchPolicy: "cache-first",
      onCompleted: ({ auction }) => {
        if (auction) {
          logAmplitudeEvent(BREAKS_EVENTS.AUCTION.PAGE_VIEWED, {
            "Auction Name": auction.name,
            "Auction Slug": auction.slug,
            "Auction State": auction.state,
          });
        }
      },
    },
  );

  return { data, error, loading };
};
