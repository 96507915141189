import { gql } from "@apollo/client";

const LOGIN_USER = gql`
  mutation login($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      refreshToken
      refreshExpiresIn
      payload
    }
  }
`;

export default LOGIN_USER;
