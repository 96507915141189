import { useApolloClient, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { setAccessTokenExpiry, setRefreshTokenExpiry } from "../utils/auth";
import { BackgroundImageWrapper } from "../utils/wrappers";
import { amplitudeIdentify, logAmplitudeEvent } from "../utils/analytics";
import { ACCOUNT_EVENTS } from "../utils/analytics/events";
import { isLoggedInVar } from "../utils/apolloConfig";

import LOGIN_USER from "../api/mutations/LOGIN_USER";
import LoginForm from "../components/LoginForm";
import { useFromLocation } from "../hooks/useFromLocation";

import logo from "../images/logo-light.svg";

const Login = () => {
  useEffect(() => logAmplitudeEvent(ACCOUNT_EVENTS.SIGN_IN.FORM_VIEWED), []);

  const client = useApolloClient();
  const { from } = useFromLocation();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [login, { error }] = useMutation(LOGIN_USER, {
    onCompleted({ tokenAuth }) {
      amplitudeIdentify(tokenAuth.payload);
      logAmplitudeEvent(ACCOUNT_EVENTS.SIGN_IN.SUCCEEDED);

      localStorage.clear();
      setAccessTokenExpiry(tokenAuth.payload.exp);
      setRefreshTokenExpiry(tokenAuth.refreshExpiresIn);

      client.clearStore().then(() => {
        isLoggedInVar(true);
        navigate(from);
      });
    },

    onError(error) {
      setErrorMessage(error.message);
      logAmplitudeEvent(ACCOUNT_EVENTS.SIGN_IN.FAILED);
      setSubmitting(false);
    },
  });

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>

      <div className="container-fluid">
        <BackgroundImageWrapper />

        <div className="row">
          <div className="col">
            <div className="text-center">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid my-3 my-sm-5" />
              </Link>
            </div>

            <div className="card mx-auto max-width-26 mb-5">
              <div className="card-body text-center">
                <h1 className="card-title h5 mt-2 mb-3">
                  <span className="font-weight-bold">Log in</span> to your
                  <br />
                  StarStock account
                </h1>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage === "User suspended"
                      ? errorMessage
                      : "Incorrect email, username or password"}
                  </div>
                )}

                <LoginForm
                  login={login}
                  isSubmitting={isSubmitting}
                  setSubmitting={setSubmitting}
                />

                <Link className="card-link" to="/signup" state={{ from }}>
                  Not yet registered? Sign up free here
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
