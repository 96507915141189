import React from "react";

import { AddressNode } from "../../../generated/schema";
import { Select, Wrapper } from "./styles";

export interface Props {
  options: AddressNode[] | null;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  value: string;
}

export const AddressDropdown = ({ options, onChange, value }: Props) => {
  return (
    <Wrapper>
      <Select value={value} onChange={onChange}>
        {options?.map((o, i) => (
          <option key={i} value={o.id}>
            {o.addressLines}, {o.region}, {o.postalCode}, {o.country}
          </option>
        ))}
      </Select>
    </Wrapper>
  );
};
