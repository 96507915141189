import { KeyboardEvent } from "react";
import { ResponsiveImage } from "../../../../components/ResponsiveImage";
import { LotState } from "../../../../generated/schema";
import { financial } from "../../../../utils/common";

import PlaceholderBG from "../../assets/auctions-logo-color.svg";
import { Countdown } from "../Countdown";
import {
  BodyContainer,
  Container,
  CurrentBid,
  Heading,
  Info,
  Status,
  Winner,
} from "./styles";

interface Props {
  endTime?: Date;
  heading: string;
  imageUrl?: string;
  nextLot?: boolean;
  onClick?(): void;
  price: number | null;
  state: LotState;
  winner?: string | null;
}

export const LotCard = ({
  endTime,
  heading,
  imageUrl,
  nextLot = false,
  onClick,
  price,
  state,
  winner,
}: Props) => {
  const isActive = [LotState.BIDDING].includes(state);
  const isCanceled = [LotState.CANCELED].includes(state);
  const isClosed = [LotState.SOLD, LotState.UNSOLD].includes(state);
  const successfulLot = ![LotState.CANCELED, LotState.UNSOLD].includes(state);
  const isUpcomingAndNotActive =
    !isActive && !isCanceled && !isClosed && !nextLot;

  const handleKeypress = (event: KeyboardEvent) => {
    if (!onClick) return;

    const { key } = event;

    if (key === " " || key === "Enter" || key === "Spacebar") {
      event.preventDefault();
      onClick();
    }
  };

  const statusLabel = () => {
    if (isActive) return "Highest Bid";
    if (isCanceled) return "Canceled";
    if (isClosed && successfulLot) return "Sold for";
    if (isClosed) return "Unsold";

    return "Bidding opens at";
  };

  return (
    <Container
      isActive={isActive}
      onClick={onClick}
      onKeyDown={handleKeypress}
      role={onClick ? "button" : undefined}
      tabIndex={0}
    >
      <ResponsiveImage src={imageUrl} background={PlaceholderBG} />

      <BodyContainer>
        <Heading>{heading}</Heading>
        <Status isActive={isActive || nextLot} nextLot={nextLot}>
          {isActive && "🔥 Bidding now"}
          {nextLot && "Up next!"}
          {isCanceled && "❌ Lot canceled"}
          {isClosed && "Bidding ended"}
          {isUpcomingAndNotActive && "Still to come"}
        </Status>

        <Info>
          <CurrentBid>
            {statusLabel()} {price && <span>${financial(price, 0)}</span>}
          </CurrentBid>

          {isActive && endTime && <Countdown endTime={endTime} />}
          {isClosed && successfulLot && (
            <Winner>
              Won by <span>{winner}</span>
            </Winner>
          )}
        </Info>
      </BodyContainer>
    </Container>
  );
};
