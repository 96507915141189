import { useEffect } from "react";

import { logAmplitudeEvent } from "../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../utils/analytics/events";

import BreakSearchInterface from "../BreakSearchInterface";

const BreaksSearch = ({ searchQuery, sport, era }) => {
  useEffect(
    () =>
      logAmplitudeEvent(BREAKS_EVENTS.SEARCH.PAGE_VIEWED, {
        Sport: sport,
        Era: era,
      }),
    [era, sport],
  );

  return (
    <BreakSearchInterface searchQuery={searchQuery} sport={sport} era={era} />
  );
};

export default BreaksSearch;
