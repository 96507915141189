import { RefObject } from "react";

import { ResponsiveImage } from "../../../../components/ResponsiveImage";
import { LotState } from "../../../../generated/schema";
import { financial } from "../../../../utils/common";

import PlaceholderBG from "../../assets/auctions-logo-color.svg";
import { InfoPanel } from "../InfoPanel";
import { Container, Information, Heading, Paragraph } from "./styles";

interface Props {
  closingPrice: number | null | undefined;
  description: string;
  imageUrl: string | null | undefined;
  innerRef?: RefObject<HTMLDivElement>;
  name: string;
  openingPrice: number | null | undefined;
  state: LotState;
}

export const LotDetails = ({
  closingPrice,
  description,
  imageUrl,
  innerRef,
  name,
  openingPrice,
  state,
}: Props) => {
  const hasEnded = [LotState.SOLD, LotState.UNSOLD].includes(state);

  return (
    <Container ref={innerRef} hasImage={true}>
      <div>
        <ResponsiveImage background={PlaceholderBG} src={imageUrl} />
      </div>

      <Information>
        <Heading>{name}</Heading>

        <InfoPanel
          rows={[
            {
              label: "Opening price",
              value:
                typeof openingPrice === "number"
                  ? `$${financial(openingPrice, 0)}`
                  : "-",
            },
            ...(hasEnded
              ? [
                  {
                    label: "Closing price",
                    value:
                      typeof closingPrice === "number"
                        ? `$${financial(closingPrice, 0)}`
                        : "No bids",
                  },
                ]
              : []),
          ]}
        />

        <Paragraph>{description}</Paragraph>
      </Information>
    </Container>
  );
};
