import { Helmet } from "react-helmet";
import styled from "styled-components";

import RequestBreakForm from "../../components/Breaks/RequestBreakForm/Standalone";
import { Colors } from "../../themes/Default";

const Container = styled.div`
  background-color: white;
  border: 1px solid rgb(0 0 0 / 12.5%);
  border-radius: 0.25rem;
  flex: 1 1 auto;
  margin: 3rem auto;
  max-width: 36rem;
  min-height: 1px;
  padding: 1.25rem 1rem;

  @media (min-width: 500px) {
    padding: 1.25rem;
  }
`;

const Heading = styled.h1`
  color: ${Colors.Astronaut};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 1rem 0;
  text-align: left;
`;

const Copy = styled.p`
  margin-bottom: 1.5rem;
  text-align: left;
`;

const RequestPersonalBreak = () => (
  <>
    <Helmet>
      <title>Request Personal Break</title>
    </Helmet>

    <Container>
      <Heading>Request break from collection</Heading>
      <Copy>
        Our breaking team can open any box from your collection as part of an
        upcoming or live personals break. Breaking fee is 5% of the box value
        ($10 minimum fee).
      </Copy>

      <RequestBreakForm />
    </Container>
  </>
);

export default RequestPersonalBreak;
