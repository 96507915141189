import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@material-ui/core";

import { financial } from "../../../../utils/common";
import { useFetchAccountData } from "../../../../hooks/useFetchAccountData";

import {
  Container,
  WalletBalance,
  WalletContainer,
  WalletDetailContainer,
  WalletIcon,
  WalletLabel,
} from "./styles";

export const WalletInfo = () => {
  const { accountData, error } = useFetchAccountData();

  return (
    <Container>
      <WalletContainer>
        <WalletIcon icon={faWallet} size="2x" />
      </WalletContainer>

      <WalletDetailContainer>
        <WalletLabel>StarStock wallet</WalletLabel>

        <WalletBalance>
          Account balance:&nbsp;
          {error && (
            <span>
              {error.message ? error.message : "Failed to load account balance"}
            </span>
          )}
          {accountData && <span>${financial(accountData.balance)}</span>}
          {!accountData && !error && (
            <CircularProgress size={16} aria-label="account balance loading" />
          )}
        </WalletBalance>
      </WalletDetailContainer>
    </Container>
  );
};
