import OptionPillsFilter from "./OptionPillsFilter";

const AvailabilityFilter = ({
  minListings,
  setMinListings,
  minOffers,
  setMinOffers,
}) => {
  const notSet = "";

  // Option values define min listings and min offers, respectively
  const options = [
    { label: "In vault", value: [notSet, notSet] },
    { label: "For sale", value: ["1", notSet] },
    { label: "Offers", value: [notSet, "1"] },
  ];

  const selectedOption =
    options.find(
      (option) =>
        (option.value[0] === minListings) & (option.value[1] === minOffers),
    ) || options[0];

  return (
    <div className="mb-4">
      <h6>Availability</h6>
      <hr className="mt-1" />

      <OptionPillsFilter
        options={options}
        selectedOption={selectedOption}
        setSelectedValue={(selectedValue) => {
          setMinListings(selectedValue[0]);
          setMinOffers(selectedValue[1]);
        }}
      />
    </div>
  );
};

export default AvailabilityFilter;
