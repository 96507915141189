import { useState } from "react";

import {
  productName,
  productNameSuffix,
  productQuantityString,
} from "../utils/content";

import CardParallelContainer from "../components/CardParallelContainer";
import CardParallelGradeTabContainer from "../components/CardParallelGradeTabContainer";
import MyListingsBadge, {
  MyListingsBadgeVariants,
} from "./CardDetails/MyListingsBadge";
import MarketSummaryDataRow from "./MarketSummaryDataRow";

const CollectionCardParallel = ({ cardParallel, onClick }) => {
  const { gradedcardparallelSet: gradedCardParallelEdges } = cardParallel;
  const gradedCardParallels = gradedCardParallelEdges.edges.map(
    ({ node }) => node,
  );
  const hiddenGradesCount = Math.max(
    gradedCardParallelEdges.totalCount - gradedCardParallels.length,
    0,
  );

  // Let the user hover (on desktop) or tap (on mobile) to swap grade
  const [selectedGradedCardParallelId, setSelectedGradedCardParallelId] =
    useState((gradedCardParallels[0] && gradedCardParallels[0].id) || null);
  const selectedGradedCardParallel =
    gradedCardParallels.find(
      (gcp) => gcp.id === selectedGradedCardParallelId,
    ) || gradedCardParallels[0];

  const linkPath = selectedGradedCardParallel
    ? `/marketplace/cards/${cardParallel.slug}?grade=${selectedGradedCardParallel.grade.id}`
    : `/marketplace/cards/${cardParallel.slug}`;

  return (
    <CardParallelContainer
      id={cardParallel.id}
      slug={cardParallel.slug}
      linkPath={onClick ? null : linkPath}
      onClick={onClick}
      productType={cardParallel.productType}
      photo={cardParallel.frontSidePhotoSmall}
      itemCount={cardParallel.myItemsCount}
      cardProgram={cardParallel.playerCard.cardFamily.program.name}
      cardFamily={cardParallel.playerCard.cardFamily.name}
      cardName={productName(cardParallel)}
      cardSetNumber={productNameSuffix(cardParallel)}
      parallel={cardParallel.parallel}
      serialLimit={cardParallel.serialLimit}
      grade={selectedGradedCardParallel.grade}
      configCard
      configClickableCard
      configLargeMode
      configInsetChildren
    >
      <CardParallelGradeTabContainer
        gradedCardParallels={gradedCardParallels}
        selectedGradedCardParallelId={selectedGradedCardParallelId}
        setSelectedGradedCardParallelId={setSelectedGradedCardParallelId}
        hiddenGradesCount={hiddenGradesCount}
        linkPath={linkPath}
      >
        <div className="pb-2 d-flex flex-row flex-wrap justify-content-between align-items-baseline mt-n2">
          <div className="mr-2 flex-grow-1 flex-shrink-0">
            <p className="text-speak mt-2 mb-0" style={{ fontSize: "0.75rem" }}>
              You own{" "}
              {productQuantityString(
                selectedGradedCardParallel.myItemCount,
                cardParallel.productType,
              )}
            </p>
          </div>
          <div className="d-flex flex-row flex-grow-1 flex-wrap justify-content-end align-items-start badge-row-right flex-nowrap">
            {selectedGradedCardParallel.myItemCount > 0 && (
              <MyListingsBadge
                variant={MyListingsBadgeVariants.doesNotRequireContext}
                listingsCount={selectedGradedCardParallel.myOpenSellOrderCount}
                minPrice={selectedGradedCardParallel.myOpenSellOrderMinPrice}
              />
            )}
          </div>
        </div>
        <div className="pt-2 border-top">
          <p
            className="text-uppercase text-whisper mb-1"
            style={{ fontSize: "0.625rem" }}
          >
            MARKET SUMMARY
          </p>
          <MarketSummaryDataRow
            listingsCount={selectedGradedCardParallel.openSellOrderCount}
            lowestListingPrice={
              selectedGradedCardParallel.openSellOrderMinPrice
            }
            offersCount={selectedGradedCardParallel.openBuyOrderCount}
            bestOfferPrice={selectedGradedCardParallel.openBuyOrderMaxPrice}
            latestSalePrice={selectedGradedCardParallel.lastSalePrice}
          />
        </div>
      </CardParallelGradeTabContainer>
    </CardParallelContainer>
  );
};

export default CollectionCardParallel;
