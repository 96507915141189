import OptionPillsFilter from "./OptionPillsFilter";

const ProductTypeFilter = ({ selectedValue, setSelectedValue }) => {
  const notSet = "";

  const options = [
    { label: "All products", value: notSet },
    { label: "Cards", value: "card" },
    { label: "Boxes", value: "box" },
  ];

  const selectedOption =
    options.find((option) => option.value === selectedValue) || options[0];

  return (
    <div className="mb-4">
      <h6>Product Type</h6>
      <hr className="mt-1" />

      <OptionPillsFilter
        options={options}
        selectedOption={selectedOption}
        setSelectedValue={setSelectedValue}
      />
    </div>
  );
};

export default ProductTypeFilter;
