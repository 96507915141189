import { useQuery } from "@apollo/client";

import { PRODUCT_TYPES } from "../../../api/types";
import GET_CARD_PARALLELS, {
  GET_CARD_PARALLELS_DEFAULT_PARAMS,
} from "../../../api/queries/GET_CARD_PARALLELS";

import CardParallelTallCarouselWidget from "./CardParallelTallCarouselWidget";
import { MarketCardParallelTallWrapper } from "../../MarketCardParallelTall";

const ThemeColor = "#4e8ad4";
const widgetName = "FeaturedCardsCarousel";

const FeaturedCardsCarouselWidget = ({ sport = "", era = "" }) => {
  const featuredResultSet =
    "FEATURED_CARDS" +
    (sport && `_${sport.toUpperCase()}`) +
    (era && `_${era.toUpperCase()}`);

  const { data, loading, error } = useQuery(GET_CARD_PARALLELS, {
    variables: Object.assign({}, GET_CARD_PARALLELS_DEFAULT_PARAMS, {
      featuredResultSet: featuredResultSet,
      sport: sport,
      era: era,
      productType: PRODUCT_TYPES.CARD,
      orderBy: "trending_rank_desc,id",
      first: 20,
    }),
  });

  return (
    <CardParallelTallCarouselWidget
      id={"featured-cards-widget"}
      widgetName={widgetName}
      title="Featured cards"
      subtitle="Our selections from the hottest, latest or most traded cards"
      linkTitle="View more"
      linkUrl={`/marketplace/cards?sport=${sport}&era=${era}&sort=featured&product_type=card`}
      themeColor={ThemeColor}
      error={error}
    >
      {!error && !loading && data
        ? data.cardParallels.edges.map(({ node }, i) => (
            <MarketCardParallelTallWrapper
              key={i}
              cardParallel={node}
              themeColor={ThemeColor}
              widgetName={widgetName}
              position={i}
            />
          ))
        : [...Array(5)].map((_, i) => (
            <MarketCardParallelTallWrapper key={i} />
          ))}
    </CardParallelTallCarouselWidget>
  );
};

export default FeaturedCardsCarouselWidget;
