import styled, { css } from "styled-components";
import { Colors, Gradients } from "../../../../themes/Default";

export const BodyContainer = styled.div`
  border-top: 1px solid ${Colors.Border};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
`;

export const Container = styled.div<{ isActive?: boolean }>`
  background: white;
  border: 1px solid ${Colors.Border};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${Gradients.Auctions};
    `}
`;

export const CurrentBid = styled.p`
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1rem;
  margin: 0;

  span {
    color: ${Colors.Havelock};
    font-weight: bold;
  }
`;

export const Heading = styled.h3`
  flex-grow: 1;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0.25rem 0 0;
`;

export const Status = styled.p<{ isActive?: boolean; nextLot?: boolean }>`
  font-size: 0.875rem;
  margin: 0.25rem 0 0;

  ${({ isActive }) =>
    isActive &&
    css`
      color: #bb1011;
    `}
`;

export const Winner = styled.p`
  background: ${Gradients.Auctions};
  border-radius: 0.25rem 0 0 0.25rem;
  color: ${Colors.Astronaut};
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 0.875rem;
  margin: -0.25rem -1rem 0 0.5rem;
  padding: 0.25rem 1rem;
  text-align: right;

  span {
    font-weight: bold;
  }
`;
