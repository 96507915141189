import { AuctionState } from "../../../../generated/schema";

import { AuctionsCarouselWidget } from "../AuctionsCarouselWidget";

interface Props {
  era?: string;
  sport?: string;
}

export const UpcomingAuctionsCarouselWidget = ({ era, sport }: Props) => (
  <AuctionsCarouselWidget
    id="upcoming-auctions-widget"
    linkTitle="View our auctions"
    linkUrl={"/breaks"}
    options={{
      variables: {
        first: 12,
        era: era,
        orderBy: "featured_rank_desc,scheduled_start_time_asc,id",
        sport: sport,
        state: [AuctionState.LIVE, AuctionState.PUBLISHED],
      },
    }}
    subtitle="Get involved in the fast-paced action of StarStock live auctions"
    themeColor={"#da4d14"}
    title={"Live auctions"}
    widgetName="UpcomingAuctionsList"
  />
);
