import LazyLoad from "react-lazyload";

import { ImageWrapper, Placeholder, StyledImage } from "./styles";

const LazyLoadImage = ({
  alt = "",
  className,
  offset = 150,
  overflow = false,
  placeholder,
  scrollContainer = null,
  src,
}) => (
  <ImageWrapper className={className}>
    <LazyLoad
      offset={offset}
      overflow={overflow}
      placeholder={<Placeholder alt="" src={placeholder} />}
      scrollContainer={scrollContainer}
    >
      <StyledImage alt={alt} src={src} />
    </LazyLoad>
  </ImageWrapper>
);

export default LazyLoadImage;
