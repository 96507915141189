import { Children, ReactNode, useState } from "react";

import EmptySection from "../Breaks/EmptySection";
import ErrorNotice from "../ErrorNotice";
import LoadMoreButton from "../LoadMoreButton";
import Loader from "../Loader";

import { Grid } from "./styles";

interface Props {
  children?: ReactNode;
  error: boolean;
  fetchMore?(): void;
  hasNextPage?: boolean;
  loading: boolean;
}

export const EventCardGrid = ({
  children,
  error,
  fetchMore,
  hasNextPage = false,
  loading,
}: Props) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const count = Children.toArray(children).length;

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;
  if (count === 0) return <EmptySection />;

  return (
    <>
      <Grid>{children}</Grid>

      {hasNextPage && fetchMore && (
        <LoadMoreButton
          loadingMore={loadingMore}
          onLoadMore={() => {
            fetchMore();
            setLoadingMore(false);
          }}
          setLoadingMore={setLoadingMore}
        />
      )}
    </>
  );
};
