import { productName, productNameSuffix } from "../../utils/content";

import CardParallelContainer from "../CardParallelContainer";
import BidListGradeItem from "./BidListGradeItem";

const BidListCardItem = ({
  groupedBid,
  groupedBidClose,
  acceptListingRefetches,
}) => {
  const { buyorderSet } = groupedBid;
  const { cardParallel, grade } = buyorderSet.edges[0].node;
  const {
    slug,
    serialLimit,
    frontSidePhotoSmall,
    parallel,
    playerCard: { cardFamily },
  } = cardParallel;

  return (
    <CardParallelContainer
      slug={slug}
      linkPath={`/marketplace/cards/${slug}`}
      productType={cardParallel.productType}
      photo={frontSidePhotoSmall}
      cardProgram={cardFamily.program.name}
      cardFamily={cardFamily.name}
      cardName={productName(cardParallel)}
      cardSetNumber={productNameSuffix(cardParallel)}
      parallel={parallel}
      serialLimit={serialLimit}
      grade={grade}
      configCard
      configAllowWide
      configLargeMode
    >
      <BidListGradeItem
        groupedBid={groupedBid}
        groupedBidClose={groupedBidClose}
        acceptListingRefetches={acceptListingRefetches}
      />
    </CardParallelContainer>
  );
};

export default BidListCardItem;
