import { Button } from "react-bootstrap";

const ItemOwnershipItemButton = ({
  buttonAction,
  buttonLabel,
  itemOwnership,
}) => {
  return (
    <Button
      className="btn btn-secondary btn-block text-speak font-weight-bold mb-2"
      onClick={() => buttonAction(itemOwnership)}
    >
      {buttonLabel}
    </Button>
  );
};

export default ItemOwnershipItemButton;
