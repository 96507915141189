import { gql } from "@apollo/client";

const PASSWORD_RESET_REQUEST = gql`
  mutation passwordResetRequest($username: String!) {
    passwordResetRequest(username: $username) {
      success
    }
  }
`;

export default PASSWORD_RESET_REQUEST;
