import { gql } from "@apollo/client";

import { BOX_BREAK_AGGREGATE_FIELDS, BOX_BREAK_FIELDS } from "../../fragments";

const GET_BOX_BREAKS = gql`
  ${BOX_BREAK_AGGREGATE_FIELDS}
  ${BOX_BREAK_FIELDS}
  query GET_BOX_BREAKS(
    $query: String
    $sport: String
    $era: String
    $state: [String]
    $stateGroup: [String]
    $spotsAvailable: Boolean
    $featured: Boolean
    $orderBy: String
    $first: Int = 48
    $cursor: String
  ) {
    boxBreaks(
      query: $query
      sport: $sport
      era: $era
      state: $state
      stateGroup: $stateGroup
      spotsAvailable: $spotsAvailable
      featured: $featured
      orderBy: $orderBy
      first: $first
      after: $cursor
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...boxBreakFields
          ...boxBreakAggregateFields
          format {
            id
            name
          }
          myParticipant {
            spotSet {
              totalCount
            }
          }
          boxbreakphotoSet(first: 1) {
            edges {
              node {
                photoMd
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_BOX_BREAKS;
