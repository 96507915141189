import { useQuery } from "@apollo/client";

import { pluralize } from "../../../utils/content";

import GET_CARD_PARALLELS, {
  GET_CARD_PARALLELS_DEFAULT_PARAMS,
} from "../../../api/queries/GET_CARD_PARALLELS";

import CardParallelTallCarouselWidget from "./CardParallelTallCarouselWidget";
import { MarketCardParallelTallWrapper } from "../../MarketCardParallelTall";

const ThemeColor = "#E88343";
const widgetName = "TodaysMostTradedCarousel";

const TodaysMostTradedCarouselWidget = ({ sport = "", era = "" }) => {
  const featuredResultSet =
    "TOP_SELLERS_TODAY" +
    (sport && `_${sport.toUpperCase()}`) +
    (era && `_${era.toUpperCase()}`);

  const { data, loading, error } = useQuery(GET_CARD_PARALLELS, {
    variables: Object.assign({}, GET_CARD_PARALLELS_DEFAULT_PARAMS, {
      featuredResultSet: featuredResultSet,
      sport: sport,
      era: era,
      orderBy: "sales_last_24_hours_desc,id",
      first: 20,
    }),
  });

  return (
    <CardParallelTallCarouselWidget
      id={"todays-most-traded-widget"}
      widgetName={widgetName}
      title="Today’s most traded"
      subtitle="The hottest cards on StarStock over the past 24 hours"
      linkTitle="View more"
      linkUrl={`/marketplace/cards?sport=${sport}&sort=sales-last-24-hours`}
      themeColor={ThemeColor}
      error={error}
    >
      {!error && !loading && data
        ? data.cardParallels.edges.map(({ node }, i) => (
            <MarketCardParallelTallWrapper
              key={i}
              cardParallel={node}
              themeColor={ThemeColor}
              widgetName={widgetName}
              position={i}
              bannerText={`${pluralize("trade", node.salesLast24Hours || 0)}`}
            />
          ))
        : [...Array(5)].map((_, i) => (
            <MarketCardParallelTallWrapper key={i} />
          ))}
    </CardParallelTallCarouselWidget>
  );
};

export default TodaysMostTradedCarouselWidget;
