import styled from "styled-components";

interface StyledBannerProps {
  gradientStart?: string;
  gradientEnd?: string;
}

interface PrefixTextProps {
  textColor?: string | null;
}

export const BannerContainer = styled.div<StyledBannerProps>`
  align-items: center;
  background-image: linear-gradient(
    135deg,
    ${(props) => props.gradientStart} 0%,
    ${(props) => props.gradientEnd} 100%
  );
  border-bottom: 0.13rem solid white;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  display: flex;
  font-size: 0.875rem;
  height: 3.75rem;
  justify-content: center;
  text-align: center;
  width: 100%;

  @media (max-width: 20rem) {
    justify-content: flex-start;
  }

  @media (max-width: 54rem) {
    height: initial;
    padding-bottom: 1rem;
    padding-left: 1.25rem;
    padding-top: 1rem;
  }
`;

export const AnnouncementContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: left;
  width: 100%;

  @media (max-width: 28rem) {
    justify-content: flex-start;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 0;
`;

export const PrefixText = styled.span<PrefixTextProps>`
  color: ${(props) => props.textColor ?? "white;"};
  font-size: 1rem;
  font-weight: bolder;
  line-height: 1.5rem;
`;

export const BodyText = styled.span<PrefixTextProps>`
  color: ${(props) => props.textColor ?? "white"};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

export const Link = styled.a`
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;

  :link,
  :visited {
    color: #fff;
  }
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;

  @media (min-width: 54rem) {
    margin-left: 1rem;
    margin-right: 0.75rem;
  }
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  font-size: 1.25rem;
  height: 2.75rem;
  line-height: 1.5rem;
  opacity: 0.8;
  outline: none;
  overflow: hidden;
  padding: 0.375rem;
  text-align: center;
  width: 2.75rem;

  :active,
  :focus,
  :hover {
    background-color: rgb(255 255 255 / 20%);
  }
`;
