import SearchInput from "../components/Activity/SearchInput";
import SortControl, {
  SortControlPositions,
  SortControlVariants,
} from "../components/Marketplace/SortControl";

import { logAmplitudeEvent } from "../utils/analytics";

const SearchSortQueryControls = ({
  searchQuery,
  setSearchQuery,
  sortOrderKey,
  setSortOrderKey,
  sortOptions,
  trackingLabel,
}) => {
  return (
    <div className="d-flex flex-row justify-content-between mb-4">
      <div className="d-flex justify-content-start flex-shrink-0 flex-grow-1 mr-3">
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <SortControl
        className="mx-n1"
        sortOptions={sortOptions}
        selectedSortOptionKey={sortOrderKey}
        setSelectedSortOptionKey={(sortKey) => {
          logAmplitudeEvent(trackingLabel, { "Sort Order": sortKey });
          setSortOrderKey(sortKey, { method: "push" });
        }}
        configVariant={SortControlVariants.auto}
        configPosition={SortControlPositions.right}
      />
    </div>
  );
};

export default SearchSortQueryControls;
