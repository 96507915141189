import styled, { css } from "styled-components";
import Slider from "react-slick";
import { rgba } from "polished";

import { Colors } from "../../themes/Default";

import { CarouselThemes } from "./Carousel";

interface ArrowProps {
  direction: "left" | "right";
  themeColor: string;
  top: string;
}

interface CarouselItemProps {
  appearing: boolean;
}

interface PageIndicatorProps {
  theme: "light" | "dark";
  themeColor: string;
}

const DefaultThemeColor = Colors.Astronaut;

export const Arrow = styled.button<ArrowProps>`
  background: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 10%);
  color: ${rgba(Colors.Astronaut, 0.333)};
  display: block;
  height: 2rem;
  position: absolute;
  top: ${(props) => props.top || "38%"};
  transform: translateY(-50%);
  transition: all 300ms ease-in-out;
  width: 2rem;
  z-index: 2;

  ${(props) =>
    props.direction === "left" &&
    css`
      left: 0.75rem;
      right: auto;
    `}
  ${(props) =>
    props.direction === "right" &&
    css`
      left: auto;
      right: 0.75rem;
    `}

  &:focus {
    border: 1px solid ${(props) => props.themeColor || DefaultThemeColor};
    outline: none;
  }

  &:hover {
    color: ${rgba(Colors.Astronaut, 1)};
    outline: none;
  }

  &:active {
    background-color: ${(props) => props.themeColor || DefaultThemeColor};
    color: white;
    outline: none;
  }
`;

export const CarouselItem = styled.div<CarouselItemProps>`
  opacity: ${(props) => (props.appearing ? 0 : 1)};
  transition: all 400ms ease-in-out;
`;

export const PageIndicator = styled.button<PageIndicatorProps>`
  background-color: ${(props) =>
    props.theme === CarouselThemes.light
      ? css`
          ${rgba(Colors.Astronaut, 0.25)}
        `
      : css`rgb(255 255 255 / 25%)`};
  border: 0;
  border-radius: 1px;
  cursor: pointer;
  height: 2px;
  margin: 22px 0 8px;
  opacity: 0.5;
  padding: 0;
  transition: all 300ms ease-in-out;
  width: 32px;

  &:hover,
  &:focus {
    opacity: 1;
    outline: none;
  }

  .slick-active & {
    background-color: ${(props) =>
      `${rgba(props.themeColor || DefaultThemeColor, 1)}`};
    opacity: 1;
  }
`;

export const StyledSlider = styled(Slider)`
  margin-bottom: 0;

  @media (min-width: 53rem) {
    margin-bottom: 1rem;
  }

  .slick-list {
    margin-top: -2px;
    padding-top: 2px;
  }
`;
