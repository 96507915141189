import { gql } from "@apollo/client";

export const GET_AUCTION = gql`
  query auction($slug: String!) {
    auction(slug: $slug) {
      id
      slug
      name
      description
      lotCount
      lotMaxOpeningPrice
      lotMinOpeningPrice
      scheduledStartTime
      pusherChannelId
      state
      youtubeVideoId
      twitchChannelName
      twitchVideoId
      lotSet {
        totalCount
        edges {
          node {
            id
            name
            description
            openingPrice
            state
            biddingEndedAt
            biddingEndsAfter
            biddingOpenedAt
            highestBidPrice
            bidPriceIncrement
            minimumNextBidPrice
            newBidMinimumDuration
            lotphotoSet(first: 1) {
              edges {
                node {
                  photoMd
                }
              }
            }
            highestBid {
              id
              bidPrice
              user {
                id
                username
              }
            }
          }
        }
      }
      auctionphotoSet {
        edges {
          node {
            photoMd
            photoXs
          }
        }
      }
    }
  }
`;
