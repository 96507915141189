import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import ContentWrapper from "../components/ContentWrapper";
import JobListing from "../components/JobList/JobListing";
import Page from "../components/Contentful/Page";
import { getContentful, markdown } from "../utils/contentful";

const ALL_JOBS_QUERY = `
query {
  jobPostCollection(order: sortOrder_ASC) {
    items {
      title
      slug
      department
      intro
    }
  }
}
`;

const JobBox = styled.div`
  background: white;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 0 auto 1.5rem;
  padding: 1.5rem;
  transition-duration: 0.25s;

  :hover {
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    -webkit-transform: scale(1.01);
    transform: scale(1.01);

    transition-duration: 0.25s;
  }
  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  h2 {
    margin-top: 0;
  }
  p {
    margin-bottom: 0;
  }
`;

const JobList = () => {
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    getContentful(ALL_JOBS_QUERY).then(({ data }) => {
      setJobs(data.jobPostCollection.items);
    });
  }, []);

  if (!jobs) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>Careers</title>
      </Helmet>

      <ContentWrapper>
        <Page slug="jobs">
          {(page) => (
            <>
              <h1>{page.title}</h1>
              {markdown(page.body)}
            </>
          )}
        </Page>
        {jobs.map((job) => (
          <JobBox key={job.slug}>
            <JobListing job={job} />
          </JobBox>
        ))}
      </ContentWrapper>
    </>
  );
};

export default JobList;
