import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const Header = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

export const LotScheduleContainer = styled.div`
  margin-bottom: 6rem;

  @media (min-width: 48rem) {
    margin-bottom: 9rem;
  }

  @media (min-width: 66rem) {
    margin-bottom: 0;
  }
`;

export const Panel = styled.section`
  display: grid;
  grid-auto-flow: initial;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  margin-top: 1rem;
`;

export const Subtitle = styled.p`
  margin: 0.5rem 0 0;
`;

export const Tab = styled.li`
  & + & {
    margin-left: 1.5rem;
  }
`;

export const TabLink = styled.a`
  border-bottom: 4px solid white;
  color: #6d819d;
  display: block;
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0.25rem;

  @media (min-width: 48rem) {
    font-size: 1rem;
  }

  &:focus,
  &:hover {
    color: ${Colors.Astronaut};
    text-decoration: none;
  }

  &[aria-selected="true"] {
    border-bottom-color: #46d229;
    color: ${Colors.Astronaut};
  }
`;
export const TabsList = styled.ul`
  display: flex;
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;
  width: 100%;
`;
