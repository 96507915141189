import {
  LegalTermsLink,
  LegalTermsAndConditions,
  LegalText,
  UnderLined,
} from "./styles";

export const LegalTerms = () => {
  return (
    <LegalTermsAndConditions>
      <LegalText>
        By ticking this box, you are indicating that you have read and agree to{" "}
        <LegalTermsLink to="/terms" target="_blank">
          <UnderLined>StarStock’s Terms of Service</UnderLined>
        </LegalTermsLink>
        .
      </LegalText>
    </LegalTermsAndConditions>
  );
};
