import { gql } from "@apollo/client";

const CREATE_WITHDRAWAL_REQUEST = gql`
  mutation CREATE_WITHDRAWAL_REQUEST(
    $amount: Float!
    $paymentMethod: WithdrawalPaymentMethods!
    $paypalEmail: String
    $addressLine1: String
    $addressLine2: String
    $checkRecipient: String
    $city: String
    $state: String
    $country: String
    $zipCode: String
    $postalTracking: Boolean
  ) {
    createWithdrawalRequest(
      amount: $amount
      paymentMethod: $paymentMethod
      paypalEmail: $paypalEmail
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      checkRecipient: $checkRecipient
      city: $city
      state: $state
      country: $country
      zipCode: $zipCode
      postalTracking: $postalTracking
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export default CREATE_WITHDRAWAL_REQUEST;
