import { ReactNode } from "react";

import { Button, StyledLoadingSpinner } from "./styles";

interface Props {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  loading: boolean;
  onClick?(): void;
}

export const LoadingButton = ({
  children,
  className,
  disabled,
  loading,
  onClick,
}: Props) => (
  <Button className={className} disabled={disabled} onClick={onClick}>
    {loading && <StyledLoadingSpinner color="white" size={1} />}

    <span style={{ visibility: loading ? "hidden" : "visible" }}>
      {children}
    </span>
  </Button>
);
