import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import { pluralize } from "../../utils/content";
import { logAmplitudeEvent } from "../../utils/analytics";
import { WATCHLIST_EVENTS } from "../../utils/analytics/events";

import GET_MY_WISHLIST_PLAYERS from "../../api/queries/GET_MY_WISHLIST_PLAYERS";

import ErrorNotice from "../ErrorNotice";
import Loader from "../Loader";
import PlayerResults from "./PlayerResults";

const PlayerSection = () => {
  useEffect(() => {
    logAmplitudeEvent(WATCHLIST_EVENTS.PLAYERS.PAGE_VIEWED);
  }, []);

  const { data, loading, error, fetchMore } = useQuery(GET_MY_WISHLIST_PLAYERS);

  return (
    <div className="mt-4 mb-3">
      <div
        className="d-flex flex-row justify-content-between align-items-baseline mb-3"
        style={{ fontSize: "0.875rem" }}
      >
        <span>
          {!error && !loading
            ? `${pluralize(
                "player",
                data.myWishlistPlayers.totalCount,
              )} watched`
            : null}
        </span>
        <span>Sorted by most recent</span>
      </div>

      {error ? (
        <ErrorNotice error={error} />
      ) : loading ? (
        <Loader />
      ) : (
        <PlayerResults players={data.myWishlistPlayers} fetchMore={fetchMore} />
      )}
    </div>
  );
};

export default PlayerSection;
