import { useQuery } from "@apollo/client";

import GET_CARD_PARALLELS, {
  GET_CARD_PARALLELS_DEFAULT_PARAMS,
} from "../../../api/queries/GET_CARD_PARALLELS";

import CardParallelTallCarouselWidget from "./CardParallelTallCarouselWidget";
import { MarketCardParallelTallWrapper } from "../../MarketCardParallelTall";

const ThemeColor = "#4e8ad4";

const MarketWidget = ({ marketWidget }) => {
  const { data, loading, error } = useQuery(GET_CARD_PARALLELS, {
    variables: Object.assign({}, GET_CARD_PARALLELS_DEFAULT_PARAMS, {
      featuredResultSet: marketWidget.featuredResultKey,
      orderBy: marketWidget.resultSetOrderBy,
      first: 20,
    }),
  });

  return (
    <CardParallelTallCarouselWidget
      id={marketWidget.id}
      widgetName={marketWidget.title}
      title={marketWidget.title}
      subtitle={marketWidget.subtitle}
      themeColor={ThemeColor}
      error={error}
    >
      {!error && !loading && data
        ? data.cardParallels.edges.map(({ node }, i) => (
            <MarketCardParallelTallWrapper
              key={i}
              cardParallel={node}
              themeColor={ThemeColor}
              widgetName={marketWidget.title}
              position={i}
            />
          ))
        : [...Array(5)].map((_, i) => (
            <MarketCardParallelTallWrapper key={i} />
          ))}
    </CardParallelTallCarouselWidget>
  );
};

export default MarketWidget;
