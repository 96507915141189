import styled, { keyframes } from "styled-components";

const gradientStart = "#f48116";
const gradientEnd = "#ec331c";

const pulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.75; }
`;

export const Label = styled.span`
  animation: ${pulse} 1s infinite;
  background: linear-gradient(0deg, ${gradientStart}, ${gradientEnd});
  /* stylelint-disable */
  background-clip: inherit;
  -webkit-background-clip: text;
  /* stylelint-enable */
  color: ${gradientStart};
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-align: right;
  -webkit-text-fill-color: transparent;

  @media (min-width: 66rem) {
    font-size: 1.5rem;
  }
`;
