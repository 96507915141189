import { gql } from "@apollo/client";

const GROUPED_BID_CLOSE = gql`
  mutation GROUPED_BID_CLOSE($id: ID!, $allBids: Boolean) {
    groupedBidClose(id: $id, allBids: $allBids) {
      errors {
        field
        message
      }
    }
  }
`;

export default GROUPED_BID_CLOSE;
