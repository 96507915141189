import { Link } from "react-router-dom";
import styled from "styled-components";

import { PRODUCT_TYPES } from "../../../api/types";
import placeholderBox from "../../../images/placeholder-box.png";
import placeholderCard from "../../../images/placeholder-card.png";
import CardParallelProductPhoto from "../../CardParallelProductPhoto";
import { PageSection } from "../../PageSection";

const OuterContainer = styled.div`
  transition: all 200ms ease-in-out;
  &:hover {
    transform: translateY(-2px);
  }
`;

const StyledProductPhotoOuter = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 140%;
  margin-bottom: 1rem;
`;

const StyledProductPhotoInner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
  overflow: hidden;
`;

const HitCarouselWidgetItem = ({ hit, spotTypeName }) => {
  const {
    gradedCardParallel: { cardParallel, grade },
  } = hit;

  const linkPath = `/marketplace/cards/${cardParallel.slug}?grade=${grade.id}`;

  return (
    <Link key={hit.id} to={linkPath} className={"btn text-left p-0 m-0"}>
      <OuterContainer>
        <StyledProductPhotoOuter>
          <StyledProductPhotoInner productType={cardParallel.productType}>
            <CardParallelProductPhoto
              productType={cardParallel.productType}
              image={
                cardParallel.productType === PRODUCT_TYPES.BOX
                  ? cardParallel.boxProductPhotoMedium || placeholderBox
                  : cardParallel.frontSidePhotoMedium || placeholderCard
              }
              grade={grade}
            />
          </StyledProductPhotoInner>
        </StyledProductPhotoOuter>
        <small>
          {spotTypeName} #{hit.spot.rank}:
        </small>
        <p className="text-shout">{hit.spot.name}</p>
        <p>{cardParallel.name}</p>
      </OuterContainer>
    </Link>
  );
};

const BreakDetailHitsSection = ({ hitSet, spotTypeName }) => {
  const hits = hitSet.edges.map(({ node: hit }) => hit);

  return hits.length > 0 ? (
    <PageSection addMarginBottom>
      <div className="mb-4">
        <h4 className="font-weight-bold mb-2">Hits from the break</h4>
        <p className="mb-0">
          During the break weʼll post the cards pulled here for all to see.
          Check out what your hits are worth on the StarStock market, or if
          youʼre jealous, buy one of your own.
        </p>
      </div>

      <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 mx-n2">
        {hits.map((hit, index) => (
          <div className="col px-2 mb-3" key={hit.id}>
            <HitCarouselWidgetItem hit={hit} spotTypeName={spotTypeName} />
          </div>
        ))}
      </div>
    </PageSection>
  ) : null;
};

export default BreakDetailHitsSection;
