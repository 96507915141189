import classNames from "classnames";
import styled from "styled-components";

import { productQuantityString } from "../../utils/content";

import MarketSummaryDataRow from "../MarketSummaryDataRow";
import MyListingsBadge from "./MyListingsBadge";
import MyOffersBadge from "./MyOffersBadge";

const GraderLogo = styled.img`
  width: auto;
  height: 40px;
  opacity: ${(props) => (props.selected ? "100%" : "20%")};
`;

const GradeListItem = ({
  gradeTitle,
  gradeDescription,
  graderLogo,
  productType,
  totalItemCount,
  latestSalePrice,
  bidsCount,
  bidsMaxPrice,
  listingsCount,
  listingsMinPrice,
  myItemsCount,
  myListingsCount,
  myBidsCount,
  selected = false,
  setSelected,
  configAlwaysExpanded = false,
}) => {
  return (
    <div
      className={classNames("card clickable-card p-0", {
        "shadow border-primary": selected,
      })}
      style={{
        borderLeft: selected ? "1rem solid #4e8ad4" : "1rem solid #00000020",
      }}
    >
      <button
        type="button"
        className="btn btn-block p-3 text-left"
        onClick={setSelected}
      >
        <div className="d-flex flex-row justify-content-between flex-wrap align-items-center mt-n2 border-bottom pb-2">
          <div className="flex-shrink-0 mr-2 mt-2">
            <h5 className={"font-weight-bold mb-0"}>{gradeTitle}</h5>
            <p className="small mb-0">{gradeDescription}</p>
          </div>
          <GraderLogo
            selected={selected}
            src={graderLogo}
            className={"font-weight-bold mt-2 mb-0"}
            alt=""
          />
        </div>

        <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
          {totalItemCount ? (
            <p className="text-primary mt-2 mb-0">
              {productQuantityString(totalItemCount, productType)} in vault
            </p>
          ) : (
            <p className="text-whisper mt-2 mb-0">Not currently available</p>
          )}
          <div className="d-flex flex-row flex-shrink-1 flex-grow-1 flex-wrap justify-content-end align-items-start badge-row-right">
            {myBidsCount > 0 && <MyOffersBadge offersCount={myBidsCount} />}
            {myItemsCount > 0 && (
              <MyListingsBadge
                ownedCount={myItemsCount}
                listingsCount={myListingsCount}
              />
            )}
          </div>
        </div>

        {configAlwaysExpanded ||
        latestSalePrice ||
        listingsMinPrice ||
        bidsMaxPrice ? (
          <div className="mt-1">
            <MarketSummaryDataRow
              listingsCount={listingsCount}
              lowestListingPrice={listingsMinPrice}
              offersCount={bidsCount}
              bestOfferPrice={bidsMaxPrice}
              latestSalePrice={latestSalePrice}
            />
          </div>
        ) : null}
      </button>
    </div>
  );
};

export default GradeListItem;
