import { gql } from "@apollo/client";

const GET_MY_NOTIFICATION_PREFERENCES = gql`
  query GET_MY_NOTIFICATION_PREFERENCES {
    defaultNotificationPreferences {
      notificationType
      targetType
    }
    myNotificationPreferences {
      notificationType
      targetType
    }
    myAccount {
      userprofile {
        buyTargetPercentage
        sellTargetPercentage
      }
    }
  }
`;

export default GET_MY_NOTIFICATION_PREFERENCES;
