import { gql } from "@apollo/client";

const GET_MY_ADDRESSES = gql`
  query GET_MY_ADDRESSES {
    myAddresses {
      id
      description
      name
      addressLines
      locality
      region
      postalCode
      country
      role
      default
    }
  }
`;

export default GET_MY_ADDRESSES;
