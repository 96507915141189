import { useQuery, useMutation } from "@apollo/client";
import { SetStateAction, useState } from "react";

import GET_MY_ADDRESSES from "../../../../api/queries/GET_MY_ADDRESSES";
import CREATE_ADDRESS from "../../../../api/mutations/CREATE_ADDRESS";
import { AddressNode } from "../../../../generated/schema";
import Loader from "../../../../components/Loader";

import { getformValues } from "../../utils/getFormValues";
import { AddressValues } from "../../types";
import { AddressForm } from "../AddressBook/components/AddressForm";
import {
  ButtonGroup,
  Button,
  ButtonLink,
  Container,
  Label,
  List,
  Span,
} from "./styles";

interface AddressItemProps {
  address: AddressNode | null;
  fieldName: string | number;
  index: number;
}

interface AddressFieldProps {
  accountAddressBook?: boolean;
  checkBoxValue?: boolean;
  fieldName: string | number;
  onCancel?(): void;
  onSubmit(): void;
  handleCheckbox?(): void;
  values: AddressValues;
  setValues: (
    fieldName: SetStateAction<{ address_id: undefined }>,
    shouldValidate?: boolean | undefined,
  ) => void;
}

interface QueryResponse {
  myAddresses: (AddressNode | null)[];
}

const AddressItem = ({ address, fieldName, index }: AddressItemProps) => {
  const fieldId = `${fieldName}__${index}`;
  return (
    <Container>
      <input
        type="radio"
        name={`${fieldName}`}
        value={address?.id}
        id={fieldId}
      />
      <Label htmlFor={fieldId}>
        <Span>{address?.description}</Span>
        {address?.name}, {address?.addressLines}
      </Label>
    </Container>
  );
};

export const AddressField = ({
  accountAddressBook,
  fieldName,
  onCancel,
  onSubmit,
  setValues,
  values,
}: AddressFieldProps) => {
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [createSubmitting, setCreateSubmitting] = useState(false);
  const [serverError, setServerError] = useState(false);

  const { data, loading } = useQuery<QueryResponse>(GET_MY_ADDRESSES, {
    onCompleted: (data) => {
      data?.myAddresses?.forEach((a) => {
        if (a?.default)
          setValues({
            [fieldName]: a.id,
            address_id: a.id as unknown as undefined,
          });
      });
    },
  });

  const addresses: (AddressNode | null)[] =
    (!loading && data?.myAddresses) || [];

  const [createAddress] = useMutation(CREATE_ADDRESS, {
    refetchQueries: [{ query: GET_MY_ADDRESSES }],
  });

  const onCreateAddress = (values: AddressValues) => {
    return createAddress({ variables: getformValues(values) })
      .then((result) => {
        const errors = result.data.createAddress.errors;
        if (errors && errors.length > 0) {
          setServerError(true);
        }
      })
      .finally(() => {
        setCreateSubmitting(false);
      });
  };

  const toggleAddressForm = () => {
    setShowAddressForm(!showAddressForm);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!accountAddressBook ? (
        <AddressForm
          onSubmitForm={onSubmit}
          onSubmit={onCreateAddress}
          isSubmitting={createSubmitting}
          setSubmitting={setCreateSubmitting}
          buttonLabel="Add address"
          cancelButtonLabel="Cancel"
          onCancel={onCancel}
          serverError={serverError}
          values={values}
        />
      ) : (
        <List>
          {addresses &&
            addresses.map((address, i) => (
              <li key={address?.id}>
                <AddressItem
                  address={address}
                  fieldName={fieldName}
                  index={i}
                />
              </li>
            ))}
          <ButtonGroup>
            <ButtonLink to="/account/address-book">Edit addresses</ButtonLink>
            <Button onClick={toggleAddressForm}>Add an address</Button>
          </ButtonGroup>
          {showAddressForm && (
            <AddressForm
              onSubmitForm={undefined}
              onSubmit={onCreateAddress}
              isSubmitting={createSubmitting}
              setSubmitting={setCreateSubmitting}
              buttonLabel="Create new address"
              serverError={serverError}
              values={values}
            />
          )}
        </List>
      )}
    </>
  );
};
