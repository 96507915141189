import { gql } from "@apollo/client";

const GET_REQUEST_BOX_BREAK = gql`
  query GET_VALID_BREAKS_AND_BOXES {
    boxBreaks(
      allowPersonalBreakRequests: true
      spotsAvailable: true
      state: ["PUBLISHED_OPEN", "LIVE_OPEN"]
      orderBy: "scheduled_start_time_asc,id"
    ) {
      totalCount
      edges {
        node {
          id
          name
          scheduledStartTime
          slug
        }
      }
    }

    myCollection(productType: "BOX", listed: false) {
      edges {
        node {
          id
          breakFee
          item {
            cardParallel {
              name
              sportId
            }
          }
        }
      }
    }
  }
`;

export default GET_REQUEST_BOX_BREAK;
