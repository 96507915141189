import { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import classNames from "classnames";
import _ from "lodash";

import { logAmplitudeEvent } from "../../utils/analytics";
import { SELLING_EVENTS } from "../../utils/analytics/events";
import { commissionFromSalePrice } from "../../utils/financial";

import StepLoading from "./StepLoading";
import StepEdit from "./StepEdit";
import StepReview from "./StepReview";
import StepSubmitting from "./StepSubmitting";
import StepComplete from "./StepComplete";

export const SellFormSteps = {
  invalid: "invalid",
  loading: "loading",
  edit: "edit",
  review: "review",
  submitting: "submitting",
  complete: "complete",
};

const defaultFormData = {
  gradedCardParallelId: null,
  cardParallelId: null,
  gradeId: null,
  slug: null,
  cardName: null,
  openBuyOrders: [],
  quantityAvailable: null,
  quantityToSell: 1,
  expiresAfter: "NO_EXPIRY",
  minimumSalePrice: null,
  closeSellOrders: false,
  expectedSalesCount: null,
  expectedSalesValue: null,
  expectedListingsCount: null,
  expectedListingsValue: null,
  expectedTransactionFees: null,
  completedSalesCount: null,
  completedListingsCount: null,
  completedErrors: null,
};

const Header = ({ title, cardName, showClose }) => {
  return (
    <Modal.Header className="border-bottom pb-0" closeButton={showClose}>
      <div className={classNames("container-fluid", { "mr-n4": showClose })}>
        <div className="text-center">
          <h3 className="font-weight-bold">{title}</h3>
          <p>{cardName}</p>
        </div>
      </div>
    </Modal.Header>
  );
};

const SellForm = ({
  show,
  setShow,
  initialStep = SellFormSteps.loading,
  gradedCardParallelId,
  cardParallelId,
  cardParallelProductType,
  gradeId,
  slug,
  cardName,
  openBuyOrders = [],
  quantityAvailable,
  quantityToSell = 1,
  expiresAfter = "NO_EXPIRY",
  minimumSalePrice,
  closeSellOrders = false,
  expectedSalesCount,
  expectedSalesValue,
  expectedListingsCount,
  expectedListingsValue,
  expectedTransactionFees,
}) => {
  // Merges given form field values into the existing form data
  const setFormDataFields = (updatedFields) =>
    setFormData((current) => ({ ...current, ...updatedFields }));

  const closeAndResetForm = () => {
    setStep(SellFormSteps.edit);
    setFormData(initialFormData);
    setShow(false);
  };

  const initialFormData = {
    ...defaultFormData,
    gradedCardParallelId: gradedCardParallelId,
    cardParallelId: cardParallelId,
    cardParallelProductType: cardParallelProductType,
    gradeId: gradeId,
    slug: slug,
    cardName: cardName,
    openBuyOrders: openBuyOrders,
    quantityAvailable: quantityAvailable,
    quantityToSell: quantityToSell,
    expiresAfter: expiresAfter,
    minimumSalePrice: minimumSalePrice,
    closeSellOrders: closeSellOrders,
    expectedSalesCount: expectedSalesCount,
    expectedSalesValue: expectedSalesValue,
    expectedListingsCount: expectedListingsCount,
    expectedListingsValue: expectedListingsValue,
    expectedTransactionFees: expectedTransactionFees,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [step, setStep] = useState(initialStep);

  // Validate we have enough data to display current step or step back as needed
  useEffect(() => {
    switch (step) {
      case SellFormSteps.complete:
        if (
          !formData.cardName ||
          (formData.completedSalesCount === null &&
            formData.completedListingsCount === null &&
            formData.completedErrors === null)
        ) {
          setStep(SellFormSteps.submitting);
        }
        break;
      case SellFormSteps.submitting:
      case SellFormSteps.review:
        if (
          !formData.cardParallelId ||
          !formData.gradeId ||
          !formData.cardName ||
          !formData.quantityToSell ||
          !formData.expiresAfter ||
          !formData.minimumSalePrice ||
          (formData.expectedSalesCount === null &&
            formData.expectedSalesValue === null &&
            formData.expectedListingsCount === null &&
            formData.expectedListingsValue === null)
        ) {
          setStep(SellFormSteps.edit);
        }
        break;
      case SellFormSteps.edit:
        if (
          !formData.cardParallelId ||
          !formData.gradeId ||
          !formData.cardName ||
          !formData.openBuyOrders
        ) {
          setStep(SellFormSteps.loading);
        }
        break;
      case SellFormSteps.loading:
        if (!formData.gradedCardParallelId) {
          setStep(SellFormSteps.invalid);
        }
        break;
      case SellFormSteps.invalid:
      default:
    }
  }, [step, setStep, formData]);

  // Calculate expected sales summary from user inputs
  useEffect(() => {
    let fulfillList = [];
    if (formData.minimumSalePrice !== "") {
      fulfillList = _.take(
        formData.openBuyOrders,
        formData.quantityToSell,
      ).filter((buyOrder) => buyOrder.buyPrice >= formData.minimumSalePrice);
    }

    const expectedListingsCount = formData.minimumSalePrice
      ? formData.quantityToSell - fulfillList.length
      : null;

    const expectedSalesValue = _.reduce(
      fulfillList,
      (sum, buyOrder) => sum + buyOrder.buyPrice,
      0,
    );
    const expectedListingsValue =
      expectedListingsCount * formData.minimumSalePrice;

    const expectedTotalValue = expectedSalesValue + expectedListingsValue;

    // Commission is computed on a per-item basis rather than as the whole sale
    const expectedSalesCommission = _.reduce(
      fulfillList,
      (sum, buyOrder) => sum + commissionFromSalePrice(buyOrder.buyPrice),
      0,
    );
    const expectedListingsCommission =
      expectedListingsCount *
      commissionFromSalePrice(formData.minimumSalePrice);

    const expectedTransactionFees =
      expectedSalesCommission + expectedListingsCommission;

    setFormDataFields({
      expectedSalesCount: fulfillList.length,
      expectedSalesValue: expectedSalesValue,
      expectedListingsCount: expectedListingsCount,
      expectedListingsValue: expectedListingsValue,
      expectedTotalValue: expectedTotalValue,
      expectedTransactionFees: expectedTransactionFees,
    });
  }, [
    formData.quantityToSell,
    formData.minimumSalePrice,
    formData.openBuyOrders,
  ]);

  return (
    <Modal
      show={show}
      onHide={() => closeAndResetForm()}
      onExited={() => closeAndResetForm()}
      backdrop={"static"}
      keyboard={step !== SellFormSteps.submitting}
      centered
    >
      <Header
        title={"Sell cards"}
        cardName={formData.cardName}
        showClose={step !== SellFormSteps.submitting}
      />

      {step === SellFormSteps.loading && (
        <StepLoading
          formData={formData}
          setFormDataFields={setFormDataFields}
          onLoaded={() => {
            logAmplitudeEvent(SELLING_EVENTS.NEW_SELL_ORDER.FORM_VIEWED, {
              "Product Type": formData.cardParallelProductType,
              "Product Slug": formData.slug,
              "Product Name": formData.cardName,
              "Items Available": formData.quantityAvailable,
            });
            setStep(SellFormSteps.edit);
          }}
          onFailed={() => setStep(SellFormSteps.invalid)}
        />
      )}
      {step === SellFormSteps.edit && (
        <StepEdit
          formData={formData}
          setFormDataFields={setFormDataFields}
          onNext={() => {
            logAmplitudeEvent(
              SELLING_EVENTS.NEW_SELL_ORDER.FORM_CONFIRM_VIEWED,
              {
                "Product Type": formData.cardParallelProductType,
                "Product Slug": formData.slug,
                "Product Name": formData.cardName,
                "Items Available": formData.quantityAvailable,
                "Sell Order Quantity": formData.quantityToSell,
                "Sell Order Minimum Price": formData.minimumSalePrice,
              },
            );
            setStep(SellFormSteps.review);
          }}
          onCancel={() => closeAndResetForm()}
        />
      )}
      {step === SellFormSteps.review && (
        <StepReview
          formData={formData}
          setFormDataFields={setFormDataFields}
          onSubmit={() => setStep(SellFormSteps.submitting)}
          onPrev={() => setStep(SellFormSteps.edit)}
        />
      )}
      {step === SellFormSteps.submitting && (
        <StepSubmitting
          formData={formData}
          setFormDataFields={setFormDataFields}
          onComplete={(errors) => {
            if (errors && errors.length > 0) {
              logAmplitudeEvent(SELLING_EVENTS.NEW_SELL_ORDER.FAILED);
            } else {
              logAmplitudeEvent(SELLING_EVENTS.NEW_SELL_ORDER.CREATED, {
                "Product Type": formData.cardParallelProductType,
                "Product Slug": formData.slug,
                "Product Name": formData.cardName,
                "Items Available": formData.quantityAvailable,
                "Sell Order Quantity": formData.quantityToSell,
                "Sell Order Minimum Price": formData.minimumSalePrice,
                "Sell Order Expires After": formData.expiresAfter,
                "Items Sold": formData.completedSalesCount,
                "Items Sold Value": formData.expectedSalesValue,
                "Listings Created": formData.completedListingsCount,
                "Listings Created Value": formData.expectedListingsValue,
              });
            }
            setStep(SellFormSteps.complete);
          }}
        />
      )}
      {step === SellFormSteps.complete && (
        <StepComplete formData={formData} onClose={() => closeAndResetForm()} />
      )}
    </Modal>
  );
};

export default SellForm;
