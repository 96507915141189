import { gql } from "@apollo/client";

const GET_MY_ACTIVITY_METADATA = gql`
  query GET_MY_ACTIVITY_METADATA {
    myGroupedBids(first: 0) {
      totalCount
    }
    myGroupedListings(first: 0) {
      totalCount
    }
  }
`;

export default GET_MY_ACTIVITY_METADATA;
