import { useQuery } from "@apollo/client";

import GET_BOX_BREAKS from "../../../../api/queries/breaks/GET_BOX_BREAKS";
import { BOX_BREAK_STATE_GROUPS } from "../../../../api/types";

import BreakCarouselWidget from "./BreakCarouselWidget";

const widgetName = "UpcomingBreaksList";

const UpcomingBreaksCarouselWidget = ({ sport, era }) => {
  return (
    <BreakCarouselWidget
      id={"upcoming-breaks-widget"}
      widgetName={widgetName}
      title="Upcoming events"
      subtitle="Join our upcoming NFT reMints and breaks"
      linkTitle="View our events"
      linkUrl={"/breaks"}
      themeColor={"#9948C3"}
      query={useQuery(GET_BOX_BREAKS, {
        variables: {
          sport: sport,
          era: era,
          stateGroup: [
            BOX_BREAK_STATE_GROUPS.LIVE,
            BOX_BREAK_STATE_GROUPS.UPCOMING,
          ],
          orderBy: "featured_rank_desc,scheduled_start_time_asc,id",
          first: 12,
        },
      })}
    />
  );
};

export default UpcomingBreaksCarouselWidget;
