import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";

import { BackgroundImageWrapper } from "../../../utils/wrappers";
import logo from "../../../images/logo.svg";
import Loader from "../../../components/Loader";

import WITHDRAWAL_CONFIRMATION from "../api/WITHDRAWAL_CONFIRMATION";
import { WithdrawalConfirmationStatus } from "../components/WithdrawalConfirmationStatus";

export const WithdrawalConfirmation = () => {
  const { uidb64, withdrawalId, token } = useParams();

  const [withdrawalConfirmation, { loading, data }] = useMutation(
    WITHDRAWAL_CONFIRMATION,
  );

  useEffect(() => {
    withdrawalConfirmation({
      variables: {
        token: token,
        userIdB64: uidb64,
        withdrawalRequestId: withdrawalId,
      },
    });
  }, [token, uidb64, withdrawalId, withdrawalConfirmation]);

  if (loading) return <Loader />;

  const errors = data?.withdrawalConfirmation?.errors;
  const errorMessage = errors && (errors[0]?.message || errors[0]?.field);

  return (
    <>
      <Helmet>
        <title>Withdrawal Request Confirmation</title>
      </Helmet>

      <div className="container-fluid">
        <BackgroundImageWrapper />

        <div className="row">
          <div className="col">
            <div className="text-center">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid my-5" />
              </Link>
            </div>

            <div className="card mx-auto max-width-26 mb-5">
              <div className="card-body text-center">
                <h1 className="card-title h5 my-3">
                  Withdrawal Request Confirmation
                </h1>
                <WithdrawalConfirmationStatus
                  errorMessage={errorMessage}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
