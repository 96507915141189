import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useQueryState } from "react-router-use-location-state";
import { useDebounce } from "use-debounce";

import { logAmplitudeEvent } from "../../utils/analytics";
import { ACTIVITY_EVENTS } from "../../utils/analytics/events";
import { pluralize } from "../../utils/content";

import GET_MY_SALES from "../../api/queries/GET_MY_SALES";

import EmptySection from "./EmptySection";
import SoldListing from "./SoldListing";
import ErrorNotice from "../ErrorNotice";
import Loader from "../Loader";
import LoadMoreButton from "../LoadMoreButton";
import SearchInput from "./SearchInput";

const SoldSection = () => {
  useEffect(() => logAmplitudeEvent(ACTIVITY_EVENTS.SALES.PAGE_VIEWED), []);

  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const { data, loading, error, fetchMore } = useQuery(GET_MY_SALES, {
    variables: { searchQuery: debouncedSearchQuery },
  });

  const [loadingMore, setLoadingMore] = useState(false);

  return (
    <div className="mt-4 mb-3">
      <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

      {error && <ErrorNotice error={error} />}
      {loading && <Loader />}

      {!error && !loading && (
        <>
          <div className="d-flex flex-row justify-content-between mt-4 mb-3">
            <span className="font-weight-bold">
              {pluralize("sale", data.mySales.totalCount)}
            </span>
            <span>Sorted by most recent</span>
          </div>

          {data.mySales.edges.length ? (
            <div className="row">
              {data.mySales.edges.map(({ node }) => (
                <div className="col-12 mb-3" key={node.id}>
                  <SoldListing saleData={node} />
                </div>
              ))}
            </div>
          ) : (
            <EmptySection />
          )}

          {data.mySales.pageInfo.hasNextPage && (
            <LoadMoreButton
              loadingMore={loadingMore}
              setLoadingMore={setLoadingMore}
              onLoadMore={() =>
                fetchMore({
                  variables: {
                    cursor: data.mySales.pageInfo.endCursor,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    setLoadingMore(false);
                    if (!fetchMoreResult) return prev;

                    fetchMoreResult.mySales.edges = [
                      ...prev.mySales.edges,
                      ...fetchMoreResult.mySales.edges,
                    ];
                    return fetchMoreResult;
                  },
                })
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default SoldSection;
