import { useEffect } from "react";

import styled, { css } from "styled-components";

import { logAmplitudeEvent } from "../utils/analytics";
import { MARKETPLACE_EVENTS } from "../utils/analytics/events";

import BarChartGhostLight from "../images/backgrounds/vector/bar-chart-ghost-light.svg";
import BarChartGhostDark from "../images/backgrounds/vector/bar-chart-ghost-dark.svg";

import NBAEmerging30Widget from "../components/Marketplace/Widgets/NBAEmerging30Widget";
import MLBEmerging20Widget from "../components/Marketplace/Widgets/MLBEmerging20Widget";
import NFLEmerging30Widget from "../components/Marketplace/Widgets/NFLEmerging30Widget";
import { WarningAlert } from "../components/WarningAlert";

const StyledWidgetWrapper = styled.div`
  background: ${(props) => props.background || "#ffffff"};

  margin-bottom: 1rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.05);
`;

const WidgetWrapper = ({ children, background }) => {
  return (
    <StyledWidgetWrapper className="row" background={background}>
      <div className="col inner-container-wide mx-auto">
        <div className="row">{children}</div>
      </div>
    </StyledWidgetWrapper>
  );
};

const MarketIndexes = () => {
  useEffect(
    () => logAmplitudeEvent(MARKETPLACE_EVENTS.MARKET_INDEXES.PAGE_VIEWED),
    [],
  );

  return (
    <>
      <WidgetWrapper>
        <div className="mx-3 my-4">
          <h1 className="font-weight-bold mt-4 mb-3">📈 2021 market indexes</h1>
          <p>
            From late 2020 through 2021 we ran three market indexes, tracking
            card sales for the hottest up-and-coming &ldquo;emerging&rdquo;
            talent in basketball, football and baseball. There were highs, and
            there were certainly lows in an eventful year for sports cards!
            Aspects of these market indexes worked really well, but in other
            ways they didnʼt provide the market overview we hoped that they
            would. For the time being we have decided to give them a timeout.
          </p>
          <p>
            They are presented below in archive form, so you can review how they
            worked, which players rose above, and who struggled for form through
            the year.
          </p>
          <WarningAlert status="Info">
            Sad to see them go? Send your feedback to{" "}
            <a href="mailto:feedback@starstock.com">feedback@starstock.com</a>{" "}
            to share what you liked about them or what didnʼt work so well!
          </WarningAlert>
        </div>
      </WidgetWrapper>

      <WidgetWrapper
        background={css`
            url(\"${BarChartGhostLight}\") right bottom repeat-x,
            linear-gradient(180deg, #001E50d0, #001E5040),
            #581b51;
          `}
      >
        <NBAEmerging30Widget />
      </WidgetWrapper>

      <WidgetWrapper
        background={css`
            url(\"${BarChartGhostDark}\") center bottom repeat-x,
            linear-gradient(180deg, #06283Ee0, #06283E40),
            #0DB394;`}
      >
        <NFLEmerging30Widget />
      </WidgetWrapper>

      <WidgetWrapper
        background={css`
            url(\"${BarChartGhostDark}\") left bottom repeat-x,
            linear-gradient(180deg, #080a44d0, #080a4440),
            #04bfbc;`}
      >
        <MLBEmerging20Widget />
      </WidgetWrapper>
    </>
  );
};

export default MarketIndexes;
