import classNames from "classnames";

import CardParallelNameBadge from "./CardParallelNameBadge";

const CardParallelBadges = ({
  name,
  badgeTextColor,
  badgeBorderColor,
  badgeGradientStart,
  badgeGradientEnd,
  badgeGradientAngle,
  serialLimit = null,
}) => {
  return (
    <div
      className={classNames(
        "d-flex flex-shrink-1 flex-row flex-wrap badge-row mt-n2",
      )}
    >
      <div className="badge-row-item mt-2">
        <CardParallelNameBadge
          name={name}
          textColor={badgeTextColor}
          borderColor={badgeBorderColor}
          gradientStart={badgeGradientStart}
          gradientEnd={badgeGradientEnd}
          gradientAngle={badgeGradientAngle}
        />
      </div>

      {serialLimit && (
        <div className="badge-row-item mt-2">
          <div className="badge badge-light inner-border">
            <span
              className="text-uppercase text-body"
              style={{ fontSize: "0.75rem" }}
            >
              1 / {serialLimit}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardParallelBadges;
