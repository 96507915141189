import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { logAmplitudeEvent } from "../utils/analytics";
import { MARKETPLACE_EVENTS } from "../utils/analytics/events";

import GET_MARKET_INDEX from "../api/queries/GET_MARKET_INDEX";

import { WarningAlert } from "../components/WarningAlert";
import ErrorNotice from "../components/ErrorNotice";
import Loader from "../components/Loader";
import {
  PageSection,
  PageSectionTypes,
  PageSectionWidths,
} from "../components/PageSection";
import IndexSummary from "../components/MarketIndex/IndexSummary";
import ComponentSnapshotsTable from "../components/MarketIndex/ComponentSnapshotsTable";

const HeaderContainer = styled.div`
  background: linear-gradient(180deg, #001e50ff, #001e5000);
  background-color: #391234;
`;

const MarketIndex = () => {
  const { slug } = useParams();

  useEffect(
    () =>
      logAmplitudeEvent(MARKETPLACE_EVENTS.MARKET_INDEX.PAGE_VIEWED, {
        Slug: slug,
      }),
    [slug],
  );

  const { data, loading, error } = useQuery(GET_MARKET_INDEX, {
    variables: { slug },
  });

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;

  return (
    <>
      {data && (
        <>
          <Helmet>
            <title>{data.marketIndex?.name}</title>
          </Helmet>

          <HeaderContainer className="row text-left py-4">
            <div className="col inner-container mx-auto">
              <p className="small text-white-50 font-weight-bold text-uppercase">
                <a href="/marketplace">Marketplace</a> /{" "}
                {data.marketIndex.symbol}
              </p>
              <h2 className="font-weight-bold text-white mb-2">
                {data.marketIndex.name}
              </h2>
              <p className="text-white mb-2" style={{ opacity: "0.6" }}>
                {data.marketIndex.description}
              </p>
            </div>
          </HeaderContainer>

          <PageSection
            width={PageSectionWidths.regular}
            type={PageSectionTypes.foreground}
          >
            <WarningAlert status="Warning">
              This index is now archived and no longer updated.
            </WarningAlert>
          </PageSection>

          <PageSection
            width={PageSectionWidths.regular}
            type={PageSectionTypes.foreground}
            addMarginBottom={true}
          >
            <IndexSummary marketIndex={data.marketIndex} />
          </PageSection>

          <PageSection
            width={PageSectionWidths.regular}
            type={PageSectionTypes.foreground}
            addMarginBottom={true}
          >
            <div className="row">
              <div className="w-100 position-relative">
                <ComponentSnapshotsTable marketIndex={data.marketIndex} />
              </div>
            </div>
          </PageSection>
        </>
      )}
    </>
  );
};

export default MarketIndex;
