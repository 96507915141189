export const checkCardIsExpired = (
  expiryMonth: string,
  expiryYear: string,
): boolean => {
  const today = new Date();
  const currentMonth = today.getMonth().toString();
  const currentYear = today.getFullYear().toString();

  return (
    expiryYear < currentYear ||
    (expiryYear === currentYear && expiryMonth < currentMonth)
  );
};
