import Widget from "../../../Marketplace/Widgets/Widget";
import BreakCarouselContainer from "../../BreakCarouselContainer";

const BreakCarouselWidget = ({
  id,
  widgetName,
  title,
  subtitle,
  linkTitle,
  linkUrl,
  themeColor,
  query,
}) => {
  const { data, loading, error } = query;

  return (
    <Widget
      id={id}
      widgetName={widgetName}
      title={title}
      subtitle={subtitle}
      linkTitle={linkTitle}
      linkUrl={linkUrl}
      themeColor={themeColor}
    >
      <BreakCarouselContainer data={data} loading={loading} error={error} />
    </Widget>
  );
};

export default BreakCarouselWidget;
