import { useEffect, useState } from "react";
import { getContentful } from "../../utils/contentful";

const JOB_QUERY = (slug) => `
query {
  jobPostCollection(where: { slug: "${slug}" }, order: sortOrder_ASC) {
    items {
      title
      slug
      department
      intro
      body
    }
  }
}
`;

const Job = ({ slug, children }) => {
  const [job, setJob] = useState(null);

  useEffect(() => {
    getContentful(JOB_QUERY(slug)).then(({ data }) => {
      setJob(data.jobPostCollection.items[0]);
    });
  }, [slug]);

  if (!job) {
    return <></>;
  }

  return <>{children(job)}</>;
};

export default Job;
