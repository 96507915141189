import { useMutation } from "@apollo/client";
import type { FetchResult } from "@apollo/client";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";

import { StripePaymentMethodNode } from "../../../../generated/schema";
import GET_MY_PAYMENT_METHODS from "../../api/GET_MY_PAYMENT_METHODS";
import { SET_DEFAULT_PAYMENT_METHOD } from "../../api/SET_DEFAULT_PAYMENT_METHOD";

import { PaymentMethodInfo } from "../PaymentMethodInfo";
import { PaymentMethodSelect } from "../PaymentMethodSelect";
import {
  TitleContainer,
  StyledModalTitle,
  ActionsContainer,
  ModalContainer,
  ModalBody,
  PaymentMethodsContainer,
  DeleteButton,
  DefaultPaymentSelectContainer,
  PaymentMethodList,
} from "./styles";

interface Props {
  show: boolean;
  showManagePaymentMethod?: boolean;
  paymentMethods: (StripePaymentMethodNode | null)[] | null;
  handleClose?: () => void;
  onDelete: (paymentMethodId: string) => Promise<FetchResult<any>>;
}

export const EditPaymentMethodsModal = ({
  show,
  handleClose,
  paymentMethods,
  onDelete,
  showManagePaymentMethod,
}: Props) => {
  const [selectedWallet, setSelectedWallet] = useState("");
  const [setDefaultPaymentMethod] = useMutation(SET_DEFAULT_PAYMENT_METHOD, {
    refetchQueries: [{ query: GET_MY_PAYMENT_METHODS }],
  });

  const onSetDefaultPaymentMethod = (value: any) => {
    const paymentMethodId = value.paymentMethod;

    setDefaultPaymentMethod({
      variables: { paymentMethodId },
    });
  };

  useEffect(() => {
    const selectedWallet = paymentMethods?.find(
      (method) => method?.defaultPaymentMethod,
    );

    if (selectedWallet) {
      setSelectedWallet(selectedWallet.paymentMethodId);
    }
  }, [paymentMethods]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <TitleContainer>
          <StyledModalTitle>Edit payment methods</StyledModalTitle>
        </TitleContainer>
      </ModalHeader>

      <ModalBody>
        {showManagePaymentMethod && (
          <DefaultPaymentSelectContainer>
            <PaymentMethodSelect
              selectedMethod={selectedWallet}
              handleChange={(e) => {
                onSetDefaultPaymentMethod({ paymentMethod: e.target.value });
                setSelectedWallet(e.target.value as string);
              }}
              hideWallet={true}
              inputLabel="Default Payment Method"
              hideExpired
            />
          </DefaultPaymentSelectContainer>
        )}

        {paymentMethods && (
          <PaymentMethodList>
            {paymentMethods.map((paymentMethod) => {
              if (!paymentMethod) return null;

              return (
                <ModalContainer key={paymentMethod.paymentMethodId}>
                  <PaymentMethodsContainer>
                    <PaymentMethodInfo
                      paymentDetails={paymentMethod}
                      showDefault={false}
                    />
                  </PaymentMethodsContainer>
                  <ActionsContainer>
                    <DeleteButton
                      onClick={() => onDelete(paymentMethod.paymentMethodId)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} /> Delete
                    </DeleteButton>
                  </ActionsContainer>
                </ModalContainer>
              );
            })}
          </PaymentMethodList>
        )}
      </ModalBody>
    </Modal>
  );
};
