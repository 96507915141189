import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Children, ReactNode, useState } from "react";
import { InfoNotice } from "../../../../components/InfoNotice";

import Loader from "../../../../components/Loader";
import MarketCardParallel from "../../../../components/MarketCardParallel";
import { useFetchAccountData } from "../../../../hooks/useFetchAccountData";

import { useFetchLot } from "../../hooks/useFetchLot";
import { BidHistory } from "../BidHistory";
import { LotDetails } from "../LotDetails";
import {
  CloseButton,
  Container,
  Contents,
  Heading,
  Section,
  Title,
  ToggleButton,
} from "./styles";

interface Props {
  close(): void;
  id: string;
}

const Wrapper = ({
  children,
  close,
}: {
  children: ReactNode;
  close(): void;
}) => (
  <Container>
    <Section>
      <Title>Lot Details</Title>
      <CloseButton aria-label="Close" onClick={close}>
        <svg viewBox="0 0 10 10" aria-hidden="true" focusable="false">
          <rect height="8" width="2" y="1" x="4" />
          <rect height="2" width="8" y="4" x="1" />
        </svg>
      </CloseButton>
    </Section>

    {Children.map(children, (child) =>
      child ? <Section>{child}</Section> : null,
    )}
  </Container>
);

export const LotSummary = ({ close, id }: Props) => {
  const { accountData } = useFetchAccountData();
  const { data, error, loading } = useFetchLot(id);
  const [showBidHistory, setShowBidHistory] = useState(false);
  const toggleShowBidHistory = () => setShowBidHistory(!showBidHistory);

  if (loading)
    return (
      <Wrapper close={close}>
        <Loader />
      </Wrapper>
    );

  if (error || !data?.lot)
    return (
      <Wrapper close={close}>
        <InfoNotice
          icon={faExclamationTriangle}
          message={["Unable to load lot details. Please try again."]}
          state="negative"
        />
      </Wrapper>
    );

  const {
    lot: {
      bidSet: { edges: bids },
      highestBid,
      description,
      lotitemSet: { edges: lotItems },
      lotphotoSet: { edges: lotPhotos },
      name,
      openingPrice,
      state,
    },
  } = data;

  const closingPrice = highestBid?.bidPrice;
  const imageUrl = lotPhotos[0]?.node?.photoMd;
  const items = lotItems.map((item) => item?.node);
  const hasBids = !!bids.length;
  const hasItems = !!items.length;

  return (
    <Wrapper close={close}>
      <LotDetails
        closingPrice={closingPrice}
        description={description}
        imageUrl={imageUrl}
        name={name}
        openingPrice={openingPrice}
        state={state}
      />

      {hasItems && (
        <>
          <Heading>Items included in this lot</Heading>

          <Contents>
            {items.map((item) =>
              item ? (
                <MarketCardParallel
                  key={item.gradedCardParallel.id}
                  badgeCount={null}
                  cardParallel={item.gradedCardParallel.cardParallel}
                  gradedCardParallels={[item.gradedCardParallel]}
                  hiddenGradesCount={0}
                  removeFromWatchlist={null}
                  watchlistId={null}
                />
              ) : null,
            )}
          </Contents>
        </>
      )}

      {hasBids && (
        <>
          <Heading>
            <ToggleButton
              aria-expanded={showBidHistory}
              onClick={toggleShowBidHistory}
            >
              Bid History
              <svg viewBox="0 0 10 10" aria-hidden="true" focusable="false">
                <rect className="vert" height="8" width="2" y="1" x="4" />
                <rect height="2" width="8" y="4" x="1" />
              </svg>
            </ToggleButton>
          </Heading>

          <BidHistory
            bids={bids}
            currentUser={accountData?.username}
            hidden={!showBidHistory}
            placeholder={false}
            state={state}
          />
        </>
      )}
    </Wrapper>
  );
};
