import { gql } from "@apollo/client";

const GET_UPCOMING_FIXTURES = gql`
  query GET_UPCOMING_FIXTURES($sport: String) {
    upcomingFixtures(first: 12, sport: $sport) {
      edges {
        node {
          id
          startDate
          homeTeam {
            id
            abbrName
          }
          awayTeam {
            id
            abbrName
          }
          fixtureteamplayerSet(first: 4) {
            edges {
              node {
                id
                teamPlayer {
                  id
                  player {
                    id
                    name
                    slug
                    position
                    headshotPhoto: headshotPhotoXs
                    startYear
                    currentTeam {
                      id
                      name
                      abbrName
                      colorPrimary
                      colorSecondary
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_UPCOMING_FIXTURES;
