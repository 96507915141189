import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import GET_MY_WISHLIST_METADATA from "../api/queries/GET_MY_WISHLIST_METADATA";

import ErrorNotice from "../components/ErrorNotice";
import Loader from "../components/Loader";
import CardSection from "../components/WatchList/CardSection";
import WishlistNavBar from "../components/WatchList/NavBar";
import PlayerSection from "../components/WatchList/PlayerSection";

const WatchList = () => {
  const { players } = useParams();
  const showWatchlistItems = players !== "players";

  const { data, loading, error } = useQuery(GET_MY_WISHLIST_METADATA);

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;

  const { myWishlistCards, myWishlistPlayers } = data;

  return (
    <>
      <Helmet>
        <title>Watchlist</title>
      </Helmet>

      <div className="row bg-white shadow border-bottom text-center">
        <div className="col inner-container mx-auto">
          <div className="d-flex flex-column justify-content-center align-items-start pt-4 pt-md-5">
            <h2 className="font-weight-bold mr-2 mb-3 mb-md-4">Watchlist</h2>

            <WishlistNavBar
              showWatchlistItems={showWatchlistItems}
              wishlistItemCount={myWishlistCards.totalCount}
              wishlistPlayerCount={myWishlistPlayers.totalCount}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col inner-container mx-auto">
          {showWatchlistItems && <CardSection />}
          {!showWatchlistItems && <PlayerSection />}
        </div>
      </div>
    </>
  );
};

export default WatchList;
