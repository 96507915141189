import styled from "styled-components";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

import { Colors } from "../../../../themes/Default";

export const Container = styled.div<{ paymentMethodSelect: boolean }>`
  align-items: center;
  border-bottom: ${({ paymentMethodSelect }) =>
    paymentMethodSelect ? "none" : "1px solid #dcddde;"};
  display: ${({ paymentMethodSelect }) =>
    paymentMethodSelect ? "flex" : "grid"};
  grid-template-columns: 1fr 16fr 3fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;

  &:last-child {
    border-bottom: none;
    margin-bottom: -1rem;
  }

  &:first-child {
    margin-top: 0.5rem;
  }

  @media (min-width: 28rem) {
    grid-template-rows: 1fr;

    &:first-child {
      margin-top: 0.125rem;
    }

    &:last-child {
      margin-bottom: -0.25rem;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: 2 / 1 / last-line / last-line;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  margin-left: 1rem;

  @media (min-width: 28rem) {
    grid-area: auto;
    margin-bottom: 0.75rem;
    margin-left: 0.75rem;
    margin-top: 0.125rem;
  }
`;

export const CardDetails = styled.p<{ cardExpired: boolean }>`
  color: ${({ cardExpired }) => (cardExpired ? "#747373" : Colors.Astronaut)};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin-bottom: 0;
  text-align: left;

  @media (min-width: 28rem) {
    margin-top: 0.75rem;
  }

  ::first-letter {
    text-transform: capitalize;
  }
`;

export const CardExpiry = styled.p<{ cardExpired: boolean }>`
  color: ${({ cardExpired }) => (cardExpired ? "#CC1D1D" : Colors.Astronaut)};
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0;
  text-align: left;
`;

export const CardInfo = styled.div<{ cardExpired: boolean }>`
  align-items: center;
  background-color: ${({ cardExpired }) =>
    cardExpired ? "#FFE1E1" : "#d0e0f3"};
  border-radius: 0.75rem;
  display: flex;
  grid-area: 1 / 3 / 1;
  justify-content: center;
  margin-right: 1rem;
  margin-top: 1rem;
  padding: 0.25rem 0.75rem;

  @media (min-width: 28rem) {
    margin-top: -1.125rem;
  }
`;

export const Text = styled.span<{ cardExpired: boolean }>`
  color: ${({ cardExpired }) => (cardExpired ? "#AE3434" : Colors.Astronaut)};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
  text-align: center;
`;

export const Icon = styled(FontAwesomeIcon)<
  FontAwesomeIconProps & { $cardExpired: boolean }
>`
  border-radius: 0.25rem;
  color: ${({ $cardExpired }) =>
    $cardExpired ? "#747373" : "rgb(44 77 118 / 60%)"};
  height: 2rem;
  opacity: ${({ $cardExpired }) => ($cardExpired ? 0.6 : 1)};
  padding: 0 0.25rem 0.25rem 0;
  width: 2rem;

  @media (min-width: 28rem) {
    padding: 0.25rem;
  }
`;

export const CardIconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 1.25rem;

  @media (min-width: 28rem) {
    margin-left: 1rem;
    margin-top: 0;
  }
`;
