import { Label, Panel, Row, Value } from "./styles";

interface Props {
  rows: { label: string; value: string }[];
}

export const InfoPanel = ({ rows }: Props) => (
  <Panel>
    {rows.map((row, index) => (
      <Row key={index}>
        <Label>{row.label}</Label>
        <Value>{row.value}</Value>
      </Row>
    ))}
  </Panel>
);
