import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import { pickTopAvailableGrade } from "../../utils/grades";
import { displayNameForProductType } from "../../utils/content";

import { PRODUCT_TYPES } from "../../api/types";

import SelectCardGrade from "./SelectCardGrade";
import CardAvailabilitySectionBody from "./CardAvailabilitySectionBody";

// Set of empty list attributes, which we use to assign a placeholder
// gradedCardParallel to grades which don't have one provided from the server
const gradedCardParallelDefaults = {
  openBuyOrders: [],
  openSellOrders: [],
  myItems: [],
  myGroupedBids: [],
  myGroupedListings: [],
};

const CardGradeInfoPopover = (
  <Popover id="grade-info-popover">
    <Popover.Content>
      <p>Card grades reflect the quality and condition of the card.</p>
      <p>
        Graded cards have been reviewed by a grading service (PSA, BGS or SGC),
        with higher numbers awarded to better graded cards.
      </p>
      <p className="mb-0">
        Ungraded cards have not been reviewed by a grading service. StarStock
        allocates these cards an A, B or C rank to give an indication on their
        condition.
      </p>
    </Popover.Content>
  </Popover>
);

const BoxGradeInfoPopover = (
  <Popover id="grade-info-popover">
    <Popover.Content>
      <p>
        All boxes available for trading on StarStock are factory-sealed. Boxes
        submitted to StarStock which are in any way damaged or tampered with are
        rejected outright, and do not make it onto the platform for trading.
      </p>
      <p className="mb-0">
        As packs are sealed, no guarantee can be made regarding the cards
        within.
      </p>
    </Popover.Content>
  </Popover>
);

const CardAvailabilitySection = ({
  slug,
  cardParallelId,
  cardParallelProductType,
  cardParallelPhoto,
  parallelName,
  serialLimit,
  playerCard,
  grades,
  gradedCardParallels,
  selectedGradeId,
  setSelectedGradeId,
}) => {
  // Associate the GradedCardParallel data with the parent grade
  const annotatedGrades = grades.map((grade) =>
    Object.assign({}, grade, {
      gradedCardParallel:
        gradedCardParallels.find((gcp) => gcp.grade.id === grade.id) ||
        gradedCardParallelDefaults,
    }),
  );

  // Pre-select the best grade available if one wasn't passed in
  const selectedGrade = selectedGradeId
    ? annotatedGrades.find((grade) => grade.id === selectedGradeId) ||
      annotatedGrades[0]
    : pickTopAvailableGrade(annotatedGrades);

  if (selectedGradeId !== selectedGrade.id) {
    setSelectedGradeId(selectedGrade.id);
  }

  return (
    <>
      <div className="d-flex flex-row justify-content-start align-items-center mb-4">
        <h3 className="font-weight-bold mb-0">
          Select {displayNameForProductType(cardParallelProductType)} grade
        </h3>

        <OverlayTrigger
          trigger={["hover", "focus"]}
          overlay={
            cardParallelProductType === PRODUCT_TYPES.BOX
              ? BoxGradeInfoPopover
              : CardGradeInfoPopover
          }
          placement={"auto"}
          flip
        >
          <button className="btn p-0 ml-2">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="text-deselected fa-lg"
            />
          </button>
        </OverlayTrigger>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="mb-4 mb-lg-0">
            <SelectCardGrade
              grades={annotatedGrades}
              selectedGradeId={selectedGradeId}
              setSelectedGradeId={setSelectedGradeId}
              productType={cardParallelProductType}
            />
          </div>
        </div>

        <div className="col-lg-7">
          <CardAvailabilitySectionBody
            slug={slug}
            cardParallelId={cardParallelId}
            cardParallelProductType={cardParallelProductType}
            cardParallelImage={cardParallelPhoto}
            parallelName={parallelName}
            serialLimit={serialLimit}
            playerCard={playerCard}
            grade={selectedGrade}
            gradedCardParallelId={selectedGrade.gradedCardParallel.id}
          />
        </div>
      </div>
    </>
  );
};

export default CardAvailabilitySection;
