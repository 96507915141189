import { gql } from "@apollo/client";

const GET_MARKET_INDEX_LATEST_SNAPSHOT = gql`
  query GET_MARKET_INDEX($slug: String!) {
    marketIndex(slug: $slug) {
      id
      slug
      symbol
      name
      description
      latestSnapshot {
        id
        date
        name
        value
        valueChange
        valueChangePercentage
        normalisedValueMultiplier
        normalisedValue
        normalisedValueChange
        normalisedValueChangePercentage
        componentSnapshots {
          edges {
            node {
              price
              priceChange
              priceChangePercentage
              marketIndexComponent {
                id
                subcategory
                gradedCardParallel {
                  grade {
                    id
                  }
                  cardParallel {
                    slug
                  }
                }
                player {
                  name
                  position
                  headshotPhoto: headshotPhotoXs
                  startYear
                  currentTeam {
                    name
                    abbrName
                    colorPrimary
                    colorSecondary
                    colorTertiary
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_MARKET_INDEX_LATEST_SNAPSHOT;
