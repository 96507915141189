import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { getUpdatedExpiryDate } from "../../utils/forms";
import { logAmplitudeEvent } from "../../utils/analytics";
import { BUYING_EVENTS, SELLING_EVENTS } from "../../utils/analytics/events";

const StepReview = ({ formData, onSubmit, onPrev }) => {
  return (
    <div className="p-3">
      <div className="alert alert-primary text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">Review</h3>
        <p className="mb-0">
          You are changing the expiry date from
          <span className="font-weight-bold">
            {" "}
            {moment(formData.expiresAfter).format("MM/DD/YYYY")}{" "}
          </span>{" "}
          to{" "}
          <span className="font-weight-bold">
            {" "}
            {getUpdatedExpiryDate(formData.extendExpiry)}{" "}
          </span>{" "}
        </p>
      </div>

      <div className="d-flex flex-row justify-content-between align-items-center">
        <button
          className="btn btn-secondary btn-block text-body"
          onClick={() => onPrev()}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" /> Back
        </button>
        <button
          className="btn btn-primary btn-block font-weight-bold mt-0 ml-3"
          onClick={() => {
            if (formData.groupedBidId) {
              logAmplitudeEvent(
                BUYING_EVENTS.EXISTING_OFFER.EXTEND_EXPIRY.FORM_SUBMITTED,
              );
            } else if (formData.groupedListingId) {
              logAmplitudeEvent(
                SELLING_EVENTS.EXISTING_LISTING.EXTEND_EXPIRY.FORM_SUBMITTED,
              );
            }
            onSubmit();
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default StepReview;
