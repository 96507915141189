import { useApolloClient } from "@apollo/client";

import Pages from "./pages";

import { isLoggedInVar } from "./utils/apolloConfig";
import { hasValidRefreshToken } from "./utils/auth";
import { initGTM } from "./utils/gtm";

const App = () => {
  const client = useApolloClient();
  const writeInitialData = async () => {
    isLoggedInVar(hasValidRefreshToken());
  };
  writeInitialData();
  client.onClearStore(writeInitialData);

  initGTM();

  // Init FB pixel and log every pageview (additional events logged elsewhere)

  return <Pages />;
};

export default App;
