import { useQuery } from "@apollo/client";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import GET_MY_ADDRESSES from "../../../../api/queries/GET_MY_ADDRESSES";
import { AddressDropdown } from "../../../../components/Breaks/BuyBreakSpotsForm/AddressDropdown";

import { AddressButton, Label, StyledFAIcon } from "./styles";

interface Props {
  onChange: (value: string) => void;
}

export const SelectAddress = ({ onChange }: Props) => {
  const navigate = useNavigate();
  const [addressId, setAddressId] = useState("");
  const { data, loading } = useQuery(GET_MY_ADDRESSES);
  const addresses = useMemo(
    () => (!loading && data.myAddresses) || [],
    [loading, data],
  );

  useEffect(() => {
    if (addresses.length > 0 && addressId === "") {
      setAddressId(addresses[0].id);
    }
  }, [addresses, addressId, onChange]);

  useEffect(() => {
    if (addressId !== "") onChange(addressId);
  }, [addressId, onChange]);

  const toggleAddressFormModal = (e: MouseEvent) => {
    e.preventDefault();
    navigate("/account/address-book");
  };

  return (
    <>
      <Label>Shipping details:</Label>
      {addresses.length > 0 ? (
        <>
          <AddressDropdown
            value={addressId}
            onChange={(e) => setAddressId(e.target.value)}
            options={addresses}
          />
          <Link
            className="btn btn-secondary btn-block font-weight-bold text-body mb-3"
            to="/account/address-book"
          >
            Manage saved addresses
          </Link>
        </>
      ) : (
        <AddressButton onClick={toggleAddressFormModal} tabIndex={0}>
          <StyledFAIcon icon={faPlus} aria-hidden={true} />
          Add new address
        </AddressButton>
      )}
    </>
  );
};
