import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_ID_FRAGMENT,
  GRADE_FRAGMENT,
  GROUPED_LISTING_FRAGMENT,
  PARALLEL_FRAGMENT,
  PLAYER_CARD_FRAGMENT,
} from "../fragments";

const GET_MY_GROUPED_LISTINGS = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${GROUPED_LISTING_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_FRAGMENT}
  query GET_MY_GROUPED_LISTINGS(
    $cursor: String
    $searchQuery: String
    $orderBy: String
  ) {
    myGroupedListings(
      first: 100
      after: $cursor
      searchQuery: $searchQuery
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...groupedListingFields
          sellorderSet(first: 1) {
            edges {
              node {
                id
                askPrice
                expiresAfter
                item {
                  cardParallel {
                    ...cardParallelIdFields
                    frontSidePhotoSmall
                    parallel {
                      ...parallelFields
                    }
                    playerCard {
                      ...playerCardFields
                    }
                  }
                  grade {
                    ...gradeFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_MY_GROUPED_LISTINGS;
