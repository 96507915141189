import { useEffect } from "react";
import { Helmet } from "react-helmet";

import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { logAmplitudeEvent } from "../utils/analytics";
import { SUPPORT_EVENTS } from "../utils/analytics/events";

const faqSections = [
  {
    id: "sending-in-cards",
    title: "Sending in cards",
    summary: (
      <>
        <p>
          <span className="font-weight-bold">Now accepting all raw modern cards (2000-present).</span>{" "}
          This includes both rookies and non-rookies! We‘ve opened up our vault to thousands of 
          new cards with simplified guidelines for what we accept. Please be advised that 
          every item submitted to our vault will be charged a $1 ingestion fee.
        </p>
        <div className="d-flex flex-row flex-wrap flex-md-nowrap justify-content-between align-items-stretch mb-3 mx-n2">
          <div className="flex-fill mx-2 w-100">
            <div
              className="p-3 h-100 rounded text-center"
              style={{
                background: "linear-gradient(135deg, #E4F6DA, #C9EEC3)",
              }}
            >
              <h5 className="font-weight-bold text-center pb-2 border-bottom mb-3">
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                Accepted
              </h5>
              <p className="mb-2">Any Modern Raw Cards (2000-Present)</p>
			  <p className="mb-1">Any PSA/BGS/SGC/CSG graded sports cards</p>
              <p className="mb-0">Any factory sealed sports wax</p>
            </div>
          </div>
          <div className="flex-fill mx-2 w-100 mt-2 mt-md-0">
            <div
              className="p-3 h-100 rounded text-center"
              style={{
                background: "linear-gradient(135deg, #f6e1da, #eca9a9)",
              }}
            >
              <h5 className="font-weight-bold text-center pb-2 border-bottom mb-3">
                <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                Not accepted
              </h5>
              <p className="mb-0">Non-Graded Vintage Cards (Pre 2000)</p>
            </div>
          </div>
        </div>
      </>
    ),
    questions: [
      {
        question: "How do I submit my cards?",
        answer: (
          <>
            <p>
              Send your cards to the following address:
            </p>
            <address className="text-shout ml-3 mb-3">
              StarStock Inc.
              <br />
              C/O <strong>(Your Username)</strong>
              <br />
              200 Corporate Blvd South #120<br />
              Yonkers, NY 10701<br />
            </address>
            <p className="mb-0">
              Once we receive your package, we will charge you $1 per every item submitted to us prior to inputting your package onto StarStock.
	          Having a funded wallet to cover your ingestion fees will drastically speed up your submission process.
            </p>
          </>
        ),
      },
      {
        question: "What cards are accepted?",
        answer: (
          <>
            StarStock accepts all modern raw (2000-present) cards including rookies and non-rookies, all graded cards from PSA, BGS, SGC, & CSG, and all factory sealed sports wax.
          </>
        ),
      },
      {
        question: "What’s the best way to ship my cards?",
        answer: (
          <>
            <p>Wrap all graded cards tightly in bubble wrap.</p>
            <p className="mb-0">
              If you don’t have a seller account from a shipping company like
              FedEx or UPS, we suggest USPS Priority Flat Rate. You can send
              whatever you can fit in the boxes and still pay the same flat
              rate. Hang on to your receipt for tracking. We highly recommend
              purchasing insurance as we are not responsible for your shipment
              if it’s lost or damaged on the way to us.
            </p>
          </>
        ),
      },
      {
        question: "How long does it take for my cards to be processed?",
        answer:
          "Estimated processing times are 1-2 weeks. You will receive an email notification when your cards are live in your collection.",
      },
      {
        question: "Are there any ingestion fees?",
        answer: "There is a $1 ingestion fee per item submitted to StarStock. This fee will be charged to you once we receive your package. Your package will not be input to your account until this fee is paid.",
      },
      {
        question: "Can I send in graded cards?",
        answer: (
          <>
            <p>You can! We accept graded cards from PSA, BGS, SGC and CSG.</p>
            <p className="mb-0">
              Note that PSA or SGC graded cards with grade qualifiers (e.g. OC),
              or older SGC slabs numbered to 100, are <em>not</em> accepted.
            </p>
          </>
        ),
      },
      {
        question: "Can I send in sealed boxes of cards?",
        answer: (
          <>
            <p>
              We accept all wax as long as it is factory sealed. This includes
              Hobby, Retail, etc... We are not currently accepting single packs,
              or anything that has been opened or tampered with. Boxes must be
              in the original, factory-sealed plastic wrapping to be eligible.
              For hanger packs which are sold at retail unsealed (e.g. Topps
              Hangers without shrink wrap), we only accept them if still sealed
              in the case.
            </p>
            <p>
              For send-ins, all boxes will be checked and authenticated before
              they’re uploaded to your account. We reserve the right to reject
              any box at any time. If we feel a box has been tampered with in
              any way, it will be immediately rejected. Any rejected boxes are
              subject to return shipping charges (postage, handling, tax).
            </p>
            <p>
              Sealed wax submissions qualify for our Fast Pass program, so you
              should currently anticipate a turnaround time of 1 week from when
              we receive your boxes to when they’re uploaded into your account.
            </p>
          </>
        ),
      },
      {
        question: "Can I send my collection to store in the vault?",
        answer:
          "Yes, however, we prefer you only send in cards that you intend to sell either now or at some point in the future. We will be charging a $0.01 per item storage fee every month in the near future to push sellers to use the marketplace and not just store cards with us for free.",
      },
      {
        question: "Does StarStock have a mailbox service?",
        answer: (
          <>
            <p>
              Yes, you can ship us cards directly from breakers, eBay, or any
              other store in the world. Just simply make sure to include your
              username in the address:
            </p>
            <address className="text-shout ml-3">
              StarStock Inc.
              <br />
              C/O <strong>(Your Username)</strong>
              <br />
              200 Corporate Blvd South #120<br />
              Yonkers, NY 10701<br />
            </address>
          </>
        ),
      },
      {
        question: "Can I send in cards internationally?",
        answer: "Yes, we accept send-ins from anywhere in the world!",
      },
      {
        question: "Is there a fee to store cards?",
        answer: "Yes, there is a $0.01 per item per month fee to store cards in our vault.",
      },
      {
        question: "What happens to the the plastic/boxes I send my cards in?",
        answer:
          "If you must send cards in toploaders, cardsavers, one touch, screwdowns, special boxes, etc., these materials will be discarded during ingestion and not returned.",
      },
      {
        question: "Can I request to send my cards home?",
        answer:
          "Yes. Go to the 'Ship cards home' form in your account tab and follow the instructions.",
      },
      {
        question: "What types of cards can be rejected?",
        answer:
          "StarStock may reject cards for a number of reasons. Most notably - fake, cut, altered, hand signed, damaged, cards with multiple players, and off brand (as per what we're accepting above). Please read our send-in instructions to avoid sending us cards that will be rejected.",
      },
      {
        question: "What happens to submitted cards or boxes that are rejected?",
        answer: (
          <>
            <p>
              We will provide you an option via email to discard or ship home
              cards or boxes we do not accept. You are responsible for the
              shipping cost (postage + packaging + labor), which we can manually
              debit from your StarStock account.
            </p>

            <table className="table table-sm border shadow">
              <thead className="bg-primary text-white">
                <tr>
                  <th>Boxes</th>
                  <th className="text-right">Shipping cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Ship home rejected wax domestically</td>
                  <td className="text-right">$15</td>
                </tr>
                <tr>
                  <td>Ship home rejected wax internationally</td>
                  <td className="text-right">$35</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-sm border shadow">
              <thead className="bg-primary text-white">
                <tr>
                  <th>Number of cards</th>
                  <th className="text-right">Shipping cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Up to 20 cards</td>
                  <td className="text-right">$5</td>
                </tr>
                <tr>
                  <td>21 - 99 cards</td>
                  <td className="text-right">$10</td>
                </tr>
                <tr>
                  <td>100 - 399 cards</td>
                  <td className="text-right">$15</td>
                </tr>
                <tr>
                  <td>401+ cards</td>
                  <td className="text-right">$20</td>
                </tr>
              </tbody>
            </table>
            <p>
              * Shipping cost is subject to the weight of the package <br />*
              Add $20 to all international rejected card shipments
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: "shipping-cards-home",
    title: "Shipping cards home",
    questions: [
      {
        question: "What is the process to ship my cards home?",
        answer: (
          <>
            <p>
              You can now{" "}
              <Link to="/ship-home/draft">ship your cards home</Link> by
              clicking the tab on your profile and selecting the specific cards
              from your collection. At the time of your request, the cards are
              removed from your collection and your account is debited for the
              total cost.
            </p>
            <p>
              Upon completing your request, you will receive a copy of the cards
              you requested to have shipped home for receipt if your order is
              for less than 100 cards. If you want over 100 cards shipped home,
              you will not receive an itemized list, but if you have any
              questions regarding your order, feel free to reach out to{" "}
              <a href="mailto:support@starstock.com">support@starstock.com</a>.
            </p>
            <p className="mb-0">
              All shipments come with USPS tracking and $50 worth of insurance.
            </p>
          </>
        ),
      },
      {
        question:
          "What is the process to request back ALL of my originally submitted cards?",
        answer: (
          <>
            <p>
              To request cards home that you’ve originally submitted for intake
              and have gone unsold, please submit a support ticket to{" "}
              <a href="mailto:support@starstock.com">support@starstock.com</a>{" "}
              with “Originally Submitted Cards” in the subject line. Please only
              send this request if you would like ALL of your originally
              submitted cards back. You will only be charged for postage and
              handling (packing materials). Support staff will walk you through
              the process by email. Individual cards purchased on our
              marketplace (not originally yours) must go through the normal
              ship-home process and cannot be combined with this request.
            </p>
            <p>
              Please note that depending on the size of your request and the
              current backlog, it may take up to a month to receive your cards
              back. We will package your cards safely and ship USPS Priority or
              UPS. The customer is responsible for all postage costs including
              insurance, signature confirmation, handling (packing materials),
              etc. We will add a charge of $5 to cover the cost of materials.
              StarStock is not responsible for lost packages once they leave our
              possession. You will receive tracking with any additional shipping
              services requested once your package is ready to ship.
            </p>
          </>
        ),
      },

      {
        question: "What is the cost to ship my cards home?",
        answer: (
          <>
            <p>
              You will be able to view your total cost in your basket prior to
              completing your ship home request. For mixed orders, only the
              highest fixed fee applicable to your order will be charged. The
              costs are as follows:
            </p>
            <table className="table table-sm border shadow">
              <thead className="bg-primary text-white">
                <tr>
                  <th>Item type</th>
                  <th className="text-right text-nowrap">Fixed fee</th>
                  <th className="text-right text-nowrap">Per item</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sealed wax</td>
                  <td className="text-right">$15.00</td>
                  <td className="text-right">+ $2.00</td>
                </tr>
                <tr>
                  <td>Graded cards</td>
                  <td className="text-right">$5.00</td>
                  <td className="text-right">+ $1.00</td>
                </tr>
                <tr>
                  <td>Raw cards (up to 100)</td>
                  <td className="text-right">$5.00</td>
                  <td className="text-right">+ $0.25</td>
                </tr>
                <tr>
                  <td>Raw cards (over 100)</td>
                  <td className="text-right">$15.00</td>
                  <td className="text-right">+ $0.25</td>
                </tr>
              </tbody>
            </table>
            <p className="mb-0">
              Note that international shipments may cost more, and we will
              contact you if there is an additional fee required.
            </p>
          </>
        ),
      },
      {
        question: "How long does it take to receive my cards?",
        answer: (
          <>
            <ul>
              <li>Graded Cards currently take around 1 week to ship</li>
              <li>Sealed Wax currently takes around 1-2 weeks to ship</li>
              <li>
                Ungraded (Raw) Cards currently take around 1-3 weeks to ship
              </li>
            </ul>
            <p className="mb-0">
              If you request both graded & ungraded cards, the request will fall
              under the “ungraded” turnaround times. Larger orders may see
              longer than expected turnaround times. Requests including both wax
              and cards may take longer to process.
            </p>
          </>
        ),
      },
      {
        question: "Can I add or remove cards from a shipping request?",
        answer: (
          <>
            Once you have submitted a shipping request it cannot be edited. If
            you have already submitted a shipping request and would like to have
            additional cards shipped home, please submit an additional shipping
            request. If you wish to cancel a shipping request you have already
            submitted, please contact support as soon as possible.
          </>
        ),
      },
      {
        question: "Any other shipping info I should know?",
        answer: (
          <ul className="mb-0">
            <li>
              International orders may have additional charges for
              weight/distance.
            </li>
            <li>
              Larger orders may see longer than expected turnaround times.
            </li>
            <li>Larger orders may have higher costs due to weight.</li>
            <li>
              Requests including both wax and cards may take longer to process.
            </li>
            <li>
              Requests made with insufficient address details may be delayed.
            </li>
            <li>
              FedEx/UPS may not ship to PO Boxes and we do NOT have a street
              address. We only ship via USPS.
            </li>
            <li>
              Shipping costs above do not include extra insurance, delivery
              signature, or any other add-ons.
            </li>
            <li>
              We do not cover damages on cards reshipped via a 3rd party
              company.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    id: "card-security",
    title: "Card security",
    questions: [
      {
        question: "Where is the vault?",
        answer: "New York",
      },
      {
        question: "Are my cards secure?",
        answer:
          "Yes. We keep all cards in a facility that has 24 hour security surveillance, key card building access and is temperature controlled. ",
      },
    ],
  },
  {
    id: "marketplace",
    title: "Marketplace",
    questions: [
      {
        question: "What are StarStock's seller fees?",
        answer:
          "StarStock charges an 8% seller fee on all sales. The fee is immediately debited from the sale at purchase. A minimum $0.05 transaction fee per card applies.",
      },
      {
        question: "How is the estimated value of my collection calculated?",
        answer: (
          <>
            <p>
              We indicate the estimated value of your cards in your Collection
              page. Estimated card values are based on the latest sale price of
              cards of exactly the same type and grade on our marketplace. If
              you apply filters to your collection, the estimated value will
              reflect the filtered results.
            </p>
            <p>
              You may own some cards we do not yet have any equivalent sales
              for, in which case we are unable to assign an estimated value. In
              this scenario we will highlight how many unvalued cards you own in
              your collection.
            </p>
            <p>
              The estimated value does not include your current account balance,
              which can be separately viewed in the account menu.
            </p>
          </>
        ),
      },
      {
        question: "How can I withdraw my funds?",
        answer: (
          <>
            <p>
              You can currently withdraw funds via PayPal (subject to PayPal
              fees) or check (no additional fees, minimum £20 withdrawal).
              Additional withdrawal options including ACH are coming soon.
            </p>
            <p>
              If you made a deposit but did not spend any of the money, your
              withdrawal will instead be treated as a refund to the credit or
              debit card you made the deposit from.
            </p>
            <p>
              To make a withdrawal, click on the Account icon on the top right
              and hit the Withdraw button. After completing the form we will
              process PayPal withdrawals within 4-5 working days. Please expect
              around 5 working days for processing checks.
            </p>
          </>
        ),
      },
      {
        question: "Are refunds offered on StarStock?",
        answer:
          "StarStock is an instant trading platform between independent buyers and sellers. All sales are final and non-refundable. If you buy a card you decide you no longer want, you can instantly re-list the card for sale on the marketplace.",
      },
    ],
  },
  {
    id: "ebay-auctions",
    title: "7-Day eBay Auctions:",
    questions: [
      {
        question: "How do I submit a card to auction?",
        answer:
          "You can submit a card by emailing support@starstock.com with a list of cards you’d like to upload to auction.  We can upload cards that are already in your StarStock account, or you can ship in new cards.",
      },
      {
        question: "What cards can I put up for auction?",
        answer: (
          <>
            <p>
              Any card that is PSA, BGS, or SGC graded or any sealed box.
            </p>
            <p>
              The card or box must be above $10 in value.
            </p>
           </>
        ),
      },
      {
        question: "What’s the fee structure for your eBay auctions?",
        answer: "8% fee (same as our marketplace)",
      },
      {
        question: "How long does it take to get paid out?",
        answer:
          "Users will get paid out within one week of the conclusion of the auction. Funds will be added into the user’s StarStock account.",
      },
    ],
  },
  {
    id: "my-account",
    title: "My account",
    questions: [
      {
        question: "How do I use a promo code?",
        answer: (
          <>
            <p>
              If you have a promo code, it can be entered on the sign up form when creating 
              your account. Promo code bonuses may be triggered by additional steps, such as 
              making a deposit or submitting cards. Requirements for such codes are outlined on 
              the deposit screen after registering.
            </p>
            <p className="mb-0">
              All live promo codes are for new registrations only. StarStock may send future promotions 
              separately via email, social media or StarStock.com.
            </p>
          </>
        ),
      },
      {
        question: "How do I contact customer support?",
        answer: (
          <>
            Send us an email at{" "}
            <a href="mailto:support@starstock.com">support@starstock.com</a>, or
            you can call or message us on Instagram @StarStock!.
          </>
        ),
      },
      {
        question: "What is the account maintenance fee?",
        answer: (
          <>
            <p>
              After being inactive on the Startock platform for 6 months or
              more, you may incur a monthly maintenance fee of $5.00. This fee
              is deducted from your in-app balance. Accounts that have not made
              a buy order or sell order in the last 6 months will be considered
              inactive.
            </p>
            <p>
              While we would hate to see you go, we do encourage those that
              don’t plan to use the site anymore to{" "}
              <Link to="/account/withdraw">withdraw their funds</Link>.
            </p>
            <p>
              No monthly maintenance fee will be deducted from accounts with $0
              balance.
            </p>
          </>
        ),
      },
      {
        question: "What defines an inactive account?",
        answer: (
          <>
            <p>
              Accounts that have not made a buy order (i.e. bought a listed card
              or placed an offer) or sell order (i.e. created a new listing or
              accepted an offer) in the last 6 months will be considered
              inactive.
            </p>
          </>
        ),
      },
      {
        question: "Can I re-activate an inactive account and avoid the fee?",
        answer: (
          <>
            <p>
              Yes - by purchasing or selling cards, your account will no longer
              be considered inactive. Only after subsequent 6 months of
              inactivity would it again be considered inactive.
            </p>
          </>
        ),
      },
      {
        question:
          "What if I do not have sufficient funds to pay the maintenance fee?",
        answer: (
          <>
            <p>
              You will only be charged what is available in your wallet.
              Accounts with $0 will not pay a maintenance fee. Accounts with
              more than $0, but less than $5, will have their balance reduced to
              zero and no further.
            </p>
          </>
        ),
      },
    ],
  },
];

const Faq = () => {
  useEffect(() => logAmplitudeEvent(SUPPORT_EVENTS.FAQ.PAGE_VIEWED), []);

  return (
    <>
      <Helmet>
        <title>FAQ</title>
      </Helmet>

      <div className="row bg-light py-5">
        <div className="col mx-auto" style={{ maxWidth: "60rem" }}>
          <div className="d-flex flex-row align-items-end pb-4">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="text-primary fa-3x mr-3"
            />
            <h1 className="font-weight-bold mb-0">FAQs</h1>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card shadow p-2 p-md-3 mb-4">
                <div className="card-body">
                  <h3 className="font-weight-bold mb-3">What is StarStock?</h3>
                  <p className="mb-4">
                    StarStock is building a new and improved marketplace for
                    sports card hobbyists to buy, sell, and invest in their
                    favorite sports players. Through technology, we push to
                    provide an exceptional user experience, low fees, and speed!
                    StarStock stores your collection in a centralized vault, and
                    issues a digital version of the card inside your StarStock
                    portfolio . This enables users to trade players
                    instantaneously, at scale, and at a substantially lower
                    transaction cost than other markets.
                  </p>

                  <ul>
                    <li>
                      <h5 className="font-weight-bold">Instant trading</h5>
                      <p>
                        Get instant ownership of your card after purchase. You
                        can even day-trade your favorite players on gameday.
                      </p>
                    </li>
                    <li>
                      <h5 className="font-weight-bold">
                        Invest in players at scale
                      </h5>
                      <p>
                        StarStock consolidates purchases from multiple sellers
                        into one checkout. That means you can buy from multiple
                        sellers in one transaction!
                      </p>
                    </li>
                    <li>
                      <h5 className="font-weight-bold">
                        Lowest fees in the game
                      </h5>
                      <p>
                        StarStock charges an 8% fee <em>($0.05 minimum)</em> to
                        the seller per card sold. We do not charge fees for cash withdrawal
                        <sup>*</sup> or at purchase.
                      </p>
                      <p className="small">
                        * Free cash withdrawals via check. Withdrawals via
                        PayPal are subject to PayPal transaction fees.
                      </p>
                    </li>
                    <li>
                      <h5 className="font-weight-bold">
                        No shipping necessary
                      </h5>
                      <p>
                        The cards stay in our vault (unless you want them
                        shipped home). All cards are secured and insured.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              {faqSections.map((faqSection) => (
                <div
                  key={faqSection.id}
                  className="card shadow p-2 p-md-3 mb-4"
                  style={faqSection.style}
                >
                  <div className="card-body">
                    <section
                      className="faq-section"
                      id={faqSection.id}
                      key={faqSection.id}
                    >
                      <h3 className="font-weight-bold mb-3">
                        {faqSection.title}
                      </h3>
                      {faqSection.summary && <>{faqSection.summary}</>}
                      <Accordion>
                        {faqSection.questions.map((question, index) => (
                          <div className="border-top py-3" key={index}>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              className="text-left font-weight-bold p-0"
                              eventKey={question}
                            >
                              {question.question}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={question}>
                              <div className="mt-3">{question.answer}</div>
                            </Accordion.Collapse>
                          </div>
                        ))}
                      </Accordion>
                    </section>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
