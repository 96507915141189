import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";
import { rgba } from "polished";

import { PRODUCT_TYPES } from "../../api/types";

import placeholder from "../../images/placeholder.png";

import ItemOwnershipMarketDataDisplay from "./ItemOwnershipMarketDataDisplay";
import SummaryDataRow, { SummaryDataItemWidths } from "../SummaryDataRow";
import CardParallelBadges from "../CardParallelBadges";
import CardParallelProductPhoto from "../CardParallelProductPhoto";

import { displayNameForGrade } from "../../utils/grades";
import { financial } from "../../utils/common";

const Astronaut = "#2c4d76";
const Porcelain = "#f6f7f8";

const priceLabel = (price) => (price ? `$${financial(price)}` : "–");

const OuterContainer = styled.div`
  transition: all 200ms ease-in-out;
  &:hover {
    transform: translateY(-2px);
  }
`;

const StyledProductPhotoOuter = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 140%;
  margin-bottom: 1rem;
`;

const StyledProductPhotoInner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
  overflow: hidden;
`;

const CardName = styled.p`
  font-size: 0.75rem;
  color: ${rgba(Astronaut, 0.8)};
  line-height: 1rem;
  height: calc(1rem * 3);
  margin-bottom: 0.5rem;
  overflow-y: hidden;
  position: relative;
  @media (min-width: 28rem) {
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: calc(1.25rem * 3);
  }
  :after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 4rem;
    height: 1rem;
    background: linear-gradient(
      to right,
      ${rgba(Porcelain, 0)},
      ${Porcelain} 100%
    );
    @media (min-width: 28rem) {
      height: 1.25rem;
    }
  }
`;

const CardGrade = styled.p`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${rgba(Astronaut, 1)};
  white-space: nowrap;
  margin-bottom: 0.25rem;
  overflow-y: hidden;
`;

const ItemOwnershipGridItem = ({ itemOwnership, showMarketData, children }) => {
  const { item } = itemOwnership;
  const { cardParallel, grade } = item;

  const linkPath = `/marketplace/cards/${cardParallel.slug}?grade=${grade.id}`;

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { itemOwnership });
    }
    return child;
  });

  return (
    <OuterContainer key={itemOwnership.id}>
      <Link to={linkPath} className={""}>
        <StyledProductPhotoOuter>
          <StyledProductPhotoInner productType={cardParallel.productType}>
            <CardParallelProductPhoto
              productType={cardParallel.productType}
              image={
                cardParallel.productType === PRODUCT_TYPES.BOX
                  ? cardParallel.boxProductPhotoMedium || placeholder
                  : cardParallel.frontSidePhotoMedium || placeholder
              }
              grade={grade}
            />
          </StyledProductPhotoInner>
        </StyledProductPhotoOuter>
      </Link>

      <div className="mb-2">
        {childrenWithProps}
        <CardGrade>{displayNameForGrade(grade)}</CardGrade>
        <CardName>{cardParallel.name}</CardName>
        <CardParallelBadges
          name={cardParallel.parallel.name}
          badgeTextColor={cardParallel.parallel.badgeTextColor}
          badgeBorderColor={cardParallel.parallel.badgeBorderColor}
          badgeGradientStart={cardParallel.parallel.badgeGradientStart}
          badgeGradientEnd={cardParallel.parallel.badgeGradientEnd}
          badgeGradientAngle={cardParallel.parallel.badgeGradientAngle}
          serialLimit={cardParallel.serialLimit}
        />
      </div>
      {showMarketData && (
        <Link to={linkPath} className={"btn text-left p-0 m-0"}>
          <SummaryDataRow
            configAlignItems="justify-content-start"
            summaryDataItems={[
              {
                label: "Ask price",
                value: priceLabel(itemOwnership.askPrice),
                width: SummaryDataItemWidths.auto,
                textAlignment: "text-left",
              },
            ]}
          />

          <ItemOwnershipMarketDataDisplay itemOwnership={itemOwnership} />
        </Link>
      )}
    </OuterContainer>
  );
};

export default ItemOwnershipGridItem;
