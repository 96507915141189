import { useState } from "react";
import moment from "moment";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

import ExtendExpiryForm, {
  ExtendExpiryFormSteps,
} from "../ExtendExpiryForm/ExtendExpiryForm";

const ListingListGradeItemMenu = ({
  groupedListingId,
  groupedListingClose,
  groupedListingQuantity,
  expiresAfter,
  refreshQueries,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const openMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const setDisabled = (expiresAfter) =>
    !(moment(expiresAfter).diff(moment.now(), "weeks") === 0);

  const setModalandMenu = () => {
    closeMenu();
    setModalShow(true);
  };

  return (
    <>
      <button
        className="btn btn-link"
        aria-haspopup="true"
        aria-label="menu"
        onClick={openMenu}
      >
        <FontAwesomeIcon icon={faEllipsisH} className="fa-fw" />
      </button>
      <Menu
        id="accessory-menu"
        className="text-danger"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            groupedListingClose({
              variables: { id: groupedListingId, allListings: false },
            });
          }}
          className="text-danger"
        >
          {groupedListingQuantity > 1 ? "Cancel one listing" : "Cancel listing"}
        </MenuItem>

        {groupedListingQuantity > 1 && (
          <MenuItem
            onClick={() => {
              groupedListingClose({
                variables: { id: groupedListingId, allListings: true },
              });
            }}
            className="text-danger"
          >
            Cancel all listings
          </MenuItem>
        )}
        <MenuItem
          disabled={setDisabled(expiresAfter)}
          onClick={() => setModalandMenu()}
          className="text-success"
        >
          Extend expiry
        </MenuItem>
      </Menu>

      {modalShow && (
        <ExtendExpiryForm
          show={modalShow}
          setShow={setModalShow}
          initialStep={ExtendExpiryFormSteps.EDIT}
          groupedListingId={groupedListingId}
          refreshQueries={refreshQueries}
        />
      )}
    </>
  );
};

export default ListingListGradeItemMenu;
