import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../../utils/getCurrency";

import { EventCard } from "../../../../components/EventCard";
import BreakMySpotsCountBadge from "../../../../components/Breaks/BreakMySpotsCountBadge";
import {
  BoxBreakNode,
  BoxBreakState,
  EventEventType,
  SpotPriceCurrency,
} from "../../../../generated/schema";
import PlaceholderBG from "../../../../images/breaks-logo-color.svg";

import { SpotsAvailable } from "../SpotsAvailable";
import { PriceLabel, PricePerSpotLabel, PriceSeparator } from "./styles";

interface Props {
  boxBreak: BoxBreakNode;
}

const Price = ({
  currency,
  maxPrice,
  minPrice,
}: {
  maxPrice: number | null;
  minPrice: number | null;
  currency: SpotPriceCurrency | null;
}) => {
  const hasNoOpeningPrices = !(minPrice && maxPrice);

  if (hasNoOpeningPrices) return <>Coming soon…</>;
  if (minPrice === maxPrice) {
    return <> {formatCurrency(minPrice, currency)}</>;
  }
  return (
    <>
      {formatCurrency(minPrice, currency)}
      <PriceSeparator> - </PriceSeparator>
      {formatCurrency(maxPrice, currency)}
    </>
  );
};

export const BreakCard = ({ boxBreak }: Props) => {
  const navigate = useNavigate();

  const images = boxBreak.boxbreakphotoSet.edges.map((image) => image?.node);
  const primaryImage = images[0] && images[0].photoMd;
  const mySpotsCount =
    boxBreak.myParticipant && boxBreak.myParticipant.spotSet.totalCount;

  const scheduledStartTime = boxBreak.scheduledStartTime
    ? new Date(boxBreak.scheduledStartTime)
    : null;

  const isFeatured =
    boxBreak.featuredRank !== null &&
    [BoxBreakState.PUBLISHED_OPEN, BoxBreakState.PUBLISHED_LOCKED].includes(
      boxBreak.state,
    );

  const hasEnded = [
    BoxBreakState.LIVE_LOCKED,
    BoxBreakState.ENDED,
    BoxBreakState.SETTLED,
  ].includes(boxBreak.state);

  return (
    <EventCard
      event={EventEventType.BREAK}
      featuredLabel="Featured Break"
      imageUrl={primaryImage}
      imageBackgroundUrl={PlaceholderBG}
      isFeatured={isFeatured}
      onClick={() => navigate(`/breaks/${boxBreak.slug}`)}
      scheduledStartTime={scheduledStartTime}
      state={boxBreak.state}
      STATES={BoxBreakState}
      subtitle={boxBreak.format.name}
      title={boxBreak.name}
    >
      <EventCard.HeroComponents>
        <BreakMySpotsCountBadge mySpotsCount={mySpotsCount} />
      </EventCard.HeroComponents>

      {!boxBreak.purchaseOverrideUrl && (
        <EventCard.UpperComponents>
          <SpotsAvailable
            availableCount={boxBreak.spotsAvailableCount}
            hasEnded={hasEnded}
            totalCount={boxBreak.spotsTotalCount}
          />
        </EventCard.UpperComponents>
      )}

      <EventCard.LowerComponents>
        <PricePerSpotLabel>Price per spot</PricePerSpotLabel>
        <PriceLabel>
          <Price
            maxPrice={boxBreak.spotsMaxPrice}
            minPrice={boxBreak.spotsMinPrice}
            currency={boxBreak.spotsCurrency as SpotPriceCurrency}
          />
        </PriceLabel>
      </EventCard.LowerComponents>
    </EventCard>
  );
};
