import { useState } from "react";

import { productName, productNameSuffix } from "../utils/content";

import { PRODUCT_TYPES } from "../api/types";

import CardParallelTallContainer from "../components/CardParallelTallContainer";
import CardParallelGradeTabContainer, {
  CardParallelGradeTabContainerSkeleton,
} from "./CardParallelGradeTabContainer";
import MarketSummaryDataRow from "../components/MarketSummaryDataRow";
import { CardParallelTallContainerSkeleton } from "./CardParallelTallContainer";
import { MarketSummaryDataRowSkeleton } from "./MarketSummaryDataRow";

export const MarketCardParallelTallWrapper = ({
  cardParallel,
  bannerText,
  themeColor,
  widgetName,
  position,
}) => {
  return cardParallel ? (
    <MarketCardParallelTall
      cardParallel={cardParallel}
      bannerText={bannerText}
      themeColor={themeColor}
      widgetName={widgetName}
      position={position}
    />
  ) : (
    <MarketCardParallelTallSkeleton />
  );
};

export const MarketCardParallelTallSkeleton = () => {
  return (
    <CardParallelTallContainerSkeleton>
      <div className="d-flex flex-column justify-content-end h-100 mt-3">
        <CardParallelGradeTabContainerSkeleton>
          <MarketSummaryDataRowSkeleton />
        </CardParallelGradeTabContainerSkeleton>
      </div>
    </CardParallelTallContainerSkeleton>
  );
};

const MarketCardParallelTall = ({
  cardParallel,
  gradedCardParallels = [],
  hiddenGradesCount = 0,
  bannerText,
  themeColor,
  widgetName,
  position,
}) => {
  if (gradedCardParallels.length === 0) {
    const { gradedcardparallelSet } = cardParallel;
    gradedCardParallels = gradedcardparallelSet.edges.map(({ node }) => node);
    hiddenGradesCount = Math.max(
      gradedcardparallelSet.totalCount - gradedCardParallels.length,
      0,
    );
  }

  // Let the user hover (on desktop) or tap (on mobile) to swap grade
  const [selectedGradedCardParallelId, setSelectedGradedCardParallelId] =
    useState((gradedCardParallels[0] && gradedCardParallels[0].id) || null);
  const selectedGradedCardParallel =
    gradedCardParallels.find(
      (gcp) => gcp.id === selectedGradedCardParallelId,
    ) || gradedCardParallels[0];

  const linkPath = selectedGradedCardParallel
    ? `/marketplace/cards/${cardParallel.slug}?grade=${selectedGradedCardParallel.grade.id}`
    : `/marketplace/cards/${cardParallel.slug}`;

  const playerHeadshot =
    cardParallel.playerCard.teamPlayers.edges.length > 0
      ? cardParallel.playerCard.teamPlayers.edges[0].node.player.headshotPhoto
      : null;

  return (
    <CardParallelTallContainer
      cardParallel={cardParallel}
      linkPath={linkPath}
      productType={cardParallel.productType}
      cardPhoto={
        cardParallel.productType === PRODUCT_TYPES.BOX
          ? cardParallel.boxProductPhotoSmall
          : cardParallel.frontSidePhotoSmall
      }
      playerHeadshot={playerHeadshot}
      itemCount={cardParallel.sellOrderCount}
      cardProgram={cardParallel.playerCard.cardFamily.program.name}
      cardFamily={cardParallel.playerCard.cardFamily.name}
      fullCardParallelName={cardParallel.name}
      cardName={productName(cardParallel)}
      cardSetNumber={productNameSuffix(cardParallel)}
      parallel={cardParallel.parallel}
      serialLimit={cardParallel.serialLimit}
      grade={selectedGradedCardParallel.grade}
      bannerText={bannerText}
      themeColor={themeColor}
      widgetName={widgetName}
      position={position}
    >
      <div className="d-flex flex-column justify-content-end h-100 mt-3">
        {selectedGradedCardParallel ? (
          <CardParallelGradeTabContainer
            gradedCardParallels={gradedCardParallels}
            selectedGradedCardParallelId={selectedGradedCardParallelId}
            setSelectedGradedCardParallelId={setSelectedGradedCardParallelId}
            hiddenGradesCount={hiddenGradesCount}
            linkPath={linkPath}
          >
            <MarketSummaryDataRow
              listingsCount={selectedGradedCardParallel.openSellOrderCount}
              lowestListingPrice={
                selectedGradedCardParallel.openSellOrderMinPrice
              }
              offersCount={selectedGradedCardParallel.openBuyOrderCount}
              bestOfferPrice={selectedGradedCardParallel.openBuyOrderMaxPrice}
              latestSalePrice={selectedGradedCardParallel.lastSalePrice}
              configShowOffers={false}
            />
          </CardParallelGradeTabContainer>
        ) : (
          <p className="text-whisper mb-0">No cards in our vault</p>
        )}
      </div>
    </CardParallelTallContainer>
  );
};

export default MarketCardParallelTall;
