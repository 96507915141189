import styled, { css } from "styled-components";

import { Colors } from "../../themes/Default";

interface LabelProps {
  isPublished: boolean;
}
interface PillProps {
  isLive: boolean;
}

const LiveGradientStart = "#EE9165";
const LiveGradientEnd = "#B56CE7";

export const Label = styled.p<LabelProps>`
  margin-bottom: 0;

  ${({ isPublished }) =>
    isPublished &&
    css`
      background: linear-gradient(
        90deg,
        ${LiveGradientStart},
        ${LiveGradientEnd}
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}
`;

export const Pill = styled.div<PillProps>`
  border-radius: 50rem;
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 32 / 10%);
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0.25rem 1rem;

  ${({ isLive }) =>
    isLive
      ? css`
          background: linear-gradient(
            135deg,
            ${LiveGradientStart},
            ${LiveGradientEnd}
          );
          border: 1px solid ${LiveGradientEnd};
          color: white;
        `
      : css`
          background: white;
          border: 1px solid #00000020;
          color: ${Colors.Astronaut}80;
        `}
`;
