import _ from "lodash";

import { Modal } from "react-bootstrap";

import { financial } from "../../utils/common";

const BuyModalBody = ({ openSellOrders }) => {
  const groupedByPrice = _.groupBy(openSellOrders, function (order) {
    return order.askPrice;
  });

  const priceKeys = _.keys(groupedByPrice).sort((a, b) => a - b);

  return (
    <Modal.Body className="py-0">
      <div className="row bg-light">
        {openSellOrders.length ? (
          <table className="table table-borderless table-sm mb-0">
            <thead>
              <tr>
                <th className="text-center w-50" scope="col">
                  Price
                </th>
                <th className="text-center w-50" scope="col">
                  Listings available
                </th>
              </tr>
            </thead>
            <tbody className="border-top">
              {priceKeys.map((priceKey) => (
                <tr key={priceKey}>
                  <th className="text-center" scope="row">
                    ${financial(priceKey)}
                  </th>
                  <td className="text-center">
                    {groupedByPrice[priceKey].length}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center lead mx-auto my-3">No open listings</p>
        )}
      </div>
    </Modal.Body>
  );
};
export default BuyModalBody;
