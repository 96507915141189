import {
  FormControl,
  MenuItem,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import moment from "moment";
import { MouseEvent, useRef } from "react";

import { SelectAddress } from "../../../../features/account";
import { financial } from "../../../../utils/common";
import { FormData } from "../types";

interface Props {
  breakId?: string;
  breakFee: number;
  breaks: {
    node: {
      id: string;
      name: string;
      scheduledStartTime: string;
    };
  }[];
  boxes: {
    node: {
      id: string;
      breakFee: number;
      item: {
        cardParallel: {
          name: string;
        };
      };
    };
  }[];
  formData: FormData;
  updateForm: (updatedFields: {
    [field: string]: string | number | boolean;
  }) => void;
  formCompleted: boolean;
  onCancel: () => void;
  onNext: () => void;
}

const Form = ({
  breakId,
  breakFee,
  breaks,
  boxes,
  formData,
  updateForm,
  formCompleted,
  onNext,
  onCancel,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleClick = (e: MouseEvent<HTMLButtonElement>, func: Function) => {
    e.preventDefault();
    formRef.current?.reset();

    func();
  };

  return (
    <form ref={formRef}>
      <FormControl className="d-block align-baseline" variant={"outlined"}>
        <TextField
          id="request-break-box"
          label="Select a box from your collection"
          defaultValue=""
          select
          variant="outlined"
          margin="dense"
          className="mb-2 w-100"
          onChange={(e) => {
            const { breakFee, id: itemOwnershipId } =
              boxes[parseInt(e.target.value)].node;

            updateForm({ breakFee, itemOwnershipId });
          }}
        >
          {boxes.map(({ node: { id, breakFee, item } }, idx) => (
            <MenuItem key={id} value={idx}>
              {item.cardParallel.name} (${financial(breakFee)})
            </MenuItem>
          ))}
        </TextField>
      </FormControl>

      <FormControl className="d-block align-baseline" variant={"outlined"}>
        <TextField
          id="request-break-break"
          label="Select an available personals break"
          defaultValue={breakId || ""}
          select
          variant="outlined"
          margin="dense"
          className="mb-3 w-100"
          onChange={(e) => updateForm({ boxBreakId: e.target.value })}
          disabled={breakId === formData.boxBreakId}
        >
          {breaks.map(({ node: { id, name, scheduledStartTime } }) => (
            <MenuItem key={id} value={id}>
              {name} ({moment(scheduledStartTime).format("MMM Do - h:mm A")})
            </MenuItem>
          ))}
        </TextField>
      </FormControl>

      <TextField
        id="request-details"
        label="Preferred time during break"
        aria-label="Preferred time during break"
        defaultValue=""
        onChange={(e) => updateForm({ requestDetails: e.target.value })}
        variant="outlined"
        fullWidth
        multiline
        rows={2}
        margin="dense"
        className="mb-3 mt-0"
        placeholder="We will do our best to accommodate your preferred time. Please specify in ET."
      />

      <SelectAddress onChange={(value) => updateForm({ addressId: value })} />

      <TextField
        id="additional-shipping-instructions"
        label="Additional shipping instructions"
        aria-label="additional shipping instructions"
        defaultValue=""
        onChange={(e) => updateForm({ additionalInstructions: e.target.value })}
        variant="outlined"
        fullWidth
        multiline
        rows={2}
        margin="dense"
        className="mb-3 mt-0"
        placeholder="Optional additional instructions for our team (e.g. discard non-RC cards, etc…)."
      />

      <p className="mb-2">
        Cards not eligible for StarStock will be shipped free to the above
        address. Cards eligible for StarStock can be added to your account, or
        shipped home.
      </p>

      <FormControl>
        <RadioGroup
          row
          aria-label="hit preference"
          name="hitPreference"
          defaultValue="false"
          onChange={(e) =>
            updateForm({ preferShipHome: e.target.value === "true" })
          }
          className="mb-2"
        >
          <FormControlLabel
            value="false"
            control={<Radio size={"medium"} color={"primary"} />}
            label="Add to StarStock account"
            className="mb-n2"
          />
          <FormControlLabel
            value="true"
            control={<Radio size={"medium"} color={"primary"} />}
            label="Ship home"
            className="mb-n2"
          />
        </RadioGroup>
      </FormControl>

      <div className="d-flex flex-row justify-content-between border-top pt-3 mt-2 mb-3">
        <p className="text-left mb-0">Breaking fee for selection: </p>
        <p className="text-right mb-0 ml-2 font-weight-bold">
          ${financial(breakFee)}
        </p>
      </div>

      <div className="d-flex flex-row justify-content-between align-items-center">
        <button
          className="btn btn-secondary btn-block text-body"
          onClick={(e) => handleClick(e, onCancel)}
        >
          Cancel
        </button>
        <button
          disabled={!formCompleted}
          className="btn btn-primary btn-block font-weight-bold mt-0 ml-3"
          onClick={(e) => handleClick(e, onNext)}
        >
          Confirm purchase
        </button>
      </div>
    </form>
  );
};

export default Form;
