import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_ID_FRAGMENT,
  GRADE_FRAGMENT,
  GROUPED_BID_FRAGMENT,
  PARALLEL_FRAGMENT,
  PLAYER_CARD_FRAGMENT,
} from "../fragments";

const GET_MY_GROUPED_BIDS = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${GROUPED_BID_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_FRAGMENT}
  query GET_MY_GROUPED_BIDS(
    $cursor: String
    $searchQuery: String
    $orderBy: String
  ) {
    myGroupedBids(
      first: 100
      after: $cursor
      searchQuery: $searchQuery
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...groupedBidFields
          buyorderSet(first: 1) {
            edges {
              node {
                id
                state
                buyPrice
                expiresAfter
                cardParallel {
                  ...cardParallelIdFields
                  frontSidePhotoSmall
                  parallel {
                    ...parallelFields
                  }
                  playerCard {
                    ...playerCardFields
                  }
                }
                grade {
                  ...gradeFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_MY_GROUPED_BIDS;
