import { gql } from "@apollo/client";

const MAKE_ADDRESS_DEFAULT = gql`
  mutation MAKE_ADDRESS_DEFAULT($id: ID!) {
    makeAddressDefault(id: $id) {
      errors {
        field
        message
      }
    }
  }
`;

export default MAKE_ADDRESS_DEFAULT;
