import styled from "styled-components";

import { financial } from "../../../utils/common";

const ErrorConfirmation = ({ errors, onClose }) => {
  return (
    <div className="p-3">
      <div className="alert alert-danger text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">There was a problem</h3>
        {errors.map((error, i) => (
          <p key={i} className="my-1">
            {error.message}
          </p>
        ))}
      </div>

      <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary px-5" onClick={() => onClose()}>
          Close
        </button>
      </div>
    </div>
  );
};

const GradientStart = "#2980b9";
const GradientEnd = "#6DD5FA";

const SuccessAlert = styled.div`
  padding: 1rem;
  background: linear-gradient(135deg, ${GradientStart}, ${GradientEnd});
`;

const SuccessConfirmation = ({ draftShippingRequest, onComplete }) => {
  return (
    <div className="p-3">
      <SuccessAlert className="alert alert-primary text-center p-4">
        <h3 className="text-white font-weight-bold">
          Your request has been submitted.
        </h3>
        <p className="text-white">
          You have been charged $
          {financial(draftShippingRequest.shippingRequestFee)}. Your ship home
          request will be processed and items sent as per the advised lead
          times.
        </p>
        <p className="text-white mb-0">
          If additional international fees are required, we will contact you to
          confirm payment prior to shipping.
        </p>
      </SuccessAlert>

      <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary px-5" onClick={() => onComplete()}>
          Continue
        </button>
      </div>
    </div>
  );
};

const StepComplete = ({
  draftShippingRequest,
  formData,
  onClose,
  onComplete,
}) => {
  return (
    <>
      {formData.completedErrors && formData.completedErrors.length ? (
        <ErrorConfirmation
          errors={formData.completedErrors}
          onClose={() => onClose()}
        />
      ) : (
        <SuccessConfirmation
          draftShippingRequest={draftShippingRequest}
          onComplete={() => onComplete()}
        />
      )}
    </>
  );
};

export default StepComplete;
