import React from "react";

const BreakList = ({ children }) => {
  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 my-3 mx-n2">
      {React.Children.map(children, (child) => (
        <div key={child.props.id} className="col px-2 mb-3">
          {child}
        </div>
      ))}
    </div>
  );
};

export default BreakList;
