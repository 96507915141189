import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Helmet } from "react-helmet";

import DELETE_PAYMENT_METHOD from "../../../api/mutations/DELETE_PAYMENT_METHOD";
import Loader from "../../../components/Loader";
import ErrorNotice from "../../../components/ErrorNotice";

import GET_MY_PAYMENT_METHODS from "../api/GET_MY_PAYMENT_METHODS";
import { PaymentMethodsResponse } from "../types";
import { EditPaymentMethodsModal } from "../components/EditPaymentMethodsModal";
import { ManagePaymentActions } from "../components/ManagePaymentActions";
import { PaymentCardList } from "../components/PaymentCardList";
import { ManagePaymentAddNewMethod } from "../components/ManagePaymentAddNewMethod";

import { Container, PaymentMethodContainer } from "./styles";

export const ManagePaymentMethods = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const { data, error, loading, refetch } = useQuery<PaymentMethodsResponse>(
    GET_MY_PAYMENT_METHODS,
  );

  const [deletePaymentMethod] = useMutation(DELETE_PAYMENT_METHOD, {
    refetchQueries: [{ query: GET_MY_PAYMENT_METHODS }],
  });

  if (loading) return <Loader />;
  if (error) return <ErrorNotice error={error} />;

  const handleEditModalClose = () => setShowEditModal(false);
  const handleEditModalShow = () => setShowEditModal(true);

  const handleAddModalClose = () => setShowAddModal(false);
  const handleAddModalShow = () => setShowAddModal(true);

  const onDeletePaymentMethod = (paymentMethodId: string) => {
    handleEditModalClose();
    return deletePaymentMethod({
      variables: {
        paymentMethodId,
      },
    });
  };

  const paymentMethods = data?.myPaymentMethods!;
  const isFirstPaymentMethod = paymentMethods.length === 0;

  return (
    <Container>
      <Helmet>
        <title>Manage Payment Methods</title>
      </Helmet>

      <PaymentMethodContainer>
        <ManagePaymentActions
          handleAdd={handleAddModalShow}
          handleEdit={handleEditModalShow}
        />
        <EditPaymentMethodsModal
          handleClose={handleEditModalClose}
          onDelete={onDeletePaymentMethod}
          paymentMethods={paymentMethods}
          show={showEditModal}
          showManagePaymentMethod
        />
        <PaymentCardList />
        <ManagePaymentAddNewMethod
          firstPaymentMethod={isFirstPaymentMethod}
          show={showAddModal}
          handleModalClose={handleAddModalClose}
          refetch={refetch}
          handleAddModalShow={handleAddModalShow}
        />
      </PaymentMethodContainer>
    </Container>
  );
};
