import { LocationLink } from "../LocationLink";

import SalesChart from "./SalesChart";
import SalesTable from "./SalesTable";

const SalesHistorySection = ({ loginData, recentPurchases }) => {
  return (
    <>
      <h3 className="font-weight-bold mb-4">Sales history</h3>
      {loginData && loginData.isLoggedIn ? (
        <div className="row">
          <div className="col-lg order-lg-1 mb-4 mb-lg-0">
            <h3 className="h5 font-weight-bold">Average daily sale prices</h3>
            <hr />

            {recentPurchases.length ? (
              <SalesChart sales={recentPurchases} />
            ) : (
              <p className="text-deselected text-center py-5 bg-light rounded">
                We don’t have any average sales price data for this product
              </p>
            )}
          </div>

          <div className="col-lg order-lg-0">
            <h3 className="h5 font-weight-bold">Recent sales</h3>
            <hr />
            {recentPurchases.length ? (
              <SalesTable sales={recentPurchases} />
            ) : (
              <p className="text-deselected text-center py-5 bg-light rounded">
                We don’t have any recent sales data for this product
              </p>
            )}
          </div>
        </div>
      ) : (
        // Nice "sign up for sales charts!" prompt
        <div className="row">
          <div className="col login-gated">
            <h3 className="h5 font-weight-bold">Recent sales charts</h3>
            <hr />
            <div className="card shadow text-center m-3 m-sm-5">
              <div className="card-body">
                <h3 className="font-weight-bold text-speak my-4">
                  Create a free StarStock account to view sales data
                </h3>
                <div className="row">
                  <div className="col-auto mx-auto d-flex flex-column">
                    <LocationLink
                      className="btn btn-success btn-lg shadow font-weight-bold px-5 mb-3"
                      to="/signup"
                    >
                      Sign up free
                    </LocationLink>
                    <LocationLink className="btn btn-link" to="/login">
                      or log in to an existing account
                    </LocationLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesHistorySection;
