import { Link } from "react-router-dom";
import { markdown } from "../../utils/contentful";

const JobListing = ({ job }) => {
  if (job.body === undefined) {
    return (
      <Link to={`/careers/${job.slug}`}>
        <h4 className="font-weight-bold mt-0 mb-2">{job.title}</h4>
        <h5 className="text-whisper mb-3">{job.department}</h5>
        <p className="mb-3">{markdown(job.intro)}</p>
        <p className="text-primary mb-0 font-weight-bold">Find out more</p>
      </Link>
    );
  } else {
    return (
      <>
        <h2>
          {job.title} in {job.department}
        </h2>
        <div className="body">{markdown(job.body)}</div>
      </>
    );
  }
};

export default JobListing;
