import React, { ReactNode, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";

import { Colors } from "../../themes/Default";

import { Arrow, CarouselItem, PageIndicator, StyledSlider } from "./styles";

interface Props {
  arrowTop?: string;
  children: ReactNode;
  customSettings?: Settings;
  id?: string;
  theme?: string;
  themeColor?: string;
}

export const CarouselThemes = {
  light: "light",
  dark: "dark",
};

export const Carousel = ({
  arrowTop = "38%",
  children,
  customSettings,
  id,
  theme = CarouselThemes.light,
  themeColor = Colors.Astronaut,
}: Props) => {
  // Trigger a one-time fade in effect
  const [appearing, setAppearing] = useState(true);
  useEffect(() => setAppearing(false), []);

  const prevArrow = (
    <Arrow direction="left" themeColor={themeColor} top={arrowTop}>
      <FontAwesomeIcon icon={faArrowLeft} className="fa" />
    </Arrow>
  );

  const nextArrow = (
    <Arrow direction="right" themeColor={themeColor} top={arrowTop}>
      <FontAwesomeIcon icon={faArrowRight} className="fa" />
    </Arrow>
  );

  const customPaging = () => (
    <PageIndicator theme={theme} themeColor={themeColor} />
  );

  const defaultSettings = {
    dots: React.Children.count(children) > 3,
    arrows: React.Children.count(children) > 3,
    infinite: React.Children.count(children) > 3,
    speed: 400,
    lazyLoad: true,
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    customPaging: customPaging,
  };

  const settings = Object.assign({}, defaultSettings, customSettings);

  return (
    // @ts-ignore
    <StyledSlider id={id} {...settings}>
      {React.Children.map(children, (child, index) => (
        <CarouselItem
          id={`${id}-${index}`}
          key={`${id}-${index}`}
          appearing={appearing}
        >
          {child}
        </CarouselItem>
      ))}
    </StyledSlider>
  );
};
