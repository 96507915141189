import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { EventLiveVideo } from "../../../../components/EventLiveVideo";
import Widget from "../../../../components/Marketplace/Widgets/Widget";
import {
  AuctionState,
  AuctionNodeConnection,
  AuctionNode,
} from "../../../../generated/schema";
import { BREAKS_EVENTS } from "../../../../utils/analytics/events";

import { GET_ALL_AUCTIONS } from "../../api/GET_ALL_AUCTIONS";
import { AuctionCard } from "../AuctionCard";

interface Props {
  sport?: string;
  era?: string;
}

interface QueryResponse {
  auctions: AuctionNodeConnection;
}

export const AuctionsVideoWidget = ({ sport, era }: Props) => {
  const [auction, setAuction] = useState<AuctionNode | null | undefined>();

  const { data, loading, error } = useQuery<QueryResponse>(GET_ALL_AUCTIONS, {
    variables: {
      sport,
      era,
      first: 1,
      state: [AuctionState.LIVE],
    },
  });

  useEffect(() => {
    if (data?.auctions.edges[0]?.node) {
      setAuction(data?.auctions.edges[0]?.node);
    }
  }, [data]);

  return (
    <Widget
      analyticsEventName={BREAKS_EVENTS.STOREFRONT.WIDGET_LINK_CLICKED}
      background={"transparent"}
      id={"live-auction"}
      linkTitle={"View auction details"}
      linkUrl={`/auctions/${auction?.slug}`}
      subtitle={"Our auction floor is open – come join in the bidding"}
      themeColor={"#e0632d"}
      title="🔥 Bidding now!"
      widgetName={"LiveAuction"}
    >
      <EventLiveVideo
        emptySectionTitle="Nothing bidding right now"
        error={!!error}
        loading={loading}
        twitchChannelName={auction?.twitchChannelName}
        twitchVideoEmbedId={"twitch-embed-auctions"}
        youtubeVideoId={auction?.youtubeVideoId}
      >
        {auction && (
          <AuctionCard
            key={auction.id}
            featured={!!auction.featuredRank}
            imageUrl={auction.auctionphotoSet.edges[0]?.node?.photoMd}
            lotCount={auction.lotCount}
            lotsRemaining={auction.lotRemainingCount}
            openingMinPrice={auction.lotMinOpeningPrice}
            openingMaxPrice={auction.lotMaxOpeningPrice}
            scheduledStartTime={auction.scheduledStartTime}
            slug={auction.slug}
            state={auction.state}
            title={auction.name}
          />
        )}
      </EventLiveVideo>
    </Widget>
  );
};
