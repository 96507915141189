import styled from "styled-components";
import { Link } from "react-router-dom";

import { Colors } from "../../../../themes/Default";

export const Container = styled.div`
  display: block;
  position: relative;
  z-index: 1;
`;

export const Label = styled.label`
  margin-bottom: 0;
  position: relative;
  vertical-align: top;
`;

export const Span = styled.span`
  background-color: ${Colors.Athens};
  border-radius: 3px;
  color: #212529;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  margin-right: 0.2rem;
  padding: 0.25em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
`;

export const List = styled.ol`
  list-style: none;
  padding-left: 0;
  padding-top: 1rem;
`;

export const ButtonGroup = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 0.5rem;
  justify-content: center;
  padding-top: 1rem;
  position: relative;
  vertical-align: middle;
`;

export const Button = styled.button`
  background: ${Colors.Havelock}
    linear-gradient(180deg, #699cda, ${Colors.Havelock}) repeat-x;
  border: 1px solid rgb(0 0 0/ 10%);
  border-color: ${Colors.Havelock};
  border-radius: 0.2rem;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  text-align: center;

  :hover {
    background: #4282d1 linear-gradient(180deg, #4282d1, #699cda) repeat-x;
    border-color: #4282d1;
  }
`;

export const ButtonLink = styled(Link)`
  background-color: transparent;
  border: 1px solid rgb(0 0 0/ 10%);
  border-color: 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0.2rem;
  color: rgb(44 77 118/ 80%);
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  text-align: center;

  :hover {
    background: #d0d2d5 linear-gradient(180deg, ${Colors.Porcelain}, #d0d2d5)
      repeat-x;
    border-color: #d0d2d5;
    text-decoration: none;
  }
`;
