import { gql } from "@apollo/client";
import {
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
  SEARCH_CARD_PARALLEL_LISTING_FIELDS,
} from "../fragments";

const SEARCH_CARD_PARALLELS = gql`
  ${GRADE_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  ${SEARCH_CARD_PARALLEL_LISTING_FIELDS}
  query SEARCH_CARD_PARALLELS(
    $query: String!
    $sport: String
    $era: String
    $productType: String
    $minItems: Decimal
    $maxItems: Decimal
    $minListings: Decimal
    $maxListings: Decimal
    $minOffers: Decimal
    $maxOffers: Decimal
    $startYear: Decimal
    $endYear: Decimal
    $minSerialLimit: Decimal
    $maxSerialLimit: Decimal
    $orderBy: String!
    $first: Int = 48
    $cursor: String
  ) {
    cardParallels: cardParallelSearch(
      query: $query
      sport: $sport
      era: $era
      productType: $productType
      minItems: $minItems
      maxItems: $maxItems
      minListings: $minListings
      maxListings: $maxListings
      minOffers: $minOffers
      maxOffers: $maxOffers
      startYear: $startYear
      endYear: $endYear
      minSerialLimit: $minSerialLimit
      maxSerialLimit: $maxSerialLimit
      orderBy: $orderBy
      first: $first
      after: $cursor
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      cardParallelStats {
        totalItemCount
        totalListingCount
      }
      edges {
        node {
          ...searchCardParallelListingFields
          gradedcardparallelSet(first: 3) {
            totalCount
            edges {
              node {
                ...gradedCardParallelFields
                grade {
                  ...gradeFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default SEARCH_CARD_PARALLELS;
