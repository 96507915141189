import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import GradeList from "./GradeList";

const SelectCardGrade = ({
  grades,
  selectedGradeId,
  setSelectedGradeId,
  productType,
}) => {
  // Split grades by availability in our vault
  const availableGrades = grades.filter(
    (grade) =>
      grade.gradedCardParallel && grade.gradedCardParallel.itemCount > 0,
  );
  const unavailableGrades = grades.filter(
    (grade) => availableGrades.indexOf(grade) === -1,
  );

  // Hide unavailable by default unless
  // - there are no available grades to show
  // - selected grade is unavailable
  const [showUnavailableGrades, setShowUnavailableGrades] = useState(
    !availableGrades.length ||
      unavailableGrades.find((g) => g.id === selectedGradeId),
  );

  return (
    <>
      <GradeList
        grades={availableGrades}
        selectedGradeId={selectedGradeId}
        setSelectedGradeId={setSelectedGradeId}
        productType={productType}
        configAlwaysExpanded
      />

      {unavailableGrades.length ? (
        <div className="text-center">
          <button
            className="btn btn-link p-0 my-2"
            onClick={() => setShowUnavailableGrades(!showUnavailableGrades)}
          >
            {showUnavailableGrades ? (
              <>
                Hide unavailable grades{" "}
                <FontAwesomeIcon icon={faChevronUp} className="fa-fw" />
              </>
            ) : (
              <>
                Show unavailable grades{" "}
                <FontAwesomeIcon icon={faChevronDown} className="fa-fw" />
              </>
            )}
          </button>
        </div>
      ) : null}

      {showUnavailableGrades && (
        <div className="mt-4">
          <p>
            {
              "We don't have these grades available in the vault yet. You can still make offers for whenever they are submitted however."
            }
          </p>
          <GradeList
            grades={unavailableGrades}
            selectedGradeId={selectedGradeId}
            setSelectedGradeId={setSelectedGradeId}
            productType={productType}
          />
        </div>
      )}
    </>
  );
};

export default SelectCardGrade;
