import { gql } from "@apollo/client";

const SELL_ITEMS = gql`
  mutation sellItems(
    $cardParallelId: ID!
    $gradeId: ID!
    $minimumSalePrice: Float!
    $quantity: Int!
    $closeSellOrders: Boolean!
    $expiresAfter: ExpiresAfter
  ) {
    sellItems(
      cardParallelId: $cardParallelId
      gradeId: $gradeId
      minimumSalePrice: $minimumSalePrice
      quantity: $quantity
      closeSellOrders: $closeSellOrders
      expiresAfter: $expiresAfter
    ) {
      soldCount
      listedCount
      errors {
        field
        message
      }
    }
  }
`;

export default SELL_ITEMS;
