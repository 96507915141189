import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";

export const LabelTooltipVariants = {
  light: "light",
  dark: "dark",
};

const StyledButton = styled.button`
  display: inline-block;
  padding: 0 0 0.125rem 0;
  margin: 0;
  outline: 0;
  border: 0;
  background: none;

  color: ${(props) =>
    props.variant === LabelTooltipVariants.light ? "#00000040" : "#ffffff50"};

  &:hover,
  &:focus,
  &:active {
    color: #4e8ad4;
  }
`;

const LabelTooltip = ({
  variant = LabelTooltipVariants.light,
  className,
  tooltipContent,
}) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    overlay={
      <Popover id="grade-info-popover">
        <Popover.Content>{tooltipContent}</Popover.Content>
      </Popover>
    }
    placement={"top"}
    flip
  >
    <StyledButton className={`${className}`} variant={variant}>
      <FontAwesomeIcon icon={faQuestionCircle} />
    </StyledButton>
  </OverlayTrigger>
);

export default LabelTooltip;
