import { gql } from "@apollo/client";

const REMOVE_ALL_ITEMS_FROM_DRAFT_SHIPPING_REQUEST = gql`
  mutation removeAllItemsFromDraftShippingRequest {
    removeAllItemsFromDraftShippingRequest {
      itemCount
      errors {
        field
        message
      }
    }
  }
`;

export default REMOVE_ALL_ITEMS_FROM_DRAFT_SHIPPING_REQUEST;
