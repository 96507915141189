import { useQuery } from "@apollo/client";
import { capitalize } from "@material-ui/core";
import moment from "moment";
import { rgba } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { PRODUCT_TYPES } from "../../../api/types";
import GET_SALES from "../../../api/queries/GET_SALES";
import placeholderBox from "../../../images/placeholder-box.png";
import placeholderCard from "../../../images/placeholder-card.png";
import { logAmplitudeEvent } from "../../../utils/analytics";
import { MARKETPLACE_EVENTS } from "../../../utils/analytics/events";
import { financial } from "../../../utils/common";
import { displayNameForGrade } from "../../../utils/grades";

import ItemSaleCarouselWidget from "./ItemSaleCarouselWidget";
import CardParallelProductPhoto from "../../CardParallelProductPhoto";
import { WidgetThemes } from "./Widget";

const Astronaut = "#2c4d76";
const Havelock = "#4e8ad4";
const ThemeColor = "#ffffff";
const widgetName = "LatestSalesCarousel";

const OuterContainer = styled.div`
  transition: all 200ms ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }
`;

const StyledProductPhotoOuter = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 140%;
  margin-bottom: 1rem;
`;

const StyledProductPhotoInner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
  overflow: hidden;
`;

const CardName = styled.p`
  font-size: 0.75rem;
  color: ${rgba("#ffffff", 0.8)};
  line-height: 1rem;
  height: calc(1rem * 3);
  margin-bottom: 0.25rem;
  overflow-y: hidden;
  position: relative;

  @media (min-width: 28rem) {
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: calc(1.25rem * 3);
  }

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 4rem;
    height: 1rem;
    background: linear-gradient(
      to right,
      ${rgba(Astronaut, 0)},
      ${Astronaut} 100%
    );

    @media (min-width: 28rem) {
      height: 1.25rem;
    }
  }
`;

const CardGrade = styled.p`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${rgba("#ffffff", 1)};
  white-space: nowrap;
  margin-bottom: 0.25rem;
  overflow-y: hidden;
`;

const CardSaleDate = styled.p`
  font-size: 0.75rem;
  color: ${rgba("#ffffff", 0.6)};
  margin-bottom: 0;
`;

const CardPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
`;

const CardPrice = styled.p`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${rgba(ThemeColor, 1.0)};
  margin-bottom: 0;
  white-space: nowrap;
`;

const CardQuantity = styled.p`
  font-size: 0.875rem;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 0;
  margin-top: -3px;
  color: ${Havelock};
  border: 1px solid ${Havelock};
  background: ${rgba("#000000", 0.1)};
  border-radius: 4px;
  padding: 0 0.375rem;
`;

const ItemSaleCarouselWidgetItem = ({ sale, index }) => {
  const linkPath = `/marketplace/cards/${sale.cardParallel.slug}?grade=${sale.grade.id}`;

  return (
    <Link
      key={index}
      to={linkPath}
      className={"btn text-left p-0 m-0"}
      onClick={() =>
        logAmplitudeEvent(MARKETPLACE_EVENTS.STOREFRONT.WIDGET_ITEM_CLICKED, {
          "Widget Name": widgetName,
          "Widget Item Type": "Item sale",
          "Widget Item Name": sale.cardParallel.name,
          "Widget Item Position": index,
        })
      }
    >
      <OuterContainer>
        <StyledProductPhotoOuter>
          <StyledProductPhotoInner productType={sale.cardParallel.productType}>
            <CardParallelProductPhoto
              productType={sale.cardParallelproductType}
              image={
                sale.cardParallel.productType === PRODUCT_TYPES.BOX
                  ? sale.cardParallel.boxProductPhotoSmall || placeholderBox
                  : sale.cardParallel.frontSidePhotoSmall || placeholderCard
              }
              grade={sale.grade}
            />
          </StyledProductPhotoInner>
        </StyledProductPhotoOuter>
        <CardGrade>{displayNameForGrade(sale.grade)}</CardGrade>
        <CardName>{sale.cardParallel.name}</CardName>
        <CardSaleDate>
          {capitalize(moment(sale.saleDate).fromNow())}{" "}
        </CardSaleDate>
        <CardPriceContainer>
          <CardPrice>${financial(sale.price)}</CardPrice>
          {sale.quantity > 1 && <CardQuantity>x{sale.quantity}</CardQuantity>}
        </CardPriceContainer>
      </OuterContainer>
    </Link>
  );
};

const LatestSalesItemCarouselWidget = ({ sport = "", era = "" }) => {
  const { data, loading, error } = useQuery(GET_SALES, {
    variables: { sport: sport, era: era, first: 42 },
  });

  return (
    <ItemSaleCarouselWidget
      id={"latest-sales-item-carousel-widget"}
      widgetName={widgetName}
      title="Latest sales"
      subtitle="The cards other investors are buying right now"
      linkTitle="View more"
      linkUrl={`/marketplace/cards?sport=${sport}&era=${era}&sort=latest-sales`}
      theme={WidgetThemes.dark}
      themeColor={ThemeColor}
      loading={loading}
      error={error}
    >
      {data &&
        data.sales.map((sale, i) => (
          <ItemSaleCarouselWidgetItem key={i} sale={sale} index={i} />
        ))}
    </ItemSaleCarouselWidget>
  );
};

export default LatestSalesItemCarouselWidget;
