import { useMutation } from "@apollo/client";

import { BidCreate } from "../../../generated/schema";
import { logAmplitudeEvent } from "../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../utils/analytics/events";

import { CREATE_BID } from "../api/CREATE_BID";
import { AuctionAnalytics } from "../types";
import { useUpdateCache } from "./useUpdateCache";
interface MutationResponse {
  bidCreate: BidCreate;
}

export const useCreateBid = (auctionAnalytics: AuctionAnalytics = {}) => {
  const { addBid } = useUpdateCache();

  const [createBidRequest] = useMutation<MutationResponse>(CREATE_BID, {
    onCompleted: ({ bidCreate: { bid, errors, lot } }) => {
      const eventProperties = {
        "Auction Name": auctionAnalytics.name,
        "Auction Slug": auctionAnalytics.slug,
        "Auction State": auctionAnalytics.state,
        "Lot ID": lot?.id,
        "Lot Name": lot?.name,
        "Lot Order": lot?.order,
        "Lot State": lot?.state,
      };

      if (errors && errors.length > 0) {
        logAmplitudeEvent(BREAKS_EVENTS.AUCTION.BID_FAILED, {
          "Error Fields": errors.map((e) => e?.field),
          "Error Messages": errors.map((e) => e?.message),
          "Bid Price": null,
          ...eventProperties,
        });
      } else {
        logAmplitudeEvent(BREAKS_EVENTS.AUCTION.BID_SUCCEEDED, {
          "Error Fields": null,
          "Error Messages": null,
          "Bid Price": bid?.bidPrice,
          ...eventProperties,
        });

        addBid({
          bid: {
            id: bid?.id,
            bidPrice: bid?.bidPrice,
            user: {
              id: bid?.user.id,
              username: bid?.user.username,
            },
            lot: {
              biddingEndsAfter: lot?.biddingEndsAfter,
              bidPriceIncrement: lot?.bidPriceIncrement,
              highestBidPrice: lot?.highestBidPrice,
              id: lot?.id,
              minimumNextBidPrice: lot?.minimumNextBidPrice,
              name: lot?.name,
              order: lot?.order,
            },
          },
        });
      }
    },
  });

  return { createBidRequest };
};
