import { useMutation } from "@apollo/client";

import { logAmplitudeEvent } from "../../utils/analytics";
import { SELLING_EVENTS } from "../../utils/analytics/events";

import GET_CARD_PARALLEL from "../../api/queries/GET_CARD_PARALLEL";
import GET_GRADED_CARD_PARALLEL from "../../api/queries/GET_GRADED_CARD_PARALLEL";
import GET_MY_GROUPED_LISTINGS from "../../api/queries/GET_MY_GROUPED_LISTINGS";
import GET_MY_ACTIVITY_METADATA from "../../api/queries/GET_MY_ACTIVITY_METADATA";
import GROUPED_LISTING_CLOSE from "../../api/mutations/GROUPED_LISTING_CLOSE";

import ListingListGradeItem from "../Activity/ListingListGradeItem";

const MyListingsSection = ({ slug, gradedCardParallelId, groupedListings }) => {
  const mutationRefetches = [
    { query: GET_MY_ACTIVITY_METADATA },
    { query: GET_MY_GROUPED_LISTINGS, variables: { searchQuery: "" } },
    { query: GET_CARD_PARALLEL, variables: { slug } },
    {
      query: GET_GRADED_CARD_PARALLEL,
      variables: { id: gradedCardParallelId },
    },
  ];

  const [groupedListingClose] = useMutation(GROUPED_LISTING_CLOSE, {
    refetchQueries: mutationRefetches,
    onCompleted({ groupedListingClose }) {
      if (groupedListingClose.errors && groupedListingClose.errors.length > 0) {
        logAmplitudeEvent(SELLING_EVENTS.EXISTING_LISTING.CANCEL.FAILED, {
          Error: groupedListingClose.errors[0],
        });
      } else {
        logAmplitudeEvent(SELLING_EVENTS.EXISTING_LISTING.CANCEL.CANCELED);
      }
    },
  });

  return (
    <div className="row">
      {groupedListings.map((groupedListing) => (
        <div className="col-12 mt-3" key={groupedListing.id}>
          <ListingListGradeItem
            slug={slug}
            gradedCardParallelId={gradedCardParallelId}
            groupedListing={groupedListing}
            groupedListingClose={groupedListingClose}
            acceptOfferRefetches={mutationRefetches}
          />
        </div>
      ))}
    </div>
  );
};

export default MyListingsSection;
