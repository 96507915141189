import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { Colors } from "../../../../themes/Default";

export const Description = styled(ReactMarkdown)`
  margin-top: 1rem;
`;

export const FormatContainer = styled.div`
  margin-top: 1.5rem;
`;

export const FormatDescription = styled.p`
  margin-bottom: 0;
`;

export const FormatHeader = styled.h2`
  color: ${Colors.Astronaut}c0;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

export const FormatName = styled.h3`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: bold;
`;

export const LotLink = styled.a`
  display: block;
  font-weight: bold;
  margin-top: 1rem;
  padding: 1rem 0;
`;
