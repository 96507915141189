import { AddressValues } from "../types";

export const getformValues = (values: AddressValues) => {
  const addressLines =
    values.line1 + (values.line2 !== undefined ? "\n" + values.line2 : "");

  return {
    id: values.id,
    description: values.description,
    name: values.name,
    addressLines: addressLines,
    locality: values.locality,
    region: values.region,
    postalCode: values.postalCode,
    country: values.country,
    role: "SHIPPING",
  };
};
