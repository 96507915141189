import styled, { css } from "styled-components";

import { Status } from "./WarningAlert";

interface Props {
  status: Status;
}

export const Container = styled.div<Props>`
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  position: relative;

  ${({ status }) => {
    switch (status) {
      case "Danger":
        return css`
          background: #f8d7da linear-gradient(180deg, #f9dde0, #f8d7da) repeat-x;
          border-color: #f5c6cb;
          color: #721c24;
        `;

      case "Dark":
        return css`
          background: #d5dbe4 linear-gradient(180deg, #dbe0e8, #d5dbe4) repeat-x;
          border-color: #c4cdd9;
          color: #17283d;
        `;

      case "Info":
        return css`
          background: #d1ecf1 linear-gradient(180deg, #d8eff3, #d1ecf1) repeat-x;
          border-color: #bee5eb;
          color: #0c5460;
        `;

      case "Light":
        return css`
          background: #fdfdfe linear-gradient(180deg, #fdfdfe, #fdfdfe) repeat-x;
          border-color: #fcfdfd;
          color: #808081;
        `;

      case "Primary":
        return css`
          background: #dce8f6 linear-gradient(180deg, #e1ebf7, #dce8f6) repeat-x;
          border-color: #cddef3;
          color: #29486e;
        `;

      case "Secondary":
        return css`
          background: #f9fafb linear-gradient(180deg, #fafbfc, #f9fafb) repeat-x;
          border-color: #f7f8f9;
          color: #75787a;
        `;

      case "Success":
        return css`
          background: #def0de linear-gradient(180deg, #e3f2e3, #def0de) repeat-x;
          border-color: #d0ead1;
          color: #2e5e30;
        `;

      case "Warning":
        return css`
          background: #fff3cd linear-gradient(180deg, #fff5d5, #fff3cd) repeat-x;
          border-color: #ffeeba;
          color: #856404;
        `;
    }
  }}
`;
