import styled from "styled-components";

import { pluralize } from "../../utils/content";

const Badge = styled.div`
  font-size: 0.75rem;
  border-radius: 2px;
  border: 1px solid #58b55c;
  color: #58b55c;
  padding: 0 0.25rem;
`;

const MyOffersBadge = ({ offersCount }) => {
  return (
    <Badge className="badge-row-item mt-2">
      You made {pluralize("offer", offersCount)}
    </Badge>
  );
};

export default MyOffersBadge;
