import { gql } from "@apollo/client";

const EXTEND_OFFER_EXPIRY_DATE = gql`
  mutation EXTEND_OFFER_EXPIRY_DATE(
    $groupedBidId: ID!
    $extendExpiry: ExpiresAfter!
  ) {
    buyOrderExtendExpiryDate(
      groupedBidId: $groupedBidId
      extendExpiry: $extendExpiry
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export default EXTEND_OFFER_EXPIRY_DATE;
