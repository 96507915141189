import { Observable } from 'graphql-typed-client'

export interface Query {
  myPaymentMethods: (StripePaymentMethodNode | null)[] | null
  channels: ChannelNodeConnection | null
  channel: ChannelNode | null
  streams: StreamNodeConnection | null
  stream: StreamNode | null
  events: EventNodeConnection | null
  event: EventNode | null
  myShippingRequests: ShippingRequestNodeConnection | null
  myShippingRequest: ShippingRequestNode | null
  myDraftShippingRequest: ShippingRequestNode | null
  player: PlayerNode | null
  upcomingFixtures: FixtureNodeConnection | null
  cardParallelSearch: CardParallelSearchResultConnection | null
  defaultNotificationPreferences: (UserNotificationPreferenceNode | null)[] | null
  myNotificationPreferences: (UserNotificationPreferenceNode | null)[] | null
  sales: (RecentSaleNode | null)[] | null
  mySales: ItemOwnershipNodeConnection | null
  myPastPurchases: HistoricalPurchaseNodeConnection | null
  myGroupedBids: GroupedBidNodeConnection | null
  myGroupedListings: GroupedListingNodeConnection | null
  gradedCardParallels: GradedCardParallelNodeConnection | null
  gradedCardParallel: GradedCardParallelNode | null
  marketIndices: MarketIndexNodeConnection | null
  marketIndex: MarketIndexNode | null
  marketWidgets: (MarketWidgetNode | null)[] | null
  grades: GradeNodeConnection | null
  myWishlistCards: CardParallelNodeConnection | null
  myWishlistPlayers: PlayerNodeConnection | null
  userCollection: ItemOwnershipNodeConnection | null
  portfolioValue: (PortfolioValueNode | null)[] | null
  myCollection: ItemOwnershipNodeConnection | null
  myInDraftShippingRequestCollection: ItemOwnershipNodeConnection | null
  cardParallel: CardParallelNode | null
  cardParallels: CardParallelNodeConnection | null
  myCardParallels: CardParallelNodeConnection | null
  cardSets: CardSetNodeConnection | null
  boxBreak: BoxBreakNode | null
  boxBreaks: BoxBreakNodeConnection | null
  myBoxBreaks: BoxBreakNodeConnection | null
  myPersonalBreakRequests: PersonalBreakRequestNodeConnection | null
  remints: BoxBreakNodeConnection | null
  remint: BoxBreakNode | null
  auctions: AuctionNodeConnection | null
  auction: AuctionNode | null
  lots: LotNodeConnection | null
  myBiddingLots: LotNodeConnection | null
  lot: LotNode | null
  bid: BidNode | null
  announcementBanners: (AnnouncementNode | null)[] | null
  myAddresses: (AddressNode | null)[] | null
  me: UserType | null
  myAccount: UserType | null
  promotion: PromotionType | null
  __typename: 'Query'
}

export interface StripePaymentMethodNode extends Node {
  paymentMethodId: String
  brand: String
  lastFourDigits: String
  expiryMonth: String
  expiryYear: String
  offSession: Boolean
  defaultPaymentMethod: Boolean
  /** The ID of the object. */
  id: ID
  __typename: 'StripePaymentMethodNode'
}

/** An object with an ID */
export interface Node {
  /** The ID of the object. */
  id: ID
  __typename:
    | 'StripePaymentMethodNode'
    | 'ChannelNode'
    | 'EventNode'
    | 'StreamNode'
    | 'BoxBreakNode'
    | 'FormatNode'
    | 'BreakLocationNode'
    | 'BoxNode'
    | 'GradedCardParallelNode'
    | 'CardParallelNode'
    | 'SportNode'
    | 'TeamNode'
    | 'CardProgramNode'
    | 'CardSetNode'
    | 'CardSetParallelNode'
    | 'CardParallelSearchResult'
    | 'PlayerCardNode'
    | 'TeamPlayerNode'
    | 'PlayerNode'
    | 'FixtureTeamPlayerNode'
    | 'FixtureNode'
    | 'CardFamilyNode'
    | 'ItemNode'
    | 'GradeNode'
    | 'ShippingItemNode'
    | 'ShippingRequestNode'
    | 'BuyOrderNode'
    | 'GroupedBidNode'
    | 'SellOrderNode'
    | 'GroupedListingNode'
    | 'LotItemNode'
    | 'LotNode'
    | 'AuctionNode'
    | 'AuctionPhotoNode'
    | 'BidNode'
    | 'LotPhotoNode'
    | 'HitNode'
    | 'SpotNode'
    | 'PersonalBreakRequestNode'
    | 'ItemOwnershipNode'
    | 'AddressNode'
    | 'ParticipantNode'
    | 'WishlistItemNode'
    | 'HistoricalPurchaseNode'
    | 'MarketIndexComponentNode'
    | 'MarketIndexNode'
    | 'MarketIndexSnapshotNode'
    | 'MarketIndexComponentSnapshotNode'
    | 'BoxBreakPhotoNode'
    | 'RemintNFTNode'
    | 'UserNotificationPreferenceNode'
    | 'MarketWidgetNode'
    | 'AnnouncementNode'
    | 'AnnouncementStyleNode'
    | 'PromotionType'
}

/** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
export type ID = string

/** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
export type String = string

/** The `Boolean` scalar type represents `true` or `false`. */
export type Boolean = boolean

/** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
export type Int = number

export interface ChannelNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (ChannelNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'ChannelNodeConnection'
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface PageInfo {
  /** When paginating forwards, are there more items? */
  hasNextPage: Boolean
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Boolean
  /** When paginating backwards, the cursor to continue. */
  startCursor: String | null
  /** When paginating forwards, the cursor to continue. */
  endCursor: String | null
  __typename: 'PageInfo'
}

/** A Relay edge containing a `ChannelNode` and its cursor. */
export interface ChannelNodeEdge {
  /** The item at the end of the edge */
  node: ChannelNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'ChannelNodeEdge'
}

export interface ChannelNode extends Node {
  /** The ID of the object. */
  id: ID
  name: String
  /** User-facing description of this channel and its focus. */
  description: String
  /** URL identifier for this channel. */
  slug: String
  eventSet: EventNodeConnection
  streamSet: StreamNodeConnection
  nextUpcomingStreamSet: StreamNodeConnection | null
  nextUpcomingStream: StreamNode | null
  liveStreamSet: StreamNodeConnection | null
  liveStream: StreamNode | null
  recentlyEndedStreamSet: StreamNodeConnection | null
  recentlyEndedStream: StreamNode | null
  __typename: 'ChannelNode'
}

export interface EventNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (EventNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'EventNodeConnection'
}

/** A Relay edge containing a `EventNode` and its cursor. */
export interface EventNodeEdge {
  /** The item at the end of the edge */
  node: EventNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'EventNodeEdge'
}

export interface EventNode extends Node {
  /** The ID of the object. */
  id: ID
  eventType: EventEventType
  channel: ChannelNode
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  stream: StreamNode | null
  /** Date and time at which this event is scheduled to begin. Does not enforce when it starts; only used as a guideline. May be left blank if not yet scheduled. */
  scheduledStartTime: DateTime | null
  /** Estimated duration of this event. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: Float
  /** Optional event-specific replay video, hosted on YouTube. If populated, once the event has ended it will show this video instead of the video from the associated Stream. Leave blank to just continue to show the full Stream video. */
  replayYoutubeVideoId: String
  state: EventState
  boxBreak: BoxBreakNode | null
  name: String | null
  __typename: 'EventNode'
}

export enum EventEventType {
  /** Break */
  BREAK = 'BREAK',
  /** Auction */
  AUCTION = 'AUCTION',
}

export interface StreamNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Display name for this stream. May be named after the date, number in a series, or just a descriptive title for the content being included in the stream. */
  name: String
  /** User-facing description of this stream and and what it will be covering. e.g. "Tonight we will be ripping 4 group breaks including a PYT Bowman Chrome break! Personal breaks on-request throughout the night!" */
  description: String
  channel: ChannelNode
  /** Date and time at which this stream is scheduled to begin. Does not enforce when it starts; only used as a guideline. */
  scheduledStartTime: DateTime
  /** Estimated duration of this stream. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: Float
  /** The 11-digit YouTube video ID for this stream. */
  youtubeVideoId: String
  state: StreamState
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  eventSet: EventNodeConnection
  nextUpcomingEventSet: EventNodeConnection | null
  nextUpcomingEvent: EventNode | null
  liveEventSet: EventNodeConnection | null
  liveEvent: EventNode | null
  recentlyEndedEventSet: EventNodeConnection | null
  recentlyEndedEvent: EventNode | null
  __typename: 'StreamNode'
}

/**
 * The `DateTime` scalar type represents a DateTime
 * value as specified by
 * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
 */
export type DateTime = any

/** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
export type Float = number

export enum StreamState {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Deleted draft */
  DELETED_DRAFT = 'DELETED_DRAFT',
  /** Upcoming */
  UPCOMING = 'UPCOMING',
  /** Live */
  LIVE = 'LIVE',
  /** Ended */
  ENDED = 'ENDED',
  /** Canceled */
  CANCELED = 'CANCELED',
}

export enum EventState {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Deleted draft */
  DELETED_DRAFT = 'DELETED_DRAFT',
  /** Upcoming */
  UPCOMING = 'UPCOMING',
  /** Live */
  LIVE = 'LIVE',
  /** Ended */
  ENDED = 'ENDED',
  /** Canceled */
  CANCELED = 'CANCELED',
}

export interface BoxBreakNode extends Node {
  /** The ID of the object. */
  id: ID
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  format: FormatNode
  /** Display name for this break (e.g. "2021 Topps Definitive Baseball 3x Hobby Box Break: 2 Random Teams") */
  name: String
  /** URL identifier for this break */
  slug: String
  /** Assign a featured rank to promote this break. Higher values take priority. Use sparingly; leave blank for most breaks. */
  featuredRank: Int | null
  /** A text description of this break, for example outlining the break format (random teams, PYT...) and products being broken. */
  description: String
  /** Date and time at which this break is scheduled to begin. Presented to users as approximate. Leave blank if break is not yet scheduled. */
  scheduledStartTime: DateTime | null
  createdAt: DateTime
  createdBy: UserType | null
  state: BoxBreakState
  /** Date and time at which this break was published onto the live site. */
  publishedAt: DateTime | null
  /** Date and time at which the break opened for entries. */
  openedAt: DateTime | null
  /** Date and time at which the live break began. */
  startedAt: DateTime | null
  /** Date and time at which the break stopped accepting new entries. */
  lockedAt: DateTime | null
  /** Date and time at which the live break concluded. */
  endedAt: DateTime | null
  /** Date and time at which the break's items completed processing. */
  settledAt: DateTime | null
  /** The 11-digit video ID for the YouTube video this break will be streamed on. */
  youtubeVideoId: String
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: String
  /** The name for the Twitch channel this break will be streamed on. */
  twitchChannelName: String
  breakLocation: BreakLocationNode
  /** Choose where the event is visible and who can access it. */
  visibility: BoxBreakVisibility
  /** Optional restriction on how many spots a participant can claim. */
  maxSpotsPerUser: Int | null
  /** Optional URL to link to buy page for reMints */
  purchaseOverrideUrl: String
  /** Break in which this box is going to be or was opened. */
  boxSet: BoxNodeConnection
  boxbreakphotoSet: BoxBreakPhotoNodeConnection
  spotSet: SpotNodeConnection
  /** The scheduled personal break that the user would like their box broken as part of. */
  personalbreakrequestSet: PersonalBreakRequestNodeConnection
  /** Break in which this nft item is going to be or was opened. */
  remintnftSet: RemintNFTNodeConnection
  event: EventNode | null
  myParticipant: ParticipantNode | null
  hitSet: HitNodeConnection | null
  targetPlayers: PlayerNodeConnection | null
  spotsTotalCount: Int | null
  spotsAvailableCount: Int | null
  spotsFilledCount: Int | null
  spotsMinPrice: Float | null
  spotsMaxPrice: Float | null
  spotsCurrency: String | null
  allowPersonalBreakRequests: Boolean | null
  __typename: 'BoxBreakNode'
}

export interface FormatNode extends Node {
  /** The ID of the object. */
  id: ID
  /** A descriptive name for this break format (e.g. "Random Team" or "Hit Draft") */
  name: String
  /** Optional description of this break format, to inform users about how it works and why they might want to choose this format over another. */
  description: String
  /** Indicates whether it is a group break, open to multiple participants, or a personal break for a single user. */
  breakCategory: FormatBreakCategory
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  boxbreakSet: BoxBreakNodeConnection
  __typename: 'FormatNode'
}

export enum FormatBreakCategory {
  /** Group */
  GROUP = 'GROUP',
  /** Personal */
  PERSONAL = 'PERSONAL',
  /** ReMint */
  REMINT = 'REMINT',
}

export interface BoxBreakNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (BoxBreakNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'BoxBreakNodeConnection'
}

/** A Relay edge containing a `BoxBreakNode` and its cursor. */
export interface BoxBreakNodeEdge {
  /** The item at the end of the edge */
  node: BoxBreakNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'BoxBreakNodeEdge'
}

export interface UserType {
  id: ID
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: String
  firstName: String
  lastName: String
  email: String
  userprofile: UserProfileType | null
  balance: Float | null
  isStaff: Boolean | null
  __typename: 'UserType'
}

export interface UserProfileType {
  id: ID
  marketingEmails: Boolean
  buyTargetPercentage: Int
  sellTargetPercentage: Int
  dailyDigestHour: Int
  isCollectionPublic: Boolean
  __typename: 'UserProfileType'
}

export enum BoxBreakState {
  /** Template */
  TEMPLATE = 'TEMPLATE',
  /** Draft */
  DRAFT = 'DRAFT',
  /** Deleted draft */
  DELETED_DRAFT = 'DELETED_DRAFT',
  /** Published (locked) */
  PUBLISHED_LOCKED = 'PUBLISHED_LOCKED',
  /** Published (open) */
  PUBLISHED_OPEN = 'PUBLISHED_OPEN',
  /** Live (open) */
  LIVE_OPEN = 'LIVE_OPEN',
  /** Live (locked) */
  LIVE_LOCKED = 'LIVE_LOCKED',
  /** Ended */
  ENDED = 'ENDED',
  /** Settled */
  SETTLED = 'SETTLED',
  /** Canceled */
  CANCELED = 'CANCELED',
}

export interface BreakLocationNode extends Node {
  /** The ID of the object. */
  id: ID
  /** e.g. StarStock New York office, Partner Studio... */
  name: String
  /** Enable to allow users to request an item in their collection be broken during a personal break. */
  allowPersonalBreakRequests: Boolean
  boxbreakSet: BoxBreakNodeConnection
  __typename: 'BreakLocationNode'
}

export enum BoxBreakVisibility {
  /** Public */
  PUBLIC = 'PUBLIC',
  /** Unlisted */
  UNLISTED = 'UNLISTED',
}

export interface BoxNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (BoxNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'BoxNodeConnection'
}

/** A Relay edge containing a `BoxNode` and its cursor. */
export interface BoxNodeEdge {
  /** The item at the end of the edge */
  node: BoxNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'BoxNodeEdge'
}

export interface BoxNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Mandatory connection to a product type in the wider card database. */
  gradedCardParallel: GradedCardParallelNode
  /** Optional display name for this this breakable product (e.g. Prizm Football 2020 Hobby Box). If blank, name is taken from associated product type. */
  name: String
  /** Break in which this box is going to be or was opened. */
  boxBreak: BoxBreakNode | null
  createdAt: DateTime
  createdBy: UserType | null
  __typename: 'BoxNode'
}

export interface GradedCardParallelNode extends Node {
  /** The ID of the object. */
  id: ID
  cardParallel: CardParallelNode | null
  grade: GradeNode
  itemCount: Int | null
  itemFirstCreatedAt: DateTime | null
  openBuyOrderCount: Int | null
  openBuyOrderMaxPriceCurrency: GradedCardParallelOpenBuyOrderMaxPriceCurrency | null
  openBuyOrderMaxPrice: Float | null
  openBuyOrderMaxCreatedAt: DateTime | null
  openSellOrderCount: Int | null
  openSellOrderMinPriceCurrency: GradedCardParallelOpenSellOrderMinPriceCurrency | null
  openSellOrderMinPrice: Float | null
  openSellOrderMaxCreatedAt: DateTime | null
  lastSaleDate: DateTime | null
  lastSalePriceCurrency: GradedCardParallelLastSalePriceCurrency | null
  lastSalePrice: Float | null
  itemSet: ItemNodeConnection
  historicalpurchaseSet: HistoricalPurchaseNodeConnection
  buyorderSet: BuyOrderNodeConnection
  sellorderSet: SellOrderNodeConnection
  marketindexcomponentSet: MarketIndexComponentNodeConnection
  lotitemSet: LotItemNodeConnection
  /** Mandatory connection to a product type in the wider card database. */
  boxSet: BoxNodeConnection
  /** The type and grade of the card hit. As these are pack-fresh pulls, we typically assume StarStock A grade. */
  hitSet: HitNodeConnection
  items: ItemNodeConnection | null
  openSellOrders: (SellOrderNode | null)[] | null
  openBuyOrders: (BuyOrderNode | null)[] | null
  myItems: (ItemNode | null)[] | null
  myItemCount: Int | null
  myOpenSellOrderCount: Int | null
  myOpenSellOrderMinPrice: Float | null
  myOpenBuyOrderCount: Int | null
  mySuspendedBuyOrderCount: Int | null
  myGroupedBids: (GroupedBidNode | null)[] | null
  myGroupedListings: (GroupedListingNode | null)[] | null
  __typename: 'GradedCardParallelNode'
}

export interface CardParallelNode extends Node {
  /** The ID of the object. */
  id: ID
  sport: SportNode
  productType: CardParallelProductType
  era: CardParallelEra
  playerCard: PlayerCardNode
  parallel: CardSetParallelNode | null
  name: String
  slug: String
  serialLimit: Int | null
  trendingRank: Int | null
  frontSidePhoto: String | null
  reverseSidePhoto: String | null
  boxProductPhoto: String | null
  ebaySearchTerm: String
  boxRetailPriceCurrency: CardParallelBoxRetailPriceCurrency | null
  boxRetailPrice: Float | null
  /** Estimated time required to open this box in a break, in minutes. Only relevant for boxes; leave blank for cards. */
  boxBreakDuration: Int | null
  boxPacksPerBox: Int | null
  boxCardsPerPack: Int | null
  /** Optional text description of this product (e.g. target players / parallels / autos in a box, historic importance of card, etc...) */
  description: String
  itemSet: ItemNodeConnection
  wishlistitemSet: WishlistItemNodeConnection
  historicalpurchaseSet: HistoricalPurchaseNodeConnection
  buyorderSet: BuyOrderNodeConnection
  sellorderSet: SellOrderNodeConnection
  gradedcardparallelSet: GradedCardParallelNodeConnection
  cardparallelsearch: CardParallelSearchResult | null
  sportId: String | null
  sellOrderCount: Int | null
  sellOrderMinPrice: Float | null
  buyOrderCount: Int | null
  buyOrderMaxPrice: Float | null
  lastListedAt: DateTime | null
  lastSaleDate: DateTime | null
  firstAddedAt: DateTime | null
  openBuyOrders: (BuyOrderNode | null)[] | null
  openSellOrders: (SellOrderNode | null)[] | null
  myGroupedBids: (GroupedBidNode | null)[] | null
  myGroupedListings: (GroupedListingNode | null)[] | null
  myItems: (ItemNode | null)[] | null
  lastSalePrice: Float | null
  recentPurchases: (HistoricalPurchaseNode | null)[] | null
  myWishlistItems: (WishlistItemNode | null)[] | null
  itemCount: Int | null
  myItemsCount: Int | null
  myValuedItemsCount: Int | null
  myItemsValue: Float | null
  myItemsMaxValue: Float | null
  myListingsCount: Int | null
  myListingsMinPrice: Float | null
  myItemOwnershipsMinStartedAt: DateTime | null
  myItemOwnershipsMaxStartedAt: DateTime | null
  frontSidePhotoThumbnail: String | null
  frontSidePhotoSmall: String | null
  frontSidePhotoMedium: String | null
  reverseSidePhotoThumbnail: String | null
  reverseSidePhotoSmall: String | null
  reverseSidePhotoMedium: String | null
  boxProductPhotoSmall: String | null
  boxProductPhotoMedium: String | null
  salesLast24Hours: Int | null
  __typename: 'CardParallelNode'
}

export interface SportNode extends Node {
  /** Examples: FOOTBALL, BASKETBALL, ICE_HOCKEY */
  key: String
  /** Examples: Football, Basketball, Ice Hockey */
  name: String
  active: Boolean
  /** Should the sport be stand-alone or should it be grouped with other secondary sports */
  primary: Boolean
  teamSet: TeamNodeConnection
  cardprogramSet: CardProgramNodeConnection
  cardparallelSet: CardParallelNodeConnection
  /** The ID of the object. */
  id: ID
  __typename: 'SportNode'
}

export interface TeamNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (TeamNodeEdge | null)[]
  __typename: 'TeamNodeConnection'
}

/** A Relay edge containing a `TeamNode` and its cursor. */
export interface TeamNodeEdge {
  /** The item at the end of the edge */
  node: TeamNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'TeamNodeEdge'
}

export interface TeamNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Examples: Chicago Bulls, LSU Tigers, Oakland Athletics */
  name: String
  /** Abbreviated name used to represent this team in shorter contexts. Examples: Chi, OKC, Sco */
  abbrName: String
  /** City, area or country this team represents. Examples: Chicago, Oakland, Scotland */
  locationName: String
  /** Standalone franchise or brand name. Examples: Bulls, Tigers, Athletics */
  franchiseName: String
  colorPrimary: String
  colorSecondary: String
  colorTertiary: String
  __typename: 'TeamNode'
}

export interface CardProgramNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (CardProgramNodeEdge | null)[]
  __typename: 'CardProgramNodeConnection'
}

/** A Relay edge containing a `CardProgramNode` and its cursor. */
export interface CardProgramNodeEdge {
  /** The item at the end of the edge */
  node: CardProgramNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'CardProgramNodeEdge'
}

export interface CardProgramNode extends Node {
  /** The ID of the object. */
  id: ID
  sport: SportNode
  /** If the program goes across multiple years, use the earliest year */
  year: Int
  /** Examples: 2014-15 Panini Select Basketball; 2019 Topps Chrome Baseball; 2019 Panini Donruss Football */
  name: String
  cardSet: CardSetNode
  cardfamilySet: CardFamilyNodeConnection
  __typename: 'CardProgramNode'
}

export interface CardSetNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Examples: Panini Prizm; Bowman Draft; Topps Chrome; Topps */
  name: String
  primary: Boolean
  cardprogramSet: CardProgramNodeConnection
  cardsetparallelSet: CardSetParallelNodeConnection
  __typename: 'CardSetNode'
}

export interface CardSetParallelNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (CardSetParallelNodeEdge | null)[]
  __typename: 'CardSetParallelNodeConnection'
}

/** A Relay edge containing a `CardSetParallelNode` and its cursor. */
export interface CardSetParallelNodeEdge {
  /** The item at the end of the edge */
  node: CardSetParallelNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'CardSetParallelNodeEdge'
}

export interface CardSetParallelNode extends Node {
  /** The ID of the object. */
  id: ID
  cardSet: CardSetNode
  /** Examples: Base, Orange Refractor, Silver */
  name: String
  badgeTextColor: String | null
  badgeBorderColor: String | null
  badgeGradientStart: String | null
  badgeGradientEnd: String | null
  badgeGradientAngle: String | null
  baseSet: Boolean
  cardparallelSet: CardParallelNodeConnection
  cardparallelsearchSet: CardParallelSearchResultConnection
  __typename: 'CardSetParallelNode'
}

/** The `Decimal` scalar type represents a python Decimal. */
export type Decimal = any

export interface CardParallelNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (CardParallelNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  cardParallelStats: CardParallelStatsNode | null
  myCardParallelStats: CardParallelStatsNode | null
  __typename: 'CardParallelNodeConnection'
}

/** A Relay edge containing a `CardParallelNode` and its cursor. */
export interface CardParallelNodeEdge {
  /** The item at the end of the edge */
  node: CardParallelNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'CardParallelNodeEdge'
}

export interface CardParallelStatsNode {
  totalItemCount: Int | null
  totalListingCount: Int | null
  totalValuedItemCount: Int | null
  totalItemValue: Float | null
  __typename: 'CardParallelStatsNode'
}

export interface CardParallelSearchResultConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (CardParallelSearchResultEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  cardParallelStats: CardParallelStatsNode | null
  __typename: 'CardParallelSearchResultConnection'
}

/** A Relay edge containing a `CardParallelSearchResult` and its cursor. */
export interface CardParallelSearchResultEdge {
  /** The item at the end of the edge */
  node: CardParallelSearchResult | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'CardParallelSearchResultEdge'
}

export interface CardParallelSearchResult extends Node {
  cardParallel: CardParallelNode
  playerCard: PlayerCardNode | null
  parallel: CardSetParallelNode | null
  sport: String | null
  era: String | null
  productType: String | null
  name: String | null
  slug: String | null
  serialLimit: Int | null
  trendingRank: Int | null
  itemCount: Int | null
  sellOrderCount: Int | null
  sellOrderMinPrice: Float | null
  buyOrderCount: Int | null
  buyOrderMaxPrice: Float | null
  firstAddedAt: DateTime | null
  lastListedAt: DateTime | null
  lastSaleDate: DateTime | null
  salesLast24Hours: Int | null
  salesLast7Days: Int | null
  /** The ID of the object. */
  id: ID
  frontSidePhoto: String | null
  frontSidePhotoSmall: String | null
  frontSidePhotoMedium: String | null
  frontSidePhotoThumbnail: String | null
  reverseSidePhoto: String | null
  reverseSidePhotoSmall: String | null
  reverseSidePhotoMedium: String | null
  reverseSidePhotoThumbnail: String | null
  boxProductPhoto: String | null
  boxProductPhotoSmall: String | null
  boxProductPhotoMedium: String | null
  gradedcardparallelSet: GradedCardParallelNodeConnection | null
  __typename: 'CardParallelSearchResult'
}

export interface PlayerCardNode extends Node {
  /** The ID of the object. */
  id: ID
  name: String
  slug: String
  setNumber: String
  teamPlayers: TeamPlayerNodeConnection
  cardFamily: CardFamilyNode
  rookieCard: Boolean
  cardparallelSet: CardParallelNodeConnection
  cardparallelsearchSet: CardParallelSearchResultConnection
  cardParallels: (CardParallelNode | null)[] | null
  __typename: 'PlayerCardNode'
}

export interface TeamPlayerNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (TeamPlayerNodeEdge | null)[]
  __typename: 'TeamPlayerNodeConnection'
}

/** A Relay edge containing a `TeamPlayerNode` and its cursor. */
export interface TeamPlayerNodeEdge {
  /** The item at the end of the edge */
  node: TeamPlayerNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'TeamPlayerNodeEdge'
}

export interface TeamPlayerNode extends Node {
  /** The ID of the object. */
  id: ID
  player: PlayerNode
  team: TeamNode
  playercardSet: PlayerCardNodeConnection
  __typename: 'TeamPlayerNode'
}

export interface PlayerNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Examples: Teddy Bridgewater, Marc Gasol */
  name: String
  slug: String
  startYear: Int
  /** Examples: WR, C-F, OF */
  position: String
  currentTeam: TeamNode | null
  headshotPhoto: String | null
  headshotPhotoXs: String | null
  headshotPhotoSm: String | null
  headshotPhotoMd: String | null
  tradeStats: PlayerTradeStatsNode | null
  cardParallels: CardParallelNodeConnection | null
  myCardParallels: CardParallelNodeConnection | null
  fixtureTeamPlayers: FixtureTeamPlayerNodeConnection | null
  seasonStats: (PlayerStatNode | null)[] | null
  __typename: 'PlayerNode'
}

export interface PlayerTradeStatsNode {
  tradeCount: Int | null
  minTradePrice: Float | null
  maxTradePrice: Float | null
  __typename: 'PlayerTradeStatsNode'
}

export interface FixtureTeamPlayerNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (FixtureTeamPlayerNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'FixtureTeamPlayerNodeConnection'
}

/** A Relay edge containing a `FixtureTeamPlayerNode` and its cursor. */
export interface FixtureTeamPlayerNodeEdge {
  /** The item at the end of the edge */
  node: FixtureTeamPlayerNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'FixtureTeamPlayerNodeEdge'
}

export interface FixtureTeamPlayerNode extends Node {
  /** The ID of the object. */
  id: ID
  fixture: FixtureNode
  teamPlayer: TeamPlayerNode
  trendingRank: Int | null
  fixtureStats: (PlayerStatNode | null)[] | null
  __typename: 'FixtureTeamPlayerNode'
}

export interface FixtureNode extends Node {
  /** The ID of the object. */
  id: ID
  sportRadarId: String
  status: String
  startDate: DateTime
  homeTeam: TeamNode
  awayTeam: TeamNode
  statsImported: Boolean
  fixtureteamplayerSet: FixtureTeamPlayerNodeConnection
  __typename: 'FixtureNode'
}

export interface PlayerStatNode {
  statName: String | null
  statValue: String | null
  __typename: 'PlayerStatNode'
}

export interface PlayerCardNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (PlayerCardNodeEdge | null)[]
  __typename: 'PlayerCardNodeConnection'
}

/** A Relay edge containing a `PlayerCardNode` and its cursor. */
export interface PlayerCardNodeEdge {
  /** The item at the end of the edge */
  node: PlayerCardNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'PlayerCardNodeEdge'
}

export interface CardFamilyNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Examples: Base, Paper Prospect Autographs, Rated Rookies */
  name: String
  program: CardProgramNode
  primary: Boolean
  playercardSet: PlayerCardNodeConnection
  __typename: 'CardFamilyNode'
}

export interface GradedCardParallelNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (GradedCardParallelNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'GradedCardParallelNodeConnection'
}

/** A Relay edge containing a `GradedCardParallelNode` and its cursor. */
export interface GradedCardParallelNodeEdge {
  /** The item at the end of the edge */
  node: GradedCardParallelNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'GradedCardParallelNodeEdge'
}

export interface CardFamilyNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (CardFamilyNodeEdge | null)[]
  __typename: 'CardFamilyNodeConnection'
}

/** A Relay edge containing a `CardFamilyNode` and its cursor. */
export interface CardFamilyNodeEdge {
  /** The item at the end of the edge */
  node: CardFamilyNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'CardFamilyNodeEdge'
}

export enum CardParallelProductType {
  /** Card */
  CARD = 'CARD',
  /** Box */
  BOX = 'BOX',
}

export enum CardParallelEra {
  /** Modern */
  MODERN = 'MODERN',
  /** Vintage */
  VINTAGE = 'VINTAGE',
}

export enum CardParallelBoxRetailPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export interface ItemNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (ItemNodeEdge | null)[]
  __typename: 'ItemNodeConnection'
}

/** A Relay edge containing a `ItemNode` and its cursor. */
export interface ItemNodeEdge {
  /** The item at the end of the edge */
  node: ItemNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'ItemNodeEdge'
}

export interface ItemNode extends Node {
  /** The ID of the object. */
  id: ID
  cardParallel: CardParallelNode
  grade: GradeNode | null
  gradedCardParallel: GradedCardParallelNode | null
  owner: UserType
  vaultLocation: String
  boxLocation: Int | null
  originalOwner: UserType | null
  frontSidePhoto: String | null
  reverseSidePhoto: String | null
  createdAt: DateTime
  createdBy: UserType | null
  notes: String
  cachedName: String
  softDeleted: Boolean
  softDeletedAt: DateTime | null
  withdrawn: Boolean
  withdrawnAt: DateTime | null
  rejected: Boolean
  selfVaulted: Boolean
  selfVaultedReceivedAt: DateTime | null
  shippingitemSet: ShippingItemNodeConnection
  buyorderSet: BuyOrderNodeConnection
  sellorderSet: SellOrderNodeConnection
  /** The specific collection item this lot item represents, if applicable. May be left blank if the item is not yet input into the marketplace. */
  lotitemSet: LotItemNodeConnection
  hit: HitNode | null
  openSellOrderCount: Int | null
  frontSidePhotoThumbnail: String | null
  frontSidePhotoSmall: String | null
  frontSidePhotoMedium: String | null
  reverseSidePhotoThumbnail: String | null
  reverseSidePhotoSmall: String | null
  reverseSidePhotoMedium: String | null
  __typename: 'ItemNode'
}

export interface GradeNode extends Node {
  /** The ID of the object. */
  id: ID
  name: String
  description: String | null
  grader: GradeGrader
  __typename: 'GradeNode'
}

export enum GradeGrader {
  /** PSA */
  PSA = 'PSA',
  /** BGS */
  BGS = 'BGS',
  /** SGC */
  SGC = 'SGC',
  /** CSG */
  CSG = 'CSG',
  /** HGA */
  HGA = 'HGA',
  /** StarStock */
  STARSTOCK = 'STARSTOCK',
  /** Sealed */
  SEALED = 'SEALED',
}

export interface ShippingItemNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (ShippingItemNodeEdge | null)[]
  __typename: 'ShippingItemNodeConnection'
}

/** A Relay edge containing a `ShippingItemNode` and its cursor. */
export interface ShippingItemNodeEdge {
  /** The item at the end of the edge */
  node: ShippingItemNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'ShippingItemNodeEdge'
}

export interface ShippingItemNode extends Node {
  /** The ID of the object. */
  id: ID
  item: ItemNode
  shippingRequest: ShippingRequestNode
  __typename: 'ShippingItemNode'
}

export interface ShippingRequestNode extends Node {
  /** The ID of the object. */
  id: ID
  user: UserType
  deliveryAddress: JSONString | null
  status: ShippingRequestStatus
  shippingRequestFeeCurrency: ShippingRequestShippingRequestFeeCurrency
  shippingRequestFee: Float | null
  createdAt: DateTime
  submittedAt: DateTime | null
  notes: String
  shippingitemSet: ShippingItemNodeConnection
  __typename: 'ShippingRequestNode'
}

/**
 * Allows use of a JSON String for input / output from the GraphQL schema.
 *
 * Use of this type is *not recommended* as you lose the benefits of having a defined, static
 * schema (one of the key benefits of GraphQL).
 */
export type JSONString = any

export enum ShippingRequestStatus {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Submitted */
  SUBMITTED = 'SUBMITTED',
  /** Pending */
  PENDING = 'PENDING',
  /** Picking */
  PICKING = 'PICKING',
  /** Shipped */
  SHIPPED = 'SHIPPED',
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Rejected */
  REJECTED = 'REJECTED',
}

export enum ShippingRequestShippingRequestFeeCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export interface BuyOrderNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (BuyOrderNodeEdge | null)[]
  __typename: 'BuyOrderNodeConnection'
}

/** A Relay edge containing a `BuyOrderNode` and its cursor. */
export interface BuyOrderNodeEdge {
  /** The item at the end of the edge */
  node: BuyOrderNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'BuyOrderNodeEdge'
}

export interface BuyOrderNode extends Node {
  /** The ID of the object. */
  id: ID
  groupedBid: GroupedBidNode | null
  cardParallel: CardParallelNode | null
  grade: GradeNode
  gradedCardParallel: GradedCardParallelNode | null
  buyPriceCurrency: BuyOrderBuyPriceCurrency
  buyPrice: Float | null
  state: BuyOrderState
  buyer: UserType | null
  seller: UserType | null
  item: ItemNode | null
  affiliateCode: String | null
  stripePaymentMethod: StripePaymentMethodNode | null
  createdAt: DateTime
  completedAt: DateTime | null
  updatedAt: DateTime
  expiresAfter: DateTime | null
  __typename: 'BuyOrderNode'
}

export interface GroupedBidNode extends Node {
  /** The ID of the object. */
  id: ID
  createdAt: DateTime
  buyorderSet: BuyOrderNodeConnection
  buyPrice: Float | null
  openBuyOrderCount: Int | null
  suspendedBuyOrderCount: Int | null
  lowestAskCount: Int | null
  lowestAskPrice: Float | null
  lowestAskPercentage: Float | null
  __typename: 'GroupedBidNode'
}

export enum BuyOrderBuyPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export enum BuyOrderState {
  /** Open */
  OPEN = 'OPEN',
  /** Buying */
  BUYING = 'BUYING',
  /** Suspended */
  SUSPENDED = 'SUSPENDED',
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Closed */
  CLOSED = 'CLOSED',
  /** Expired */
  EXPIRED = 'EXPIRED',
}

export interface SellOrderNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (SellOrderNodeEdge | null)[]
  __typename: 'SellOrderNodeConnection'
}

/** A Relay edge containing a `SellOrderNode` and its cursor. */
export interface SellOrderNodeEdge {
  /** The item at the end of the edge */
  node: SellOrderNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'SellOrderNodeEdge'
}

export interface SellOrderNode extends Node {
  /** The ID of the object. */
  id: ID
  groupedListing: GroupedListingNode | null
  item: ItemNode
  cardParallel: CardParallelNode | null
  grade: GradeNode | null
  gradedCardParallel: GradedCardParallelNode | null
  askPriceCurrency: SellOrderAskPriceCurrency
  askPrice: Float | null
  state: SellOrderState
  seller: UserType | null
  buyer: UserType | null
  createdAt: DateTime
  completedAt: DateTime | null
  updatedAt: DateTime
  expiresAfter: DateTime | null
  __typename: 'SellOrderNode'
}

export interface GroupedListingNode extends Node {
  /** The ID of the object. */
  id: ID
  createdAt: DateTime
  sellorderSet: SellOrderNodeConnection
  askPrice: Float | null
  openSellOrderCount: Int | null
  highestBidCount: Int | null
  highestBidPrice: Float | null
  highestBidPercentage: Float | null
  __typename: 'GroupedListingNode'
}

export enum SellOrderAskPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export enum SellOrderState {
  /** Open */
  OPEN = 'OPEN',
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Closed */
  CLOSED = 'CLOSED',
  /** Expired */
  EXPIRED = 'EXPIRED',
}

export interface LotItemNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (LotItemNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'LotItemNodeConnection'
}

/** A Relay edge containing a `LotItemNode` and its cursor. */
export interface LotItemNodeEdge {
  /** The item at the end of the edge */
  node: LotItemNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'LotItemNodeEdge'
}

export interface LotItemNode extends Node {
  lot: LotNode
  gradedCardParallel: GradedCardParallelNode
  /** The specific collection item this lot item represents, if applicable. May be left blank if the item is not yet input into the marketplace. */
  item: ItemNode | null
  /** The ID of the object. */
  id: ID
  __typename: 'LotItemNode'
}

export interface LotNode extends Node {
  order: Int
  name: String
  /** A longer text description of this lot, such as an overview of the items included, and so on. */
  description: String
  auction: AuctionNode | null
  currency: LotCurrency
  openingPrice: Float | null
  initialDuration: Int | null
  newBidMinimumDuration: Int | null
  /** Display name for the collection this NFT belongs to. */
  nftCollectionName: String
  state: LotState
  /** Date and time at which this lot opened for bidding. */
  biddingOpenedAt: DateTime | null
  /** Date and time at which this lot is scheduled to conclude bidding. */
  biddingEndsAfter: DateTime | null
  /** Date and time at which this lot concluded bidding. */
  biddingEndedAt: DateTime | null
  /** Optional URL to link out to NFT information on an external website. */
  nftExternalUrl: String
  bidSet: BidNodeConnection
  lotitemSet: LotItemNodeConnection
  lotphotoSet: LotPhotoNodeConnection
  /** The ID of the object. */
  id: ID
  highestBid: BidNode | null
  highestBidPrice: Float | null
  bidPriceIncrement: Float | null
  minimumNextBidPrice: Float | null
  __typename: 'LotNode'
}

export interface AuctionNode extends Node {
  name: String
  /** URL identifier for this auction. */
  slug: String
  /** Assign a featured rank to promote this auction. Higher values take priority. Use sparingly; leave blank for most auctions. */
  featuredRank: Int | null
  /** A longer text description of this auction, such as an overview of the items included, special guests participating in the stream, and so on. */
  description: String
  /** Date and time at which this auction is scheduled to begin. Presented to users as approximate. Leave blank if auction is not yet scheduled. */
  scheduledStartTime: DateTime | null
  state: AuctionState
  /** The 11-digit video ID for the YouTube video this auction will be streamed on. */
  youtubeVideoId: String
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: String
  /** The name for the Twitch channel this auction will be streamed on. */
  twitchChannelName: String
  /** Choose where the event is visible and who can access it. */
  visibility: AuctionVisibility
  /** Determines whether bidding is restricted to a single lot at any given time, or allows for multiple lots to be bidding concurrently. */
  lotRunningFormat: AuctionLotRunningFormat
  /** Allow lots to be marked as open for bidding prior to the event going live. */
  allowPrebidding: Boolean
  currency: AuctionCurrency
  auctionphotoSet: AuctionPhotoNodeConnection
  lotSet: LotNodeConnection
  /** The ID of the object. */
  id: ID
  lotCount: Int | null
  lotItemCount: Int | null
  lotMinOpeningPrice: Float | null
  lotMaxOpeningPrice: Float | null
  lotRemainingCount: Int | null
  pusherChannelId: String | null
  __typename: 'AuctionNode'
}

export enum AuctionState {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Deleted draft */
  DELETED_DRAFT = 'DELETED_DRAFT',
  /** Published */
  PUBLISHED = 'PUBLISHED',
  /** Live */
  LIVE = 'LIVE',
  /** Ended */
  ENDED = 'ENDED',
  /** Canceled */
  CANCELED = 'CANCELED',
}

export enum AuctionVisibility {
  /** Public */
  PUBLIC = 'PUBLIC',
  /** Unlisted */
  UNLISTED = 'UNLISTED',
}

export enum AuctionLotRunningFormat {
  /** Sequential */
  SEQUENTIAL = 'SEQUENTIAL',
  /** Concurrent */
  CONCURRENT = 'CONCURRENT',
}

export enum AuctionCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export interface AuctionPhotoNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (AuctionPhotoNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'AuctionPhotoNodeConnection'
}

/** A Relay edge containing a `AuctionPhotoNode` and its cursor. */
export interface AuctionPhotoNodeEdge {
  /** The item at the end of the edge */
  node: AuctionPhotoNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'AuctionPhotoNodeEdge'
}

export interface AuctionPhotoNode extends Node {
  /** The ID of the object. */
  id: ID
  auction: AuctionNode
  photo: String | null
  createdAt: DateTime
  photoXs: String | null
  photoSm: String | null
  photoMd: String | null
  __typename: 'AuctionPhotoNode'
}

export interface LotNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (LotNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'LotNodeConnection'
}

/** A Relay edge containing a `LotNode` and its cursor. */
export interface LotNodeEdge {
  /** The item at the end of the edge */
  node: LotNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'LotNodeEdge'
}

export enum LotCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export enum LotState {
  /** Upcoming */
  UPCOMING = 'UPCOMING',
  /** Starting */
  STARTING = 'STARTING',
  /** Bidding */
  BIDDING = 'BIDDING',
  /** Canceled */
  CANCELED = 'CANCELED',
  /** Unsold */
  UNSOLD = 'UNSOLD',
  /** Sold */
  SOLD = 'SOLD',
}

export interface BidNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (BidNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'BidNodeConnection'
}

/** A Relay edge containing a `BidNode` and its cursor. */
export interface BidNodeEdge {
  /** The item at the end of the edge */
  node: BidNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'BidNodeEdge'
}

export interface BidNode extends Node {
  lot: LotNode
  user: UserType
  bidPrice: Float | null
  createdAt: DateTime
  state: BidState
  /** The ID of the object. */
  id: ID
  bidPriceCurrency: String | null
  __typename: 'BidNode'
}

export enum BidState {
  /** Submitted */
  SUBMITTED = 'SUBMITTED',
  /** Canceled */
  CANCELED = 'CANCELED',
  /** Below reserve */
  BELOW_RESERVE = 'BELOW_RESERVE',
  /** Lost */
  LOST = 'LOST',
  /** Won (pending payment) */
  WON_PENDING_PAYMENT = 'WON_PENDING_PAYMENT',
  /** Won */
  WON = 'WON',
}

export interface LotPhotoNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (LotPhotoNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'LotPhotoNodeConnection'
}

/** A Relay edge containing a `LotPhotoNode` and its cursor. */
export interface LotPhotoNodeEdge {
  /** The item at the end of the edge */
  node: LotPhotoNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'LotPhotoNodeEdge'
}

export interface LotPhotoNode extends Node {
  /** The ID of the object. */
  id: ID
  lot: LotNode
  photo: String | null
  createdAt: DateTime
  photoXs: String | null
  photoSm: String | null
  photoMd: String | null
  __typename: 'LotPhotoNode'
}

export interface HitNode extends Node {
  /** The ID of the object. */
  id: ID
  /** User-facing display name for this hit (e.g. "🔥 EPIC PULL! Tom Brady Prizm Auto Silver #/10!") */
  name: String
  /** An optional longer description, giving context to the break or hit. */
  description: String
  /** The spot in the break that this hit is assigned to. */
  spot: SpotNode
  /** The type and grade of the card hit. As these are pack-fresh pulls, we typically assume StarStock A grade. */
  gradedCardParallel: GradedCardParallelNode
  /** The specific item in our database representing this hit. May be left blank prior to the card being processed. */
  item: ItemNode | null
  createdAt: DateTime
  createdBy: UserType | null
  photo: String | null
  photoXs: String | null
  photoSm: String | null
  photoMd: String | null
  __typename: 'HitNode'
}

export interface SpotNode extends Node {
  /** The ID of the object. */
  id: ID
  boxBreak: BoxBreakNode
  /** Spots are ordered within the parent break by their rank (asc). Rank will also be used as slot name if one is not specified (e.g. random breaks). */
  rank: Int
  /** Optional display name for this spot in the break (e.g. Cleveland Browns), or may be left blank to use rank. */
  name: String
  priceCurrency: SpotPriceCurrency
  price: Float | null
  purchasedAt: DateTime | null
  /** The spot in the break that this hit is assigned to. */
  hitSet: HitNodeConnection
  personalbreakrequest: PersonalBreakRequestNode | null
  isPersonalBreakRequest: Boolean | null
  myParticipant: ParticipantNode | null
  __typename: 'SpotNode'
}

export enum SpotPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export interface HitNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (HitNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'HitNodeConnection'
}

/** A Relay edge containing a `HitNode` and its cursor. */
export interface HitNodeEdge {
  /** The item at the end of the edge */
  node: HitNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'HitNodeEdge'
}

export interface PersonalBreakRequestNode extends Node {
  /** The ID of the object. */
  id: ID
  user: UserType
  itemOwnership: ItemOwnershipNode
  /** The scheduled personal break that the user would like their box broken as part of. */
  boxBreak: BoxBreakNode
  /** The spot in the break that represents this accepted request. */
  spot: SpotNode | null
  /** Optional, additional request information, such as a preferred start time. */
  requestDetails: String
  breakFeeCurrency: PersonalBreakRequestBreakFeeCurrency
  breakFee: Float | null
  createdAt: DateTime
  state: PersonalBreakRequestState
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: Boolean
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: String
  address: AddressNode | null
  __typename: 'PersonalBreakRequestNode'
}

export interface ItemOwnershipNode extends Node {
  /** The ID of the object. */
  id: ID
  item: ItemNode
  owner: UserType
  boughtBuyorder: BuyOrderNode | null
  boughtSellorder: SellOrderNode | null
  soldBuyorder: BuyOrderNode | null
  soldSellorder: SellOrderNode | null
  boughtPriceCurrency: ItemOwnershipBoughtPriceCurrency | null
  boughtPrice: Float | null
  soldPriceCurrency: ItemOwnershipSoldPriceCurrency | null
  soldPrice: Float | null
  startedAt: DateTime | null
  endedAt: DateTime | null
  personalbreakrequestSet: PersonalBreakRequestNodeConnection
  askPrice: Float | null
  askPriceValue: Float | null
  breakFee: Float | null
  inDraftShippingRequest: Boolean | null
  __typename: 'ItemOwnershipNode'
}

export enum ItemOwnershipBoughtPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export enum ItemOwnershipSoldPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export interface PersonalBreakRequestNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (PersonalBreakRequestNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'PersonalBreakRequestNodeConnection'
}

/** A Relay edge containing a `PersonalBreakRequestNode` and its cursor. */
export interface PersonalBreakRequestNodeEdge {
  /** The item at the end of the edge */
  node: PersonalBreakRequestNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'PersonalBreakRequestNodeEdge'
}

export enum PersonalBreakRequestBreakFeeCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export enum PersonalBreakRequestState {
  /** Requested */
  REQUESTED = 'REQUESTED',
  /** Canceled */
  CANCELED = 'CANCELED',
  /** Rejected */
  REJECTED = 'REJECTED',
  /** Accepted */
  ACCEPTED = 'ACCEPTED',
}

export interface AddressNode extends Node {
  description: String
  name: String
  addressLines: String
  locality: String
  region: String
  postalCode: String
  country: String
  role: AddressRole
  default: Boolean
  /** The ID of the object. */
  id: ID
  __typename: 'AddressNode'
}

export enum AddressRole {
  /** Shipping */
  SHIPPING = 'SHIPPING',
}

export interface ParticipantNode extends Node {
  /** The ID of the object. */
  id: ID
  boxBreak: BoxBreakNode
  user: UserType
  joinedAt: DateTime
  /** The streaming platform on which the participant plans to watch. */
  liveStreamPlatform: ParticipantLiveStreamPlatform
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: Boolean
  address: AddressNode | null
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: String
  /** The break participant who has claimed this spot (note that one participant may claim multiple spots). */
  spotSet: SpotNodeConnection
  __typename: 'ParticipantNode'
}

export enum ParticipantLiveStreamPlatform {
  /** YouTube */
  YOUTUBE = 'YOUTUBE',
}

export interface SpotNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (SpotNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'SpotNodeConnection'
}

/** A Relay edge containing a `SpotNode` and its cursor. */
export interface SpotNodeEdge {
  /** The item at the end of the edge */
  node: SpotNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'SpotNodeEdge'
}

export interface WishlistItemNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (WishlistItemNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'WishlistItemNodeConnection'
}

/** A Relay edge containing a `WishlistItemNode` and its cursor. */
export interface WishlistItemNodeEdge {
  /** The item at the end of the edge */
  node: WishlistItemNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'WishlistItemNodeEdge'
}

export interface WishlistItemNode extends Node {
  /** The ID of the object. */
  id: ID
  cardParallel: CardParallelNode
  wisher: UserType
  createdAt: DateTime
  __typename: 'WishlistItemNode'
}

export interface HistoricalPurchaseNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (HistoricalPurchaseNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'HistoricalPurchaseNodeConnection'
}

/** A Relay edge containing a `HistoricalPurchaseNode` and its cursor. */
export interface HistoricalPurchaseNodeEdge {
  /** The item at the end of the edge */
  node: HistoricalPurchaseNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'HistoricalPurchaseNodeEdge'
}

export interface HistoricalPurchaseNode extends Node {
  /** The ID of the object. */
  id: ID
  cardParallel: CardParallelNode
  grade: GradeNode | null
  gradedCardParallel: GradedCardParallelNode | null
  source: String
  priceCurrency: HistoricalPurchasePriceCurrency
  price: Float | null
  buyer: UserType | null
  seller: UserType | null
  affiliateCode: String | null
  saleDate: DateTime | null
  verified: Boolean
  softDeleted: Boolean
  quantity: Int | null
  date: Date | null
  myRole: String | null
  __typename: 'HistoricalPurchaseNode'
}

export enum HistoricalPurchasePriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

/**
 * The `Date` scalar type represents a Date
 * value as specified by
 * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
 */
export type Date = any

export enum GradedCardParallelOpenBuyOrderMaxPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export enum GradedCardParallelOpenSellOrderMinPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export enum GradedCardParallelLastSalePriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export interface MarketIndexComponentNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (MarketIndexComponentNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'MarketIndexComponentNodeConnection'
}

/** A Relay edge containing a `MarketIndexComponentNode` and its cursor. */
export interface MarketIndexComponentNodeEdge {
  /** The item at the end of the edge */
  node: MarketIndexComponentNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'MarketIndexComponentNodeEdge'
}

export interface MarketIndexComponentNode extends Node {
  /** The ID of the object. */
  id: ID
  marketIndex: MarketIndexNode
  /** Unique ranking for this component within the index. Represents relative strength of the component, disconnected from exact current value. */
  rank: Int
  /** Optional grouping category for this component within the index (e.g. a position in a single-sport index, a sport in a multi-sport index) */
  subcategory: String
  player: PlayerNode
  gradedCardParallel: GradedCardParallelNode | null
  /** URL linking to the appropriate detail page for this component (e.g. a card or player page) */
  linkUrl: String
  latestComponentSnapshot: MarketIndexComponentSnapshotNode | null
  componentSnapshots: MarketIndexComponentSnapshotNodeConnection
  __typename: 'MarketIndexComponentNode'
}

export interface MarketIndexNode extends Node {
  /** The ID of the object. */
  id: ID
  /** A short alphanumeric symbol to represent this index (e.g. NFLP30 for the NFL Prizm 30 index) */
  symbol: String
  name: String
  /** URL path for this index */
  slug: String
  /** Brief summary of this index and the components it includes */
  description: String
  latestSnapshot: MarketIndexSnapshotNode | null
  snapshots: MarketIndexSnapshotNodeConnection
  components: MarketIndexComponentNodeConnection
  __typename: 'MarketIndexNode'
}

export interface MarketIndexSnapshotNode extends Node {
  /** The ID of the object. */
  id: ID
  marketIndex: MarketIndexNode
  /** The series this snapshot belongs to (e.g. weekly, daily...) */
  seriesName: MarketIndexSnapshotSeriesName
  date: DateTime
  /** An optional friendly label to identify this snapshot, e.g. 'NFL Week 1' or 'Superbowl' */
  name: String
  value: Float | null
  valueChange: Float | null
  valueChangePercentage: Float | null
  normalisedValueMultiplier: Float | null
  normalisedValue: Float | null
  normalisedValueChange: Float | null
  normalisedValueChangePercentage: Float | null
  marketindex: MarketIndexNode | null
  componentSnapshots: MarketIndexComponentSnapshotNodeConnection
  __typename: 'MarketIndexSnapshotNode'
}

export enum MarketIndexSnapshotSeriesName {
  /** Weekly */
  WEEKLY = 'WEEKLY',
}

export interface MarketIndexComponentSnapshotNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (MarketIndexComponentSnapshotNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'MarketIndexComponentSnapshotNodeConnection'
}

/** A Relay edge containing a `MarketIndexComponentSnapshotNode` and its cursor. */
export interface MarketIndexComponentSnapshotNodeEdge {
  /** The item at the end of the edge */
  node: MarketIndexComponentSnapshotNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'MarketIndexComponentSnapshotNodeEdge'
}

export interface MarketIndexComponentSnapshotNode extends Node {
  /** The ID of the object. */
  id: ID
  marketIndexComponent: MarketIndexComponentNode
  marketIndexSnapshot: MarketIndexSnapshotNode
  /** Set None to hide fantasy status from display */
  fantasyStatus: MarketIndexComponentSnapshotFantasyStatus
  fantasyPoints: Decimal | null
  priceCurrency: MarketIndexComponentSnapshotPriceCurrency | null
  price: Float | null
  priceChangeCurrency: MarketIndexComponentSnapshotPriceChangeCurrency | null
  priceChange: Float | null
  /** The percentage change of this price from the previous snapshot */
  priceChangePercentage: Decimal | null
  marketindexcomponent: MarketIndexComponentNode | null
  __typename: 'MarketIndexComponentSnapshotNode'
}

export enum MarketIndexComponentSnapshotFantasyStatus {
  /** None */
  NONE = 'NONE',
  /** Final */
  FINAL = 'FINAL',
  /** Delayed */
  DELAYED = 'DELAYED',
  /** Canceled */
  CANCELED = 'CANCELED',
  /** Bye */
  BYE = 'BYE',
  /** Out */
  OUT = 'OUT',
  /** Did not play */
  DID_NOT_PLAY = 'DID_NOT_PLAY',
}

export enum MarketIndexComponentSnapshotPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export enum MarketIndexComponentSnapshotPriceChangeCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export interface MarketIndexSnapshotNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (MarketIndexSnapshotNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'MarketIndexSnapshotNodeConnection'
}

/** A Relay edge containing a `MarketIndexSnapshotNode` and its cursor. */
export interface MarketIndexSnapshotNodeEdge {
  /** The item at the end of the edge */
  node: MarketIndexSnapshotNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'MarketIndexSnapshotNodeEdge'
}

export interface BoxBreakPhotoNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (BoxBreakPhotoNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'BoxBreakPhotoNodeConnection'
}

/** A Relay edge containing a `BoxBreakPhotoNode` and its cursor. */
export interface BoxBreakPhotoNodeEdge {
  /** The item at the end of the edge */
  node: BoxBreakPhotoNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'BoxBreakPhotoNodeEdge'
}

export interface BoxBreakPhotoNode extends Node {
  /** The ID of the object. */
  id: ID
  boxBreak: BoxBreakNode
  photo: String | null
  createdAt: DateTime
  photoXs: String | null
  photoSm: String | null
  photoMd: String | null
  __typename: 'BoxBreakPhotoNode'
}

export interface RemintNFTNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (RemintNFTNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'RemintNFTNodeConnection'
}

/** A Relay edge containing a `RemintNFTNode` and its cursor. */
export interface RemintNFTNodeEdge {
  /** The item at the end of the edge */
  node: RemintNFTNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'RemintNFTNodeEdge'
}

export interface RemintNFTNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Display name for this nft item. */
  name: String
  /** Display name for the collection this nft item belongs to. */
  collectionName: String
  blockchain: RemintNFTBlockchain
  /** Optional Link to external URL. */
  externalLink: String
  /** Optional Name of external URL page. */
  externalLinkText: String
  image: String
  /** Break in which this nft item is going to be or was opened. */
  boxBreak: BoxBreakNode | null
  __typename: 'RemintNFTNode'
}

export enum RemintNFTBlockchain {
  /** Ethereum */
  ETHEREUM = 'ETHEREUM',
  /** Solana */
  SOLANA = 'SOLANA',
}

export interface PlayerNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (PlayerNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'PlayerNodeConnection'
}

/** A Relay edge containing a `PlayerNode` and its cursor. */
export interface PlayerNodeEdge {
  /** The item at the end of the edge */
  node: PlayerNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'PlayerNodeEdge'
}

export interface StreamNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (StreamNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'StreamNodeConnection'
}

/** A Relay edge containing a `StreamNode` and its cursor. */
export interface StreamNodeEdge {
  /** The item at the end of the edge */
  node: StreamNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'StreamNodeEdge'
}

export interface ShippingRequestNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (ShippingRequestNodeEdge | null)[]
  __typename: 'ShippingRequestNodeConnection'
}

/** A Relay edge containing a `ShippingRequestNode` and its cursor. */
export interface ShippingRequestNodeEdge {
  /** The item at the end of the edge */
  node: ShippingRequestNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'ShippingRequestNodeEdge'
}

export interface FixtureNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (FixtureNodeEdge | null)[]
  __typename: 'FixtureNodeConnection'
}

/** A Relay edge containing a `FixtureNode` and its cursor. */
export interface FixtureNodeEdge {
  /** The item at the end of the edge */
  node: FixtureNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'FixtureNodeEdge'
}

export interface UserNotificationPreferenceNode extends Node {
  /** The ID of the object. */
  id: ID
  notificationType: UserNotificationPreferenceNotificationType
  targetType: UserNotificationPreferenceTargetType
  __typename: 'UserNotificationPreferenceNode'
}

export enum UserNotificationPreferenceNotificationType {
  /** Buy orders created */
  BUY_ORDERS_CREATED = 'BUY_ORDERS_CREATED',
  /** Buy orders completed */
  BUY_ORDERS_COMPLETED = 'BUY_ORDERS_COMPLETED',
  /** Buy orders new lowest listing */
  BUY_ORDERS_NEW_LOWEST_LISTING = 'BUY_ORDERS_NEW_LOWEST_LISTING',
  /** Buy orders outbid */
  BUY_ORDERS_OUTBID = 'BUY_ORDERS_OUTBID',
  /** Buy orders suspended */
  BUY_ORDERS_SUSPENDED = 'BUY_ORDERS_SUSPENDED',
  /** Buy orders expired */
  BUY_ORDERS_EXPIRED = 'BUY_ORDERS_EXPIRED',
  /** Sell orders created */
  SELL_ORDERS_CREATED = 'SELL_ORDERS_CREATED',
  /** Sell orders completed */
  SELL_ORDERS_COMPLETED = 'SELL_ORDERS_COMPLETED',
  /** Sell orders new best offer */
  SELL_ORDERS_NEW_BEST_OFFER = 'SELL_ORDERS_NEW_BEST_OFFER',
  /** Sell orders undercut */
  SELL_ORDERS_UNDERCUT = 'SELL_ORDERS_UNDERCUT',
  /** Sell orders expired */
  SELL_ORDERS_EXPIRED = 'SELL_ORDERS_EXPIRED',
  /** Ship home request confirmation */
  SHIP_HOME_REQUEST_CONFIRMATION = 'SHIP_HOME_REQUEST_CONFIRMATION',
  /** Submission completed */
  SUBMISSION_COMPLETED = 'SUBMISSION_COMPLETED',
  /** Break joined */
  BREAK_JOINED = 'BREAK_JOINED',
  /** Break started */
  BREAK_STARTED = 'BREAK_STARTED',
  /** Auction winning bid */
  AUCTION_WINNING_BID = 'AUCTION_WINNING_BID',
  /** Deposit receipt */
  DEPOSIT_RECEIPT = 'DEPOSIT_RECEIPT',
  /** Withdrawal request confirmation */
  WITHDRAWAL_REQUEST_CONFIRMATION = 'WITHDRAWAL_REQUEST_CONFIRMATION',
  /** Withdrawal request payment confirmation */
  WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION = 'WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION',
  /** Withdrawal request stripe refund */
  WITHDRAWAL_REQUEST_STRIPE_REFUND = 'WITHDRAWAL_REQUEST_STRIPE_REFUND',
  /** Withdrawal request paypal rejected */
  WITHDRAWAL_REQUEST_PAYPAL_REJECTED = 'WITHDRAWAL_REQUEST_PAYPAL_REJECTED',
  /** Withdrawal request insufficient funds */
  WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS = 'WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS',
  /** Withdrawal submission fee */
  WITHDRAWAL_SUBMISSION_FEE = 'WITHDRAWAL_SUBMISSION_FEE',
  /** Daily report */
  DAILY_REPORT = 'DAILY_REPORT',
  /** Weekly report */
  WEEKLY_REPORT = 'WEEKLY_REPORT',
}

export enum UserNotificationPreferenceTargetType {
  /** Email */
  EMAIL = 'EMAIL',
  /** Ignore */
  IGNORE = 'IGNORE',
}

export interface RecentSaleNode {
  cardParallel: CardParallelNode | null
  grade: GradeNode | null
  price: Float | null
  saleDate: DateTime | null
  quantity: Int | null
  __typename: 'RecentSaleNode'
}

export interface ItemOwnershipNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (ItemOwnershipNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'ItemOwnershipNodeConnection'
}

/** A Relay edge containing a `ItemOwnershipNode` and its cursor. */
export interface ItemOwnershipNodeEdge {
  /** The item at the end of the edge */
  node: ItemOwnershipNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'ItemOwnershipNodeEdge'
}

export interface GroupedBidNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (GroupedBidNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'GroupedBidNodeConnection'
}

/** A Relay edge containing a `GroupedBidNode` and its cursor. */
export interface GroupedBidNodeEdge {
  /** The item at the end of the edge */
  node: GroupedBidNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'GroupedBidNodeEdge'
}

export interface GroupedListingNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (GroupedListingNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'GroupedListingNodeConnection'
}

/** A Relay edge containing a `GroupedListingNode` and its cursor. */
export interface GroupedListingNodeEdge {
  /** The item at the end of the edge */
  node: GroupedListingNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'GroupedListingNodeEdge'
}

export interface MarketIndexNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (MarketIndexNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'MarketIndexNodeConnection'
}

/** A Relay edge containing a `MarketIndexNode` and its cursor. */
export interface MarketIndexNodeEdge {
  /** The item at the end of the edge */
  node: MarketIndexNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'MarketIndexNodeEdge'
}

export interface MarketWidgetNode extends Node {
  /** The ID of the object. */
  id: ID
  title: String
  subtitle: String
  rank: Int | null
  active: Boolean
  featuredResultKey: String | null
  resultSetOrderBy: String | null
  __typename: 'MarketWidgetNode'
}

export interface GradeNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (GradeNodeEdge | null)[]
  __typename: 'GradeNodeConnection'
}

/** A Relay edge containing a `GradeNode` and its cursor. */
export interface GradeNodeEdge {
  /** The item at the end of the edge */
  node: GradeNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'GradeNodeEdge'
}

export enum PortfolioValueTimePeriodUnits {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface PortfolioValueNode {
  openDate: DateTime | null
  closeDate: DateTime | null
  closeItemCount: Int | null
  closeSubmittedItemCount: Int | null
  closePurchasedItemCount: Int | null
  closeUnvaluedItemCount: Int | null
  closeValue: Float | null
  closeInvested: Float | null
  closeRoiValue: Float | null
  closeRoiPercent: Float | null
  __typename: 'PortfolioValueNode'
}

export interface CardSetNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (CardSetNodeEdge | null)[]
  cardParallelCounts: (CardSetCounts | null)[] | null
  myCardParallelCounts: (CardSetCounts | null)[] | null
  __typename: 'CardSetNodeConnection'
}

/** A Relay edge containing a `CardSetNode` and its cursor. */
export interface CardSetNodeEdge {
  /** The item at the end of the edge */
  node: CardSetNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'CardSetNodeEdge'
}

export interface CardSetCounts {
  name: String | null
  company: String | null
  parallelCount: Int | null
  __typename: 'CardSetCounts'
}

export interface AuctionNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (AuctionNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'AuctionNodeConnection'
}

/** A Relay edge containing a `AuctionNode` and its cursor. */
export interface AuctionNodeEdge {
  /** The item at the end of the edge */
  node: AuctionNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'AuctionNodeEdge'
}

export interface AnnouncementNode extends Node {
  /** The ID of the object. */
  id: ID
  state: AnnouncementBannerState
  /** Automatically generated. Example: 2021-10-16-submit-for-grading-update */
  slug: String
  /** Example: Submit for grading update */
  name: String
  /** Text that will be highlighted in the announcement banner  */
  prefixText: String | null
  /** Announcement body content */
  bodyText: String
  /** Link title */
  linkTitle: String
  /** URL linking to the announced target */
  linkUrl: String
  style: AnnouncementStyleNode
  __typename: 'AnnouncementNode'
}

export enum AnnouncementBannerState {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Preview */
  PREVIEW = 'PREVIEW',
  /** Live (published) */
  LIVE = 'LIVE',
  /** Ended */
  ENDED = 'ENDED',
}

export interface AnnouncementStyleNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Examples: Breaks, Product announcements, Technical announcements, Marketing, etc. */
  name: String
  badgeTextColor: String | null
  linkTextColor: String | null
  badgeGradientStart: String
  badgeGradientEnd: String
  announcementbannerSet: AnnouncementNodeConnection
  __typename: 'AnnouncementStyleNode'
}

export interface AnnouncementNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (AnnouncementNodeEdge | null)[]
  __typename: 'AnnouncementNodeConnection'
}

/** A Relay edge containing a `AnnouncementNode` and its cursor. */
export interface AnnouncementNodeEdge {
  /** The item at the end of the edge */
  node: AnnouncementNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'AnnouncementNodeEdge'
}

export interface PromotionType extends Node {
  /** The ID of the object. */
  id: ID
  description: String | null
  __typename: 'PromotionType'
}

export interface Mutation {
  submitDraftShippingRequest: SubmitDraftShippingRequest | null
  addItemsToDraftShippingRequest: AddItemsToDraftShippingRequest | null
  removeItemsFromDraftShippingRequest: RemoveItemsFromDraftShippingRequest | null
  addAllItemsToDraftShippingRequest: AddAllItemsToDraftShippingRequest | null
  removeAllItemsFromDraftShippingRequest: RemoveAllItemsFromDraftShippingRequest | null
  createPaymentIntent: CreatePaymentIntent | null
  createWithdrawalRequest: CreateWithdrawalRequest | null
  deletePaymentMethod: DeletePaymentMethod | null
  setDefaultPaymentMethod: SetDefaultPaymentMethod | null
  createSetupIntent: CreateSetupIntent | null
  updateCryptoWalletBalance: UpdateCryptoWalletBalance | null
  withdrawalConfirmation: WithdrawalConfirmation | null
  updateNotificationPreferences: UpdateNotificationPreferences | null
  groupedBidClose: GroupedBidClose | null
  groupedListingClose: GroupedListingClose | null
  sellItems: SellItems | null
  buyItems: BuyItems | null
  buyOrderExtendExpiryDate: BuyOrderExtendExpiryDate | null
  sellOrderExtendExpiryDate: SellOrderExtendExpiryDate | null
  wishlistItemCreate: WishlistItemCreate | null
  wishlistItemDelete: WishlistItemDelete | null
  sendUserOwnershipsCsv: SendUserOwnershipsCsv | null
  buyBoxBreakSpots: BuyBoxBreakSpots | null
  personalBreakRequestCreate: PersonalBreakRequestCreate | null
  bidCreate: BidCreate | null
  /** Create a brand new address for the logged-in user. */
  createAddress: CreateAddress | null
  /** Hide a specified address for the logged-in user. */
  deleteAddress: DeleteAddress | null
  /** Hide the existing address and create a replacement with the specified changes. */
  updateAddress: UpdateAddress | null
  /** Mark an existing address as the default. */
  makeAddressDefault: MakeAddressDefault | null
  tokenAuth: ObtainJSONWebToken | null
  userCreate: UserCreate | null
  passwordChange: PasswordChange | null
  passwordResetRequest: PasswordResetRequest | null
  passwordResetChange: PasswordResetChange | null
  updateUserCollectionVisibility: UpdateUserCollectionVisibility | null
  verifyToken: Verify | null
  refreshToken: Refresh | null
  deleteTokenCookie: DeleteJSONWebTokenCookie | null
  deleteRefreshTokenCookie: DeleteRefreshTokenCookie | null
  __typename: 'Mutation'
}

export interface SubmitDraftShippingRequest {
  /** List of errors that occured executing the mutation. */
  errors: Error[] | null
  __typename: 'SubmitDraftShippingRequest'
}

/** Represents an error in the input of a mutation. */
export interface Error {
  /** Name of the field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field: String | null
  /** The error message. */
  message: String | null
  __typename: 'Error'
}

export interface AddItemsToDraftShippingRequest {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  itemCount: Int | null
  addedItems: (String | null)[] | null
  __typename: 'AddItemsToDraftShippingRequest'
}

export interface RemoveItemsFromDraftShippingRequest {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  itemCount: Int | null
  removedItems: (String | null)[] | null
  __typename: 'RemoveItemsFromDraftShippingRequest'
}

export interface AddAllItemsToDraftShippingRequest {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  itemCount: Int | null
  __typename: 'AddAllItemsToDraftShippingRequest'
}

export interface RemoveAllItemsFromDraftShippingRequest {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  itemCount: Int | null
  __typename: 'RemoveAllItemsFromDraftShippingRequest'
}

export interface CreatePaymentIntent {
  paymentIntentToken: String | null
  paymentSequence: Int | null
  __typename: 'CreatePaymentIntent'
}

export enum WithdrawalPaymentMethods {
  PAYPAL = 'PAYPAL',
  CHECK = 'CHECK',
}

export interface CreateWithdrawalRequest {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'CreateWithdrawalRequest'
}

export interface DeletePaymentMethod {
  paymentMethodId: String | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'DeletePaymentMethod'
}

export interface SetDefaultPaymentMethod {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'SetDefaultPaymentMethod'
}

export interface CreateSetupIntent {
  setupIntentClientSecret: String | null
  __typename: 'CreateSetupIntent'
}

export enum CryptoCurrencies {
  ETH = 'ETH',
  SOL = 'SOL',
}

export interface UpdateCryptoWalletBalance {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UpdateCryptoWalletBalance'
}

export interface WithdrawalConfirmation {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'WithdrawalConfirmation'
}

export enum NotificationTypes {
  BUY_ORDERS_CREATED = 'BUY_ORDERS_CREATED',
  BUY_ORDERS_COMPLETED = 'BUY_ORDERS_COMPLETED',
  BUY_ORDERS_NEW_LOWEST_LISTING = 'BUY_ORDERS_NEW_LOWEST_LISTING',
  BUY_ORDERS_OUTBID = 'BUY_ORDERS_OUTBID',
  BUY_ORDERS_SUSPENDED = 'BUY_ORDERS_SUSPENDED',
  BUY_ORDERS_EXPIRED = 'BUY_ORDERS_EXPIRED',
  SELL_ORDERS_CREATED = 'SELL_ORDERS_CREATED',
  SELL_ORDERS_COMPLETED = 'SELL_ORDERS_COMPLETED',
  SELL_ORDERS_NEW_BEST_OFFER = 'SELL_ORDERS_NEW_BEST_OFFER',
  SELL_ORDERS_UNDERCUT = 'SELL_ORDERS_UNDERCUT',
  SELL_ORDERS_EXPIRED = 'SELL_ORDERS_EXPIRED',
  SHIP_HOME_REQUEST_CONFIRMATION = 'SHIP_HOME_REQUEST_CONFIRMATION',
  SUBMISSION_COMPLETED = 'SUBMISSION_COMPLETED',
  BREAK_JOINED = 'BREAK_JOINED',
  BREAK_STARTED = 'BREAK_STARTED',
  AUCTION_WINNING_BID = 'AUCTION_WINNING_BID',
  DEPOSIT_RECEIPT = 'DEPOSIT_RECEIPT',
  WITHDRAWAL_REQUEST_CONFIRMATION = 'WITHDRAWAL_REQUEST_CONFIRMATION',
  WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION = 'WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION',
  WITHDRAWAL_REQUEST_STRIPE_REFUND = 'WITHDRAWAL_REQUEST_STRIPE_REFUND',
  WITHDRAWAL_REQUEST_PAYPAL_REJECTED = 'WITHDRAWAL_REQUEST_PAYPAL_REJECTED',
  WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS = 'WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS',
  WITHDRAWAL_SUBMISSION_FEE = 'WITHDRAWAL_SUBMISSION_FEE',
  DAILY_SUMMARY = 'DAILY_SUMMARY',
  WEEKLY_SUMMARY = 'WEEKLY_SUMMARY',
}

export enum TargetTypes {
  EMAIL = 'EMAIL',
  IGNORE = 'IGNORE',
}

export interface UpdateNotificationPreferences {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UpdateNotificationPreferences'
}

export interface GroupedBidClose {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'GroupedBidClose'
}

export interface GroupedListingClose {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'GroupedListingClose'
}

export enum ExpiresAfter {
  NO_EXPIRY = 'NO_EXPIRY',
  THREE_DAYS = 'THREE_DAYS',
  SEVEN_DAYS = 'SEVEN_DAYS',
  FOURTEEN_DAYS = 'FOURTEEN_DAYS',
  THIRTY_DAYS = 'THIRTY_DAYS',
  SIXTY_DAYS = 'SIXTY_DAYS',
}

export interface SellItems {
  soldCount: Int | null
  listedCount: Int | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'SellItems'
}

export interface BuyItems {
  boughtCount: Int | null
  listedCount: Int | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'BuyItems'
}

export interface BuyOrderExtendExpiryDate {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'BuyOrderExtendExpiryDate'
}

export interface SellOrderExtendExpiryDate {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'SellOrderExtendExpiryDate'
}

export interface WishlistItemCreate {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  wishlistItem: WishlistItemNode | null
  __typename: 'WishlistItemCreate'
}

export interface WishlistItemDelete {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  wishlistItem: WishlistItemNode | null
  __typename: 'WishlistItemDelete'
}

export interface SendUserOwnershipsCsv {
  ok: Boolean | null
  __typename: 'SendUserOwnershipsCsv'
}

export interface BuyBoxBreakSpots {
  purchasedSpotsCount: Int | null
  purchasedSpotsCost: Float | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'BuyBoxBreakSpots'
}

export interface PersonalBreakRequestCreate {
  purchasedSpotsCount: Int | null
  purchasedSpotsCost: Float | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'PersonalBreakRequestCreate'
}

export interface BidCreate {
  bid: BidNode | null
  lot: LotNode | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'BidCreate'
}

/** Create a brand new address for the logged-in user. */
export interface CreateAddress {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'CreateAddress'
}

/** Hide a specified address for the logged-in user. */
export interface DeleteAddress {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'DeleteAddress'
}

/** Hide the existing address and create a replacement with the specified changes. */
export interface UpdateAddress {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UpdateAddress'
}

/** Mark an existing address as the default. */
export interface MakeAddressDefault {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'MakeAddressDefault'
}

export interface ObtainJSONWebToken {
  payload: GenericScalar
  refreshExpiresIn: Int
  token: String
  refreshToken: String
  __typename: 'ObtainJSONWebToken'
}

/**
 * The `GenericScalar` scalar type represents a generic
 * GraphQL scalar value that could be:
 * String, Boolean, Int, Float, List or Object.
 */
export type GenericScalar = any

export interface UserCreate {
  user: UserType | null
  refreshToken: String | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UserCreate'
}

export interface PasswordChange {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'PasswordChange'
}

export interface PasswordResetRequest {
  success: Boolean | null
  __typename: 'PasswordResetRequest'
}

export interface PasswordResetChange {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'PasswordResetChange'
}

export interface UpdateUserCollectionVisibility {
  ok: Boolean | null
  __typename: 'UpdateUserCollectionVisibility'
}

export interface Verify {
  payload: GenericScalar
  __typename: 'Verify'
}

export interface Refresh {
  payload: GenericScalar
  refreshExpiresIn: Int
  token: String
  refreshToken: String
  __typename: 'Refresh'
}

export interface DeleteJSONWebTokenCookie {
  deleted: Boolean
  __typename: 'DeleteJSONWebTokenCookie'
}

export interface DeleteRefreshTokenCookie {
  deleted: Boolean
  __typename: 'DeleteRefreshTokenCookie'
}

export interface QueryRequest {
  myPaymentMethods?: StripePaymentMethodNodeRequest
  channels?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        ChannelNodeConnectionRequest,
      ]
    | ChannelNodeConnectionRequest
  channel?: [{ id?: ID | null; slug?: String | null }, ChannelNodeRequest] | ChannelNodeRequest
  streams?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  stream?: [{ id?: ID | null }, StreamNodeRequest] | StreamNodeRequest
  events?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          eventType?: (String | null)[] | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  event?: [{ id?: ID | null }, EventNodeRequest] | EventNodeRequest
  myShippingRequests?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          status?: String | null
        },
        ShippingRequestNodeConnectionRequest,
      ]
    | ShippingRequestNodeConnectionRequest
  myShippingRequest?: [{ id?: ID | null }, ShippingRequestNodeRequest] | ShippingRequestNodeRequest
  myDraftShippingRequest?: ShippingRequestNodeRequest
  player?: [{ id?: ID | null; slug?: String | null }, PlayerNodeRequest] | PlayerNodeRequest
  upcomingFixtures?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          sport?: String | null
        },
        FixtureNodeConnectionRequest,
      ]
    | FixtureNodeConnectionRequest
  cardParallelSearch?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          sport?: String | null
          era?: String | null
          query?: String | null
          productType?: String | null
          minItems?: Decimal | null
          maxItems?: Decimal | null
          minListings?: Decimal | null
          maxListings?: Decimal | null
          minOffers?: Decimal | null
          maxOffers?: Decimal | null
          minSerialLimit?: Decimal | null
          maxSerialLimit?: Decimal | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          /** Ordering */
          orderBy?: String | null
        },
        CardParallelSearchResultConnectionRequest,
      ]
    | CardParallelSearchResultConnectionRequest
  defaultNotificationPreferences?: UserNotificationPreferenceNodeRequest
  myNotificationPreferences?: UserNotificationPreferenceNodeRequest
  sales?: [{ first?: Int | null; sport?: String | null; era?: String | null }, RecentSaleNodeRequest] | RecentSaleNodeRequest
  mySales?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          searchQuery?: String | null
          sport?: String | null
          era?: String | null
          productType?: (String | null)[] | null
          listed?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        ItemOwnershipNodeConnectionRequest,
      ]
    | ItemOwnershipNodeConnectionRequest
  myPastPurchases?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          searchQuery?: String | null
          sport?: String | null
        },
        HistoricalPurchaseNodeConnectionRequest,
      ]
    | HistoricalPurchaseNodeConnectionRequest
  myGroupedBids?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          searchQuery?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        GroupedBidNodeConnectionRequest,
      ]
    | GroupedBidNodeConnectionRequest
  myGroupedListings?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          searchQuery?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        GroupedListingNodeConnectionRequest,
      ]
    | GroupedListingNodeConnectionRequest
  gradedCardParallels?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          grader?: String | null
          minItems?: Decimal | null
          minListings?: Decimal | null
          minOffers?: Decimal | null
          myListingsStatus?: String | null
        },
        GradedCardParallelNodeConnectionRequest,
      ]
    | GradedCardParallelNodeConnectionRequest
  gradedCardParallel?: [{ id?: ID | null }, GradedCardParallelNodeRequest] | GradedCardParallelNodeRequest
  marketIndices?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          name?: String | null
        },
        MarketIndexNodeConnectionRequest,
      ]
    | MarketIndexNodeConnectionRequest
  marketIndex?: [{ id?: ID | null; slug?: String | null }, MarketIndexNodeRequest] | MarketIndexNodeRequest
  marketWidgets?: MarketWidgetNodeRequest
  grades?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        GradeNodeConnectionRequest,
      ]
    | GradeNodeConnectionRequest
  myWishlistCards?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          featuredResultSet?: String | null
          searchQuery?: String | null
          sport?: String | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          cardSet?: String | null
          minItems?: Decimal | null
          maxItems?: Decimal | null
          minListings?: Decimal | null
          maxListings?: Decimal | null
          minOffers?: Decimal | null
          maxOffers?: Decimal | null
          minSerialLimit?: Decimal | null
          maxSerialLimit?: Decimal | null
          grader?: String | null
          myListingsStatus?: String | null
          playerCard?: ID | null
          productType?: String | null
          era?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        CardParallelNodeConnectionRequest,
      ]
    | CardParallelNodeConnectionRequest
  myWishlistPlayers?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        PlayerNodeConnectionRequest,
      ]
    | PlayerNodeConnectionRequest
  userCollection?: [
    {
      username: String
      offset?: Int | null
      before?: String | null
      after?: String | null
      first?: Int | null
      last?: Int | null
      searchQuery?: String | null
      sport?: String | null
      era?: String | null
      productType?: (String | null)[] | null
      listed?: Boolean | null
      /** Ordering */
      orderBy?: String | null
    },
    ItemOwnershipNodeConnectionRequest,
  ]
  portfolioValue?:
    | [
        {
          sport?: String | null
          era?: String | null
          timePeriodUnit?: PortfolioValueTimePeriodUnits | null
          timePeriodCount?: Int | null
          timePeriodOffset?: Int | null
        },
        PortfolioValueNodeRequest,
      ]
    | PortfolioValueNodeRequest
  myCollection?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          searchQuery?: String | null
          sport?: String | null
          era?: String | null
          productType?: (String | null)[] | null
          listed?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        ItemOwnershipNodeConnectionRequest,
      ]
    | ItemOwnershipNodeConnectionRequest
  myInDraftShippingRequestCollection?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          searchQuery?: String | null
          sport?: String | null
          era?: String | null
          productType?: (String | null)[] | null
          listed?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        ItemOwnershipNodeConnectionRequest,
      ]
    | ItemOwnershipNodeConnectionRequest
  cardParallel?: [{ id?: ID | null; slug?: String | null }, CardParallelNodeRequest] | CardParallelNodeRequest
  cardParallels?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          featuredResultSet?: String | null
          searchQuery?: String | null
          sport?: String | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          cardSet?: String | null
          minItems?: Decimal | null
          maxItems?: Decimal | null
          minListings?: Decimal | null
          maxListings?: Decimal | null
          minOffers?: Decimal | null
          maxOffers?: Decimal | null
          minSerialLimit?: Decimal | null
          maxSerialLimit?: Decimal | null
          grader?: String | null
          myListingsStatus?: String | null
          playerCard?: ID | null
          productType?: String | null
          era?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        CardParallelNodeConnectionRequest,
      ]
    | CardParallelNodeConnectionRequest
  myCardParallels?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          featuredResultSet?: String | null
          searchQuery?: String | null
          sport?: String | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          cardSet?: String | null
          minItems?: Decimal | null
          maxItems?: Decimal | null
          minListings?: Decimal | null
          maxListings?: Decimal | null
          minOffers?: Decimal | null
          maxOffers?: Decimal | null
          minSerialLimit?: Decimal | null
          maxSerialLimit?: Decimal | null
          grader?: String | null
          myListingsStatus?: String | null
          playerCard?: ID | null
          productType?: String | null
          era?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        CardParallelNodeConnectionRequest,
      ]
    | CardParallelNodeConnectionRequest
  cardSets?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          sport?: String | null
          startYear?: Decimal | null
          endYear?: Decimal | null
        },
        CardSetNodeConnectionRequest,
      ]
    | CardSetNodeConnectionRequest
  boxBreak?: [{ id?: ID | null; slug?: String | null }, BoxBreakNodeRequest] | BoxBreakNodeRequest
  boxBreaks?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          sport?: String | null
          era?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  myBoxBreaks?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          sport?: String | null
          era?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  myPersonalBreakRequests?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        PersonalBreakRequestNodeConnectionRequest,
      ]
    | PersonalBreakRequestNodeConnectionRequest
  remints?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          sport?: String | null
          era?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  remint?: [{ id?: ID | null; slug?: String | null }, BoxBreakNodeRequest] | BoxBreakNodeRequest
  auctions?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          sport?: String | null
          era?: String | null
          state?: (String | null)[] | null
          featured?: Boolean | null
          currency?: (String | null)[] | null
          lotRunningFormat?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        AuctionNodeConnectionRequest,
      ]
    | AuctionNodeConnectionRequest
  auction?: [{ id?: ID | null; slug?: String | null }, AuctionNodeRequest] | AuctionNodeRequest
  lots?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          productCategory?: (String | null)[] | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        LotNodeConnectionRequest,
      ]
    | LotNodeConnectionRequest
  myBiddingLots?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          productCategory?: (String | null)[] | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        LotNodeConnectionRequest,
      ]
    | LotNodeConnectionRequest
  lot?: [{ id?: ID | null }, LotNodeRequest] | LotNodeRequest
  bid?: [{ id?: ID | null }, BidNodeRequest] | BidNodeRequest
  announcementBanners?: AnnouncementNodeRequest
  myAddresses?: AddressNodeRequest
  me?: UserTypeRequest
  myAccount?: UserTypeRequest
  promotion?: PromotionTypeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StripePaymentMethodNodeRequest {
  paymentMethodId?: boolean | number
  brand?: boolean | number
  lastFourDigits?: boolean | number
  expiryMonth?: boolean | number
  expiryYear?: boolean | number
  offSession?: boolean | number
  defaultPaymentMethod?: boolean | number
  /** The ID of the object. */
  id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** An object with an ID */
export interface NodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  on_StripePaymentMethodNode?: StripePaymentMethodNodeRequest
  on_ChannelNode?: ChannelNodeRequest
  on_EventNode?: EventNodeRequest
  on_StreamNode?: StreamNodeRequest
  on_BoxBreakNode?: BoxBreakNodeRequest
  on_FormatNode?: FormatNodeRequest
  on_BreakLocationNode?: BreakLocationNodeRequest
  on_BoxNode?: BoxNodeRequest
  on_GradedCardParallelNode?: GradedCardParallelNodeRequest
  on_CardParallelNode?: CardParallelNodeRequest
  on_SportNode?: SportNodeRequest
  on_TeamNode?: TeamNodeRequest
  on_CardProgramNode?: CardProgramNodeRequest
  on_CardSetNode?: CardSetNodeRequest
  on_CardSetParallelNode?: CardSetParallelNodeRequest
  on_CardParallelSearchResult?: CardParallelSearchResultRequest
  on_PlayerCardNode?: PlayerCardNodeRequest
  on_TeamPlayerNode?: TeamPlayerNodeRequest
  on_PlayerNode?: PlayerNodeRequest
  on_FixtureTeamPlayerNode?: FixtureTeamPlayerNodeRequest
  on_FixtureNode?: FixtureNodeRequest
  on_CardFamilyNode?: CardFamilyNodeRequest
  on_ItemNode?: ItemNodeRequest
  on_GradeNode?: GradeNodeRequest
  on_ShippingItemNode?: ShippingItemNodeRequest
  on_ShippingRequestNode?: ShippingRequestNodeRequest
  on_BuyOrderNode?: BuyOrderNodeRequest
  on_GroupedBidNode?: GroupedBidNodeRequest
  on_SellOrderNode?: SellOrderNodeRequest
  on_GroupedListingNode?: GroupedListingNodeRequest
  on_LotItemNode?: LotItemNodeRequest
  on_LotNode?: LotNodeRequest
  on_AuctionNode?: AuctionNodeRequest
  on_AuctionPhotoNode?: AuctionPhotoNodeRequest
  on_BidNode?: BidNodeRequest
  on_LotPhotoNode?: LotPhotoNodeRequest
  on_HitNode?: HitNodeRequest
  on_SpotNode?: SpotNodeRequest
  on_PersonalBreakRequestNode?: PersonalBreakRequestNodeRequest
  on_ItemOwnershipNode?: ItemOwnershipNodeRequest
  on_AddressNode?: AddressNodeRequest
  on_ParticipantNode?: ParticipantNodeRequest
  on_WishlistItemNode?: WishlistItemNodeRequest
  on_HistoricalPurchaseNode?: HistoricalPurchaseNodeRequest
  on_MarketIndexComponentNode?: MarketIndexComponentNodeRequest
  on_MarketIndexNode?: MarketIndexNodeRequest
  on_MarketIndexSnapshotNode?: MarketIndexSnapshotNodeRequest
  on_MarketIndexComponentSnapshotNode?: MarketIndexComponentSnapshotNodeRequest
  on_BoxBreakPhotoNode?: BoxBreakPhotoNodeRequest
  on_RemintNFTNode?: RemintNFTNodeRequest
  on_UserNotificationPreferenceNode?: UserNotificationPreferenceNodeRequest
  on_MarketWidgetNode?: MarketWidgetNodeRequest
  on_AnnouncementNode?: AnnouncementNodeRequest
  on_AnnouncementStyleNode?: AnnouncementStyleNodeRequest
  on_PromotionType?: PromotionTypeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ChannelNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: ChannelNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface PageInfoRequest {
  /** When paginating forwards, are there more items? */
  hasNextPage?: boolean | number
  /** When paginating backwards, are there more items? */
  hasPreviousPage?: boolean | number
  /** When paginating backwards, the cursor to continue. */
  startCursor?: boolean | number
  /** When paginating forwards, the cursor to continue. */
  endCursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `ChannelNode` and its cursor. */
export interface ChannelNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: ChannelNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ChannelNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  name?: boolean | number
  /** User-facing description of this channel and its focus. */
  description?: boolean | number
  /** URL identifier for this channel. */
  slug?: boolean | number
  eventSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          eventType?: (String | null)[] | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  streamSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  nextUpcomingStreamSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  nextUpcomingStream?: StreamNodeRequest
  liveStreamSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  liveStream?: StreamNodeRequest
  recentlyEndedStreamSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  recentlyEndedStream?: StreamNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface EventNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: EventNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `EventNode` and its cursor. */
export interface EventNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: EventNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface EventNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  eventType?: boolean | number
  channel?: ChannelNodeRequest
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  stream?: StreamNodeRequest
  /** Date and time at which this event is scheduled to begin. Does not enforce when it starts; only used as a guideline. May be left blank if not yet scheduled. */
  scheduledStartTime?: boolean | number
  /** Estimated duration of this event. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration?: boolean | number
  /** Optional event-specific replay video, hosted on YouTube. If populated, once the event has ended it will show this video instead of the video from the associated Stream. Leave blank to just continue to show the full Stream video. */
  replayYoutubeVideoId?: boolean | number
  state?: boolean | number
  boxBreak?: BoxBreakNodeRequest
  name?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StreamNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Display name for this stream. May be named after the date, number in a series, or just a descriptive title for the content being included in the stream. */
  name?: boolean | number
  /** User-facing description of this stream and and what it will be covering. e.g. "Tonight we will be ripping 4 group breaks including a PYT Bowman Chrome break! Personal breaks on-request throughout the night!" */
  description?: boolean | number
  channel?: ChannelNodeRequest
  /** Date and time at which this stream is scheduled to begin. Does not enforce when it starts; only used as a guideline. */
  scheduledStartTime?: boolean | number
  /** Estimated duration of this stream. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration?: boolean | number
  /** The 11-digit YouTube video ID for this stream. */
  youtubeVideoId?: boolean | number
  state?: boolean | number
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  eventSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          eventType?: (String | null)[] | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  nextUpcomingEventSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  nextUpcomingEvent?: EventNodeRequest
  liveEventSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  liveEvent?: EventNodeRequest
  recentlyEndedEventSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  recentlyEndedEvent?: EventNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxBreakNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  format?: FormatNodeRequest
  /** Display name for this break (e.g. "2021 Topps Definitive Baseball 3x Hobby Box Break: 2 Random Teams") */
  name?: boolean | number
  /** URL identifier for this break */
  slug?: boolean | number
  /** Assign a featured rank to promote this break. Higher values take priority. Use sparingly; leave blank for most breaks. */
  featuredRank?: boolean | number
  /** A text description of this break, for example outlining the break format (random teams, PYT...) and products being broken. */
  description?: boolean | number
  /** Date and time at which this break is scheduled to begin. Presented to users as approximate. Leave blank if break is not yet scheduled. */
  scheduledStartTime?: boolean | number
  createdAt?: boolean | number
  createdBy?: UserTypeRequest
  state?: boolean | number
  /** Date and time at which this break was published onto the live site. */
  publishedAt?: boolean | number
  /** Date and time at which the break opened for entries. */
  openedAt?: boolean | number
  /** Date and time at which the live break began. */
  startedAt?: boolean | number
  /** Date and time at which the break stopped accepting new entries. */
  lockedAt?: boolean | number
  /** Date and time at which the live break concluded. */
  endedAt?: boolean | number
  /** Date and time at which the break's items completed processing. */
  settledAt?: boolean | number
  /** The 11-digit video ID for the YouTube video this break will be streamed on. */
  youtubeVideoId?: boolean | number
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId?: boolean | number
  /** The name for the Twitch channel this break will be streamed on. */
  twitchChannelName?: boolean | number
  breakLocation?: BreakLocationNodeRequest
  /** Choose where the event is visible and who can access it. */
  visibility?: boolean | number
  /** Optional restriction on how many spots a participant can claim. */
  maxSpotsPerUser?: boolean | number
  /** Optional URL to link to buy page for reMints */
  purchaseOverrideUrl?: boolean | number
  /** Break in which this box is going to be or was opened. */
  boxSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BoxNodeConnectionRequest,
      ]
    | BoxNodeConnectionRequest
  boxbreakphotoSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BoxBreakPhotoNodeConnectionRequest,
      ]
    | BoxBreakPhotoNodeConnectionRequest
  spotSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        SpotNodeConnectionRequest,
      ]
    | SpotNodeConnectionRequest
  /** The scheduled personal break that the user would like their box broken as part of. */
  personalbreakrequestSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        PersonalBreakRequestNodeConnectionRequest,
      ]
    | PersonalBreakRequestNodeConnectionRequest
  /** Break in which this nft item is going to be or was opened. */
  remintnftSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        RemintNFTNodeConnectionRequest,
      ]
    | RemintNFTNodeConnectionRequest
  event?: EventNodeRequest
  myParticipant?: ParticipantNodeRequest
  hitSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        HitNodeConnectionRequest,
      ]
    | HitNodeConnectionRequest
  targetPlayers?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        PlayerNodeConnectionRequest,
      ]
    | PlayerNodeConnectionRequest
  spotsTotalCount?: boolean | number
  spotsAvailableCount?: boolean | number
  spotsFilledCount?: boolean | number
  spotsMinPrice?: boolean | number
  spotsMaxPrice?: boolean | number
  spotsCurrency?: boolean | number
  allowPersonalBreakRequests?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface FormatNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** A descriptive name for this break format (e.g. "Random Team" or "Hit Draft") */
  name?: boolean | number
  /** Optional description of this break format, to inform users about how it works and why they might want to choose this format over another. */
  description?: boolean | number
  /** Indicates whether it is a group break, open to multiple participants, or a personal break for a single user. */
  breakCategory?: boolean | number
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  boxbreakSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          sport?: String | null
          era?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxBreakNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: BoxBreakNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `BoxBreakNode` and its cursor. */
export interface BoxBreakNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: BoxBreakNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserTypeRequest {
  id?: boolean | number
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: boolean | number
  firstName?: boolean | number
  lastName?: boolean | number
  email?: boolean | number
  userprofile?: UserProfileTypeRequest
  balance?: boolean | number
  isStaff?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserProfileTypeRequest {
  id?: boolean | number
  marketingEmails?: boolean | number
  buyTargetPercentage?: boolean | number
  sellTargetPercentage?: boolean | number
  dailyDigestHour?: boolean | number
  isCollectionPublic?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BreakLocationNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** e.g. StarStock New York office, Partner Studio... */
  name?: boolean | number
  /** Enable to allow users to request an item in their collection be broken during a personal break. */
  allowPersonalBreakRequests?: boolean | number
  boxbreakSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          sport?: String | null
          era?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: BoxNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `BoxNode` and its cursor. */
export interface BoxNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: BoxNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Mandatory connection to a product type in the wider card database. */
  gradedCardParallel?: GradedCardParallelNodeRequest
  /** Optional display name for this this breakable product (e.g. Prizm Football 2020 Hobby Box). If blank, name is taken from associated product type. */
  name?: boolean | number
  /** Break in which this box is going to be or was opened. */
  boxBreak?: BoxBreakNodeRequest
  createdAt?: boolean | number
  createdBy?: UserTypeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GradedCardParallelNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  cardParallel?: CardParallelNodeRequest
  grade?: GradeNodeRequest
  itemCount?: boolean | number
  itemFirstCreatedAt?: boolean | number
  openBuyOrderCount?: boolean | number
  openBuyOrderMaxPriceCurrency?: boolean | number
  openBuyOrderMaxPrice?: boolean | number
  openBuyOrderMaxCreatedAt?: boolean | number
  openSellOrderCount?: boolean | number
  openSellOrderMinPriceCurrency?: boolean | number
  openSellOrderMinPrice?: boolean | number
  openSellOrderMaxCreatedAt?: boolean | number
  lastSaleDate?: boolean | number
  lastSalePriceCurrency?: boolean | number
  lastSalePrice?: boolean | number
  itemSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          search?: String | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          /** Ordering */
          orderBy?: String | null
        },
        ItemNodeConnectionRequest,
      ]
    | ItemNodeConnectionRequest
  historicalpurchaseSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          searchQuery?: String | null
          sport?: String | null
        },
        HistoricalPurchaseNodeConnectionRequest,
      ]
    | HistoricalPurchaseNodeConnectionRequest
  buyorderSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BuyOrderNodeConnectionRequest,
      ]
    | BuyOrderNodeConnectionRequest
  sellorderSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        SellOrderNodeConnectionRequest,
      ]
    | SellOrderNodeConnectionRequest
  marketindexcomponentSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          sport?: String | null
        },
        MarketIndexComponentNodeConnectionRequest,
      ]
    | MarketIndexComponentNodeConnectionRequest
  lotitemSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        LotItemNodeConnectionRequest,
      ]
    | LotItemNodeConnectionRequest
  /** Mandatory connection to a product type in the wider card database. */
  boxSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BoxNodeConnectionRequest,
      ]
    | BoxNodeConnectionRequest
  /** The type and grade of the card hit. As these are pack-fresh pulls, we typically assume StarStock A grade. */
  hitSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        HitNodeConnectionRequest,
      ]
    | HitNodeConnectionRequest
  items?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        ItemNodeConnectionRequest,
      ]
    | ItemNodeConnectionRequest
  openSellOrders?: SellOrderNodeRequest
  openBuyOrders?: BuyOrderNodeRequest
  myItems?: ItemNodeRequest
  myItemCount?: boolean | number
  myOpenSellOrderCount?: boolean | number
  myOpenSellOrderMinPrice?: boolean | number
  myOpenBuyOrderCount?: boolean | number
  mySuspendedBuyOrderCount?: boolean | number
  myGroupedBids?: GroupedBidNodeRequest
  myGroupedListings?: GroupedListingNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardParallelNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  sport?: SportNodeRequest
  productType?: boolean | number
  era?: boolean | number
  playerCard?: PlayerCardNodeRequest
  parallel?: CardSetParallelNodeRequest
  name?: boolean | number
  slug?: boolean | number
  serialLimit?: boolean | number
  trendingRank?: boolean | number
  frontSidePhoto?: boolean | number
  reverseSidePhoto?: boolean | number
  boxProductPhoto?: boolean | number
  ebaySearchTerm?: boolean | number
  boxRetailPriceCurrency?: boolean | number
  boxRetailPrice?: boolean | number
  /** Estimated time required to open this box in a break, in minutes. Only relevant for boxes; leave blank for cards. */
  boxBreakDuration?: boolean | number
  boxPacksPerBox?: boolean | number
  boxCardsPerPack?: boolean | number
  /** Optional text description of this product (e.g. target players / parallels / autos in a box, historic importance of card, etc...) */
  description?: boolean | number
  itemSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          search?: String | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          /** Ordering */
          orderBy?: String | null
        },
        ItemNodeConnectionRequest,
      ]
    | ItemNodeConnectionRequest
  wishlistitemSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          sport?: String | null
        },
        WishlistItemNodeConnectionRequest,
      ]
    | WishlistItemNodeConnectionRequest
  historicalpurchaseSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          searchQuery?: String | null
          sport?: String | null
        },
        HistoricalPurchaseNodeConnectionRequest,
      ]
    | HistoricalPurchaseNodeConnectionRequest
  buyorderSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BuyOrderNodeConnectionRequest,
      ]
    | BuyOrderNodeConnectionRequest
  sellorderSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        SellOrderNodeConnectionRequest,
      ]
    | SellOrderNodeConnectionRequest
  gradedcardparallelSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          grader?: String | null
          minItems?: Decimal | null
          minListings?: Decimal | null
          minOffers?: Decimal | null
          myListingsStatus?: String | null
        },
        GradedCardParallelNodeConnectionRequest,
      ]
    | GradedCardParallelNodeConnectionRequest
  cardparallelsearch?: CardParallelSearchResultRequest
  sportId?: boolean | number
  sellOrderCount?: boolean | number
  sellOrderMinPrice?: boolean | number
  buyOrderCount?: boolean | number
  buyOrderMaxPrice?: boolean | number
  lastListedAt?: boolean | number
  lastSaleDate?: boolean | number
  firstAddedAt?: boolean | number
  openBuyOrders?: BuyOrderNodeRequest
  openSellOrders?: SellOrderNodeRequest
  myGroupedBids?: GroupedBidNodeRequest
  myGroupedListings?: GroupedListingNodeRequest
  myItems?: ItemNodeRequest
  lastSalePrice?: boolean | number
  recentPurchases?: HistoricalPurchaseNodeRequest
  myWishlistItems?: WishlistItemNodeRequest
  itemCount?: boolean | number
  myItemsCount?: boolean | number
  myValuedItemsCount?: boolean | number
  myItemsValue?: boolean | number
  myItemsMaxValue?: boolean | number
  myListingsCount?: boolean | number
  myListingsMinPrice?: boolean | number
  myItemOwnershipsMinStartedAt?: boolean | number
  myItemOwnershipsMaxStartedAt?: boolean | number
  frontSidePhotoThumbnail?: boolean | number
  frontSidePhotoSmall?: boolean | number
  frontSidePhotoMedium?: boolean | number
  reverseSidePhotoThumbnail?: boolean | number
  reverseSidePhotoSmall?: boolean | number
  reverseSidePhotoMedium?: boolean | number
  boxProductPhotoSmall?: boolean | number
  boxProductPhotoMedium?: boolean | number
  salesLast24Hours?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SportNodeRequest {
  /** Examples: FOOTBALL, BASKETBALL, ICE_HOCKEY */
  key?: boolean | number
  /** Examples: Football, Basketball, Ice Hockey */
  name?: boolean | number
  active?: boolean | number
  /** Should the sport be stand-alone or should it be grouped with other secondary sports */
  primary?: boolean | number
  teamSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        TeamNodeConnectionRequest,
      ]
    | TeamNodeConnectionRequest
  cardprogramSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        CardProgramNodeConnectionRequest,
      ]
    | CardProgramNodeConnectionRequest
  cardparallelSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          featuredResultSet?: String | null
          searchQuery?: String | null
          sport?: String | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          cardSet?: String | null
          minItems?: Decimal | null
          maxItems?: Decimal | null
          minListings?: Decimal | null
          maxListings?: Decimal | null
          minOffers?: Decimal | null
          maxOffers?: Decimal | null
          minSerialLimit?: Decimal | null
          maxSerialLimit?: Decimal | null
          grader?: String | null
          myListingsStatus?: String | null
          playerCard?: ID | null
          productType?: String | null
          era?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        CardParallelNodeConnectionRequest,
      ]
    | CardParallelNodeConnectionRequest
  /** The ID of the object. */
  id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TeamNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: TeamNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `TeamNode` and its cursor. */
export interface TeamNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: TeamNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TeamNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Examples: Chicago Bulls, LSU Tigers, Oakland Athletics */
  name?: boolean | number
  /** Abbreviated name used to represent this team in shorter contexts. Examples: Chi, OKC, Sco */
  abbrName?: boolean | number
  /** City, area or country this team represents. Examples: Chicago, Oakland, Scotland */
  locationName?: boolean | number
  /** Standalone franchise or brand name. Examples: Bulls, Tigers, Athletics */
  franchiseName?: boolean | number
  colorPrimary?: boolean | number
  colorSecondary?: boolean | number
  colorTertiary?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardProgramNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: CardProgramNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `CardProgramNode` and its cursor. */
export interface CardProgramNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: CardProgramNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardProgramNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  sport?: SportNodeRequest
  /** If the program goes across multiple years, use the earliest year */
  year?: boolean | number
  /** Examples: 2014-15 Panini Select Basketball; 2019 Topps Chrome Baseball; 2019 Panini Donruss Football */
  name?: boolean | number
  cardSet?: CardSetNodeRequest
  cardfamilySet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        CardFamilyNodeConnectionRequest,
      ]
    | CardFamilyNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardSetNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Examples: Panini Prizm; Bowman Draft; Topps Chrome; Topps */
  name?: boolean | number
  primary?: boolean | number
  cardprogramSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        CardProgramNodeConnectionRequest,
      ]
    | CardProgramNodeConnectionRequest
  cardsetparallelSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        CardSetParallelNodeConnectionRequest,
      ]
    | CardSetParallelNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardSetParallelNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: CardSetParallelNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `CardSetParallelNode` and its cursor. */
export interface CardSetParallelNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: CardSetParallelNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardSetParallelNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  cardSet?: CardSetNodeRequest
  /** Examples: Base, Orange Refractor, Silver */
  name?: boolean | number
  badgeTextColor?: boolean | number
  badgeBorderColor?: boolean | number
  badgeGradientStart?: boolean | number
  badgeGradientEnd?: boolean | number
  badgeGradientAngle?: boolean | number
  baseSet?: boolean | number
  cardparallelSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          featuredResultSet?: String | null
          searchQuery?: String | null
          sport?: String | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          cardSet?: String | null
          minItems?: Decimal | null
          maxItems?: Decimal | null
          minListings?: Decimal | null
          maxListings?: Decimal | null
          minOffers?: Decimal | null
          maxOffers?: Decimal | null
          minSerialLimit?: Decimal | null
          maxSerialLimit?: Decimal | null
          grader?: String | null
          myListingsStatus?: String | null
          playerCard?: ID | null
          productType?: String | null
          era?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        CardParallelNodeConnectionRequest,
      ]
    | CardParallelNodeConnectionRequest
  cardparallelsearchSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          sport?: String | null
          era?: String | null
          query?: String | null
          productType?: String | null
          minItems?: Decimal | null
          maxItems?: Decimal | null
          minListings?: Decimal | null
          maxListings?: Decimal | null
          minOffers?: Decimal | null
          maxOffers?: Decimal | null
          minSerialLimit?: Decimal | null
          maxSerialLimit?: Decimal | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          /** Ordering */
          orderBy?: String | null
        },
        CardParallelSearchResultConnectionRequest,
      ]
    | CardParallelSearchResultConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardParallelNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: CardParallelNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  cardParallelStats?: CardParallelStatsNodeRequest
  myCardParallelStats?: CardParallelStatsNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `CardParallelNode` and its cursor. */
export interface CardParallelNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: CardParallelNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardParallelStatsNodeRequest {
  totalItemCount?: boolean | number
  totalListingCount?: boolean | number
  totalValuedItemCount?: boolean | number
  totalItemValue?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardParallelSearchResultConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: CardParallelSearchResultEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  cardParallelStats?: CardParallelStatsNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `CardParallelSearchResult` and its cursor. */
export interface CardParallelSearchResultEdgeRequest {
  /** The item at the end of the edge */
  node?: CardParallelSearchResultRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardParallelSearchResultRequest {
  cardParallel?: CardParallelNodeRequest
  playerCard?: PlayerCardNodeRequest
  parallel?: CardSetParallelNodeRequest
  sport?: boolean | number
  era?: boolean | number
  productType?: boolean | number
  name?: boolean | number
  slug?: boolean | number
  serialLimit?: boolean | number
  trendingRank?: boolean | number
  itemCount?: boolean | number
  sellOrderCount?: boolean | number
  sellOrderMinPrice?: boolean | number
  buyOrderCount?: boolean | number
  buyOrderMaxPrice?: boolean | number
  firstAddedAt?: boolean | number
  lastListedAt?: boolean | number
  lastSaleDate?: boolean | number
  salesLast24Hours?: boolean | number
  salesLast7Days?: boolean | number
  /** The ID of the object. */
  id?: boolean | number
  frontSidePhoto?: boolean | number
  frontSidePhotoSmall?: boolean | number
  frontSidePhotoMedium?: boolean | number
  frontSidePhotoThumbnail?: boolean | number
  reverseSidePhoto?: boolean | number
  reverseSidePhotoSmall?: boolean | number
  reverseSidePhotoMedium?: boolean | number
  reverseSidePhotoThumbnail?: boolean | number
  boxProductPhoto?: boolean | number
  boxProductPhotoSmall?: boolean | number
  boxProductPhotoMedium?: boolean | number
  gradedcardparallelSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        GradedCardParallelNodeConnectionRequest,
      ]
    | GradedCardParallelNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PlayerCardNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  name?: boolean | number
  slug?: boolean | number
  setNumber?: boolean | number
  teamPlayers?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        TeamPlayerNodeConnectionRequest,
      ]
    | TeamPlayerNodeConnectionRequest
  cardFamily?: CardFamilyNodeRequest
  rookieCard?: boolean | number
  cardparallelSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          featuredResultSet?: String | null
          searchQuery?: String | null
          sport?: String | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          cardSet?: String | null
          minItems?: Decimal | null
          maxItems?: Decimal | null
          minListings?: Decimal | null
          maxListings?: Decimal | null
          minOffers?: Decimal | null
          maxOffers?: Decimal | null
          minSerialLimit?: Decimal | null
          maxSerialLimit?: Decimal | null
          grader?: String | null
          myListingsStatus?: String | null
          playerCard?: ID | null
          productType?: String | null
          era?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        CardParallelNodeConnectionRequest,
      ]
    | CardParallelNodeConnectionRequest
  cardparallelsearchSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          sport?: String | null
          era?: String | null
          query?: String | null
          productType?: String | null
          minItems?: Decimal | null
          maxItems?: Decimal | null
          minListings?: Decimal | null
          maxListings?: Decimal | null
          minOffers?: Decimal | null
          maxOffers?: Decimal | null
          minSerialLimit?: Decimal | null
          maxSerialLimit?: Decimal | null
          startYear?: Decimal | null
          endYear?: Decimal | null
          /** Ordering */
          orderBy?: String | null
        },
        CardParallelSearchResultConnectionRequest,
      ]
    | CardParallelSearchResultConnectionRequest
  cardParallels?: CardParallelNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TeamPlayerNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: TeamPlayerNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `TeamPlayerNode` and its cursor. */
export interface TeamPlayerNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: TeamPlayerNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TeamPlayerNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  player?: PlayerNodeRequest
  team?: TeamNodeRequest
  playercardSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        PlayerCardNodeConnectionRequest,
      ]
    | PlayerCardNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PlayerNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Examples: Teddy Bridgewater, Marc Gasol */
  name?: boolean | number
  slug?: boolean | number
  startYear?: boolean | number
  /** Examples: WR, C-F, OF */
  position?: boolean | number
  currentTeam?: TeamNodeRequest
  headshotPhoto?: boolean | number
  headshotPhotoXs?: boolean | number
  headshotPhotoSm?: boolean | number
  headshotPhotoMd?: boolean | number
  tradeStats?: PlayerTradeStatsNodeRequest
  cardParallels?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        CardParallelNodeConnectionRequest,
      ]
    | CardParallelNodeConnectionRequest
  myCardParallels?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        CardParallelNodeConnectionRequest,
      ]
    | CardParallelNodeConnectionRequest
  fixtureTeamPlayers?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        FixtureTeamPlayerNodeConnectionRequest,
      ]
    | FixtureTeamPlayerNodeConnectionRequest
  seasonStats?: PlayerStatNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PlayerTradeStatsNodeRequest {
  tradeCount?: boolean | number
  minTradePrice?: boolean | number
  maxTradePrice?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface FixtureTeamPlayerNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: FixtureTeamPlayerNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `FixtureTeamPlayerNode` and its cursor. */
export interface FixtureTeamPlayerNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: FixtureTeamPlayerNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface FixtureTeamPlayerNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  fixture?: FixtureNodeRequest
  teamPlayer?: TeamPlayerNodeRequest
  trendingRank?: boolean | number
  fixtureStats?: PlayerStatNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface FixtureNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  sportRadarId?: boolean | number
  status?: boolean | number
  startDate?: boolean | number
  homeTeam?: TeamNodeRequest
  awayTeam?: TeamNodeRequest
  statsImported?: boolean | number
  fixtureteamplayerSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        FixtureTeamPlayerNodeConnectionRequest,
      ]
    | FixtureTeamPlayerNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PlayerStatNodeRequest {
  statName?: boolean | number
  statValue?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PlayerCardNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: PlayerCardNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `PlayerCardNode` and its cursor. */
export interface PlayerCardNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: PlayerCardNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardFamilyNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Examples: Base, Paper Prospect Autographs, Rated Rookies */
  name?: boolean | number
  program?: CardProgramNodeRequest
  primary?: boolean | number
  playercardSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        PlayerCardNodeConnectionRequest,
      ]
    | PlayerCardNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GradedCardParallelNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: GradedCardParallelNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `GradedCardParallelNode` and its cursor. */
export interface GradedCardParallelNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: GradedCardParallelNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardFamilyNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: CardFamilyNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `CardFamilyNode` and its cursor. */
export interface CardFamilyNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: CardFamilyNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ItemNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: ItemNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `ItemNode` and its cursor. */
export interface ItemNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: ItemNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ItemNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  cardParallel?: CardParallelNodeRequest
  grade?: GradeNodeRequest
  gradedCardParallel?: GradedCardParallelNodeRequest
  owner?: UserTypeRequest
  vaultLocation?: boolean | number
  boxLocation?: boolean | number
  originalOwner?: UserTypeRequest
  frontSidePhoto?: boolean | number
  reverseSidePhoto?: boolean | number
  createdAt?: boolean | number
  createdBy?: UserTypeRequest
  notes?: boolean | number
  cachedName?: boolean | number
  softDeleted?: boolean | number
  softDeletedAt?: boolean | number
  withdrawn?: boolean | number
  withdrawnAt?: boolean | number
  rejected?: boolean | number
  selfVaulted?: boolean | number
  selfVaultedReceivedAt?: boolean | number
  shippingitemSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        ShippingItemNodeConnectionRequest,
      ]
    | ShippingItemNodeConnectionRequest
  buyorderSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BuyOrderNodeConnectionRequest,
      ]
    | BuyOrderNodeConnectionRequest
  sellorderSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        SellOrderNodeConnectionRequest,
      ]
    | SellOrderNodeConnectionRequest
  /** The specific collection item this lot item represents, if applicable. May be left blank if the item is not yet input into the marketplace. */
  lotitemSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        LotItemNodeConnectionRequest,
      ]
    | LotItemNodeConnectionRequest
  hit?: HitNodeRequest
  openSellOrderCount?: boolean | number
  frontSidePhotoThumbnail?: boolean | number
  frontSidePhotoSmall?: boolean | number
  frontSidePhotoMedium?: boolean | number
  reverseSidePhotoThumbnail?: boolean | number
  reverseSidePhotoSmall?: boolean | number
  reverseSidePhotoMedium?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GradeNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  name?: boolean | number
  description?: boolean | number
  grader?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ShippingItemNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: ShippingItemNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `ShippingItemNode` and its cursor. */
export interface ShippingItemNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: ShippingItemNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ShippingItemNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  item?: ItemNodeRequest
  shippingRequest?: ShippingRequestNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ShippingRequestNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  user?: UserTypeRequest
  deliveryAddress?: boolean | number
  status?: boolean | number
  shippingRequestFeeCurrency?: boolean | number
  shippingRequestFee?: boolean | number
  createdAt?: boolean | number
  submittedAt?: boolean | number
  notes?: boolean | number
  shippingitemSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        ShippingItemNodeConnectionRequest,
      ]
    | ShippingItemNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BuyOrderNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: BuyOrderNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `BuyOrderNode` and its cursor. */
export interface BuyOrderNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: BuyOrderNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BuyOrderNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  groupedBid?: GroupedBidNodeRequest
  cardParallel?: CardParallelNodeRequest
  grade?: GradeNodeRequest
  gradedCardParallel?: GradedCardParallelNodeRequest
  buyPriceCurrency?: boolean | number
  buyPrice?: boolean | number
  state?: boolean | number
  buyer?: UserTypeRequest
  seller?: UserTypeRequest
  item?: ItemNodeRequest
  affiliateCode?: boolean | number
  stripePaymentMethod?: StripePaymentMethodNodeRequest
  createdAt?: boolean | number
  completedAt?: boolean | number
  updatedAt?: boolean | number
  expiresAfter?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GroupedBidNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  createdAt?: boolean | number
  buyorderSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BuyOrderNodeConnectionRequest,
      ]
    | BuyOrderNodeConnectionRequest
  buyPrice?: boolean | number
  openBuyOrderCount?: boolean | number
  suspendedBuyOrderCount?: boolean | number
  lowestAskCount?: boolean | number
  lowestAskPrice?: boolean | number
  lowestAskPercentage?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SellOrderNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: SellOrderNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `SellOrderNode` and its cursor. */
export interface SellOrderNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: SellOrderNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SellOrderNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  groupedListing?: GroupedListingNodeRequest
  item?: ItemNodeRequest
  cardParallel?: CardParallelNodeRequest
  grade?: GradeNodeRequest
  gradedCardParallel?: GradedCardParallelNodeRequest
  askPriceCurrency?: boolean | number
  askPrice?: boolean | number
  state?: boolean | number
  seller?: UserTypeRequest
  buyer?: UserTypeRequest
  createdAt?: boolean | number
  completedAt?: boolean | number
  updatedAt?: boolean | number
  expiresAfter?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GroupedListingNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  createdAt?: boolean | number
  sellorderSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        SellOrderNodeConnectionRequest,
      ]
    | SellOrderNodeConnectionRequest
  askPrice?: boolean | number
  openSellOrderCount?: boolean | number
  highestBidCount?: boolean | number
  highestBidPrice?: boolean | number
  highestBidPercentage?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotItemNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: LotItemNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `LotItemNode` and its cursor. */
export interface LotItemNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: LotItemNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotItemNodeRequest {
  lot?: LotNodeRequest
  gradedCardParallel?: GradedCardParallelNodeRequest
  /** The specific collection item this lot item represents, if applicable. May be left blank if the item is not yet input into the marketplace. */
  item?: ItemNodeRequest
  /** The ID of the object. */
  id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotNodeRequest {
  order?: boolean | number
  name?: boolean | number
  /** A longer text description of this lot, such as an overview of the items included, and so on. */
  description?: boolean | number
  auction?: AuctionNodeRequest
  currency?: boolean | number
  openingPrice?: boolean | number
  initialDuration?: boolean | number
  newBidMinimumDuration?: boolean | number
  /** Display name for the collection this NFT belongs to. */
  nftCollectionName?: boolean | number
  state?: boolean | number
  /** Date and time at which this lot opened for bidding. */
  biddingOpenedAt?: boolean | number
  /** Date and time at which this lot is scheduled to conclude bidding. */
  biddingEndsAfter?: boolean | number
  /** Date and time at which this lot concluded bidding. */
  biddingEndedAt?: boolean | number
  /** Optional URL to link out to NFT information on an external website. */
  nftExternalUrl?: boolean | number
  bidSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BidNodeConnectionRequest,
      ]
    | BidNodeConnectionRequest
  lotitemSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        LotItemNodeConnectionRequest,
      ]
    | LotItemNodeConnectionRequest
  lotphotoSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        LotPhotoNodeConnectionRequest,
      ]
    | LotPhotoNodeConnectionRequest
  /** The ID of the object. */
  id?: boolean | number
  highestBid?: BidNodeRequest
  highestBidPrice?: boolean | number
  bidPriceIncrement?: boolean | number
  minimumNextBidPrice?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionNodeRequest {
  name?: boolean | number
  /** URL identifier for this auction. */
  slug?: boolean | number
  /** Assign a featured rank to promote this auction. Higher values take priority. Use sparingly; leave blank for most auctions. */
  featuredRank?: boolean | number
  /** A longer text description of this auction, such as an overview of the items included, special guests participating in the stream, and so on. */
  description?: boolean | number
  /** Date and time at which this auction is scheduled to begin. Presented to users as approximate. Leave blank if auction is not yet scheduled. */
  scheduledStartTime?: boolean | number
  state?: boolean | number
  /** The 11-digit video ID for the YouTube video this auction will be streamed on. */
  youtubeVideoId?: boolean | number
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId?: boolean | number
  /** The name for the Twitch channel this auction will be streamed on. */
  twitchChannelName?: boolean | number
  /** Choose where the event is visible and who can access it. */
  visibility?: boolean | number
  /** Determines whether bidding is restricted to a single lot at any given time, or allows for multiple lots to be bidding concurrently. */
  lotRunningFormat?: boolean | number
  /** Allow lots to be marked as open for bidding prior to the event going live. */
  allowPrebidding?: boolean | number
  currency?: boolean | number
  auctionphotoSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        AuctionPhotoNodeConnectionRequest,
      ]
    | AuctionPhotoNodeConnectionRequest
  lotSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          productCategory?: (String | null)[] | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        LotNodeConnectionRequest,
      ]
    | LotNodeConnectionRequest
  /** The ID of the object. */
  id?: boolean | number
  lotCount?: boolean | number
  lotItemCount?: boolean | number
  lotMinOpeningPrice?: boolean | number
  lotMaxOpeningPrice?: boolean | number
  lotRemainingCount?: boolean | number
  pusherChannelId?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionPhotoNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: AuctionPhotoNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `AuctionPhotoNode` and its cursor. */
export interface AuctionPhotoNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: AuctionPhotoNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionPhotoNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  auction?: AuctionNodeRequest
  photo?: boolean | number
  createdAt?: boolean | number
  photoXs?: boolean | number
  photoSm?: boolean | number
  photoMd?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: LotNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `LotNode` and its cursor. */
export interface LotNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: LotNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BidNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: BidNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `BidNode` and its cursor. */
export interface BidNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: BidNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BidNodeRequest {
  lot?: LotNodeRequest
  user?: UserTypeRequest
  bidPrice?: boolean | number
  createdAt?: boolean | number
  state?: boolean | number
  /** The ID of the object. */
  id?: boolean | number
  bidPriceCurrency?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotPhotoNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: LotPhotoNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `LotPhotoNode` and its cursor. */
export interface LotPhotoNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: LotPhotoNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotPhotoNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  lot?: LotNodeRequest
  photo?: boolean | number
  createdAt?: boolean | number
  photoXs?: boolean | number
  photoSm?: boolean | number
  photoMd?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface HitNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** User-facing display name for this hit (e.g. "🔥 EPIC PULL! Tom Brady Prizm Auto Silver #/10!") */
  name?: boolean | number
  /** An optional longer description, giving context to the break or hit. */
  description?: boolean | number
  /** The spot in the break that this hit is assigned to. */
  spot?: SpotNodeRequest
  /** The type and grade of the card hit. As these are pack-fresh pulls, we typically assume StarStock A grade. */
  gradedCardParallel?: GradedCardParallelNodeRequest
  /** The specific item in our database representing this hit. May be left blank prior to the card being processed. */
  item?: ItemNodeRequest
  createdAt?: boolean | number
  createdBy?: UserTypeRequest
  photo?: boolean | number
  photoXs?: boolean | number
  photoSm?: boolean | number
  photoMd?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SpotNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  boxBreak?: BoxBreakNodeRequest
  /** Spots are ordered within the parent break by their rank (asc). Rank will also be used as slot name if one is not specified (e.g. random breaks). */
  rank?: boolean | number
  /** Optional display name for this spot in the break (e.g. Cleveland Browns), or may be left blank to use rank. */
  name?: boolean | number
  priceCurrency?: boolean | number
  price?: boolean | number
  purchasedAt?: boolean | number
  /** The spot in the break that this hit is assigned to. */
  hitSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        HitNodeConnectionRequest,
      ]
    | HitNodeConnectionRequest
  personalbreakrequest?: PersonalBreakRequestNodeRequest
  isPersonalBreakRequest?: boolean | number
  myParticipant?: ParticipantNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface HitNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: HitNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `HitNode` and its cursor. */
export interface HitNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: HitNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PersonalBreakRequestNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  user?: UserTypeRequest
  itemOwnership?: ItemOwnershipNodeRequest
  /** The scheduled personal break that the user would like their box broken as part of. */
  boxBreak?: BoxBreakNodeRequest
  /** The spot in the break that represents this accepted request. */
  spot?: SpotNodeRequest
  /** Optional, additional request information, such as a preferred start time. */
  requestDetails?: boolean | number
  breakFeeCurrency?: boolean | number
  breakFee?: boolean | number
  createdAt?: boolean | number
  state?: boolean | number
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome?: boolean | number
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions?: boolean | number
  address?: AddressNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ItemOwnershipNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  item?: ItemNodeRequest
  owner?: UserTypeRequest
  boughtBuyorder?: BuyOrderNodeRequest
  boughtSellorder?: SellOrderNodeRequest
  soldBuyorder?: BuyOrderNodeRequest
  soldSellorder?: SellOrderNodeRequest
  boughtPriceCurrency?: boolean | number
  boughtPrice?: boolean | number
  soldPriceCurrency?: boolean | number
  soldPrice?: boolean | number
  startedAt?: boolean | number
  endedAt?: boolean | number
  personalbreakrequestSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        PersonalBreakRequestNodeConnectionRequest,
      ]
    | PersonalBreakRequestNodeConnectionRequest
  askPrice?: boolean | number
  askPriceValue?: boolean | number
  breakFee?: boolean | number
  inDraftShippingRequest?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PersonalBreakRequestNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: PersonalBreakRequestNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `PersonalBreakRequestNode` and its cursor. */
export interface PersonalBreakRequestNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: PersonalBreakRequestNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AddressNodeRequest {
  description?: boolean | number
  name?: boolean | number
  addressLines?: boolean | number
  locality?: boolean | number
  region?: boolean | number
  postalCode?: boolean | number
  country?: boolean | number
  role?: boolean | number
  default?: boolean | number
  /** The ID of the object. */
  id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ParticipantNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  boxBreak?: BoxBreakNodeRequest
  user?: UserTypeRequest
  joinedAt?: boolean | number
  /** The streaming platform on which the participant plans to watch. */
  liveStreamPlatform?: boolean | number
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome?: boolean | number
  address?: AddressNodeRequest
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions?: boolean | number
  /** The break participant who has claimed this spot (note that one participant may claim multiple spots). */
  spotSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        SpotNodeConnectionRequest,
      ]
    | SpotNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SpotNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: SpotNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `SpotNode` and its cursor. */
export interface SpotNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: SpotNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface WishlistItemNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: WishlistItemNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `WishlistItemNode` and its cursor. */
export interface WishlistItemNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: WishlistItemNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface WishlistItemNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  cardParallel?: CardParallelNodeRequest
  wisher?: UserTypeRequest
  createdAt?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface HistoricalPurchaseNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: HistoricalPurchaseNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `HistoricalPurchaseNode` and its cursor. */
export interface HistoricalPurchaseNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: HistoricalPurchaseNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface HistoricalPurchaseNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  cardParallel?: CardParallelNodeRequest
  grade?: GradeNodeRequest
  gradedCardParallel?: GradedCardParallelNodeRequest
  source?: boolean | number
  priceCurrency?: boolean | number
  price?: boolean | number
  buyer?: UserTypeRequest
  seller?: UserTypeRequest
  affiliateCode?: boolean | number
  saleDate?: boolean | number
  verified?: boolean | number
  softDeleted?: boolean | number
  quantity?: boolean | number
  date?: boolean | number
  myRole?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MarketIndexComponentNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: MarketIndexComponentNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `MarketIndexComponentNode` and its cursor. */
export interface MarketIndexComponentNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: MarketIndexComponentNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MarketIndexComponentNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  marketIndex?: MarketIndexNodeRequest
  /** Unique ranking for this component within the index. Represents relative strength of the component, disconnected from exact current value. */
  rank?: boolean | number
  /** Optional grouping category for this component within the index (e.g. a position in a single-sport index, a sport in a multi-sport index) */
  subcategory?: boolean | number
  player?: PlayerNodeRequest
  gradedCardParallel?: GradedCardParallelNodeRequest
  /** URL linking to the appropriate detail page for this component (e.g. a card or player page) */
  linkUrl?: boolean | number
  latestComponentSnapshot?: MarketIndexComponentSnapshotNodeRequest
  componentSnapshots?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        MarketIndexComponentSnapshotNodeConnectionRequest,
      ]
    | MarketIndexComponentSnapshotNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MarketIndexNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** A short alphanumeric symbol to represent this index (e.g. NFLP30 for the NFL Prizm 30 index) */
  symbol?: boolean | number
  name?: boolean | number
  /** URL path for this index */
  slug?: boolean | number
  /** Brief summary of this index and the components it includes */
  description?: boolean | number
  latestSnapshot?: MarketIndexSnapshotNodeRequest
  snapshots?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          name?: String | null
        },
        MarketIndexSnapshotNodeConnectionRequest,
      ]
    | MarketIndexSnapshotNodeConnectionRequest
  components?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          sport?: String | null
        },
        MarketIndexComponentNodeConnectionRequest,
      ]
    | MarketIndexComponentNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MarketIndexSnapshotNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  marketIndex?: MarketIndexNodeRequest
  /** The series this snapshot belongs to (e.g. weekly, daily...) */
  seriesName?: boolean | number
  date?: boolean | number
  /** An optional friendly label to identify this snapshot, e.g. 'NFL Week 1' or 'Superbowl' */
  name?: boolean | number
  value?: boolean | number
  valueChange?: boolean | number
  valueChangePercentage?: boolean | number
  normalisedValueMultiplier?: boolean | number
  normalisedValue?: boolean | number
  normalisedValueChange?: boolean | number
  normalisedValueChangePercentage?: boolean | number
  marketindex?: MarketIndexNodeRequest
  componentSnapshots?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        MarketIndexComponentSnapshotNodeConnectionRequest,
      ]
    | MarketIndexComponentSnapshotNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MarketIndexComponentSnapshotNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: MarketIndexComponentSnapshotNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `MarketIndexComponentSnapshotNode` and its cursor. */
export interface MarketIndexComponentSnapshotNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: MarketIndexComponentSnapshotNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MarketIndexComponentSnapshotNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  marketIndexComponent?: MarketIndexComponentNodeRequest
  marketIndexSnapshot?: MarketIndexSnapshotNodeRequest
  /** Set None to hide fantasy status from display */
  fantasyStatus?: boolean | number
  fantasyPoints?: boolean | number
  priceCurrency?: boolean | number
  price?: boolean | number
  priceChangeCurrency?: boolean | number
  priceChange?: boolean | number
  /** The percentage change of this price from the previous snapshot */
  priceChangePercentage?: boolean | number
  marketindexcomponent?: MarketIndexComponentNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MarketIndexSnapshotNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: MarketIndexSnapshotNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `MarketIndexSnapshotNode` and its cursor. */
export interface MarketIndexSnapshotNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: MarketIndexSnapshotNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxBreakPhotoNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: BoxBreakPhotoNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `BoxBreakPhotoNode` and its cursor. */
export interface BoxBreakPhotoNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: BoxBreakPhotoNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxBreakPhotoNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  boxBreak?: BoxBreakNodeRequest
  photo?: boolean | number
  createdAt?: boolean | number
  photoXs?: boolean | number
  photoSm?: boolean | number
  photoMd?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RemintNFTNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: RemintNFTNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `RemintNFTNode` and its cursor. */
export interface RemintNFTNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: RemintNFTNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RemintNFTNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Display name for this nft item. */
  name?: boolean | number
  /** Display name for the collection this nft item belongs to. */
  collectionName?: boolean | number
  blockchain?: boolean | number
  /** Optional Link to external URL. */
  externalLink?: boolean | number
  /** Optional Name of external URL page. */
  externalLinkText?: boolean | number
  image?: boolean | number
  /** Break in which this nft item is going to be or was opened. */
  boxBreak?: BoxBreakNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PlayerNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: PlayerNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `PlayerNode` and its cursor. */
export interface PlayerNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: PlayerNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StreamNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: StreamNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `StreamNode` and its cursor. */
export interface StreamNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: StreamNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ShippingRequestNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: ShippingRequestNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `ShippingRequestNode` and its cursor. */
export interface ShippingRequestNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: ShippingRequestNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface FixtureNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: FixtureNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `FixtureNode` and its cursor. */
export interface FixtureNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: FixtureNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserNotificationPreferenceNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  notificationType?: boolean | number
  targetType?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RecentSaleNodeRequest {
  cardParallel?: CardParallelNodeRequest
  grade?: GradeNodeRequest
  price?: boolean | number
  saleDate?: boolean | number
  quantity?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ItemOwnershipNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: ItemOwnershipNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `ItemOwnershipNode` and its cursor. */
export interface ItemOwnershipNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: ItemOwnershipNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GroupedBidNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: GroupedBidNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `GroupedBidNode` and its cursor. */
export interface GroupedBidNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: GroupedBidNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GroupedListingNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: GroupedListingNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `GroupedListingNode` and its cursor. */
export interface GroupedListingNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: GroupedListingNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MarketIndexNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: MarketIndexNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `MarketIndexNode` and its cursor. */
export interface MarketIndexNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: MarketIndexNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MarketWidgetNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  title?: boolean | number
  subtitle?: boolean | number
  rank?: boolean | number
  active?: boolean | number
  featuredResultKey?: boolean | number
  resultSetOrderBy?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GradeNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: GradeNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `GradeNode` and its cursor. */
export interface GradeNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: GradeNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PortfolioValueNodeRequest {
  openDate?: boolean | number
  closeDate?: boolean | number
  closeItemCount?: boolean | number
  closeSubmittedItemCount?: boolean | number
  closePurchasedItemCount?: boolean | number
  closeUnvaluedItemCount?: boolean | number
  closeValue?: boolean | number
  closeInvested?: boolean | number
  closeRoiValue?: boolean | number
  closeRoiPercent?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardSetNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: CardSetNodeEdgeRequest
  cardParallelCounts?: CardSetCountsRequest
  myCardParallelCounts?: CardSetCountsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `CardSetNode` and its cursor. */
export interface CardSetNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: CardSetNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CardSetCountsRequest {
  name?: boolean | number
  company?: boolean | number
  parallelCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: AuctionNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `AuctionNode` and its cursor. */
export interface AuctionNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: AuctionNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnnouncementNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  state?: boolean | number
  /** Automatically generated. Example: 2021-10-16-submit-for-grading-update */
  slug?: boolean | number
  /** Example: Submit for grading update */
  name?: boolean | number
  /** Text that will be highlighted in the announcement banner  */
  prefixText?: boolean | number
  /** Announcement body content */
  bodyText?: boolean | number
  /** Link title */
  linkTitle?: boolean | number
  /** URL linking to the announced target */
  linkUrl?: boolean | number
  style?: AnnouncementStyleNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnnouncementStyleNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Examples: Breaks, Product announcements, Technical announcements, Marketing, etc. */
  name?: boolean | number
  badgeTextColor?: boolean | number
  linkTextColor?: boolean | number
  badgeGradientStart?: boolean | number
  badgeGradientEnd?: boolean | number
  announcementbannerSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        AnnouncementNodeConnectionRequest,
      ]
    | AnnouncementNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnnouncementNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: AnnouncementNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `AnnouncementNode` and its cursor. */
export interface AnnouncementNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: AnnouncementNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PromotionTypeRequest {
  /** The ID of the object. */
  id?: boolean | number
  description?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MutationRequest {
  submitDraftShippingRequest?: [
    {
      addressLine1: String
      addressLine2?: String | null
      city: String
      country: String
      recipient: String
      state: String
      zipCode: String
    },
    SubmitDraftShippingRequestRequest,
  ]
  addItemsToDraftShippingRequest?: [{ itemsToAdd: (String | null)[] }, AddItemsToDraftShippingRequestRequest]
  removeItemsFromDraftShippingRequest?: [{ itemsToRemove: (String | null)[] }, RemoveItemsFromDraftShippingRequestRequest]
  addAllItemsToDraftShippingRequest?: AddAllItemsToDraftShippingRequestRequest
  removeAllItemsFromDraftShippingRequest?: RemoveAllItemsFromDraftShippingRequestRequest
  createPaymentIntent?: [{ amount: Int; saveCard?: Boolean | null }, CreatePaymentIntentRequest]
  createWithdrawalRequest?: [
    {
      addressLine1?: String | null
      addressLine2?: String | null
      amount: Float
      checkRecipient?: String | null
      city?: String | null
      country?: String | null
      paymentMethod: WithdrawalPaymentMethods
      paypalEmail?: String | null
      postalTracking?: Boolean | null
      state?: String | null
      zipCode?: String | null
    },
    CreateWithdrawalRequestRequest,
  ]
  deletePaymentMethod?: [{ paymentMethodId?: String | null }, DeletePaymentMethodRequest] | DeletePaymentMethodRequest
  setDefaultPaymentMethod?:
    | [{ paymentMethodId?: String | null }, SetDefaultPaymentMethodRequest]
    | SetDefaultPaymentMethodRequest
  createSetupIntent?: CreateSetupIntentRequest
  updateCryptoWalletBalance?: [
    { balanceAmount: Float; balanceCurrency: CryptoCurrencies; cryptoWalletId: String },
    UpdateCryptoWalletBalanceRequest,
  ]
  withdrawalConfirmation?:
    | [
        { token?: String | null; userIdB64?: String | null; withdrawalRequestId?: String | null },
        WithdrawalConfirmationRequest,
      ]
    | WithdrawalConfirmationRequest
  updateNotificationPreferences?:
    | [
        {
          buyTargetPercentage?: Int | null
          notificationPreferences?: (UpdateNotificationPreferencesInput | null)[] | null
          sellTargetPercentage?: Int | null
        },
        UpdateNotificationPreferencesRequest,
      ]
    | UpdateNotificationPreferencesRequest
  groupedBidClose?: [{ allBids?: Boolean | null; id: ID }, GroupedBidCloseRequest]
  groupedListingClose?: [{ allListings?: Boolean | null; id: ID }, GroupedListingCloseRequest]
  sellItems?: [
    {
      cardParallelId: ID
      closeSellOrders?: Boolean | null
      expiresAfter?: ExpiresAfter | null
      gradeId: ID
      minimumSalePrice: Float
      quantity: Int
    },
    SellItemsRequest,
  ]
  buyItems?: [
    {
      affiliateCode?: String | null
      cancelBidsPriced?: Float | null
      cardParallelId: ID
      expiresAfter?: ExpiresAfter | null
      gradeId: ID
      maximumBuyPrice: Float
      quantity: Int
    },
    BuyItemsRequest,
  ]
  buyOrderExtendExpiryDate?: [{ extendExpiry: ExpiresAfter; groupedBidId: ID }, BuyOrderExtendExpiryDateRequest]
  sellOrderExtendExpiryDate?: [{ extendExpiry: ExpiresAfter; groupedListingId: ID }, SellOrderExtendExpiryDateRequest]
  wishlistItemCreate?: [{ cardParallelId: ID }, WishlistItemCreateRequest]
  wishlistItemDelete?: [{ id: ID }, WishlistItemDeleteRequest]
  sendUserOwnershipsCsv?: SendUserOwnershipsCsvRequest
  buyBoxBreakSpots?: [
    {
      additionalInstructions?: String | null
      addressId: ID
      boxBreakId: ID
      paymentMethod: String
      preferShipHome?: Boolean | null
      spotIds: (ID | null)[]
    },
    BuyBoxBreakSpotsRequest,
  ]
  personalBreakRequestCreate?: [
    {
      additionalInstructions?: String | null
      addressId: ID
      boxBreakId: ID
      itemOwnershipId: ID
      preferShipHome?: Boolean | null
      requestDetails?: String | null
    },
    PersonalBreakRequestCreateRequest,
  ]
  bidCreate?: [{ bidCurrency?: String | null; bidPrice: Float; lotId: ID }, BidCreateRequest]
  /** Create a brand new address for the logged-in user. */
  createAddress?: [
    {
      addressLines: String
      country: String
      description: String
      locality: String
      name: String
      postalCode: String
      region: String
      role: String
    },
    CreateAddressRequest,
  ]
  /** Hide a specified address for the logged-in user. */
  deleteAddress?: [{ id: ID }, DeleteAddressRequest]
  /** Hide the existing address and create a replacement with the specified changes. */
  updateAddress?: [
    {
      addressLines: String
      country: String
      description: String
      id: ID
      locality: String
      name: String
      postalCode: String
      region: String
      role: String
    },
    UpdateAddressRequest,
  ]
  /** Mark an existing address as the default. */
  makeAddressDefault?: [{ id: ID }, MakeAddressDefaultRequest]
  tokenAuth?: [{ username: String; password: String }, ObtainJSONWebTokenRequest]
  userCreate?: [
    {
      email: String
      firstName: String
      lastName: String
      marketingEmails: Boolean
      password: String
      promoCode?: String | null
      username: String
    },
    UserCreateRequest,
  ]
  passwordChange?:
    | [{ newPassword?: String | null; oldPassword?: String | null }, PasswordChangeRequest]
    | PasswordChangeRequest
  passwordResetRequest?: [{ username?: String | null }, PasswordResetRequestRequest] | PasswordResetRequestRequest
  passwordResetChange?:
    | [{ newPassword?: String | null; token?: String | null; userIdB64?: String | null }, PasswordResetChangeRequest]
    | PasswordResetChangeRequest
  updateUserCollectionVisibility?: [{ isPublic: Boolean }, UpdateUserCollectionVisibilityRequest]
  verifyToken?: [{ token?: String | null }, VerifyRequest] | VerifyRequest
  refreshToken?: [{ refreshToken?: String | null }, RefreshRequest] | RefreshRequest
  deleteTokenCookie?: DeleteJSONWebTokenCookieRequest
  deleteRefreshTokenCookie?: DeleteRefreshTokenCookieRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SubmitDraftShippingRequestRequest {
  /** List of errors that occured executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Represents an error in the input of a mutation. */
export interface ErrorRequest {
  /** Name of the field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field?: boolean | number
  /** The error message. */
  message?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AddItemsToDraftShippingRequestRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  itemCount?: boolean | number
  addedItems?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RemoveItemsFromDraftShippingRequestRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  itemCount?: boolean | number
  removedItems?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AddAllItemsToDraftShippingRequestRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  itemCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RemoveAllItemsFromDraftShippingRequestRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  itemCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CreatePaymentIntentRequest {
  paymentIntentToken?: boolean | number
  paymentSequence?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CreateWithdrawalRequestRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DeletePaymentMethodRequest {
  paymentMethodId?: boolean | number
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SetDefaultPaymentMethodRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CreateSetupIntentRequest {
  setupIntentClientSecret?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UpdateCryptoWalletBalanceRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface WithdrawalConfirmationRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UpdateNotificationPreferencesInput {
  notificationType: NotificationTypes
  targetType: TargetTypes
}

export interface UpdateNotificationPreferencesRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GroupedBidCloseRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GroupedListingCloseRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SellItemsRequest {
  soldCount?: boolean | number
  listedCount?: boolean | number
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BuyItemsRequest {
  boughtCount?: boolean | number
  listedCount?: boolean | number
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BuyOrderExtendExpiryDateRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SellOrderExtendExpiryDateRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface WishlistItemCreateRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  wishlistItem?: WishlistItemNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface WishlistItemDeleteRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  wishlistItem?: WishlistItemNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SendUserOwnershipsCsvRequest {
  ok?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BuyBoxBreakSpotsRequest {
  purchasedSpotsCount?: boolean | number
  purchasedSpotsCost?: boolean | number
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PersonalBreakRequestCreateRequest {
  purchasedSpotsCount?: boolean | number
  purchasedSpotsCost?: boolean | number
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BidCreateRequest {
  bid?: BidNodeRequest
  lot?: LotNodeRequest
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Create a brand new address for the logged-in user. */
export interface CreateAddressRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Hide a specified address for the logged-in user. */
export interface DeleteAddressRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Hide the existing address and create a replacement with the specified changes. */
export interface UpdateAddressRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Mark an existing address as the default. */
export interface MakeAddressDefaultRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ObtainJSONWebTokenRequest {
  payload?: boolean | number
  refreshExpiresIn?: boolean | number
  token?: boolean | number
  refreshToken?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserCreateRequest {
  user?: UserTypeRequest
  refreshToken?: boolean | number
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PasswordChangeRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PasswordResetRequestRequest {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PasswordResetChangeRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UpdateUserCollectionVisibilityRequest {
  ok?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VerifyRequest {
  payload?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RefreshRequest {
  payload?: boolean | number
  refreshExpiresIn?: boolean | number
  token?: boolean | number
  refreshToken?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DeleteJSONWebTokenCookieRequest {
  deleted?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DeleteRefreshTokenCookieRequest {
  deleted?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

const Query_possibleTypes = ['Query']
export const isQuery = (obj: { __typename: String }): obj is Query => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Query_possibleTypes.includes(obj.__typename)
}

const StripePaymentMethodNode_possibleTypes = ['StripePaymentMethodNode']
export const isStripePaymentMethodNode = (obj: { __typename: String }): obj is StripePaymentMethodNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StripePaymentMethodNode_possibleTypes.includes(obj.__typename)
}

const Node_possibleTypes = [
  'StripePaymentMethodNode',
  'ChannelNode',
  'EventNode',
  'StreamNode',
  'BoxBreakNode',
  'FormatNode',
  'BreakLocationNode',
  'BoxNode',
  'GradedCardParallelNode',
  'CardParallelNode',
  'SportNode',
  'TeamNode',
  'CardProgramNode',
  'CardSetNode',
  'CardSetParallelNode',
  'CardParallelSearchResult',
  'PlayerCardNode',
  'TeamPlayerNode',
  'PlayerNode',
  'FixtureTeamPlayerNode',
  'FixtureNode',
  'CardFamilyNode',
  'ItemNode',
  'GradeNode',
  'ShippingItemNode',
  'ShippingRequestNode',
  'BuyOrderNode',
  'GroupedBidNode',
  'SellOrderNode',
  'GroupedListingNode',
  'LotItemNode',
  'LotNode',
  'AuctionNode',
  'AuctionPhotoNode',
  'BidNode',
  'LotPhotoNode',
  'HitNode',
  'SpotNode',
  'PersonalBreakRequestNode',
  'ItemOwnershipNode',
  'AddressNode',
  'ParticipantNode',
  'WishlistItemNode',
  'HistoricalPurchaseNode',
  'MarketIndexComponentNode',
  'MarketIndexNode',
  'MarketIndexSnapshotNode',
  'MarketIndexComponentSnapshotNode',
  'BoxBreakPhotoNode',
  'RemintNFTNode',
  'UserNotificationPreferenceNode',
  'MarketWidgetNode',
  'AnnouncementNode',
  'AnnouncementStyleNode',
  'PromotionType',
]
export const isNode = (obj: { __typename: String }): obj is Node => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Node_possibleTypes.includes(obj.__typename)
}

const ChannelNodeConnection_possibleTypes = ['ChannelNodeConnection']
export const isChannelNodeConnection = (obj: { __typename: String }): obj is ChannelNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ChannelNodeConnection_possibleTypes.includes(obj.__typename)
}

const PageInfo_possibleTypes = ['PageInfo']
export const isPageInfo = (obj: { __typename: String }): obj is PageInfo => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PageInfo_possibleTypes.includes(obj.__typename)
}

const ChannelNodeEdge_possibleTypes = ['ChannelNodeEdge']
export const isChannelNodeEdge = (obj: { __typename: String }): obj is ChannelNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ChannelNodeEdge_possibleTypes.includes(obj.__typename)
}

const ChannelNode_possibleTypes = ['ChannelNode']
export const isChannelNode = (obj: { __typename: String }): obj is ChannelNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ChannelNode_possibleTypes.includes(obj.__typename)
}

const EventNodeConnection_possibleTypes = ['EventNodeConnection']
export const isEventNodeConnection = (obj: { __typename: String }): obj is EventNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return EventNodeConnection_possibleTypes.includes(obj.__typename)
}

const EventNodeEdge_possibleTypes = ['EventNodeEdge']
export const isEventNodeEdge = (obj: { __typename: String }): obj is EventNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return EventNodeEdge_possibleTypes.includes(obj.__typename)
}

const EventNode_possibleTypes = ['EventNode']
export const isEventNode = (obj: { __typename: String }): obj is EventNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return EventNode_possibleTypes.includes(obj.__typename)
}

const StreamNode_possibleTypes = ['StreamNode']
export const isStreamNode = (obj: { __typename: String }): obj is StreamNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StreamNode_possibleTypes.includes(obj.__typename)
}

const BoxBreakNode_possibleTypes = ['BoxBreakNode']
export const isBoxBreakNode = (obj: { __typename: String }): obj is BoxBreakNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakNode_possibleTypes.includes(obj.__typename)
}

const FormatNode_possibleTypes = ['FormatNode']
export const isFormatNode = (obj: { __typename: String }): obj is FormatNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FormatNode_possibleTypes.includes(obj.__typename)
}

const BoxBreakNodeConnection_possibleTypes = ['BoxBreakNodeConnection']
export const isBoxBreakNodeConnection = (obj: { __typename: String }): obj is BoxBreakNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakNodeConnection_possibleTypes.includes(obj.__typename)
}

const BoxBreakNodeEdge_possibleTypes = ['BoxBreakNodeEdge']
export const isBoxBreakNodeEdge = (obj: { __typename: String }): obj is BoxBreakNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakNodeEdge_possibleTypes.includes(obj.__typename)
}

const UserType_possibleTypes = ['UserType']
export const isUserType = (obj: { __typename: String }): obj is UserType => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserType_possibleTypes.includes(obj.__typename)
}

const UserProfileType_possibleTypes = ['UserProfileType']
export const isUserProfileType = (obj: { __typename: String }): obj is UserProfileType => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserProfileType_possibleTypes.includes(obj.__typename)
}

const BreakLocationNode_possibleTypes = ['BreakLocationNode']
export const isBreakLocationNode = (obj: { __typename: String }): obj is BreakLocationNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BreakLocationNode_possibleTypes.includes(obj.__typename)
}

const BoxNodeConnection_possibleTypes = ['BoxNodeConnection']
export const isBoxNodeConnection = (obj: { __typename: String }): obj is BoxNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxNodeConnection_possibleTypes.includes(obj.__typename)
}

const BoxNodeEdge_possibleTypes = ['BoxNodeEdge']
export const isBoxNodeEdge = (obj: { __typename: String }): obj is BoxNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxNodeEdge_possibleTypes.includes(obj.__typename)
}

const BoxNode_possibleTypes = ['BoxNode']
export const isBoxNode = (obj: { __typename: String }): obj is BoxNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxNode_possibleTypes.includes(obj.__typename)
}

const GradedCardParallelNode_possibleTypes = ['GradedCardParallelNode']
export const isGradedCardParallelNode = (obj: { __typename: String }): obj is GradedCardParallelNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GradedCardParallelNode_possibleTypes.includes(obj.__typename)
}

const CardParallelNode_possibleTypes = ['CardParallelNode']
export const isCardParallelNode = (obj: { __typename: String }): obj is CardParallelNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardParallelNode_possibleTypes.includes(obj.__typename)
}

const SportNode_possibleTypes = ['SportNode']
export const isSportNode = (obj: { __typename: String }): obj is SportNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SportNode_possibleTypes.includes(obj.__typename)
}

const TeamNodeConnection_possibleTypes = ['TeamNodeConnection']
export const isTeamNodeConnection = (obj: { __typename: String }): obj is TeamNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TeamNodeConnection_possibleTypes.includes(obj.__typename)
}

const TeamNodeEdge_possibleTypes = ['TeamNodeEdge']
export const isTeamNodeEdge = (obj: { __typename: String }): obj is TeamNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TeamNodeEdge_possibleTypes.includes(obj.__typename)
}

const TeamNode_possibleTypes = ['TeamNode']
export const isTeamNode = (obj: { __typename: String }): obj is TeamNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TeamNode_possibleTypes.includes(obj.__typename)
}

const CardProgramNodeConnection_possibleTypes = ['CardProgramNodeConnection']
export const isCardProgramNodeConnection = (obj: { __typename: String }): obj is CardProgramNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardProgramNodeConnection_possibleTypes.includes(obj.__typename)
}

const CardProgramNodeEdge_possibleTypes = ['CardProgramNodeEdge']
export const isCardProgramNodeEdge = (obj: { __typename: String }): obj is CardProgramNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardProgramNodeEdge_possibleTypes.includes(obj.__typename)
}

const CardProgramNode_possibleTypes = ['CardProgramNode']
export const isCardProgramNode = (obj: { __typename: String }): obj is CardProgramNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardProgramNode_possibleTypes.includes(obj.__typename)
}

const CardSetNode_possibleTypes = ['CardSetNode']
export const isCardSetNode = (obj: { __typename: String }): obj is CardSetNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardSetNode_possibleTypes.includes(obj.__typename)
}

const CardSetParallelNodeConnection_possibleTypes = ['CardSetParallelNodeConnection']
export const isCardSetParallelNodeConnection = (obj: { __typename: String }): obj is CardSetParallelNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardSetParallelNodeConnection_possibleTypes.includes(obj.__typename)
}

const CardSetParallelNodeEdge_possibleTypes = ['CardSetParallelNodeEdge']
export const isCardSetParallelNodeEdge = (obj: { __typename: String }): obj is CardSetParallelNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardSetParallelNodeEdge_possibleTypes.includes(obj.__typename)
}

const CardSetParallelNode_possibleTypes = ['CardSetParallelNode']
export const isCardSetParallelNode = (obj: { __typename: String }): obj is CardSetParallelNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardSetParallelNode_possibleTypes.includes(obj.__typename)
}

const CardParallelNodeConnection_possibleTypes = ['CardParallelNodeConnection']
export const isCardParallelNodeConnection = (obj: { __typename: String }): obj is CardParallelNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardParallelNodeConnection_possibleTypes.includes(obj.__typename)
}

const CardParallelNodeEdge_possibleTypes = ['CardParallelNodeEdge']
export const isCardParallelNodeEdge = (obj: { __typename: String }): obj is CardParallelNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardParallelNodeEdge_possibleTypes.includes(obj.__typename)
}

const CardParallelStatsNode_possibleTypes = ['CardParallelStatsNode']
export const isCardParallelStatsNode = (obj: { __typename: String }): obj is CardParallelStatsNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardParallelStatsNode_possibleTypes.includes(obj.__typename)
}

const CardParallelSearchResultConnection_possibleTypes = ['CardParallelSearchResultConnection']
export const isCardParallelSearchResultConnection = (obj: {
  __typename: String
}): obj is CardParallelSearchResultConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardParallelSearchResultConnection_possibleTypes.includes(obj.__typename)
}

const CardParallelSearchResultEdge_possibleTypes = ['CardParallelSearchResultEdge']
export const isCardParallelSearchResultEdge = (obj: { __typename: String }): obj is CardParallelSearchResultEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardParallelSearchResultEdge_possibleTypes.includes(obj.__typename)
}

const CardParallelSearchResult_possibleTypes = ['CardParallelSearchResult']
export const isCardParallelSearchResult = (obj: { __typename: String }): obj is CardParallelSearchResult => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardParallelSearchResult_possibleTypes.includes(obj.__typename)
}

const PlayerCardNode_possibleTypes = ['PlayerCardNode']
export const isPlayerCardNode = (obj: { __typename: String }): obj is PlayerCardNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PlayerCardNode_possibleTypes.includes(obj.__typename)
}

const TeamPlayerNodeConnection_possibleTypes = ['TeamPlayerNodeConnection']
export const isTeamPlayerNodeConnection = (obj: { __typename: String }): obj is TeamPlayerNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TeamPlayerNodeConnection_possibleTypes.includes(obj.__typename)
}

const TeamPlayerNodeEdge_possibleTypes = ['TeamPlayerNodeEdge']
export const isTeamPlayerNodeEdge = (obj: { __typename: String }): obj is TeamPlayerNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TeamPlayerNodeEdge_possibleTypes.includes(obj.__typename)
}

const TeamPlayerNode_possibleTypes = ['TeamPlayerNode']
export const isTeamPlayerNode = (obj: { __typename: String }): obj is TeamPlayerNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TeamPlayerNode_possibleTypes.includes(obj.__typename)
}

const PlayerNode_possibleTypes = ['PlayerNode']
export const isPlayerNode = (obj: { __typename: String }): obj is PlayerNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PlayerNode_possibleTypes.includes(obj.__typename)
}

const PlayerTradeStatsNode_possibleTypes = ['PlayerTradeStatsNode']
export const isPlayerTradeStatsNode = (obj: { __typename: String }): obj is PlayerTradeStatsNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PlayerTradeStatsNode_possibleTypes.includes(obj.__typename)
}

const FixtureTeamPlayerNodeConnection_possibleTypes = ['FixtureTeamPlayerNodeConnection']
export const isFixtureTeamPlayerNodeConnection = (obj: { __typename: String }): obj is FixtureTeamPlayerNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FixtureTeamPlayerNodeConnection_possibleTypes.includes(obj.__typename)
}

const FixtureTeamPlayerNodeEdge_possibleTypes = ['FixtureTeamPlayerNodeEdge']
export const isFixtureTeamPlayerNodeEdge = (obj: { __typename: String }): obj is FixtureTeamPlayerNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FixtureTeamPlayerNodeEdge_possibleTypes.includes(obj.__typename)
}

const FixtureTeamPlayerNode_possibleTypes = ['FixtureTeamPlayerNode']
export const isFixtureTeamPlayerNode = (obj: { __typename: String }): obj is FixtureTeamPlayerNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FixtureTeamPlayerNode_possibleTypes.includes(obj.__typename)
}

const FixtureNode_possibleTypes = ['FixtureNode']
export const isFixtureNode = (obj: { __typename: String }): obj is FixtureNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FixtureNode_possibleTypes.includes(obj.__typename)
}

const PlayerStatNode_possibleTypes = ['PlayerStatNode']
export const isPlayerStatNode = (obj: { __typename: String }): obj is PlayerStatNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PlayerStatNode_possibleTypes.includes(obj.__typename)
}

const PlayerCardNodeConnection_possibleTypes = ['PlayerCardNodeConnection']
export const isPlayerCardNodeConnection = (obj: { __typename: String }): obj is PlayerCardNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PlayerCardNodeConnection_possibleTypes.includes(obj.__typename)
}

const PlayerCardNodeEdge_possibleTypes = ['PlayerCardNodeEdge']
export const isPlayerCardNodeEdge = (obj: { __typename: String }): obj is PlayerCardNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PlayerCardNodeEdge_possibleTypes.includes(obj.__typename)
}

const CardFamilyNode_possibleTypes = ['CardFamilyNode']
export const isCardFamilyNode = (obj: { __typename: String }): obj is CardFamilyNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardFamilyNode_possibleTypes.includes(obj.__typename)
}

const GradedCardParallelNodeConnection_possibleTypes = ['GradedCardParallelNodeConnection']
export const isGradedCardParallelNodeConnection = (obj: { __typename: String }): obj is GradedCardParallelNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GradedCardParallelNodeConnection_possibleTypes.includes(obj.__typename)
}

const GradedCardParallelNodeEdge_possibleTypes = ['GradedCardParallelNodeEdge']
export const isGradedCardParallelNodeEdge = (obj: { __typename: String }): obj is GradedCardParallelNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GradedCardParallelNodeEdge_possibleTypes.includes(obj.__typename)
}

const CardFamilyNodeConnection_possibleTypes = ['CardFamilyNodeConnection']
export const isCardFamilyNodeConnection = (obj: { __typename: String }): obj is CardFamilyNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardFamilyNodeConnection_possibleTypes.includes(obj.__typename)
}

const CardFamilyNodeEdge_possibleTypes = ['CardFamilyNodeEdge']
export const isCardFamilyNodeEdge = (obj: { __typename: String }): obj is CardFamilyNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardFamilyNodeEdge_possibleTypes.includes(obj.__typename)
}

const ItemNodeConnection_possibleTypes = ['ItemNodeConnection']
export const isItemNodeConnection = (obj: { __typename: String }): obj is ItemNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ItemNodeConnection_possibleTypes.includes(obj.__typename)
}

const ItemNodeEdge_possibleTypes = ['ItemNodeEdge']
export const isItemNodeEdge = (obj: { __typename: String }): obj is ItemNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ItemNodeEdge_possibleTypes.includes(obj.__typename)
}

const ItemNode_possibleTypes = ['ItemNode']
export const isItemNode = (obj: { __typename: String }): obj is ItemNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ItemNode_possibleTypes.includes(obj.__typename)
}

const GradeNode_possibleTypes = ['GradeNode']
export const isGradeNode = (obj: { __typename: String }): obj is GradeNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GradeNode_possibleTypes.includes(obj.__typename)
}

const ShippingItemNodeConnection_possibleTypes = ['ShippingItemNodeConnection']
export const isShippingItemNodeConnection = (obj: { __typename: String }): obj is ShippingItemNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ShippingItemNodeConnection_possibleTypes.includes(obj.__typename)
}

const ShippingItemNodeEdge_possibleTypes = ['ShippingItemNodeEdge']
export const isShippingItemNodeEdge = (obj: { __typename: String }): obj is ShippingItemNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ShippingItemNodeEdge_possibleTypes.includes(obj.__typename)
}

const ShippingItemNode_possibleTypes = ['ShippingItemNode']
export const isShippingItemNode = (obj: { __typename: String }): obj is ShippingItemNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ShippingItemNode_possibleTypes.includes(obj.__typename)
}

const ShippingRequestNode_possibleTypes = ['ShippingRequestNode']
export const isShippingRequestNode = (obj: { __typename: String }): obj is ShippingRequestNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ShippingRequestNode_possibleTypes.includes(obj.__typename)
}

const BuyOrderNodeConnection_possibleTypes = ['BuyOrderNodeConnection']
export const isBuyOrderNodeConnection = (obj: { __typename: String }): obj is BuyOrderNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BuyOrderNodeConnection_possibleTypes.includes(obj.__typename)
}

const BuyOrderNodeEdge_possibleTypes = ['BuyOrderNodeEdge']
export const isBuyOrderNodeEdge = (obj: { __typename: String }): obj is BuyOrderNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BuyOrderNodeEdge_possibleTypes.includes(obj.__typename)
}

const BuyOrderNode_possibleTypes = ['BuyOrderNode']
export const isBuyOrderNode = (obj: { __typename: String }): obj is BuyOrderNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BuyOrderNode_possibleTypes.includes(obj.__typename)
}

const GroupedBidNode_possibleTypes = ['GroupedBidNode']
export const isGroupedBidNode = (obj: { __typename: String }): obj is GroupedBidNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GroupedBidNode_possibleTypes.includes(obj.__typename)
}

const SellOrderNodeConnection_possibleTypes = ['SellOrderNodeConnection']
export const isSellOrderNodeConnection = (obj: { __typename: String }): obj is SellOrderNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SellOrderNodeConnection_possibleTypes.includes(obj.__typename)
}

const SellOrderNodeEdge_possibleTypes = ['SellOrderNodeEdge']
export const isSellOrderNodeEdge = (obj: { __typename: String }): obj is SellOrderNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SellOrderNodeEdge_possibleTypes.includes(obj.__typename)
}

const SellOrderNode_possibleTypes = ['SellOrderNode']
export const isSellOrderNode = (obj: { __typename: String }): obj is SellOrderNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SellOrderNode_possibleTypes.includes(obj.__typename)
}

const GroupedListingNode_possibleTypes = ['GroupedListingNode']
export const isGroupedListingNode = (obj: { __typename: String }): obj is GroupedListingNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GroupedListingNode_possibleTypes.includes(obj.__typename)
}

const LotItemNodeConnection_possibleTypes = ['LotItemNodeConnection']
export const isLotItemNodeConnection = (obj: { __typename: String }): obj is LotItemNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotItemNodeConnection_possibleTypes.includes(obj.__typename)
}

const LotItemNodeEdge_possibleTypes = ['LotItemNodeEdge']
export const isLotItemNodeEdge = (obj: { __typename: String }): obj is LotItemNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotItemNodeEdge_possibleTypes.includes(obj.__typename)
}

const LotItemNode_possibleTypes = ['LotItemNode']
export const isLotItemNode = (obj: { __typename: String }): obj is LotItemNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotItemNode_possibleTypes.includes(obj.__typename)
}

const LotNode_possibleTypes = ['LotNode']
export const isLotNode = (obj: { __typename: String }): obj is LotNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotNode_possibleTypes.includes(obj.__typename)
}

const AuctionNode_possibleTypes = ['AuctionNode']
export const isAuctionNode = (obj: { __typename: String }): obj is AuctionNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionNode_possibleTypes.includes(obj.__typename)
}

const AuctionPhotoNodeConnection_possibleTypes = ['AuctionPhotoNodeConnection']
export const isAuctionPhotoNodeConnection = (obj: { __typename: String }): obj is AuctionPhotoNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionPhotoNodeConnection_possibleTypes.includes(obj.__typename)
}

const AuctionPhotoNodeEdge_possibleTypes = ['AuctionPhotoNodeEdge']
export const isAuctionPhotoNodeEdge = (obj: { __typename: String }): obj is AuctionPhotoNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionPhotoNodeEdge_possibleTypes.includes(obj.__typename)
}

const AuctionPhotoNode_possibleTypes = ['AuctionPhotoNode']
export const isAuctionPhotoNode = (obj: { __typename: String }): obj is AuctionPhotoNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionPhotoNode_possibleTypes.includes(obj.__typename)
}

const LotNodeConnection_possibleTypes = ['LotNodeConnection']
export const isLotNodeConnection = (obj: { __typename: String }): obj is LotNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotNodeConnection_possibleTypes.includes(obj.__typename)
}

const LotNodeEdge_possibleTypes = ['LotNodeEdge']
export const isLotNodeEdge = (obj: { __typename: String }): obj is LotNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotNodeEdge_possibleTypes.includes(obj.__typename)
}

const BidNodeConnection_possibleTypes = ['BidNodeConnection']
export const isBidNodeConnection = (obj: { __typename: String }): obj is BidNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BidNodeConnection_possibleTypes.includes(obj.__typename)
}

const BidNodeEdge_possibleTypes = ['BidNodeEdge']
export const isBidNodeEdge = (obj: { __typename: String }): obj is BidNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BidNodeEdge_possibleTypes.includes(obj.__typename)
}

const BidNode_possibleTypes = ['BidNode']
export const isBidNode = (obj: { __typename: String }): obj is BidNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BidNode_possibleTypes.includes(obj.__typename)
}

const LotPhotoNodeConnection_possibleTypes = ['LotPhotoNodeConnection']
export const isLotPhotoNodeConnection = (obj: { __typename: String }): obj is LotPhotoNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotPhotoNodeConnection_possibleTypes.includes(obj.__typename)
}

const LotPhotoNodeEdge_possibleTypes = ['LotPhotoNodeEdge']
export const isLotPhotoNodeEdge = (obj: { __typename: String }): obj is LotPhotoNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotPhotoNodeEdge_possibleTypes.includes(obj.__typename)
}

const LotPhotoNode_possibleTypes = ['LotPhotoNode']
export const isLotPhotoNode = (obj: { __typename: String }): obj is LotPhotoNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotPhotoNode_possibleTypes.includes(obj.__typename)
}

const HitNode_possibleTypes = ['HitNode']
export const isHitNode = (obj: { __typename: String }): obj is HitNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return HitNode_possibleTypes.includes(obj.__typename)
}

const SpotNode_possibleTypes = ['SpotNode']
export const isSpotNode = (obj: { __typename: String }): obj is SpotNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SpotNode_possibleTypes.includes(obj.__typename)
}

const HitNodeConnection_possibleTypes = ['HitNodeConnection']
export const isHitNodeConnection = (obj: { __typename: String }): obj is HitNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return HitNodeConnection_possibleTypes.includes(obj.__typename)
}

const HitNodeEdge_possibleTypes = ['HitNodeEdge']
export const isHitNodeEdge = (obj: { __typename: String }): obj is HitNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return HitNodeEdge_possibleTypes.includes(obj.__typename)
}

const PersonalBreakRequestNode_possibleTypes = ['PersonalBreakRequestNode']
export const isPersonalBreakRequestNode = (obj: { __typename: String }): obj is PersonalBreakRequestNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PersonalBreakRequestNode_possibleTypes.includes(obj.__typename)
}

const ItemOwnershipNode_possibleTypes = ['ItemOwnershipNode']
export const isItemOwnershipNode = (obj: { __typename: String }): obj is ItemOwnershipNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ItemOwnershipNode_possibleTypes.includes(obj.__typename)
}

const PersonalBreakRequestNodeConnection_possibleTypes = ['PersonalBreakRequestNodeConnection']
export const isPersonalBreakRequestNodeConnection = (obj: {
  __typename: String
}): obj is PersonalBreakRequestNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PersonalBreakRequestNodeConnection_possibleTypes.includes(obj.__typename)
}

const PersonalBreakRequestNodeEdge_possibleTypes = ['PersonalBreakRequestNodeEdge']
export const isPersonalBreakRequestNodeEdge = (obj: { __typename: String }): obj is PersonalBreakRequestNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PersonalBreakRequestNodeEdge_possibleTypes.includes(obj.__typename)
}

const AddressNode_possibleTypes = ['AddressNode']
export const isAddressNode = (obj: { __typename: String }): obj is AddressNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AddressNode_possibleTypes.includes(obj.__typename)
}

const ParticipantNode_possibleTypes = ['ParticipantNode']
export const isParticipantNode = (obj: { __typename: String }): obj is ParticipantNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ParticipantNode_possibleTypes.includes(obj.__typename)
}

const SpotNodeConnection_possibleTypes = ['SpotNodeConnection']
export const isSpotNodeConnection = (obj: { __typename: String }): obj is SpotNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SpotNodeConnection_possibleTypes.includes(obj.__typename)
}

const SpotNodeEdge_possibleTypes = ['SpotNodeEdge']
export const isSpotNodeEdge = (obj: { __typename: String }): obj is SpotNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SpotNodeEdge_possibleTypes.includes(obj.__typename)
}

const WishlistItemNodeConnection_possibleTypes = ['WishlistItemNodeConnection']
export const isWishlistItemNodeConnection = (obj: { __typename: String }): obj is WishlistItemNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return WishlistItemNodeConnection_possibleTypes.includes(obj.__typename)
}

const WishlistItemNodeEdge_possibleTypes = ['WishlistItemNodeEdge']
export const isWishlistItemNodeEdge = (obj: { __typename: String }): obj is WishlistItemNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return WishlistItemNodeEdge_possibleTypes.includes(obj.__typename)
}

const WishlistItemNode_possibleTypes = ['WishlistItemNode']
export const isWishlistItemNode = (obj: { __typename: String }): obj is WishlistItemNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return WishlistItemNode_possibleTypes.includes(obj.__typename)
}

const HistoricalPurchaseNodeConnection_possibleTypes = ['HistoricalPurchaseNodeConnection']
export const isHistoricalPurchaseNodeConnection = (obj: { __typename: String }): obj is HistoricalPurchaseNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return HistoricalPurchaseNodeConnection_possibleTypes.includes(obj.__typename)
}

const HistoricalPurchaseNodeEdge_possibleTypes = ['HistoricalPurchaseNodeEdge']
export const isHistoricalPurchaseNodeEdge = (obj: { __typename: String }): obj is HistoricalPurchaseNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return HistoricalPurchaseNodeEdge_possibleTypes.includes(obj.__typename)
}

const HistoricalPurchaseNode_possibleTypes = ['HistoricalPurchaseNode']
export const isHistoricalPurchaseNode = (obj: { __typename: String }): obj is HistoricalPurchaseNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return HistoricalPurchaseNode_possibleTypes.includes(obj.__typename)
}

const MarketIndexComponentNodeConnection_possibleTypes = ['MarketIndexComponentNodeConnection']
export const isMarketIndexComponentNodeConnection = (obj: {
  __typename: String
}): obj is MarketIndexComponentNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexComponentNodeConnection_possibleTypes.includes(obj.__typename)
}

const MarketIndexComponentNodeEdge_possibleTypes = ['MarketIndexComponentNodeEdge']
export const isMarketIndexComponentNodeEdge = (obj: { __typename: String }): obj is MarketIndexComponentNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexComponentNodeEdge_possibleTypes.includes(obj.__typename)
}

const MarketIndexComponentNode_possibleTypes = ['MarketIndexComponentNode']
export const isMarketIndexComponentNode = (obj: { __typename: String }): obj is MarketIndexComponentNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexComponentNode_possibleTypes.includes(obj.__typename)
}

const MarketIndexNode_possibleTypes = ['MarketIndexNode']
export const isMarketIndexNode = (obj: { __typename: String }): obj is MarketIndexNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexNode_possibleTypes.includes(obj.__typename)
}

const MarketIndexSnapshotNode_possibleTypes = ['MarketIndexSnapshotNode']
export const isMarketIndexSnapshotNode = (obj: { __typename: String }): obj is MarketIndexSnapshotNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexSnapshotNode_possibleTypes.includes(obj.__typename)
}

const MarketIndexComponentSnapshotNodeConnection_possibleTypes = ['MarketIndexComponentSnapshotNodeConnection']
export const isMarketIndexComponentSnapshotNodeConnection = (obj: {
  __typename: String
}): obj is MarketIndexComponentSnapshotNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexComponentSnapshotNodeConnection_possibleTypes.includes(obj.__typename)
}

const MarketIndexComponentSnapshotNodeEdge_possibleTypes = ['MarketIndexComponentSnapshotNodeEdge']
export const isMarketIndexComponentSnapshotNodeEdge = (obj: {
  __typename: String
}): obj is MarketIndexComponentSnapshotNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexComponentSnapshotNodeEdge_possibleTypes.includes(obj.__typename)
}

const MarketIndexComponentSnapshotNode_possibleTypes = ['MarketIndexComponentSnapshotNode']
export const isMarketIndexComponentSnapshotNode = (obj: { __typename: String }): obj is MarketIndexComponentSnapshotNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexComponentSnapshotNode_possibleTypes.includes(obj.__typename)
}

const MarketIndexSnapshotNodeConnection_possibleTypes = ['MarketIndexSnapshotNodeConnection']
export const isMarketIndexSnapshotNodeConnection = (obj: {
  __typename: String
}): obj is MarketIndexSnapshotNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexSnapshotNodeConnection_possibleTypes.includes(obj.__typename)
}

const MarketIndexSnapshotNodeEdge_possibleTypes = ['MarketIndexSnapshotNodeEdge']
export const isMarketIndexSnapshotNodeEdge = (obj: { __typename: String }): obj is MarketIndexSnapshotNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexSnapshotNodeEdge_possibleTypes.includes(obj.__typename)
}

const BoxBreakPhotoNodeConnection_possibleTypes = ['BoxBreakPhotoNodeConnection']
export const isBoxBreakPhotoNodeConnection = (obj: { __typename: String }): obj is BoxBreakPhotoNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakPhotoNodeConnection_possibleTypes.includes(obj.__typename)
}

const BoxBreakPhotoNodeEdge_possibleTypes = ['BoxBreakPhotoNodeEdge']
export const isBoxBreakPhotoNodeEdge = (obj: { __typename: String }): obj is BoxBreakPhotoNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakPhotoNodeEdge_possibleTypes.includes(obj.__typename)
}

const BoxBreakPhotoNode_possibleTypes = ['BoxBreakPhotoNode']
export const isBoxBreakPhotoNode = (obj: { __typename: String }): obj is BoxBreakPhotoNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakPhotoNode_possibleTypes.includes(obj.__typename)
}

const RemintNFTNodeConnection_possibleTypes = ['RemintNFTNodeConnection']
export const isRemintNFTNodeConnection = (obj: { __typename: String }): obj is RemintNFTNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RemintNFTNodeConnection_possibleTypes.includes(obj.__typename)
}

const RemintNFTNodeEdge_possibleTypes = ['RemintNFTNodeEdge']
export const isRemintNFTNodeEdge = (obj: { __typename: String }): obj is RemintNFTNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RemintNFTNodeEdge_possibleTypes.includes(obj.__typename)
}

const RemintNFTNode_possibleTypes = ['RemintNFTNode']
export const isRemintNFTNode = (obj: { __typename: String }): obj is RemintNFTNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RemintNFTNode_possibleTypes.includes(obj.__typename)
}

const PlayerNodeConnection_possibleTypes = ['PlayerNodeConnection']
export const isPlayerNodeConnection = (obj: { __typename: String }): obj is PlayerNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PlayerNodeConnection_possibleTypes.includes(obj.__typename)
}

const PlayerNodeEdge_possibleTypes = ['PlayerNodeEdge']
export const isPlayerNodeEdge = (obj: { __typename: String }): obj is PlayerNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PlayerNodeEdge_possibleTypes.includes(obj.__typename)
}

const StreamNodeConnection_possibleTypes = ['StreamNodeConnection']
export const isStreamNodeConnection = (obj: { __typename: String }): obj is StreamNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StreamNodeConnection_possibleTypes.includes(obj.__typename)
}

const StreamNodeEdge_possibleTypes = ['StreamNodeEdge']
export const isStreamNodeEdge = (obj: { __typename: String }): obj is StreamNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StreamNodeEdge_possibleTypes.includes(obj.__typename)
}

const ShippingRequestNodeConnection_possibleTypes = ['ShippingRequestNodeConnection']
export const isShippingRequestNodeConnection = (obj: { __typename: String }): obj is ShippingRequestNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ShippingRequestNodeConnection_possibleTypes.includes(obj.__typename)
}

const ShippingRequestNodeEdge_possibleTypes = ['ShippingRequestNodeEdge']
export const isShippingRequestNodeEdge = (obj: { __typename: String }): obj is ShippingRequestNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ShippingRequestNodeEdge_possibleTypes.includes(obj.__typename)
}

const FixtureNodeConnection_possibleTypes = ['FixtureNodeConnection']
export const isFixtureNodeConnection = (obj: { __typename: String }): obj is FixtureNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FixtureNodeConnection_possibleTypes.includes(obj.__typename)
}

const FixtureNodeEdge_possibleTypes = ['FixtureNodeEdge']
export const isFixtureNodeEdge = (obj: { __typename: String }): obj is FixtureNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FixtureNodeEdge_possibleTypes.includes(obj.__typename)
}

const UserNotificationPreferenceNode_possibleTypes = ['UserNotificationPreferenceNode']
export const isUserNotificationPreferenceNode = (obj: { __typename: String }): obj is UserNotificationPreferenceNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserNotificationPreferenceNode_possibleTypes.includes(obj.__typename)
}

const RecentSaleNode_possibleTypes = ['RecentSaleNode']
export const isRecentSaleNode = (obj: { __typename: String }): obj is RecentSaleNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RecentSaleNode_possibleTypes.includes(obj.__typename)
}

const ItemOwnershipNodeConnection_possibleTypes = ['ItemOwnershipNodeConnection']
export const isItemOwnershipNodeConnection = (obj: { __typename: String }): obj is ItemOwnershipNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ItemOwnershipNodeConnection_possibleTypes.includes(obj.__typename)
}

const ItemOwnershipNodeEdge_possibleTypes = ['ItemOwnershipNodeEdge']
export const isItemOwnershipNodeEdge = (obj: { __typename: String }): obj is ItemOwnershipNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ItemOwnershipNodeEdge_possibleTypes.includes(obj.__typename)
}

const GroupedBidNodeConnection_possibleTypes = ['GroupedBidNodeConnection']
export const isGroupedBidNodeConnection = (obj: { __typename: String }): obj is GroupedBidNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GroupedBidNodeConnection_possibleTypes.includes(obj.__typename)
}

const GroupedBidNodeEdge_possibleTypes = ['GroupedBidNodeEdge']
export const isGroupedBidNodeEdge = (obj: { __typename: String }): obj is GroupedBidNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GroupedBidNodeEdge_possibleTypes.includes(obj.__typename)
}

const GroupedListingNodeConnection_possibleTypes = ['GroupedListingNodeConnection']
export const isGroupedListingNodeConnection = (obj: { __typename: String }): obj is GroupedListingNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GroupedListingNodeConnection_possibleTypes.includes(obj.__typename)
}

const GroupedListingNodeEdge_possibleTypes = ['GroupedListingNodeEdge']
export const isGroupedListingNodeEdge = (obj: { __typename: String }): obj is GroupedListingNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GroupedListingNodeEdge_possibleTypes.includes(obj.__typename)
}

const MarketIndexNodeConnection_possibleTypes = ['MarketIndexNodeConnection']
export const isMarketIndexNodeConnection = (obj: { __typename: String }): obj is MarketIndexNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexNodeConnection_possibleTypes.includes(obj.__typename)
}

const MarketIndexNodeEdge_possibleTypes = ['MarketIndexNodeEdge']
export const isMarketIndexNodeEdge = (obj: { __typename: String }): obj is MarketIndexNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketIndexNodeEdge_possibleTypes.includes(obj.__typename)
}

const MarketWidgetNode_possibleTypes = ['MarketWidgetNode']
export const isMarketWidgetNode = (obj: { __typename: String }): obj is MarketWidgetNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MarketWidgetNode_possibleTypes.includes(obj.__typename)
}

const GradeNodeConnection_possibleTypes = ['GradeNodeConnection']
export const isGradeNodeConnection = (obj: { __typename: String }): obj is GradeNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GradeNodeConnection_possibleTypes.includes(obj.__typename)
}

const GradeNodeEdge_possibleTypes = ['GradeNodeEdge']
export const isGradeNodeEdge = (obj: { __typename: String }): obj is GradeNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GradeNodeEdge_possibleTypes.includes(obj.__typename)
}

const PortfolioValueNode_possibleTypes = ['PortfolioValueNode']
export const isPortfolioValueNode = (obj: { __typename: String }): obj is PortfolioValueNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PortfolioValueNode_possibleTypes.includes(obj.__typename)
}

const CardSetNodeConnection_possibleTypes = ['CardSetNodeConnection']
export const isCardSetNodeConnection = (obj: { __typename: String }): obj is CardSetNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardSetNodeConnection_possibleTypes.includes(obj.__typename)
}

const CardSetNodeEdge_possibleTypes = ['CardSetNodeEdge']
export const isCardSetNodeEdge = (obj: { __typename: String }): obj is CardSetNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardSetNodeEdge_possibleTypes.includes(obj.__typename)
}

const CardSetCounts_possibleTypes = ['CardSetCounts']
export const isCardSetCounts = (obj: { __typename: String }): obj is CardSetCounts => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CardSetCounts_possibleTypes.includes(obj.__typename)
}

const AuctionNodeConnection_possibleTypes = ['AuctionNodeConnection']
export const isAuctionNodeConnection = (obj: { __typename: String }): obj is AuctionNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionNodeConnection_possibleTypes.includes(obj.__typename)
}

const AuctionNodeEdge_possibleTypes = ['AuctionNodeEdge']
export const isAuctionNodeEdge = (obj: { __typename: String }): obj is AuctionNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionNodeEdge_possibleTypes.includes(obj.__typename)
}

const AnnouncementNode_possibleTypes = ['AnnouncementNode']
export const isAnnouncementNode = (obj: { __typename: String }): obj is AnnouncementNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnnouncementNode_possibleTypes.includes(obj.__typename)
}

const AnnouncementStyleNode_possibleTypes = ['AnnouncementStyleNode']
export const isAnnouncementStyleNode = (obj: { __typename: String }): obj is AnnouncementStyleNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnnouncementStyleNode_possibleTypes.includes(obj.__typename)
}

const AnnouncementNodeConnection_possibleTypes = ['AnnouncementNodeConnection']
export const isAnnouncementNodeConnection = (obj: { __typename: String }): obj is AnnouncementNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnnouncementNodeConnection_possibleTypes.includes(obj.__typename)
}

const AnnouncementNodeEdge_possibleTypes = ['AnnouncementNodeEdge']
export const isAnnouncementNodeEdge = (obj: { __typename: String }): obj is AnnouncementNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnnouncementNodeEdge_possibleTypes.includes(obj.__typename)
}

const PromotionType_possibleTypes = ['PromotionType']
export const isPromotionType = (obj: { __typename: String }): obj is PromotionType => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PromotionType_possibleTypes.includes(obj.__typename)
}

const Mutation_possibleTypes = ['Mutation']
export const isMutation = (obj: { __typename: String }): obj is Mutation => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Mutation_possibleTypes.includes(obj.__typename)
}

const SubmitDraftShippingRequest_possibleTypes = ['SubmitDraftShippingRequest']
export const isSubmitDraftShippingRequest = (obj: { __typename: String }): obj is SubmitDraftShippingRequest => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SubmitDraftShippingRequest_possibleTypes.includes(obj.__typename)
}

const Error_possibleTypes = ['Error']
export const isError = (obj: { __typename: String }): obj is Error => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Error_possibleTypes.includes(obj.__typename)
}

const AddItemsToDraftShippingRequest_possibleTypes = ['AddItemsToDraftShippingRequest']
export const isAddItemsToDraftShippingRequest = (obj: { __typename: String }): obj is AddItemsToDraftShippingRequest => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AddItemsToDraftShippingRequest_possibleTypes.includes(obj.__typename)
}

const RemoveItemsFromDraftShippingRequest_possibleTypes = ['RemoveItemsFromDraftShippingRequest']
export const isRemoveItemsFromDraftShippingRequest = (obj: {
  __typename: String
}): obj is RemoveItemsFromDraftShippingRequest => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RemoveItemsFromDraftShippingRequest_possibleTypes.includes(obj.__typename)
}

const AddAllItemsToDraftShippingRequest_possibleTypes = ['AddAllItemsToDraftShippingRequest']
export const isAddAllItemsToDraftShippingRequest = (obj: {
  __typename: String
}): obj is AddAllItemsToDraftShippingRequest => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AddAllItemsToDraftShippingRequest_possibleTypes.includes(obj.__typename)
}

const RemoveAllItemsFromDraftShippingRequest_possibleTypes = ['RemoveAllItemsFromDraftShippingRequest']
export const isRemoveAllItemsFromDraftShippingRequest = (obj: {
  __typename: String
}): obj is RemoveAllItemsFromDraftShippingRequest => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RemoveAllItemsFromDraftShippingRequest_possibleTypes.includes(obj.__typename)
}

const CreatePaymentIntent_possibleTypes = ['CreatePaymentIntent']
export const isCreatePaymentIntent = (obj: { __typename: String }): obj is CreatePaymentIntent => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CreatePaymentIntent_possibleTypes.includes(obj.__typename)
}

const CreateWithdrawalRequest_possibleTypes = ['CreateWithdrawalRequest']
export const isCreateWithdrawalRequest = (obj: { __typename: String }): obj is CreateWithdrawalRequest => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CreateWithdrawalRequest_possibleTypes.includes(obj.__typename)
}

const DeletePaymentMethod_possibleTypes = ['DeletePaymentMethod']
export const isDeletePaymentMethod = (obj: { __typename: String }): obj is DeletePaymentMethod => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeletePaymentMethod_possibleTypes.includes(obj.__typename)
}

const SetDefaultPaymentMethod_possibleTypes = ['SetDefaultPaymentMethod']
export const isSetDefaultPaymentMethod = (obj: { __typename: String }): obj is SetDefaultPaymentMethod => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SetDefaultPaymentMethod_possibleTypes.includes(obj.__typename)
}

const CreateSetupIntent_possibleTypes = ['CreateSetupIntent']
export const isCreateSetupIntent = (obj: { __typename: String }): obj is CreateSetupIntent => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CreateSetupIntent_possibleTypes.includes(obj.__typename)
}

const UpdateCryptoWalletBalance_possibleTypes = ['UpdateCryptoWalletBalance']
export const isUpdateCryptoWalletBalance = (obj: { __typename: String }): obj is UpdateCryptoWalletBalance => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateCryptoWalletBalance_possibleTypes.includes(obj.__typename)
}

const WithdrawalConfirmation_possibleTypes = ['WithdrawalConfirmation']
export const isWithdrawalConfirmation = (obj: { __typename: String }): obj is WithdrawalConfirmation => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return WithdrawalConfirmation_possibleTypes.includes(obj.__typename)
}

const UpdateNotificationPreferences_possibleTypes = ['UpdateNotificationPreferences']
export const isUpdateNotificationPreferences = (obj: { __typename: String }): obj is UpdateNotificationPreferences => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateNotificationPreferences_possibleTypes.includes(obj.__typename)
}

const GroupedBidClose_possibleTypes = ['GroupedBidClose']
export const isGroupedBidClose = (obj: { __typename: String }): obj is GroupedBidClose => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GroupedBidClose_possibleTypes.includes(obj.__typename)
}

const GroupedListingClose_possibleTypes = ['GroupedListingClose']
export const isGroupedListingClose = (obj: { __typename: String }): obj is GroupedListingClose => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GroupedListingClose_possibleTypes.includes(obj.__typename)
}

const SellItems_possibleTypes = ['SellItems']
export const isSellItems = (obj: { __typename: String }): obj is SellItems => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SellItems_possibleTypes.includes(obj.__typename)
}

const BuyItems_possibleTypes = ['BuyItems']
export const isBuyItems = (obj: { __typename: String }): obj is BuyItems => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BuyItems_possibleTypes.includes(obj.__typename)
}

const BuyOrderExtendExpiryDate_possibleTypes = ['BuyOrderExtendExpiryDate']
export const isBuyOrderExtendExpiryDate = (obj: { __typename: String }): obj is BuyOrderExtendExpiryDate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BuyOrderExtendExpiryDate_possibleTypes.includes(obj.__typename)
}

const SellOrderExtendExpiryDate_possibleTypes = ['SellOrderExtendExpiryDate']
export const isSellOrderExtendExpiryDate = (obj: { __typename: String }): obj is SellOrderExtendExpiryDate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SellOrderExtendExpiryDate_possibleTypes.includes(obj.__typename)
}

const WishlistItemCreate_possibleTypes = ['WishlistItemCreate']
export const isWishlistItemCreate = (obj: { __typename: String }): obj is WishlistItemCreate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return WishlistItemCreate_possibleTypes.includes(obj.__typename)
}

const WishlistItemDelete_possibleTypes = ['WishlistItemDelete']
export const isWishlistItemDelete = (obj: { __typename: String }): obj is WishlistItemDelete => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return WishlistItemDelete_possibleTypes.includes(obj.__typename)
}

const SendUserOwnershipsCsv_possibleTypes = ['SendUserOwnershipsCsv']
export const isSendUserOwnershipsCsv = (obj: { __typename: String }): obj is SendUserOwnershipsCsv => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SendUserOwnershipsCsv_possibleTypes.includes(obj.__typename)
}

const BuyBoxBreakSpots_possibleTypes = ['BuyBoxBreakSpots']
export const isBuyBoxBreakSpots = (obj: { __typename: String }): obj is BuyBoxBreakSpots => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BuyBoxBreakSpots_possibleTypes.includes(obj.__typename)
}

const PersonalBreakRequestCreate_possibleTypes = ['PersonalBreakRequestCreate']
export const isPersonalBreakRequestCreate = (obj: { __typename: String }): obj is PersonalBreakRequestCreate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PersonalBreakRequestCreate_possibleTypes.includes(obj.__typename)
}

const BidCreate_possibleTypes = ['BidCreate']
export const isBidCreate = (obj: { __typename: String }): obj is BidCreate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BidCreate_possibleTypes.includes(obj.__typename)
}

const CreateAddress_possibleTypes = ['CreateAddress']
export const isCreateAddress = (obj: { __typename: String }): obj is CreateAddress => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CreateAddress_possibleTypes.includes(obj.__typename)
}

const DeleteAddress_possibleTypes = ['DeleteAddress']
export const isDeleteAddress = (obj: { __typename: String }): obj is DeleteAddress => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeleteAddress_possibleTypes.includes(obj.__typename)
}

const UpdateAddress_possibleTypes = ['UpdateAddress']
export const isUpdateAddress = (obj: { __typename: String }): obj is UpdateAddress => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateAddress_possibleTypes.includes(obj.__typename)
}

const MakeAddressDefault_possibleTypes = ['MakeAddressDefault']
export const isMakeAddressDefault = (obj: { __typename: String }): obj is MakeAddressDefault => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MakeAddressDefault_possibleTypes.includes(obj.__typename)
}

const ObtainJSONWebToken_possibleTypes = ['ObtainJSONWebToken']
export const isObtainJSONWebToken = (obj: { __typename: String }): obj is ObtainJSONWebToken => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ObtainJSONWebToken_possibleTypes.includes(obj.__typename)
}

const UserCreate_possibleTypes = ['UserCreate']
export const isUserCreate = (obj: { __typename: String }): obj is UserCreate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserCreate_possibleTypes.includes(obj.__typename)
}

const PasswordChange_possibleTypes = ['PasswordChange']
export const isPasswordChange = (obj: { __typename: String }): obj is PasswordChange => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PasswordChange_possibleTypes.includes(obj.__typename)
}

const PasswordResetRequest_possibleTypes = ['PasswordResetRequest']
export const isPasswordResetRequest = (obj: { __typename: String }): obj is PasswordResetRequest => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PasswordResetRequest_possibleTypes.includes(obj.__typename)
}

const PasswordResetChange_possibleTypes = ['PasswordResetChange']
export const isPasswordResetChange = (obj: { __typename: String }): obj is PasswordResetChange => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PasswordResetChange_possibleTypes.includes(obj.__typename)
}

const UpdateUserCollectionVisibility_possibleTypes = ['UpdateUserCollectionVisibility']
export const isUpdateUserCollectionVisibility = (obj: { __typename: String }): obj is UpdateUserCollectionVisibility => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateUserCollectionVisibility_possibleTypes.includes(obj.__typename)
}

const Verify_possibleTypes = ['Verify']
export const isVerify = (obj: { __typename: String }): obj is Verify => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Verify_possibleTypes.includes(obj.__typename)
}

const Refresh_possibleTypes = ['Refresh']
export const isRefresh = (obj: { __typename: String }): obj is Refresh => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Refresh_possibleTypes.includes(obj.__typename)
}

const DeleteJSONWebTokenCookie_possibleTypes = ['DeleteJSONWebTokenCookie']
export const isDeleteJSONWebTokenCookie = (obj: { __typename: String }): obj is DeleteJSONWebTokenCookie => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeleteJSONWebTokenCookie_possibleTypes.includes(obj.__typename)
}

const DeleteRefreshTokenCookie_possibleTypes = ['DeleteRefreshTokenCookie']
export const isDeleteRefreshTokenCookie = (obj: { __typename: String }): obj is DeleteRefreshTokenCookie => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeleteRefreshTokenCookie_possibleTypes.includes(obj.__typename)
}

export interface QueryPromiseChain {
  myPaymentMethods: {
    execute: (
      request: StripePaymentMethodNodeRequest,
      defaultValue?: (StripePaymentMethodNode | null)[] | null,
    ) => Promise<(StripePaymentMethodNode | null)[] | null>
  }
  channels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => ChannelNodeConnectionPromiseChain & {
    execute: (
      request: ChannelNodeConnectionRequest,
      defaultValue?: ChannelNodeConnection | null,
    ) => Promise<ChannelNodeConnection | null>
  }) &
    (ChannelNodeConnectionPromiseChain & {
      execute: (
        request: ChannelNodeConnectionRequest,
        defaultValue?: ChannelNodeConnection | null,
      ) => Promise<ChannelNodeConnection | null>
    })
  channel: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => ChannelNodePromiseChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Promise<ChannelNode | null>
  }) &
    (ChannelNodePromiseChain & {
      execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Promise<ChannelNode | null>
    })
  streams: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Promise<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Promise<StreamNodeConnection | null>
    })
  stream: ((args?: {
    id?: ID | null
  }) => StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }) &
    (StreamNodePromiseChain & {
      execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
    })
  events: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Promise<EventNodeConnection | null>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Promise<EventNodeConnection | null>
    })
  event: ((args?: {
    id?: ID | null
  }) => EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }) &
    (EventNodePromiseChain & {
      execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
    })
  myShippingRequests: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    status?: String | null
  }) => ShippingRequestNodeConnectionPromiseChain & {
    execute: (
      request: ShippingRequestNodeConnectionRequest,
      defaultValue?: ShippingRequestNodeConnection | null,
    ) => Promise<ShippingRequestNodeConnection | null>
  }) &
    (ShippingRequestNodeConnectionPromiseChain & {
      execute: (
        request: ShippingRequestNodeConnectionRequest,
        defaultValue?: ShippingRequestNodeConnection | null,
      ) => Promise<ShippingRequestNodeConnection | null>
    })
  myShippingRequest: ((args?: {
    id?: ID | null
  }) => ShippingRequestNodePromiseChain & {
    execute: (
      request: ShippingRequestNodeRequest,
      defaultValue?: ShippingRequestNode | null,
    ) => Promise<ShippingRequestNode | null>
  }) &
    (ShippingRequestNodePromiseChain & {
      execute: (
        request: ShippingRequestNodeRequest,
        defaultValue?: ShippingRequestNode | null,
      ) => Promise<ShippingRequestNode | null>
    })
  myDraftShippingRequest: ShippingRequestNodePromiseChain & {
    execute: (
      request: ShippingRequestNodeRequest,
      defaultValue?: ShippingRequestNode | null,
    ) => Promise<ShippingRequestNode | null>
  }
  player: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => PlayerNodePromiseChain & {
    execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode | null) => Promise<PlayerNode | null>
  }) &
    (PlayerNodePromiseChain & {
      execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode | null) => Promise<PlayerNode | null>
    })
  upcomingFixtures: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
  }) => FixtureNodeConnectionPromiseChain & {
    execute: (
      request: FixtureNodeConnectionRequest,
      defaultValue?: FixtureNodeConnection | null,
    ) => Promise<FixtureNodeConnection | null>
  }) &
    (FixtureNodeConnectionPromiseChain & {
      execute: (
        request: FixtureNodeConnectionRequest,
        defaultValue?: FixtureNodeConnection | null,
      ) => Promise<FixtureNodeConnection | null>
    })
  cardParallelSearch: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
    era?: String | null
    query?: String | null
    productType?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelSearchResultConnectionPromiseChain & {
    execute: (
      request: CardParallelSearchResultConnectionRequest,
      defaultValue?: CardParallelSearchResultConnection | null,
    ) => Promise<CardParallelSearchResultConnection | null>
  }) &
    (CardParallelSearchResultConnectionPromiseChain & {
      execute: (
        request: CardParallelSearchResultConnectionRequest,
        defaultValue?: CardParallelSearchResultConnection | null,
      ) => Promise<CardParallelSearchResultConnection | null>
    })
  defaultNotificationPreferences: {
    execute: (
      request: UserNotificationPreferenceNodeRequest,
      defaultValue?: (UserNotificationPreferenceNode | null)[] | null,
    ) => Promise<(UserNotificationPreferenceNode | null)[] | null>
  }
  myNotificationPreferences: {
    execute: (
      request: UserNotificationPreferenceNodeRequest,
      defaultValue?: (UserNotificationPreferenceNode | null)[] | null,
    ) => Promise<(UserNotificationPreferenceNode | null)[] | null>
  }
  sales: ((args?: {
    first?: Int | null
    sport?: String | null
    era?: String | null
  }) => {
    execute: (
      request: RecentSaleNodeRequest,
      defaultValue?: (RecentSaleNode | null)[] | null,
    ) => Promise<(RecentSaleNode | null)[] | null>
  }) & {
    execute: (
      request: RecentSaleNodeRequest,
      defaultValue?: (RecentSaleNode | null)[] | null,
    ) => Promise<(RecentSaleNode | null)[] | null>
  }
  mySales: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
    era?: String | null
    productType?: (String | null)[] | null
    listed?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemOwnershipNodeConnectionPromiseChain & {
    execute: (
      request: ItemOwnershipNodeConnectionRequest,
      defaultValue?: ItemOwnershipNodeConnection | null,
    ) => Promise<ItemOwnershipNodeConnection | null>
  }) &
    (ItemOwnershipNodeConnectionPromiseChain & {
      execute: (
        request: ItemOwnershipNodeConnectionRequest,
        defaultValue?: ItemOwnershipNodeConnection | null,
      ) => Promise<ItemOwnershipNodeConnection | null>
    })
  myPastPurchases: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
  }) => HistoricalPurchaseNodeConnectionPromiseChain & {
    execute: (
      request: HistoricalPurchaseNodeConnectionRequest,
      defaultValue?: HistoricalPurchaseNodeConnection | null,
    ) => Promise<HistoricalPurchaseNodeConnection | null>
  }) &
    (HistoricalPurchaseNodeConnectionPromiseChain & {
      execute: (
        request: HistoricalPurchaseNodeConnectionRequest,
        defaultValue?: HistoricalPurchaseNodeConnection | null,
      ) => Promise<HistoricalPurchaseNodeConnection | null>
    })
  myGroupedBids: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => GroupedBidNodeConnectionPromiseChain & {
    execute: (
      request: GroupedBidNodeConnectionRequest,
      defaultValue?: GroupedBidNodeConnection | null,
    ) => Promise<GroupedBidNodeConnection | null>
  }) &
    (GroupedBidNodeConnectionPromiseChain & {
      execute: (
        request: GroupedBidNodeConnectionRequest,
        defaultValue?: GroupedBidNodeConnection | null,
      ) => Promise<GroupedBidNodeConnection | null>
    })
  myGroupedListings: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => GroupedListingNodeConnectionPromiseChain & {
    execute: (
      request: GroupedListingNodeConnectionRequest,
      defaultValue?: GroupedListingNodeConnection | null,
    ) => Promise<GroupedListingNodeConnection | null>
  }) &
    (GroupedListingNodeConnectionPromiseChain & {
      execute: (
        request: GroupedListingNodeConnectionRequest,
        defaultValue?: GroupedListingNodeConnection | null,
      ) => Promise<GroupedListingNodeConnection | null>
    })
  gradedCardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    grader?: String | null
    minItems?: Decimal | null
    minListings?: Decimal | null
    minOffers?: Decimal | null
    myListingsStatus?: String | null
  }) => GradedCardParallelNodeConnectionPromiseChain & {
    execute: (
      request: GradedCardParallelNodeConnectionRequest,
      defaultValue?: GradedCardParallelNodeConnection | null,
    ) => Promise<GradedCardParallelNodeConnection | null>
  }) &
    (GradedCardParallelNodeConnectionPromiseChain & {
      execute: (
        request: GradedCardParallelNodeConnectionRequest,
        defaultValue?: GradedCardParallelNodeConnection | null,
      ) => Promise<GradedCardParallelNodeConnection | null>
    })
  gradedCardParallel: ((args?: {
    id?: ID | null
  }) => GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Promise<GradedCardParallelNode | null>
  }) &
    (GradedCardParallelNodePromiseChain & {
      execute: (
        request: GradedCardParallelNodeRequest,
        defaultValue?: GradedCardParallelNode | null,
      ) => Promise<GradedCardParallelNode | null>
    })
  marketIndices: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    name?: String | null
  }) => MarketIndexNodeConnectionPromiseChain & {
    execute: (
      request: MarketIndexNodeConnectionRequest,
      defaultValue?: MarketIndexNodeConnection | null,
    ) => Promise<MarketIndexNodeConnection | null>
  }) &
    (MarketIndexNodeConnectionPromiseChain & {
      execute: (
        request: MarketIndexNodeConnectionRequest,
        defaultValue?: MarketIndexNodeConnection | null,
      ) => Promise<MarketIndexNodeConnection | null>
    })
  marketIndex: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => MarketIndexNodePromiseChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode | null) => Promise<MarketIndexNode | null>
  }) &
    (MarketIndexNodePromiseChain & {
      execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode | null) => Promise<MarketIndexNode | null>
    })
  marketWidgets: {
    execute: (
      request: MarketWidgetNodeRequest,
      defaultValue?: (MarketWidgetNode | null)[] | null,
    ) => Promise<(MarketWidgetNode | null)[] | null>
  }
  grades: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => GradeNodeConnectionPromiseChain & {
    execute: (
      request: GradeNodeConnectionRequest,
      defaultValue?: GradeNodeConnection | null,
    ) => Promise<GradeNodeConnection | null>
  }) &
    (GradeNodeConnectionPromiseChain & {
      execute: (
        request: GradeNodeConnectionRequest,
        defaultValue?: GradeNodeConnection | null,
      ) => Promise<GradeNodeConnection | null>
    })
  myWishlistCards: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionPromiseChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Promise<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionPromiseChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Promise<CardParallelNodeConnection | null>
    })
  myWishlistPlayers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PlayerNodeConnectionPromiseChain & {
    execute: (
      request: PlayerNodeConnectionRequest,
      defaultValue?: PlayerNodeConnection | null,
    ) => Promise<PlayerNodeConnection | null>
  }) &
    (PlayerNodeConnectionPromiseChain & {
      execute: (
        request: PlayerNodeConnectionRequest,
        defaultValue?: PlayerNodeConnection | null,
      ) => Promise<PlayerNodeConnection | null>
    })
  userCollection: (args: {
    username: String
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
    era?: String | null
    productType?: (String | null)[] | null
    listed?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemOwnershipNodeConnectionPromiseChain & {
    execute: (
      request: ItemOwnershipNodeConnectionRequest,
      defaultValue?: ItemOwnershipNodeConnection | null,
    ) => Promise<ItemOwnershipNodeConnection | null>
  }
  portfolioValue: ((args?: {
    sport?: String | null
    era?: String | null
    timePeriodUnit?: PortfolioValueTimePeriodUnits | null
    timePeriodCount?: Int | null
    timePeriodOffset?: Int | null
  }) => {
    execute: (
      request: PortfolioValueNodeRequest,
      defaultValue?: (PortfolioValueNode | null)[] | null,
    ) => Promise<(PortfolioValueNode | null)[] | null>
  }) & {
    execute: (
      request: PortfolioValueNodeRequest,
      defaultValue?: (PortfolioValueNode | null)[] | null,
    ) => Promise<(PortfolioValueNode | null)[] | null>
  }
  myCollection: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
    era?: String | null
    productType?: (String | null)[] | null
    listed?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemOwnershipNodeConnectionPromiseChain & {
    execute: (
      request: ItemOwnershipNodeConnectionRequest,
      defaultValue?: ItemOwnershipNodeConnection | null,
    ) => Promise<ItemOwnershipNodeConnection | null>
  }) &
    (ItemOwnershipNodeConnectionPromiseChain & {
      execute: (
        request: ItemOwnershipNodeConnectionRequest,
        defaultValue?: ItemOwnershipNodeConnection | null,
      ) => Promise<ItemOwnershipNodeConnection | null>
    })
  myInDraftShippingRequestCollection: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
    era?: String | null
    productType?: (String | null)[] | null
    listed?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemOwnershipNodeConnectionPromiseChain & {
    execute: (
      request: ItemOwnershipNodeConnectionRequest,
      defaultValue?: ItemOwnershipNodeConnection | null,
    ) => Promise<ItemOwnershipNodeConnection | null>
  }) &
    (ItemOwnershipNodeConnectionPromiseChain & {
      execute: (
        request: ItemOwnershipNodeConnectionRequest,
        defaultValue?: ItemOwnershipNodeConnection | null,
      ) => Promise<ItemOwnershipNodeConnection | null>
    })
  cardParallel: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode | null) => Promise<CardParallelNode | null>
  }) &
    (CardParallelNodePromiseChain & {
      execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode | null) => Promise<CardParallelNode | null>
    })
  cardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionPromiseChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Promise<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionPromiseChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Promise<CardParallelNodeConnection | null>
    })
  myCardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionPromiseChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Promise<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionPromiseChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Promise<CardParallelNodeConnection | null>
    })
  cardSets: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
  }) => CardSetNodeConnectionPromiseChain & {
    execute: (
      request: CardSetNodeConnectionRequest,
      defaultValue?: CardSetNodeConnection | null,
    ) => Promise<CardSetNodeConnection | null>
  }) &
    (CardSetNodeConnectionPromiseChain & {
      execute: (
        request: CardSetNodeConnectionRequest,
        defaultValue?: CardSetNodeConnection | null,
      ) => Promise<CardSetNodeConnection | null>
    })
  boxBreak: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }) &
    (BoxBreakNodePromiseChain & {
      execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
    })
  boxBreaks: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Promise<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Promise<BoxBreakNodeConnection | null>
    })
  myBoxBreaks: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Promise<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Promise<BoxBreakNodeConnection | null>
    })
  myPersonalBreakRequests: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionPromiseChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection | null,
    ) => Promise<PersonalBreakRequestNodeConnection | null>
  }) &
    (PersonalBreakRequestNodeConnectionPromiseChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection | null,
      ) => Promise<PersonalBreakRequestNodeConnection | null>
    })
  remints: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Promise<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Promise<BoxBreakNodeConnection | null>
    })
  remint: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }) &
    (BoxBreakNodePromiseChain & {
      execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
    })
  auctions: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    state?: (String | null)[] | null
    featured?: Boolean | null
    currency?: (String | null)[] | null
    lotRunningFormat?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => AuctionNodeConnectionPromiseChain & {
    execute: (
      request: AuctionNodeConnectionRequest,
      defaultValue?: AuctionNodeConnection | null,
    ) => Promise<AuctionNodeConnection | null>
  }) &
    (AuctionNodeConnectionPromiseChain & {
      execute: (
        request: AuctionNodeConnectionRequest,
        defaultValue?: AuctionNodeConnection | null,
      ) => Promise<AuctionNodeConnection | null>
    })
  auction: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => AuctionNodePromiseChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Promise<AuctionNode | null>
  }) &
    (AuctionNodePromiseChain & {
      execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Promise<AuctionNode | null>
    })
  lots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    productCategory?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionPromiseChain & {
    execute: (
      request: LotNodeConnectionRequest,
      defaultValue?: LotNodeConnection | null,
    ) => Promise<LotNodeConnection | null>
  }) &
    (LotNodeConnectionPromiseChain & {
      execute: (
        request: LotNodeConnectionRequest,
        defaultValue?: LotNodeConnection | null,
      ) => Promise<LotNodeConnection | null>
    })
  myBiddingLots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    productCategory?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionPromiseChain & {
    execute: (
      request: LotNodeConnectionRequest,
      defaultValue?: LotNodeConnection | null,
    ) => Promise<LotNodeConnection | null>
  }) &
    (LotNodeConnectionPromiseChain & {
      execute: (
        request: LotNodeConnectionRequest,
        defaultValue?: LotNodeConnection | null,
      ) => Promise<LotNodeConnection | null>
    })
  lot: ((args?: {
    id?: ID | null
  }) => LotNodePromiseChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Promise<LotNode | null>
  }) &
    (LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Promise<LotNode | null> })
  bid: ((args?: {
    id?: ID | null
  }) => BidNodePromiseChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null>
  }) &
    (BidNodePromiseChain & { execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null> })
  announcementBanners: {
    execute: (
      request: AnnouncementNodeRequest,
      defaultValue?: (AnnouncementNode | null)[] | null,
    ) => Promise<(AnnouncementNode | null)[] | null>
  }
  myAddresses: {
    execute: (
      request: AddressNodeRequest,
      defaultValue?: (AddressNode | null)[] | null,
    ) => Promise<(AddressNode | null)[] | null>
  }
  me: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  myAccount: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  promotion: PromotionTypePromiseChain & {
    execute: (request: PromotionTypeRequest, defaultValue?: PromotionType | null) => Promise<PromotionType | null>
  }
}

export interface QueryObservableChain {
  myPaymentMethods: {
    execute: (
      request: StripePaymentMethodNodeRequest,
      defaultValue?: (StripePaymentMethodNode | null)[] | null,
    ) => Observable<(StripePaymentMethodNode | null)[] | null>
  }
  channels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => ChannelNodeConnectionObservableChain & {
    execute: (
      request: ChannelNodeConnectionRequest,
      defaultValue?: ChannelNodeConnection | null,
    ) => Observable<ChannelNodeConnection | null>
  }) &
    (ChannelNodeConnectionObservableChain & {
      execute: (
        request: ChannelNodeConnectionRequest,
        defaultValue?: ChannelNodeConnection | null,
      ) => Observable<ChannelNodeConnection | null>
    })
  channel: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => ChannelNodeObservableChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Observable<ChannelNode | null>
  }) &
    (ChannelNodeObservableChain & {
      execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Observable<ChannelNode | null>
    })
  streams: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Observable<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Observable<StreamNodeConnection | null>
    })
  stream: ((args?: {
    id?: ID | null
  }) => StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }) &
    (StreamNodeObservableChain & {
      execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
    })
  events: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionObservableChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Observable<EventNodeConnection | null>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Observable<EventNodeConnection | null>
    })
  event: ((args?: {
    id?: ID | null
  }) => EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }) &
    (EventNodeObservableChain & {
      execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
    })
  myShippingRequests: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    status?: String | null
  }) => ShippingRequestNodeConnectionObservableChain & {
    execute: (
      request: ShippingRequestNodeConnectionRequest,
      defaultValue?: ShippingRequestNodeConnection | null,
    ) => Observable<ShippingRequestNodeConnection | null>
  }) &
    (ShippingRequestNodeConnectionObservableChain & {
      execute: (
        request: ShippingRequestNodeConnectionRequest,
        defaultValue?: ShippingRequestNodeConnection | null,
      ) => Observable<ShippingRequestNodeConnection | null>
    })
  myShippingRequest: ((args?: {
    id?: ID | null
  }) => ShippingRequestNodeObservableChain & {
    execute: (
      request: ShippingRequestNodeRequest,
      defaultValue?: ShippingRequestNode | null,
    ) => Observable<ShippingRequestNode | null>
  }) &
    (ShippingRequestNodeObservableChain & {
      execute: (
        request: ShippingRequestNodeRequest,
        defaultValue?: ShippingRequestNode | null,
      ) => Observable<ShippingRequestNode | null>
    })
  myDraftShippingRequest: ShippingRequestNodeObservableChain & {
    execute: (
      request: ShippingRequestNodeRequest,
      defaultValue?: ShippingRequestNode | null,
    ) => Observable<ShippingRequestNode | null>
  }
  player: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => PlayerNodeObservableChain & {
    execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode | null) => Observable<PlayerNode | null>
  }) &
    (PlayerNodeObservableChain & {
      execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode | null) => Observable<PlayerNode | null>
    })
  upcomingFixtures: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
  }) => FixtureNodeConnectionObservableChain & {
    execute: (
      request: FixtureNodeConnectionRequest,
      defaultValue?: FixtureNodeConnection | null,
    ) => Observable<FixtureNodeConnection | null>
  }) &
    (FixtureNodeConnectionObservableChain & {
      execute: (
        request: FixtureNodeConnectionRequest,
        defaultValue?: FixtureNodeConnection | null,
      ) => Observable<FixtureNodeConnection | null>
    })
  cardParallelSearch: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
    era?: String | null
    query?: String | null
    productType?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelSearchResultConnectionObservableChain & {
    execute: (
      request: CardParallelSearchResultConnectionRequest,
      defaultValue?: CardParallelSearchResultConnection | null,
    ) => Observable<CardParallelSearchResultConnection | null>
  }) &
    (CardParallelSearchResultConnectionObservableChain & {
      execute: (
        request: CardParallelSearchResultConnectionRequest,
        defaultValue?: CardParallelSearchResultConnection | null,
      ) => Observable<CardParallelSearchResultConnection | null>
    })
  defaultNotificationPreferences: {
    execute: (
      request: UserNotificationPreferenceNodeRequest,
      defaultValue?: (UserNotificationPreferenceNode | null)[] | null,
    ) => Observable<(UserNotificationPreferenceNode | null)[] | null>
  }
  myNotificationPreferences: {
    execute: (
      request: UserNotificationPreferenceNodeRequest,
      defaultValue?: (UserNotificationPreferenceNode | null)[] | null,
    ) => Observable<(UserNotificationPreferenceNode | null)[] | null>
  }
  sales: ((args?: {
    first?: Int | null
    sport?: String | null
    era?: String | null
  }) => {
    execute: (
      request: RecentSaleNodeRequest,
      defaultValue?: (RecentSaleNode | null)[] | null,
    ) => Observable<(RecentSaleNode | null)[] | null>
  }) & {
    execute: (
      request: RecentSaleNodeRequest,
      defaultValue?: (RecentSaleNode | null)[] | null,
    ) => Observable<(RecentSaleNode | null)[] | null>
  }
  mySales: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
    era?: String | null
    productType?: (String | null)[] | null
    listed?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemOwnershipNodeConnectionObservableChain & {
    execute: (
      request: ItemOwnershipNodeConnectionRequest,
      defaultValue?: ItemOwnershipNodeConnection | null,
    ) => Observable<ItemOwnershipNodeConnection | null>
  }) &
    (ItemOwnershipNodeConnectionObservableChain & {
      execute: (
        request: ItemOwnershipNodeConnectionRequest,
        defaultValue?: ItemOwnershipNodeConnection | null,
      ) => Observable<ItemOwnershipNodeConnection | null>
    })
  myPastPurchases: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
  }) => HistoricalPurchaseNodeConnectionObservableChain & {
    execute: (
      request: HistoricalPurchaseNodeConnectionRequest,
      defaultValue?: HistoricalPurchaseNodeConnection | null,
    ) => Observable<HistoricalPurchaseNodeConnection | null>
  }) &
    (HistoricalPurchaseNodeConnectionObservableChain & {
      execute: (
        request: HistoricalPurchaseNodeConnectionRequest,
        defaultValue?: HistoricalPurchaseNodeConnection | null,
      ) => Observable<HistoricalPurchaseNodeConnection | null>
    })
  myGroupedBids: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => GroupedBidNodeConnectionObservableChain & {
    execute: (
      request: GroupedBidNodeConnectionRequest,
      defaultValue?: GroupedBidNodeConnection | null,
    ) => Observable<GroupedBidNodeConnection | null>
  }) &
    (GroupedBidNodeConnectionObservableChain & {
      execute: (
        request: GroupedBidNodeConnectionRequest,
        defaultValue?: GroupedBidNodeConnection | null,
      ) => Observable<GroupedBidNodeConnection | null>
    })
  myGroupedListings: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => GroupedListingNodeConnectionObservableChain & {
    execute: (
      request: GroupedListingNodeConnectionRequest,
      defaultValue?: GroupedListingNodeConnection | null,
    ) => Observable<GroupedListingNodeConnection | null>
  }) &
    (GroupedListingNodeConnectionObservableChain & {
      execute: (
        request: GroupedListingNodeConnectionRequest,
        defaultValue?: GroupedListingNodeConnection | null,
      ) => Observable<GroupedListingNodeConnection | null>
    })
  gradedCardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    grader?: String | null
    minItems?: Decimal | null
    minListings?: Decimal | null
    minOffers?: Decimal | null
    myListingsStatus?: String | null
  }) => GradedCardParallelNodeConnectionObservableChain & {
    execute: (
      request: GradedCardParallelNodeConnectionRequest,
      defaultValue?: GradedCardParallelNodeConnection | null,
    ) => Observable<GradedCardParallelNodeConnection | null>
  }) &
    (GradedCardParallelNodeConnectionObservableChain & {
      execute: (
        request: GradedCardParallelNodeConnectionRequest,
        defaultValue?: GradedCardParallelNodeConnection | null,
      ) => Observable<GradedCardParallelNodeConnection | null>
    })
  gradedCardParallel: ((args?: {
    id?: ID | null
  }) => GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Observable<GradedCardParallelNode | null>
  }) &
    (GradedCardParallelNodeObservableChain & {
      execute: (
        request: GradedCardParallelNodeRequest,
        defaultValue?: GradedCardParallelNode | null,
      ) => Observable<GradedCardParallelNode | null>
    })
  marketIndices: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    name?: String | null
  }) => MarketIndexNodeConnectionObservableChain & {
    execute: (
      request: MarketIndexNodeConnectionRequest,
      defaultValue?: MarketIndexNodeConnection | null,
    ) => Observable<MarketIndexNodeConnection | null>
  }) &
    (MarketIndexNodeConnectionObservableChain & {
      execute: (
        request: MarketIndexNodeConnectionRequest,
        defaultValue?: MarketIndexNodeConnection | null,
      ) => Observable<MarketIndexNodeConnection | null>
    })
  marketIndex: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => MarketIndexNodeObservableChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode | null) => Observable<MarketIndexNode | null>
  }) &
    (MarketIndexNodeObservableChain & {
      execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode | null) => Observable<MarketIndexNode | null>
    })
  marketWidgets: {
    execute: (
      request: MarketWidgetNodeRequest,
      defaultValue?: (MarketWidgetNode | null)[] | null,
    ) => Observable<(MarketWidgetNode | null)[] | null>
  }
  grades: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => GradeNodeConnectionObservableChain & {
    execute: (
      request: GradeNodeConnectionRequest,
      defaultValue?: GradeNodeConnection | null,
    ) => Observable<GradeNodeConnection | null>
  }) &
    (GradeNodeConnectionObservableChain & {
      execute: (
        request: GradeNodeConnectionRequest,
        defaultValue?: GradeNodeConnection | null,
      ) => Observable<GradeNodeConnection | null>
    })
  myWishlistCards: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionObservableChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Observable<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionObservableChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Observable<CardParallelNodeConnection | null>
    })
  myWishlistPlayers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PlayerNodeConnectionObservableChain & {
    execute: (
      request: PlayerNodeConnectionRequest,
      defaultValue?: PlayerNodeConnection | null,
    ) => Observable<PlayerNodeConnection | null>
  }) &
    (PlayerNodeConnectionObservableChain & {
      execute: (
        request: PlayerNodeConnectionRequest,
        defaultValue?: PlayerNodeConnection | null,
      ) => Observable<PlayerNodeConnection | null>
    })
  userCollection: (args: {
    username: String
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
    era?: String | null
    productType?: (String | null)[] | null
    listed?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemOwnershipNodeConnectionObservableChain & {
    execute: (
      request: ItemOwnershipNodeConnectionRequest,
      defaultValue?: ItemOwnershipNodeConnection | null,
    ) => Observable<ItemOwnershipNodeConnection | null>
  }
  portfolioValue: ((args?: {
    sport?: String | null
    era?: String | null
    timePeriodUnit?: PortfolioValueTimePeriodUnits | null
    timePeriodCount?: Int | null
    timePeriodOffset?: Int | null
  }) => {
    execute: (
      request: PortfolioValueNodeRequest,
      defaultValue?: (PortfolioValueNode | null)[] | null,
    ) => Observable<(PortfolioValueNode | null)[] | null>
  }) & {
    execute: (
      request: PortfolioValueNodeRequest,
      defaultValue?: (PortfolioValueNode | null)[] | null,
    ) => Observable<(PortfolioValueNode | null)[] | null>
  }
  myCollection: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
    era?: String | null
    productType?: (String | null)[] | null
    listed?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemOwnershipNodeConnectionObservableChain & {
    execute: (
      request: ItemOwnershipNodeConnectionRequest,
      defaultValue?: ItemOwnershipNodeConnection | null,
    ) => Observable<ItemOwnershipNodeConnection | null>
  }) &
    (ItemOwnershipNodeConnectionObservableChain & {
      execute: (
        request: ItemOwnershipNodeConnectionRequest,
        defaultValue?: ItemOwnershipNodeConnection | null,
      ) => Observable<ItemOwnershipNodeConnection | null>
    })
  myInDraftShippingRequestCollection: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
    era?: String | null
    productType?: (String | null)[] | null
    listed?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemOwnershipNodeConnectionObservableChain & {
    execute: (
      request: ItemOwnershipNodeConnectionRequest,
      defaultValue?: ItemOwnershipNodeConnection | null,
    ) => Observable<ItemOwnershipNodeConnection | null>
  }) &
    (ItemOwnershipNodeConnectionObservableChain & {
      execute: (
        request: ItemOwnershipNodeConnectionRequest,
        defaultValue?: ItemOwnershipNodeConnection | null,
      ) => Observable<ItemOwnershipNodeConnection | null>
    })
  cardParallel: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => CardParallelNodeObservableChain & {
    execute: (
      request: CardParallelNodeRequest,
      defaultValue?: CardParallelNode | null,
    ) => Observable<CardParallelNode | null>
  }) &
    (CardParallelNodeObservableChain & {
      execute: (
        request: CardParallelNodeRequest,
        defaultValue?: CardParallelNode | null,
      ) => Observable<CardParallelNode | null>
    })
  cardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionObservableChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Observable<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionObservableChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Observable<CardParallelNodeConnection | null>
    })
  myCardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionObservableChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Observable<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionObservableChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Observable<CardParallelNodeConnection | null>
    })
  cardSets: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
  }) => CardSetNodeConnectionObservableChain & {
    execute: (
      request: CardSetNodeConnectionRequest,
      defaultValue?: CardSetNodeConnection | null,
    ) => Observable<CardSetNodeConnection | null>
  }) &
    (CardSetNodeConnectionObservableChain & {
      execute: (
        request: CardSetNodeConnectionRequest,
        defaultValue?: CardSetNodeConnection | null,
      ) => Observable<CardSetNodeConnection | null>
    })
  boxBreak: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }) &
    (BoxBreakNodeObservableChain & {
      execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
    })
  boxBreaks: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Observable<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Observable<BoxBreakNodeConnection | null>
    })
  myBoxBreaks: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Observable<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Observable<BoxBreakNodeConnection | null>
    })
  myPersonalBreakRequests: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionObservableChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection | null,
    ) => Observable<PersonalBreakRequestNodeConnection | null>
  }) &
    (PersonalBreakRequestNodeConnectionObservableChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection | null,
      ) => Observable<PersonalBreakRequestNodeConnection | null>
    })
  remints: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Observable<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Observable<BoxBreakNodeConnection | null>
    })
  remint: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }) &
    (BoxBreakNodeObservableChain & {
      execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
    })
  auctions: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    state?: (String | null)[] | null
    featured?: Boolean | null
    currency?: (String | null)[] | null
    lotRunningFormat?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => AuctionNodeConnectionObservableChain & {
    execute: (
      request: AuctionNodeConnectionRequest,
      defaultValue?: AuctionNodeConnection | null,
    ) => Observable<AuctionNodeConnection | null>
  }) &
    (AuctionNodeConnectionObservableChain & {
      execute: (
        request: AuctionNodeConnectionRequest,
        defaultValue?: AuctionNodeConnection | null,
      ) => Observable<AuctionNodeConnection | null>
    })
  auction: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => AuctionNodeObservableChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Observable<AuctionNode | null>
  }) &
    (AuctionNodeObservableChain & {
      execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Observable<AuctionNode | null>
    })
  lots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    productCategory?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionObservableChain & {
    execute: (
      request: LotNodeConnectionRequest,
      defaultValue?: LotNodeConnection | null,
    ) => Observable<LotNodeConnection | null>
  }) &
    (LotNodeConnectionObservableChain & {
      execute: (
        request: LotNodeConnectionRequest,
        defaultValue?: LotNodeConnection | null,
      ) => Observable<LotNodeConnection | null>
    })
  myBiddingLots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    productCategory?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionObservableChain & {
    execute: (
      request: LotNodeConnectionRequest,
      defaultValue?: LotNodeConnection | null,
    ) => Observable<LotNodeConnection | null>
  }) &
    (LotNodeConnectionObservableChain & {
      execute: (
        request: LotNodeConnectionRequest,
        defaultValue?: LotNodeConnection | null,
      ) => Observable<LotNodeConnection | null>
    })
  lot: ((args?: {
    id?: ID | null
  }) => LotNodeObservableChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Observable<LotNode | null>
  }) &
    (LotNodeObservableChain & {
      execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Observable<LotNode | null>
    })
  bid: ((args?: {
    id?: ID | null
  }) => BidNodeObservableChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
  }) &
    (BidNodeObservableChain & {
      execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
    })
  announcementBanners: {
    execute: (
      request: AnnouncementNodeRequest,
      defaultValue?: (AnnouncementNode | null)[] | null,
    ) => Observable<(AnnouncementNode | null)[] | null>
  }
  myAddresses: {
    execute: (
      request: AddressNodeRequest,
      defaultValue?: (AddressNode | null)[] | null,
    ) => Observable<(AddressNode | null)[] | null>
  }
  me: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  myAccount: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  promotion: PromotionTypeObservableChain & {
    execute: (request: PromotionTypeRequest, defaultValue?: PromotionType | null) => Observable<PromotionType | null>
  }
}

export interface StripePaymentMethodNodePromiseChain {
  paymentMethodId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  brand: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  lastFourDigits: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  expiryMonth: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  expiryYear: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  offSession: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  defaultPaymentMethod: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
}

export interface StripePaymentMethodNodeObservableChain {
  paymentMethodId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  brand: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  lastFourDigits: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  expiryMonth: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  expiryYear: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  offSession: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  defaultPaymentMethod: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
}

/** An object with an ID */
export interface NodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
}

/** An object with an ID */
export interface NodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
}

export interface ChannelNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ChannelNodeEdgeRequest,
      defaultValue?: (ChannelNodeEdge | null)[],
    ) => Promise<(ChannelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface ChannelNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ChannelNodeEdgeRequest,
      defaultValue?: (ChannelNodeEdge | null)[],
    ) => Observable<(ChannelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface PageInfoPromiseChain {
  /** When paginating forwards, are there more items? */
  hasNextPage: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** When paginating backwards, are there more items? */
  hasPreviousPage: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** When paginating backwards, the cursor to continue. */
  startCursor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** When paginating forwards, the cursor to continue. */
  endCursor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface PageInfoObservableChain {
  /** When paginating forwards, are there more items? */
  hasNextPage: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** When paginating backwards, are there more items? */
  hasPreviousPage: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** When paginating backwards, the cursor to continue. */
  startCursor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** When paginating forwards, the cursor to continue. */
  endCursor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

/** A Relay edge containing a `ChannelNode` and its cursor. */
export interface ChannelNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: ChannelNodePromiseChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Promise<ChannelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `ChannelNode` and its cursor. */
export interface ChannelNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: ChannelNodeObservableChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Observable<ChannelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface ChannelNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** User-facing description of this channel and its focus. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL identifier for this channel. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  eventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Promise<EventNodeConnection>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Promise<EventNodeConnection>
    })
  streamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (request: StreamNodeConnectionRequest, defaultValue?: StreamNodeConnection) => Promise<StreamNodeConnection>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (request: StreamNodeConnectionRequest, defaultValue?: StreamNodeConnection) => Promise<StreamNodeConnection>
    })
  nextUpcomingStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Promise<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Promise<StreamNodeConnection | null>
    })
  nextUpcomingStream: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
  liveStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Promise<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Promise<StreamNodeConnection | null>
    })
  liveStream: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
  recentlyEndedStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Promise<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Promise<StreamNodeConnection | null>
    })
  recentlyEndedStream: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
}

export interface ChannelNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** User-facing description of this channel and its focus. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL identifier for this channel. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  eventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionObservableChain & {
    execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Observable<EventNodeConnection>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Observable<EventNodeConnection>
    })
  streamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (request: StreamNodeConnectionRequest, defaultValue?: StreamNodeConnection) => Observable<StreamNodeConnection>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection,
      ) => Observable<StreamNodeConnection>
    })
  nextUpcomingStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Observable<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Observable<StreamNodeConnection | null>
    })
  nextUpcomingStream: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
  liveStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Observable<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Observable<StreamNodeConnection | null>
    })
  liveStream: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
  recentlyEndedStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Observable<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Observable<StreamNodeConnection | null>
    })
  recentlyEndedStream: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
}

export interface EventNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: EventNodeEdgeRequest, defaultValue?: (EventNodeEdge | null)[]) => Promise<(EventNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface EventNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: EventNodeEdgeRequest, defaultValue?: (EventNodeEdge | null)[]) => Observable<(EventNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `EventNode` and its cursor. */
export interface EventNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `EventNode` and its cursor. */
export interface EventNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface EventNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  eventType: { execute: (request?: boolean | number, defaultValue?: EventEventType) => Promise<EventEventType> }
  channel: ChannelNodePromiseChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode) => Promise<ChannelNode>
  }
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  stream: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
  /** Date and time at which this event is scheduled to begin. Does not enforce when it starts; only used as a guideline. May be left blank if not yet scheduled. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Estimated duration of this event. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  /** Optional event-specific replay video, hosted on YouTube. If populated, once the event has ended it will show this video instead of the video from the associated Stream. Leave blank to just continue to show the full Stream video. */
  replayYoutubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  state: { execute: (request?: boolean | number, defaultValue?: EventState) => Promise<EventState> }
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface EventNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  eventType: { execute: (request?: boolean | number, defaultValue?: EventEventType) => Observable<EventEventType> }
  channel: ChannelNodeObservableChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode) => Observable<ChannelNode>
  }
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  stream: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
  /** Date and time at which this event is scheduled to begin. Does not enforce when it starts; only used as a guideline. May be left blank if not yet scheduled. */
  scheduledStartTime: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  /** Estimated duration of this event. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  /** Optional event-specific replay video, hosted on YouTube. If populated, once the event has ended it will show this video instead of the video from the associated Stream. Leave blank to just continue to show the full Stream video. */
  replayYoutubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  state: { execute: (request?: boolean | number, defaultValue?: EventState) => Observable<EventState> }
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface StreamNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Display name for this stream. May be named after the date, number in a series, or just a descriptive title for the content being included in the stream. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** User-facing description of this stream and and what it will be covering. e.g. "Tonight we will be ripping 4 group breaks including a PYT Bowman Chrome break! Personal breaks on-request throughout the night!" */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  channel: ChannelNodePromiseChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode) => Promise<ChannelNode>
  }
  /** Date and time at which this stream is scheduled to begin. Does not enforce when it starts; only used as a guideline. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  /** Estimated duration of this stream. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  /** The 11-digit YouTube video ID for this stream. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  state: { execute: (request?: boolean | number, defaultValue?: StreamState) => Promise<StreamState> }
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  eventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Promise<EventNodeConnection>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Promise<EventNodeConnection>
    })
  nextUpcomingEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Promise<EventNodeConnection | null>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Promise<EventNodeConnection | null>
    })
  nextUpcomingEvent: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
  liveEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Promise<EventNodeConnection | null>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Promise<EventNodeConnection | null>
    })
  liveEvent: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
  recentlyEndedEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Promise<EventNodeConnection | null>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Promise<EventNodeConnection | null>
    })
  recentlyEndedEvent: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
}

export interface StreamNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Display name for this stream. May be named after the date, number in a series, or just a descriptive title for the content being included in the stream. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** User-facing description of this stream and and what it will be covering. e.g. "Tonight we will be ripping 4 group breaks including a PYT Bowman Chrome break! Personal breaks on-request throughout the night!" */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  channel: ChannelNodeObservableChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode) => Observable<ChannelNode>
  }
  /** Date and time at which this stream is scheduled to begin. Does not enforce when it starts; only used as a guideline. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  /** Estimated duration of this stream. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  /** The 11-digit YouTube video ID for this stream. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  state: { execute: (request?: boolean | number, defaultValue?: StreamState) => Observable<StreamState> }
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  eventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionObservableChain & {
    execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Observable<EventNodeConnection>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Observable<EventNodeConnection>
    })
  nextUpcomingEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionObservableChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Observable<EventNodeConnection | null>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Observable<EventNodeConnection | null>
    })
  nextUpcomingEvent: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
  liveEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionObservableChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Observable<EventNodeConnection | null>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Observable<EventNodeConnection | null>
    })
  liveEvent: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
  recentlyEndedEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionObservableChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Observable<EventNodeConnection | null>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Observable<EventNodeConnection | null>
    })
  recentlyEndedEvent: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
}

export interface BoxBreakNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  format: FormatNodePromiseChain & {
    execute: (request: FormatNodeRequest, defaultValue?: FormatNode) => Promise<FormatNode>
  }
  /** Display name for this break (e.g. "2021 Topps Definitive Baseball 3x Hobby Box Break: 2 Random Teams") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL identifier for this break */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Assign a featured rank to promote this break. Higher values take priority. Use sparingly; leave blank for most breaks. */
  featuredRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** A text description of this break, for example outlining the break format (random teams, PYT...) and products being broken. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Date and time at which this break is scheduled to begin. Presented to users as approximate. Leave blank if break is not yet scheduled. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  createdBy: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  state: { execute: (request?: boolean | number, defaultValue?: BoxBreakState) => Promise<BoxBreakState> }
  /** Date and time at which this break was published onto the live site. */
  publishedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the break opened for entries. */
  openedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the live break began. */
  startedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the break stopped accepting new entries. */
  lockedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the live break concluded. */
  endedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the break's items completed processing. */
  settledAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** The 11-digit video ID for the YouTube video this break will be streamed on. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The name for the Twitch channel this break will be streamed on. */
  twitchChannelName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  breakLocation: BreakLocationNodePromiseChain & {
    execute: (request: BreakLocationNodeRequest, defaultValue?: BreakLocationNode) => Promise<BreakLocationNode>
  }
  /** Choose where the event is visible and who can access it. */
  visibility: { execute: (request?: boolean | number, defaultValue?: BoxBreakVisibility) => Promise<BoxBreakVisibility> }
  /** Optional restriction on how many spots a participant can claim. */
  maxSpotsPerUser: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** Optional URL to link to buy page for reMints */
  purchaseOverrideUrl: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Break in which this box is going to be or was opened. */
  boxSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxNodeConnectionPromiseChain & {
    execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Promise<BoxNodeConnection>
  }) &
    (BoxNodeConnectionPromiseChain & {
      execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Promise<BoxNodeConnection>
    })
  boxbreakphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxBreakPhotoNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakPhotoNodeConnectionRequest,
      defaultValue?: BoxBreakPhotoNodeConnection,
    ) => Promise<BoxBreakPhotoNodeConnection>
  }) &
    (BoxBreakPhotoNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakPhotoNodeConnectionRequest,
        defaultValue?: BoxBreakPhotoNodeConnection,
      ) => Promise<BoxBreakPhotoNodeConnection>
    })
  spotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SpotNodeConnectionPromiseChain & {
    execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Promise<SpotNodeConnection>
  }) &
    (SpotNodeConnectionPromiseChain & {
      execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Promise<SpotNodeConnection>
    })
  /** The scheduled personal break that the user would like their box broken as part of. */
  personalbreakrequestSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionPromiseChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection,
    ) => Promise<PersonalBreakRequestNodeConnection>
  }) &
    (PersonalBreakRequestNodeConnectionPromiseChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection,
      ) => Promise<PersonalBreakRequestNodeConnection>
    })
  /** Break in which this nft item is going to be or was opened. */
  remintnftSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => RemintNFTNodeConnectionPromiseChain & {
    execute: (
      request: RemintNFTNodeConnectionRequest,
      defaultValue?: RemintNFTNodeConnection,
    ) => Promise<RemintNFTNodeConnection>
  }) &
    (RemintNFTNodeConnectionPromiseChain & {
      execute: (
        request: RemintNFTNodeConnectionRequest,
        defaultValue?: RemintNFTNodeConnection,
      ) => Promise<RemintNFTNodeConnection>
    })
  event: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
  myParticipant: ParticipantNodePromiseChain & {
    execute: (request: ParticipantNodeRequest, defaultValue?: ParticipantNode | null) => Promise<ParticipantNode | null>
  }
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionPromiseChain & {
    execute: (
      request: HitNodeConnectionRequest,
      defaultValue?: HitNodeConnection | null,
    ) => Promise<HitNodeConnection | null>
  }) &
    (HitNodeConnectionPromiseChain & {
      execute: (
        request: HitNodeConnectionRequest,
        defaultValue?: HitNodeConnection | null,
      ) => Promise<HitNodeConnection | null>
    })
  targetPlayers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PlayerNodeConnectionPromiseChain & {
    execute: (
      request: PlayerNodeConnectionRequest,
      defaultValue?: PlayerNodeConnection | null,
    ) => Promise<PlayerNodeConnection | null>
  }) &
    (PlayerNodeConnectionPromiseChain & {
      execute: (
        request: PlayerNodeConnectionRequest,
        defaultValue?: PlayerNodeConnection | null,
      ) => Promise<PlayerNodeConnection | null>
    })
  spotsTotalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  spotsAvailableCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  spotsFilledCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  spotsMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  spotsMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  spotsCurrency: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  allowPersonalBreakRequests: {
    execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null>
  }
}

export interface BoxBreakNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  format: FormatNodeObservableChain & {
    execute: (request: FormatNodeRequest, defaultValue?: FormatNode) => Observable<FormatNode>
  }
  /** Display name for this break (e.g. "2021 Topps Definitive Baseball 3x Hobby Box Break: 2 Random Teams") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL identifier for this break */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Assign a featured rank to promote this break. Higher values take priority. Use sparingly; leave blank for most breaks. */
  featuredRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** A text description of this break, for example outlining the break format (random teams, PYT...) and products being broken. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Date and time at which this break is scheduled to begin. Presented to users as approximate. Leave blank if break is not yet scheduled. */
  scheduledStartTime: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  createdBy: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  state: { execute: (request?: boolean | number, defaultValue?: BoxBreakState) => Observable<BoxBreakState> }
  /** Date and time at which this break was published onto the live site. */
  publishedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the break opened for entries. */
  openedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the live break began. */
  startedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the break stopped accepting new entries. */
  lockedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the live break concluded. */
  endedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the break's items completed processing. */
  settledAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** The 11-digit video ID for the YouTube video this break will be streamed on. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The name for the Twitch channel this break will be streamed on. */
  twitchChannelName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  breakLocation: BreakLocationNodeObservableChain & {
    execute: (request: BreakLocationNodeRequest, defaultValue?: BreakLocationNode) => Observable<BreakLocationNode>
  }
  /** Choose where the event is visible and who can access it. */
  visibility: { execute: (request?: boolean | number, defaultValue?: BoxBreakVisibility) => Observable<BoxBreakVisibility> }
  /** Optional restriction on how many spots a participant can claim. */
  maxSpotsPerUser: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** Optional URL to link to buy page for reMints */
  purchaseOverrideUrl: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Break in which this box is going to be or was opened. */
  boxSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxNodeConnectionObservableChain & {
    execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Observable<BoxNodeConnection>
  }) &
    (BoxNodeConnectionObservableChain & {
      execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Observable<BoxNodeConnection>
    })
  boxbreakphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxBreakPhotoNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakPhotoNodeConnectionRequest,
      defaultValue?: BoxBreakPhotoNodeConnection,
    ) => Observable<BoxBreakPhotoNodeConnection>
  }) &
    (BoxBreakPhotoNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakPhotoNodeConnectionRequest,
        defaultValue?: BoxBreakPhotoNodeConnection,
      ) => Observable<BoxBreakPhotoNodeConnection>
    })
  spotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SpotNodeConnectionObservableChain & {
    execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Observable<SpotNodeConnection>
  }) &
    (SpotNodeConnectionObservableChain & {
      execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Observable<SpotNodeConnection>
    })
  /** The scheduled personal break that the user would like their box broken as part of. */
  personalbreakrequestSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionObservableChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection,
    ) => Observable<PersonalBreakRequestNodeConnection>
  }) &
    (PersonalBreakRequestNodeConnectionObservableChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection,
      ) => Observable<PersonalBreakRequestNodeConnection>
    })
  /** Break in which this nft item is going to be or was opened. */
  remintnftSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => RemintNFTNodeConnectionObservableChain & {
    execute: (
      request: RemintNFTNodeConnectionRequest,
      defaultValue?: RemintNFTNodeConnection,
    ) => Observable<RemintNFTNodeConnection>
  }) &
    (RemintNFTNodeConnectionObservableChain & {
      execute: (
        request: RemintNFTNodeConnectionRequest,
        defaultValue?: RemintNFTNodeConnection,
      ) => Observable<RemintNFTNodeConnection>
    })
  event: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
  myParticipant: ParticipantNodeObservableChain & {
    execute: (request: ParticipantNodeRequest, defaultValue?: ParticipantNode | null) => Observable<ParticipantNode | null>
  }
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionObservableChain & {
    execute: (
      request: HitNodeConnectionRequest,
      defaultValue?: HitNodeConnection | null,
    ) => Observable<HitNodeConnection | null>
  }) &
    (HitNodeConnectionObservableChain & {
      execute: (
        request: HitNodeConnectionRequest,
        defaultValue?: HitNodeConnection | null,
      ) => Observable<HitNodeConnection | null>
    })
  targetPlayers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PlayerNodeConnectionObservableChain & {
    execute: (
      request: PlayerNodeConnectionRequest,
      defaultValue?: PlayerNodeConnection | null,
    ) => Observable<PlayerNodeConnection | null>
  }) &
    (PlayerNodeConnectionObservableChain & {
      execute: (
        request: PlayerNodeConnectionRequest,
        defaultValue?: PlayerNodeConnection | null,
      ) => Observable<PlayerNodeConnection | null>
    })
  spotsTotalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  spotsAvailableCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  spotsFilledCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  spotsMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  spotsMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  spotsCurrency: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  allowPersonalBreakRequests: {
    execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null>
  }
}

export interface FormatNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** A descriptive name for this break format (e.g. "Random Team" or "Hit Draft") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Optional description of this break format, to inform users about how it works and why they might want to choose this format over another. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Indicates whether it is a group break, open to multiple participants, or a personal break for a single user. */
  breakCategory: {
    execute: (request?: boolean | number, defaultValue?: FormatBreakCategory) => Promise<FormatBreakCategory>
  }
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  boxbreakSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection,
    ) => Promise<BoxBreakNodeConnection>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection,
      ) => Promise<BoxBreakNodeConnection>
    })
}

export interface FormatNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** A descriptive name for this break format (e.g. "Random Team" or "Hit Draft") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Optional description of this break format, to inform users about how it works and why they might want to choose this format over another. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Indicates whether it is a group break, open to multiple participants, or a personal break for a single user. */
  breakCategory: {
    execute: (request?: boolean | number, defaultValue?: FormatBreakCategory) => Observable<FormatBreakCategory>
  }
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  boxbreakSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection,
    ) => Observable<BoxBreakNodeConnection>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection,
      ) => Observable<BoxBreakNodeConnection>
    })
}

export interface BoxBreakNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BoxBreakNodeEdgeRequest,
      defaultValue?: (BoxBreakNodeEdge | null)[],
    ) => Promise<(BoxBreakNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface BoxBreakNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BoxBreakNodeEdgeRequest,
      defaultValue?: (BoxBreakNodeEdge | null)[],
    ) => Observable<(BoxBreakNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `BoxBreakNode` and its cursor. */
export interface BoxBreakNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `BoxBreakNode` and its cursor. */
export interface BoxBreakNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface UserTypePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  firstName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  lastName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  email: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  userprofile: UserProfileTypePromiseChain & {
    execute: (request: UserProfileTypeRequest, defaultValue?: UserProfileType | null) => Promise<UserProfileType | null>
  }
  balance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  isStaff: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface UserTypeObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  firstName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  lastName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  email: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  userprofile: UserProfileTypeObservableChain & {
    execute: (request: UserProfileTypeRequest, defaultValue?: UserProfileType | null) => Observable<UserProfileType | null>
  }
  balance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  isStaff: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface UserProfileTypePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  marketingEmails: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  buyTargetPercentage: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  sellTargetPercentage: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  dailyDigestHour: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  isCollectionPublic: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
}

export interface UserProfileTypeObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  marketingEmails: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  buyTargetPercentage: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  sellTargetPercentage: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  dailyDigestHour: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  isCollectionPublic: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
}

export interface BreakLocationNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** e.g. StarStock New York office, Partner Studio... */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Enable to allow users to request an item in their collection be broken during a personal break. */
  allowPersonalBreakRequests: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  boxbreakSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection,
    ) => Promise<BoxBreakNodeConnection>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection,
      ) => Promise<BoxBreakNodeConnection>
    })
}

export interface BreakLocationNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** e.g. StarStock New York office, Partner Studio... */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Enable to allow users to request an item in their collection be broken during a personal break. */
  allowPersonalBreakRequests: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  boxbreakSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    sport?: String | null
    era?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection,
    ) => Observable<BoxBreakNodeConnection>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection,
      ) => Observable<BoxBreakNodeConnection>
    })
}

export interface BoxNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: { execute: (request: BoxNodeEdgeRequest, defaultValue?: (BoxNodeEdge | null)[]) => Promise<(BoxNodeEdge | null)[]> }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface BoxNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: BoxNodeEdgeRequest, defaultValue?: (BoxNodeEdge | null)[]) => Observable<(BoxNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `BoxNode` and its cursor. */
export interface BoxNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: BoxNodePromiseChain & {
    execute: (request: BoxNodeRequest, defaultValue?: BoxNode | null) => Promise<BoxNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `BoxNode` and its cursor. */
export interface BoxNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: BoxNodeObservableChain & {
    execute: (request: BoxNodeRequest, defaultValue?: BoxNode | null) => Observable<BoxNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BoxNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Mandatory connection to a product type in the wider card database. */
  gradedCardParallel: GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode,
    ) => Promise<GradedCardParallelNode>
  }
  /** Optional display name for this this breakable product (e.g. Prizm Football 2020 Hobby Box). If blank, name is taken from associated product type. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Break in which this box is going to be or was opened. */
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  createdBy: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
}

export interface BoxNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Mandatory connection to a product type in the wider card database. */
  gradedCardParallel: GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode,
    ) => Observable<GradedCardParallelNode>
  }
  /** Optional display name for this this breakable product (e.g. Prizm Football 2020 Hobby Box). If blank, name is taken from associated product type. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Break in which this box is going to be or was opened. */
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  createdBy: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
}

export interface GradedCardParallelNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  cardParallel: CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode | null) => Promise<CardParallelNode | null>
  }
  grade: GradeNodePromiseChain & { execute: (request: GradeNodeRequest, defaultValue?: GradeNode) => Promise<GradeNode> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  itemFirstCreatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  openBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  openBuyOrderMaxPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: GradedCardParallelOpenBuyOrderMaxPriceCurrency | null,
    ) => Promise<GradedCardParallelOpenBuyOrderMaxPriceCurrency | null>
  }
  openBuyOrderMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  openBuyOrderMaxCreatedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null>
  }
  openSellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  openSellOrderMinPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: GradedCardParallelOpenSellOrderMinPriceCurrency | null,
    ) => Promise<GradedCardParallelOpenSellOrderMinPriceCurrency | null>
  }
  openSellOrderMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  openSellOrderMaxCreatedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null>
  }
  lastSaleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  lastSalePriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: GradedCardParallelLastSalePriceCurrency | null,
    ) => Promise<GradedCardParallelLastSalePriceCurrency | null>
  }
  lastSalePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  itemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    search?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemNodeConnectionPromiseChain & {
    execute: (request: ItemNodeConnectionRequest, defaultValue?: ItemNodeConnection) => Promise<ItemNodeConnection>
  }) &
    (ItemNodeConnectionPromiseChain & {
      execute: (request: ItemNodeConnectionRequest, defaultValue?: ItemNodeConnection) => Promise<ItemNodeConnection>
    })
  historicalpurchaseSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
  }) => HistoricalPurchaseNodeConnectionPromiseChain & {
    execute: (
      request: HistoricalPurchaseNodeConnectionRequest,
      defaultValue?: HistoricalPurchaseNodeConnection,
    ) => Promise<HistoricalPurchaseNodeConnection>
  }) &
    (HistoricalPurchaseNodeConnectionPromiseChain & {
      execute: (
        request: HistoricalPurchaseNodeConnectionRequest,
        defaultValue?: HistoricalPurchaseNodeConnection,
      ) => Promise<HistoricalPurchaseNodeConnection>
    })
  buyorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BuyOrderNodeConnectionPromiseChain & {
    execute: (
      request: BuyOrderNodeConnectionRequest,
      defaultValue?: BuyOrderNodeConnection,
    ) => Promise<BuyOrderNodeConnection>
  }) &
    (BuyOrderNodeConnectionPromiseChain & {
      execute: (
        request: BuyOrderNodeConnectionRequest,
        defaultValue?: BuyOrderNodeConnection,
      ) => Promise<BuyOrderNodeConnection>
    })
  sellorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SellOrderNodeConnectionPromiseChain & {
    execute: (
      request: SellOrderNodeConnectionRequest,
      defaultValue?: SellOrderNodeConnection,
    ) => Promise<SellOrderNodeConnection>
  }) &
    (SellOrderNodeConnectionPromiseChain & {
      execute: (
        request: SellOrderNodeConnectionRequest,
        defaultValue?: SellOrderNodeConnection,
      ) => Promise<SellOrderNodeConnection>
    })
  marketindexcomponentSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
  }) => MarketIndexComponentNodeConnectionPromiseChain & {
    execute: (
      request: MarketIndexComponentNodeConnectionRequest,
      defaultValue?: MarketIndexComponentNodeConnection,
    ) => Promise<MarketIndexComponentNodeConnection>
  }) &
    (MarketIndexComponentNodeConnectionPromiseChain & {
      execute: (
        request: MarketIndexComponentNodeConnectionRequest,
        defaultValue?: MarketIndexComponentNodeConnection,
      ) => Promise<MarketIndexComponentNodeConnection>
    })
  lotitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotItemNodeConnectionPromiseChain & {
    execute: (request: LotItemNodeConnectionRequest, defaultValue?: LotItemNodeConnection) => Promise<LotItemNodeConnection>
  }) &
    (LotItemNodeConnectionPromiseChain & {
      execute: (
        request: LotItemNodeConnectionRequest,
        defaultValue?: LotItemNodeConnection,
      ) => Promise<LotItemNodeConnection>
    })
  /** Mandatory connection to a product type in the wider card database. */
  boxSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxNodeConnectionPromiseChain & {
    execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Promise<BoxNodeConnection>
  }) &
    (BoxNodeConnectionPromiseChain & {
      execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Promise<BoxNodeConnection>
    })
  /** The type and grade of the card hit. As these are pack-fresh pulls, we typically assume StarStock A grade. */
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionPromiseChain & {
    execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Promise<HitNodeConnection>
  }) &
    (HitNodeConnectionPromiseChain & {
      execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Promise<HitNodeConnection>
    })
  items: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => ItemNodeConnectionPromiseChain & {
    execute: (
      request: ItemNodeConnectionRequest,
      defaultValue?: ItemNodeConnection | null,
    ) => Promise<ItemNodeConnection | null>
  }) &
    (ItemNodeConnectionPromiseChain & {
      execute: (
        request: ItemNodeConnectionRequest,
        defaultValue?: ItemNodeConnection | null,
      ) => Promise<ItemNodeConnection | null>
    })
  openSellOrders: {
    execute: (
      request: SellOrderNodeRequest,
      defaultValue?: (SellOrderNode | null)[] | null,
    ) => Promise<(SellOrderNode | null)[] | null>
  }
  openBuyOrders: {
    execute: (
      request: BuyOrderNodeRequest,
      defaultValue?: (BuyOrderNode | null)[] | null,
    ) => Promise<(BuyOrderNode | null)[] | null>
  }
  myItems: {
    execute: (request: ItemNodeRequest, defaultValue?: (ItemNode | null)[] | null) => Promise<(ItemNode | null)[] | null>
  }
  myItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  myOpenSellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  myOpenSellOrderMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  myOpenBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  mySuspendedBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  myGroupedBids: {
    execute: (
      request: GroupedBidNodeRequest,
      defaultValue?: (GroupedBidNode | null)[] | null,
    ) => Promise<(GroupedBidNode | null)[] | null>
  }
  myGroupedListings: {
    execute: (
      request: GroupedListingNodeRequest,
      defaultValue?: (GroupedListingNode | null)[] | null,
    ) => Promise<(GroupedListingNode | null)[] | null>
  }
}

export interface GradedCardParallelNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  cardParallel: CardParallelNodeObservableChain & {
    execute: (
      request: CardParallelNodeRequest,
      defaultValue?: CardParallelNode | null,
    ) => Observable<CardParallelNode | null>
  }
  grade: GradeNodeObservableChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode) => Observable<GradeNode>
  }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  itemFirstCreatedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  openBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  openBuyOrderMaxPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: GradedCardParallelOpenBuyOrderMaxPriceCurrency | null,
    ) => Observable<GradedCardParallelOpenBuyOrderMaxPriceCurrency | null>
  }
  openBuyOrderMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  openBuyOrderMaxCreatedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  openSellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  openSellOrderMinPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: GradedCardParallelOpenSellOrderMinPriceCurrency | null,
    ) => Observable<GradedCardParallelOpenSellOrderMinPriceCurrency | null>
  }
  openSellOrderMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  openSellOrderMaxCreatedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  lastSaleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  lastSalePriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: GradedCardParallelLastSalePriceCurrency | null,
    ) => Observable<GradedCardParallelLastSalePriceCurrency | null>
  }
  lastSalePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  itemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    search?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemNodeConnectionObservableChain & {
    execute: (request: ItemNodeConnectionRequest, defaultValue?: ItemNodeConnection) => Observable<ItemNodeConnection>
  }) &
    (ItemNodeConnectionObservableChain & {
      execute: (request: ItemNodeConnectionRequest, defaultValue?: ItemNodeConnection) => Observable<ItemNodeConnection>
    })
  historicalpurchaseSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
  }) => HistoricalPurchaseNodeConnectionObservableChain & {
    execute: (
      request: HistoricalPurchaseNodeConnectionRequest,
      defaultValue?: HistoricalPurchaseNodeConnection,
    ) => Observable<HistoricalPurchaseNodeConnection>
  }) &
    (HistoricalPurchaseNodeConnectionObservableChain & {
      execute: (
        request: HistoricalPurchaseNodeConnectionRequest,
        defaultValue?: HistoricalPurchaseNodeConnection,
      ) => Observable<HistoricalPurchaseNodeConnection>
    })
  buyorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BuyOrderNodeConnectionObservableChain & {
    execute: (
      request: BuyOrderNodeConnectionRequest,
      defaultValue?: BuyOrderNodeConnection,
    ) => Observable<BuyOrderNodeConnection>
  }) &
    (BuyOrderNodeConnectionObservableChain & {
      execute: (
        request: BuyOrderNodeConnectionRequest,
        defaultValue?: BuyOrderNodeConnection,
      ) => Observable<BuyOrderNodeConnection>
    })
  sellorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SellOrderNodeConnectionObservableChain & {
    execute: (
      request: SellOrderNodeConnectionRequest,
      defaultValue?: SellOrderNodeConnection,
    ) => Observable<SellOrderNodeConnection>
  }) &
    (SellOrderNodeConnectionObservableChain & {
      execute: (
        request: SellOrderNodeConnectionRequest,
        defaultValue?: SellOrderNodeConnection,
      ) => Observable<SellOrderNodeConnection>
    })
  marketindexcomponentSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
  }) => MarketIndexComponentNodeConnectionObservableChain & {
    execute: (
      request: MarketIndexComponentNodeConnectionRequest,
      defaultValue?: MarketIndexComponentNodeConnection,
    ) => Observable<MarketIndexComponentNodeConnection>
  }) &
    (MarketIndexComponentNodeConnectionObservableChain & {
      execute: (
        request: MarketIndexComponentNodeConnectionRequest,
        defaultValue?: MarketIndexComponentNodeConnection,
      ) => Observable<MarketIndexComponentNodeConnection>
    })
  lotitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotItemNodeConnectionObservableChain & {
    execute: (
      request: LotItemNodeConnectionRequest,
      defaultValue?: LotItemNodeConnection,
    ) => Observable<LotItemNodeConnection>
  }) &
    (LotItemNodeConnectionObservableChain & {
      execute: (
        request: LotItemNodeConnectionRequest,
        defaultValue?: LotItemNodeConnection,
      ) => Observable<LotItemNodeConnection>
    })
  /** Mandatory connection to a product type in the wider card database. */
  boxSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxNodeConnectionObservableChain & {
    execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Observable<BoxNodeConnection>
  }) &
    (BoxNodeConnectionObservableChain & {
      execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Observable<BoxNodeConnection>
    })
  /** The type and grade of the card hit. As these are pack-fresh pulls, we typically assume StarStock A grade. */
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionObservableChain & {
    execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Observable<HitNodeConnection>
  }) &
    (HitNodeConnectionObservableChain & {
      execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Observable<HitNodeConnection>
    })
  items: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => ItemNodeConnectionObservableChain & {
    execute: (
      request: ItemNodeConnectionRequest,
      defaultValue?: ItemNodeConnection | null,
    ) => Observable<ItemNodeConnection | null>
  }) &
    (ItemNodeConnectionObservableChain & {
      execute: (
        request: ItemNodeConnectionRequest,
        defaultValue?: ItemNodeConnection | null,
      ) => Observable<ItemNodeConnection | null>
    })
  openSellOrders: {
    execute: (
      request: SellOrderNodeRequest,
      defaultValue?: (SellOrderNode | null)[] | null,
    ) => Observable<(SellOrderNode | null)[] | null>
  }
  openBuyOrders: {
    execute: (
      request: BuyOrderNodeRequest,
      defaultValue?: (BuyOrderNode | null)[] | null,
    ) => Observable<(BuyOrderNode | null)[] | null>
  }
  myItems: {
    execute: (request: ItemNodeRequest, defaultValue?: (ItemNode | null)[] | null) => Observable<(ItemNode | null)[] | null>
  }
  myItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  myOpenSellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  myOpenSellOrderMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  myOpenBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  mySuspendedBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  myGroupedBids: {
    execute: (
      request: GroupedBidNodeRequest,
      defaultValue?: (GroupedBidNode | null)[] | null,
    ) => Observable<(GroupedBidNode | null)[] | null>
  }
  myGroupedListings: {
    execute: (
      request: GroupedListingNodeRequest,
      defaultValue?: (GroupedListingNode | null)[] | null,
    ) => Observable<(GroupedListingNode | null)[] | null>
  }
}

export interface CardParallelNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  sport: SportNodePromiseChain & { execute: (request: SportNodeRequest, defaultValue?: SportNode) => Promise<SportNode> }
  productType: {
    execute: (request?: boolean | number, defaultValue?: CardParallelProductType) => Promise<CardParallelProductType>
  }
  era: { execute: (request?: boolean | number, defaultValue?: CardParallelEra) => Promise<CardParallelEra> }
  playerCard: PlayerCardNodePromiseChain & {
    execute: (request: PlayerCardNodeRequest, defaultValue?: PlayerCardNode) => Promise<PlayerCardNode>
  }
  parallel: CardSetParallelNodePromiseChain & {
    execute: (
      request: CardSetParallelNodeRequest,
      defaultValue?: CardSetParallelNode | null,
    ) => Promise<CardSetParallelNode | null>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  serialLimit: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  trendingRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  frontSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  boxProductPhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  ebaySearchTerm: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  boxRetailPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: CardParallelBoxRetailPriceCurrency | null,
    ) => Promise<CardParallelBoxRetailPriceCurrency | null>
  }
  boxRetailPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  /** Estimated time required to open this box in a break, in minutes. Only relevant for boxes; leave blank for cards. */
  boxBreakDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  boxPacksPerBox: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  boxCardsPerPack: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** Optional text description of this product (e.g. target players / parallels / autos in a box, historic importance of card, etc...) */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  itemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    search?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemNodeConnectionPromiseChain & {
    execute: (request: ItemNodeConnectionRequest, defaultValue?: ItemNodeConnection) => Promise<ItemNodeConnection>
  }) &
    (ItemNodeConnectionPromiseChain & {
      execute: (request: ItemNodeConnectionRequest, defaultValue?: ItemNodeConnection) => Promise<ItemNodeConnection>
    })
  wishlistitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
  }) => WishlistItemNodeConnectionPromiseChain & {
    execute: (
      request: WishlistItemNodeConnectionRequest,
      defaultValue?: WishlistItemNodeConnection,
    ) => Promise<WishlistItemNodeConnection>
  }) &
    (WishlistItemNodeConnectionPromiseChain & {
      execute: (
        request: WishlistItemNodeConnectionRequest,
        defaultValue?: WishlistItemNodeConnection,
      ) => Promise<WishlistItemNodeConnection>
    })
  historicalpurchaseSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
  }) => HistoricalPurchaseNodeConnectionPromiseChain & {
    execute: (
      request: HistoricalPurchaseNodeConnectionRequest,
      defaultValue?: HistoricalPurchaseNodeConnection,
    ) => Promise<HistoricalPurchaseNodeConnection>
  }) &
    (HistoricalPurchaseNodeConnectionPromiseChain & {
      execute: (
        request: HistoricalPurchaseNodeConnectionRequest,
        defaultValue?: HistoricalPurchaseNodeConnection,
      ) => Promise<HistoricalPurchaseNodeConnection>
    })
  buyorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BuyOrderNodeConnectionPromiseChain & {
    execute: (
      request: BuyOrderNodeConnectionRequest,
      defaultValue?: BuyOrderNodeConnection,
    ) => Promise<BuyOrderNodeConnection>
  }) &
    (BuyOrderNodeConnectionPromiseChain & {
      execute: (
        request: BuyOrderNodeConnectionRequest,
        defaultValue?: BuyOrderNodeConnection,
      ) => Promise<BuyOrderNodeConnection>
    })
  sellorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SellOrderNodeConnectionPromiseChain & {
    execute: (
      request: SellOrderNodeConnectionRequest,
      defaultValue?: SellOrderNodeConnection,
    ) => Promise<SellOrderNodeConnection>
  }) &
    (SellOrderNodeConnectionPromiseChain & {
      execute: (
        request: SellOrderNodeConnectionRequest,
        defaultValue?: SellOrderNodeConnection,
      ) => Promise<SellOrderNodeConnection>
    })
  gradedcardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    grader?: String | null
    minItems?: Decimal | null
    minListings?: Decimal | null
    minOffers?: Decimal | null
    myListingsStatus?: String | null
  }) => GradedCardParallelNodeConnectionPromiseChain & {
    execute: (
      request: GradedCardParallelNodeConnectionRequest,
      defaultValue?: GradedCardParallelNodeConnection,
    ) => Promise<GradedCardParallelNodeConnection>
  }) &
    (GradedCardParallelNodeConnectionPromiseChain & {
      execute: (
        request: GradedCardParallelNodeConnectionRequest,
        defaultValue?: GradedCardParallelNodeConnection,
      ) => Promise<GradedCardParallelNodeConnection>
    })
  cardparallelsearch: CardParallelSearchResultPromiseChain & {
    execute: (
      request: CardParallelSearchResultRequest,
      defaultValue?: CardParallelSearchResult | null,
    ) => Promise<CardParallelSearchResult | null>
  }
  sportId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  sellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  sellOrderMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  buyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  buyOrderMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  lastListedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  lastSaleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  firstAddedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  openBuyOrders: {
    execute: (
      request: BuyOrderNodeRequest,
      defaultValue?: (BuyOrderNode | null)[] | null,
    ) => Promise<(BuyOrderNode | null)[] | null>
  }
  openSellOrders: {
    execute: (
      request: SellOrderNodeRequest,
      defaultValue?: (SellOrderNode | null)[] | null,
    ) => Promise<(SellOrderNode | null)[] | null>
  }
  myGroupedBids: {
    execute: (
      request: GroupedBidNodeRequest,
      defaultValue?: (GroupedBidNode | null)[] | null,
    ) => Promise<(GroupedBidNode | null)[] | null>
  }
  myGroupedListings: {
    execute: (
      request: GroupedListingNodeRequest,
      defaultValue?: (GroupedListingNode | null)[] | null,
    ) => Promise<(GroupedListingNode | null)[] | null>
  }
  myItems: {
    execute: (request: ItemNodeRequest, defaultValue?: (ItemNode | null)[] | null) => Promise<(ItemNode | null)[] | null>
  }
  lastSalePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  recentPurchases: {
    execute: (
      request: HistoricalPurchaseNodeRequest,
      defaultValue?: (HistoricalPurchaseNode | null)[] | null,
    ) => Promise<(HistoricalPurchaseNode | null)[] | null>
  }
  myWishlistItems: {
    execute: (
      request: WishlistItemNodeRequest,
      defaultValue?: (WishlistItemNode | null)[] | null,
    ) => Promise<(WishlistItemNode | null)[] | null>
  }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  myItemsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  myValuedItemsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  myItemsValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  myItemsMaxValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  myListingsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  myListingsMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  myItemOwnershipsMinStartedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null>
  }
  myItemOwnershipsMaxStartedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null>
  }
  frontSidePhotoThumbnail: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  frontSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  frontSidePhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhotoThumbnail: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null>
  }
  reverseSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  boxProductPhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  boxProductPhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  salesLast24Hours: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface CardParallelNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  sport: SportNodeObservableChain & {
    execute: (request: SportNodeRequest, defaultValue?: SportNode) => Observable<SportNode>
  }
  productType: {
    execute: (request?: boolean | number, defaultValue?: CardParallelProductType) => Observable<CardParallelProductType>
  }
  era: { execute: (request?: boolean | number, defaultValue?: CardParallelEra) => Observable<CardParallelEra> }
  playerCard: PlayerCardNodeObservableChain & {
    execute: (request: PlayerCardNodeRequest, defaultValue?: PlayerCardNode) => Observable<PlayerCardNode>
  }
  parallel: CardSetParallelNodeObservableChain & {
    execute: (
      request: CardSetParallelNodeRequest,
      defaultValue?: CardSetParallelNode | null,
    ) => Observable<CardSetParallelNode | null>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  serialLimit: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  trendingRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  frontSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  reverseSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  boxProductPhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  ebaySearchTerm: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  boxRetailPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: CardParallelBoxRetailPriceCurrency | null,
    ) => Observable<CardParallelBoxRetailPriceCurrency | null>
  }
  boxRetailPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  /** Estimated time required to open this box in a break, in minutes. Only relevant for boxes; leave blank for cards. */
  boxBreakDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  boxPacksPerBox: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  boxCardsPerPack: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** Optional text description of this product (e.g. target players / parallels / autos in a box, historic importance of card, etc...) */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  itemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    search?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => ItemNodeConnectionObservableChain & {
    execute: (request: ItemNodeConnectionRequest, defaultValue?: ItemNodeConnection) => Observable<ItemNodeConnection>
  }) &
    (ItemNodeConnectionObservableChain & {
      execute: (request: ItemNodeConnectionRequest, defaultValue?: ItemNodeConnection) => Observable<ItemNodeConnection>
    })
  wishlistitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
  }) => WishlistItemNodeConnectionObservableChain & {
    execute: (
      request: WishlistItemNodeConnectionRequest,
      defaultValue?: WishlistItemNodeConnection,
    ) => Observable<WishlistItemNodeConnection>
  }) &
    (WishlistItemNodeConnectionObservableChain & {
      execute: (
        request: WishlistItemNodeConnectionRequest,
        defaultValue?: WishlistItemNodeConnection,
      ) => Observable<WishlistItemNodeConnection>
    })
  historicalpurchaseSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    searchQuery?: String | null
    sport?: String | null
  }) => HistoricalPurchaseNodeConnectionObservableChain & {
    execute: (
      request: HistoricalPurchaseNodeConnectionRequest,
      defaultValue?: HistoricalPurchaseNodeConnection,
    ) => Observable<HistoricalPurchaseNodeConnection>
  }) &
    (HistoricalPurchaseNodeConnectionObservableChain & {
      execute: (
        request: HistoricalPurchaseNodeConnectionRequest,
        defaultValue?: HistoricalPurchaseNodeConnection,
      ) => Observable<HistoricalPurchaseNodeConnection>
    })
  buyorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BuyOrderNodeConnectionObservableChain & {
    execute: (
      request: BuyOrderNodeConnectionRequest,
      defaultValue?: BuyOrderNodeConnection,
    ) => Observable<BuyOrderNodeConnection>
  }) &
    (BuyOrderNodeConnectionObservableChain & {
      execute: (
        request: BuyOrderNodeConnectionRequest,
        defaultValue?: BuyOrderNodeConnection,
      ) => Observable<BuyOrderNodeConnection>
    })
  sellorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SellOrderNodeConnectionObservableChain & {
    execute: (
      request: SellOrderNodeConnectionRequest,
      defaultValue?: SellOrderNodeConnection,
    ) => Observable<SellOrderNodeConnection>
  }) &
    (SellOrderNodeConnectionObservableChain & {
      execute: (
        request: SellOrderNodeConnectionRequest,
        defaultValue?: SellOrderNodeConnection,
      ) => Observable<SellOrderNodeConnection>
    })
  gradedcardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    grader?: String | null
    minItems?: Decimal | null
    minListings?: Decimal | null
    minOffers?: Decimal | null
    myListingsStatus?: String | null
  }) => GradedCardParallelNodeConnectionObservableChain & {
    execute: (
      request: GradedCardParallelNodeConnectionRequest,
      defaultValue?: GradedCardParallelNodeConnection,
    ) => Observable<GradedCardParallelNodeConnection>
  }) &
    (GradedCardParallelNodeConnectionObservableChain & {
      execute: (
        request: GradedCardParallelNodeConnectionRequest,
        defaultValue?: GradedCardParallelNodeConnection,
      ) => Observable<GradedCardParallelNodeConnection>
    })
  cardparallelsearch: CardParallelSearchResultObservableChain & {
    execute: (
      request: CardParallelSearchResultRequest,
      defaultValue?: CardParallelSearchResult | null,
    ) => Observable<CardParallelSearchResult | null>
  }
  sportId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  sellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  sellOrderMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  buyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  buyOrderMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  lastListedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  lastSaleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  firstAddedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  openBuyOrders: {
    execute: (
      request: BuyOrderNodeRequest,
      defaultValue?: (BuyOrderNode | null)[] | null,
    ) => Observable<(BuyOrderNode | null)[] | null>
  }
  openSellOrders: {
    execute: (
      request: SellOrderNodeRequest,
      defaultValue?: (SellOrderNode | null)[] | null,
    ) => Observable<(SellOrderNode | null)[] | null>
  }
  myGroupedBids: {
    execute: (
      request: GroupedBidNodeRequest,
      defaultValue?: (GroupedBidNode | null)[] | null,
    ) => Observable<(GroupedBidNode | null)[] | null>
  }
  myGroupedListings: {
    execute: (
      request: GroupedListingNodeRequest,
      defaultValue?: (GroupedListingNode | null)[] | null,
    ) => Observable<(GroupedListingNode | null)[] | null>
  }
  myItems: {
    execute: (request: ItemNodeRequest, defaultValue?: (ItemNode | null)[] | null) => Observable<(ItemNode | null)[] | null>
  }
  lastSalePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  recentPurchases: {
    execute: (
      request: HistoricalPurchaseNodeRequest,
      defaultValue?: (HistoricalPurchaseNode | null)[] | null,
    ) => Observable<(HistoricalPurchaseNode | null)[] | null>
  }
  myWishlistItems: {
    execute: (
      request: WishlistItemNodeRequest,
      defaultValue?: (WishlistItemNode | null)[] | null,
    ) => Observable<(WishlistItemNode | null)[] | null>
  }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  myItemsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  myValuedItemsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  myItemsValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  myItemsMaxValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  myListingsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  myListingsMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  myItemOwnershipsMinStartedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  myItemOwnershipsMaxStartedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  frontSidePhotoThumbnail: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  frontSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  frontSidePhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  reverseSidePhotoThumbnail: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  reverseSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  reverseSidePhotoMedium: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  boxProductPhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  boxProductPhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  salesLast24Hours: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

export interface SportNodePromiseChain {
  /** Examples: FOOTBALL, BASKETBALL, ICE_HOCKEY */
  key: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Examples: Football, Basketball, Ice Hockey */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  active: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** Should the sport be stand-alone or should it be grouped with other secondary sports */
  primary: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  teamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => TeamNodeConnectionPromiseChain & {
    execute: (request: TeamNodeConnectionRequest, defaultValue?: TeamNodeConnection) => Promise<TeamNodeConnection>
  }) &
    (TeamNodeConnectionPromiseChain & {
      execute: (request: TeamNodeConnectionRequest, defaultValue?: TeamNodeConnection) => Promise<TeamNodeConnection>
    })
  cardprogramSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardProgramNodeConnectionPromiseChain & {
    execute: (
      request: CardProgramNodeConnectionRequest,
      defaultValue?: CardProgramNodeConnection,
    ) => Promise<CardProgramNodeConnection>
  }) &
    (CardProgramNodeConnectionPromiseChain & {
      execute: (
        request: CardProgramNodeConnectionRequest,
        defaultValue?: CardProgramNodeConnection,
      ) => Promise<CardProgramNodeConnection>
    })
  cardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionPromiseChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection,
    ) => Promise<CardParallelNodeConnection>
  }) &
    (CardParallelNodeConnectionPromiseChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection,
      ) => Promise<CardParallelNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
}

export interface SportNodeObservableChain {
  /** Examples: FOOTBALL, BASKETBALL, ICE_HOCKEY */
  key: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Examples: Football, Basketball, Ice Hockey */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  active: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** Should the sport be stand-alone or should it be grouped with other secondary sports */
  primary: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  teamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => TeamNodeConnectionObservableChain & {
    execute: (request: TeamNodeConnectionRequest, defaultValue?: TeamNodeConnection) => Observable<TeamNodeConnection>
  }) &
    (TeamNodeConnectionObservableChain & {
      execute: (request: TeamNodeConnectionRequest, defaultValue?: TeamNodeConnection) => Observable<TeamNodeConnection>
    })
  cardprogramSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardProgramNodeConnectionObservableChain & {
    execute: (
      request: CardProgramNodeConnectionRequest,
      defaultValue?: CardProgramNodeConnection,
    ) => Observable<CardProgramNodeConnection>
  }) &
    (CardProgramNodeConnectionObservableChain & {
      execute: (
        request: CardProgramNodeConnectionRequest,
        defaultValue?: CardProgramNodeConnection,
      ) => Observable<CardProgramNodeConnection>
    })
  cardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionObservableChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection,
    ) => Observable<CardParallelNodeConnection>
  }) &
    (CardParallelNodeConnectionObservableChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection,
      ) => Observable<CardParallelNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
}

export interface TeamNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: TeamNodeEdgeRequest, defaultValue?: (TeamNodeEdge | null)[]) => Promise<(TeamNodeEdge | null)[]>
  }
}

export interface TeamNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: TeamNodeEdgeRequest, defaultValue?: (TeamNodeEdge | null)[]) => Observable<(TeamNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `TeamNode` and its cursor. */
export interface TeamNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: TeamNodePromiseChain & {
    execute: (request: TeamNodeRequest, defaultValue?: TeamNode | null) => Promise<TeamNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `TeamNode` and its cursor. */
export interface TeamNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: TeamNodeObservableChain & {
    execute: (request: TeamNodeRequest, defaultValue?: TeamNode | null) => Observable<TeamNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface TeamNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Examples: Chicago Bulls, LSU Tigers, Oakland Athletics */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Abbreviated name used to represent this team in shorter contexts. Examples: Chi, OKC, Sco */
  abbrName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** City, area or country this team represents. Examples: Chicago, Oakland, Scotland */
  locationName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Standalone franchise or brand name. Examples: Bulls, Tigers, Athletics */
  franchiseName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  colorPrimary: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  colorSecondary: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  colorTertiary: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface TeamNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Examples: Chicago Bulls, LSU Tigers, Oakland Athletics */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Abbreviated name used to represent this team in shorter contexts. Examples: Chi, OKC, Sco */
  abbrName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** City, area or country this team represents. Examples: Chicago, Oakland, Scotland */
  locationName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Standalone franchise or brand name. Examples: Bulls, Tigers, Athletics */
  franchiseName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  colorPrimary: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  colorSecondary: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  colorTertiary: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CardProgramNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardProgramNodeEdgeRequest,
      defaultValue?: (CardProgramNodeEdge | null)[],
    ) => Promise<(CardProgramNodeEdge | null)[]>
  }
}

export interface CardProgramNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardProgramNodeEdgeRequest,
      defaultValue?: (CardProgramNodeEdge | null)[],
    ) => Observable<(CardProgramNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `CardProgramNode` and its cursor. */
export interface CardProgramNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: CardProgramNodePromiseChain & {
    execute: (request: CardProgramNodeRequest, defaultValue?: CardProgramNode | null) => Promise<CardProgramNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `CardProgramNode` and its cursor. */
export interface CardProgramNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: CardProgramNodeObservableChain & {
    execute: (request: CardProgramNodeRequest, defaultValue?: CardProgramNode | null) => Observable<CardProgramNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CardProgramNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  sport: SportNodePromiseChain & { execute: (request: SportNodeRequest, defaultValue?: SportNode) => Promise<SportNode> }
  /** If the program goes across multiple years, use the earliest year */
  year: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** Examples: 2014-15 Panini Select Basketball; 2019 Topps Chrome Baseball; 2019 Panini Donruss Football */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  cardSet: CardSetNodePromiseChain & {
    execute: (request: CardSetNodeRequest, defaultValue?: CardSetNode) => Promise<CardSetNode>
  }
  cardfamilySet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardFamilyNodeConnectionPromiseChain & {
    execute: (
      request: CardFamilyNodeConnectionRequest,
      defaultValue?: CardFamilyNodeConnection,
    ) => Promise<CardFamilyNodeConnection>
  }) &
    (CardFamilyNodeConnectionPromiseChain & {
      execute: (
        request: CardFamilyNodeConnectionRequest,
        defaultValue?: CardFamilyNodeConnection,
      ) => Promise<CardFamilyNodeConnection>
    })
}

export interface CardProgramNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  sport: SportNodeObservableChain & {
    execute: (request: SportNodeRequest, defaultValue?: SportNode) => Observable<SportNode>
  }
  /** If the program goes across multiple years, use the earliest year */
  year: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** Examples: 2014-15 Panini Select Basketball; 2019 Topps Chrome Baseball; 2019 Panini Donruss Football */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  cardSet: CardSetNodeObservableChain & {
    execute: (request: CardSetNodeRequest, defaultValue?: CardSetNode) => Observable<CardSetNode>
  }
  cardfamilySet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardFamilyNodeConnectionObservableChain & {
    execute: (
      request: CardFamilyNodeConnectionRequest,
      defaultValue?: CardFamilyNodeConnection,
    ) => Observable<CardFamilyNodeConnection>
  }) &
    (CardFamilyNodeConnectionObservableChain & {
      execute: (
        request: CardFamilyNodeConnectionRequest,
        defaultValue?: CardFamilyNodeConnection,
      ) => Observable<CardFamilyNodeConnection>
    })
}

export interface CardSetNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Examples: Panini Prizm; Bowman Draft; Topps Chrome; Topps */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  primary: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  cardprogramSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardProgramNodeConnectionPromiseChain & {
    execute: (
      request: CardProgramNodeConnectionRequest,
      defaultValue?: CardProgramNodeConnection,
    ) => Promise<CardProgramNodeConnection>
  }) &
    (CardProgramNodeConnectionPromiseChain & {
      execute: (
        request: CardProgramNodeConnectionRequest,
        defaultValue?: CardProgramNodeConnection,
      ) => Promise<CardProgramNodeConnection>
    })
  cardsetparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardSetParallelNodeConnectionPromiseChain & {
    execute: (
      request: CardSetParallelNodeConnectionRequest,
      defaultValue?: CardSetParallelNodeConnection,
    ) => Promise<CardSetParallelNodeConnection>
  }) &
    (CardSetParallelNodeConnectionPromiseChain & {
      execute: (
        request: CardSetParallelNodeConnectionRequest,
        defaultValue?: CardSetParallelNodeConnection,
      ) => Promise<CardSetParallelNodeConnection>
    })
}

export interface CardSetNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Examples: Panini Prizm; Bowman Draft; Topps Chrome; Topps */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  primary: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  cardprogramSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardProgramNodeConnectionObservableChain & {
    execute: (
      request: CardProgramNodeConnectionRequest,
      defaultValue?: CardProgramNodeConnection,
    ) => Observable<CardProgramNodeConnection>
  }) &
    (CardProgramNodeConnectionObservableChain & {
      execute: (
        request: CardProgramNodeConnectionRequest,
        defaultValue?: CardProgramNodeConnection,
      ) => Observable<CardProgramNodeConnection>
    })
  cardsetparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardSetParallelNodeConnectionObservableChain & {
    execute: (
      request: CardSetParallelNodeConnectionRequest,
      defaultValue?: CardSetParallelNodeConnection,
    ) => Observable<CardSetParallelNodeConnection>
  }) &
    (CardSetParallelNodeConnectionObservableChain & {
      execute: (
        request: CardSetParallelNodeConnectionRequest,
        defaultValue?: CardSetParallelNodeConnection,
      ) => Observable<CardSetParallelNodeConnection>
    })
}

export interface CardSetParallelNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardSetParallelNodeEdgeRequest,
      defaultValue?: (CardSetParallelNodeEdge | null)[],
    ) => Promise<(CardSetParallelNodeEdge | null)[]>
  }
}

export interface CardSetParallelNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardSetParallelNodeEdgeRequest,
      defaultValue?: (CardSetParallelNodeEdge | null)[],
    ) => Observable<(CardSetParallelNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `CardSetParallelNode` and its cursor. */
export interface CardSetParallelNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: CardSetParallelNodePromiseChain & {
    execute: (
      request: CardSetParallelNodeRequest,
      defaultValue?: CardSetParallelNode | null,
    ) => Promise<CardSetParallelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `CardSetParallelNode` and its cursor. */
export interface CardSetParallelNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: CardSetParallelNodeObservableChain & {
    execute: (
      request: CardSetParallelNodeRequest,
      defaultValue?: CardSetParallelNode | null,
    ) => Observable<CardSetParallelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CardSetParallelNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  cardSet: CardSetNodePromiseChain & {
    execute: (request: CardSetNodeRequest, defaultValue?: CardSetNode) => Promise<CardSetNode>
  }
  /** Examples: Base, Orange Refractor, Silver */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  badgeTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  badgeBorderColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  badgeGradientStart: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  badgeGradientEnd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  badgeGradientAngle: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  baseSet: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  cardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionPromiseChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection,
    ) => Promise<CardParallelNodeConnection>
  }) &
    (CardParallelNodeConnectionPromiseChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection,
      ) => Promise<CardParallelNodeConnection>
    })
  cardparallelsearchSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
    era?: String | null
    query?: String | null
    productType?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelSearchResultConnectionPromiseChain & {
    execute: (
      request: CardParallelSearchResultConnectionRequest,
      defaultValue?: CardParallelSearchResultConnection,
    ) => Promise<CardParallelSearchResultConnection>
  }) &
    (CardParallelSearchResultConnectionPromiseChain & {
      execute: (
        request: CardParallelSearchResultConnectionRequest,
        defaultValue?: CardParallelSearchResultConnection,
      ) => Promise<CardParallelSearchResultConnection>
    })
}

export interface CardSetParallelNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  cardSet: CardSetNodeObservableChain & {
    execute: (request: CardSetNodeRequest, defaultValue?: CardSetNode) => Observable<CardSetNode>
  }
  /** Examples: Base, Orange Refractor, Silver */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  badgeTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  badgeBorderColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  badgeGradientStart: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  badgeGradientEnd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  badgeGradientAngle: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  baseSet: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  cardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionObservableChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection,
    ) => Observable<CardParallelNodeConnection>
  }) &
    (CardParallelNodeConnectionObservableChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection,
      ) => Observable<CardParallelNodeConnection>
    })
  cardparallelsearchSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
    era?: String | null
    query?: String | null
    productType?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelSearchResultConnectionObservableChain & {
    execute: (
      request: CardParallelSearchResultConnectionRequest,
      defaultValue?: CardParallelSearchResultConnection,
    ) => Observable<CardParallelSearchResultConnection>
  }) &
    (CardParallelSearchResultConnectionObservableChain & {
      execute: (
        request: CardParallelSearchResultConnectionRequest,
        defaultValue?: CardParallelSearchResultConnection,
      ) => Observable<CardParallelSearchResultConnection>
    })
}

export interface CardParallelNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardParallelNodeEdgeRequest,
      defaultValue?: (CardParallelNodeEdge | null)[],
    ) => Promise<(CardParallelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  cardParallelStats: CardParallelStatsNodePromiseChain & {
    execute: (
      request: CardParallelStatsNodeRequest,
      defaultValue?: CardParallelStatsNode | null,
    ) => Promise<CardParallelStatsNode | null>
  }
  myCardParallelStats: CardParallelStatsNodePromiseChain & {
    execute: (
      request: CardParallelStatsNodeRequest,
      defaultValue?: CardParallelStatsNode | null,
    ) => Promise<CardParallelStatsNode | null>
  }
}

export interface CardParallelNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardParallelNodeEdgeRequest,
      defaultValue?: (CardParallelNodeEdge | null)[],
    ) => Observable<(CardParallelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  cardParallelStats: CardParallelStatsNodeObservableChain & {
    execute: (
      request: CardParallelStatsNodeRequest,
      defaultValue?: CardParallelStatsNode | null,
    ) => Observable<CardParallelStatsNode | null>
  }
  myCardParallelStats: CardParallelStatsNodeObservableChain & {
    execute: (
      request: CardParallelStatsNodeRequest,
      defaultValue?: CardParallelStatsNode | null,
    ) => Observable<CardParallelStatsNode | null>
  }
}

/** A Relay edge containing a `CardParallelNode` and its cursor. */
export interface CardParallelNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode | null) => Promise<CardParallelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `CardParallelNode` and its cursor. */
export interface CardParallelNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: CardParallelNodeObservableChain & {
    execute: (
      request: CardParallelNodeRequest,
      defaultValue?: CardParallelNode | null,
    ) => Observable<CardParallelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CardParallelStatsNodePromiseChain {
  totalItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  totalListingCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  totalValuedItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  totalItemValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface CardParallelStatsNodeObservableChain {
  totalItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  totalListingCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  totalValuedItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  totalItemValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface CardParallelSearchResultConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardParallelSearchResultEdgeRequest,
      defaultValue?: (CardParallelSearchResultEdge | null)[],
    ) => Promise<(CardParallelSearchResultEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  cardParallelStats: CardParallelStatsNodePromiseChain & {
    execute: (
      request: CardParallelStatsNodeRequest,
      defaultValue?: CardParallelStatsNode | null,
    ) => Promise<CardParallelStatsNode | null>
  }
}

export interface CardParallelSearchResultConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardParallelSearchResultEdgeRequest,
      defaultValue?: (CardParallelSearchResultEdge | null)[],
    ) => Observable<(CardParallelSearchResultEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  cardParallelStats: CardParallelStatsNodeObservableChain & {
    execute: (
      request: CardParallelStatsNodeRequest,
      defaultValue?: CardParallelStatsNode | null,
    ) => Observable<CardParallelStatsNode | null>
  }
}

/** A Relay edge containing a `CardParallelSearchResult` and its cursor. */
export interface CardParallelSearchResultEdgePromiseChain {
  /** The item at the end of the edge */
  node: CardParallelSearchResultPromiseChain & {
    execute: (
      request: CardParallelSearchResultRequest,
      defaultValue?: CardParallelSearchResult | null,
    ) => Promise<CardParallelSearchResult | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `CardParallelSearchResult` and its cursor. */
export interface CardParallelSearchResultEdgeObservableChain {
  /** The item at the end of the edge */
  node: CardParallelSearchResultObservableChain & {
    execute: (
      request: CardParallelSearchResultRequest,
      defaultValue?: CardParallelSearchResult | null,
    ) => Observable<CardParallelSearchResult | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CardParallelSearchResultPromiseChain {
  cardParallel: CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode) => Promise<CardParallelNode>
  }
  playerCard: PlayerCardNodePromiseChain & {
    execute: (request: PlayerCardNodeRequest, defaultValue?: PlayerCardNode | null) => Promise<PlayerCardNode | null>
  }
  parallel: CardSetParallelNodePromiseChain & {
    execute: (
      request: CardSetParallelNodeRequest,
      defaultValue?: CardSetParallelNode | null,
    ) => Promise<CardSetParallelNode | null>
  }
  sport: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  era: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  productType: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  slug: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  serialLimit: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  trendingRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  sellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  sellOrderMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  buyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  buyOrderMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  firstAddedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  lastListedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  lastSaleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  salesLast24Hours: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  salesLast7Days: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  frontSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  frontSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  frontSidePhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  frontSidePhotoThumbnail: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhotoThumbnail: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null>
  }
  boxProductPhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  boxProductPhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  boxProductPhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  gradedcardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => GradedCardParallelNodeConnectionPromiseChain & {
    execute: (
      request: GradedCardParallelNodeConnectionRequest,
      defaultValue?: GradedCardParallelNodeConnection | null,
    ) => Promise<GradedCardParallelNodeConnection | null>
  }) &
    (GradedCardParallelNodeConnectionPromiseChain & {
      execute: (
        request: GradedCardParallelNodeConnectionRequest,
        defaultValue?: GradedCardParallelNodeConnection | null,
      ) => Promise<GradedCardParallelNodeConnection | null>
    })
}

export interface CardParallelSearchResultObservableChain {
  cardParallel: CardParallelNodeObservableChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode) => Observable<CardParallelNode>
  }
  playerCard: PlayerCardNodeObservableChain & {
    execute: (request: PlayerCardNodeRequest, defaultValue?: PlayerCardNode | null) => Observable<PlayerCardNode | null>
  }
  parallel: CardSetParallelNodeObservableChain & {
    execute: (
      request: CardSetParallelNodeRequest,
      defaultValue?: CardSetParallelNode | null,
    ) => Observable<CardSetParallelNode | null>
  }
  sport: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  era: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  productType: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  slug: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  serialLimit: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  trendingRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  sellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  sellOrderMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  buyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  buyOrderMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  firstAddedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  lastListedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  lastSaleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  salesLast24Hours: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  salesLast7Days: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  frontSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  frontSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  frontSidePhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  frontSidePhotoThumbnail: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  reverseSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  reverseSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  reverseSidePhotoMedium: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  reverseSidePhotoThumbnail: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  boxProductPhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  boxProductPhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  boxProductPhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  gradedcardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => GradedCardParallelNodeConnectionObservableChain & {
    execute: (
      request: GradedCardParallelNodeConnectionRequest,
      defaultValue?: GradedCardParallelNodeConnection | null,
    ) => Observable<GradedCardParallelNodeConnection | null>
  }) &
    (GradedCardParallelNodeConnectionObservableChain & {
      execute: (
        request: GradedCardParallelNodeConnectionRequest,
        defaultValue?: GradedCardParallelNodeConnection | null,
      ) => Observable<GradedCardParallelNodeConnection | null>
    })
}

export interface PlayerCardNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  setNumber: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  teamPlayers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => TeamPlayerNodeConnectionPromiseChain & {
    execute: (
      request: TeamPlayerNodeConnectionRequest,
      defaultValue?: TeamPlayerNodeConnection,
    ) => Promise<TeamPlayerNodeConnection>
  }) &
    (TeamPlayerNodeConnectionPromiseChain & {
      execute: (
        request: TeamPlayerNodeConnectionRequest,
        defaultValue?: TeamPlayerNodeConnection,
      ) => Promise<TeamPlayerNodeConnection>
    })
  cardFamily: CardFamilyNodePromiseChain & {
    execute: (request: CardFamilyNodeRequest, defaultValue?: CardFamilyNode) => Promise<CardFamilyNode>
  }
  rookieCard: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  cardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionPromiseChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection,
    ) => Promise<CardParallelNodeConnection>
  }) &
    (CardParallelNodeConnectionPromiseChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection,
      ) => Promise<CardParallelNodeConnection>
    })
  cardparallelsearchSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
    era?: String | null
    query?: String | null
    productType?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelSearchResultConnectionPromiseChain & {
    execute: (
      request: CardParallelSearchResultConnectionRequest,
      defaultValue?: CardParallelSearchResultConnection,
    ) => Promise<CardParallelSearchResultConnection>
  }) &
    (CardParallelSearchResultConnectionPromiseChain & {
      execute: (
        request: CardParallelSearchResultConnectionRequest,
        defaultValue?: CardParallelSearchResultConnection,
      ) => Promise<CardParallelSearchResultConnection>
    })
  cardParallels: {
    execute: (
      request: CardParallelNodeRequest,
      defaultValue?: (CardParallelNode | null)[] | null,
    ) => Promise<(CardParallelNode | null)[] | null>
  }
}

export interface PlayerCardNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  setNumber: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  teamPlayers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => TeamPlayerNodeConnectionObservableChain & {
    execute: (
      request: TeamPlayerNodeConnectionRequest,
      defaultValue?: TeamPlayerNodeConnection,
    ) => Observable<TeamPlayerNodeConnection>
  }) &
    (TeamPlayerNodeConnectionObservableChain & {
      execute: (
        request: TeamPlayerNodeConnectionRequest,
        defaultValue?: TeamPlayerNodeConnection,
      ) => Observable<TeamPlayerNodeConnection>
    })
  cardFamily: CardFamilyNodeObservableChain & {
    execute: (request: CardFamilyNodeRequest, defaultValue?: CardFamilyNode) => Observable<CardFamilyNode>
  }
  rookieCard: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  cardparallelSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    featuredResultSet?: String | null
    searchQuery?: String | null
    sport?: String | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    cardSet?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    grader?: String | null
    myListingsStatus?: String | null
    playerCard?: ID | null
    productType?: String | null
    era?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelNodeConnectionObservableChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection,
    ) => Observable<CardParallelNodeConnection>
  }) &
    (CardParallelNodeConnectionObservableChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection,
      ) => Observable<CardParallelNodeConnection>
    })
  cardparallelsearchSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
    era?: String | null
    query?: String | null
    productType?: String | null
    minItems?: Decimal | null
    maxItems?: Decimal | null
    minListings?: Decimal | null
    maxListings?: Decimal | null
    minOffers?: Decimal | null
    maxOffers?: Decimal | null
    minSerialLimit?: Decimal | null
    maxSerialLimit?: Decimal | null
    startYear?: Decimal | null
    endYear?: Decimal | null
    /** Ordering */
    orderBy?: String | null
  }) => CardParallelSearchResultConnectionObservableChain & {
    execute: (
      request: CardParallelSearchResultConnectionRequest,
      defaultValue?: CardParallelSearchResultConnection,
    ) => Observable<CardParallelSearchResultConnection>
  }) &
    (CardParallelSearchResultConnectionObservableChain & {
      execute: (
        request: CardParallelSearchResultConnectionRequest,
        defaultValue?: CardParallelSearchResultConnection,
      ) => Observable<CardParallelSearchResultConnection>
    })
  cardParallels: {
    execute: (
      request: CardParallelNodeRequest,
      defaultValue?: (CardParallelNode | null)[] | null,
    ) => Observable<(CardParallelNode | null)[] | null>
  }
}

export interface TeamPlayerNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: TeamPlayerNodeEdgeRequest,
      defaultValue?: (TeamPlayerNodeEdge | null)[],
    ) => Promise<(TeamPlayerNodeEdge | null)[]>
  }
}

export interface TeamPlayerNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: TeamPlayerNodeEdgeRequest,
      defaultValue?: (TeamPlayerNodeEdge | null)[],
    ) => Observable<(TeamPlayerNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `TeamPlayerNode` and its cursor. */
export interface TeamPlayerNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: TeamPlayerNodePromiseChain & {
    execute: (request: TeamPlayerNodeRequest, defaultValue?: TeamPlayerNode | null) => Promise<TeamPlayerNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `TeamPlayerNode` and its cursor. */
export interface TeamPlayerNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: TeamPlayerNodeObservableChain & {
    execute: (request: TeamPlayerNodeRequest, defaultValue?: TeamPlayerNode | null) => Observable<TeamPlayerNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface TeamPlayerNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  player: PlayerNodePromiseChain & {
    execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode) => Promise<PlayerNode>
  }
  team: TeamNodePromiseChain & { execute: (request: TeamNodeRequest, defaultValue?: TeamNode) => Promise<TeamNode> }
  playercardSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PlayerCardNodeConnectionPromiseChain & {
    execute: (
      request: PlayerCardNodeConnectionRequest,
      defaultValue?: PlayerCardNodeConnection,
    ) => Promise<PlayerCardNodeConnection>
  }) &
    (PlayerCardNodeConnectionPromiseChain & {
      execute: (
        request: PlayerCardNodeConnectionRequest,
        defaultValue?: PlayerCardNodeConnection,
      ) => Promise<PlayerCardNodeConnection>
    })
}

export interface TeamPlayerNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  player: PlayerNodeObservableChain & {
    execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode) => Observable<PlayerNode>
  }
  team: TeamNodeObservableChain & { execute: (request: TeamNodeRequest, defaultValue?: TeamNode) => Observable<TeamNode> }
  playercardSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PlayerCardNodeConnectionObservableChain & {
    execute: (
      request: PlayerCardNodeConnectionRequest,
      defaultValue?: PlayerCardNodeConnection,
    ) => Observable<PlayerCardNodeConnection>
  }) &
    (PlayerCardNodeConnectionObservableChain & {
      execute: (
        request: PlayerCardNodeConnectionRequest,
        defaultValue?: PlayerCardNodeConnection,
      ) => Observable<PlayerCardNodeConnection>
    })
}

export interface PlayerNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Examples: Teddy Bridgewater, Marc Gasol */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  startYear: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** Examples: WR, C-F, OF */
  position: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  currentTeam: TeamNodePromiseChain & {
    execute: (request: TeamNodeRequest, defaultValue?: TeamNode | null) => Promise<TeamNode | null>
  }
  headshotPhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  headshotPhotoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  headshotPhotoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  headshotPhotoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  tradeStats: PlayerTradeStatsNodePromiseChain & {
    execute: (
      request: PlayerTradeStatsNodeRequest,
      defaultValue?: PlayerTradeStatsNode | null,
    ) => Promise<PlayerTradeStatsNode | null>
  }
  cardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardParallelNodeConnectionPromiseChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Promise<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionPromiseChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Promise<CardParallelNodeConnection | null>
    })
  myCardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardParallelNodeConnectionPromiseChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Promise<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionPromiseChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Promise<CardParallelNodeConnection | null>
    })
  fixtureTeamPlayers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => FixtureTeamPlayerNodeConnectionPromiseChain & {
    execute: (
      request: FixtureTeamPlayerNodeConnectionRequest,
      defaultValue?: FixtureTeamPlayerNodeConnection | null,
    ) => Promise<FixtureTeamPlayerNodeConnection | null>
  }) &
    (FixtureTeamPlayerNodeConnectionPromiseChain & {
      execute: (
        request: FixtureTeamPlayerNodeConnectionRequest,
        defaultValue?: FixtureTeamPlayerNodeConnection | null,
      ) => Promise<FixtureTeamPlayerNodeConnection | null>
    })
  seasonStats: {
    execute: (
      request: PlayerStatNodeRequest,
      defaultValue?: (PlayerStatNode | null)[] | null,
    ) => Promise<(PlayerStatNode | null)[] | null>
  }
}

export interface PlayerNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Examples: Teddy Bridgewater, Marc Gasol */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  startYear: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** Examples: WR, C-F, OF */
  position: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  currentTeam: TeamNodeObservableChain & {
    execute: (request: TeamNodeRequest, defaultValue?: TeamNode | null) => Observable<TeamNode | null>
  }
  headshotPhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  headshotPhotoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  headshotPhotoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  headshotPhotoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  tradeStats: PlayerTradeStatsNodeObservableChain & {
    execute: (
      request: PlayerTradeStatsNodeRequest,
      defaultValue?: PlayerTradeStatsNode | null,
    ) => Observable<PlayerTradeStatsNode | null>
  }
  cardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardParallelNodeConnectionObservableChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Observable<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionObservableChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Observable<CardParallelNodeConnection | null>
    })
  myCardParallels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => CardParallelNodeConnectionObservableChain & {
    execute: (
      request: CardParallelNodeConnectionRequest,
      defaultValue?: CardParallelNodeConnection | null,
    ) => Observable<CardParallelNodeConnection | null>
  }) &
    (CardParallelNodeConnectionObservableChain & {
      execute: (
        request: CardParallelNodeConnectionRequest,
        defaultValue?: CardParallelNodeConnection | null,
      ) => Observable<CardParallelNodeConnection | null>
    })
  fixtureTeamPlayers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => FixtureTeamPlayerNodeConnectionObservableChain & {
    execute: (
      request: FixtureTeamPlayerNodeConnectionRequest,
      defaultValue?: FixtureTeamPlayerNodeConnection | null,
    ) => Observable<FixtureTeamPlayerNodeConnection | null>
  }) &
    (FixtureTeamPlayerNodeConnectionObservableChain & {
      execute: (
        request: FixtureTeamPlayerNodeConnectionRequest,
        defaultValue?: FixtureTeamPlayerNodeConnection | null,
      ) => Observable<FixtureTeamPlayerNodeConnection | null>
    })
  seasonStats: {
    execute: (
      request: PlayerStatNodeRequest,
      defaultValue?: (PlayerStatNode | null)[] | null,
    ) => Observable<(PlayerStatNode | null)[] | null>
  }
}

export interface PlayerTradeStatsNodePromiseChain {
  tradeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  minTradePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  maxTradePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface PlayerTradeStatsNodeObservableChain {
  tradeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  minTradePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  maxTradePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface FixtureTeamPlayerNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: FixtureTeamPlayerNodeEdgeRequest,
      defaultValue?: (FixtureTeamPlayerNodeEdge | null)[],
    ) => Promise<(FixtureTeamPlayerNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface FixtureTeamPlayerNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: FixtureTeamPlayerNodeEdgeRequest,
      defaultValue?: (FixtureTeamPlayerNodeEdge | null)[],
    ) => Observable<(FixtureTeamPlayerNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `FixtureTeamPlayerNode` and its cursor. */
export interface FixtureTeamPlayerNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: FixtureTeamPlayerNodePromiseChain & {
    execute: (
      request: FixtureTeamPlayerNodeRequest,
      defaultValue?: FixtureTeamPlayerNode | null,
    ) => Promise<FixtureTeamPlayerNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `FixtureTeamPlayerNode` and its cursor. */
export interface FixtureTeamPlayerNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: FixtureTeamPlayerNodeObservableChain & {
    execute: (
      request: FixtureTeamPlayerNodeRequest,
      defaultValue?: FixtureTeamPlayerNode | null,
    ) => Observable<FixtureTeamPlayerNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface FixtureTeamPlayerNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  fixture: FixtureNodePromiseChain & {
    execute: (request: FixtureNodeRequest, defaultValue?: FixtureNode) => Promise<FixtureNode>
  }
  teamPlayer: TeamPlayerNodePromiseChain & {
    execute: (request: TeamPlayerNodeRequest, defaultValue?: TeamPlayerNode) => Promise<TeamPlayerNode>
  }
  trendingRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  fixtureStats: {
    execute: (
      request: PlayerStatNodeRequest,
      defaultValue?: (PlayerStatNode | null)[] | null,
    ) => Promise<(PlayerStatNode | null)[] | null>
  }
}

export interface FixtureTeamPlayerNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  fixture: FixtureNodeObservableChain & {
    execute: (request: FixtureNodeRequest, defaultValue?: FixtureNode) => Observable<FixtureNode>
  }
  teamPlayer: TeamPlayerNodeObservableChain & {
    execute: (request: TeamPlayerNodeRequest, defaultValue?: TeamPlayerNode) => Observable<TeamPlayerNode>
  }
  trendingRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  fixtureStats: {
    execute: (
      request: PlayerStatNodeRequest,
      defaultValue?: (PlayerStatNode | null)[] | null,
    ) => Observable<(PlayerStatNode | null)[] | null>
  }
}

export interface FixtureNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  sportRadarId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  status: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  startDate: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  homeTeam: TeamNodePromiseChain & { execute: (request: TeamNodeRequest, defaultValue?: TeamNode) => Promise<TeamNode> }
  awayTeam: TeamNodePromiseChain & { execute: (request: TeamNodeRequest, defaultValue?: TeamNode) => Promise<TeamNode> }
  statsImported: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  fixtureteamplayerSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => FixtureTeamPlayerNodeConnectionPromiseChain & {
    execute: (
      request: FixtureTeamPlayerNodeConnectionRequest,
      defaultValue?: FixtureTeamPlayerNodeConnection,
    ) => Promise<FixtureTeamPlayerNodeConnection>
  }) &
    (FixtureTeamPlayerNodeConnectionPromiseChain & {
      execute: (
        request: FixtureTeamPlayerNodeConnectionRequest,
        defaultValue?: FixtureTeamPlayerNodeConnection,
      ) => Promise<FixtureTeamPlayerNodeConnection>
    })
}

export interface FixtureNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  sportRadarId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  status: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  startDate: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  homeTeam: TeamNodeObservableChain & {
    execute: (request: TeamNodeRequest, defaultValue?: TeamNode) => Observable<TeamNode>
  }
  awayTeam: TeamNodeObservableChain & {
    execute: (request: TeamNodeRequest, defaultValue?: TeamNode) => Observable<TeamNode>
  }
  statsImported: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  fixtureteamplayerSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => FixtureTeamPlayerNodeConnectionObservableChain & {
    execute: (
      request: FixtureTeamPlayerNodeConnectionRequest,
      defaultValue?: FixtureTeamPlayerNodeConnection,
    ) => Observable<FixtureTeamPlayerNodeConnection>
  }) &
    (FixtureTeamPlayerNodeConnectionObservableChain & {
      execute: (
        request: FixtureTeamPlayerNodeConnectionRequest,
        defaultValue?: FixtureTeamPlayerNodeConnection,
      ) => Observable<FixtureTeamPlayerNodeConnection>
    })
}

export interface PlayerStatNodePromiseChain {
  statName: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  statValue: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface PlayerStatNodeObservableChain {
  statName: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  statValue: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface PlayerCardNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: PlayerCardNodeEdgeRequest,
      defaultValue?: (PlayerCardNodeEdge | null)[],
    ) => Promise<(PlayerCardNodeEdge | null)[]>
  }
}

export interface PlayerCardNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: PlayerCardNodeEdgeRequest,
      defaultValue?: (PlayerCardNodeEdge | null)[],
    ) => Observable<(PlayerCardNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `PlayerCardNode` and its cursor. */
export interface PlayerCardNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: PlayerCardNodePromiseChain & {
    execute: (request: PlayerCardNodeRequest, defaultValue?: PlayerCardNode | null) => Promise<PlayerCardNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `PlayerCardNode` and its cursor. */
export interface PlayerCardNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: PlayerCardNodeObservableChain & {
    execute: (request: PlayerCardNodeRequest, defaultValue?: PlayerCardNode | null) => Observable<PlayerCardNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CardFamilyNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Examples: Base, Paper Prospect Autographs, Rated Rookies */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  program: CardProgramNodePromiseChain & {
    execute: (request: CardProgramNodeRequest, defaultValue?: CardProgramNode) => Promise<CardProgramNode>
  }
  primary: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  playercardSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PlayerCardNodeConnectionPromiseChain & {
    execute: (
      request: PlayerCardNodeConnectionRequest,
      defaultValue?: PlayerCardNodeConnection,
    ) => Promise<PlayerCardNodeConnection>
  }) &
    (PlayerCardNodeConnectionPromiseChain & {
      execute: (
        request: PlayerCardNodeConnectionRequest,
        defaultValue?: PlayerCardNodeConnection,
      ) => Promise<PlayerCardNodeConnection>
    })
}

export interface CardFamilyNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Examples: Base, Paper Prospect Autographs, Rated Rookies */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  program: CardProgramNodeObservableChain & {
    execute: (request: CardProgramNodeRequest, defaultValue?: CardProgramNode) => Observable<CardProgramNode>
  }
  primary: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  playercardSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PlayerCardNodeConnectionObservableChain & {
    execute: (
      request: PlayerCardNodeConnectionRequest,
      defaultValue?: PlayerCardNodeConnection,
    ) => Observable<PlayerCardNodeConnection>
  }) &
    (PlayerCardNodeConnectionObservableChain & {
      execute: (
        request: PlayerCardNodeConnectionRequest,
        defaultValue?: PlayerCardNodeConnection,
      ) => Observable<PlayerCardNodeConnection>
    })
}

export interface GradedCardParallelNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: GradedCardParallelNodeEdgeRequest,
      defaultValue?: (GradedCardParallelNodeEdge | null)[],
    ) => Promise<(GradedCardParallelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface GradedCardParallelNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: GradedCardParallelNodeEdgeRequest,
      defaultValue?: (GradedCardParallelNodeEdge | null)[],
    ) => Observable<(GradedCardParallelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `GradedCardParallelNode` and its cursor. */
export interface GradedCardParallelNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Promise<GradedCardParallelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `GradedCardParallelNode` and its cursor. */
export interface GradedCardParallelNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Observable<GradedCardParallelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CardFamilyNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardFamilyNodeEdgeRequest,
      defaultValue?: (CardFamilyNodeEdge | null)[],
    ) => Promise<(CardFamilyNodeEdge | null)[]>
  }
}

export interface CardFamilyNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardFamilyNodeEdgeRequest,
      defaultValue?: (CardFamilyNodeEdge | null)[],
    ) => Observable<(CardFamilyNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `CardFamilyNode` and its cursor. */
export interface CardFamilyNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: CardFamilyNodePromiseChain & {
    execute: (request: CardFamilyNodeRequest, defaultValue?: CardFamilyNode | null) => Promise<CardFamilyNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `CardFamilyNode` and its cursor. */
export interface CardFamilyNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: CardFamilyNodeObservableChain & {
    execute: (request: CardFamilyNodeRequest, defaultValue?: CardFamilyNode | null) => Observable<CardFamilyNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface ItemNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: ItemNodeEdgeRequest, defaultValue?: (ItemNodeEdge | null)[]) => Promise<(ItemNodeEdge | null)[]>
  }
}

export interface ItemNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: ItemNodeEdgeRequest, defaultValue?: (ItemNodeEdge | null)[]) => Observable<(ItemNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `ItemNode` and its cursor. */
export interface ItemNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: ItemNodePromiseChain & {
    execute: (request: ItemNodeRequest, defaultValue?: ItemNode | null) => Promise<ItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `ItemNode` and its cursor. */
export interface ItemNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: ItemNodeObservableChain & {
    execute: (request: ItemNodeRequest, defaultValue?: ItemNode | null) => Observable<ItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface ItemNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  cardParallel: CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode) => Promise<CardParallelNode>
  }
  grade: GradeNodePromiseChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Promise<GradeNode | null>
  }
  gradedCardParallel: GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Promise<GradedCardParallelNode | null>
  }
  owner: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  vaultLocation: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  boxLocation: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  originalOwner: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  frontSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  createdBy: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  notes: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  cachedName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  softDeleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  softDeletedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  withdrawn: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  withdrawnAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  rejected: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  selfVaulted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  selfVaultedReceivedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null>
  }
  shippingitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => ShippingItemNodeConnectionPromiseChain & {
    execute: (
      request: ShippingItemNodeConnectionRequest,
      defaultValue?: ShippingItemNodeConnection,
    ) => Promise<ShippingItemNodeConnection>
  }) &
    (ShippingItemNodeConnectionPromiseChain & {
      execute: (
        request: ShippingItemNodeConnectionRequest,
        defaultValue?: ShippingItemNodeConnection,
      ) => Promise<ShippingItemNodeConnection>
    })
  buyorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BuyOrderNodeConnectionPromiseChain & {
    execute: (
      request: BuyOrderNodeConnectionRequest,
      defaultValue?: BuyOrderNodeConnection,
    ) => Promise<BuyOrderNodeConnection>
  }) &
    (BuyOrderNodeConnectionPromiseChain & {
      execute: (
        request: BuyOrderNodeConnectionRequest,
        defaultValue?: BuyOrderNodeConnection,
      ) => Promise<BuyOrderNodeConnection>
    })
  sellorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SellOrderNodeConnectionPromiseChain & {
    execute: (
      request: SellOrderNodeConnectionRequest,
      defaultValue?: SellOrderNodeConnection,
    ) => Promise<SellOrderNodeConnection>
  }) &
    (SellOrderNodeConnectionPromiseChain & {
      execute: (
        request: SellOrderNodeConnectionRequest,
        defaultValue?: SellOrderNodeConnection,
      ) => Promise<SellOrderNodeConnection>
    })
  /** The specific collection item this lot item represents, if applicable. May be left blank if the item is not yet input into the marketplace. */
  lotitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotItemNodeConnectionPromiseChain & {
    execute: (request: LotItemNodeConnectionRequest, defaultValue?: LotItemNodeConnection) => Promise<LotItemNodeConnection>
  }) &
    (LotItemNodeConnectionPromiseChain & {
      execute: (
        request: LotItemNodeConnectionRequest,
        defaultValue?: LotItemNodeConnection,
      ) => Promise<LotItemNodeConnection>
    })
  hit: HitNodePromiseChain & { execute: (request: HitNodeRequest, defaultValue?: HitNode | null) => Promise<HitNode | null> }
  openSellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  frontSidePhotoThumbnail: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  frontSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  frontSidePhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhotoThumbnail: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null>
  }
  reverseSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  reverseSidePhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface ItemNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  cardParallel: CardParallelNodeObservableChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode) => Observable<CardParallelNode>
  }
  grade: GradeNodeObservableChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Observable<GradeNode | null>
  }
  gradedCardParallel: GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Observable<GradedCardParallelNode | null>
  }
  owner: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  vaultLocation: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  boxLocation: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  originalOwner: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  frontSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  reverseSidePhoto: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  createdBy: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  notes: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  cachedName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  softDeleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  softDeletedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  withdrawn: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  withdrawnAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  rejected: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  selfVaulted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  selfVaultedReceivedAt: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  shippingitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => ShippingItemNodeConnectionObservableChain & {
    execute: (
      request: ShippingItemNodeConnectionRequest,
      defaultValue?: ShippingItemNodeConnection,
    ) => Observable<ShippingItemNodeConnection>
  }) &
    (ShippingItemNodeConnectionObservableChain & {
      execute: (
        request: ShippingItemNodeConnectionRequest,
        defaultValue?: ShippingItemNodeConnection,
      ) => Observable<ShippingItemNodeConnection>
    })
  buyorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BuyOrderNodeConnectionObservableChain & {
    execute: (
      request: BuyOrderNodeConnectionRequest,
      defaultValue?: BuyOrderNodeConnection,
    ) => Observable<BuyOrderNodeConnection>
  }) &
    (BuyOrderNodeConnectionObservableChain & {
      execute: (
        request: BuyOrderNodeConnectionRequest,
        defaultValue?: BuyOrderNodeConnection,
      ) => Observable<BuyOrderNodeConnection>
    })
  sellorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SellOrderNodeConnectionObservableChain & {
    execute: (
      request: SellOrderNodeConnectionRequest,
      defaultValue?: SellOrderNodeConnection,
    ) => Observable<SellOrderNodeConnection>
  }) &
    (SellOrderNodeConnectionObservableChain & {
      execute: (
        request: SellOrderNodeConnectionRequest,
        defaultValue?: SellOrderNodeConnection,
      ) => Observable<SellOrderNodeConnection>
    })
  /** The specific collection item this lot item represents, if applicable. May be left blank if the item is not yet input into the marketplace. */
  lotitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotItemNodeConnectionObservableChain & {
    execute: (
      request: LotItemNodeConnectionRequest,
      defaultValue?: LotItemNodeConnection,
    ) => Observable<LotItemNodeConnection>
  }) &
    (LotItemNodeConnectionObservableChain & {
      execute: (
        request: LotItemNodeConnectionRequest,
        defaultValue?: LotItemNodeConnection,
      ) => Observable<LotItemNodeConnection>
    })
  hit: HitNodeObservableChain & {
    execute: (request: HitNodeRequest, defaultValue?: HitNode | null) => Observable<HitNode | null>
  }
  openSellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  frontSidePhotoThumbnail: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  frontSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  frontSidePhotoMedium: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  reverseSidePhotoThumbnail: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  reverseSidePhotoSmall: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  reverseSidePhotoMedium: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
}

export interface GradeNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  description: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  grader: { execute: (request?: boolean | number, defaultValue?: GradeGrader) => Promise<GradeGrader> }
}

export interface GradeNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  description: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  grader: { execute: (request?: boolean | number, defaultValue?: GradeGrader) => Observable<GradeGrader> }
}

export interface ShippingItemNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ShippingItemNodeEdgeRequest,
      defaultValue?: (ShippingItemNodeEdge | null)[],
    ) => Promise<(ShippingItemNodeEdge | null)[]>
  }
}

export interface ShippingItemNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ShippingItemNodeEdgeRequest,
      defaultValue?: (ShippingItemNodeEdge | null)[],
    ) => Observable<(ShippingItemNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `ShippingItemNode` and its cursor. */
export interface ShippingItemNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: ShippingItemNodePromiseChain & {
    execute: (request: ShippingItemNodeRequest, defaultValue?: ShippingItemNode | null) => Promise<ShippingItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `ShippingItemNode` and its cursor. */
export interface ShippingItemNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: ShippingItemNodeObservableChain & {
    execute: (
      request: ShippingItemNodeRequest,
      defaultValue?: ShippingItemNode | null,
    ) => Observable<ShippingItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface ShippingItemNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  item: ItemNodePromiseChain & { execute: (request: ItemNodeRequest, defaultValue?: ItemNode) => Promise<ItemNode> }
  shippingRequest: ShippingRequestNodePromiseChain & {
    execute: (request: ShippingRequestNodeRequest, defaultValue?: ShippingRequestNode) => Promise<ShippingRequestNode>
  }
}

export interface ShippingItemNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  item: ItemNodeObservableChain & { execute: (request: ItemNodeRequest, defaultValue?: ItemNode) => Observable<ItemNode> }
  shippingRequest: ShippingRequestNodeObservableChain & {
    execute: (request: ShippingRequestNodeRequest, defaultValue?: ShippingRequestNode) => Observable<ShippingRequestNode>
  }
}

export interface ShippingRequestNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  deliveryAddress: { execute: (request?: boolean | number, defaultValue?: JSONString | null) => Promise<JSONString | null> }
  status: { execute: (request?: boolean | number, defaultValue?: ShippingRequestStatus) => Promise<ShippingRequestStatus> }
  shippingRequestFeeCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: ShippingRequestShippingRequestFeeCurrency,
    ) => Promise<ShippingRequestShippingRequestFeeCurrency>
  }
  shippingRequestFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  submittedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  notes: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  shippingitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => ShippingItemNodeConnectionPromiseChain & {
    execute: (
      request: ShippingItemNodeConnectionRequest,
      defaultValue?: ShippingItemNodeConnection,
    ) => Promise<ShippingItemNodeConnection>
  }) &
    (ShippingItemNodeConnectionPromiseChain & {
      execute: (
        request: ShippingItemNodeConnectionRequest,
        defaultValue?: ShippingItemNodeConnection,
      ) => Promise<ShippingItemNodeConnection>
    })
}

export interface ShippingRequestNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  deliveryAddress: {
    execute: (request?: boolean | number, defaultValue?: JSONString | null) => Observable<JSONString | null>
  }
  status: {
    execute: (request?: boolean | number, defaultValue?: ShippingRequestStatus) => Observable<ShippingRequestStatus>
  }
  shippingRequestFeeCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: ShippingRequestShippingRequestFeeCurrency,
    ) => Observable<ShippingRequestShippingRequestFeeCurrency>
  }
  shippingRequestFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  submittedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  notes: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  shippingitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => ShippingItemNodeConnectionObservableChain & {
    execute: (
      request: ShippingItemNodeConnectionRequest,
      defaultValue?: ShippingItemNodeConnection,
    ) => Observable<ShippingItemNodeConnection>
  }) &
    (ShippingItemNodeConnectionObservableChain & {
      execute: (
        request: ShippingItemNodeConnectionRequest,
        defaultValue?: ShippingItemNodeConnection,
      ) => Observable<ShippingItemNodeConnection>
    })
}

export interface BuyOrderNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BuyOrderNodeEdgeRequest,
      defaultValue?: (BuyOrderNodeEdge | null)[],
    ) => Promise<(BuyOrderNodeEdge | null)[]>
  }
}

export interface BuyOrderNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BuyOrderNodeEdgeRequest,
      defaultValue?: (BuyOrderNodeEdge | null)[],
    ) => Observable<(BuyOrderNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `BuyOrderNode` and its cursor. */
export interface BuyOrderNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: BuyOrderNodePromiseChain & {
    execute: (request: BuyOrderNodeRequest, defaultValue?: BuyOrderNode | null) => Promise<BuyOrderNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `BuyOrderNode` and its cursor. */
export interface BuyOrderNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: BuyOrderNodeObservableChain & {
    execute: (request: BuyOrderNodeRequest, defaultValue?: BuyOrderNode | null) => Observable<BuyOrderNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BuyOrderNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  groupedBid: GroupedBidNodePromiseChain & {
    execute: (request: GroupedBidNodeRequest, defaultValue?: GroupedBidNode | null) => Promise<GroupedBidNode | null>
  }
  cardParallel: CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode | null) => Promise<CardParallelNode | null>
  }
  grade: GradeNodePromiseChain & { execute: (request: GradeNodeRequest, defaultValue?: GradeNode) => Promise<GradeNode> }
  gradedCardParallel: GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Promise<GradedCardParallelNode | null>
  }
  buyPriceCurrency: {
    execute: (request?: boolean | number, defaultValue?: BuyOrderBuyPriceCurrency) => Promise<BuyOrderBuyPriceCurrency>
  }
  buyPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: BuyOrderState) => Promise<BuyOrderState> }
  buyer: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  seller: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  item: ItemNodePromiseChain & {
    execute: (request: ItemNodeRequest, defaultValue?: ItemNode | null) => Promise<ItemNode | null>
  }
  affiliateCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  stripePaymentMethod: StripePaymentMethodNodePromiseChain & {
    execute: (
      request: StripePaymentMethodNodeRequest,
      defaultValue?: StripePaymentMethodNode | null,
    ) => Promise<StripePaymentMethodNode | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  completedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  expiresAfter: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
}

export interface BuyOrderNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  groupedBid: GroupedBidNodeObservableChain & {
    execute: (request: GroupedBidNodeRequest, defaultValue?: GroupedBidNode | null) => Observable<GroupedBidNode | null>
  }
  cardParallel: CardParallelNodeObservableChain & {
    execute: (
      request: CardParallelNodeRequest,
      defaultValue?: CardParallelNode | null,
    ) => Observable<CardParallelNode | null>
  }
  grade: GradeNodeObservableChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode) => Observable<GradeNode>
  }
  gradedCardParallel: GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Observable<GradedCardParallelNode | null>
  }
  buyPriceCurrency: {
    execute: (request?: boolean | number, defaultValue?: BuyOrderBuyPriceCurrency) => Observable<BuyOrderBuyPriceCurrency>
  }
  buyPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: BuyOrderState) => Observable<BuyOrderState> }
  buyer: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  seller: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  item: ItemNodeObservableChain & {
    execute: (request: ItemNodeRequest, defaultValue?: ItemNode | null) => Observable<ItemNode | null>
  }
  affiliateCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  stripePaymentMethod: StripePaymentMethodNodeObservableChain & {
    execute: (
      request: StripePaymentMethodNodeRequest,
      defaultValue?: StripePaymentMethodNode | null,
    ) => Observable<StripePaymentMethodNode | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  completedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  expiresAfter: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
}

export interface GroupedBidNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  buyorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BuyOrderNodeConnectionPromiseChain & {
    execute: (
      request: BuyOrderNodeConnectionRequest,
      defaultValue?: BuyOrderNodeConnection,
    ) => Promise<BuyOrderNodeConnection>
  }) &
    (BuyOrderNodeConnectionPromiseChain & {
      execute: (
        request: BuyOrderNodeConnectionRequest,
        defaultValue?: BuyOrderNodeConnection,
      ) => Promise<BuyOrderNodeConnection>
    })
  buyPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  openBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  suspendedBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  lowestAskCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  lowestAskPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  lowestAskPercentage: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface GroupedBidNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  buyorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BuyOrderNodeConnectionObservableChain & {
    execute: (
      request: BuyOrderNodeConnectionRequest,
      defaultValue?: BuyOrderNodeConnection,
    ) => Observable<BuyOrderNodeConnection>
  }) &
    (BuyOrderNodeConnectionObservableChain & {
      execute: (
        request: BuyOrderNodeConnectionRequest,
        defaultValue?: BuyOrderNodeConnection,
      ) => Observable<BuyOrderNodeConnection>
    })
  buyPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  openBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  suspendedBuyOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  lowestAskCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  lowestAskPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  lowestAskPercentage: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface SellOrderNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: SellOrderNodeEdgeRequest,
      defaultValue?: (SellOrderNodeEdge | null)[],
    ) => Promise<(SellOrderNodeEdge | null)[]>
  }
}

export interface SellOrderNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: SellOrderNodeEdgeRequest,
      defaultValue?: (SellOrderNodeEdge | null)[],
    ) => Observable<(SellOrderNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `SellOrderNode` and its cursor. */
export interface SellOrderNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: SellOrderNodePromiseChain & {
    execute: (request: SellOrderNodeRequest, defaultValue?: SellOrderNode | null) => Promise<SellOrderNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `SellOrderNode` and its cursor. */
export interface SellOrderNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: SellOrderNodeObservableChain & {
    execute: (request: SellOrderNodeRequest, defaultValue?: SellOrderNode | null) => Observable<SellOrderNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface SellOrderNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  groupedListing: GroupedListingNodePromiseChain & {
    execute: (
      request: GroupedListingNodeRequest,
      defaultValue?: GroupedListingNode | null,
    ) => Promise<GroupedListingNode | null>
  }
  item: ItemNodePromiseChain & { execute: (request: ItemNodeRequest, defaultValue?: ItemNode) => Promise<ItemNode> }
  cardParallel: CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode | null) => Promise<CardParallelNode | null>
  }
  grade: GradeNodePromiseChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Promise<GradeNode | null>
  }
  gradedCardParallel: GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Promise<GradedCardParallelNode | null>
  }
  askPriceCurrency: {
    execute: (request?: boolean | number, defaultValue?: SellOrderAskPriceCurrency) => Promise<SellOrderAskPriceCurrency>
  }
  askPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: SellOrderState) => Promise<SellOrderState> }
  seller: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  buyer: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  completedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  expiresAfter: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
}

export interface SellOrderNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  groupedListing: GroupedListingNodeObservableChain & {
    execute: (
      request: GroupedListingNodeRequest,
      defaultValue?: GroupedListingNode | null,
    ) => Observable<GroupedListingNode | null>
  }
  item: ItemNodeObservableChain & { execute: (request: ItemNodeRequest, defaultValue?: ItemNode) => Observable<ItemNode> }
  cardParallel: CardParallelNodeObservableChain & {
    execute: (
      request: CardParallelNodeRequest,
      defaultValue?: CardParallelNode | null,
    ) => Observable<CardParallelNode | null>
  }
  grade: GradeNodeObservableChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Observable<GradeNode | null>
  }
  gradedCardParallel: GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Observable<GradedCardParallelNode | null>
  }
  askPriceCurrency: {
    execute: (request?: boolean | number, defaultValue?: SellOrderAskPriceCurrency) => Observable<SellOrderAskPriceCurrency>
  }
  askPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: SellOrderState) => Observable<SellOrderState> }
  seller: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  buyer: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  completedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  expiresAfter: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
}

export interface GroupedListingNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  sellorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SellOrderNodeConnectionPromiseChain & {
    execute: (
      request: SellOrderNodeConnectionRequest,
      defaultValue?: SellOrderNodeConnection,
    ) => Promise<SellOrderNodeConnection>
  }) &
    (SellOrderNodeConnectionPromiseChain & {
      execute: (
        request: SellOrderNodeConnectionRequest,
        defaultValue?: SellOrderNodeConnection,
      ) => Promise<SellOrderNodeConnection>
    })
  askPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  openSellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  highestBidCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  highestBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  highestBidPercentage: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface GroupedListingNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  sellorderSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SellOrderNodeConnectionObservableChain & {
    execute: (
      request: SellOrderNodeConnectionRequest,
      defaultValue?: SellOrderNodeConnection,
    ) => Observable<SellOrderNodeConnection>
  }) &
    (SellOrderNodeConnectionObservableChain & {
      execute: (
        request: SellOrderNodeConnectionRequest,
        defaultValue?: SellOrderNodeConnection,
      ) => Observable<SellOrderNodeConnection>
    })
  askPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  openSellOrderCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  highestBidCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  highestBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  highestBidPercentage: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface LotItemNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: LotItemNodeEdgeRequest,
      defaultValue?: (LotItemNodeEdge | null)[],
    ) => Promise<(LotItemNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface LotItemNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: LotItemNodeEdgeRequest,
      defaultValue?: (LotItemNodeEdge | null)[],
    ) => Observable<(LotItemNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `LotItemNode` and its cursor. */
export interface LotItemNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: LotItemNodePromiseChain & {
    execute: (request: LotItemNodeRequest, defaultValue?: LotItemNode | null) => Promise<LotItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `LotItemNode` and its cursor. */
export interface LotItemNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: LotItemNodeObservableChain & {
    execute: (request: LotItemNodeRequest, defaultValue?: LotItemNode | null) => Observable<LotItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface LotItemNodePromiseChain {
  lot: LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Promise<LotNode> }
  gradedCardParallel: GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode,
    ) => Promise<GradedCardParallelNode>
  }
  /** The specific collection item this lot item represents, if applicable. May be left blank if the item is not yet input into the marketplace. */
  item: ItemNodePromiseChain & {
    execute: (request: ItemNodeRequest, defaultValue?: ItemNode | null) => Promise<ItemNode | null>
  }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
}

export interface LotItemNodeObservableChain {
  lot: LotNodeObservableChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Observable<LotNode> }
  gradedCardParallel: GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode,
    ) => Observable<GradedCardParallelNode>
  }
  /** The specific collection item this lot item represents, if applicable. May be left blank if the item is not yet input into the marketplace. */
  item: ItemNodeObservableChain & {
    execute: (request: ItemNodeRequest, defaultValue?: ItemNode | null) => Observable<ItemNode | null>
  }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
}

export interface LotNodePromiseChain {
  order: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** A longer text description of this lot, such as an overview of the items included, and so on. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  auction: AuctionNodePromiseChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Promise<AuctionNode | null>
  }
  currency: { execute: (request?: boolean | number, defaultValue?: LotCurrency) => Promise<LotCurrency> }
  openingPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  initialDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  newBidMinimumDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** Display name for the collection this NFT belongs to. */
  nftCollectionName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  state: { execute: (request?: boolean | number, defaultValue?: LotState) => Promise<LotState> }
  /** Date and time at which this lot opened for bidding. */
  biddingOpenedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which this lot is scheduled to conclude bidding. */
  biddingEndsAfter: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which this lot concluded bidding. */
  biddingEndedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Optional URL to link out to NFT information on an external website. */
  nftExternalUrl: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  bidSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BidNodeConnectionPromiseChain & {
    execute: (request: BidNodeConnectionRequest, defaultValue?: BidNodeConnection) => Promise<BidNodeConnection>
  }) &
    (BidNodeConnectionPromiseChain & {
      execute: (request: BidNodeConnectionRequest, defaultValue?: BidNodeConnection) => Promise<BidNodeConnection>
    })
  lotitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotItemNodeConnectionPromiseChain & {
    execute: (request: LotItemNodeConnectionRequest, defaultValue?: LotItemNodeConnection) => Promise<LotItemNodeConnection>
  }) &
    (LotItemNodeConnectionPromiseChain & {
      execute: (
        request: LotItemNodeConnectionRequest,
        defaultValue?: LotItemNodeConnection,
      ) => Promise<LotItemNodeConnection>
    })
  lotphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotPhotoNodeConnectionPromiseChain & {
    execute: (
      request: LotPhotoNodeConnectionRequest,
      defaultValue?: LotPhotoNodeConnection,
    ) => Promise<LotPhotoNodeConnection>
  }) &
    (LotPhotoNodeConnectionPromiseChain & {
      execute: (
        request: LotPhotoNodeConnectionRequest,
        defaultValue?: LotPhotoNodeConnection,
      ) => Promise<LotPhotoNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  highestBid: BidNodePromiseChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null>
  }
  highestBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bidPriceIncrement: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  minimumNextBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface LotNodeObservableChain {
  order: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** A longer text description of this lot, such as an overview of the items included, and so on. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  auction: AuctionNodeObservableChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Observable<AuctionNode | null>
  }
  currency: { execute: (request?: boolean | number, defaultValue?: LotCurrency) => Observable<LotCurrency> }
  openingPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  initialDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  newBidMinimumDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** Display name for the collection this NFT belongs to. */
  nftCollectionName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  state: { execute: (request?: boolean | number, defaultValue?: LotState) => Observable<LotState> }
  /** Date and time at which this lot opened for bidding. */
  biddingOpenedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which this lot is scheduled to conclude bidding. */
  biddingEndsAfter: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which this lot concluded bidding. */
  biddingEndedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Optional URL to link out to NFT information on an external website. */
  nftExternalUrl: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  bidSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BidNodeConnectionObservableChain & {
    execute: (request: BidNodeConnectionRequest, defaultValue?: BidNodeConnection) => Observable<BidNodeConnection>
  }) &
    (BidNodeConnectionObservableChain & {
      execute: (request: BidNodeConnectionRequest, defaultValue?: BidNodeConnection) => Observable<BidNodeConnection>
    })
  lotitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotItemNodeConnectionObservableChain & {
    execute: (
      request: LotItemNodeConnectionRequest,
      defaultValue?: LotItemNodeConnection,
    ) => Observable<LotItemNodeConnection>
  }) &
    (LotItemNodeConnectionObservableChain & {
      execute: (
        request: LotItemNodeConnectionRequest,
        defaultValue?: LotItemNodeConnection,
      ) => Observable<LotItemNodeConnection>
    })
  lotphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotPhotoNodeConnectionObservableChain & {
    execute: (
      request: LotPhotoNodeConnectionRequest,
      defaultValue?: LotPhotoNodeConnection,
    ) => Observable<LotPhotoNodeConnection>
  }) &
    (LotPhotoNodeConnectionObservableChain & {
      execute: (
        request: LotPhotoNodeConnectionRequest,
        defaultValue?: LotPhotoNodeConnection,
      ) => Observable<LotPhotoNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  highestBid: BidNodeObservableChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
  }
  highestBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bidPriceIncrement: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  minimumNextBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface AuctionNodePromiseChain {
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL identifier for this auction. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Assign a featured rank to promote this auction. Higher values take priority. Use sparingly; leave blank for most auctions. */
  featuredRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** A longer text description of this auction, such as an overview of the items included, special guests participating in the stream, and so on. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Date and time at which this auction is scheduled to begin. Presented to users as approximate. Leave blank if auction is not yet scheduled. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  state: { execute: (request?: boolean | number, defaultValue?: AuctionState) => Promise<AuctionState> }
  /** The 11-digit video ID for the YouTube video this auction will be streamed on. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The name for the Twitch channel this auction will be streamed on. */
  twitchChannelName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Choose where the event is visible and who can access it. */
  visibility: { execute: (request?: boolean | number, defaultValue?: AuctionVisibility) => Promise<AuctionVisibility> }
  /** Determines whether bidding is restricted to a single lot at any given time, or allows for multiple lots to be bidding concurrently. */
  lotRunningFormat: {
    execute: (request?: boolean | number, defaultValue?: AuctionLotRunningFormat) => Promise<AuctionLotRunningFormat>
  }
  /** Allow lots to be marked as open for bidding prior to the event going live. */
  allowPrebidding: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  currency: { execute: (request?: boolean | number, defaultValue?: AuctionCurrency) => Promise<AuctionCurrency> }
  auctionphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => AuctionPhotoNodeConnectionPromiseChain & {
    execute: (
      request: AuctionPhotoNodeConnectionRequest,
      defaultValue?: AuctionPhotoNodeConnection,
    ) => Promise<AuctionPhotoNodeConnection>
  }) &
    (AuctionPhotoNodeConnectionPromiseChain & {
      execute: (
        request: AuctionPhotoNodeConnectionRequest,
        defaultValue?: AuctionPhotoNodeConnection,
      ) => Promise<AuctionPhotoNodeConnection>
    })
  lotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    productCategory?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionPromiseChain & {
    execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Promise<LotNodeConnection>
  }) &
    (LotNodeConnectionPromiseChain & {
      execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Promise<LotNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  lotCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  lotItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  lotMinOpeningPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  lotMaxOpeningPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  lotRemainingCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  pusherChannelId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface AuctionNodeObservableChain {
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL identifier for this auction. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Assign a featured rank to promote this auction. Higher values take priority. Use sparingly; leave blank for most auctions. */
  featuredRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** A longer text description of this auction, such as an overview of the items included, special guests participating in the stream, and so on. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Date and time at which this auction is scheduled to begin. Presented to users as approximate. Leave blank if auction is not yet scheduled. */
  scheduledStartTime: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  state: { execute: (request?: boolean | number, defaultValue?: AuctionState) => Observable<AuctionState> }
  /** The 11-digit video ID for the YouTube video this auction will be streamed on. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The name for the Twitch channel this auction will be streamed on. */
  twitchChannelName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Choose where the event is visible and who can access it. */
  visibility: { execute: (request?: boolean | number, defaultValue?: AuctionVisibility) => Observable<AuctionVisibility> }
  /** Determines whether bidding is restricted to a single lot at any given time, or allows for multiple lots to be bidding concurrently. */
  lotRunningFormat: {
    execute: (request?: boolean | number, defaultValue?: AuctionLotRunningFormat) => Observable<AuctionLotRunningFormat>
  }
  /** Allow lots to be marked as open for bidding prior to the event going live. */
  allowPrebidding: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  currency: { execute: (request?: boolean | number, defaultValue?: AuctionCurrency) => Observable<AuctionCurrency> }
  auctionphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => AuctionPhotoNodeConnectionObservableChain & {
    execute: (
      request: AuctionPhotoNodeConnectionRequest,
      defaultValue?: AuctionPhotoNodeConnection,
    ) => Observable<AuctionPhotoNodeConnection>
  }) &
    (AuctionPhotoNodeConnectionObservableChain & {
      execute: (
        request: AuctionPhotoNodeConnectionRequest,
        defaultValue?: AuctionPhotoNodeConnection,
      ) => Observable<AuctionPhotoNodeConnection>
    })
  lotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    productCategory?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionObservableChain & {
    execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Observable<LotNodeConnection>
  }) &
    (LotNodeConnectionObservableChain & {
      execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Observable<LotNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  lotCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  lotItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  lotMinOpeningPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  lotMaxOpeningPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  lotRemainingCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  pusherChannelId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface AuctionPhotoNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AuctionPhotoNodeEdgeRequest,
      defaultValue?: (AuctionPhotoNodeEdge | null)[],
    ) => Promise<(AuctionPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface AuctionPhotoNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AuctionPhotoNodeEdgeRequest,
      defaultValue?: (AuctionPhotoNodeEdge | null)[],
    ) => Observable<(AuctionPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `AuctionPhotoNode` and its cursor. */
export interface AuctionPhotoNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: AuctionPhotoNodePromiseChain & {
    execute: (request: AuctionPhotoNodeRequest, defaultValue?: AuctionPhotoNode | null) => Promise<AuctionPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `AuctionPhotoNode` and its cursor. */
export interface AuctionPhotoNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: AuctionPhotoNodeObservableChain & {
    execute: (
      request: AuctionPhotoNodeRequest,
      defaultValue?: AuctionPhotoNode | null,
    ) => Observable<AuctionPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface AuctionPhotoNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  auction: AuctionNodePromiseChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode) => Promise<AuctionNode>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface AuctionPhotoNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  auction: AuctionNodeObservableChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode) => Observable<AuctionNode>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface LotNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: { execute: (request: LotNodeEdgeRequest, defaultValue?: (LotNodeEdge | null)[]) => Promise<(LotNodeEdge | null)[]> }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface LotNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: LotNodeEdgeRequest, defaultValue?: (LotNodeEdge | null)[]) => Observable<(LotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `LotNode` and its cursor. */
export interface LotNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: LotNodePromiseChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Promise<LotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `LotNode` and its cursor. */
export interface LotNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: LotNodeObservableChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Observable<LotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BidNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: { execute: (request: BidNodeEdgeRequest, defaultValue?: (BidNodeEdge | null)[]) => Promise<(BidNodeEdge | null)[]> }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface BidNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: BidNodeEdgeRequest, defaultValue?: (BidNodeEdge | null)[]) => Observable<(BidNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `BidNode` and its cursor. */
export interface BidNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: BidNodePromiseChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `BidNode` and its cursor. */
export interface BidNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: BidNodeObservableChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BidNodePromiseChain {
  lot: LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Promise<LotNode> }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  bidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  state: { execute: (request?: boolean | number, defaultValue?: BidState) => Promise<BidState> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  bidPriceCurrency: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface BidNodeObservableChain {
  lot: LotNodeObservableChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Observable<LotNode> }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  bidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  state: { execute: (request?: boolean | number, defaultValue?: BidState) => Observable<BidState> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  bidPriceCurrency: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface LotPhotoNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: LotPhotoNodeEdgeRequest,
      defaultValue?: (LotPhotoNodeEdge | null)[],
    ) => Promise<(LotPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface LotPhotoNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: LotPhotoNodeEdgeRequest,
      defaultValue?: (LotPhotoNodeEdge | null)[],
    ) => Observable<(LotPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `LotPhotoNode` and its cursor. */
export interface LotPhotoNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: LotPhotoNodePromiseChain & {
    execute: (request: LotPhotoNodeRequest, defaultValue?: LotPhotoNode | null) => Promise<LotPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `LotPhotoNode` and its cursor. */
export interface LotPhotoNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: LotPhotoNodeObservableChain & {
    execute: (request: LotPhotoNodeRequest, defaultValue?: LotPhotoNode | null) => Observable<LotPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface LotPhotoNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  lot: LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Promise<LotNode> }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface LotPhotoNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  lot: LotNodeObservableChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Observable<LotNode> }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface HitNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** User-facing display name for this hit (e.g. "🔥 EPIC PULL! Tom Brady Prizm Auto Silver #/10!") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** An optional longer description, giving context to the break or hit. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The spot in the break that this hit is assigned to. */
  spot: SpotNodePromiseChain & { execute: (request: SpotNodeRequest, defaultValue?: SpotNode) => Promise<SpotNode> }
  /** The type and grade of the card hit. As these are pack-fresh pulls, we typically assume StarStock A grade. */
  gradedCardParallel: GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode,
    ) => Promise<GradedCardParallelNode>
  }
  /** The specific item in our database representing this hit. May be left blank prior to the card being processed. */
  item: ItemNodePromiseChain & {
    execute: (request: ItemNodeRequest, defaultValue?: ItemNode | null) => Promise<ItemNode | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  createdBy: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface HitNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** User-facing display name for this hit (e.g. "🔥 EPIC PULL! Tom Brady Prizm Auto Silver #/10!") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** An optional longer description, giving context to the break or hit. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The spot in the break that this hit is assigned to. */
  spot: SpotNodeObservableChain & { execute: (request: SpotNodeRequest, defaultValue?: SpotNode) => Observable<SpotNode> }
  /** The type and grade of the card hit. As these are pack-fresh pulls, we typically assume StarStock A grade. */
  gradedCardParallel: GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode,
    ) => Observable<GradedCardParallelNode>
  }
  /** The specific item in our database representing this hit. May be left blank prior to the card being processed. */
  item: ItemNodeObservableChain & {
    execute: (request: ItemNodeRequest, defaultValue?: ItemNode | null) => Observable<ItemNode | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  createdBy: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface SpotNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Promise<BoxBreakNode>
  }
  /** Spots are ordered within the parent break by their rank (asc). Rank will also be used as slot name if one is not specified (e.g. random breaks). */
  rank: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** Optional display name for this spot in the break (e.g. Cleveland Browns), or may be left blank to use rank. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  priceCurrency: { execute: (request?: boolean | number, defaultValue?: SpotPriceCurrency) => Promise<SpotPriceCurrency> }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  purchasedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** The spot in the break that this hit is assigned to. */
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionPromiseChain & {
    execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Promise<HitNodeConnection>
  }) &
    (HitNodeConnectionPromiseChain & {
      execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Promise<HitNodeConnection>
    })
  personalbreakrequest: PersonalBreakRequestNodePromiseChain & {
    execute: (
      request: PersonalBreakRequestNodeRequest,
      defaultValue?: PersonalBreakRequestNode | null,
    ) => Promise<PersonalBreakRequestNode | null>
  }
  isPersonalBreakRequest: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  myParticipant: ParticipantNodePromiseChain & {
    execute: (request: ParticipantNodeRequest, defaultValue?: ParticipantNode | null) => Promise<ParticipantNode | null>
  }
}

export interface SpotNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Observable<BoxBreakNode>
  }
  /** Spots are ordered within the parent break by their rank (asc). Rank will also be used as slot name if one is not specified (e.g. random breaks). */
  rank: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** Optional display name for this spot in the break (e.g. Cleveland Browns), or may be left blank to use rank. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  priceCurrency: { execute: (request?: boolean | number, defaultValue?: SpotPriceCurrency) => Observable<SpotPriceCurrency> }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  purchasedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** The spot in the break that this hit is assigned to. */
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionObservableChain & {
    execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Observable<HitNodeConnection>
  }) &
    (HitNodeConnectionObservableChain & {
      execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Observable<HitNodeConnection>
    })
  personalbreakrequest: PersonalBreakRequestNodeObservableChain & {
    execute: (
      request: PersonalBreakRequestNodeRequest,
      defaultValue?: PersonalBreakRequestNode | null,
    ) => Observable<PersonalBreakRequestNode | null>
  }
  isPersonalBreakRequest: {
    execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null>
  }
  myParticipant: ParticipantNodeObservableChain & {
    execute: (request: ParticipantNodeRequest, defaultValue?: ParticipantNode | null) => Observable<ParticipantNode | null>
  }
}

export interface HitNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: { execute: (request: HitNodeEdgeRequest, defaultValue?: (HitNodeEdge | null)[]) => Promise<(HitNodeEdge | null)[]> }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface HitNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: HitNodeEdgeRequest, defaultValue?: (HitNodeEdge | null)[]) => Observable<(HitNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `HitNode` and its cursor. */
export interface HitNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: HitNodePromiseChain & {
    execute: (request: HitNodeRequest, defaultValue?: HitNode | null) => Promise<HitNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `HitNode` and its cursor. */
export interface HitNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: HitNodeObservableChain & {
    execute: (request: HitNodeRequest, defaultValue?: HitNode | null) => Observable<HitNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface PersonalBreakRequestNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  itemOwnership: ItemOwnershipNodePromiseChain & {
    execute: (request: ItemOwnershipNodeRequest, defaultValue?: ItemOwnershipNode) => Promise<ItemOwnershipNode>
  }
  /** The scheduled personal break that the user would like their box broken as part of. */
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Promise<BoxBreakNode>
  }
  /** The spot in the break that represents this accepted request. */
  spot: SpotNodePromiseChain & {
    execute: (request: SpotNodeRequest, defaultValue?: SpotNode | null) => Promise<SpotNode | null>
  }
  /** Optional, additional request information, such as a preferred start time. */
  requestDetails: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  breakFeeCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: PersonalBreakRequestBreakFeeCurrency,
    ) => Promise<PersonalBreakRequestBreakFeeCurrency>
  }
  breakFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  state: {
    execute: (request?: boolean | number, defaultValue?: PersonalBreakRequestState) => Promise<PersonalBreakRequestState>
  }
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  address: AddressNodePromiseChain & {
    execute: (request: AddressNodeRequest, defaultValue?: AddressNode | null) => Promise<AddressNode | null>
  }
}

export interface PersonalBreakRequestNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  itemOwnership: ItemOwnershipNodeObservableChain & {
    execute: (request: ItemOwnershipNodeRequest, defaultValue?: ItemOwnershipNode) => Observable<ItemOwnershipNode>
  }
  /** The scheduled personal break that the user would like their box broken as part of. */
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Observable<BoxBreakNode>
  }
  /** The spot in the break that represents this accepted request. */
  spot: SpotNodeObservableChain & {
    execute: (request: SpotNodeRequest, defaultValue?: SpotNode | null) => Observable<SpotNode | null>
  }
  /** Optional, additional request information, such as a preferred start time. */
  requestDetails: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  breakFeeCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: PersonalBreakRequestBreakFeeCurrency,
    ) => Observable<PersonalBreakRequestBreakFeeCurrency>
  }
  breakFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  state: {
    execute: (request?: boolean | number, defaultValue?: PersonalBreakRequestState) => Observable<PersonalBreakRequestState>
  }
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  address: AddressNodeObservableChain & {
    execute: (request: AddressNodeRequest, defaultValue?: AddressNode | null) => Observable<AddressNode | null>
  }
}

export interface ItemOwnershipNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  item: ItemNodePromiseChain & { execute: (request: ItemNodeRequest, defaultValue?: ItemNode) => Promise<ItemNode> }
  owner: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  boughtBuyorder: BuyOrderNodePromiseChain & {
    execute: (request: BuyOrderNodeRequest, defaultValue?: BuyOrderNode | null) => Promise<BuyOrderNode | null>
  }
  boughtSellorder: SellOrderNodePromiseChain & {
    execute: (request: SellOrderNodeRequest, defaultValue?: SellOrderNode | null) => Promise<SellOrderNode | null>
  }
  soldBuyorder: BuyOrderNodePromiseChain & {
    execute: (request: BuyOrderNodeRequest, defaultValue?: BuyOrderNode | null) => Promise<BuyOrderNode | null>
  }
  soldSellorder: SellOrderNodePromiseChain & {
    execute: (request: SellOrderNodeRequest, defaultValue?: SellOrderNode | null) => Promise<SellOrderNode | null>
  }
  boughtPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: ItemOwnershipBoughtPriceCurrency | null,
    ) => Promise<ItemOwnershipBoughtPriceCurrency | null>
  }
  boughtPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  soldPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: ItemOwnershipSoldPriceCurrency | null,
    ) => Promise<ItemOwnershipSoldPriceCurrency | null>
  }
  soldPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  startedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  endedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  personalbreakrequestSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionPromiseChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection,
    ) => Promise<PersonalBreakRequestNodeConnection>
  }) &
    (PersonalBreakRequestNodeConnectionPromiseChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection,
      ) => Promise<PersonalBreakRequestNodeConnection>
    })
  askPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  askPriceValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  breakFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  inDraftShippingRequest: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface ItemOwnershipNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  item: ItemNodeObservableChain & { execute: (request: ItemNodeRequest, defaultValue?: ItemNode) => Observable<ItemNode> }
  owner: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  boughtBuyorder: BuyOrderNodeObservableChain & {
    execute: (request: BuyOrderNodeRequest, defaultValue?: BuyOrderNode | null) => Observable<BuyOrderNode | null>
  }
  boughtSellorder: SellOrderNodeObservableChain & {
    execute: (request: SellOrderNodeRequest, defaultValue?: SellOrderNode | null) => Observable<SellOrderNode | null>
  }
  soldBuyorder: BuyOrderNodeObservableChain & {
    execute: (request: BuyOrderNodeRequest, defaultValue?: BuyOrderNode | null) => Observable<BuyOrderNode | null>
  }
  soldSellorder: SellOrderNodeObservableChain & {
    execute: (request: SellOrderNodeRequest, defaultValue?: SellOrderNode | null) => Observable<SellOrderNode | null>
  }
  boughtPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: ItemOwnershipBoughtPriceCurrency | null,
    ) => Observable<ItemOwnershipBoughtPriceCurrency | null>
  }
  boughtPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  soldPriceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: ItemOwnershipSoldPriceCurrency | null,
    ) => Observable<ItemOwnershipSoldPriceCurrency | null>
  }
  soldPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  startedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  endedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  personalbreakrequestSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionObservableChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection,
    ) => Observable<PersonalBreakRequestNodeConnection>
  }) &
    (PersonalBreakRequestNodeConnectionObservableChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection,
      ) => Observable<PersonalBreakRequestNodeConnection>
    })
  askPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  askPriceValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  breakFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  inDraftShippingRequest: {
    execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null>
  }
}

export interface PersonalBreakRequestNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: PersonalBreakRequestNodeEdgeRequest,
      defaultValue?: (PersonalBreakRequestNodeEdge | null)[],
    ) => Promise<(PersonalBreakRequestNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface PersonalBreakRequestNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: PersonalBreakRequestNodeEdgeRequest,
      defaultValue?: (PersonalBreakRequestNodeEdge | null)[],
    ) => Observable<(PersonalBreakRequestNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `PersonalBreakRequestNode` and its cursor. */
export interface PersonalBreakRequestNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: PersonalBreakRequestNodePromiseChain & {
    execute: (
      request: PersonalBreakRequestNodeRequest,
      defaultValue?: PersonalBreakRequestNode | null,
    ) => Promise<PersonalBreakRequestNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `PersonalBreakRequestNode` and its cursor. */
export interface PersonalBreakRequestNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: PersonalBreakRequestNodeObservableChain & {
    execute: (
      request: PersonalBreakRequestNodeRequest,
      defaultValue?: PersonalBreakRequestNode | null,
    ) => Observable<PersonalBreakRequestNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface AddressNodePromiseChain {
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  addressLines: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  locality: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  region: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  postalCode: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  country: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  role: { execute: (request?: boolean | number, defaultValue?: AddressRole) => Promise<AddressRole> }
  default: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
}

export interface AddressNodeObservableChain {
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  addressLines: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  locality: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  region: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  postalCode: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  country: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  role: { execute: (request?: boolean | number, defaultValue?: AddressRole) => Observable<AddressRole> }
  default: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
}

export interface ParticipantNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Promise<BoxBreakNode>
  }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  joinedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  /** The streaming platform on which the participant plans to watch. */
  liveStreamPlatform: {
    execute: (
      request?: boolean | number,
      defaultValue?: ParticipantLiveStreamPlatform,
    ) => Promise<ParticipantLiveStreamPlatform>
  }
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  address: AddressNodePromiseChain & {
    execute: (request: AddressNodeRequest, defaultValue?: AddressNode | null) => Promise<AddressNode | null>
  }
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The break participant who has claimed this spot (note that one participant may claim multiple spots). */
  spotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SpotNodeConnectionPromiseChain & {
    execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Promise<SpotNodeConnection>
  }) &
    (SpotNodeConnectionPromiseChain & {
      execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Promise<SpotNodeConnection>
    })
}

export interface ParticipantNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Observable<BoxBreakNode>
  }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  joinedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  /** The streaming platform on which the participant plans to watch. */
  liveStreamPlatform: {
    execute: (
      request?: boolean | number,
      defaultValue?: ParticipantLiveStreamPlatform,
    ) => Observable<ParticipantLiveStreamPlatform>
  }
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  address: AddressNodeObservableChain & {
    execute: (request: AddressNodeRequest, defaultValue?: AddressNode | null) => Observable<AddressNode | null>
  }
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The break participant who has claimed this spot (note that one participant may claim multiple spots). */
  spotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SpotNodeConnectionObservableChain & {
    execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Observable<SpotNodeConnection>
  }) &
    (SpotNodeConnectionObservableChain & {
      execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Observable<SpotNodeConnection>
    })
}

export interface SpotNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: SpotNodeEdgeRequest, defaultValue?: (SpotNodeEdge | null)[]) => Promise<(SpotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface SpotNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: SpotNodeEdgeRequest, defaultValue?: (SpotNodeEdge | null)[]) => Observable<(SpotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `SpotNode` and its cursor. */
export interface SpotNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: SpotNodePromiseChain & {
    execute: (request: SpotNodeRequest, defaultValue?: SpotNode | null) => Promise<SpotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `SpotNode` and its cursor. */
export interface SpotNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: SpotNodeObservableChain & {
    execute: (request: SpotNodeRequest, defaultValue?: SpotNode | null) => Observable<SpotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface WishlistItemNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: WishlistItemNodeEdgeRequest,
      defaultValue?: (WishlistItemNodeEdge | null)[],
    ) => Promise<(WishlistItemNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface WishlistItemNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: WishlistItemNodeEdgeRequest,
      defaultValue?: (WishlistItemNodeEdge | null)[],
    ) => Observable<(WishlistItemNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `WishlistItemNode` and its cursor. */
export interface WishlistItemNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: WishlistItemNodePromiseChain & {
    execute: (request: WishlistItemNodeRequest, defaultValue?: WishlistItemNode | null) => Promise<WishlistItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `WishlistItemNode` and its cursor. */
export interface WishlistItemNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: WishlistItemNodeObservableChain & {
    execute: (
      request: WishlistItemNodeRequest,
      defaultValue?: WishlistItemNode | null,
    ) => Observable<WishlistItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface WishlistItemNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  cardParallel: CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode) => Promise<CardParallelNode>
  }
  wisher: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
}

export interface WishlistItemNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  cardParallel: CardParallelNodeObservableChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode) => Observable<CardParallelNode>
  }
  wisher: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
}

export interface HistoricalPurchaseNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: HistoricalPurchaseNodeEdgeRequest,
      defaultValue?: (HistoricalPurchaseNodeEdge | null)[],
    ) => Promise<(HistoricalPurchaseNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface HistoricalPurchaseNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: HistoricalPurchaseNodeEdgeRequest,
      defaultValue?: (HistoricalPurchaseNodeEdge | null)[],
    ) => Observable<(HistoricalPurchaseNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `HistoricalPurchaseNode` and its cursor. */
export interface HistoricalPurchaseNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: HistoricalPurchaseNodePromiseChain & {
    execute: (
      request: HistoricalPurchaseNodeRequest,
      defaultValue?: HistoricalPurchaseNode | null,
    ) => Promise<HistoricalPurchaseNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `HistoricalPurchaseNode` and its cursor. */
export interface HistoricalPurchaseNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: HistoricalPurchaseNodeObservableChain & {
    execute: (
      request: HistoricalPurchaseNodeRequest,
      defaultValue?: HistoricalPurchaseNode | null,
    ) => Observable<HistoricalPurchaseNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface HistoricalPurchaseNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  cardParallel: CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode) => Promise<CardParallelNode>
  }
  grade: GradeNodePromiseChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Promise<GradeNode | null>
  }
  gradedCardParallel: GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Promise<GradedCardParallelNode | null>
  }
  source: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  priceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: HistoricalPurchasePriceCurrency,
    ) => Promise<HistoricalPurchasePriceCurrency>
  }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  buyer: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  seller: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  affiliateCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  saleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  verified: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  softDeleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  quantity: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  date: { execute: (request?: boolean | number, defaultValue?: Date | null) => Promise<Date | null> }
  myRole: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface HistoricalPurchaseNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  cardParallel: CardParallelNodeObservableChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode) => Observable<CardParallelNode>
  }
  grade: GradeNodeObservableChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Observable<GradeNode | null>
  }
  gradedCardParallel: GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Observable<GradedCardParallelNode | null>
  }
  source: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  priceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: HistoricalPurchasePriceCurrency,
    ) => Observable<HistoricalPurchasePriceCurrency>
  }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  buyer: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  seller: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  affiliateCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  saleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  verified: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  softDeleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  quantity: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  date: { execute: (request?: boolean | number, defaultValue?: Date | null) => Observable<Date | null> }
  myRole: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface MarketIndexComponentNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: MarketIndexComponentNodeEdgeRequest,
      defaultValue?: (MarketIndexComponentNodeEdge | null)[],
    ) => Promise<(MarketIndexComponentNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface MarketIndexComponentNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: MarketIndexComponentNodeEdgeRequest,
      defaultValue?: (MarketIndexComponentNodeEdge | null)[],
    ) => Observable<(MarketIndexComponentNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `MarketIndexComponentNode` and its cursor. */
export interface MarketIndexComponentNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: MarketIndexComponentNodePromiseChain & {
    execute: (
      request: MarketIndexComponentNodeRequest,
      defaultValue?: MarketIndexComponentNode | null,
    ) => Promise<MarketIndexComponentNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `MarketIndexComponentNode` and its cursor. */
export interface MarketIndexComponentNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: MarketIndexComponentNodeObservableChain & {
    execute: (
      request: MarketIndexComponentNodeRequest,
      defaultValue?: MarketIndexComponentNode | null,
    ) => Observable<MarketIndexComponentNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface MarketIndexComponentNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  marketIndex: MarketIndexNodePromiseChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode) => Promise<MarketIndexNode>
  }
  /** Unique ranking for this component within the index. Represents relative strength of the component, disconnected from exact current value. */
  rank: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** Optional grouping category for this component within the index (e.g. a position in a single-sport index, a sport in a multi-sport index) */
  subcategory: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  player: PlayerNodePromiseChain & {
    execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode) => Promise<PlayerNode>
  }
  gradedCardParallel: GradedCardParallelNodePromiseChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Promise<GradedCardParallelNode | null>
  }
  /** URL linking to the appropriate detail page for this component (e.g. a card or player page) */
  linkUrl: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  latestComponentSnapshot: MarketIndexComponentSnapshotNodePromiseChain & {
    execute: (
      request: MarketIndexComponentSnapshotNodeRequest,
      defaultValue?: MarketIndexComponentSnapshotNode | null,
    ) => Promise<MarketIndexComponentSnapshotNode | null>
  }
  componentSnapshots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => MarketIndexComponentSnapshotNodeConnectionPromiseChain & {
    execute: (
      request: MarketIndexComponentSnapshotNodeConnectionRequest,
      defaultValue?: MarketIndexComponentSnapshotNodeConnection,
    ) => Promise<MarketIndexComponentSnapshotNodeConnection>
  }) &
    (MarketIndexComponentSnapshotNodeConnectionPromiseChain & {
      execute: (
        request: MarketIndexComponentSnapshotNodeConnectionRequest,
        defaultValue?: MarketIndexComponentSnapshotNodeConnection,
      ) => Promise<MarketIndexComponentSnapshotNodeConnection>
    })
}

export interface MarketIndexComponentNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  marketIndex: MarketIndexNodeObservableChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode) => Observable<MarketIndexNode>
  }
  /** Unique ranking for this component within the index. Represents relative strength of the component, disconnected from exact current value. */
  rank: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** Optional grouping category for this component within the index (e.g. a position in a single-sport index, a sport in a multi-sport index) */
  subcategory: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  player: PlayerNodeObservableChain & {
    execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode) => Observable<PlayerNode>
  }
  gradedCardParallel: GradedCardParallelNodeObservableChain & {
    execute: (
      request: GradedCardParallelNodeRequest,
      defaultValue?: GradedCardParallelNode | null,
    ) => Observable<GradedCardParallelNode | null>
  }
  /** URL linking to the appropriate detail page for this component (e.g. a card or player page) */
  linkUrl: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  latestComponentSnapshot: MarketIndexComponentSnapshotNodeObservableChain & {
    execute: (
      request: MarketIndexComponentSnapshotNodeRequest,
      defaultValue?: MarketIndexComponentSnapshotNode | null,
    ) => Observable<MarketIndexComponentSnapshotNode | null>
  }
  componentSnapshots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => MarketIndexComponentSnapshotNodeConnectionObservableChain & {
    execute: (
      request: MarketIndexComponentSnapshotNodeConnectionRequest,
      defaultValue?: MarketIndexComponentSnapshotNodeConnection,
    ) => Observable<MarketIndexComponentSnapshotNodeConnection>
  }) &
    (MarketIndexComponentSnapshotNodeConnectionObservableChain & {
      execute: (
        request: MarketIndexComponentSnapshotNodeConnectionRequest,
        defaultValue?: MarketIndexComponentSnapshotNodeConnection,
      ) => Observable<MarketIndexComponentSnapshotNodeConnection>
    })
}

export interface MarketIndexNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** A short alphanumeric symbol to represent this index (e.g. NFLP30 for the NFL Prizm 30 index) */
  symbol: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL path for this index */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Brief summary of this index and the components it includes */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  latestSnapshot: MarketIndexSnapshotNodePromiseChain & {
    execute: (
      request: MarketIndexSnapshotNodeRequest,
      defaultValue?: MarketIndexSnapshotNode | null,
    ) => Promise<MarketIndexSnapshotNode | null>
  }
  snapshots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    name?: String | null
  }) => MarketIndexSnapshotNodeConnectionPromiseChain & {
    execute: (
      request: MarketIndexSnapshotNodeConnectionRequest,
      defaultValue?: MarketIndexSnapshotNodeConnection,
    ) => Promise<MarketIndexSnapshotNodeConnection>
  }) &
    (MarketIndexSnapshotNodeConnectionPromiseChain & {
      execute: (
        request: MarketIndexSnapshotNodeConnectionRequest,
        defaultValue?: MarketIndexSnapshotNodeConnection,
      ) => Promise<MarketIndexSnapshotNodeConnection>
    })
  components: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
  }) => MarketIndexComponentNodeConnectionPromiseChain & {
    execute: (
      request: MarketIndexComponentNodeConnectionRequest,
      defaultValue?: MarketIndexComponentNodeConnection,
    ) => Promise<MarketIndexComponentNodeConnection>
  }) &
    (MarketIndexComponentNodeConnectionPromiseChain & {
      execute: (
        request: MarketIndexComponentNodeConnectionRequest,
        defaultValue?: MarketIndexComponentNodeConnection,
      ) => Promise<MarketIndexComponentNodeConnection>
    })
}

export interface MarketIndexNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** A short alphanumeric symbol to represent this index (e.g. NFLP30 for the NFL Prizm 30 index) */
  symbol: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL path for this index */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Brief summary of this index and the components it includes */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  latestSnapshot: MarketIndexSnapshotNodeObservableChain & {
    execute: (
      request: MarketIndexSnapshotNodeRequest,
      defaultValue?: MarketIndexSnapshotNode | null,
    ) => Observable<MarketIndexSnapshotNode | null>
  }
  snapshots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    name?: String | null
  }) => MarketIndexSnapshotNodeConnectionObservableChain & {
    execute: (
      request: MarketIndexSnapshotNodeConnectionRequest,
      defaultValue?: MarketIndexSnapshotNodeConnection,
    ) => Observable<MarketIndexSnapshotNodeConnection>
  }) &
    (MarketIndexSnapshotNodeConnectionObservableChain & {
      execute: (
        request: MarketIndexSnapshotNodeConnectionRequest,
        defaultValue?: MarketIndexSnapshotNodeConnection,
      ) => Observable<MarketIndexSnapshotNodeConnection>
    })
  components: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    sport?: String | null
  }) => MarketIndexComponentNodeConnectionObservableChain & {
    execute: (
      request: MarketIndexComponentNodeConnectionRequest,
      defaultValue?: MarketIndexComponentNodeConnection,
    ) => Observable<MarketIndexComponentNodeConnection>
  }) &
    (MarketIndexComponentNodeConnectionObservableChain & {
      execute: (
        request: MarketIndexComponentNodeConnectionRequest,
        defaultValue?: MarketIndexComponentNodeConnection,
      ) => Observable<MarketIndexComponentNodeConnection>
    })
}

export interface MarketIndexSnapshotNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  marketIndex: MarketIndexNodePromiseChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode) => Promise<MarketIndexNode>
  }
  /** The series this snapshot belongs to (e.g. weekly, daily...) */
  seriesName: {
    execute: (
      request?: boolean | number,
      defaultValue?: MarketIndexSnapshotSeriesName,
    ) => Promise<MarketIndexSnapshotSeriesName>
  }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  /** An optional friendly label to identify this snapshot, e.g. 'NFL Week 1' or 'Superbowl' */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  valueChange: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  valueChangePercentage: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  normalisedValueMultiplier: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  normalisedValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  normalisedValueChange: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  normalisedValueChangePercentage: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null>
  }
  marketindex: MarketIndexNodePromiseChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode | null) => Promise<MarketIndexNode | null>
  }
  componentSnapshots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => MarketIndexComponentSnapshotNodeConnectionPromiseChain & {
    execute: (
      request: MarketIndexComponentSnapshotNodeConnectionRequest,
      defaultValue?: MarketIndexComponentSnapshotNodeConnection,
    ) => Promise<MarketIndexComponentSnapshotNodeConnection>
  }) &
    (MarketIndexComponentSnapshotNodeConnectionPromiseChain & {
      execute: (
        request: MarketIndexComponentSnapshotNodeConnectionRequest,
        defaultValue?: MarketIndexComponentSnapshotNodeConnection,
      ) => Promise<MarketIndexComponentSnapshotNodeConnection>
    })
}

export interface MarketIndexSnapshotNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  marketIndex: MarketIndexNodeObservableChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode) => Observable<MarketIndexNode>
  }
  /** The series this snapshot belongs to (e.g. weekly, daily...) */
  seriesName: {
    execute: (
      request?: boolean | number,
      defaultValue?: MarketIndexSnapshotSeriesName,
    ) => Observable<MarketIndexSnapshotSeriesName>
  }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  /** An optional friendly label to identify this snapshot, e.g. 'NFL Week 1' or 'Superbowl' */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  valueChange: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  valueChangePercentage: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  normalisedValueMultiplier: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  normalisedValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  normalisedValueChange: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  normalisedValueChangePercentage: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  marketindex: MarketIndexNodeObservableChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode | null) => Observable<MarketIndexNode | null>
  }
  componentSnapshots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => MarketIndexComponentSnapshotNodeConnectionObservableChain & {
    execute: (
      request: MarketIndexComponentSnapshotNodeConnectionRequest,
      defaultValue?: MarketIndexComponentSnapshotNodeConnection,
    ) => Observable<MarketIndexComponentSnapshotNodeConnection>
  }) &
    (MarketIndexComponentSnapshotNodeConnectionObservableChain & {
      execute: (
        request: MarketIndexComponentSnapshotNodeConnectionRequest,
        defaultValue?: MarketIndexComponentSnapshotNodeConnection,
      ) => Observable<MarketIndexComponentSnapshotNodeConnection>
    })
}

export interface MarketIndexComponentSnapshotNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: MarketIndexComponentSnapshotNodeEdgeRequest,
      defaultValue?: (MarketIndexComponentSnapshotNodeEdge | null)[],
    ) => Promise<(MarketIndexComponentSnapshotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface MarketIndexComponentSnapshotNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: MarketIndexComponentSnapshotNodeEdgeRequest,
      defaultValue?: (MarketIndexComponentSnapshotNodeEdge | null)[],
    ) => Observable<(MarketIndexComponentSnapshotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `MarketIndexComponentSnapshotNode` and its cursor. */
export interface MarketIndexComponentSnapshotNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: MarketIndexComponentSnapshotNodePromiseChain & {
    execute: (
      request: MarketIndexComponentSnapshotNodeRequest,
      defaultValue?: MarketIndexComponentSnapshotNode | null,
    ) => Promise<MarketIndexComponentSnapshotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `MarketIndexComponentSnapshotNode` and its cursor. */
export interface MarketIndexComponentSnapshotNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: MarketIndexComponentSnapshotNodeObservableChain & {
    execute: (
      request: MarketIndexComponentSnapshotNodeRequest,
      defaultValue?: MarketIndexComponentSnapshotNode | null,
    ) => Observable<MarketIndexComponentSnapshotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface MarketIndexComponentSnapshotNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  marketIndexComponent: MarketIndexComponentNodePromiseChain & {
    execute: (
      request: MarketIndexComponentNodeRequest,
      defaultValue?: MarketIndexComponentNode,
    ) => Promise<MarketIndexComponentNode>
  }
  marketIndexSnapshot: MarketIndexSnapshotNodePromiseChain & {
    execute: (
      request: MarketIndexSnapshotNodeRequest,
      defaultValue?: MarketIndexSnapshotNode,
    ) => Promise<MarketIndexSnapshotNode>
  }
  /** Set None to hide fantasy status from display */
  fantasyStatus: {
    execute: (
      request?: boolean | number,
      defaultValue?: MarketIndexComponentSnapshotFantasyStatus,
    ) => Promise<MarketIndexComponentSnapshotFantasyStatus>
  }
  fantasyPoints: { execute: (request?: boolean | number, defaultValue?: Decimal | null) => Promise<Decimal | null> }
  priceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: MarketIndexComponentSnapshotPriceCurrency | null,
    ) => Promise<MarketIndexComponentSnapshotPriceCurrency | null>
  }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  priceChangeCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: MarketIndexComponentSnapshotPriceChangeCurrency | null,
    ) => Promise<MarketIndexComponentSnapshotPriceChangeCurrency | null>
  }
  priceChange: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  /** The percentage change of this price from the previous snapshot */
  priceChangePercentage: { execute: (request?: boolean | number, defaultValue?: Decimal | null) => Promise<Decimal | null> }
  marketindexcomponent: MarketIndexComponentNodePromiseChain & {
    execute: (
      request: MarketIndexComponentNodeRequest,
      defaultValue?: MarketIndexComponentNode | null,
    ) => Promise<MarketIndexComponentNode | null>
  }
}

export interface MarketIndexComponentSnapshotNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  marketIndexComponent: MarketIndexComponentNodeObservableChain & {
    execute: (
      request: MarketIndexComponentNodeRequest,
      defaultValue?: MarketIndexComponentNode,
    ) => Observable<MarketIndexComponentNode>
  }
  marketIndexSnapshot: MarketIndexSnapshotNodeObservableChain & {
    execute: (
      request: MarketIndexSnapshotNodeRequest,
      defaultValue?: MarketIndexSnapshotNode,
    ) => Observable<MarketIndexSnapshotNode>
  }
  /** Set None to hide fantasy status from display */
  fantasyStatus: {
    execute: (
      request?: boolean | number,
      defaultValue?: MarketIndexComponentSnapshotFantasyStatus,
    ) => Observable<MarketIndexComponentSnapshotFantasyStatus>
  }
  fantasyPoints: { execute: (request?: boolean | number, defaultValue?: Decimal | null) => Observable<Decimal | null> }
  priceCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: MarketIndexComponentSnapshotPriceCurrency | null,
    ) => Observable<MarketIndexComponentSnapshotPriceCurrency | null>
  }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  priceChangeCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: MarketIndexComponentSnapshotPriceChangeCurrency | null,
    ) => Observable<MarketIndexComponentSnapshotPriceChangeCurrency | null>
  }
  priceChange: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  /** The percentage change of this price from the previous snapshot */
  priceChangePercentage: {
    execute: (request?: boolean | number, defaultValue?: Decimal | null) => Observable<Decimal | null>
  }
  marketindexcomponent: MarketIndexComponentNodeObservableChain & {
    execute: (
      request: MarketIndexComponentNodeRequest,
      defaultValue?: MarketIndexComponentNode | null,
    ) => Observable<MarketIndexComponentNode | null>
  }
}

export interface MarketIndexSnapshotNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: MarketIndexSnapshotNodeEdgeRequest,
      defaultValue?: (MarketIndexSnapshotNodeEdge | null)[],
    ) => Promise<(MarketIndexSnapshotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface MarketIndexSnapshotNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: MarketIndexSnapshotNodeEdgeRequest,
      defaultValue?: (MarketIndexSnapshotNodeEdge | null)[],
    ) => Observable<(MarketIndexSnapshotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `MarketIndexSnapshotNode` and its cursor. */
export interface MarketIndexSnapshotNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: MarketIndexSnapshotNodePromiseChain & {
    execute: (
      request: MarketIndexSnapshotNodeRequest,
      defaultValue?: MarketIndexSnapshotNode | null,
    ) => Promise<MarketIndexSnapshotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `MarketIndexSnapshotNode` and its cursor. */
export interface MarketIndexSnapshotNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: MarketIndexSnapshotNodeObservableChain & {
    execute: (
      request: MarketIndexSnapshotNodeRequest,
      defaultValue?: MarketIndexSnapshotNode | null,
    ) => Observable<MarketIndexSnapshotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BoxBreakPhotoNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BoxBreakPhotoNodeEdgeRequest,
      defaultValue?: (BoxBreakPhotoNodeEdge | null)[],
    ) => Promise<(BoxBreakPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface BoxBreakPhotoNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BoxBreakPhotoNodeEdgeRequest,
      defaultValue?: (BoxBreakPhotoNodeEdge | null)[],
    ) => Observable<(BoxBreakPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `BoxBreakPhotoNode` and its cursor. */
export interface BoxBreakPhotoNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: BoxBreakPhotoNodePromiseChain & {
    execute: (
      request: BoxBreakPhotoNodeRequest,
      defaultValue?: BoxBreakPhotoNode | null,
    ) => Promise<BoxBreakPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `BoxBreakPhotoNode` and its cursor. */
export interface BoxBreakPhotoNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: BoxBreakPhotoNodeObservableChain & {
    execute: (
      request: BoxBreakPhotoNodeRequest,
      defaultValue?: BoxBreakPhotoNode | null,
    ) => Observable<BoxBreakPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BoxBreakPhotoNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Promise<BoxBreakNode>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface BoxBreakPhotoNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Observable<BoxBreakNode>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface RemintNFTNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: RemintNFTNodeEdgeRequest,
      defaultValue?: (RemintNFTNodeEdge | null)[],
    ) => Promise<(RemintNFTNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface RemintNFTNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: RemintNFTNodeEdgeRequest,
      defaultValue?: (RemintNFTNodeEdge | null)[],
    ) => Observable<(RemintNFTNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `RemintNFTNode` and its cursor. */
export interface RemintNFTNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: RemintNFTNodePromiseChain & {
    execute: (request: RemintNFTNodeRequest, defaultValue?: RemintNFTNode | null) => Promise<RemintNFTNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `RemintNFTNode` and its cursor. */
export interface RemintNFTNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: RemintNFTNodeObservableChain & {
    execute: (request: RemintNFTNodeRequest, defaultValue?: RemintNFTNode | null) => Observable<RemintNFTNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface RemintNFTNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Display name for this nft item. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Display name for the collection this nft item belongs to. */
  collectionName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  blockchain: { execute: (request?: boolean | number, defaultValue?: RemintNFTBlockchain) => Promise<RemintNFTBlockchain> }
  /** Optional Link to external URL. */
  externalLink: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Optional Name of external URL page. */
  externalLinkText: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  image: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Break in which this nft item is going to be or was opened. */
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }
}

export interface RemintNFTNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Display name for this nft item. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Display name for the collection this nft item belongs to. */
  collectionName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  blockchain: {
    execute: (request?: boolean | number, defaultValue?: RemintNFTBlockchain) => Observable<RemintNFTBlockchain>
  }
  /** Optional Link to external URL. */
  externalLink: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Optional Name of external URL page. */
  externalLinkText: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  image: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Break in which this nft item is going to be or was opened. */
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }
}

export interface PlayerNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: PlayerNodeEdgeRequest, defaultValue?: (PlayerNodeEdge | null)[]) => Promise<(PlayerNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface PlayerNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: PlayerNodeEdgeRequest,
      defaultValue?: (PlayerNodeEdge | null)[],
    ) => Observable<(PlayerNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `PlayerNode` and its cursor. */
export interface PlayerNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: PlayerNodePromiseChain & {
    execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode | null) => Promise<PlayerNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `PlayerNode` and its cursor. */
export interface PlayerNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: PlayerNodeObservableChain & {
    execute: (request: PlayerNodeRequest, defaultValue?: PlayerNode | null) => Observable<PlayerNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface StreamNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: StreamNodeEdgeRequest, defaultValue?: (StreamNodeEdge | null)[]) => Promise<(StreamNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface StreamNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: StreamNodeEdgeRequest,
      defaultValue?: (StreamNodeEdge | null)[],
    ) => Observable<(StreamNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `StreamNode` and its cursor. */
export interface StreamNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `StreamNode` and its cursor. */
export interface StreamNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface ShippingRequestNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ShippingRequestNodeEdgeRequest,
      defaultValue?: (ShippingRequestNodeEdge | null)[],
    ) => Promise<(ShippingRequestNodeEdge | null)[]>
  }
}

export interface ShippingRequestNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ShippingRequestNodeEdgeRequest,
      defaultValue?: (ShippingRequestNodeEdge | null)[],
    ) => Observable<(ShippingRequestNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `ShippingRequestNode` and its cursor. */
export interface ShippingRequestNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: ShippingRequestNodePromiseChain & {
    execute: (
      request: ShippingRequestNodeRequest,
      defaultValue?: ShippingRequestNode | null,
    ) => Promise<ShippingRequestNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `ShippingRequestNode` and its cursor. */
export interface ShippingRequestNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: ShippingRequestNodeObservableChain & {
    execute: (
      request: ShippingRequestNodeRequest,
      defaultValue?: ShippingRequestNode | null,
    ) => Observable<ShippingRequestNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface FixtureNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: FixtureNodeEdgeRequest,
      defaultValue?: (FixtureNodeEdge | null)[],
    ) => Promise<(FixtureNodeEdge | null)[]>
  }
}

export interface FixtureNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: FixtureNodeEdgeRequest,
      defaultValue?: (FixtureNodeEdge | null)[],
    ) => Observable<(FixtureNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `FixtureNode` and its cursor. */
export interface FixtureNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: FixtureNodePromiseChain & {
    execute: (request: FixtureNodeRequest, defaultValue?: FixtureNode | null) => Promise<FixtureNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `FixtureNode` and its cursor. */
export interface FixtureNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: FixtureNodeObservableChain & {
    execute: (request: FixtureNodeRequest, defaultValue?: FixtureNode | null) => Observable<FixtureNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface UserNotificationPreferenceNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  notificationType: {
    execute: (
      request?: boolean | number,
      defaultValue?: UserNotificationPreferenceNotificationType,
    ) => Promise<UserNotificationPreferenceNotificationType>
  }
  targetType: {
    execute: (
      request?: boolean | number,
      defaultValue?: UserNotificationPreferenceTargetType,
    ) => Promise<UserNotificationPreferenceTargetType>
  }
}

export interface UserNotificationPreferenceNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  notificationType: {
    execute: (
      request?: boolean | number,
      defaultValue?: UserNotificationPreferenceNotificationType,
    ) => Observable<UserNotificationPreferenceNotificationType>
  }
  targetType: {
    execute: (
      request?: boolean | number,
      defaultValue?: UserNotificationPreferenceTargetType,
    ) => Observable<UserNotificationPreferenceTargetType>
  }
}

export interface RecentSaleNodePromiseChain {
  cardParallel: CardParallelNodePromiseChain & {
    execute: (request: CardParallelNodeRequest, defaultValue?: CardParallelNode | null) => Promise<CardParallelNode | null>
  }
  grade: GradeNodePromiseChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Promise<GradeNode | null>
  }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  saleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  quantity: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface RecentSaleNodeObservableChain {
  cardParallel: CardParallelNodeObservableChain & {
    execute: (
      request: CardParallelNodeRequest,
      defaultValue?: CardParallelNode | null,
    ) => Observable<CardParallelNode | null>
  }
  grade: GradeNodeObservableChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Observable<GradeNode | null>
  }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  saleDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  quantity: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

export interface ItemOwnershipNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ItemOwnershipNodeEdgeRequest,
      defaultValue?: (ItemOwnershipNodeEdge | null)[],
    ) => Promise<(ItemOwnershipNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface ItemOwnershipNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ItemOwnershipNodeEdgeRequest,
      defaultValue?: (ItemOwnershipNodeEdge | null)[],
    ) => Observable<(ItemOwnershipNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `ItemOwnershipNode` and its cursor. */
export interface ItemOwnershipNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: ItemOwnershipNodePromiseChain & {
    execute: (
      request: ItemOwnershipNodeRequest,
      defaultValue?: ItemOwnershipNode | null,
    ) => Promise<ItemOwnershipNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `ItemOwnershipNode` and its cursor. */
export interface ItemOwnershipNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: ItemOwnershipNodeObservableChain & {
    execute: (
      request: ItemOwnershipNodeRequest,
      defaultValue?: ItemOwnershipNode | null,
    ) => Observable<ItemOwnershipNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface GroupedBidNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: GroupedBidNodeEdgeRequest,
      defaultValue?: (GroupedBidNodeEdge | null)[],
    ) => Promise<(GroupedBidNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface GroupedBidNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: GroupedBidNodeEdgeRequest,
      defaultValue?: (GroupedBidNodeEdge | null)[],
    ) => Observable<(GroupedBidNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `GroupedBidNode` and its cursor. */
export interface GroupedBidNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: GroupedBidNodePromiseChain & {
    execute: (request: GroupedBidNodeRequest, defaultValue?: GroupedBidNode | null) => Promise<GroupedBidNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `GroupedBidNode` and its cursor. */
export interface GroupedBidNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: GroupedBidNodeObservableChain & {
    execute: (request: GroupedBidNodeRequest, defaultValue?: GroupedBidNode | null) => Observable<GroupedBidNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface GroupedListingNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: GroupedListingNodeEdgeRequest,
      defaultValue?: (GroupedListingNodeEdge | null)[],
    ) => Promise<(GroupedListingNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface GroupedListingNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: GroupedListingNodeEdgeRequest,
      defaultValue?: (GroupedListingNodeEdge | null)[],
    ) => Observable<(GroupedListingNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `GroupedListingNode` and its cursor. */
export interface GroupedListingNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: GroupedListingNodePromiseChain & {
    execute: (
      request: GroupedListingNodeRequest,
      defaultValue?: GroupedListingNode | null,
    ) => Promise<GroupedListingNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `GroupedListingNode` and its cursor. */
export interface GroupedListingNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: GroupedListingNodeObservableChain & {
    execute: (
      request: GroupedListingNodeRequest,
      defaultValue?: GroupedListingNode | null,
    ) => Observable<GroupedListingNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface MarketIndexNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: MarketIndexNodeEdgeRequest,
      defaultValue?: (MarketIndexNodeEdge | null)[],
    ) => Promise<(MarketIndexNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface MarketIndexNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: MarketIndexNodeEdgeRequest,
      defaultValue?: (MarketIndexNodeEdge | null)[],
    ) => Observable<(MarketIndexNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `MarketIndexNode` and its cursor. */
export interface MarketIndexNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: MarketIndexNodePromiseChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode | null) => Promise<MarketIndexNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `MarketIndexNode` and its cursor. */
export interface MarketIndexNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: MarketIndexNodeObservableChain & {
    execute: (request: MarketIndexNodeRequest, defaultValue?: MarketIndexNode | null) => Observable<MarketIndexNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface MarketWidgetNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  title: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  subtitle: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  rank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  active: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  featuredResultKey: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  resultSetOrderBy: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface MarketWidgetNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  title: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  subtitle: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  rank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  active: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  featuredResultKey: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  resultSetOrderBy: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface GradeNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: GradeNodeEdgeRequest, defaultValue?: (GradeNodeEdge | null)[]) => Promise<(GradeNodeEdge | null)[]>
  }
}

export interface GradeNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: GradeNodeEdgeRequest, defaultValue?: (GradeNodeEdge | null)[]) => Observable<(GradeNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `GradeNode` and its cursor. */
export interface GradeNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: GradeNodePromiseChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Promise<GradeNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `GradeNode` and its cursor. */
export interface GradeNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: GradeNodeObservableChain & {
    execute: (request: GradeNodeRequest, defaultValue?: GradeNode | null) => Observable<GradeNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface PortfolioValueNodePromiseChain {
  openDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  closeDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  closeItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  closeSubmittedItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  closePurchasedItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  closeUnvaluedItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  closeValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  closeInvested: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  closeRoiValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  closeRoiPercent: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface PortfolioValueNodeObservableChain {
  openDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  closeDate: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  closeItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  closeSubmittedItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  closePurchasedItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  closeUnvaluedItemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  closeValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  closeInvested: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  closeRoiValue: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  closeRoiPercent: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface CardSetNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardSetNodeEdgeRequest,
      defaultValue?: (CardSetNodeEdge | null)[],
    ) => Promise<(CardSetNodeEdge | null)[]>
  }
  cardParallelCounts: {
    execute: (
      request: CardSetCountsRequest,
      defaultValue?: (CardSetCounts | null)[] | null,
    ) => Promise<(CardSetCounts | null)[] | null>
  }
  myCardParallelCounts: {
    execute: (
      request: CardSetCountsRequest,
      defaultValue?: (CardSetCounts | null)[] | null,
    ) => Promise<(CardSetCounts | null)[] | null>
  }
}

export interface CardSetNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: CardSetNodeEdgeRequest,
      defaultValue?: (CardSetNodeEdge | null)[],
    ) => Observable<(CardSetNodeEdge | null)[]>
  }
  cardParallelCounts: {
    execute: (
      request: CardSetCountsRequest,
      defaultValue?: (CardSetCounts | null)[] | null,
    ) => Observable<(CardSetCounts | null)[] | null>
  }
  myCardParallelCounts: {
    execute: (
      request: CardSetCountsRequest,
      defaultValue?: (CardSetCounts | null)[] | null,
    ) => Observable<(CardSetCounts | null)[] | null>
  }
}

/** A Relay edge containing a `CardSetNode` and its cursor. */
export interface CardSetNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: CardSetNodePromiseChain & {
    execute: (request: CardSetNodeRequest, defaultValue?: CardSetNode | null) => Promise<CardSetNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `CardSetNode` and its cursor. */
export interface CardSetNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: CardSetNodeObservableChain & {
    execute: (request: CardSetNodeRequest, defaultValue?: CardSetNode | null) => Observable<CardSetNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CardSetCountsPromiseChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  company: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  parallelCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface CardSetCountsObservableChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  company: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  parallelCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

export interface AuctionNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AuctionNodeEdgeRequest,
      defaultValue?: (AuctionNodeEdge | null)[],
    ) => Promise<(AuctionNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface AuctionNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AuctionNodeEdgeRequest,
      defaultValue?: (AuctionNodeEdge | null)[],
    ) => Observable<(AuctionNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `AuctionNode` and its cursor. */
export interface AuctionNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: AuctionNodePromiseChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Promise<AuctionNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `AuctionNode` and its cursor. */
export interface AuctionNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: AuctionNodeObservableChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Observable<AuctionNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface AnnouncementNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  state: {
    execute: (request?: boolean | number, defaultValue?: AnnouncementBannerState) => Promise<AnnouncementBannerState>
  }
  /** Automatically generated. Example: 2021-10-16-submit-for-grading-update */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Example: Submit for grading update */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Text that will be highlighted in the announcement banner  */
  prefixText: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** Announcement body content */
  bodyText: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Link title */
  linkTitle: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL linking to the announced target */
  linkUrl: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  style: AnnouncementStyleNodePromiseChain & {
    execute: (request: AnnouncementStyleNodeRequest, defaultValue?: AnnouncementStyleNode) => Promise<AnnouncementStyleNode>
  }
}

export interface AnnouncementNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  state: {
    execute: (request?: boolean | number, defaultValue?: AnnouncementBannerState) => Observable<AnnouncementBannerState>
  }
  /** Automatically generated. Example: 2021-10-16-submit-for-grading-update */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Example: Submit for grading update */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Text that will be highlighted in the announcement banner  */
  prefixText: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** Announcement body content */
  bodyText: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Link title */
  linkTitle: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL linking to the announced target */
  linkUrl: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  style: AnnouncementStyleNodeObservableChain & {
    execute: (
      request: AnnouncementStyleNodeRequest,
      defaultValue?: AnnouncementStyleNode,
    ) => Observable<AnnouncementStyleNode>
  }
}

export interface AnnouncementStyleNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Examples: Breaks, Product announcements, Technical announcements, Marketing, etc. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  badgeTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  linkTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  badgeGradientStart: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  badgeGradientEnd: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  announcementbannerSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => AnnouncementNodeConnectionPromiseChain & {
    execute: (
      request: AnnouncementNodeConnectionRequest,
      defaultValue?: AnnouncementNodeConnection,
    ) => Promise<AnnouncementNodeConnection>
  }) &
    (AnnouncementNodeConnectionPromiseChain & {
      execute: (
        request: AnnouncementNodeConnectionRequest,
        defaultValue?: AnnouncementNodeConnection,
      ) => Promise<AnnouncementNodeConnection>
    })
}

export interface AnnouncementStyleNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Examples: Breaks, Product announcements, Technical announcements, Marketing, etc. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  badgeTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  linkTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  badgeGradientStart: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  badgeGradientEnd: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  announcementbannerSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => AnnouncementNodeConnectionObservableChain & {
    execute: (
      request: AnnouncementNodeConnectionRequest,
      defaultValue?: AnnouncementNodeConnection,
    ) => Observable<AnnouncementNodeConnection>
  }) &
    (AnnouncementNodeConnectionObservableChain & {
      execute: (
        request: AnnouncementNodeConnectionRequest,
        defaultValue?: AnnouncementNodeConnection,
      ) => Observable<AnnouncementNodeConnection>
    })
}

export interface AnnouncementNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AnnouncementNodeEdgeRequest,
      defaultValue?: (AnnouncementNodeEdge | null)[],
    ) => Promise<(AnnouncementNodeEdge | null)[]>
  }
}

export interface AnnouncementNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AnnouncementNodeEdgeRequest,
      defaultValue?: (AnnouncementNodeEdge | null)[],
    ) => Observable<(AnnouncementNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `AnnouncementNode` and its cursor. */
export interface AnnouncementNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: AnnouncementNodePromiseChain & {
    execute: (request: AnnouncementNodeRequest, defaultValue?: AnnouncementNode | null) => Promise<AnnouncementNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `AnnouncementNode` and its cursor. */
export interface AnnouncementNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: AnnouncementNodeObservableChain & {
    execute: (
      request: AnnouncementNodeRequest,
      defaultValue?: AnnouncementNode | null,
    ) => Observable<AnnouncementNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface PromotionTypePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  description: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface PromotionTypeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  description: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface MutationPromiseChain {
  submitDraftShippingRequest: (args: {
    addressLine1: String
    addressLine2?: String | null
    city: String
    country: String
    recipient: String
    state: String
    zipCode: String
  }) => SubmitDraftShippingRequestPromiseChain & {
    execute: (
      request: SubmitDraftShippingRequestRequest,
      defaultValue?: SubmitDraftShippingRequest | null,
    ) => Promise<SubmitDraftShippingRequest | null>
  }
  addItemsToDraftShippingRequest: (args: {
    itemsToAdd: (String | null)[]
  }) => AddItemsToDraftShippingRequestPromiseChain & {
    execute: (
      request: AddItemsToDraftShippingRequestRequest,
      defaultValue?: AddItemsToDraftShippingRequest | null,
    ) => Promise<AddItemsToDraftShippingRequest | null>
  }
  removeItemsFromDraftShippingRequest: (args: {
    itemsToRemove: (String | null)[]
  }) => RemoveItemsFromDraftShippingRequestPromiseChain & {
    execute: (
      request: RemoveItemsFromDraftShippingRequestRequest,
      defaultValue?: RemoveItemsFromDraftShippingRequest | null,
    ) => Promise<RemoveItemsFromDraftShippingRequest | null>
  }
  addAllItemsToDraftShippingRequest: AddAllItemsToDraftShippingRequestPromiseChain & {
    execute: (
      request: AddAllItemsToDraftShippingRequestRequest,
      defaultValue?: AddAllItemsToDraftShippingRequest | null,
    ) => Promise<AddAllItemsToDraftShippingRequest | null>
  }
  removeAllItemsFromDraftShippingRequest: RemoveAllItemsFromDraftShippingRequestPromiseChain & {
    execute: (
      request: RemoveAllItemsFromDraftShippingRequestRequest,
      defaultValue?: RemoveAllItemsFromDraftShippingRequest | null,
    ) => Promise<RemoveAllItemsFromDraftShippingRequest | null>
  }
  createPaymentIntent: (args: {
    amount: Int
    saveCard?: Boolean | null
  }) => CreatePaymentIntentPromiseChain & {
    execute: (
      request: CreatePaymentIntentRequest,
      defaultValue?: CreatePaymentIntent | null,
    ) => Promise<CreatePaymentIntent | null>
  }
  createWithdrawalRequest: (args: {
    addressLine1?: String | null
    addressLine2?: String | null
    amount: Float
    checkRecipient?: String | null
    city?: String | null
    country?: String | null
    paymentMethod: WithdrawalPaymentMethods
    paypalEmail?: String | null
    postalTracking?: Boolean | null
    state?: String | null
    zipCode?: String | null
  }) => CreateWithdrawalRequestPromiseChain & {
    execute: (
      request: CreateWithdrawalRequestRequest,
      defaultValue?: CreateWithdrawalRequest | null,
    ) => Promise<CreateWithdrawalRequest | null>
  }
  deletePaymentMethod: ((args?: {
    paymentMethodId?: String | null
  }) => DeletePaymentMethodPromiseChain & {
    execute: (
      request: DeletePaymentMethodRequest,
      defaultValue?: DeletePaymentMethod | null,
    ) => Promise<DeletePaymentMethod | null>
  }) &
    (DeletePaymentMethodPromiseChain & {
      execute: (
        request: DeletePaymentMethodRequest,
        defaultValue?: DeletePaymentMethod | null,
      ) => Promise<DeletePaymentMethod | null>
    })
  setDefaultPaymentMethod: ((args?: {
    paymentMethodId?: String | null
  }) => SetDefaultPaymentMethodPromiseChain & {
    execute: (
      request: SetDefaultPaymentMethodRequest,
      defaultValue?: SetDefaultPaymentMethod | null,
    ) => Promise<SetDefaultPaymentMethod | null>
  }) &
    (SetDefaultPaymentMethodPromiseChain & {
      execute: (
        request: SetDefaultPaymentMethodRequest,
        defaultValue?: SetDefaultPaymentMethod | null,
      ) => Promise<SetDefaultPaymentMethod | null>
    })
  createSetupIntent: CreateSetupIntentPromiseChain & {
    execute: (
      request: CreateSetupIntentRequest,
      defaultValue?: CreateSetupIntent | null,
    ) => Promise<CreateSetupIntent | null>
  }
  updateCryptoWalletBalance: (args: {
    balanceAmount: Float
    balanceCurrency: CryptoCurrencies
    cryptoWalletId: String
  }) => UpdateCryptoWalletBalancePromiseChain & {
    execute: (
      request: UpdateCryptoWalletBalanceRequest,
      defaultValue?: UpdateCryptoWalletBalance | null,
    ) => Promise<UpdateCryptoWalletBalance | null>
  }
  withdrawalConfirmation: ((args?: {
    token?: String | null
    userIdB64?: String | null
    withdrawalRequestId?: String | null
  }) => WithdrawalConfirmationPromiseChain & {
    execute: (
      request: WithdrawalConfirmationRequest,
      defaultValue?: WithdrawalConfirmation | null,
    ) => Promise<WithdrawalConfirmation | null>
  }) &
    (WithdrawalConfirmationPromiseChain & {
      execute: (
        request: WithdrawalConfirmationRequest,
        defaultValue?: WithdrawalConfirmation | null,
      ) => Promise<WithdrawalConfirmation | null>
    })
  updateNotificationPreferences: ((args?: {
    buyTargetPercentage?: Int | null
    notificationPreferences?: (UpdateNotificationPreferencesInput | null)[] | null
    sellTargetPercentage?: Int | null
  }) => UpdateNotificationPreferencesPromiseChain & {
    execute: (
      request: UpdateNotificationPreferencesRequest,
      defaultValue?: UpdateNotificationPreferences | null,
    ) => Promise<UpdateNotificationPreferences | null>
  }) &
    (UpdateNotificationPreferencesPromiseChain & {
      execute: (
        request: UpdateNotificationPreferencesRequest,
        defaultValue?: UpdateNotificationPreferences | null,
      ) => Promise<UpdateNotificationPreferences | null>
    })
  groupedBidClose: (args: {
    allBids?: Boolean | null
    id: ID
  }) => GroupedBidClosePromiseChain & {
    execute: (request: GroupedBidCloseRequest, defaultValue?: GroupedBidClose | null) => Promise<GroupedBidClose | null>
  }
  groupedListingClose: (args: {
    allListings?: Boolean | null
    id: ID
  }) => GroupedListingClosePromiseChain & {
    execute: (
      request: GroupedListingCloseRequest,
      defaultValue?: GroupedListingClose | null,
    ) => Promise<GroupedListingClose | null>
  }
  sellItems: (args: {
    cardParallelId: ID
    closeSellOrders?: Boolean | null
    expiresAfter?: ExpiresAfter | null
    gradeId: ID
    minimumSalePrice: Float
    quantity: Int
  }) => SellItemsPromiseChain & {
    execute: (request: SellItemsRequest, defaultValue?: SellItems | null) => Promise<SellItems | null>
  }
  buyItems: (args: {
    affiliateCode?: String | null
    cancelBidsPriced?: Float | null
    cardParallelId: ID
    expiresAfter?: ExpiresAfter | null
    gradeId: ID
    maximumBuyPrice: Float
    quantity: Int
  }) => BuyItemsPromiseChain & {
    execute: (request: BuyItemsRequest, defaultValue?: BuyItems | null) => Promise<BuyItems | null>
  }
  buyOrderExtendExpiryDate: (args: {
    extendExpiry: ExpiresAfter
    groupedBidId: ID
  }) => BuyOrderExtendExpiryDatePromiseChain & {
    execute: (
      request: BuyOrderExtendExpiryDateRequest,
      defaultValue?: BuyOrderExtendExpiryDate | null,
    ) => Promise<BuyOrderExtendExpiryDate | null>
  }
  sellOrderExtendExpiryDate: (args: {
    extendExpiry: ExpiresAfter
    groupedListingId: ID
  }) => SellOrderExtendExpiryDatePromiseChain & {
    execute: (
      request: SellOrderExtendExpiryDateRequest,
      defaultValue?: SellOrderExtendExpiryDate | null,
    ) => Promise<SellOrderExtendExpiryDate | null>
  }
  wishlistItemCreate: (args: {
    cardParallelId: ID
  }) => WishlistItemCreatePromiseChain & {
    execute: (
      request: WishlistItemCreateRequest,
      defaultValue?: WishlistItemCreate | null,
    ) => Promise<WishlistItemCreate | null>
  }
  wishlistItemDelete: (args: {
    id: ID
  }) => WishlistItemDeletePromiseChain & {
    execute: (
      request: WishlistItemDeleteRequest,
      defaultValue?: WishlistItemDelete | null,
    ) => Promise<WishlistItemDelete | null>
  }
  sendUserOwnershipsCsv: SendUserOwnershipsCsvPromiseChain & {
    execute: (
      request: SendUserOwnershipsCsvRequest,
      defaultValue?: SendUserOwnershipsCsv | null,
    ) => Promise<SendUserOwnershipsCsv | null>
  }
  buyBoxBreakSpots: (args: {
    additionalInstructions?: String | null
    addressId: ID
    boxBreakId: ID
    paymentMethod: String
    preferShipHome?: Boolean | null
    spotIds: (ID | null)[]
  }) => BuyBoxBreakSpotsPromiseChain & {
    execute: (request: BuyBoxBreakSpotsRequest, defaultValue?: BuyBoxBreakSpots | null) => Promise<BuyBoxBreakSpots | null>
  }
  personalBreakRequestCreate: (args: {
    additionalInstructions?: String | null
    addressId: ID
    boxBreakId: ID
    itemOwnershipId: ID
    preferShipHome?: Boolean | null
    requestDetails?: String | null
  }) => PersonalBreakRequestCreatePromiseChain & {
    execute: (
      request: PersonalBreakRequestCreateRequest,
      defaultValue?: PersonalBreakRequestCreate | null,
    ) => Promise<PersonalBreakRequestCreate | null>
  }
  bidCreate: (args: {
    bidCurrency?: String | null
    bidPrice: Float
    lotId: ID
  }) => BidCreatePromiseChain & {
    execute: (request: BidCreateRequest, defaultValue?: BidCreate | null) => Promise<BidCreate | null>
  }
  /** Create a brand new address for the logged-in user. */
  createAddress: (args: {
    addressLines: String
    country: String
    description: String
    locality: String
    name: String
    postalCode: String
    region: String
    role: String
  }) => CreateAddressPromiseChain & {
    execute: (request: CreateAddressRequest, defaultValue?: CreateAddress | null) => Promise<CreateAddress | null>
  }
  /** Hide a specified address for the logged-in user. */
  deleteAddress: (args: {
    id: ID
  }) => DeleteAddressPromiseChain & {
    execute: (request: DeleteAddressRequest, defaultValue?: DeleteAddress | null) => Promise<DeleteAddress | null>
  }
  /** Hide the existing address and create a replacement with the specified changes. */
  updateAddress: (args: {
    addressLines: String
    country: String
    description: String
    id: ID
    locality: String
    name: String
    postalCode: String
    region: String
    role: String
  }) => UpdateAddressPromiseChain & {
    execute: (request: UpdateAddressRequest, defaultValue?: UpdateAddress | null) => Promise<UpdateAddress | null>
  }
  /** Mark an existing address as the default. */
  makeAddressDefault: (args: {
    id: ID
  }) => MakeAddressDefaultPromiseChain & {
    execute: (
      request: MakeAddressDefaultRequest,
      defaultValue?: MakeAddressDefault | null,
    ) => Promise<MakeAddressDefault | null>
  }
  tokenAuth: (args: {
    username: String
    password: String
  }) => ObtainJSONWebTokenPromiseChain & {
    execute: (
      request: ObtainJSONWebTokenRequest,
      defaultValue?: ObtainJSONWebToken | null,
    ) => Promise<ObtainJSONWebToken | null>
  }
  userCreate: (args: {
    email: String
    firstName: String
    lastName: String
    marketingEmails: Boolean
    password: String
    promoCode?: String | null
    username: String
  }) => UserCreatePromiseChain & {
    execute: (request: UserCreateRequest, defaultValue?: UserCreate | null) => Promise<UserCreate | null>
  }
  passwordChange: ((args?: {
    newPassword?: String | null
    oldPassword?: String | null
  }) => PasswordChangePromiseChain & {
    execute: (request: PasswordChangeRequest, defaultValue?: PasswordChange | null) => Promise<PasswordChange | null>
  }) &
    (PasswordChangePromiseChain & {
      execute: (request: PasswordChangeRequest, defaultValue?: PasswordChange | null) => Promise<PasswordChange | null>
    })
  passwordResetRequest: ((args?: {
    username?: String | null
  }) => PasswordResetRequestPromiseChain & {
    execute: (
      request: PasswordResetRequestRequest,
      defaultValue?: PasswordResetRequest | null,
    ) => Promise<PasswordResetRequest | null>
  }) &
    (PasswordResetRequestPromiseChain & {
      execute: (
        request: PasswordResetRequestRequest,
        defaultValue?: PasswordResetRequest | null,
      ) => Promise<PasswordResetRequest | null>
    })
  passwordResetChange: ((args?: {
    newPassword?: String | null
    token?: String | null
    userIdB64?: String | null
  }) => PasswordResetChangePromiseChain & {
    execute: (
      request: PasswordResetChangeRequest,
      defaultValue?: PasswordResetChange | null,
    ) => Promise<PasswordResetChange | null>
  }) &
    (PasswordResetChangePromiseChain & {
      execute: (
        request: PasswordResetChangeRequest,
        defaultValue?: PasswordResetChange | null,
      ) => Promise<PasswordResetChange | null>
    })
  updateUserCollectionVisibility: (args: {
    isPublic: Boolean
  }) => UpdateUserCollectionVisibilityPromiseChain & {
    execute: (
      request: UpdateUserCollectionVisibilityRequest,
      defaultValue?: UpdateUserCollectionVisibility | null,
    ) => Promise<UpdateUserCollectionVisibility | null>
  }
  verifyToken: ((args?: {
    token?: String | null
  }) => VerifyPromiseChain & { execute: (request: VerifyRequest, defaultValue?: Verify | null) => Promise<Verify | null> }) &
    (VerifyPromiseChain & { execute: (request: VerifyRequest, defaultValue?: Verify | null) => Promise<Verify | null> })
  refreshToken: ((args?: {
    refreshToken?: String | null
  }) => RefreshPromiseChain & {
    execute: (request: RefreshRequest, defaultValue?: Refresh | null) => Promise<Refresh | null>
  }) &
    (RefreshPromiseChain & { execute: (request: RefreshRequest, defaultValue?: Refresh | null) => Promise<Refresh | null> })
  deleteTokenCookie: DeleteJSONWebTokenCookiePromiseChain & {
    execute: (
      request: DeleteJSONWebTokenCookieRequest,
      defaultValue?: DeleteJSONWebTokenCookie | null,
    ) => Promise<DeleteJSONWebTokenCookie | null>
  }
  deleteRefreshTokenCookie: DeleteRefreshTokenCookiePromiseChain & {
    execute: (
      request: DeleteRefreshTokenCookieRequest,
      defaultValue?: DeleteRefreshTokenCookie | null,
    ) => Promise<DeleteRefreshTokenCookie | null>
  }
}

export interface MutationObservableChain {
  submitDraftShippingRequest: (args: {
    addressLine1: String
    addressLine2?: String | null
    city: String
    country: String
    recipient: String
    state: String
    zipCode: String
  }) => SubmitDraftShippingRequestObservableChain & {
    execute: (
      request: SubmitDraftShippingRequestRequest,
      defaultValue?: SubmitDraftShippingRequest | null,
    ) => Observable<SubmitDraftShippingRequest | null>
  }
  addItemsToDraftShippingRequest: (args: {
    itemsToAdd: (String | null)[]
  }) => AddItemsToDraftShippingRequestObservableChain & {
    execute: (
      request: AddItemsToDraftShippingRequestRequest,
      defaultValue?: AddItemsToDraftShippingRequest | null,
    ) => Observable<AddItemsToDraftShippingRequest | null>
  }
  removeItemsFromDraftShippingRequest: (args: {
    itemsToRemove: (String | null)[]
  }) => RemoveItemsFromDraftShippingRequestObservableChain & {
    execute: (
      request: RemoveItemsFromDraftShippingRequestRequest,
      defaultValue?: RemoveItemsFromDraftShippingRequest | null,
    ) => Observable<RemoveItemsFromDraftShippingRequest | null>
  }
  addAllItemsToDraftShippingRequest: AddAllItemsToDraftShippingRequestObservableChain & {
    execute: (
      request: AddAllItemsToDraftShippingRequestRequest,
      defaultValue?: AddAllItemsToDraftShippingRequest | null,
    ) => Observable<AddAllItemsToDraftShippingRequest | null>
  }
  removeAllItemsFromDraftShippingRequest: RemoveAllItemsFromDraftShippingRequestObservableChain & {
    execute: (
      request: RemoveAllItemsFromDraftShippingRequestRequest,
      defaultValue?: RemoveAllItemsFromDraftShippingRequest | null,
    ) => Observable<RemoveAllItemsFromDraftShippingRequest | null>
  }
  createPaymentIntent: (args: {
    amount: Int
    saveCard?: Boolean | null
  }) => CreatePaymentIntentObservableChain & {
    execute: (
      request: CreatePaymentIntentRequest,
      defaultValue?: CreatePaymentIntent | null,
    ) => Observable<CreatePaymentIntent | null>
  }
  createWithdrawalRequest: (args: {
    addressLine1?: String | null
    addressLine2?: String | null
    amount: Float
    checkRecipient?: String | null
    city?: String | null
    country?: String | null
    paymentMethod: WithdrawalPaymentMethods
    paypalEmail?: String | null
    postalTracking?: Boolean | null
    state?: String | null
    zipCode?: String | null
  }) => CreateWithdrawalRequestObservableChain & {
    execute: (
      request: CreateWithdrawalRequestRequest,
      defaultValue?: CreateWithdrawalRequest | null,
    ) => Observable<CreateWithdrawalRequest | null>
  }
  deletePaymentMethod: ((args?: {
    paymentMethodId?: String | null
  }) => DeletePaymentMethodObservableChain & {
    execute: (
      request: DeletePaymentMethodRequest,
      defaultValue?: DeletePaymentMethod | null,
    ) => Observable<DeletePaymentMethod | null>
  }) &
    (DeletePaymentMethodObservableChain & {
      execute: (
        request: DeletePaymentMethodRequest,
        defaultValue?: DeletePaymentMethod | null,
      ) => Observable<DeletePaymentMethod | null>
    })
  setDefaultPaymentMethod: ((args?: {
    paymentMethodId?: String | null
  }) => SetDefaultPaymentMethodObservableChain & {
    execute: (
      request: SetDefaultPaymentMethodRequest,
      defaultValue?: SetDefaultPaymentMethod | null,
    ) => Observable<SetDefaultPaymentMethod | null>
  }) &
    (SetDefaultPaymentMethodObservableChain & {
      execute: (
        request: SetDefaultPaymentMethodRequest,
        defaultValue?: SetDefaultPaymentMethod | null,
      ) => Observable<SetDefaultPaymentMethod | null>
    })
  createSetupIntent: CreateSetupIntentObservableChain & {
    execute: (
      request: CreateSetupIntentRequest,
      defaultValue?: CreateSetupIntent | null,
    ) => Observable<CreateSetupIntent | null>
  }
  updateCryptoWalletBalance: (args: {
    balanceAmount: Float
    balanceCurrency: CryptoCurrencies
    cryptoWalletId: String
  }) => UpdateCryptoWalletBalanceObservableChain & {
    execute: (
      request: UpdateCryptoWalletBalanceRequest,
      defaultValue?: UpdateCryptoWalletBalance | null,
    ) => Observable<UpdateCryptoWalletBalance | null>
  }
  withdrawalConfirmation: ((args?: {
    token?: String | null
    userIdB64?: String | null
    withdrawalRequestId?: String | null
  }) => WithdrawalConfirmationObservableChain & {
    execute: (
      request: WithdrawalConfirmationRequest,
      defaultValue?: WithdrawalConfirmation | null,
    ) => Observable<WithdrawalConfirmation | null>
  }) &
    (WithdrawalConfirmationObservableChain & {
      execute: (
        request: WithdrawalConfirmationRequest,
        defaultValue?: WithdrawalConfirmation | null,
      ) => Observable<WithdrawalConfirmation | null>
    })
  updateNotificationPreferences: ((args?: {
    buyTargetPercentage?: Int | null
    notificationPreferences?: (UpdateNotificationPreferencesInput | null)[] | null
    sellTargetPercentage?: Int | null
  }) => UpdateNotificationPreferencesObservableChain & {
    execute: (
      request: UpdateNotificationPreferencesRequest,
      defaultValue?: UpdateNotificationPreferences | null,
    ) => Observable<UpdateNotificationPreferences | null>
  }) &
    (UpdateNotificationPreferencesObservableChain & {
      execute: (
        request: UpdateNotificationPreferencesRequest,
        defaultValue?: UpdateNotificationPreferences | null,
      ) => Observable<UpdateNotificationPreferences | null>
    })
  groupedBidClose: (args: {
    allBids?: Boolean | null
    id: ID
  }) => GroupedBidCloseObservableChain & {
    execute: (request: GroupedBidCloseRequest, defaultValue?: GroupedBidClose | null) => Observable<GroupedBidClose | null>
  }
  groupedListingClose: (args: {
    allListings?: Boolean | null
    id: ID
  }) => GroupedListingCloseObservableChain & {
    execute: (
      request: GroupedListingCloseRequest,
      defaultValue?: GroupedListingClose | null,
    ) => Observable<GroupedListingClose | null>
  }
  sellItems: (args: {
    cardParallelId: ID
    closeSellOrders?: Boolean | null
    expiresAfter?: ExpiresAfter | null
    gradeId: ID
    minimumSalePrice: Float
    quantity: Int
  }) => SellItemsObservableChain & {
    execute: (request: SellItemsRequest, defaultValue?: SellItems | null) => Observable<SellItems | null>
  }
  buyItems: (args: {
    affiliateCode?: String | null
    cancelBidsPriced?: Float | null
    cardParallelId: ID
    expiresAfter?: ExpiresAfter | null
    gradeId: ID
    maximumBuyPrice: Float
    quantity: Int
  }) => BuyItemsObservableChain & {
    execute: (request: BuyItemsRequest, defaultValue?: BuyItems | null) => Observable<BuyItems | null>
  }
  buyOrderExtendExpiryDate: (args: {
    extendExpiry: ExpiresAfter
    groupedBidId: ID
  }) => BuyOrderExtendExpiryDateObservableChain & {
    execute: (
      request: BuyOrderExtendExpiryDateRequest,
      defaultValue?: BuyOrderExtendExpiryDate | null,
    ) => Observable<BuyOrderExtendExpiryDate | null>
  }
  sellOrderExtendExpiryDate: (args: {
    extendExpiry: ExpiresAfter
    groupedListingId: ID
  }) => SellOrderExtendExpiryDateObservableChain & {
    execute: (
      request: SellOrderExtendExpiryDateRequest,
      defaultValue?: SellOrderExtendExpiryDate | null,
    ) => Observable<SellOrderExtendExpiryDate | null>
  }
  wishlistItemCreate: (args: {
    cardParallelId: ID
  }) => WishlistItemCreateObservableChain & {
    execute: (
      request: WishlistItemCreateRequest,
      defaultValue?: WishlistItemCreate | null,
    ) => Observable<WishlistItemCreate | null>
  }
  wishlistItemDelete: (args: {
    id: ID
  }) => WishlistItemDeleteObservableChain & {
    execute: (
      request: WishlistItemDeleteRequest,
      defaultValue?: WishlistItemDelete | null,
    ) => Observable<WishlistItemDelete | null>
  }
  sendUserOwnershipsCsv: SendUserOwnershipsCsvObservableChain & {
    execute: (
      request: SendUserOwnershipsCsvRequest,
      defaultValue?: SendUserOwnershipsCsv | null,
    ) => Observable<SendUserOwnershipsCsv | null>
  }
  buyBoxBreakSpots: (args: {
    additionalInstructions?: String | null
    addressId: ID
    boxBreakId: ID
    paymentMethod: String
    preferShipHome?: Boolean | null
    spotIds: (ID | null)[]
  }) => BuyBoxBreakSpotsObservableChain & {
    execute: (
      request: BuyBoxBreakSpotsRequest,
      defaultValue?: BuyBoxBreakSpots | null,
    ) => Observable<BuyBoxBreakSpots | null>
  }
  personalBreakRequestCreate: (args: {
    additionalInstructions?: String | null
    addressId: ID
    boxBreakId: ID
    itemOwnershipId: ID
    preferShipHome?: Boolean | null
    requestDetails?: String | null
  }) => PersonalBreakRequestCreateObservableChain & {
    execute: (
      request: PersonalBreakRequestCreateRequest,
      defaultValue?: PersonalBreakRequestCreate | null,
    ) => Observable<PersonalBreakRequestCreate | null>
  }
  bidCreate: (args: {
    bidCurrency?: String | null
    bidPrice: Float
    lotId: ID
  }) => BidCreateObservableChain & {
    execute: (request: BidCreateRequest, defaultValue?: BidCreate | null) => Observable<BidCreate | null>
  }
  /** Create a brand new address for the logged-in user. */
  createAddress: (args: {
    addressLines: String
    country: String
    description: String
    locality: String
    name: String
    postalCode: String
    region: String
    role: String
  }) => CreateAddressObservableChain & {
    execute: (request: CreateAddressRequest, defaultValue?: CreateAddress | null) => Observable<CreateAddress | null>
  }
  /** Hide a specified address for the logged-in user. */
  deleteAddress: (args: {
    id: ID
  }) => DeleteAddressObservableChain & {
    execute: (request: DeleteAddressRequest, defaultValue?: DeleteAddress | null) => Observable<DeleteAddress | null>
  }
  /** Hide the existing address and create a replacement with the specified changes. */
  updateAddress: (args: {
    addressLines: String
    country: String
    description: String
    id: ID
    locality: String
    name: String
    postalCode: String
    region: String
    role: String
  }) => UpdateAddressObservableChain & {
    execute: (request: UpdateAddressRequest, defaultValue?: UpdateAddress | null) => Observable<UpdateAddress | null>
  }
  /** Mark an existing address as the default. */
  makeAddressDefault: (args: {
    id: ID
  }) => MakeAddressDefaultObservableChain & {
    execute: (
      request: MakeAddressDefaultRequest,
      defaultValue?: MakeAddressDefault | null,
    ) => Observable<MakeAddressDefault | null>
  }
  tokenAuth: (args: {
    username: String
    password: String
  }) => ObtainJSONWebTokenObservableChain & {
    execute: (
      request: ObtainJSONWebTokenRequest,
      defaultValue?: ObtainJSONWebToken | null,
    ) => Observable<ObtainJSONWebToken | null>
  }
  userCreate: (args: {
    email: String
    firstName: String
    lastName: String
    marketingEmails: Boolean
    password: String
    promoCode?: String | null
    username: String
  }) => UserCreateObservableChain & {
    execute: (request: UserCreateRequest, defaultValue?: UserCreate | null) => Observable<UserCreate | null>
  }
  passwordChange: ((args?: {
    newPassword?: String | null
    oldPassword?: String | null
  }) => PasswordChangeObservableChain & {
    execute: (request: PasswordChangeRequest, defaultValue?: PasswordChange | null) => Observable<PasswordChange | null>
  }) &
    (PasswordChangeObservableChain & {
      execute: (request: PasswordChangeRequest, defaultValue?: PasswordChange | null) => Observable<PasswordChange | null>
    })
  passwordResetRequest: ((args?: {
    username?: String | null
  }) => PasswordResetRequestObservableChain & {
    execute: (
      request: PasswordResetRequestRequest,
      defaultValue?: PasswordResetRequest | null,
    ) => Observable<PasswordResetRequest | null>
  }) &
    (PasswordResetRequestObservableChain & {
      execute: (
        request: PasswordResetRequestRequest,
        defaultValue?: PasswordResetRequest | null,
      ) => Observable<PasswordResetRequest | null>
    })
  passwordResetChange: ((args?: {
    newPassword?: String | null
    token?: String | null
    userIdB64?: String | null
  }) => PasswordResetChangeObservableChain & {
    execute: (
      request: PasswordResetChangeRequest,
      defaultValue?: PasswordResetChange | null,
    ) => Observable<PasswordResetChange | null>
  }) &
    (PasswordResetChangeObservableChain & {
      execute: (
        request: PasswordResetChangeRequest,
        defaultValue?: PasswordResetChange | null,
      ) => Observable<PasswordResetChange | null>
    })
  updateUserCollectionVisibility: (args: {
    isPublic: Boolean
  }) => UpdateUserCollectionVisibilityObservableChain & {
    execute: (
      request: UpdateUserCollectionVisibilityRequest,
      defaultValue?: UpdateUserCollectionVisibility | null,
    ) => Observable<UpdateUserCollectionVisibility | null>
  }
  verifyToken: ((args?: {
    token?: String | null
  }) => VerifyObservableChain & {
    execute: (request: VerifyRequest, defaultValue?: Verify | null) => Observable<Verify | null>
  }) &
    (VerifyObservableChain & {
      execute: (request: VerifyRequest, defaultValue?: Verify | null) => Observable<Verify | null>
    })
  refreshToken: ((args?: {
    refreshToken?: String | null
  }) => RefreshObservableChain & {
    execute: (request: RefreshRequest, defaultValue?: Refresh | null) => Observable<Refresh | null>
  }) &
    (RefreshObservableChain & {
      execute: (request: RefreshRequest, defaultValue?: Refresh | null) => Observable<Refresh | null>
    })
  deleteTokenCookie: DeleteJSONWebTokenCookieObservableChain & {
    execute: (
      request: DeleteJSONWebTokenCookieRequest,
      defaultValue?: DeleteJSONWebTokenCookie | null,
    ) => Observable<DeleteJSONWebTokenCookie | null>
  }
  deleteRefreshTokenCookie: DeleteRefreshTokenCookieObservableChain & {
    execute: (
      request: DeleteRefreshTokenCookieRequest,
      defaultValue?: DeleteRefreshTokenCookie | null,
    ) => Observable<DeleteRefreshTokenCookie | null>
  }
}

export interface SubmitDraftShippingRequestPromiseChain {
  /** List of errors that occured executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface SubmitDraftShippingRequestObservableChain {
  /** List of errors that occured executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Represents an error in the input of a mutation. */
export interface ErrorPromiseChain {
  /** Name of the field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** The error message. */
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** Represents an error in the input of a mutation. */
export interface ErrorObservableChain {
  /** Name of the field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** The error message. */
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface AddItemsToDraftShippingRequestPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  addedItems: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Promise<(String | null)[] | null>
  }
}

export interface AddItemsToDraftShippingRequestObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  addedItems: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Observable<(String | null)[] | null>
  }
}

export interface RemoveItemsFromDraftShippingRequestPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  removedItems: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Promise<(String | null)[] | null>
  }
}

export interface RemoveItemsFromDraftShippingRequestObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  removedItems: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Observable<(String | null)[] | null>
  }
}

export interface AddAllItemsToDraftShippingRequestPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface AddAllItemsToDraftShippingRequestObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

export interface RemoveAllItemsFromDraftShippingRequestPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface RemoveAllItemsFromDraftShippingRequestObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
  itemCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

export interface CreatePaymentIntentPromiseChain {
  paymentIntentToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  paymentSequence: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface CreatePaymentIntentObservableChain {
  paymentIntentToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  paymentSequence: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

export interface CreateWithdrawalRequestPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface CreateWithdrawalRequestObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface DeletePaymentMethodPromiseChain {
  paymentMethodId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface DeletePaymentMethodObservableChain {
  paymentMethodId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface SetDefaultPaymentMethodPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface SetDefaultPaymentMethodObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface CreateSetupIntentPromiseChain {
  setupIntentClientSecret: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface CreateSetupIntentObservableChain {
  setupIntentClientSecret: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
}

export interface UpdateCryptoWalletBalancePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface UpdateCryptoWalletBalanceObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface WithdrawalConfirmationPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface WithdrawalConfirmationObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface UpdateNotificationPreferencesPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface UpdateNotificationPreferencesObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface GroupedBidClosePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface GroupedBidCloseObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface GroupedListingClosePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface GroupedListingCloseObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface SellItemsPromiseChain {
  soldCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  listedCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface SellItemsObservableChain {
  soldCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  listedCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface BuyItemsPromiseChain {
  boughtCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  listedCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface BuyItemsObservableChain {
  boughtCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  listedCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface BuyOrderExtendExpiryDatePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface BuyOrderExtendExpiryDateObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface SellOrderExtendExpiryDatePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface SellOrderExtendExpiryDateObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface WishlistItemCreatePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
  wishlistItem: WishlistItemNodePromiseChain & {
    execute: (request: WishlistItemNodeRequest, defaultValue?: WishlistItemNode | null) => Promise<WishlistItemNode | null>
  }
}

export interface WishlistItemCreateObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
  wishlistItem: WishlistItemNodeObservableChain & {
    execute: (
      request: WishlistItemNodeRequest,
      defaultValue?: WishlistItemNode | null,
    ) => Observable<WishlistItemNode | null>
  }
}

export interface WishlistItemDeletePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
  wishlistItem: WishlistItemNodePromiseChain & {
    execute: (request: WishlistItemNodeRequest, defaultValue?: WishlistItemNode | null) => Promise<WishlistItemNode | null>
  }
}

export interface WishlistItemDeleteObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
  wishlistItem: WishlistItemNodeObservableChain & {
    execute: (
      request: WishlistItemNodeRequest,
      defaultValue?: WishlistItemNode | null,
    ) => Observable<WishlistItemNode | null>
  }
}

export interface SendUserOwnershipsCsvPromiseChain {
  ok: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface SendUserOwnershipsCsvObservableChain {
  ok: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface BuyBoxBreakSpotsPromiseChain {
  purchasedSpotsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  purchasedSpotsCost: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface BuyBoxBreakSpotsObservableChain {
  purchasedSpotsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  purchasedSpotsCost: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface PersonalBreakRequestCreatePromiseChain {
  purchasedSpotsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  purchasedSpotsCost: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface PersonalBreakRequestCreateObservableChain {
  purchasedSpotsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  purchasedSpotsCost: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface BidCreatePromiseChain {
  bid: BidNodePromiseChain & { execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null> }
  lot: LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Promise<LotNode | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface BidCreateObservableChain {
  bid: BidNodeObservableChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
  }
  lot: LotNodeObservableChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Observable<LotNode | null>
  }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Create a brand new address for the logged-in user. */
export interface CreateAddressPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

/** Create a brand new address for the logged-in user. */
export interface CreateAddressObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Hide a specified address for the logged-in user. */
export interface DeleteAddressPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

/** Hide a specified address for the logged-in user. */
export interface DeleteAddressObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Hide the existing address and create a replacement with the specified changes. */
export interface UpdateAddressPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

/** Hide the existing address and create a replacement with the specified changes. */
export interface UpdateAddressObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Mark an existing address as the default. */
export interface MakeAddressDefaultPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

/** Mark an existing address as the default. */
export interface MakeAddressDefaultObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface ObtainJSONWebTokenPromiseChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Promise<GenericScalar> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  token: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface ObtainJSONWebTokenObservableChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Observable<GenericScalar> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  token: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface UserCreatePromiseChain {
  user: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface UserCreateObservableChain {
  user: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface PasswordChangePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface PasswordChangeObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface PasswordResetRequestPromiseChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface PasswordResetRequestObservableChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface PasswordResetChangePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface PasswordResetChangeObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface UpdateUserCollectionVisibilityPromiseChain {
  ok: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface UpdateUserCollectionVisibilityObservableChain {
  ok: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface VerifyPromiseChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Promise<GenericScalar> }
}

export interface VerifyObservableChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Observable<GenericScalar> }
}

export interface RefreshPromiseChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Promise<GenericScalar> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  token: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface RefreshObservableChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Observable<GenericScalar> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  token: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface DeleteJSONWebTokenCookiePromiseChain {
  deleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
}

export interface DeleteJSONWebTokenCookieObservableChain {
  deleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
}

export interface DeleteRefreshTokenCookiePromiseChain {
  deleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
}

export interface DeleteRefreshTokenCookieObservableChain {
  deleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
}
