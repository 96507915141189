import { useQuery } from "@apollo/client";
import { Navigate, useLocation } from "react-router-dom";

import IS_LOGGED_IN from "../api/queries/IS_LOGGED_IN";

const PleaseLogIn = ({ children }) => {
  const location = useLocation();
  const { data } = useQuery(IS_LOGGED_IN);

  if (data && data.isLoggedIn) {
    return <>{children}</>;
  }

  return <Navigate to="/login" state={{ from: location }} />;
};

export default PleaseLogIn;
