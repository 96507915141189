import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { object, ref, string } from "yup";

const PasswordResetForm = ({
  passwordResetChange,
  uidb64,
  token,
  isSubmitting,
  setSubmitting,
}) => {
  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirm: "",
      }}
      validationSchema={object({
        password: string()
          .required()
          .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d^&$£!%#*@?-]{8,128}$/),
        passwordConfirm: string()
          .oneOf([ref("password"), null], "Passwords must match")
          .required("Required"),
      })}
      onSubmit={(values) => {
        setSubmitting(true);
        passwordResetChange({
          variables: {
            newPassword: values.password,
            token: token,
            userIdB64: uidb64,
          },
        });
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <Form>
          <TextField
            id="password"
            name="password"
            type="password"
            label="New password"
            autoComplete="new-password"
            value={values.password}
            error={errors.password && touched.password}
            helperText={
              "Requires 8 or more characters including letters and numbers"
            }
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-1"
          />

          <TextField
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            label="Confirm new password"
            autoComplete="new-password"
            value={values.passwordConfirm}
            error={errors.passwordConfirm && touched.passwordConfirm}
            helperText={
              errors.passwordConfirm &&
              touched.passwordConfirm &&
              errors.passwordConfirm
            }
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-3"
          />

          <button
            type="submit"
            className="btn btn-success btn-block btn-lg mb-3"
            disabled={isSubmitting}
          >
            Save
          </button>

          <Link className="card-link" to="/login">
            Log in
          </Link>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordResetForm;
