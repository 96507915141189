import { useEffect, useState } from "react";
import { getContentful } from "../../utils/contentful";

const QUERY = (slug) => `
query {
  pageCollection(where: { slug: "${slug}" }) {
    items {
      title
      slug
      body
    }
  }
}
`;

const LOADING = {
  title: "Loading …",
  content: "",
};

const NOT_FOUND = {
  title: "Page not found",
  content: "The page you are looking for could not be found at this time.",
};

const Page = ({ slug, children }) => {
  const [page, setPage] = useState(LOADING);

  useEffect(() => {
    getContentful(QUERY(slug)).then(({ data }) => {
      if (data.pageCollection.items.length > 0) {
        setPage(data.pageCollection.items[0]);
      } else {
        setPage(NOT_FOUND);
      }
    });
  }, [slug]);

  return <>{children(page)}</>;
};

export default Page;
