import { gql } from "@apollo/client";

const GET_MARKET_WIDGETS = gql`
  query GET_MARKET_WIDGETS {
    marketWidgets {
      id
      title
      subtitle
      featuredResultKey
      resultSetOrderBy
    }
    boxBreaks(stateGroup: ["UPCOMING", "LIVE"]) {
      totalCount
    }
    auctions(state: ["PUBLISHED", "LIVE"]) {
      totalCount
    }
  }
`;

export default GET_MARKET_WIDGETS;
