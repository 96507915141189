import { Helmet } from "react-helmet";

const PrivacyPolicy = () => (
  <>
    <Helmet>
      <title>Privacy Policy</title>
    </Helmet>

    <div className="row bg-light py-md-5">
      <div
        className="col card shadow p-md-4 mx-auto"
        style={{ maxWidth: "48rem" }}
      >
        <div className="card-body">
          <h1 className="font-weight-bold border-bottom pb-3 mb-4">
            StarStock, Inc. Privacy Policy
          </h1>
          <p className="font-weight-bold mb-3">Last Updated: April 20, 2020</p>
          <p>
            <span>
              StarStock, Inc (&ldquo;StarStock,&rdquo; &ldquo;we&rdquo;, or
              &ldquo;us&rdquo;) provides an online marketplace to allow our
              customers to buy and sell their favorite sports cards. &nbsp; Our
              Privacy Policy (&ldquo;Privacy Policy&rdquo;) is designed to help
              you understand how we collect, use and share your personal
              information and to assist you in exercising the privacy rights
              available to you.
            </span>
          </p>
          <h5 className="font-weight-bold">SCOPE</h5>
          <p>
            <span>
              This Privacy Policy applies to personal information processed by
              us in our business, including on our websites, mobile
              applications, and other online or offline offerings (collectively,
              the &ldquo;Services&rdquo;).
            </span>
          </p>

          <ol className="pl-3 mt-4">
            <li className="pl-3 mb-4">
              <h4 className="font-weight-bold">
                PERSONAL INFORMATION WE COLLECT
              </h4>
              <p>
                <span>
                  The categories of personal information we collect depend on
                  whether you are a customer, job applicant or site visitor, and
                  the requirements of applicable law.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Information You Provide to Us
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Account Creation.{" "}
                </span>
                <span>
                  When you create a user account, we collect your name, email
                  address, username, and password.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Your Communications with Us
                </span>
                <span>
                  . &nbsp;We collect personal information from you such as email
                  address, phone number, or mailing address when you request
                  information about our Services, register for our newsletter or
                  loyalty program, request customer or technical support, apply
                  for a job or otherwise communicate with us.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Payment and Shipping Information
                </span>
                <span>
                  . To ship cards, you must provide us your shipping address. In
                  order to process your payments for our Services, you may also
                  need to provide third parties with your payment information,
                  such as credit or debit card information, billing address, and
                  shipping address. Your use of these third-party payment
                  processing services is governed by their respective privacy
                  policies.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">Surveys</span>
                <span>
                  . &nbsp;We may contact you to participate in surveys. &nbsp;If
                  you decide to participate, you may be asked to provide certain
                  information which may include personal information.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Social Media Content
                </span>
                <span>
                  . &nbsp;We may offer forums, blogs, or social media pages.
                  &nbsp;Any content you provide on these channels will be
                  considered &ldquo;public,&rdquo; and is not subject to privacy
                  protections.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Registration for Sweepstakes or Contests
                </span>
                <span>
                  . &nbsp;We may run sweepstakes and contests. &nbsp;Contact
                  information you provide may be used to reach you about the
                  sweepstakes or contest and for other promotional, marketing
                  and business purposes, if permitted by law. &nbsp;In some
                  jurisdictions, we are required to publicly share information
                  of winners.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Information Collected Automatically or From Others
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Automatic Data Collection
                </span>
                <span>
                  . &nbsp;We may collect certain information automatically when
                  you use the Services. &nbsp;This information may include your
                  Internet Protocol (IP) address, user settings, MAC address,
                  cookie identifiers, mobile carrier, mobile advertising and
                  other unique identifiers, details about your browser,
                  operating system or device, location information, Internet
                  service provider, pages that you visit before, during and
                  after using the Services, information about the links you
                  click, and other information about how you use the Services.
                  &nbsp;We may associate information we collect with accounts
                  and other devices.
                </span>
              </p>
              <p>
                <span>
                  In addition, we may automatically collect data regarding your
                  use of our Services, such as the types of content you interact
                  with and the frequency and duration of your activities.
                  &nbsp;We may combine your information with information that
                  other people provide about you when they use our Services.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Cookies, Pixel Tags/Web Beacons, Analytics Information, and
                  Interest-Based Advertising Technologies. &nbsp;
                </span>
                <span>
                  We, as well as third parties that provide content,
                  advertising, or other functionality on the Services, may use
                  cookies, pixel tags, local storage, and other technologies
                  (&ldquo;Technologies&rdquo;) to automatically collect
                  information through the Services. &nbsp;Technologies are
                  essentially small data files placed on your computer, tablet,
                  mobile phone, or other devices that allow us and our partners
                  to record certain pieces of information whenever you visit or
                  interact with our Services.
                </span>
              </p>
              <ul className="mb-3">
                <li>
                  <span className="text-shout font-weight-bold">Cookies</span>
                  <span>
                    . &nbsp;Cookies are small text files placed in
                    visitors&rsquo; computer browsers to store their
                    preferences. &nbsp;Most browsers allow you to block and
                    delete cookies. &nbsp;However, if you do that, the Services
                    may not work properly.
                  </span>
                </li>
                <li>
                  <span className="text-shout font-weight-bold">
                    Pixel Tags/Web Beacons
                  </span>
                  <span>
                    . &nbsp;A pixel tag (also known as a web beacon) is a piece
                    of code embedded in the Services that collects information
                    about users&rsquo; engagement on that web page. &nbsp;The
                    use of a pixel allows us to record, for example, that a user
                    has visited a particular web page or clicked on a particular
                    advertisement.
                  </span>
                </li>
              </ul>
              <p>
                <span className="text-shout font-weight-bold">Analytics</span>
                <span>
                  . &nbsp;We may also use Google Analytics and other service
                  providers to collect information regarding visitor behavior
                  and visitor demographics on our Services. &nbsp;For more
                  information about Google Analytics, please visit{" "}
                </span>
                <span>
                  <a href="http://www.google.com/policies/privacy/partners/">
                    www.google.com/policies/privacy/partners/
                  </a>
                </span>
                <span>
                  . &nbsp;You can opt out of Google&rsquo;s collection and
                  processing of data generated by your use of the Services by
                  going to{" "}
                </span>
                <span>
                  <a href="http://tools.google.com/dlpage/gaoptout">
                    http://tools.google.com/dlpage/gaoptout
                  </a>
                </span>
                <span>.</span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Information from Other Sources.
                </span>
                <span>
                  &nbsp;We may obtain information about you from other sources,
                  including through third party services and organizations to
                  supplement information provided by you. &nbsp;For example, if
                  you access our Services through a third-party application,
                  such as an app store, a third-party login service, or a social
                  networking site, we may collect information about you from
                  that third-party application that you have made public via
                  your privacy settings. &nbsp;Information we collect through
                  these services may include your name, your user identification
                  number, your user name, location, gender, birth date, email,
                  profile picture, and your contacts stored in that service.
                  &nbsp;This supplemental information allows us to verify
                  information that you have provided to us and to enhance our
                  ability to provide you with information about our business,
                  products, and Services.
                </span>
              </p>
            </li>

            <li className="pl-3 mb-4">
              <h4 className="font-weight-bold">
                HOW WE USE YOUR INFORMATION.{" "}
              </h4>
              <p>
                <span>
                  We use your information for a variety of business purposes,
                  including to:
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Fulfil our contract with you and provide you with our
                  Services, such as:
                </span>
              </p>
              <ul className="mb-3">
                <li>
                  <span>Managing your information and accounts;</span>
                </li>
                <li>
                  <span>
                    Providing access to certain areas, functionalities, and
                    features of our Services;
                  </span>
                </li>
                <li>
                  <span>
                    Communicating with you about your account, activities on our
                    Services and policy changes;
                  </span>
                </li>
                <li>
                  <span>
                    Undertaking activities to verify or maintain the quality or
                    safety of a service or device;
                  </span>
                </li>
                <li>
                  <span>
                    Processing your financial information and other payment
                    methods for products or Services purchased;
                  </span>
                </li>
                <li>
                  <span>Maintaining your sports card vault;</span>
                </li>
                <li>
                  <span>
                    Providing advertising, analytics and marketing services;
                  </span>
                </li>
                <li>
                  <span>
                    Providing Services on behalf of our customers, such as
                    maintaining or servicing accounts, providing customer
                    service, and verifying customer information;
                  </span>
                </li>
                <li>
                  <span>Processing applications and transactions; and</span>
                </li>
                <li>
                  <span>Allowing you to register for events.</span>
                </li>
              </ul>
              <p>
                <span className="text-shout font-weight-bold">
                  Analyze and improve our Services pursuant to our legitimate
                  interest, such as:
                </span>
              </p>
              <ul className="mb-3">
                <li>
                  <span>
                    Detecting security incidents, protecting against malicious,
                    deceptive, fraudulent or illegal activity, and prosecuting
                    those responsible for that activity;
                  </span>
                </li>
                <li>
                  <span>
                    Measuring interest and engagement in our Services and
                    short-term, transient use, such as contextual customization
                    of ads;
                  </span>
                </li>
                <li>
                  <span>
                    Undertaking research for technological development and
                    demonstration;
                  </span>
                </li>
                <li>
                  <span>
                    Researching and developing products, services, marketing or
                    security procedures to improve their performance,
                    resilience, reliability or efficiency;
                  </span>
                </li>
                <li>
                  <span>
                    Improving, upgrading or enhancing our Services or device;
                  </span>
                </li>
                <li>
                  <span>Developing new products and Services;</span>
                </li>
                <li>
                  <span>Ensuring internal quality control;</span>
                </li>
              </ul>
              <ul className="mb-3">
                <li>
                  <span>Verifying your identity and preventing fraud;</span>
                </li>
                <li>
                  <span>
                    Debugging to identify and repair errors that impair existing
                    intended functionality;
                  </span>
                </li>
                <li>
                  <span>Enforcing our terms and policies; and</span>
                </li>
                <li>
                  <span>
                    Complying with our legal obligations, protecting your vital
                    interest, or as may be required for the public good.
                  </span>
                </li>
              </ul>
              <p>
                <span className="text-shout font-weight-bold">
                  Provide you with additional content and Services, such as:
                </span>
              </p>
              <ul className="mb-3">
                <li>
                  <span>
                    Furnishing you with customized materials about offers,
                    products, and Services that may be of interest, including
                    new content or Services;
                  </span>
                </li>
                <li>
                  <span>
                    Auditing relating to interactions, transactions and other
                    compliance activities; and
                  </span>
                </li>
                <li>
                  <span>
                    Other purposes you consent to, are notified of, or are
                    disclosed when you provide personal information.
                  </span>
                </li>
              </ul>
              <p>
                <span className="text-shout font-weight-bold">
                  Automated profiling. &nbsp;
                </span>
                <span>
                  We may use technologies considered automated decision making
                  or profiling. &nbsp;We will not make automated decisions about
                  you that would significantly affect you, unless such a
                  decision is necessary as part of a contract we have with you,
                  we have your consent, or we are permitted by law to use such
                  technology. &nbsp;You may escalate any concerns you have by
                  contacting us below.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Use De-identified and Aggregated Information. &nbsp;
                </span>
                <span>
                  We may use personal information and other data about you to
                  create de-identified and aggregated information, such as
                  de-identified demographic information, de-identified location
                  information, information about the computer or device from
                  which you access our Services, or other analyses we create.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Share Content with Friends or Colleagues. &nbsp;
                </span>
                <span>
                  Our Services may offer various tools and functionalities.
                  &nbsp;For example, we may allow you to provide information
                  about your friends through our referral services. &nbsp;Our
                  referral services may allow you to forward or share certain
                  content with a friend or colleague, such as an email inviting
                  your friend to use our Services.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  How We Use Automatic Collection Technologies. &nbsp;
                </span>
                <span>
                  We, as well as third parties that provide content,
                  advertising, or other functionality on the Services, may use
                  cookies, pixel tags, local storage, and other technologies to
                  automatically collect information through the Services.
                  &nbsp;Our uses of these Technologies fall into the following
                  general categories:
                </span>
              </p>
              <ul className="mb-3">
                <li>
                  <span className="text-shout font-weight-bold">
                    Operationally Necessary
                  </span>
                  <span>
                    . This includes Technologies that allow you access to our
                    Services, applications, and tools that are required to
                    identify irregular site behavior, prevent fraudulent
                    activity and improve security or that allow you to make use
                    of our functionality;
                  </span>
                </li>
                <li>
                  <span className="text-shout font-weight-bold">
                    Performance Related
                  </span>
                  <span>
                    . &nbsp;We may use Technologies to assess the performance of
                    our Services, including as part of our analytic practices to
                    help us understand how our visitors use the Services;
                  </span>
                </li>
                <li>
                  <span className="text-shout font-weight-bold">
                    Functionality Related
                  </span>
                  <span>
                    . &nbsp;We may use Technologies that allow us to offer you
                    enhanced functionality when accessing or using our Services.
                    &nbsp;This may include identifying you when you sign into
                    our Services, or keeping track of your specified
                    preferences, interests, or past items viewed;
                  </span>
                </li>
                <li>
                  <span className="text-shout font-weight-bold">
                    Advertising or Targeting Related
                  </span>
                  <span>
                    . &nbsp;We may use first party or third-party Technologies
                    to deliver content, including ads relevant to your
                    interests, on our Services or on third-party sites.
                  </span>
                </li>
              </ul>
              <p>
                <span className="text-shout font-weight-bold">
                  Cross-Device Tracking
                </span>
                <span>
                  . &nbsp;Your browsing activity may be tracked across different
                  websites and different devices or apps. &nbsp;For example, we
                  may attempt to match your browsing activity on your mobile
                  device with your browsing activity on your laptop. &nbsp;To do
                  this, our technology partners may share data, such as your
                  browsing patterns, geo-location and device identifiers, and
                  will match the information of the browser and devices that
                  appear to be used by the same person.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Notice Regarding Third-Party Websites, Social Media Platforms
                  and Software Development Kits. &nbsp;
                </span>
                <span>
                  The Services may contain links to other websites, and other
                  websites may reference or link to our website or other
                  Services. &nbsp;These other websites are not controlled by us.
                  &nbsp;We encourage our users to read the privacy policies of
                  each website and application with which they interact.
                  &nbsp;We do not endorse, screen or approve and are not
                  responsible for the privacy practices or content of such other
                  websites or applications. &nbsp;Visiting these other websites
                  or applications is at your own risk.
                </span>
              </p>
              <p>
                <span>
                  Our Services may include publicly accessible blogs, forums,
                  social media pages, and private messaging features. &nbsp;By
                  using such Services, you assume the risk that the personal
                  information provided by you may be viewed and used by third
                  parties for any number of purposes. &nbsp;In addition, social
                  media buttons such as Facebook, LinkedIn, Instagram, and
                  Twitter (that might include widgets such as the &ldquo;share
                  this&rdquo; button or other interactive mini-programs) may be
                  on our site. &nbsp;These features may collect your IP address,
                  which page you are visiting on our site, and may set a cookie
                  to enable the feature to function properly. &nbsp;These social
                  media features are either hosted by a third party or hosted
                  directly on our site. &nbsp;Your interactions with these
                  features apart from your visit to our site are governed by the
                  privacy policy of the company providing it.
                </span>
              </p>
              <p>
                <span>
                  We may use third-party Application Programming Interfaces
                  (&ldquo;APIs&rdquo;) and Software Development Kits
                  (&ldquo;SDKs&rdquo;) as part of the functionality of our
                  Services. &nbsp;APIs and SDKs may allow third parties
                  including analytics and advertising partners to collect your
                  personal information for various purposes including to provide
                  analytics services and content that is more relevant to you.
                  &nbsp;For more information about our use of APIs and SDKs,
                  please contact us as set forth below.
                </span>
              </p>
            </li>

            <li className="pl-3 mb-4">
              <h4 className="font-weight-bold">
                DISCLOSING YOUR INFORMATION TO THIRD PARTIES.{" "}
              </h4>
              <p>
                <span>
                  Except as provided below, we do not share your personal
                  information. &nbsp;We have not sold consumers&rsquo; personal
                  information in the preceding 12 months. &nbsp;We have
                  disclosed the following categories of personal information
                  about consumers for a business purpose in the past 12 months:
                  <br />
                </span>
              </p>
              <table>
                <tbody>
                  <tr>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span className="text-shout font-weight-bold">
                          Category
                        </span>
                      </p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span className="text-shout font-weight-bold">
                          Examples
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>A. Identifiers.</span>
                      </p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>
                          A real name, alias, postal address, unique personal
                          identifier, online identifier, Internet Protocol
                          address, email address, account name, or other similar
                          identifiers.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>
                          B. Personal information categories listed in the
                          California Customer Records statute (Cal. Civ. Code
                          &sect; 1798.80(e)).
                        </span>
                      </p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>A name or address. </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>C. Commercial information.</span>
                      </p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>
                          Records of products or services purchased, obtained,
                          or considered, or other purchasing or consuming
                          histories or tendencies.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>
                          D. Internet or other electronic network activity.
                        </span>
                      </p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>
                          Browsing history, search history, information on a
                          consumer&#39;s interaction with an internet website,
                          application, or advertisement.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>
                          E. Inferences drawn from other personal information to
                          create a profile about a consumer.
                        </span>
                      </p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                      <p>
                        <span>
                          Profile reflecting a consumer&#39;s preferences,
                          characteristics, psychological trends,
                          predispositions, behavior, attitudes, intelligence,
                          abilities, and aptitudes.
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <span></span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Service Providers. &nbsp;
                </span>
                <span>
                  We may share any personal information we collect about you
                  with our third-party service providers. &nbsp;The categories
                  of service providers (processors) to whom we entrust personal
                  information include: IT and related services; information and
                  services; payment processors; customer service providers; and
                  vendors to support the provision of the Services.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Business Partners. &nbsp;
                </span>
                <span>
                  We may provide personal information to business partners with
                  whom we jointly offer products or services. &nbsp;In such
                  cases, our business partner&rsquo;s name will appear along
                  with ours.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Affiliates. &nbsp;
                </span>
                <span>
                  We may share personal information with our affiliated
                  companies.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Advertising Partners. &nbsp;
                </span>
                <span>
                  Through our Services, we may allow third-party advertising
                  partners to set Technologies and other tracking tools to
                  collect information regarding your activities and your device
                  (e.g., your IP address, mobile identifiers, page(s) visited,
                  location, time of day). &nbsp;We may also combine and share
                  such information and other information (such as demographic
                  information and past purchase history) with third-party
                  advertising partners. &nbsp;These advertising partners may use
                  this information (and similar information collected from other
                  websites) for purposes of delivering targeted advertisements
                  to you when you visit third-party websites within their
                  networks. &nbsp;This practice is commonly referred to as
                  &ldquo;interest-based advertising&rdquo; or &ldquo;online
                  behavioral advertising.&rdquo; &nbsp;We may allow access to
                  other data collected by the Services to share information that
                  may be useful, relevant, valuable or otherwise of interest to
                  you. &nbsp;If you prefer not to share your personal
                  information with third-party advertising partners, you may
                  follow the instructions below.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Disclosures to Protect Us or Others.
                </span>
                <span>
                  &nbsp; We may access, preserve, and disclose any information
                  we store associated with you to external parties if we, in
                  good faith, believe doing so is required or appropriate to:
                  comply with law enforcement or national security requests and
                  legal process, such as a court order or subpoena; protect
                  your, our or others&rsquo; rights, property, or safety;
                  enforce our policies or contracts; collect amounts owed to us;
                  or assist with an investigation or prosecution of suspected or
                  actual illegal activity.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Disclosure in the Event of Merger, Sale, or Other Asset
                  Transfers. &nbsp;
                </span>
                <span>
                  If we are involved in a merger, acquisition, financing due
                  diligence, reorganization, bankruptcy, receivership, purchase
                  or sale of assets, or transition of service to another
                  provider, then your information may be sold or transferred as
                  part of such a transaction, as permitted by law and/or
                  contract.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  International Data Transfers.
                </span>
                <span>
                  &nbsp; You agree that all information processed by us may be
                  transferred, processed, and stored anywhere in the world,
                  including but not limited to, the United States or other
                  countries, which may have data protection laws that are
                  different from the laws where you live. &nbsp;We have taken
                  appropriate safeguards to require that your personal
                  information will remain protected, and require our third-party
                  service providers and partners to have appropriate safeguards
                  as well. &nbsp;Further details can be provided upon request.
                </span>
              </p>
            </li>

            <li className="pl-3 mb-4">
              <h4 className="font-weight-bold">YOUR CHOICES. </h4>
              <p>
                <span className="text-shout font-weight-bold">
                  General. &nbsp;
                </span>
                <span>
                  You have certain choices about your personal information.
                  &nbsp;Where you have consented to the processing of your
                  personal information, you may withdraw that consent at any
                  time and prevent further processing by contacting us as
                  described below. &nbsp;Even if you opt out, we may still
                  collect and use non-personal information regarding your
                  activities on our Services and for other legal purposes as
                  described above.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Email Communications. &nbsp;
                </span>
                <span>
                  If you receive an unwanted email from us, you can use the
                  unsubscribe link found at the bottom of the email to opt out
                  of receiving future emails. &nbsp;Note that you will continue
                  to receive transaction-related emails regarding products or
                  Services you have requested. &nbsp;We may also send you
                  certain non-promotional communications regarding us and our
                  Services, and you will not be able to opt out of those
                  communications (e.g., communications regarding the Services or
                  updates to our Terms or this Privacy Policy).
                </span>
              </p>
              <p>
                <span>
                  We process requests to be placed on do-not-mail and
                  do-not-contact lists as required by applicable law.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Mobile Devices. &nbsp;
                </span>
                <span>
                  We may send you push notifications through our mobile
                  application. &nbsp;You may at any time opt-out from receiving
                  these types of communications by changing the settings on your
                  mobile device. &nbsp;We may also collect location-based
                  information if you use our mobile applications. &nbsp;You may
                  opt-out of this collection by changing the settings on your
                  mobile device.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  &ldquo;Do Not Track.&rdquo; &nbsp;
                </span>
                <span>
                  Do Not Track (&ldquo;DNT&rdquo;) is a privacy preference that
                  users can set in certain web browsers. &nbsp;Please note that
                  we do not respond to or honor DNT signals or similar
                  mechanisms transmitted by web browsers.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Cookies and Interest-Based Advertising. &nbsp;
                </span>
                <span>
                  You may stop or restrict the placement of Technologies on your
                  device or remove them by adjusting your preferences as your
                  browser or device permits. &nbsp;The online advertising
                  industry also provides websites from which you may opt out of
                  receiving targeted ads from data partners and other
                  advertising partners that participate in self-regulatory
                  programs. &nbsp;You can access these and learn more about
                  targeted advertising and consumer choice and privacy, at{" "}
                </span>
                <span>
                  <a href="http://www.networkadvertising.org/managing/opt_out.asp">
                    www.networkadvertising.org/managing/opt_out.asp
                  </a>
                </span>
                <span>, </span>
                <span>
                  <a href="http://www.youronlinechoices.eu/">
                    http://www.youronlinechoices.eu/
                  </a>
                </span>
                <span>,</span>
                <span>&nbsp;</span>
                <span>
                  <a href="https://youradchoices.ca/choices/">
                    https://youradchoices.ca/choices/
                  </a>
                </span>
                <span>, and </span>
                <span>
                  <a href="http://www.aboutads.info/choices/">
                    www.aboutads.info/choices/
                  </a>
                </span>
                <span>
                  . &nbsp;To separately make choices for mobile apps on a mobile
                  device, you can download DAA&rsquo;s AppChoices application
                  from your device&rsquo;s app store. &nbsp;Alternatively, for
                  some devices, you may use your device&rsquo;s platform
                  controls in your settings to exercise choice.
                </span>
              </p>
              <p>
                <span>
                  Please note you must separately opt out in each browser and on
                  each device. &nbsp;Advertisements on third-party websites that
                  contain the AdChoices link may have been directed to you based
                  on information collected by advertising partners over time and
                  across websites. &nbsp;These advertisements provide a
                  mechanism to opt out of the advertising partners&rsquo; use of
                  this information for interest-based advertising purposes.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Your Privacy Rights.
                </span>
              </p>
              <p>
                <span>
                  In accordance with applicable law, you may have the right to:
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Access to/Portability of Personal Data{" "}
                </span>
                <span>
                  about you consistent with legal requirements. &nbsp;In
                  addition, you may have the right in some cases to receive or
                  have your electronic Personal Data transferred to another
                  party.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Request Correction{" "}
                </span>
                <span>
                  of your personal information where it is inaccurate or
                  incomplete. &nbsp;In some cases, we may provide self-service
                  tools that enable you to update your personal information, or
                  we may refer you to the controller of your personal
                  information who is able to make the correction.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Request Deletion{" "}
                </span>
                <span>
                  of your personal information, subject to certain exceptions
                  prescribed by law.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Request restriction of or object to{" "}
                </span>
                <span>
                  processing of your personal information, including the right
                  to opt in or opt out of the sale of your Personal Data to
                  third parties, if applicable, where such requests are
                  permitted by law.
                </span>
              </p>
              <p>
                <span>
                  If you would like to exercise any of these rights, please
                  contact us as set forth below. &nbsp;We will process such
                  requests in accordance with applicable laws. &nbsp;To protect
                  your privacy, we will take steps to verify your identity
                  before fulfilling your request.
                </span>
              </p>
            </li>

            <li className="pl-3 mb-4">
              <h4 className="font-weight-bold">DATA RETENTION.</h4>
              <p>
                <span>
                  We store the personal information we receive as described in
                  this Privacy Policy for as long &nbsp;as you use our Services
                  or as necessary to fulfill the purpose(s) for which it was
                  collected, provide our Services, resolve disputes, establish
                  legal defenses, conduct audits, pursue legitimate business
                  purposes, enforce our agreements, and comply with applicable
                  laws.
                </span>
              </p>
            </li>

            <li className="pl-3 mb-4">
              <h4 className="font-weight-bold">
                SECURITY OF YOUR INFORMATION.
              </h4>
              <p>
                <span>
                  We take steps to ensure that your information is treated
                  securely and in accordance with this Privacy Policy.
                  &nbsp;Unfortunately, no system is 100% secure, and we cannot
                  ensure or warrant the security of any information you provide
                  to us. &nbsp;To the fullest extent permitted by applicable
                  law, we do not accept liability for unintentional disclosure.
                </span>
              </p>
              <p>
                <span>
                  By using the Services or providing personal information to us,
                  you agree that we may communicate with you electronically
                  regarding security, privacy, and administrative issues
                  relating to your use of the Services. &nbsp;If we learn of a
                  security system&rsquo;s breach, we may attempt to notify you
                  electronically by posting a notice on the Services, by mail or
                  by sending an e-mail to you.
                </span>
              </p>
            </li>

            <li className="pl-3 mb-4">
              <h4 className="font-weight-bold">
                CHILDREN&rsquo;S INFORMATION.
              </h4>
              <p>
                <span>
                  The Services are not directed to children under 13 (or other
                  age as required by local law), and we do not knowingly collect
                  personal information from children. &nbsp;If you learn that
                  your child has provided us with personal information without
                  your consent, you may contact us as set forth below. &nbsp;If
                  we learn that we have collected any personal information in
                  violation of applicable law, we will promptly take steps to
                  delete such information, unless we have a legal obligation to
                  retain it, and terminate the child&rsquo;s account.
                </span>
              </p>
            </li>

            <li className="pl-3 mb-4">
              <h4 className="font-weight-bold">OTHER PROVISIONS.</h4>
              <p>
                <span className="text-shout font-weight-bold">
                  Supervisory Authority. &nbsp;
                </span>
                <span>
                  If you are located in the European Economic Area or the UK,
                  you have the right to lodge a complaint with a supervisory
                  authority if you believe our processing of your personal
                  information violates applicable law.
                </span>
              </p>
              <p>
                <span className="text-shout font-weight-bold">
                  Changes to Our Privacy Policy. &nbsp;
                </span>
                <span>
                  We may revise this Privacy Policy from time to time in our
                  sole discretion. &nbsp;If there are any material changes to
                  this Privacy Policy, we will notify you as required by
                  applicable law. &nbsp;You understand and agree that you will
                  be deemed to have accepted the updated Privacy Policy if you
                  continue to use the Services after the new Privacy Policy
                  takes effect.
                </span>
              </p>
            </li>

            <li className="pl-3 mb-4">
              <h4 className="font-weight-bold">CONTACT US.</h4>
              <p>
                <span>
                  If you have any questions about our privacy practices or this
                  Privacy Policy, or if you wish to submit a request to exercise
                  your rights as detailed in this Privacy Policy, please contact
                  us at:
                </span>
              </p>
              <p>
                <span>StarStock, Inc.</span>
                <br />
                <span>Privacy@starstock.com</span>
                <br />
                <span>200 Corporate Blvd South #120</span>
                <br />
                <span>Yonkers, NY 10701</span>
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </>
);

export default PrivacyPolicy;
