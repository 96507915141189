import { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import classNames from "classnames";

import StepLoading from "./StepLoading";
import StepEdit from "./StepEdit";
import StepSubmitting from "./StepSubmitting";
import StepComplete from "./StepComplete";

export const BuyBreakSpotsFormSteps = {
  invalid: "invalid",
  loading: "loading",
  edit: "edit",
  submitting: "submitting",
  complete: "complete",
};

const defaultFormData = {
  boxBreakId: null,
  boxBreakName: null,
  boxBreak: null,
  availableSpots: [],
  selectedSpots: [],
  participant: null,
  preferShipHome: "false",
  addressId: null,
  additionalInstructions: "",
  purchasedSpotsCount: 0,
  purchasedSpotsCost: 0,
  paymentMethod: "wallet",
  errors: [],
};

const Header = ({ title, boxBreakName, showClose }) => {
  return (
    <Modal.Header className="border-bottom pb-0" closeButton={showClose}>
      <div className={classNames("container-fluid", { "mr-n4": showClose })}>
        <div className="text-center">
          <h3 className="font-weight-bold">{title}</h3>
          <p>{boxBreakName}</p>
        </div>
      </div>
    </Modal.Header>
  );
};

const BuyBreakSpotsForm = ({
  show,
  setShow,
  initialStep = BuyBreakSpotsFormSteps.loading,
  boxBreakId,
  boxBreakName,
  boxBreak,
  availableSpots = [],
  selectedSpots = [],
  participant,
  preferShipHome,
  addressId,
  additionalInstructions = "",
  onComplete,
}) => {
  // Merges given form field values into the existing form data
  const setFormDataFields = (updatedFields) =>
    setFormData((current) => ({ ...current, ...updatedFields }));

  const closeAndResetForm = () => {
    setStep(BuyBreakSpotsFormSteps.edit);
    setFormData(initialFormData);
    setShow(false);
  };

  const initialFormData = {
    ...defaultFormData,
    boxBreakId: boxBreakId,
    boxBreakName: boxBreakName,
    boxBreak: boxBreak,
    availableSpots: availableSpots,
    selectedSpots: selectedSpots,
    participant: participant,
    preferShipHome: preferShipHome,
    addressId: addressId,
    additionalInstructions: additionalInstructions,
    purchasedSpotsCount: null,
    purchasedSpotsCost: null,
    errors: null,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [step, setStep] = useState(initialStep);

  // Validate we have enough data to display current step or step back as needed
  useEffect(() => {
    switch (step) {
      case BuyBreakSpotsFormSteps.complete:
        if (
          formData.purchasedSpotsCount === null &&
          formData.purchasedSpotsCost === null &&
          formData.errors === null
        ) {
          setStep(BuyBreakSpotsFormSteps.submitting);
        }
        break;
      case BuyBreakSpotsFormSteps.submitting:
        if (!formData.boxBreakId || !formData.selectedSpots) {
          setStep(BuyBreakSpotsFormSteps.edit);
        }
        break;
      case BuyBreakSpotsFormSteps.edit:
        if (!formData.boxBreak || !formData.availableSpots) {
          setStep(BuyBreakSpotsFormSteps.loading);
        }
        break;
      case BuyBreakSpotsFormSteps.loading:
        if (!formData.boxBreakId) {
          setStep(BuyBreakSpotsFormSteps.invalid);
        }
        break;
      case BuyBreakSpotsFormSteps.invalid:
      default:
    }
  }, [
    formData.availableSpots,
    formData.boxBreak,
    formData.boxBreakId,
    formData.errors,
    formData.purchasedSpotsCost,
    formData.purchasedSpotsCount,
    formData.selectedSpots,
    step,
  ]);

  return (
    <Modal
      show={show}
      onHide={() => closeAndResetForm()}
      onExited={() => closeAndResetForm()}
      backdrop={"static"}
      keyboard={step !== BuyBreakSpotsFormSteps.submitting}
      centered
    >
      {step === BuyBreakSpotsFormSteps.loading && (
        <>
          <Header
            title={"Buy into the break"}
            boxBreakName={formData.boxBreakName}
            showClose={step !== BuyBreakSpotsFormSteps.submitting}
          />
          <StepLoading
            formData={formData}
            setFormDataFields={setFormDataFields}
            onLoaded={() => setStep(BuyBreakSpotsFormSteps.edit)}
            onFailed={() => setStep(BuyBreakSpotsFormSteps.invalid)}
          />
        </>
      )}
      {step === BuyBreakSpotsFormSteps.edit && (
        <StepEdit
          formData={formData}
          setFormDataFields={setFormDataFields}
          onNext={() => setStep(BuyBreakSpotsFormSteps.submitting)}
          onCancel={() => closeAndResetForm()}
        />
      )}
      {step === BuyBreakSpotsFormSteps.submitting && (
        <>
          <Header
            title={"Buy into the break"}
            boxBreakName={formData.boxBreakName}
            showClose={step !== BuyBreakSpotsFormSteps.submitting}
          />
          <StepSubmitting
            formData={formData}
            setFormDataFields={setFormDataFields}
            onComplete={() => setStep(BuyBreakSpotsFormSteps.complete)}
          />
        </>
      )}
      {step === BuyBreakSpotsFormSteps.complete && (
        <>
          <Header
            title={"Buy into the break"}
            boxBreakName={formData.boxBreakName}
            showClose={step !== BuyBreakSpotsFormSteps.submitting}
          />
          <StepComplete
            formData={formData}
            onClose={() => closeAndResetForm()}
          />
        </>
      )}
    </Modal>
  );
};

export default BuyBreakSpotsForm;
