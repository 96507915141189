import { useEffect, useState } from "react";

import _ from "lodash";
import { Modal } from "react-bootstrap";
import { useMutation } from "@apollo/client";

import { logAmplitudeEvent } from "../../utils/analytics";
import { BUYING_EVENTS } from "../../utils/analytics/events";
import { pluralize } from "../../utils/content";
import { prettyExpiresAfter } from "../../utils/forms";

import GET_CARD_PARALLEL from "../../api/queries/GET_CARD_PARALLEL";
import GET_GRADED_CARD_PARALLEL from "../../api/queries/GET_GRADED_CARD_PARALLEL";
import BUY_ITEMS from "../../api/mutations/BUY_ITEMS";

import ModalHeaderSection from "./ModalHeaderSection";
import BuyModalBody from "./BuyModalBody";
import BuyModalFooter from "./BuyModalFooter";
import Loader from "../Loader";

const ErrorConfirmation = ({ errors, onDismiss }) => {
  return (
    <div className="p-3">
      <div className="alert alert-danger text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">There was a problem</h3>
        {errors.map((error, i) => (
          <p key={i} className="my-1">
            {error.message}
          </p>
        ))}
      </div>

      <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary px-5" onClick={() => onDismiss()}>
          OK
        </button>
      </div>
    </div>
  );
};

const SuccessConfirmation = ({
  cardsBoughtCount,
  offersCreatedCount,
  onDismiss,
  expiresAfter,
}) => {
  return (
    <div className="p-3">
      <div className="alert alert-success text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">
          {cardsBoughtCount > 0
            ? "Congratulations on your purchase!"
            : "Thanks, your offer is now live!"}
        </h3>
        {cardsBoughtCount > 0 && (
          <p className="my-1">
            You bought{" "}
            <strong>{pluralize("new item", cardsBoughtCount)}</strong>
          </p>
        )}
        {offersCreatedCount > 0 && (
          <>
            <p className="my-1">
              You placed{" "}
              <strong>{pluralize("new offer", offersCreatedCount)}.</strong>
            </p>
            {expiresAfter !== "NO_EXPIRY" ? (
              <p>
                Offers will expire in{" "}
                <span className="font-weight-bold">
                  {prettyExpiresAfter(expiresAfter)}.
                </span>
              </p>
            ) : null}
          </>
        )}
      </div>

      <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary px-5" onClick={() => onDismiss()}>
          Continue
        </button>
      </div>
    </div>
  );
};

const BuyModal = ({
  show,
  setShow,
  slug,
  gradeId,
  gradeName,
  gradedCardParallelId,
  cardParallelId,
  cardParallelProductType,
  playerCard,
  parallelName,
  serialLimit,
  openSellOrders,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [maxBidPerCard, setMaxBidPerCard] = useState(
    openSellOrders.length ? openSellOrders[0].askPrice : "",
  );
  const [expiresAfter, setExpiresAfter] = useState("NO_EXPIRY");
  const [sellOrdersToBuy, setSellOrdersToBuy] = useState([]);
  const [bidsToCreateCount, setBidsToCreateCount] = useState(0);
  const [saleTotal, setSaleTotal] = useState(0);
  const [bidTotal, setBidTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [isSubmitting, setSubmitting] = useState(false);
  const [cardsBoughtCount, setCardsBoughtCount] = useState(0);
  const [offersCreatedCount, setOffersCreatedCount] = useState(0);
  const [errors, setErrors] = useState([]);

  let buyItemsRefetchQueries = [];
  if (slug) {
    buyItemsRefetchQueries.push({
      query: GET_CARD_PARALLEL,
      variables: { slug },
    });
  }
  if (gradedCardParallelId) {
    buyItemsRefetchQueries.push({
      query: GET_GRADED_CARD_PARALLEL,
      variables: { id: gradedCardParallelId },
    });
  }

  useEffect(() => {
    if (show) {
      logAmplitudeEvent(BUYING_EVENTS.NEW_BUY_ORDER.FORM_VIEWED, {
        "Product Type": cardParallelProductType,
        "Product Slug": slug,
        "Product Grade": gradeName,
      });
    }
  }, [show, cardParallelProductType, slug, gradeName]);

  const [buyItems] = useMutation(BUY_ITEMS, {
    refetchQueries: buyItemsRefetchQueries,
    onCompleted({ buyItems }) {
      setSubmitting(false);
      setCardsBoughtCount(buyItems.boughtCount || 0);
      setOffersCreatedCount(buyItems.listedCount || 0);
      setErrors(buyItems.errors || []);

      if (buyItems.errors && buyItems.errors.length > 0) {
        logAmplitudeEvent(BUYING_EVENTS.NEW_BUY_ORDER.FAILED);
      } else {
        logAmplitudeEvent(BUYING_EVENTS.NEW_BUY_ORDER.CREATED, {
          "Product Type": cardParallelProductType,
          "Product Slug": slug,
          "Product Grade": gradeName,
          "Buy Order Quantity": quantity,
          "Buy Order Maximum Price": maxBidPerCard,
          "Buy Order Expires After": expiresAfter,
          "Items Bought": buyItems.boughtCount,
          "Items Bought Value": saleTotal,
          "Offers Created": buyItems.listedCount,
          "Offers Created Value": bidTotal,
        });
      }
    },
  });

  useEffect(() => {
    setMaxBidPerCard(openSellOrders.length ? openSellOrders[0].askPrice : "");
  }, [openSellOrders, setMaxBidPerCard]);

  useEffect(() => {
    let fulfillList = _.take(openSellOrders, quantity);

    if (maxBidPerCard !== "") {
      fulfillList = fulfillList.filter(
        (sellOrder) => sellOrder.askPrice <= maxBidPerCard,
      );
    }

    const currentSaleTotal = _.reduce(
      fulfillList,
      function (sum, sellOrder) {
        return sum + sellOrder.askPrice;
      },
      0,
    );

    const bidCount = quantity - fulfillList.length;

    setSellOrdersToBuy(fulfillList);
    setSaleTotal(currentSaleTotal);
    setBidsToCreateCount(bidCount);
    setBidTotal(bidCount * maxBidPerCard);
  }, [
    quantity,
    maxBidPerCard,
    openSellOrders,
    setSellOrdersToBuy,
    setSaleTotal,
    setBidsToCreateCount,
    setBidTotal,
  ]);

  useEffect(() => {
    setTotal(saleTotal + bidTotal);
  }, [saleTotal, bidTotal, setTotal]);

  const failed = errors.length > 0;
  const successful = cardsBoughtCount > 0 || offersCreatedCount > 0;

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      onExited={() => {
        setCardsBoughtCount(0);
        setOffersCreatedCount(0);
        setErrors([]);
      }}
      backdrop={"static"}
      keyboard={!isSubmitting}
      centered
    >
      <ModalHeaderSection
        title={"Buy or Offer"}
        gradeName={gradeName}
        playerCard={playerCard}
        parallelName={parallelName}
        serialLimit={serialLimit}
        showClose={!isSubmitting}
      />

      {isSubmitting ? (
        <Loader message={"Submitting order…"} />
      ) : failed ? (
        <ErrorConfirmation errors={errors} onDismiss={() => setShow(false)} />
      ) : successful ? (
        <SuccessConfirmation
          cardsBoughtCount={cardsBoughtCount}
          offersCreatedCount={offersCreatedCount}
          onDismiss={() => setShow(false)}
          expiresAfter={expiresAfter}
        />
      ) : (
        <>
          <BuyModalBody openSellOrders={openSellOrders} />
          <BuyModalFooter
            setShow={setShow}
            isSubmitting={isSubmitting}
            setSubmitting={setSubmitting}
            buyItems={buyItems}
            gradeId={gradeId}
            cardParallelId={cardParallelId}
            cardParallelProductType={cardParallelProductType}
            quantity={quantity}
            setQuantity={setQuantity}
            maxBidPerCard={maxBidPerCard}
            setMaxBidPerCard={setMaxBidPerCard}
            expiresAfter={expiresAfter}
            setExpiresAfter={setExpiresAfter}
            sellOrdersToBuy={sellOrdersToBuy}
            bidsToCreateCount={bidsToCreateCount}
            saleTotal={saleTotal}
            bidTotal={bidTotal}
            total={total}
          />
        </>
      )}
    </Modal>
  );
};

export default BuyModal;
