import { Fragment, useEffect, useRef } from "react";
import { useQueryState } from "react-router-use-location-state";

import styled, { css } from "styled-components";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { rgba } from "polished";
import _ from "lodash";

import { financial } from "../../utils/common";

import ComponentInfo, { MarketIndexInfo } from "./ComponentInfo";
import SortControl, {
  SortControlPositions,
  SortControlVariants,
} from "../Marketplace/SortControl";

const Astronaut = "#2c4d76";
const Havelock = "#4e8ad4";

const HeaderHeight = "4rem";
const WideBreakpointWidth = "40rem";
const FixedColumnWidthNarrow = "12rem";
const FixedColumnWidthWide = "16rem";
const ColumnWidthNarrow = "6rem";
const ColumnWidthWide = "7rem";

function latestSnapshotForComponent(component) {
  return component.componentSnapshots.edges
    .map(({ node }) => node)
    .sort((a, b) => {
      return (
        new Date(b.marketIndexSnapshot.date) -
        new Date(a.marketIndexSnapshot.date)
      );
    })[0];
}

const sortOptions = [
  {
    key: "highest-valued",
    label: "Highest valued",
    description: "The highest sale prices in the index last week",
    sortFunction: (a, b) =>
      latestSnapshotForComponent(b).price - latestSnapshotForComponent(a).price,
  },
  {
    key: "top-performers",
    label: "Top performers",
    description: "Last week's biggest percentage price increases",
    sortFunction: (a, b) =>
      latestSnapshotForComponent(b).priceChangePercentage -
      latestSnapshotForComponent(a).priceChangePercentage,
  },
];

const StyledWrapper = styled.div`
  position: relative;
  overscroll-behavior: contain;
  margin-bottom: 0;
`;

const StyledUpperContainer = styled.div`
  height: ${HeaderHeight};
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-between;
  z-index: 2;

  border-bottom: 1px solid #00000020;
  background: linear-gradient(
      to bottom,
      ${rgba(Havelock, 0.0)},
      ${rgba(Havelock, 0.05)}
    ),
    white;
  box-shadow: 0 0.5rem 0.5rem #00000010;

  // Distinct styling for wider screens to taper out the harsh lines / shadow
  @media (min-width: 1280px) {
    // Replaces full-width border with a gradient one to taper out at edges
    border: 0;
    border-bottom: 1px solid #00000020;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      #00000000,
      #00000020 4rem,
      #00000020 calc(100% - 4rem),
      #00000000
    );

    // Replaces full-width background with a radial one to taper out at edges
    background: transparent;
    ::before {
      content: "";
      position: absolute;
      left: 0rem;
      right: 0rem;
      top: 0;
      bottom: 0;
      z-index: -1;
      background: radial-gradient(
          farthest-side at 50% 100%,
          ${rgba(Havelock, 0.1)},
          ${rgba(Havelock, 0.0)}
        )
        white;
    }

    // Replaces full-width shadow with a radial shadow to taper out at edges
    box-shadow: none;
    ::after {
      content: "";
      position: absolute;
      left: 0rem;
      right: 0rem;
      bottom: -1rem;
      height: 1rem;
      background: radial-gradient(farthest-side at 50% 0, #00000010, #00000000);
    }
  }
`;

const StyledLowerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTableHeader = styled.div`
  height: 100%;
  display: flex;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 1rem;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

const StyledTableBody = styled.div`
  padding: 1rem;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

const StyledTableRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const StyledFixedLeftSidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: flex-start;
  position: relative;
  z-index: 1;

  width: ${FixedColumnWidthNarrow};

  @media (min-width: ${WideBreakpointWidth}) {
    width: ${FixedColumnWidthWide};
  }

  border: 0;
  border-right: 1px solid;
  border-image-slice: 1;

  &.top {
    padding: 0.75rem 1rem;
    border-image-source: linear-gradient(to bottom, #00000000, #00000020);

    ::after {
      content: "";
      position: absolute;
      right: -0.25rem;
      top: -1rem;
      bottom: 0;
      width: 0.25rem;

      background: radial-gradient(farthest-side at 0 50%, #00000010, #00000000);
    }
  }

  &.bottom {
    padding: 1rem;
    border-image-source: linear-gradient(to top, #00000000, #00000020 2rem);

    ::after {
      content: "";
      position: absolute;
      right: -2rem;
      top: -8rem;
      bottom: -8rem;
      width: 2rem;

      background: radial-gradient(
        farthest-side at 0% 50%,
        #00000010,
        #00000000
      );
    }
  }
`;

const StyledSnapshotHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;

  font-size: 1rem;

  margin-left: 0.5rem;

  width: ${ColumnWidthNarrow};

  @media (min-width: ${WideBreakpointWidth}) {
    width: ${ColumnWidthWide};
  }
`;

const SnapshotHeader = ({ snapshot }) => {
  return (
    <StyledSnapshotHeader>
      <p className="text-whisper mb-n1 small">{snapshot.name}</p>
      <p className="text-speak mb-n1">
        {new Date(snapshot.date).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
        })}
      </p>
    </StyledSnapshotHeader>
  );
};

const StyledSnapshotInternalVariants = {
  empty: "empty",
  neutral: "neutral",
  positive: "positive",
  negative: "negative",
};

const StyledSnapshotInternal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  border-radius: 4px;
  flex-grow: 0;
  height: 100%;
  background: #f5f5f5;

  padding: 0.25rem 0.5rem;
  &:first {
    padding: 10rem;
    background: red;
  }

  height: 3rem;

  flex-grow: 0;
  flex-shrink: 0;

  width: ${ColumnWidthNarrow};

  @media (min-width: ${WideBreakpointWidth}) {
    width: ${ColumnWidthWide};
  }

  ${(props) =>
    props.variant === StyledSnapshotInternalVariants.empty &&
    css`
      background: #ffffff;
      border: 1px dashed #00000010;
      text-align: center;
      color: ${rgba(Astronaut, 0.2)};
    `}

  ${(props) =>
    props.variant === StyledSnapshotInternalVariants.positive &&
    css`
      background: #ddeede;
    `}

  ${(props) =>
    props.variant === StyledSnapshotInternalVariants.negative &&
    css`
      background: #fbe2e2;
    `}
`;

const SnapshotCell = ({ snapshot }) => {
  return (
    <StyledSnapshotInternal
      className="ml-2"
      style={{ marginBottom: "2rem" }}
      variant={
        snapshot.valueChangePercentage > 0
          ? StyledSnapshotInternalVariants.positive
          : snapshot.valueChangePercentage < 0
          ? StyledSnapshotInternalVariants.negative
          : snapshot.value
          ? StyledSnapshotInternalVariants.neutral
          : StyledSnapshotInternalVariants.empty
      }
    >
      <p className="text-right text-shout font-weight-bold mb-0">
        {financial(snapshot.normalisedValue)}
      </p>
      <div
        className="d-flex flex-row justify-content-between"
        style={{ fontSize: "0.75rem" }}
      >
        <p className="text-left text-speak mb-0">&nbsp;</p>
        {snapshot.normalisedValueChangePercentage > 0 ? (
          <p className="text-right text-success mb-0">
            +{(snapshot.normalisedValueChangePercentage * 100).toFixed(1)}%
          </p>
        ) : snapshot.normalisedValueChangePercentage < 0 ? (
          <p className="text-right text-danger mb-0">
            {(snapshot.normalisedValueChangePercentage * 100).toFixed(1)}%
          </p>
        ) : null}
      </div>
    </StyledSnapshotInternal>
  );
};

const ComponentSnapshotCell = ({ component, snapshot }) => {
  const componentSnapshot = component.componentSnapshots.edges
    .map((edge) => edge.node)
    .find(
      (node) =>
        node.marketIndexSnapshot && node.marketIndexSnapshot.id === snapshot.id,
    );

  if (componentSnapshot) {
    return (
      <StyledSnapshotInternal
        className="mb-2 ml-2"
        variant={
          componentSnapshot.priceChangePercentage > 0
            ? StyledSnapshotInternalVariants.positive
            : componentSnapshot.priceChangePercentage < 0
            ? StyledSnapshotInternalVariants.negative
            : StyledSnapshotInternalVariants.neutral
        }
      >
        <p className="text-right text-shout font-weight-bold mb-0">
          ${financial(componentSnapshot.price)}
        </p>
        <div
          className="d-flex flex-row justify-content-between"
          style={{ fontSize: "0.75rem" }}
        >
          {componentSnapshot.fantasyStatus === "FINAL" ? (
            <p className="text-left text-speak mb-0">
              {componentSnapshot.fantasyPoints} pts
            </p>
          ) : componentSnapshot.fantasyStatus !== "NONE" ? (
            <p className="text-left text-speak mb-0">
              {componentSnapshot.fantasyStatus}
            </p>
          ) : (
            <p className="mb-0"></p>
          )}
          {componentSnapshot.priceChangePercentage > 0 ? (
            <p className="text-right text-success mb-0">
              +{(componentSnapshot.priceChangePercentage * 100).toFixed(1)}%
            </p>
          ) : componentSnapshot.priceChangePercentage < 0 ? (
            <p className="text-right text-danger mb-0">
              {(componentSnapshot.priceChangePercentage * 100).toFixed(1)}%
            </p>
          ) : null}
        </div>
      </StyledSnapshotInternal>
    );
  } else {
    return (
      <StyledSnapshotInternal
        className="mb-2 ml-2"
        variant={StyledSnapshotInternalVariants.empty}
      >
        No data
      </StyledSnapshotInternal>
    );
  }
};

// Overflow-x absorbs trailing padding/margin - we add this as a buffer
const TrailingSpacer = styled.div`
  width: 1rem;
  flex-shrink: 0;
`;

const ComponentSnapshotsTable = ({ marketIndex }) => {
  const firstRender = useRef(true);
  const tableTopRef = useRef();
  const tableBodyRef = useRef();

  const snapshots = marketIndex.snapshots.edges.map(({ node }) => node);

  // Sort snapshots oldest-to-newest, to put latest on right of table
  const sortedSnapshots = snapshots.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  const components = marketIndex.components.edges.map(({ node }) => node);
  const [sortOrderKey, setSortOrderKey] = useQueryState(
    "sort",
    sortOptions[0].key,
  );
  const sortOrder =
    sortOptions.find((o) => o.key === sortOrderKey) || sortOptions[0];
  const sortedComponents = components.sort(sortOrder.sortFunction);

  // Group components by subcategory for display
  const groupedComponents = _.groupBy(sortedComponents, function (component) {
    return component.subcategory || "";
  });

  // Start the scrollview scrolled far-right to show latest dates first
  useEffect(() => {
    tableBodyRef.current.scrollLeft = tableBodyRef.current.scrollWidth;
  }, [tableBodyRef]);

  // When sort order changes, scroll to top-right of table
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      tableTopRef.current.scrollIntoView();
      tableBodyRef.current.scrollLeft = tableBodyRef.current.scrollWidth;
    }
  }, [sortOrderKey]);

  return (
    <ScrollSync>
      <>
        <StyledWrapper ref={tableTopRef}>
          <StyledUpperContainer>
            <StyledFixedLeftSidebar className="top">
              <SortControl
                className="mx-n1"
                sortOptions={sortOptions}
                selectedSortOptionKey={sortOrderKey}
                setSelectedSortOptionKey={(sortKey) => {
                  setSortOrderKey(sortKey, { method: "push" });
                }}
                configVariant={SortControlVariants.fixed}
                configPosition={SortControlPositions.left}
              />
            </StyledFixedLeftSidebar>
            <ScrollSyncPane>
              <StyledTableHeader>
                <StyledTableRow>
                  {sortedSnapshots.map((snapshot, i) => (
                    <SnapshotHeader key={i} snapshot={snapshot} />
                  ))}
                  <TrailingSpacer />
                </StyledTableRow>
              </StyledTableHeader>
            </ScrollSyncPane>
          </StyledUpperContainer>

          <StyledLowerContainer>
            <StyledFixedLeftSidebar className="bottom">
              <div>
                <MarketIndexInfo marketIndex={marketIndex} />
              </div>

              <hr className="w-100 my-3" />

              {Object.keys(groupedComponents).map((subcategory, i) => (
                <Fragment key={i}>
                  {subcategory && (
                    <h6 className="small text-uppercase text-whisper font-weight-bold border-bottom pb-2 mt-3 mb-2">
                      {subcategory}
                    </h6>
                  )}
                  {groupedComponents[subcategory].map((component, j) => (
                    <ComponentInfo key={j} component={component} />
                  ))}
                </Fragment>
              ))}
            </StyledFixedLeftSidebar>

            <ScrollSyncPane>
              <StyledTableBody ref={tableBodyRef}>
                <StyledTableRow>
                  {sortedSnapshots.map((snapshot, i) => (
                    <SnapshotCell key={i} snapshot={snapshot} />
                  ))}
                  <TrailingSpacer />
                </StyledTableRow>

                {Object.keys(groupedComponents).map((subcategory, i) => (
                  <Fragment key={i}>
                    {subcategory && (
                      <div className="mt-3 pb-2 mb-2 small">&nbsp;</div>
                    )}
                    {groupedComponents[subcategory].map((component, j) => (
                      <StyledTableRow key={j}>
                        {sortedSnapshots.map((snapshot, k) => (
                          <ComponentSnapshotCell
                            key={k}
                            component={component}
                            snapshot={snapshot}
                          />
                        ))}
                        <TrailingSpacer />
                      </StyledTableRow>
                    ))}
                  </Fragment>
                ))}
              </StyledTableBody>
            </ScrollSyncPane>
          </StyledLowerContainer>
        </StyledWrapper>
      </>
    </ScrollSync>
  );
};

export default ComponentSnapshotsTable;
