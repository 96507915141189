import styled from "styled-components";

export const Error = styled.div`
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  color: #721c24;
  margin: 1rem 1.5rem 0;
  padding: 0.75rem 1.25rem;
`;
