import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_LISTING_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
} from "../fragments";

const GET_MY_WISHLIST_CARDS = gql`
  ${CARD_PARALLEL_LISTING_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  query GET_MY_WISHLIST_CARDS(
    $orderBy: String
    $first: Int = 24
    $cursor: String
  ) {
    myWishlistCards(orderBy: $orderBy, first: $first, after: $cursor)
      @connection(key: "myWishlistCards", filter: ["orderBy"]) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      cardParallelStats {
        totalItemCount
        totalListingCount
      }
      edges {
        node {
          ...cardParallelListingFields
          gradedcardparallelSet(first: 3) {
            totalCount
            edges {
              node {
                ...gradedCardParallelFields
                grade {
                  ...gradeFields
                }
              }
            }
          }
          myWishlistItems {
            id
          }
        }
      }
    }
  }
`;

export default GET_MY_WISHLIST_CARDS;
