import { ReactNode } from "react";

import { useFetchAccountData } from "../hooks/useFetchAccountData";

interface Props {
  additionalChecks?: boolean;
  children: ReactNode;
  fallback?: ReactNode;
}

const StaffOnly = ({ additionalChecks = true, children, fallback }: Props) => {
  const { accountData, loginData } = useFetchAccountData();

  const canSeeChildren =
    additionalChecks &&
    loginData &&
    loginData.isLoggedIn &&
    accountData &&
    accountData.isStaff;

  if (canSeeChildren) return <>{children}</>;

  return <>{fallback}</> || null;
};

export default StaffOnly;
