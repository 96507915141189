import { gql } from "@apollo/client";

import {
  BOX_BREAK_FIELDS,
  CARD_PARALLEL_LISTING_FRAGMENT,
  FORMAT_FIELDS,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
  HIT_FIELDS,
  PARTICIPANT_FIELDS,
  PLAYER_LISTING_FRAGMENT,
  SPOT_FIELDS,
} from "../../fragments";

const GET_BOX_BREAK = gql`
  ${BOX_BREAK_FIELDS}
  ${CARD_PARALLEL_LISTING_FRAGMENT}
  ${FORMAT_FIELDS}
  ${GRADE_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  ${HIT_FIELDS}
  ${PARTICIPANT_FIELDS}
  ${PLAYER_LISTING_FRAGMENT}
  ${SPOT_FIELDS}
  query GET_BOX_BREAK($id: ID, $slug: String) {
    boxBreak(id: $id, slug: $slug) {
      ...boxBreakFields
      boxbreakphotoSet {
        totalCount
        edges {
          node {
            photoXs
            photoMd
          }
        }
      }
      format {
        ...formatFields
      }
      boxSet {
        edges {
          node {
            id
            gradedCardParallel {
              ...gradedCardParallelFields
              cardParallel {
                ...cardParallelListingFields
              }
              grade {
                ...gradeFields
              }
            }
          }
        }
      }
      targetPlayers {
        edges {
          node {
            ...playerListingFields
            headshotPhoto: headshotPhotoSm
          }
        }
      }
      spotSet {
        totalCount
        edges {
          node {
            ...spotFields
            isPersonalBreakRequest
          }
        }
      }
      myParticipant {
        ...participantFields
        spotSet {
          totalCount
          edges {
            node {
              ...spotFields
              isPersonalBreakRequest
            }
          }
        }
      }
      hitSet {
        totalCount
        edges {
          node {
            ...hitFields
          }
        }
      }
    }
  }
`;

export default GET_BOX_BREAK;
