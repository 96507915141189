import styled from "styled-components";

export const ChatContainer = styled.div`
  display: none;
  padding: 0;

  @media (min-width: 992px) {
    display: block;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

export const ChatWrapper = styled.div`
  height: 100%;
  min-height: 320px;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  @media (min-width: 992px) {
    padding: 0 1rem;
  }
`;

export const Embed = styled.iframe`
  border: 1px solid rgb(0 0 0 / 10%);
  left: 0;
  position: absolute;
  top: 0;
`;

export const VideoContainer = styled.div`
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

export const VideoWrapper = styled.div`
  padding-top: 56.25%;
  position: relative;
`;
