import playerOutline from "../../images/player-outline.png";
import { positionAndTeamName } from "../../utils/content";
import { financial } from "../../utils/common";

const DetailSection = ({
  name,
  position,
  currentTeam,
  headshotPhoto,
  tradeStats,
}) => {
  const imageSrc = headshotPhoto || playerOutline;
  const tradingRangeText =
    tradeStats.minTradePrice !== null
      ? `$${financial(tradeStats.minTradePrice)} - $${financial(
          tradeStats.maxTradePrice,
        )}`
      : "N/A";

  return (
    <div className="row align-items-end text-center text-sm-left">
      <div className="col-sm-auto">
        <img
          className="img-fluid"
          src={imageSrc}
          alt="Player headshot"
          width="224px"
        />
      </div>

      <div className="col py-3">
        <p className="mb-1">{positionAndTeamName(position, currentTeam)}</p>
        <h2 className="font-weight-bold text-shout mb-0">{name}</h2>

        <hr className="my-3" />
        <h6 className="font-weight-bold mb-2">3 month highlights</h6>

        <div className="row justify-content-center justify-content-sm-start">
          <div className="col-auto">
            <small>Total trades</small>
            <p className="font-weight-bold text-shout mb-0">
              {tradeStats.tradeCount}
            </p>
          </div>

          <div className="col-auto">
            <small>Trading range</small>
            <p className="font-weight-bold text-shout mb-0">
              {tradingRangeText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailSection;
