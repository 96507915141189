import { Formik } from "formik";
import { SetStateAction } from "react";

import { AddressValues } from "../../types";
import { AddressField } from "../AddressField";

interface Props {
  accountAddressBook?: boolean;
  checkBoxValue?: boolean;
  handleCheckbox?(): void;
  onCancel?(): void;
  onSubmit(): void;
}

export const AddAddress = ({
  accountAddressBook,
  checkBoxValue,
  handleCheckbox,
  onCancel,
  onSubmit,
}: Props) => {
  return (
    <Formik
      initialValues={{
        id: "",
        description: "",
        name: "",
        line1: "",
        line2: "",
        locality: "",
        region: "",
        postalCode: "",
        country: "",
        address_id: "",
      }}
      onSubmit={async () => {
        await new Promise((r) => setTimeout(r, 500));
      }}
    >
      {({
        values,
        setValues,
      }: {
        values: AddressValues;
        setValues: (
          fieldName: SetStateAction<{ address_id: undefined }>,
          shouldValidate?: boolean | undefined,
        ) => void;
      }) => (
        <AddressField
          accountAddressBook={accountAddressBook}
          onCancel={onCancel}
          onSubmit={onSubmit}
          handleCheckbox={handleCheckbox}
          checkBoxValue={checkBoxValue}
          fieldName="address_id"
          setValues={setValues}
          values={values}
        />
      )}
    </Formik>
  );
};
