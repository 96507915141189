import { AuctionState } from "../../../../generated/schema";

import { AuctionsWidget } from "../AuctionsWidget";

interface Props {
  era?: string;
  sport?: string;
}

export const UpcomingAuctionsWidget = ({ era, sport }: Props) => (
  <AuctionsWidget
    id="upcoming-auctions-widget"
    options={{
      variables: {
        era,
        orderBy: "scheduled_start_time_asc,id",
        sport,
        state: [AuctionState.PUBLISHED, AuctionState.LIVE],
      },
    }}
    subtitle="Get involved in the fast-paced action of StarStock live auctions"
    themeColor={"#da4d14"}
    title={"💬 Live auctions"}
    widgetName="UpcomingAuctions"
  />
);
