import { Button, Modal } from "react-bootstrap";

const ConfirmationDialog = ({
  show,
  titleText,
  bodyText,
  confirmText = "Confirm",
  cancelText = "Cancel",
  onConfirm,
  onCancel,
  children,
}) => {
  return (
    <Modal centered show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title className="font-weight-bold">{titleText}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {bodyText}
        {children ? <div className="mt-3">{children}</div> : null}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
