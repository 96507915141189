import { useQuery } from "@apollo/client";
import Avatar from "@material-ui/core/Avatar";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import { useDebounce } from "use-debounce";

import { pathWithSearch } from "../utils/common";
import { logAmplitudeEvent } from "../utils/analytics";
import { USER_COLLECTION_EVENTS } from "../utils/analytics/events";
import { sortOptions } from "../utils/userCollection";
import GET_USER_COLLECTION from "../api/queries/GET_USER_COLLECTION";
import ErrorNotice from "../components/ErrorNotice";
import Loader from "../components/Loader";
import LoadMoreButton from "../components/LoadMoreButton";
import CollectionNavBar from "../components/Collection/NavBar";
import SearchSortQueryControls from "../components/SearchSortQueryControls";
import ItemOwnershipGridContainer from "../components/ItemOwnership/ItemOwnershipGridContainer";

const UserCollection = () => {
  const [sport, setSport] = useQueryState("sport", "");
  const [era, setEra] = useQueryState("era", "");
  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [sortOrderKey, setSortOrderKey] = useQueryState(
    "sort",
    sortOptions[0].key,
  );
  const sortOrder =
    sortOptions.find((o) => o.key === sortOrderKey) || sortOptions[0];

  const navigate = useNavigate();
  const { username } = useParams();
  const avatarInitial = username.charAt(0).toUpperCase();

  const { data, loading, error, fetchMore } = useQuery(GET_USER_COLLECTION, {
    variables: {
      username: username,
      sport: sport,
      era: era,
      searchQuery: debouncedSearchQuery,
      orderBy: sortOrder.value,
    },
  });

  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    logAmplitudeEvent(USER_COLLECTION_EVENTS.ALL_CARDS.PAGE_VIEWED, {
      Username: username,
    });
  }, [username]);

  return (
    <>
      <Helmet>
        <title>{username}&apos;s Collection</title>
      </Helmet>

      <div className="row bg-white shadow border-bottom">
        <div className="col inner-container-wide mx-auto">
          <div className="d-flex flex-column justify-content-center pt-4 pt-md-5">
            <div className="d-flex flex-row justify-content-start align-items-center mb-3 mb-md-4">
              <Avatar
                className="mr-3"
                style={{ width: "4rem", height: "4rem", background: "#f6c144" }}
              >
                {avatarInitial}
              </Avatar>

              <h2 className="font-weight-bold mr-2 mb-0">{username}</h2>
            </div>

            <CollectionNavBar
              sport={sport}
              era={era}
              setValues={(sport, era) => {
                logAmplitudeEvent(
                  USER_COLLECTION_EVENTS.ALL_CARDS.SEARCH_UPDATED,
                  { Sport: sport, Era: era },
                );
                setEra(era);
                setSport(sport);
                navigate(pathWithSearch());
              }}
            />
          </div>
        </div>
      </div>

      <div className="inner-container-wide mx-auto">
        <div className="row">
          {/* SECTION: Main results body */}
          <div className="col mt-4 mt-md-4 mb-3">
            <SearchSortQueryControls
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              sortOrderKey={sortOrderKey}
              setSortOrderKey={setSortOrderKey}
              sortOptions={sortOptions}
              trackingLabel={USER_COLLECTION_EVENTS.ALL_CARDS.SEARCH_UPDATED}
            />

            {/* SECTION: Item ownerships */}
            {error && <ErrorNotice />}
            {loading && <Loader />}
            {!error && !loading && (
              <>
                <ItemOwnershipGridContainer
                  itemOwnershipCollection={data.userCollection}
                  emptyResultsMessage={
                    "No cards found matching this user or your search criteria"
                  }
                />

                {data.userCollection.pageInfo.hasNextPage && (
                  <LoadMoreButton
                    loadingMore={loadingMore}
                    setLoadingMore={setLoadingMore}
                    onLoadMore={() =>
                      fetchMore({
                        variables: {
                          cursor: data.userCollection.pageInfo.endCursor,
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          setLoadingMore(false);
                          if (!fetchMoreResult) return prev;

                          fetchMoreResult.userCollection.edges = [
                            ...prev.userCollection.edges,
                            ...fetchMoreResult.userCollection.edges,
                          ];
                          return fetchMoreResult;
                        },
                      })
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCollection;
