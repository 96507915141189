import { Alert } from "../../../../components/Alert";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";

export interface WithdrawalConfirmationStatusProps {
  errorMessage: string | null;
  loading: boolean;
}

export const WithdrawalConfirmationStatus = ({
  errorMessage,
  loading,
}: WithdrawalConfirmationStatusProps) => {
  if (loading) return <LoadingSpinner size={4} />;
  else
    return (
      <>
        {errorMessage ? (
          <Alert state="negative">{errorMessage}</Alert>
        ) : (
          <Alert state="positive">
            <h1 className="card-title h5 mt-2 mb-3 font-weight-bold">
              Successfully Confirmed
            </h1>
            <p>
              Thank you for your request to withdraw funds. You should hear from
              us soon if we need any more details. Please expect PayPal payments
              to take 4-5 working days. Checks should take around 5 working
              days.
            </p>
            <p>Thanks for using StarStock!</p>
          </Alert>
        )}
      </>
    );
};
