import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { debounce } from "lodash";

import Loader from "../../Loader";
import Error from "./components/Error";
import Form from "./components/Form";
import Header from "./components/Header";
import Success from "./components/Success";
import useFetchPersonalBreaks from "./hooks/useFetchPersonalBreaks";
import useRequestBreak from "./hooks/useRequestBreak";
import useRequestForm from "./hooks/useRequestForm";
import { Payload } from "./types";

interface Props {
  boxBreakId?: string;
  setShow: (show: boolean) => void;
  show: boolean;
}

const RequestBreakForm = ({ boxBreakId, show, setShow }: Props) => {
  const { state, dispatch } = useRequestForm();
  const { data } = useFetchPersonalBreaks(dispatch);
  const { createPersonalBreakRequest } = useRequestBreak(dispatch, state, data);

  useEffect(() => {
    if (boxBreakId) dispatch({ type: "update", payload: { boxBreakId } });
  }, [boxBreakId, dispatch]);

  const setFormDataFields = (payload: Payload) =>
    dispatch({ type: "update", payload });

  const closeAndResetForm = () => {
    dispatch({ type: "reset" });
    setShow(!show);
  };

  const submitForm = () => {
    if (!state.formComplete) return;

    dispatch({ type: "submit" });

    createPersonalBreakRequest({
      variables: {
        addressId: state.form.addressId,
        boxBreakId: state.form.boxBreakId,
        itemOwnershipId: state.form.itemOwnershipId,
        preferShipHome: state.form.preferShipHome,
        additionalInstructions: state.form.additionalInstructions,
        requestDetails: state.form.requestDetails,
      },
    });
  };

  return (
    <Modal
      backdrop={"static"}
      centered
      onExited={() => closeAndResetForm()}
      onHide={() => closeAndResetForm()}
      show={show}
    >
      <Header
        copy="Our breaking team can open any box from your collection as part of an upcoming or live personals break. Breaking fee is 5% of the box value ($10 minimum fee)."
        showClose={true}
        title="Request break from collection"
      />

      {state.step === "LOADING" && <Loader message="Checking breaks…" />}

      {state.step === "ERROR" && (
        <Error errors={state.errors} onClose={closeAndResetForm} />
      )}

      {state.step === "FORM" && (
        <Modal.Body style={{ padding: "1rem" }}>
          <Form
            breakFee={state.form.breakFee}
            boxes={data.myCollection.edges}
            breakId={boxBreakId}
            breaks={data.boxBreaks.edges}
            formData={state.form}
            updateForm={debounce(setFormDataFields, 100)}
            onCancel={closeAndResetForm}
            onNext={submitForm}
            formCompleted={state.formComplete}
          />
        </Modal.Body>
      )}

      {state.step === "SUBMIT" && (
        <Loader message="Adding your box to the break…" />
      )}

      {state.step === "SUCCESS" && (
        <Success
          onClose={closeAndResetForm}
          requestCost={state.result.purchasedSpotsCost}
        />
      )}
    </Modal>
  );
};

export default RequestBreakForm;
