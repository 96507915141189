import { gql } from "@apollo/client";

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation SET_DEFAULT_PAYMENT_METHOD($paymentMethodId: String!) {
    setDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
      errors {
        field
        message
      }
    }
  }
`;
