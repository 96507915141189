import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

import { Colors } from "../../../../themes/Default";

import { EventLineup } from "../EventLineup";
import {
  Contents,
  Controls,
  Heading,
  NextButton,
  PrevButton,
  StyledFAIcon,
} from "./styles";

interface Props {
  startingDate?: Date;
}

export const WeeklyView = ({ startingDate = new Date() }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(moment(startingDate));
  const [weekStart, setWeekStart] = useState("");
  const [weekEnd, setWeekEnd] = useState("");

  useEffect(() => {
    setWeekStart(startDate.clone().startOf("isoWeek").format("MMM D, YYYY"));
    setWeekEnd(startDate.clone().endOf("isoWeek").format("MMM D, YYYY"));
  }, [startDate]);

  useEffect(() => {
    const newUrl = `/live/upcoming/${startDate.clone().format("MM-DD-YYYY")}`;
    if (newUrl !== location.pathname) navigate(newUrl);
  }, [location, navigate, startDate]);

  const addWeek = () => setStartDate(startDate.clone().add(7, "days"));
  const subtractWeek = () =>
    setStartDate(startDate.clone().subtract(7, "days"));

  // Temporary code until GraphQL is created
  const renderContents = [...Array(7)].map((_, idx) => (
    <EventLineup
      key={idx}
      date={startDate.startOf("isoWeek").clone().add(idx, "days").toDate()}
    />
  ));

  return (
    <>
      <Helmet>
        <title>
          Events {startDate.format("D MMM YYYY")} | Upcoming | Live Events
        </title>
      </Helmet>

      <div>
        <Controls>
          <PrevButton aria-label="Previous week" onClick={subtractWeek}>
            <StyledFAIcon color={Colors.Blue} icon={faChevronLeft} />
          </PrevButton>

          <Heading>
            {weekStart} - {weekEnd}
          </Heading>

          <NextButton aria-label="Next week" onClick={addWeek}>
            <StyledFAIcon color={Colors.Blue} icon={faChevronRight} />
          </NextButton>
        </Controls>

        <Contents>{renderContents}</Contents>
      </div>
    </>
  );
};
