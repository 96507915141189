import { useState } from "react";

import { EventDescriptionSection } from "../../../../components/EventDescriptionSection";
import { ResponsiveImage } from "../../../../components/ResponsiveImage";

import { AuctionPhotoNode, AuctionState } from "../../../../generated/schema";

import PlaceholderBG from "../../assets/auctions-logo-color.svg";
import { AuctionHeaderDetail } from "../AuctionHeaderDetail";
import {
  HeroImageDiv,
  ThumbnailList,
  ThumbnailListItem,
  ThumbnailImage,
  StyledTimeBadge,
} from "./styles";

interface Props {
  description: string;
  heading: string;
  images: (AuctionPhotoNode | null | undefined)[];
  lotCount: number | null;
  maxPrice: number | null | undefined;
  minPrice: number | null | undefined;
  startTime: Date;
  state: AuctionState;
}

export const AuctionHeader = ({
  description,
  heading,
  images,
  lotCount,
  maxPrice,
  minPrice,
  startTime,
  state,
}: Props) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const scheduledStartTime = startTime ? new Date(startTime) : null;
  const selectedImage = images[selectedImageIndex] || { photoMd: null };

  return (
    <EventDescriptionSection heading={heading}>
      <EventDescriptionSection.MediaBlock>
        <HeroImageDiv>
          <StyledTimeBadge
            scheduledStartTime={scheduledStartTime}
            state={state}
            STATES={AuctionState}
          />
          <ResponsiveImage
            alt={`Auction image ${selectedImageIndex + 1}`}
            background={PlaceholderBG}
            height={2}
            src={selectedImage?.photoMd}
            width={3}
          />
        </HeroImageDiv>

        {images.length > 1 && (
          <ThumbnailList>
            {images.map((image, index) => (
              <ThumbnailListItem key={index}>
                <ThumbnailImage
                  src={image?.photoXs || ""}
                  alt={`Auction thumbnail ${index + 1}`}
                  onMouseEnter={() => setSelectedImageIndex(index)}
                />
              </ThumbnailListItem>
            ))}
          </ThumbnailList>
        )}
      </EventDescriptionSection.MediaBlock>

      <EventDescriptionSection.DescriptionBlock>
        <AuctionHeaderDetail
          description={description}
          lotCount={lotCount}
          maxPrice={maxPrice}
          minPrice={minPrice}
          state={state}
        />
      </EventDescriptionSection.DescriptionBlock>
    </EventDescriptionSection>
  );
};
