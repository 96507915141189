import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { rgba } from "polished";
import { useState } from "react";
import styled, { css } from "styled-components";

import IS_LOGGED_IN from "../../api/queries/IS_LOGGED_IN";
import { PRODUCT_TYPES } from "../../api/types";
import placeholderCard from "../../images/placeholder-card.png";
import placeholderBox from "../../images/placeholder-box-detail.png";
import { productQuantityString } from "../../utils/content";

import CardImageModal from "./CardImageModal";
import PlayerListing from "../PlayerListing";
import CardParallelBadges from "../CardParallelBadges";

const BorderColor = "rgba(0, 0, 0, 0.1)";
const Astronaut = "#2c4d76";

const StyledProductThumbnail = styled.button`
  background: transparent;
  background-image: url(\"${(props) => props.imageSrc}\");
  background-position: center;
  background-size: cover;
  border: 1px solid ${BorderColor};
  box-shadow: 0 0.25rem 0.5rem ${rgba(Astronaut, 0.1)};
  height: 13.125rem;
  padding: 0;

  @media (min-width: 48rem) {
    height: 17.5rem;
  }

  ${(props) =>
    props.productType === PRODUCT_TYPES.BOX
      ? css`
          // Aspect ratio for boxes is 1:1.28
          width: 10.25rem;

          @media (min-width: 48rem) {
            width: 13.625rem;
          }
        `
      : css`
          // Aspect ratio for cards is 1:1.4
          width: 9.375rem;

          @media (min-width: 48rem) {
            width: 12.5rem;
          }
        `};
`;

const CardDetailSection = ({
  cardParallelId,
  productType,
  parallel,
  cardName,
  cardNameSuffix,
  playerCard,
  playerCard: { cardFamily },
  serialLimit,
  itemCount,
  listingsCount,
  frontSidePhotoPreview,
  frontSidePhotoFull,
  reverseSidePhotoFull,
  boxProductPhotoPreview,
  boxProductPhotoFull,
  wishlistItemId,
  createWishlistItem,
  deleteWishlistItem,
}) => {
  const [show, setShow] = useState(false);
  const [cardWishlisted, setCardWishlisted] = useState(!!wishlistItemId);

  const { data } = useQuery(IS_LOGGED_IN);

  const imageSrc =
    productType === PRODUCT_TYPES.BOX
      ? boxProductPhotoPreview || placeholderBox
      : frontSidePhotoPreview || placeholderCard;
  const icon = cardWishlisted ? solidStar : faStar;
  const wishlistButtonText = cardWishlisted ? "Watching" : "Add to watchlist";

  const toggleWishlisted = () => {
    if (wishlistItemId) {
      deleteWishlistItem({
        variables: {
          id: wishlistItemId,
        },
        update: () => {
          setCardWishlisted(false);
        },
      });
    } else {
      createWishlistItem({
        variables: {
          cardParallelId: cardParallelId,
        },
        update: () => {
          setCardWishlisted(true);
        },
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-auto mb-4 mb-md-0 d-flex flex-row justify-content-center">
          <StyledProductThumbnail
            imageSrc={imageSrc}
            productType={productType}
            disabled={
              productType === PRODUCT_TYPES.BOX
                ? !boxProductPhotoFull
                : !frontSidePhotoFull
            }
            onClick={() => setShow(true)}
          />

          <CardImageModal
            show={show}
            setShow={setShow}
            frontImage={
              productType === PRODUCT_TYPES.BOX
                ? boxProductPhotoFull
                : frontSidePhotoFull
            }
            reverseSidePhoto={reverseSidePhotoFull}
          />
        </div>

        <div className="col-md d-flex flex-column mx-auto">
          <div className="row text-center text-md-left">
            <div className="col-md mb-3">
              <h6 className="text-speak mb-1">
                {cardFamily.program.name}{" "}
                <span className="text-deselected px-1">•</span>{" "}
                {cardFamily.name}
              </h6>
              <h2 className="text-shout">
                <span className="font-weight-bold">{cardName}</span>{" "}
                {cardNameSuffix}
              </h2>
              <div className="d-flex justify-content-center justify-content-md-start">
                <CardParallelBadges
                  name={parallel.name}
                  badgeTextColor={parallel.badgeTextColor}
                  badgeBorderColor={parallel.badgeBorderColor}
                  badgeGradientStart={parallel.badgeGradientStart}
                  badgeGradientEnd={parallel.badgeGradientEnd}
                  badgeGradientAngle={parallel.badgeGradientAngle}
                  serialLimit={serialLimit}
                />
              </div>
            </div>

            {data && data.isLoggedIn && (
              <div className="col-md-auto mb-4 mb-mb-0">
                <div className="d-flex flex-row text-nowrap justify-content-center justify-content-md-start align-items-start">
                  <button
                    type="button"
                    className={classNames("btn font-weight-bold rounded-pill", {
                      "btn-outline-primary": !cardWishlisted,
                      "btn-primary": cardWishlisted,
                    })}
                    onClick={() => toggleWishlisted()}
                  >
                    <FontAwesomeIcon icon={icon} className="mr-2" />
                    {wishlistButtonText}
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="text-center text-md-left mb-3">
            {productQuantityString(itemCount, productType)} in vault
            <span className="text-deselected px-1">•</span> {listingsCount || 0}{" "}
            listed for sale
          </div>

          <div className="row mt-auto">
            <div className="col-12 col-md-auto">
              {playerCard.teamPlayers.edges.map(({ node }) => (
                <PlayerListing key={node.player.id} player={node.player} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDetailSection;
