export const copyQueryCache = (cache, query, variables) => {
  const cachedData = (() => {
    try {
      return cache.readQuery({
        query: query,
        variables: variables,
      });
    } catch (e) {
      return null;
    }
  })();
  if (!cachedData) return;

  // Create a copy of the cached data to avoid in-memory edit
  const cachedDataCopy = JSON.parse(JSON.stringify(cachedData));

  return cachedDataCopy;
};

export // Cache writing helper functions:
const writeQueryCache = (cache, query, variables, dataToWrite) => {
  cache.writeQuery({
    query: query,
    variables: variables,
    data: dataToWrite,
  });
};
