import { gql } from "@apollo/client";

const GET_BOX_BREAKS_STOREFRONT = gql`
  query GET_BOX_BREAKS_STOREFRONT($sport: String, $era: String) {
    liveAuctions: auctions(
      sport: $sport
      era: $era
      state: ["LIVE"]
      orderBy: "scheduled_start_time_desc"
      first: 1
    ) {
      totalCount
    }
    liveBoxBreaks: boxBreaks(
      sport: $sport
      era: $era
      stateGroup: ["LIVE"]
      orderBy: "scheduled_start_time_desc"
      first: 1
    ) {
      totalCount
    }
  }
`;

export default GET_BOX_BREAKS_STOREFRONT;
