import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BOX_BREAK_STATES } from "../../../api/types";
import { EventEventType } from "../../../generated/schema";
import { Colors } from "../../../themes/Default";
import { pluralize } from "../../../utils/content";

import { Alert } from "../../Alert";
import { EventDescriptionSection } from "../../EventDescriptionSection";
import LoggedIn from "../../LoggedIn";
import { TimeBadge } from "../../TimeBadge";
import BreakMySpotsCountBadge from "../BreakMySpotsCountBadge";
import { formatCurrency } from "../../../utils/getCurrency";

const BoxBreakHeroImageDiv = styled.div`
  background: ${Colors.Porcelain};
  position: relative;

  // Full-bleed edge-to-edge image on mobile, padding once larger
  margin: -2rem -1rem 0 -1rem;
  @media (min-width: 48rem) {
    margin: 0;
  }

  ::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 66.66%; // 3:2 aspect ratio
  }
  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;

const BoxBreakHeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BoxBreakThumbnailList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

const BoxBreakThumbnailListItem = styled.li`
  background: ${Colors.Porcelain};
  width: 100px;
  height: 100px;
  margin-right: 8px;
  margin-bottom: 8px;

  :last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const BoxBreakThumbnailImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  margin-top: 1rem;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-top: 0.75rem;
`;

const Text = styled.p`
  margin: 0;

  span + & {
    margin-top: 0.75rem;
  }
`;

const BreakDetailHeaderSection = ({
  boxBreak,
  availableSpots,
  mySpotsCount,
  setSelectedSpotIds,
  setShowBuyForm,
  setShowRequestForm,
  userPurchasedMaxSpots = false,
}) => {
  const images = boxBreak.boxbreakphotoSet.edges.map(({ node }) => node);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const selectedImage = images[selectedImageIndex] || { photoMd: null };

  const firstSelectedSpotId = availableSpots[0] ? availableSpots[0].id : null;
  const [selectedSpotId, setSelectedSpotId] = useState(firstSelectedSpotId);
  const { purchaseOverrideUrl } = boxBreak;

  useEffect(() => {
    if (!availableSpots.map((spot) => spot.id).includes(selectedSpotId)) {
      setSelectedSpotId(firstSelectedSpotId);
    }
  }, [availableSpots, selectedSpotId, firstSelectedSpotId]);

  return (
    <EventDescriptionSection
      heading={boxBreak.name}
      description={boxBreak.description}
    >
      <EventDescriptionSection.MediaBlock>
        <BoxBreakHeroImageDiv className="mb-2">
          <BreakMySpotsCountBadge mySpotsCount={mySpotsCount} />
          <div
            className="d-flex flex-row align-self-start p-3 position-absolute"
            style={{ zIndex: 3 }}
          >
            <TimeBadge
              event={EventEventType.BREAK}
              scheduledStartTime={boxBreak.scheduledStartTime}
              state={boxBreak.state}
              STATES={BOX_BREAK_STATES}
            />
          </div>
          <BoxBreakHeroImage
            src={selectedImage.photoMd}
            alt={`Box break image ${selectedImageIndex + 1}`}
            style={{ zIndex: 1 }}
          />
        </BoxBreakHeroImageDiv>

        {images.length > 1 ? (
          <BoxBreakThumbnailList className="mb-2">
            {images.map((image, index) => (
              <BoxBreakThumbnailListItem key={index}>
                <BoxBreakThumbnailImage
                  src={image.photoXs}
                  alt={`Box break thumbnail ${index + 1}`}
                  onMouseEnter={() => setSelectedImageIndex(index)}
                />
              </BoxBreakThumbnailListItem>
            ))}
          </BoxBreakThumbnailList>
        ) : null}
      </EventDescriptionSection.MediaBlock>

      <EventDescriptionSection.DescriptionBlock>
        {boxBreak.maxSpotsPerUser > 0 && (
          <>
            <Alert>
              You can buy a maxiumum of{" "}
              {pluralize("box", boxBreak.maxSpotsPerUser, "boxes")} spots for
              this break.
            </Alert>
          </>
        )}

        {boxBreak.state === BOX_BREAK_STATES.PUBLISHED_LOCKED ? (
          <Alert>
            This break has not yet opened for entry, please check back later!
          </Alert>
        ) : ![
            BOX_BREAK_STATES.PUBLISHED_OPEN,
            BOX_BREAK_STATES.LIVE_OPEN,
          ].includes(boxBreak.state) ? (
          <Alert state="warning">
            <Text>This break has now closed for new entries.</Text>

            <StyledLink to={"/breaks"}>
              Buy into other breaks <FontAwesomeIcon icon={faChevronRight} />
            </StyledLink>
          </Alert>
        ) : availableSpots.length === 0 ? (
          <Alert state="warning">
            <Text>This break has sold out!</Text>

            <StyledLink to={"/breaks"}>
              Buy into other breaks <FontAwesomeIcon icon={faChevronRight} />
            </StyledLink>
          </Alert>
        ) : userPurchasedMaxSpots ? (
          <Alert state="warning">
            <span>⚠️</span>

            <Text>
              You have purchased the maximum number of spots available for this
              break. Good luck!
            </Text>

            <StyledLink to={"/breaks"}>
              Buy into other breaks <FontAwesomeIcon icon={faChevronRight} />
            </StyledLink>
          </Alert>
        ) : (
          <Container>
            <FormControl
              className="d-block align-baseline"
              variant={"outlined"}
            >
              <Select
                className="w-100 mb-3"
                value={selectedSpotId}
                onChange={(event) => setSelectedSpotId(event.target.value)}
              >
                {availableSpots.map((spot) => {
                  const priceLabel = formatCurrency(
                    spot.price,
                    spot.priceCurrency,
                  );
                  return (
                    <MenuItem key={spot.id} value={spot.id}>
                      #{spot.rank}: {spot.name || `Spot ${spot.rank}`} (
                      {priceLabel}) {spot.purchasedAt && " – Sold!"}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {purchaseOverrideUrl ? (
              <Button
                className="btn btn-lg btn-primary px-4"
                href={purchaseOverrideUrl}
                rel="noreferrer"
                target="_blank"
              >
                Buy this spot
              </Button>
            ) : (
              <Button
                className="btn btn-lg btn-primary px-4"
                onClick={() => {
                  setSelectedSpotIds([selectedSpotId]);
                  setShowBuyForm(true);
                }}
              >
                Buy this spot
              </Button>
            )}

            <a href={"#buy-spots-section"} className="mx-3">
              View all spots{" "}
              <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
            </a>

            <LoggedIn additionalChecks={boxBreak.allowPersonalBreakRequests}>
              <Container>
                <Button
                  className="btn btn-primary px-3"
                  onClick={() => setShowRequestForm(true)}
                >
                  Request a break from your collection
                </Button>
              </Container>
            </LoggedIn>
          </Container>
        )}

        <h6 className="font-weight-bold text-whisper pt-3 mt-3">
          BREAK FORMAT
        </h6>
        <h5 className="font-weight-bold text-shout">{boxBreak.format.name}</h5>
        <p className="text-speak mb-0" style={{ whiteSpace: "pre-line" }}>
          {boxBreak.format.description}
        </p>
      </EventDescriptionSection.DescriptionBlock>
    </EventDescriptionSection>
  );
};

export default BreakDetailHeaderSection;
