import styled from "styled-components";
import { Link } from "react-router-dom";
import { rgba } from "polished";

import { Colors } from "../../../../themes/Default";

export const StyledLink = styled(Link)`
  background-color: ${Colors.Athens};
  border-radius: 0.3rem;
  color: ${Colors.Astronaut};
  display: block;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0.375rem 0.75rem;
  text-align: center;
  width: 100%;

  :hover {
    background-color: #cfd8df;
    color: ${Colors.Astronaut};
    text-decoration: none;
  }

  :focus {
    background-color: ${rgba(Colors.Athens, 0.75)};
  }
`;
