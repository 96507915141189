import { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { useQueryState } from "react-router-use-location-state";
import { useDebounce } from "use-debounce";

import { logAmplitudeEvent } from "../../utils/analytics";
import { ACTIVITY_EVENTS, BUYING_EVENTS } from "../../utils/analytics/events";
import { pluralize } from "../../utils/content";

import GET_MY_ACTIVITY_METADATA from "../../api/queries/GET_MY_ACTIVITY_METADATA";
import GET_MY_GROUPED_BIDS from "../../api/queries/GET_MY_GROUPED_BIDS";
import GROUPED_BID_CLOSE from "../../api/mutations/GROUPED_BID_CLOSE";

import BidListCardItem from "./BidListCardItem";
import EmptySection from "./EmptySection";
import Loader from "../Loader";
import ErrorNotice from "../ErrorNotice";
import LoadMoreButton from "../LoadMoreButton";
import SearchSortQueryControls from "../SearchSortQueryControls";

const sortOptions = [
  {
    key: "newest",
    label: "Newest",
    value: "created_at_desc",
    description: "Your most recently placed bids",
  },
  {
    key: "oldest",
    label: "Oldest",
    value: "created_at_asc",
    description: "View your bids placed longest ago",
  },
  {
    key: "expiring-soon",
    label: "Expiring soon",
    value: "expires_after_asc,id",
    description: "Sort offers by how close they are to expiring",
  },
  {
    key: "highest-offer-price",
    label: "Highest offer price",
    value: "buy_price_desc,id",
    description: "Your biggest offers by bid price",
  },
  {
    key: "lowest-offer-price",
    label: "Lowest offer price",
    value: "buy_price_asc,id",
    description: "See your bids at the lowest price points",
  },
  {
    key: "lowest-ask-price",
    label: "Lowest ask price",
    value: "lowest_ask_price_asc,id",
    description: "By the lowest asking price from sellers",
  },
  {
    key: "closest-listing",
    label: "Closest listing",
    value: "lowest_ask_percentage_desc,id",
    description: "Percentage your offer reaches of the ask price",
  },
];

const BidSection = () => {
  useEffect(() => logAmplitudeEvent(ACTIVITY_EVENTS.OFFERS.PAGE_VIEWED), []);

  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const [sortOrderKey, setSortOrderKey] = useQueryState(
    "sort",
    sortOptions[0].key,
  );
  const sortOrder =
    sortOptions.find((o) => o.key === sortOrderKey) || sortOptions[0];

  const { data, loading, error, fetchMore } = useQuery(GET_MY_GROUPED_BIDS, {
    variables: {
      searchQuery: debouncedSearchQuery,
      orderBy: sortOrder.value,
    },
    fetchPolicy: "network-only",
  });

  const [loadingMore, setLoadingMore] = useState(false);

  const mutationRefetches = [
    { query: GET_MY_ACTIVITY_METADATA },
    {
      query: GET_MY_GROUPED_BIDS,
      variables: {
        searchQuery: debouncedSearchQuery,
        orderBy: sortOrder.value,
      },
      fetchPolicy: "network-only",
    },
  ];

  const [groupedBidClose] = useMutation(GROUPED_BID_CLOSE, {
    refetchQueries: mutationRefetches,
    onCompleted({ groupedBidClose }) {
      if (groupedBidClose.errors && groupedBidClose.errors.length > 0) {
        logAmplitudeEvent(BUYING_EVENTS.EXISTING_OFFER.CANCEL.FAILED, {
          Error: groupedBidClose.errors[0],
        });
      } else {
        logAmplitudeEvent(BUYING_EVENTS.EXISTING_OFFER.CANCEL.CANCELED);
      }
    },
  });

  return (
    <div className="mt-4 mb-3">
      <SearchSortQueryControls
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        sortOrderKey={sortOrderKey}
        setSortOrderKey={setSortOrderKey}
        sortOptions={sortOptions}
        trackingLabel={ACTIVITY_EVENTS.OFFERS.SEARCH_UPDATED}
      />

      {error && <ErrorNotice error={error} />}
      {loading && <Loader />}

      {!error && !loading && (
        <>
          <div className="d-flex flex-row justify-content-between align-items-baseline mt-4 mb-3">
            <span className="font-weight-bold">
              {pluralize("open offer", data.myGroupedBids.totalCount)}
            </span>
          </div>

          {data.myGroupedBids.edges.length ? (
            <div className="row">
              {data.myGroupedBids.edges.map(({ node }) => (
                <div className="col-12 mb-3" key={node.id}>
                  <BidListCardItem
                    groupedBid={node}
                    groupedBidClose={groupedBidClose}
                    acceptListingRefetches={mutationRefetches}
                  />
                </div>
              ))}
            </div>
          ) : (
            <EmptySection />
          )}

          {data.myGroupedBids.pageInfo.hasNextPage && (
            <LoadMoreButton
              loadingMore={loadingMore}
              setLoadingMore={setLoadingMore}
              onLoadMore={() =>
                fetchMore({
                  variables: {
                    cursor: data.myGroupedBids.pageInfo.endCursor,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    setLoadingMore(false);
                    if (!fetchMoreResult) return prev;

                    fetchMoreResult.myGroupedBids.edges = [
                      ...prev.myGroupedBids.edges,
                      ...fetchMoreResult.myGroupedBids.edges,
                    ];
                    return fetchMoreResult;
                  },
                })
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default BidSection;
