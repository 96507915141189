import Skeleton from "@material-ui/lab/Skeleton";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { rgba } from "polished";

import { logAmplitudeEvent } from "../utils/analytics";
import { MARKETPLACE_EVENTS } from "../utils/analytics/events";

import { PRODUCT_TYPES } from "../api/types";

import playerPlaceholder from "../images/player-outline.png";

import CardParallelBadges from "./CardParallelBadges";
import CardParallelProductPhoto from "./CardParallelProductPhoto";
import LazyLoadImage from "./LazyLoadImage";

const BorderColor = "rgba(0, 0, 0, 0.1)";
const Havelock = "#4e8ad4";
const DefaultThemeColor = Havelock;
const UpperContainerGradientStart = "#ffffff";
const UpperContainerGradientEnd = "#d1ddec";

const OuterContainer = styled.div`
  transition: all 200ms ease-in-out;
  display: block;
  flex-direction: column;
  border: 1px solid ${BorderColor};
  border-radius: 0.25rem;
  overflow: hidden;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    border-color: ${Havelock};
  }
`;

const UpperContainer = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: ${UpperContainerGradientEnd};
  background: radial-gradient(
    ${UpperContainerGradientStart},
    ${UpperContainerGradientEnd}
  );
`;

const UpperInnerContainer = styled.div`
  padding: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const StyledProductPhotoContainer = styled.div`
  height: 140px;

  // Aspect ratio for boxes is 1:1.28
  // Aspect ratio for cards is 1:1.4
  width: ${(props) =>
    props.productType === PRODUCT_TYPES.BOX ? "110px" : "100px"};
`;

const PlayerHeadshot = styled(LazyLoadImage)`
  position: absolute;
  height: 112px;
  width: 112px;
  min-width: 48px;
  max-width: 144px;
  top: auto;
  bottom: 0;
  right: 0.5rem;
  filter: drop-shadow(0 0 8px ${rgba(UpperContainerGradientEnd, 0.8)})
    drop-shadow(0 0 2px ${rgba(UpperContainerGradientEnd, 0.8)});
`;

const Banner = styled.div`
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.125rem 0.5rem;
  background-color: ${(props) => props.themeColor || DefaultThemeColor};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LowerContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5rem;
  border-top: 1px solid ${BorderColor};
  ${(props) =>
    !props.bannerText &&
    css`
      border-top: 1px solid ${BorderColor};
    `}
`;

export const CardParallelTallContainerSkeleton = ({
  withBanner = false,
  children,
}) => {
  return (
    <OuterContainer className="card">
      <Skeleton variant="rect" width="100%" height="156px" />

      {withBanner && (
        <Skeleton variant="rect" width={"20%"}>
          <Banner>Banner text</Banner>
        </Skeleton>
      )}

      <LowerContainer>
        <Skeleton variant="text">
          <p className="mb-1" style={{ fontSize: "0.75rem" }}>
            2020 Donruss Rated Rookies
          </p>
        </Skeleton>

        <Skeleton variant="text">
          <h5 style={{ fontSize: "1.25rem" }}>Austin Hays #245</h5>
        </Skeleton>

        <Skeleton variant="text">
          <CardParallelBadges name={"Base"} />
        </Skeleton>

        {children}
      </LowerContainer>
    </OuterContainer>
  );
};

const CardParallelTallContainer = ({
  linkPath,
  onClick,
  productType,
  cardPhoto,
  playerHeadshot,
  cardProgram,
  cardFamily,
  fullCardParallelName,
  cardName,
  cardSetNumber,
  parallel,
  serialLimit,
  grade,
  bannerText,
  themeColor = DefaultThemeColor,
  widgetName = "Widget",
  position = 0,
  children,
}) => {
  const {
    name: parallelName,
    badgeTextColor: parallelBadgeTextColor,
    badgeBorderColor: parallelBadgeBorderColor,
    badgeGradientStart: parallelBadgeGradientStart,
    badgeGradientEnd: parallelBadgeGradientEnd,
    badgeGradientAngle: parallelBadgeGradientAngle,
  } = parallel;

  const productPhoto = (
    <StyledProductPhotoContainer productType={productType}>
      <CardParallelProductPhoto
        productType={productType}
        image={cardPhoto}
        grade={grade}
      />
    </StyledProductPhotoContainer>
  );

  return (
    <OuterContainer className="card">
      {linkPath && (
        <Link
          to={linkPath}
          className="stretched-link"
          onClick={() =>
            logAmplitudeEvent(
              MARKETPLACE_EVENTS.STOREFRONT.WIDGET_ITEM_CLICKED,
              {
                "Widget Name": widgetName,
                "Widget Item Type": "Card Parallel",
                "Widget Item Name": fullCardParallelName,
                "Widget Item Position": position,
              },
            )
          }
        />
      )}

      {!linkPath && onClick && (
        <button
          onClick={onClick}
          className="btn btn-link rounded-0 border-0 stretched-link p-0 m-0"
        />
      )}

      <UpperContainer>
        <UpperInnerContainer>
          {linkPath ? <Link to={linkPath}>{productPhoto}</Link> : productPhoto}

          {productType !== PRODUCT_TYPES.BOX && (
            <PlayerHeadshot
              alt=""
              placeholder={playerPlaceholder}
              src={playerHeadshot || playerPlaceholder}
            />
          )}
        </UpperInnerContainer>
      </UpperContainer>

      {bannerText && <Banner themeColor={themeColor}>{bannerText}</Banner>}

      <LowerContainer>
        <p
          className="text-speak text-nowrap text-truncate mb-1"
          style={{ fontSize: "0.75rem" }}
        >
          {cardProgram} <span className="text-deselected">•</span> {cardFamily}
        </p>

        {linkPath ? (
          <Link to={linkPath}>
            <h5
              className="text-nowrap text-truncate mb-2"
              style={{ fontSize: "1.25rem" }}
            >
              <span className="font-weight-bold">{cardName}</span>{" "}
              {cardSetNumber}
            </h5>
          </Link>
        ) : (
          <h5
            className="text-nowrap text-truncate mb-2"
            style={{ fontSize: "1.25rem" }}
          >
            <span className="font-weight-bold">{cardName}</span> {cardSetNumber}
          </h5>
        )}
        <CardParallelBadges
          name={parallelName}
          badgeTextColor={parallelBadgeTextColor}
          badgeBorderColor={parallelBadgeBorderColor}
          badgeGradientStart={parallelBadgeGradientStart}
          badgeGradientEnd={parallelBadgeGradientEnd}
          badgeGradientAngle={parallelBadgeGradientAngle}
          serialLimit={serialLimit}
        />

        {children}
      </LowerContainer>
    </OuterContainer>
  );
};

export default CardParallelTallContainer;
