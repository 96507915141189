import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";

import { StripePaymentMethodNode } from "../../../../generated/schema";

import { checkCardIsExpired } from "../../utils/checkCardIsExpired";

import {
  Icon,
  CardContainer,
  Container,
  Text,
  CardIconContainer,
  CardDetails,
  CardExpiry,
  CardInfo,
} from "./styles";

interface Props {
  paymentDetails: StripePaymentMethodNode;
  paymentMethodSelect?: boolean;
  showDefault?: boolean;
}
export const PaymentMethodInfo = ({
  paymentDetails,
  showDefault,
  paymentMethodSelect = false,
}: Props) => {
  const icons: { [key: string]: IconDefinition } = {
    amex: faCcAmex,
    diners_club: faCcDinersClub,
    diners: faCcDinersClub,
    discover: faCcDiscover,
    jcb: faCcJcb,
    mastercard: faCcMastercard,
    unionpay: faCreditCard,
    unknown: faCreditCard,
    visa: faCcVisa,
  };

  const {
    brand,
    lastFourDigits,
    expiryMonth,
    expiryYear,
    defaultPaymentMethod,
  } = paymentDetails;

  const isCardExpired = checkCardIsExpired(expiryMonth, expiryYear);
  const showDefaultCard = showDefault && !isCardExpired && defaultPaymentMethod;

  return (
    <Container paymentMethodSelect={paymentMethodSelect}>
      <CardIconContainer>
        <Icon
          icon={icons[brand] || faCreditCard}
          size="2x"
          $cardExpired={isCardExpired}
        />
      </CardIconContainer>
      <CardContainer>
        <CardDetails cardExpired={isCardExpired}>
          {brand} **** {lastFourDigits}
        </CardDetails>
        <CardExpiry cardExpired={isCardExpired}>
          {isCardExpired ? "Expired" : "Expires"} {expiryMonth}/
          {expiryYear.slice(-2)}
        </CardExpiry>
      </CardContainer>

      {showDefaultCard && (
        <CardInfo cardExpired={isCardExpired}>
          <Text cardExpired={isCardExpired}>Default</Text>
        </CardInfo>
      )}

      {isCardExpired && (
        <CardInfo cardExpired={isCardExpired}>
          <Text cardExpired={isCardExpired}>Expired</Text>
        </CardInfo>
      )}
    </Container>
  );
};
