import { useMutation, useQuery } from "@apollo/client";
import { faBell, faSquareFull } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import UPDATE_NOTIFICATION_PREFERENCES from "../api/mutations/UPDATE_NOTIFICATION_PREFERENCES";
import GET_MY_NOTIFICATION_PREFERENCES from "../api/queries/GET_MY_NOTIFICATION_PREFERENCES";

import ErrorNotice from "../components/ErrorNotice";
import Loader from "../components/Loader";
import {
  PageSection,
  PageSectionTypes,
  PageSectionWidths,
} from "../components/PageSection";
import ToggleSwitch from "../components/ToggleSwitch";
import { logAmplitudeEvent } from "../utils/analytics";
import { ACCOUNT_EVENTS } from "../utils/analytics/events";

// Transform API formatted preferences objects into a frontend-convenient map
const preferencesArrayToMap = (preferencesArray) => {
  return preferencesArray.reduce((result, item, index, array) => {
    result[item.notificationType] = item.targetType;
    return result;
  }, {});
};

// Transform frontend-convenient preferences format back to API format
const preferencesMapToArray = (preferencesMap) => {
  let preferencesArray = [];
  for (const [key, value] of Object.entries(preferencesMap)) {
    preferencesArray.push({
      notificationType: key,
      targetType: value,
    });
  }
  return preferencesArray;
};

const optionsPercentage = [
  { label: "95%", value: 95 },
  { label: "90%", value: 90 },
  { label: "85%", value: 85 },
  { label: "80%", value: 80 },
  { label: "75%", value: 75 },
  { label: "50%", value: 50 },
  { label: "25%", value: 25 },
  { label: "any %", value: 0 },
];

const defaultAccountPreferences = () => ({
  buyTargetPercentage: 75,
  sellTargetPercentage: 75,
});

const NotificationTitleLabel = styled.h4`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0;
`;

const defaultNotificationPreferences = (data) =>
  // Merge server-provided default values into frontend defaults, to ensure
  // interface can populate itself if API values change behind scenes
  Object.assign(
    {
      BUY_ORDERS_CREATED: "IGNORE",
      BUY_ORDERS_COMPLETED: "EMAIL",
      BUY_ORDERS_NEW_LOWEST_LISTING: "EMAIL",
      BUY_ORDERS_OUTBID: "EMAIL",
      BUY_ORDERS_SUSPENDED: "EMAIL",
      BUY_ORDERS_EXPIRED: "EMAIL",
      SELL_ORDERS_CREATED: "IGNORE",
      SELL_ORDERS_COMPLETED: "EMAIL",
      SELL_ORDERS_NEW_BEST_OFFER: "EMAIL",
      SELL_ORDERS_UNDERCUT: "EMAIL",
      SELL_ORDERS_EXPIRED: "EMAIL",
      SUBMISSION_COMPLETED: "SUBMISSION_COMPLETED",
      BREAK_JOINED: "EMAIL",
      BREAK_STARTED: "EMAIL",
      AUCTION_WINNING_BID: "EMAIL",
      DEPOSIT_RECEIPT: "EMAIL",
      WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION: "EMAIL",
      WITHDRAWAL_REQUEST_STRIPE_REFUND: "EMAIL",
      WITHDRAWAL_REQUEST_PAYPAL_REJECTED: "EMAIL",
      WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS: "EMAIL",
      WITHDRAWAL_SUBMISSION_FEE: "EMAIL",
      SHIP_HOME_REQUEST_CONFIRMATION: "EMAIL",
    },
    preferencesArrayToMap(data.defaultNotificationPreferences),
  );

const initialAccountPreferences = (data) =>
  // Merge user's own values into defaults
  Object.assign({}, defaultAccountPreferences(), data.myAccount.userprofile);

const initialNotificationPreferences = (data) =>
  // Merge user's own values into the front-and-backend merged defaults
  Object.assign(
    {},
    defaultNotificationPreferences(data),
    preferencesArrayToMap(data.myNotificationPreferences),
  );

const NotificationPreferencesForm = ({ data }) => {
  // Initialise user preferences by blending user-set values into defaults
  const [accountPreferences, setAccountPreferences] = useState(
    initialAccountPreferences(data),
  );
  const [notificationPreferences, setNotificationPreferences] = useState(
    initialNotificationPreferences(data),
  );

  // Helpers for replacing individual attributes within state objects
  const updateAccountPreferences = (updated) => {
    mutation({
      variables: { ...updated },
    });
    setAccountPreferences((current) => ({ ...current, ...updated }));
    logAmplitudeEvent(ACCOUNT_EVENTS.NOTIFICATION_PREFERENCES.UPDATED, {
      "Updated Preferences": updated,
    });
  };

  const updateNotificationPreferences = (updated) => {
    mutation({
      variables: { notificationPreferences: preferencesMapToArray(updated) },
    });
    setNotificationPreferences((current) => ({ ...current, ...updated }));
    logAmplitudeEvent(ACCOUNT_EVENTS.NOTIFICATION_PREFERENCES.UPDATED, {
      "Updated Preferences": updated,
    });
  };

  // Helpers for checking or updating the current state of a preference
  const emailEnabledForNotification = (notificationType) =>
    notificationPreferences[notificationType] === "EMAIL";
  const toggleNotificationPreference = (notificationType) =>
    emailEnabledForNotification(notificationType)
      ? updateNotificationPreferences({ [notificationType]: "IGNORE" })
      : updateNotificationPreferences({ [notificationType]: "EMAIL" });

  const [mutation] = useMutation(UPDATE_NOTIFICATION_PREFERENCES);

  return (
    <div className="row">
      <div className="col-md-5 col-lg-4 col-xl-3">
        <div className="bg-white rounded border mb-5 p-3 p-md-4 text-center">
          <p className="fa-4x mb-3 text-center d-none d-md-block">
            <FontAwesomeIcon
              style={{
                background: "linear-gradient(135deg, #58B55C, #4e8ad4)",
              }}
              icon={faBell}
              mask={faSquareFull}
              inverse
            />
          </p>

          <h4 className="font-weight-bold text-shout mb-3">
            Choose when you want to be notified
          </h4>

          <p className="mb-0">
            We know emails can get out of hand. You can opt in or out of the
            notifications which interest you, and set your own thresholds for
            when certain events are triggered.
          </p>
        </div>
      </div>

      <div className="col-md">
        <h3 className="font-weight-bold mb-3">Buyer notifications</h3>
        <div className="bg-white rounded border mb-5 p-3 p-md-4">
          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Offer created</NotificationTitleLabel>
              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("BUY_ORDERS_CREATED")}
                  onChange={(event) =>
                    toggleNotificationPreference("BUY_ORDERS_CREATED")
                  }
                  name="buyOrdersCreatedSwitch"
                />
              </FormGroup>
            </div>
            <p className="mb-0">
              A confirmation email when you place an offer or purchase a card
              for the seller’s asking price.
            </p>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Offer accepted</NotificationTitleLabel>
              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("BUY_ORDERS_COMPLETED")}
                  onChange={(event) =>
                    toggleNotificationPreference("BUY_ORDERS_COMPLETED")
                  }
                  name="buyOrdersCompletedSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              A seller has sold you a card by accepting an offer you placed.
            </p>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                New lowest listing available
              </NotificationTitleLabel>
              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification(
                    "BUY_ORDERS_NEW_LOWEST_LISTING",
                  )}
                  onChange={(event) =>
                    toggleNotificationPreference(
                      "BUY_ORDERS_NEW_LOWEST_LISTING",
                    )
                  }
                  name="buyOrdersNewLowestListingSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              A card you’re offering to buy has had its lowest listing price
              reduced.
            </p>

            <div
              className="mt-2"
              style={{
                opacity: emailEnabledForNotification(
                  "BUY_ORDERS_NEW_LOWEST_LISTING",
                )
                  ? 1
                  : 0.4,
              }}
            >
              <span className="mr-1">Your offer is now </span>
              <FormControl
                className="d-inline align-baseline"
                disabled={
                  !emailEnabledForNotification("BUY_ORDERS_NEW_LOWEST_LISTING")
                }
                variant={"outlined"}
                size={"small"}
              >
                <Select
                  autoWidth={true}
                  value={accountPreferences.buyTargetPercentage}
                  onChange={(event) =>
                    updateAccountPreferences({
                      buyTargetPercentage: event.target.value,
                    })
                  }
                >
                  {optionsPercentage.map((option) => (
                    <MenuItem key={"buy" + option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span className="ml-1">
                {accountPreferences.buyTargetPercentage ? " or more" : " "} of
                the asking price.
              </span>
            </div>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Offer outbid</NotificationTitleLabel>
              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("BUY_ORDERS_OUTBID")}
                  onChange={(event) =>
                    toggleNotificationPreference("BUY_ORDERS_OUTBID")
                  }
                  name="buyOrdersOutbidSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              Another buyer has outbid you and you no longer have the best offer
              price for a card.
            </p>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Offers expired</NotificationTitleLabel>
              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("BUY_ORDERS_EXPIRED")}
                  onChange={(event) =>
                    toggleNotificationPreference("BUY_ORDERS_EXPIRED")
                  }
                  name="buyOrdersExpiredSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              A daily summary when you have offers that expired in the previous
              24 hours.
            </p>
          </div>

          <div className="">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Offers suspended</NotificationTitleLabel>
              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("BUY_ORDERS_SUSPENDED")}
                  onChange={(event) =>
                    toggleNotificationPreference("BUY_ORDERS_SUSPENDED")
                  }
                  name="buyOrdersSuspendedSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              Some of your offers have been suspended as your balance has
              dropped too low to pay for them.
            </p>
          </div>
        </div>

        <h3 className="font-weight-bold mb-3">Seller notifications</h3>
        <div className="bg-white rounded border mb-5 p-3 p-md-4">
          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                Card listed for sale
              </NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("SELL_ORDERS_CREATED")}
                  onChange={(event) =>
                    toggleNotificationPreference("SELL_ORDERS_CREATED")
                  }
                  name="sellOrdersCreatedSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              You instantly sold a card by accepting a buyer’s offer price.
            </p>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Card listing sold</NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("SELL_ORDERS_COMPLETED")}
                  onChange={(event) =>
                    toggleNotificationPreference("SELL_ORDERS_COMPLETED")
                  }
                  name="sellOrdersCompletedSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              A buyer has purchased one of your cards for your listed asking
              price.
            </p>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                Card listings expired
              </NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("SELL_ORDERS_EXPIRED")}
                  onChange={(event) =>
                    toggleNotificationPreference("SELL_ORDERS_EXPIRED")
                  }
                  name="sellOrdersExpiredSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              A daily summary when you have card listings that expired in the
              previous 24 hours.
            </p>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                New best offer received
              </NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification(
                    "SELL_ORDERS_NEW_BEST_OFFER",
                  )}
                  onChange={(event) =>
                    toggleNotificationPreference("SELL_ORDERS_NEW_BEST_OFFER")
                  }
                  name="sellOrdersNewBestOfferSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              An item you’re selling has had its best offer price increased.
            </p>

            <div
              className="mt-2"
              style={{
                opacity: emailEnabledForNotification(
                  "SELL_ORDERS_NEW_BEST_OFFER",
                )
                  ? 1
                  : 0.4,
              }}
            >
              <span className="mr-1">The offer is now </span>
              <FormControl
                className="d-inline align-baseline"
                disabled={
                  !emailEnabledForNotification("SELL_ORDERS_NEW_BEST_OFFER")
                }
                variant={"outlined"}
                size={"small"}
              >
                <Select
                  value={accountPreferences.sellTargetPercentage}
                  onChange={(event) =>
                    updateAccountPreferences({
                      sellTargetPercentage: event.target.value,
                    })
                  }
                >
                  {optionsPercentage.map((option) => (
                    <MenuItem key={"sell" + option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span className="ml-1">
                {accountPreferences.sellTargetPercentage ? " or more" : " "} of
                your asking price.
              </span>
            </div>
          </div>

          <div className="">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Listing undercut</NotificationTitleLabel>
              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("SELL_ORDERS_UNDERCUT")}
                  onChange={(event) =>
                    toggleNotificationPreference("SELL_ORDERS_UNDERCUT")
                  }
                  name="sellOrdersUndercutSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              Another seller has undercut your asking price and you no longer
              have the lowest priced listing for a card.
            </p>
          </div>
        </div>

        <h3 className="font-weight-bold mb-3">Submissions notifications</h3>
        <div className="bg-white rounded border mb-5 p-3 p-md-4">
          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                Submission completed
              </NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("SUBMISSION_COMPLETED")}
                  onChange={(event) =>
                    toggleNotificationPreference("SUBMISSION_COMPLETED")
                  }
                  name="submissionCompletedSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              StarStock has processed your submission and has listed all
              accepted and rejected items.
            </p>
          </div>
          <div className="">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                Submission fee receipt
              </NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification(
                    "WITHDRAWAL_SUBMISSION_FEE",
                  )}
                  onChange={(event) =>
                    toggleNotificationPreference("WITHDRAWAL_SUBMISSION_FEE")
                  }
                  name="withdrawalSubmissionFeeSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              StarStock has successfully deducted your submission fee from your
              account.
            </p>
          </div>
        </div>

        <h3 className="font-weight-bold mb-3">Live breaks notifications</h3>
        <div className="bg-white rounded border mb-5 p-3 p-md-4">
          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Joined a break</NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("BREAK_JOINED")}
                  onChange={(event) =>
                    toggleNotificationPreference("BREAK_JOINED")
                  }
                  name="breakJoinedSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              Confirmation when you first buy a spot in a break. Additional
              spots in the same break will not trigger emails.
            </p>
          </div>

          <div className="">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Break is live</NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("BREAK_STARTED")}
                  onChange={(event) =>
                    toggleNotificationPreference("BREAK_STARTED")
                  }
                  name="breakStartedSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              A break you have bought into has just started its live stream.
            </p>
          </div>
        </div>

        <h3 className="font-weight-bold mb-3">Auctions notifications</h3>
        <div className="bg-white rounded border mb-5 p-3 p-md-4">
          <div className="d-flex flex-row justify-content-between align-items-center mb-3">
            <NotificationTitleLabel>Winning bid</NotificationTitleLabel>

            <FormGroup>
              <ToggleSwitch
                checked={emailEnabledForNotification("AUCTION_WINNING_BID")}
                onChange={(event) =>
                  toggleNotificationPreference("AUCTION_WINNING_BID")
                }
                name="auctionWinningBid"
              />
            </FormGroup>
          </div>

          <p className="mb-0">
            Confirmation receipt when you are the winning bidder in an auction.
          </p>
        </div>

        <h3 className="font-weight-bold mb-3">Wallet notifications</h3>

        <div className="bg-white rounded border mb-5 p-3 p-md-4">
          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>Deposit receipt</NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification("DEPOSIT_RECEIPT")}
                  onChange={(event) =>
                    toggleNotificationPreference("DEPOSIT_RECEIPT")
                  }
                  name="depositReceiptSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              You successfully made a deposit into your StarStock wallet.
            </p>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                Withdrawal payment confirmation
              </NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification(
                    "WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION",
                  )}
                  onChange={(event) =>
                    toggleNotificationPreference(
                      "WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION",
                    )
                  }
                  name="withdrawalRequestPaymentConfirmationSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              StarStock has sent you a payment via check or PayPal.
            </p>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                Withdrawal refund confirmation
              </NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification(
                    "WITHDRAWAL_REQUEST_STRIPE_REFUND",
                  )}
                  onChange={(event) =>
                    toggleNotificationPreference(
                      "WITHDRAWAL_REQUEST_STRIPE_REFUND",
                    )
                  }
                  name="withdrawalRequestStripeRefundSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              StarStock has refunded your original debit/credit card.
            </p>
          </div>

          <div className="border-bottom pb-3 pb-md-4 mb-3 mb-md-4">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                Withdrawal request cannot be completed
              </NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification(
                    "WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS",
                  )}
                  onChange={(event) =>
                    toggleNotificationPreference(
                      "WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS",
                    )
                  }
                  name="withdrawalRequestInsufficientFundsSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              Your withdrawal request cannot be completed due to insufficient
              funds.
            </p>
          </div>

          <div className="">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <NotificationTitleLabel>
                Withdrawal PayPal rejection notification
              </NotificationTitleLabel>

              <FormGroup>
                <ToggleSwitch
                  checked={emailEnabledForNotification(
                    "WITHDRAWAL_REQUEST_PAYPAL_REJECTED",
                  )}
                  onChange={(event) =>
                    toggleNotificationPreference(
                      "WITHDRAWAL_REQUEST_PAYPAL_REJECTED",
                    )
                  }
                  name="withdrawalRequestPaypalRejectedSwitch"
                />
              </FormGroup>
            </div>

            <p className="mb-0">
              StarStock cannot complete your withdrawal as the PayPal email
              provided does not accept payments.
            </p>
          </div>
        </div>

        <h3 className="font-weight-bold mb-3">Ship home notifications</h3>
        <div className="bg-white rounded border mb-5 p-3 p-md-4">
          <div className="d-flex flex-row justify-content-between align-items-center mb-3">
            <NotificationTitleLabel>
              Ship home request confirmation
            </NotificationTitleLabel>

            <FormGroup>
              <ToggleSwitch
                checked={emailEnabledForNotification(
                  "SHIP_HOME_REQUEST_CONFIRMATION",
                )}
                onChange={(event) =>
                  toggleNotificationPreference("SHIP_HOME_REQUEST_CONFIRMATION")
                }
                name="shipHomeRequestConfirmationSwitch"
              />
            </FormGroup>
          </div>
          <p className="mb-0">
            StarStock has received your request to ship home items from your
            collection.
          </p>
        </div>
      </div>
    </div>
  );
};

const NotificationPreferences = () => {
  const { data, loading, error } = useQuery(GET_MY_NOTIFICATION_PREFERENCES);

  useEffect(() => {
    logAmplitudeEvent(ACCOUNT_EVENTS.NOTIFICATION_PREFERENCES.PAGE_VIEWED);
  }, []);

  return (
    <>
      <Helmet>
        <title>Notification Preferences</title>
      </Helmet>

      <PageSection
        width={PageSectionWidths.regular}
        type={PageSectionTypes.foreground}
        removePaddingBottom
      >
        <h2 className="font-weight-bold mb-3 mb-md-4 pt-md-3">
          Notification Settings
        </h2>
      </PageSection>

      <PageSection
        width={PageSectionWidths.regular}
        type={PageSectionTypes.background}
        borderBottom={false}
      >
        {error ? (
          <ErrorNotice error={error} />
        ) : loading ? (
          <Loader />
        ) : (
          <NotificationPreferencesForm data={data} />
        )}
      </PageSection>
    </>
  );
};

export default NotificationPreferences;
