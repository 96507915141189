import { useState } from "react";

import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

import { financial } from "../../utils/common";
import { productQuantityString } from "../../utils/content";
import { displayNameForGrade } from "../../utils/grades";

import IS_LOGGED_IN from "../../api/queries/IS_LOGGED_IN";

import BuyModal from "./BuyModal";
import LoginModal from "./LoginModal";
import SummaryDataRow from "../SummaryDataRow";

const BuySection = ({
  slug,
  playerCard,
  cardParallelId,
  cardParallelProductType,
  parallelName,
  serialLimit,
  grade,
  gradedCardParallelId,
  openSellOrders,
  openSellOrderMinPrice,
  myOpenBuyOrderCount,
}) => {
  const [show, setShow] = useState(false);

  const { data: loggedInData } = useQuery(IS_LOGGED_IN);

  const openSellOrderCount = openSellOrders.length;

  return (
    <>
      <div className="row no-gutters d-flex align-items-center mb-3">
        <div className="col-auto d-none d-sm-block mr-3">
          <FontAwesomeIcon
            icon={faLayerGroup}
            size="2x"
            className="text-success"
            fixedWidth
          />
        </div>

        <div className="col d-flex flex-row justify-content-between align-items-center mr-3">
          <SummaryDataRow
            summaryDataItems={[
              {
                label:
                  openSellOrderCount > 0
                    ? `${openSellOrderCount} for sale from`
                    : "0 for sale",
                value: openSellOrderMinPrice
                  ? `$${financial(openSellOrderMinPrice)}`
                  : "–",
                valueColor: openSellOrderMinPrice
                  ? "text-success"
                  : "text-deselected",
              },
            ]}
          />
          <SummaryDataRow
            configAlignItems="justify-content-end"
            summaryDataItems={[
              {
                label: "My offers",
                value:
                  myOpenBuyOrderCount > 0
                    ? productQuantityString(
                        myOpenBuyOrderCount,
                        cardParallelProductType,
                      )
                    : "No offers",
                valueColor:
                  myOpenBuyOrderCount > 0 ? "text-shout" : "text-deselected",
                textAlignment: "text-right",
              },
            ]}
          />
        </div>

        <div className="col-5">
          <button
            className="btn btn-lg btn-mantis btn-block text-white text-nowrap shadow px-2"
            onClick={() => setShow(true)}
          >
            <span className="font-weight-bold">Buy</span> or Offer
          </button>
        </div>
      </div>

      {loggedInData && loggedInData.isLoggedIn ? (
        <BuyModal
          show={show}
          setShow={setShow}
          slug={slug}
          gradeId={grade.id}
          gradeName={displayNameForGrade(grade)}
          gradedCardParallelId={gradedCardParallelId}
          cardParallelId={cardParallelId}
          cardParallelProductType={cardParallelProductType}
          playerCard={playerCard}
          parallelName={parallelName}
          serialLimit={serialLimit}
          openSellOrders={openSellOrders}
        />
      ) : (
        <LoginModal show={show} setShow={setShow} />
      )}
    </>
  );
};
export default BuySection;
