import _, { debounce } from "lodash";
import { ReactNode, useRef, useEffect, useState, useMemo } from "react";

import MarketCardParallel from "../../../../components/MarketCardParallel";
import { LotItemNodeEdge, LotState } from "../../../../generated/schema";
import { useOnScreen } from "../../../../hooks/useOnScreen";
import { logAmplitudeEvent } from "../../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../../utils/analytics/events";

import { AuctionAnalytics } from "../../types";
import { LotDetails } from "../LotDetails";
import {
  ChildrenWrapper,
  Contents,
  ContentsHeading,
  ContentsItems,
  ContentsSubheading,
  Grid,
  LotContainer,
} from "./styles";

interface Props {
  auctionAnalytics?: AuctionAnalytics;
  children?: ReactNode;
  closingPrice?: number | null;
  imageUrl?: string;
  lotContents: (LotItemNodeEdge | null)[];
  lotDescription: string;
  lotId: string;
  lotName: string;
  lotOrder?: Number;
  openingPrice?: number | null;
  setShowSticky?(sticky: boolean): void;
  state: LotState;
}

export const ActiveLot = ({
  auctionAnalytics = {},
  children,
  closingPrice,
  imageUrl,
  lotContents,
  lotDescription,
  lotId,
  lotName = "Lot Name",
  lotOrder = 0,
  openingPrice,
  setShowSticky,
  state,
}: Props) => {
  const contentsRef = useRef<HTMLDivElement>(null);
  const informationRef = useRef<HTMLDivElement>(null);
  const [childrenMaxHeight, setChildrenMaxHeight] = useState("100%");
  const [stickyOffset, setStickyOffset] = useState(0);
  const activeLotVisible = useOnScreen(informationRef, {
    rootMargin: "-225px",
  });
  const groupedContents = useMemo(() => {
    const contents = lotContents.map((content) => content?.node);

    return _(contents)
      .groupBy((gcp) => gcp?.gradedCardParallel.id)
      .map((value) => ({
        gcp: value[0],
        boxCount: value.length,
      }))
      .sort((a, b) => b.boxCount - a.boxCount)
      .value();
  }, [lotContents]);

  const hasEnded = [LotState.SOLD, LotState.UNSOLD].includes(state);
  const hasChildren = typeof children === "object";
  const isStarting = [LotState.STARTING].includes(state);
  const hasLotItems = groupedContents.length > 0;
  const {
    name: auctionName,
    slug: auctionSlug,
    state: auctionState,
  } = auctionAnalytics;

  const setMaxHeight = debounce(() => {
    const refsAreDefined = contentsRef.current && informationRef.current;

    if (informationRef.current) {
      const { right } = informationRef.current.getBoundingClientRect();
      setStickyOffset(right + 32); // need to add 2rem for the space between
    }

    if (refsAreDefined) {
      // Get the height of the two refs, and then add the marginTop
      const { marginTop } = getComputedStyle(contentsRef.current as Element);
      const marginTopAsNumber = parseInt(marginTop.replace(/\D/g, ""));
      const informationHeight =
        informationRef.current.getBoundingClientRect().height;
      const contentsHeight = contentsRef.current.getBoundingClientRect().height;
      const totalHeight =
        informationHeight + contentsHeight + marginTopAsNumber;

      setChildrenMaxHeight(`${totalHeight}px`);
    }
  }, 200);

  const [shouldStick, setShouldStick] = useState(false);
  const setSticky = debounce(() => {
    if (informationRef.current) {
      setShouldStick(informationRef.current.getBoundingClientRect().y >= 300);
    }
  }, 15);

  useEffect(() => {
    setSticky();
    setMaxHeight();
  });

  useEffect(() => {
    window.addEventListener("resize", setMaxHeight);

    return () => window.removeEventListener("resize", setMaxHeight);
  });

  useEffect(() => {
    window.addEventListener("scroll", setSticky);

    return () => window.removeEventListener("scroll", setSticky);
  });

  useEffect(
    () =>
      logAmplitudeEvent(BREAKS_EVENTS.AUCTION.LOT_VIEWED, {
        "Auction Name": auctionName,
        "Auction Slug": auctionSlug,
        "Auction State": auctionState,
        "Lot ID": lotId,
        "Lot Name": lotName,
        "Lot Order": lotOrder,
        "Lot State": state,
      }),
    [auctionName, auctionSlug, auctionState, lotId, lotName, lotOrder, state],
  );

  useEffect(() => {
    if (informationRef.current && setShowSticky) {
      setShowSticky(!activeLotVisible && shouldStick);
    }
  }, [activeLotVisible, setShowSticky, shouldStick]);

  return (
    <Grid hasChildren={hasChildren}>
      <LotContainer hasEnded={hasEnded} isStarting={isStarting}>
        <LotDetails
          closingPrice={closingPrice}
          description={lotDescription}
          imageUrl={imageUrl}
          innerRef={informationRef}
          name={lotName}
          openingPrice={openingPrice}
          state={state}
        />

        {hasLotItems && (
          <Contents ref={contentsRef}>
            <ContentsHeading>Included items</ContentsHeading>
            <ContentsSubheading>
              See what the included items are trading for on the StarStock
              marketplace.
            </ContentsSubheading>

            <ContentsItems>
              {groupedContents.map((content) =>
                content.gcp ? (
                  <MarketCardParallel
                    key={content.gcp.gradedCardParallel.id}
                    cardParallel={content.gcp.gradedCardParallel.cardParallel}
                    gradedCardParallels={[content.gcp.gradedCardParallel]}
                    hiddenGradesCount={0}
                    badgeCount={content.boxCount}
                    watchlistId={null}
                    removeFromWatchlist={null}
                  />
                ) : null,
              )}
            </ContentsItems>
          </Contents>
        )}
      </LotContainer>

      {/* Bid Panel */}
      {children && (
        <ChildrenWrapper
          isStarting={isStarting}
          lotHasItems={hasLotItems}
          maxHeight={childrenMaxHeight}
          sticky={!activeLotVisible && shouldStick}
          stickyOffset={stickyOffset}
        >
          {children}
        </ChildrenWrapper>
      )}
    </Grid>
  );
};
