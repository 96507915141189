import { gql } from "@apollo/client";

const GROUPED_LISTING_CLOSE = gql`
  mutation GROUPED_LISTING_CLOSE($id: ID!, $allListings: Boolean) {
    groupedListingClose(id: $id, allListings: $allListings) {
      errors {
        field
        message
      }
    }
  }
`;

export default GROUPED_LISTING_CLOSE;
