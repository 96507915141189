import { useEffect } from "react";

import styled from "styled-components";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { pluralize } from "../utils/content";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { logAmplitudeEvent } from "../utils/analytics";
import { CONTENT_EVENTS } from "../utils/analytics/events";
import { ContentPageHelmet } from "../utils/wrappers";

import background from "../images/sibbc/2020/background.png";

import LebronJames from "../images/sibbc/2020/prizes/lebron-james.png";
import JaMorant from "../images/sibbc/2020/prizes/ja-morant.png";
import ZionWilliamson from "../images/sibbc/2020/prizes/zion-williamson.png";
import LukaDoncic from "../images/sibbc/2020/prizes/luka-doncic.png";

import AdamLekfoe from "../images/sibbc/2020/contestants/01-AdamLefkoe.jpg";
import VasuKulkarni from "../images/sibbc/2020/contestants/02-VasuKulkarni.jpg";
import DrewDinkmeyer from "../images/sibbc/2020/contestants/03-DrewDinkmeyer.jpg";
import JarodHector from "../images/sibbc/2020/contestants/04-JarodHector.jpg";
import BusterScher from "../images/sibbc/2020/contestants/05-BusterScher.jpg";
import EricWong from "../images/sibbc/2020/contestants/06-EricWong.jpg";
import JackSettleman from "../images/sibbc/2020/contestants/07-JackSettleman.jpg";
import CoreyParson from "../images/sibbc/2020/contestants/08-CoreyParson.jpg";
import JoshLloyd from "../images/sibbc/2020/contestants/09-JoshLloyd.jpg";
import MichaelLeone from "../images/sibbc/2020/contestants/10-MichaelLeone.png";
import PhilGiampi from "../images/sibbc/2020/contestants/11-PhilGiampi.jpeg";
import AbeGranoff from "../images/sibbc/2020/contestants/12-AbeGranoff.jpg";

import {
  PageSection,
  PageSectionTypes,
  PageSectionWidths,
} from "../components/PageSection";

const updates = [
  {
    title: "Week 9 update",
    date: Date.UTC(2020, 8, 29),
    body: (
      <>
        <p>
          The NBA Season may have been narrowed down to just two teams, but the
          SIBBC is still anyone’s game. Buster remains in the top spot after
          selling possibly the hottest card on the market, a Tyler Herro Prizm
          Base, for $50. Buster’s lead shrunk from $48 to $16, as Vasu made two
          big moves to jump in the standings. Vasu jumped off the Celtics wagon
          at the right time, selling a Jaylen Brown Mosaic Base for $75, and
          bought a De’Aaron Fox Prizm Base for $50, netting $25 profit when it
          was all said and done.
        </p>

        <p>
          To round out the top four places, Jack comes in at third place while
          Abe trails by only $5 in fourth. Jack leads the competition by far
          with the most sales with 61, but has yet to sell his Jason Tatum Optic
          Red and Yellow PSA 10. That purchase looked great last week, but now
          that the Celtics have been bounced out of the playoffs by the Heat, it
          could turn out to be an oversight not to sell. Abe has taken a
          different approach than Jack, and is still sitting on his StarStock A
          Zion Prizm Red Ice, which he went all in for with his entire $500.
        </p>

        <p>
          The biggest seller award of the week goes to Corey, who currently sits
          in 9th place. Corey made three sales this week: Jamal Murray Select
          Base PSA 9 for $150, OG Anunoby Donruss Jersey ($5), and Montrezl
          Harrell Prizm Base ($8.25). As the competition heads into its final
          weeks, we should be seeing more users follow in Corey’s footsteps and
          start selling off their cards in hopes to increase their portfolio
          value.
        </p>

        <p>
          The final week and a half to two weeks of the competition should yield
          plenty of standings fluidity. It is now time for competitors to
          offload their collections in an attempt to maximize their account
          value, and make the final push in card trading to win the SIBBC.
        </p>
      </>
    ),
  },
  {
    title: "Week 8 update",
    date: Date.UTC(2020, 8, 22),
    body: (
      <>
        <p>
          The eighth week of our competition is in the books, and Buster
          continues to hold onto the first place spot! His lead is still
          growing, now around $48 ahead of second place at the time of writing.
          Speaking of second place, Vasu now holds that spot! The standings are
          really close, but his portfolio is currently worth more than our next
          closest competitors, Phil and Jack.
        </p>
        <p>
          Jack dropped from second to fifth place since our last update, but
          made a huge move that could catapult him into winning the competition.
          He picked up a Tatum Optic Red and Yellow PSA 10 for $300! That’s a
          great price for a high-end card like that, but if the Celtics get
          eliminated before the Finals the move could be disastrous. It will be
          interesting to see whether Jack tries to quickly flip the card or hold
          and hope for a Celtics title run.
        </p>
        <p>
          Although the competition is entering its final few weeks, our
          competitors in the lower half of the standings are trying their best
          to make a late push. Eric is currently in 9th place, and picked up two
          Marcus Smart Select Concourse at $7.50 apiece. He’s also holding onto
          a Jaylen Brown Select, so a Celtics run would be huge for his success.
          Corey is still attempting to recover from being ranked in last place
          after the first week of trading, and he’s making solid progress! He is
          now in tenth place, and made a great flip this week. After the
          Nuggets’ improbable comeback against the Clippers, Corey was able to
          sell his Jamal Murray Select PSA 9 for $150!
        </p>
      </>
    ),
  },
  {
    title: "Week 7 update",
    date: Date.UTC(2020, 8, 15),
    body: (
      <>
        <p>
          Buster is slowly increasing his lead over Jack as we get closer to the
          end of the competition. There is less than a month until October 12th
          when we crown a winner! Jack is still well within striking distance
          though, about $42 short of Buster. He also currently holds the most
          cash in his account after selling off five more Donte DiVincenzo Prizm
          cards for $3.75 apiece. Don’t forget that cash is king in this
          contest, because the final standings will be based solely on the
          amount of dollars held in the contestants’ accounts.
        </p>
        <p>
          We shouldn’t count out the current third and fourth place contestants,
          Phil and Vasu, either! They are both within $15 of second place (and
          the Zion Prizm PSA 10)! It will be interesting to see their strategies
          going forward since the majority of their portfolios consist of
          players already eliminated from the playoffs.
        </p>
        <p>
          Drew continued to make some interesting pickups, and once again they
          consisted of Shai Gilgeous-Alexander cards. This week he grabbed a SGA
          Optic Flash and an Optic Hyper Pink for $23 and $35 respectively. Do
          you think he’ll be able to flip these before the Finals ends?
        </p>
      </>
    ),
  },
  {
    title: "Week 6 update",
    date: Date.UTC(2020, 8, 9),
    body: (
      <>
        <p>
          After the sixth week of trading, we have a new face at the top of the
          leaderboard! Buster was able to surpass Jack and now holds a $32 lead.
          Buster’s patient approach has continued to work for him. He made only
          his second sale of the competition this week when he sold his Jimmy
          Butler NBA Hoops Base for $50. This was a great trade, as he only paid
          $15 for it when the competition started!
        </p>
        <p>
          Jack was active as usual, but didn’t pick up anything new. He sold off
          six Donte Divincenzo Prizm Base cards after the disappointing
          performance by the Bucks. These sales weren’t profitable, but allowed
          him to recoup some funds as the season gets closer to its end. Our
          next-most active competitor this week was Eric Wong. He sold two
          Anfernee Simons Prizm Base and a Ja Morant Donruss Base. Eric will be
          rooting for the Celtics tonight after picking up a Jaylen Brown Select
          Concourse and a Robert Williams Prizm.
        </p>
        <p>
          Our eighth place competitor Drew made a very interesting move this
          week when he picked up a SGA Prizm Pink Cracked Ice for $90. That is
          by far his biggest purchase so far, and it’ll be intriguing to see if
          he’s able to flip it at some point before the season is over. Corey
          now sits right behind Lefkoe in last place, but that could change if
          Denver is able to pull off the upset against LA. At the beginning of
          the week he bought a Jamal Murray Select PSA 9 for $100!
        </p>
      </>
    ),
  },
  {
    title: "Week 5 update",
    date: Date.UTC(2020, 8, 2),
    body: (
      <>
        <p>
          The fifth week of the SIBBC is complete, and it looks like Jack has
          some serious competition for the #1 spot! His lead has started to slip
          after a tough week where he had to cut his losses on Joel Embiid. The
          Sixers got swept, and Jack sold his Embiid Prizm Base for only $53
          after paying nearly $92 for it! On the bright side, he was able to
          sell a Jayson Tatum Optic Base and profit $28.
        </p>
        <p>
          Buster is still in second place and now trails Jack by only $55! His
          strategy of being very patient is paying off as he inches closer and
          closer to first place. The increase in portfolio value is being driven
          by his early pickups of a Tyler Herro Prizm, Jimmy Butler NBA Hoops,
          and Jaylen Brown Optic.
        </p>
        <p>
          Our fourth place contestant Phil Giampi made a major purchase this
          week. He picked up a Luka Doncic Donruss Base for $150, just one day
          before the Mavs were eliminated by Los Angeles. That may be a
          challenge to flip with Luka out of the bubble!
        </p>
        <p>
          It’s been a while since we checked in on Abe and his Zion Prizm Red
          Ice, but that’s because there’s no news! He’s still holding on to the
          card which is available for $400. Corey Parson is still in eleventh
          place, but made some moves to help increase his portfolio’s value. His
          pickup of a Jayson Tatum NBA Hoops is looking good after the Celtics
          took a 2-0 series lead yesterday. Corey was also able to make a couple
          bucks this week by flipping a Marcus Smart Prizm Red White and Blue
          Pulsar!
        </p>
      </>
    ),
  },
  {
    title: "Week 4 update",
    date: Date.UTC(2020, 7, 26),
    body: (
      <>
        <p>
          It was a slow week in the SIBBC, with the majority of competitors’
          portfolios decreasing in value. Jack still holds a solid lead though,
          about $150 greater than second place. He remained the most active
          competitor by starting off the week with the sale of two Tyler Herro
          Prizm Base at $39 each. Those funds were used in his purchase of three
          Kyle Kuzma Prizm Base totaling $96.
        </p>
        <p>
          Speaking of second place, Buster was just able to slide past Phil
          Giampi to grab the spot. Buster didn’t make any moves this week, but
          Phil showed his belief in OKC by buying three Darius Bazley Prizm Base
          at $13 each. The “race for second-place” is still very close! (Buster
          only leads Phil by about $5)
        </p>
        <p>
          It was a great week for Vasu Kulkarni, moving from 6th to 4th place.
          Not only did his viral tweet have Shaq dying laughing on live TV, he
          was the sole competitor to actually increase his portfolio’s value
          (only by $10, but still an accomplishment!). Vasu didn’t buy or sell
          anything this week, but his early investments into Luka Doncic and
          Jaylen Brown have served him well.
        </p>
        <p>
          Our 10th place competitor Eric Wong had a very busy trading week. He
          was able to make a couple bucks by flipping two Grant Williams Prizm
          Base, but took a bigger loss on two Ja Morant Donruss Base that he
          picked up on the first day of trading. Eric made a huge move at the
          end of the week when he purchased a Donovan Mitchell Optic Red and
          Yellow for $50! Corey Parson may be saving up for a similar big
          purchase after selling off nearly $70 in cards, including two Donovan
          Mitchell Select Concourse and two SGA NBA Hoops Base.
        </p>
      </>
    ),
  },
  {
    title: "Week 3 update",
    date: Date.UTC(2020, 7, 19),
    body: (
      <>
        <p>
          The second week of the SIBBC is in the books, and it looks like Jack
          Settleman may be running away with it. He holds a commanding $230+
          dollar lead over second place, the Twitter wildcard Phil Giampi. Jack
          has also made the most sales by far (34), more than double the next
          closest competitor. Two of his most successful sales this week were:
          De’Aaron Fox Prizm Base PSA 10 for $336 (paid $225!) and Bam Adebayo
          Prizm Base for $60 (paid $50). His account’s cash balance is currently
          zero after spending every last penny he had on a Joel Embiid Prizm
          Base!
        </p>
        <p>
          Buster made the leap from eleventh to third place, taking advantage of
          Jamal Murray’s big game against Utah. He was able to flip a Prizm
          Green for $45 profit, bringing him within a single dollar of second
          place.
        </p>
        <p>
          Jack’s podcast co-host Abe still sits in the middle of the pack,
          holding only his Zion Prizm Red Ice. He’s been unsuccessful in his
          attempts to flip the card, and is now struggling to even get $400 for
          it! Yikes!
        </p>
        <p>
          Adam Lefkoe had a disappointing week, dropping in the standings all
          the way from fourth to ninth place. He’s in a much better spot than
          Michael Leone though, who invested the majority of his cash into
          Lonnie Walker IV before the Spurs were eliminated from contention. He
          was unable to unload any Walker cards, and his portfolio is now valued
          at around $370. Maybe his big purchase of a PSA 9 Markelle Fultz Prizm
          Silver will allow him to redeem himself?
        </p>
      </>
    ),
  },
  {
    title: "Week 1 update",
    date: Date.UTC(2020, 7, 10),
    body: (
      <>
        <p>
          Just over a week of trading, and all our competitors have their first
          wave of investments.
        </p>
        <p>
          Top of the table is Jack Settleman. The only competitor so far to have
          sold more cards than he holds with 23 sales (?!) racked up already,
          he’s capitalising on the fast-paced market to gain an early profits.
        </p>
        <p>
          The bulk of the pack is up on their $500 starting fund. Well, if you
          can count Vasu’s single-cent profit as being &quot;up&quot;. Still
          earns him a position above Abe, who wasted no time picking up the
          biggest card of the contest so far – a{" "}
          <Link
            to="/marketplace/cards/2019-20-panini-prizm-basketball-base-248-zion-williamson-panini-prizm-red-ice-285223?grade=R3JhZGVOb2RlOjc="
            className="font-weight-bold"
          >
            Zion Prizm Red Ice
          </Link>{" "}
          – using his entire $500 allowance in a single transaction.
        </p>
        <p>
          At the tail-end of the table? Ok, so it hasn’t been a dream start for
          Corey &quot;TheFantasyExec&quot; Parson, but with the largest
          portfolio of our lineup, those fortunes could easily change.
        </p>
        <p>
          Plenty more to come! For the latest updates and portfolio analysis
          keep your eyes on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>
          .
        </p>
      </>
    ),
  },
  {
    title: "The contest begins!",
    date: Date.UTC(2020, 6, 31),
    body: (
      <>
        <p>
          We’re just 24 hours in and already we’ve been seeing some wildly
          diverging strategies appearing. Whether it’s a one-card-wonder or an
          army of lower priced rookies, it’s clear our competitors are coming at
          this competition from their own angles!
        </p>
        <p>
          We’ll be highlighting key trades and sharing people’s portfolios on
          social media throughout the contest, so be sure to follow us on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/starstock"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/starstock"
          >
            Instagram
          </a>{" "}
          to keep up with the latest news.
        </p>
      </>
    ),
  },
];

const contestants = [
  {
    id: 1,
    photo: AdamLekfoe,
    name: "Adam Lefkoe",
    role: "TNT/Bleacher",
    bio: (
      <>
        Adam is the current host of NBA on TNT Tuesdays and The Lefkoe Show on
        B/R. Will Shaq and Barkley’s inside scoop gives him the edge?
      </>
    ),
    twitter: "AdamLefkoe",
    instagram: "adamlefkoe",
    cards_owned: 10,
    cards_sold: 6,
    balance: 27.84,
    account_value: 287.81,
  },
  {
    id: 2,
    photo: VasuKulkarni,
    name: "Vasu Kulkarni",
    role: "Courtside",
    bio: (
      <>
        Vasu is the self proclaimed biggest basketball fan in the world. He was
        previously founder of Krossover, and currently Managing Partner at
        Courtside Ventures, but mostly he spends his time getting buckets.
      </>
    ),
    twitter: "vasu",
    instagram: "",
    cards_owned: 18,
    cards_sold: 2,
    balance: 30.68,
    account_value: 508.16,
  },
  {
    id: 3,
    photo: DrewDinkmeyer,
    name: "Drew Dinkmeyer",
    role: "Establish The Run",
    bio: (
      <>
        Drew is the Head of NBA at EstablishTheRun. He has been playing DFS
        professionally since 2013, racking up a million-dollar win and a dozen
        six-figure scores. Will his DFS skills give him the edge?
      </>
    ),
    twitter: "drewdinkmeyer",
    instagram: "",
    cards_owned: 11,
    cards_sold: 0,
    balance: 198.0,
    account_value: 451.39,
  },
  {
    id: 4,
    photo: JarodHector,
    name: "Jarod Hector",
    role: "Truehoop",
    bio: (
      <>
        Jarod joins the contest from TrueHoop (
        <a target="_blank" rel="noopener noreferrer" href="TrueHoop">
          @TrueHoop
        </a>
        ), and is co-host of The 7 Footers Basketball Podcast and Kicks N’
        Shitshow. Truehoop is known for their investigative angle, will that
        make the difference?
      </>
    ),
    twitter: "jshector",
    instagram: "jshector",
    cards_owned: 9,
    cards_sold: 0,
    balance: 244.0,
    account_value: 468.6,
  },
  {
    id: 5,
    photo: BusterScher,
    name: "Buster Scher",
    role: "Hoopsnation",
    bio: (
      <>
        Buster is founder of Hoopsnation on Instagram (with over 1 million
        followers) and Cardsnation. Combining deep sports card knowledge and NBA
        expertise Buster could be one to watch
      </>
    ),
    twitter: "BusterScher",
    instagram: "hoopsnation",
    cards_owned: 13,
    cards_sold: 3,
    balance: 258.78,
    account_value: 524.04,
  },
  {
    id: 6,
    photo: EricWong,
    name: "Eric Wong",
    role: "The Athletic",
    bio: (
      <>
        Eric writes about fantasy hoops for The Athletic and{" "}
        <a target="_blank" rel="noopener noreferrer" href="http://RotoEvil.com">
          RotoEvil
        </a>
        , and has won numerous fantasy basketball championships. He’s so
        dedicated to the NBA, that he doesn’t follow other sports!
      </>
    ),
    twitter: "RotoEvil",
    instagram: "",
    cards_owned: 10,
    cards_sold: 27,
    balance: 0.7,
    account_value: 344.3,
  },
  {
    id: 7,
    photo: JackSettleman,
    name: "Jack Settleman",
    role: "Snapback",
    bio: (
      <>
        Jack is a sports content creator with an army of followers on Snapchat.
        He’s leading the charge in sports cards and ready to compete.
      </>
    ),
    twitter: "jacksettleman",
    instagram: "jacksettleman",
    cards_owned: 6,
    cards_sold: 61,
    balance: 24.6,
    account_value: 505.6,
  },
  {
    id: 8,
    photo: CoreyParson,
    name: "Corey Parson",
    role: "Sports Illustrated",
    bio: (
      <>
        Corey is better known as The Fantasy Executive, and is currently a
        Fantasy and Gambling analyst for Sports Illustrated. Along the way he’s
        worked at SportsGrid Network and RotoExperts.
      </>
    ),
    twitter: "TheFantasyExec",
    instagram: "",
    cards_owned: 20,
    cards_sold: 32,
    balance: 30.98,
    account_value: 374.72,
  },
  {
    id: 9,
    photo: JoshLloyd,
    name: "Josh Lloyd",
    role: "Basketball Monster",
    bio: (
      <>
        Josh is the lead analyst at Basketball Monster, Yahoo Sports Australia,
        covers the NBA for Fade The Noise, and hosts the Locked On Fantasy
        Basketball and Locked On NBA podcasts.
      </>
    ),
    twitter: "redrock_bball",
    instagram: "",
    cards_owned: 18,
    cards_sold: 0,
    balance: 258.05,
    account_value: 460.64,
  },
  {
    id: 10,
    photo: MichaelLeone,
    name: "Michael Leone",
    role: "Establish The Run",
    bio: (
      <>
        Michael has worked in fantasy sports over the past decade and currently
        serves as the Director of Analytics for Establish the Run (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/EstablishTheRun"
        >
          @EstablishTheRun
        </a>
        ). He recently renewed his interest in sports cards.
      </>
    ),
    twitter: "2Hats1Mike",
    instagram: "",
    cards_owned: 28,
    cards_sold: 1,
    balance: 53.55,
    account_value: 363.05,
  },
  {
    id: 11,
    photo: PhilGiampi,
    name: "Phil Giampi",
    role: "Wildcard (Twitter)",
    bio: (
      <>
        Phil got lucky and nabbed the wildcard spot we offered up to our Twitter
        followers. Now he’s gotta prove himself against the professionals!
      </>
    ),
    twitter: "PG3Phil",
    instagram: "",
    cards_owned: 12,
    cards_sold: 13,
    balance: 1.27,
    account_value: 383.31,
  },
  {
    id: 12,
    photo: AbeGranoff,
    name: "Abe Granoff",
    role: "Wildcard (Instagram)",
    bio: (
      <>
        Abe won the wildcard slot we offered up to our Instagram followers. Can
        he double down on that early luck and make a play for the title?
      </>
    ),
    twitter: "",
    instagram: "abegranoff",
    cards_owned: 1,
    cards_sold: 0,
    balance: 0,
    account_value: 500,
  },
];

const prizes = [
  {
    rank: "1st",
    name: "LeBron Topps rookie card PSA 10",
    value: "$5,000 + the trophy!",
    photo: LebronJames,
  },
  {
    rank: "2nd",
    name: "2019-20 Prizm Zion Williamson Base PSA 10",
    value: "$1,000",
    photo: ZionWilliamson,
  },
  {
    rank: "3rd",
    name: "2019-20 Prizm Ja Morant Base PSA 10",
    value: "$500",
    photo: JaMorant,
  },
  {
    rank: "4th",
    name: "2018-19 NBA Hoops Luka Doncic PSA 10",
    value: "$250",
    photo: LukaDoncic,
  },
  { rank: "5th", name: "2019-20 Optic RJ Barrett Base PSA 10", value: "$100" },
  {
    rank: "6th",
    name: "2019-20 Prizm Kevin Porter Jr Base PSA 10",
    value: "$60",
  },
  {
    rank: "7th",
    name: "2018-19 Optic Devonte Graham Blue Velocity",
    value: "$30",
  },
  { rank: "8th", name: "2019-20 Optic Tyler Herro Base", value: "$10" },
  { rank: "9th", name: "2018-19 Prizm Colin Sexton Base", value: "$6" },
  { rank: "10th", name: "2019-20 Optic Deandre Hunter", value: "$2.50" },
  { rank: "11th", name: "2019-20 Optic Ignas Brazdeikis", value: "$1" },
  { rank: "12th", name: "2019-20 NBA Hoops Jordan Bone", value: "$0.25" },
];

const SibbcOrangeLight = "#f4a179";
const SibbcOrange = "#e88343";
const SibbcOrangeDark = "#dd832c";

const StyledBanner = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.9);
  background: ${SibbcOrange};

  @media (min-width: 36rem) {
    padding: 0.5rem 4rem;
  }
`;

export const SibbcAnnouncementBanner = () => {
  return (
    <StyledBanner className="shadow text-center">
      <p className="mb-0">
        Catch up on the latest from the SIBBC as the week 7 leaderboard is in!
        <a href="/sibbc" className="font-weight-bold text-white ml-2">
          Read more
        </a>
      </p>
    </StyledBanner>
  );
};

const HeaderContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0b1f3c;
  background-position: 70% 20%;

  height: 13rem;
  @media (min-width: 48rem) {
    height: 20rem;
  }
`;

const ContestTitle = styled.h1`
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 4rem;
  line-height: 3rem;
  font-style: italic;
  text-transform: uppercase;
  color: white;
  display: block;
  margin: 0 -0.25rem;
  padding-bottom: 1rem;

  @media (min-width: 48rem) {
    font-size: 8rem;
    line-height: 7rem;
  }

  border-bottom: 4px solid ${SibbcOrange};
`;

const ContestTagline = styled.h2`
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: white;
  display: block;
  margin-top: 2px;
  padding-top: 1rem;

  @media (min-width: 48rem) {
    font-size: 1.5rem;
    line-height: 1.25rem;
  }

  border-top: 2px solid ${SibbcOrange};
`;

const SectionHeading = styled.h3`
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.5rem;
  border-bottom: 2px solid ${SibbcOrange};
  padding-bottom: 1rem;
  margin: 2px -0.125rem;

  @media (min-width: 48rem) {
    font-size: 2rem;
  }
`;

const SectionBodyText = styled.div`
  border-top: 1px solid ${SibbcOrange};
  padding-top: 1rem;
  margin-bottom: 0;
`;

const NameLabel = styled.h4`
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 1.75rem;
`;

const RoleLabel = styled.h5`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1.5rem;
`;

const RankLabel = styled.h5`
  font-family: "Barlow", sans-serif;
  font-weight: "Black";
  font-size: "1.25rem";
  width: 2.5rem;
`;

const ScoreNameLabel = styled.h5`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1.25rem;
  margin-bottom: 0;
`;

const ScoreCardsLabel = styled.p`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1rem;
  margin-bottom: 0;
`;

const ScoreLabel = styled.h4`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: regular;
  font-size: 1.5rem;
  text-align: right;
  margin-bottom: 0;
`;

const BulletList = styled.ul`
  list-style: none;
  line-height: 1.75rem;

  li::before {
    content: "\\25CB"; /* CSS Code/unicode for a bullet */
    color: ${SibbcOrange};
    font-weight: bold;
    display: inline-block;
    width: 2em;
    margin-left: -2rem;
  }
`;

const CountdownSection = styled.div`
  background: linear-gradient(135deg, ${SibbcOrangeLight}, ${SibbcOrangeDark});
`;

const Sibbc2020 = () => {
  useEffect(
    () =>
      logAmplitudeEvent(CONTENT_EVENTS.INVITATIONAL_CHAMPIONSHIP.PAGE_VIEWED, {
        Championship: "SIBBC 2020",
      }),
    [],
  );

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>
          SIBBC 2020 - StarStock Invitational Basketball Championship 2020
        </title>
      </Helmet>
      <ContentPageHelmet />

      <HeaderContainer className="row text-left">
        <div className="col p-3 inner-container-wide mx-auto d-flex flex-column justify-content-end">
          <ContestTitle>SIBBC 2020</ContestTitle>
          <ContestTagline>
            StarStock invitational Basketball Championship 2020
          </ContestTagline>
        </div>
      </HeaderContainer>

      <PageSection
        width={PageSectionWidths.wide}
        type={PageSectionTypes.background}
        removePaddingBottom={true}
      >
        <div className="row">
          <div className="col-lg order-lg-2 d-flex flex-column">
            <CountdownSection className="shadow px-4 py-3 rounded mb-4">
              <SectionHeading
                className="text-center"
                style={{ color: "white", borderBottom: "1px solid white" }}
              >
                CONTEST LIVE!
              </SectionHeading>
              <SectionBodyText
                className="text-center text-deselected py-2"
                style={{
                  fontFamily: "Barlow, sans-serif",
                  fontWeight: "Black",
                  fontStyle: "italic",
                  fontSize: "2rem",
                  color: "white",
                }}
              >
                Ends Tuesday, October 12th
              </SectionBodyText>
            </CountdownSection>

            <div
              className="bg-white shadow px-3 px-sm-4 py-3 rounded mb-5 d-flex flex-column justify-content-start align-items-stretch"
              style={{ border: `1px solid ${SibbcOrange}` }}
            >
              <SectionHeading>Leaderboard</SectionHeading>
              <SectionBodyText />
              <p className="small text-speak text-uppercase text-center mb-0">
                Ranked by portfolio value + balance
              </p>
              <div className="mt-3">
                {contestants
                  .sort((a, b) => b.account_value - a.account_value)
                  .map((contestant, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row align-items-center border-top py-2"
                    >
                      <RankLabel className="text-whisper mb-1 mr-3 text-center flex-shrink-0 d-none d-sm-block">
                        {moment.localeData().ordinal(index + 1)}
                      </RankLabel>
                      <div
                        className="bg-light rounded-circle border overflow-hidden mr-3 flex-shrink-0"
                        style={{ width: "3rem", height: "3rem" }}
                      >
                        <img
                          src={contestant.photo}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="flex-shrink-1 flex-grow-1 text-truncate mr-4">
                        <ScoreNameLabel className="text-truncate">
                          {contestant.name}
                        </ScoreNameLabel>
                        <ScoreCardsLabel className="text-whisper text-truncate">
                          {pluralize("card", contestant.cards_owned)},{" "}
                          {pluralize("sale", contestant.cards_sold)}
                        </ScoreCardsLabel>
                      </div>
                      <ScoreLabel>
                        {currencyFormatter.format(contestant.account_value)}
                      </ScoreLabel>
                    </div>
                  ))}
              </div>
              <p className="small text-speak text-uppercase text-center pt-3 mb-0 border-top">
                Last updated Tuesday, September 29th
              </p>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="mb-5">
              <SectionHeading>
                The fight for the debut StarStock trophy!
              </SectionHeading>
              <SectionBodyText>
                <p>
                  We’ve invited 12 of the sharpest minds in sports to prove
                  their expertise. With a starting deposit of $500, who can
                  build up the most valuable portfolio of basketball cards on
                  StarStock?
                </p>
                <p className="mb-0">
                  They’ve got until exactly 24 hours after the final buzzer of
                  the NBA finals to maximise their account balance. Time to find
                  our very first StarStock champ!
                </p>
              </SectionBodyText>
            </div>

            <div className="mb-5">
              <SectionHeading>Latest updates</SectionHeading>
              <SectionBodyText />

              {updates.map((update) => (
                <div
                  key={update.title}
                  className="mb-3 py-3"
                  style={{ borderBottom: `1px solid ${SibbcOrange}` }}
                >
                  <h5 className="font-weight-bold">{update.title}</h5>
                  <p className="small text-speak text-uppercase mb-3">
                    {moment(update.date).format("dddd, MMMM Do")}
                  </p>
                  <div className="mb-3">{update.body}</div>
                </div>
              ))}
            </div>

            <div className="mb-5">
              <SectionHeading>Contest rules</SectionHeading>
              <SectionBodyText>
                <p>
                  The trades are theirs to decide, but that doesn’t mean
                  anything goes! To keep everyone playing fair there’s a few
                  simple rules everyone’s gotta stick to.
                </p>
                <BulletList>
                  <li>Trades are for NBA cards only (obv!)</li>
                  <li>
                    No deposits – the $500 starting fund is all they have to
                    play with
                  </li>
                  <li>
                    No sending in additional cards to boost their collection
                  </li>
                  <li>
                    No related party trades – e.g. getting a friend to pay over
                    the odds
                  </li>
                  <li>We may review transactions to ensure they are fair</li>
                  <li>
                    Promoting listed cards on social media is allowed and part
                    of the game!
                  </li>
                </BulletList>
              </SectionBodyText>
            </div>

            <div className="mb-5">
              <SectionHeading>Prizes</SectionHeading>
              <SectionBodyText>
                <p>
                  These trades are with real cards and real money, so everyone
                  can come away a winner! But it isn’t a competition without
                  some prizes to fight over, so lets see what bonus awards are
                  at stake!
                </p>
                <div className="row">
                  {prizes
                    .filter((prize) => prize.photo)
                    .map((prize) => (
                      <div
                        key={prize.rank}
                        className="col-sm-3 text-center mb-3"
                      >
                        <h3
                          style={{
                            fontFamily: "Barlow, sans-serif",
                            fontWeight: "Black",
                            fontStyle: "italic",
                            fontSize: "2rem",
                          }}
                        >
                          {prize.rank}
                        </h3>
                        <img
                          src={prize.photo}
                          className="img-fluid mb-2"
                          alt=""
                        />
                        <p className="mb-1">{prize.name}</p>
                        <p
                          style={{
                            fontFamily: "Barlow, sans-serif",
                            fontWeight: "Black",
                            fontStyle: "italic",
                            fontSize: "1rem",
                          }}
                        >
                          Approx value {prize.value}
                        </p>
                      </div>
                    ))}
                </div>
                <table className="table table-sm table-borderless">
                  <thead className="border-bottom">
                    <tr>
                      <th>Rank</th>
                      <th>Prize</th>
                      <th className="text-right">Approx value</th>
                    </tr>
                  </thead>
                  <tbody style={{ lineHeight: "1.5rem" }}>
                    {prizes
                      .filter((prize) => !prize.photo)
                      .map((prize) => (
                        <tr key={prize.rank}>
                          <td className="font-weight-bold">{prize.rank}</td>
                          <td>{prize.name}</td>
                          <td className="text-right">{prize.value}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </SectionBodyText>
            </div>
          </div>
        </div>
      </PageSection>

      <PageSection
        width={PageSectionWidths.wide}
        type={PageSectionTypes.foreground}
        removePaddingBottom={true}
      >
        <div>
          <SectionHeading>The contestants</SectionHeading>
          <SectionBodyText />
          <div className="row mt-3 d-flex flex-row">
            {contestants.map((contestant) => (
              <div
                key={contestant.id}
                className="col-sm-6 col-md-4 col-xl-3 d-flex flex-column justify-content-start align-items-center pb-4 mb-4 border-bottom"
              >
                <div
                  className="bg-light rounded-circle border mb-3 overflow-hidden"
                  style={{ width: "8rem", height: "8rem" }}
                >
                  <img src={contestant.photo} className="img-fluid" alt="" />
                </div>
                <NameLabel>{contestant.name}</NameLabel>
                <RoleLabel>{contestant.role}</RoleLabel>
                <p
                  className="pt-3 mb-2"
                  style={{ borderTop: `1px solid ${SibbcOrange}` }}
                >
                  {contestant.bio}
                </p>
                <div className="btn-toolbar justify-content-center w-100 mt-auto">
                  {contestant.twitter && (
                    <a
                      href={`https://twitter.com/${contestant.twitter}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm bg-light border text-primary mt-2 mx-1"
                    >
                      <FontAwesomeIcon
                        icon={faTwitter}
                        className="fa fa-lg mr-2"
                      />
                      @{contestant.twitter}
                    </a>
                  )}
                  {contestant.instagram && (
                    <a
                      href={`https://instagram.com/${contestant.instagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm bg-light border text-primary mt-2 mx-1"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="fa fa-lg mr-2"
                      />
                      {contestant.instagram}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </PageSection>
    </>
  );
};

export default Sibbc2020;
