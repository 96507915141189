import styled from "styled-components";
import { Styles } from "react-modal";

import { Colors } from "../../../../themes/Default";

export const Success = styled.div`
  align-items: center;
  background: rgb(225 242 225 / 25%);
  border: 1px solid #d7e7d7;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 1.5rem 1rem;
  text-align: center;
`;

export const CloseButton = styled.button`
  align-self: center;
  background: #77a8e5
    linear-gradient(180deg, ${Colors.Porcelain} 0%, #dadfe6 100%);
  border: 1px solid #efefef;
  border-radius: 0.5rem;
  color: ${Colors.Astronaut};
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 auto 1.5rem;
  opacity: 1;
  padding: 0.5rem 1rem;
  text-align: center;
  width: 7.5rem;
`;
export const customStyles: Styles = {
  content: {
    maxWidth: "36rem",
    maxHeight: "40.5rem",
  },
};
