import { financial } from "../utils/common";
import { pluralize } from "../utils/content";

import SummaryDataRow, {
  SummaryDataRowSkeleton,
  SummaryDataItemWidths,
} from "./SummaryDataRow";

export const MarketSummaryDataRowSkeleton = () => {
  return (
    <div className="d-flex flex-row flex-wrap justify-content-between">
      <SummaryDataRowSkeleton />
      <SummaryDataRowSkeleton configAlignItems="justify-content-end" />
    </div>
  );
};

const MarketSummaryDataRow = ({
  listingsCount = null,
  lowestListingPrice,
  offersCount = null,
  bestOfferPrice,
  latestSalePrice,
  configShowOffers = true,
}) => {
  const priceLabel = (price) => (price ? `$${financial(price)}` : "–");

  let leftItems = [
    {
      label:
        listingsCount === null
          ? "Lowest listing"
          : listingsCount > 0
          ? `${listingsCount} for sale from`
          : "0 for sale",
      value: priceLabel(lowestListingPrice),
      valueColor: lowestListingPrice ? "text-success" : "text-deselected",
      width: SummaryDataItemWidths.narrow,
    },
  ];
  if (configShowOffers) {
    leftItems.push({
      label:
        offersCount === null
          ? "Best offer"
          : offersCount > 0
          ? `${pluralize("offer", offersCount)} from`
          : "0 offers",
      value: priceLabel(bestOfferPrice),
      valueColor: bestOfferPrice ? "text-primary" : "text-deselected",
      width: SummaryDataItemWidths.narrow,
    });
  }

  const rightItems = [
    {
      label: "Latest sale",
      value: priceLabel(latestSalePrice),
      valueColor: latestSalePrice ? "text-speak" : "text-deselected",
      width: SummaryDataItemWidths.auto,
      textAlignment: "text-right",
    },
  ];

  return (
    <div className="d-flex flex-row justify-content-between">
      <SummaryDataRow summaryDataItems={leftItems} />
      <div className="ml-2">
        <SummaryDataRow summaryDataItems={rightItems} />
      </div>
    </div>
  );
};

export default MarketSummaryDataRow;
