import { useMutation } from "@apollo/client";

import { logAmplitudeEvent } from "../../utils/analytics";
import { BUYING_EVENTS } from "../../utils/analytics/events";

import GET_CARD_PARALLEL from "../../api/queries/GET_CARD_PARALLEL";
import GET_GRADED_CARD_PARALLEL from "../../api/queries/GET_GRADED_CARD_PARALLEL";
import GET_MY_GROUPED_BIDS from "../../api/queries/GET_MY_GROUPED_BIDS";
import GET_MY_ACTIVITY_METADATA from "../../api/queries/GET_MY_ACTIVITY_METADATA";
import GROUPED_BID_CLOSE from "../../api/mutations/GROUPED_BID_CLOSE";

import BidListGradeItem from "../Activity/BidListGradeItem";

const MyBidsSection = ({ slug, gradedCardParallelId, groupedBids }) => {
  const mutationRefetches = [
    { query: GET_MY_ACTIVITY_METADATA },
    { query: GET_MY_GROUPED_BIDS, variables: { searchQuery: "" } },
    { query: GET_CARD_PARALLEL, variables: { slug } },
    {
      query: GET_GRADED_CARD_PARALLEL,
      variables: { id: gradedCardParallelId },
    },
  ];

  const [groupedBidClose] = useMutation(GROUPED_BID_CLOSE, {
    refetchQueries: mutationRefetches,
    onCompleted({ groupedBidClose }) {
      if (groupedBidClose.errors && groupedBidClose.errors.length > 0) {
        logAmplitudeEvent(BUYING_EVENTS.EXISTING_OFFER.CANCEL.FAILED, {
          Error: groupedBidClose.errors[0],
        });
      } else {
        logAmplitudeEvent(BUYING_EVENTS.EXISTING_OFFER.CANCEL.CANCELED);
      }
    },
  });

  return (
    <div className="row">
      {groupedBids.map((groupedBid) => (
        <div className="col-12 mt-3" key={groupedBid.id}>
          <BidListGradeItem
            groupedBid={groupedBid}
            groupedBidClose={groupedBidClose}
            acceptListingRefetches={mutationRefetches}
          />
        </div>
      ))}
    </div>
  );
};

export default MyBidsSection;
