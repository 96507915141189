import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

const WithdrawalRequestSubmitSuccess = () => {
  return (
    <>
      <Helmet>
        <title>Withdrawal Request Submitted</title>
      </Helmet>

      <div className="card mx-auto max-width-26 my-5">
        <div className="card-body text-center">
          <h1 className="card-title h5 my-3">Email Sent!</h1>
          <FontAwesomeIcon icon={faPaperPlane} size="6x" className="mb-3" />
          <p>
            Please check your inbox and click the link to confirm your
            withdrawal request.
          </p>
          <p>
            The confirmation link will be valid for two days. Your request
            will not be processed until it has been confirmed.
          </p>
      </div>
      </div>
    </>
  );
};

export default WithdrawalRequestSubmitSuccess;
