import styled from "styled-components";

export const PaymentMethodContainer = styled.div`
  background-color: #fff;
  border: 1px solid #dcddde;
  border-radius: 0.5rem;
  box-shadow: 0 1px 0.5rem 0 rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 49rem) {
    width: 32.5rem;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 5rem;
`;
