import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_LISTING_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
} from "../fragments";

const GET_CARD_PARALLEL_SIBLINGS = gql`
  ${CARD_PARALLEL_LISTING_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  ${GRADE_FRAGMENT}
  query GET_CARD_PARALLEL_SIBLINGS($playerCard: ID!) {
    cardParallels(playerCard: $playerCard) {
      edges {
        node {
          ...cardParallelListingFields
          gradedcardparallelSet(first: 3) {
            totalCount
            edges {
              node {
                ...gradedCardParallelFields
                grade {
                  ...gradeFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_CARD_PARALLEL_SIBLINGS;
