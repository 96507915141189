import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useQuery } from "@apollo/client";
import styled, { css } from "styled-components";

import GET_MARKET_WIDGETS from "../../api/queries/GET_MARKET_WIDGETS";

import { logAmplitudeEvent } from "../../utils/analytics";
import { MARKETPLACE_EVENTS } from "../../utils/analytics/events";

import BarChartGhostLight from "../../images/backgrounds/vector/bar-chart-ghost-light.svg";

import MarketWidget from "./Widgets/MarketWidget";
import LatestSalesItemCarouselWidget from "./Widgets/LatestSalesItemCarouselWidget";
import FeaturedCardsCarouselWidget from "./Widgets/FeaturedCardsCarouselWidget";
import FeaturedBoxesCarouselWidget from "./Widgets/FeaturedBoxesCarouselWidget";
import TodaysMostTradedCarouselWidget from "./Widgets/TodaysMostTradedCarouselWidget";
import UpcomingFixturesWidget from "./Widgets/UpcomingFixturesWidget";
import UpcomingBreaksCarouselWidget from "../Breaks/Home/Widgets/UpcomingBreaksCarouselWidget";
import { UpcomingAuctionsCarouselWidget } from "../../features/auctions";

const Astronaut = "#2c4d76";

const StyledWidgetWrapper = styled.div`
  background: ${(props) => props.background || "#ffffff"};
  margin-bottom: 1rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.05);
`;

const WidgetWrapper = ({ children, background }) => {
  return (
    <StyledWidgetWrapper className="row" background={background}>
      <div className="col inner-container-wide mx-auto">
        <div className="row">{children}</div>
      </div>
    </StyledWidgetWrapper>
  );
};

const Storefront = ({ sport = "", era = "" }) => {
  useEffect(
    () =>
      logAmplitudeEvent(MARKETPLACE_EVENTS.STOREFRONT.PAGE_VIEWED, {
        Sport: sport,
        Era: era,
      }),
    [era, sport],
  );

  const { data } = useQuery(GET_MARKET_WIDGETS);

  const mainStorefront = !sport && !era;

  // We do not currently maintain an active subscription to SportRadar
  const sportRadarEnabled = false;
  return (
    <>
      { sport && (
      <WidgetWrapper>
        <FeaturedCardsCarouselWidget sport={sport} era={era} />
      </WidgetWrapper>)}

      { era && (
      <WidgetWrapper>
        <FeaturedCardsCarouselWidget sport={sport} era={era} />
      </WidgetWrapper>)}

      {mainStorefront && data && data.boxBreaks.totalCount > 0 && (
        <WidgetWrapper>
          <UpcomingBreaksCarouselWidget sport={sport} era={era} />
        </WidgetWrapper>
      )}

      {mainStorefront && data && data.auctions.totalCount > 0 && (
        <WidgetWrapper>
          <UpcomingAuctionsCarouselWidget sport={sport} era={era} />
        </WidgetWrapper>
      )}

      {mainStorefront &&
        data &&
        data.marketWidgets.map((marketWidget, i) => (
          <WidgetWrapper key={`widget-wrapper-${i}`}>
            <MarketWidget marketWidget={marketWidget} />
          </WidgetWrapper>
        ))}

      <WidgetWrapper>
        <FeaturedBoxesCarouselWidget sport={sport} era={era} />
      </WidgetWrapper>

      <WidgetWrapper background={Astronaut}>
        <LatestSalesItemCarouselWidget sport={sport} era={era} />
      </WidgetWrapper>

      {sportRadarEnabled &&
        (mainStorefront || sport === "basketball" || sport === "baseball") && (
          <WidgetWrapper
            background={css`
            linear-gradient(160deg, #58B55Cd0, #2c4d76d0),
            #2c4d76;
          `}
          >
            <UpcomingFixturesWidget sport={sport} />
          </WidgetWrapper>
        )}

      {sport !== "soccer" &&
        sport !== "hockey" &&
        sport !== "other" &&
        era !== "vintage" && (
          <WidgetWrapper>
            <TodaysMostTradedCarouselWidget sport={sport} era={era} />
          </WidgetWrapper>
        )}

      <WidgetWrapper
        background={css`
            url(\"${BarChartGhostLight}\") right bottom repeat-x,
            linear-gradient(180deg, #001E50d0, #001E5040),
            #581b51;
          `}
      >
        <div className="mx-3 my-5">
          <h3 className="text-white font-weight-bold mb-3">
            Looking for the market indexes?
          </h3>
          <p className="text-white mb-4" style={{ opacity: "80%" }}>
            Weʼve decided to put these on ice while we review how best to
            surface this data moving forwards. For more info, or to share your
            feedback, click the link below.
          </p>
          <Link
            to="/marketplace/indexes"
            className="py-2 px-3 bg-light rounded d-inline-block"
          >
            View archived indexes
          </Link>
        </div>
      </WidgetWrapper>
    </>
  );

};

export default Storefront;
