import { productName, productNameSuffix } from "../../utils/content";

import CardParallelContainer from "../CardParallelContainer";
import ListingListGradeItem from "./ListingListGradeItem";

const ListingListCardItem = ({
  groupedListing,
  groupedListingClose,
  acceptOfferRefetches,
}) => {
  const { sellorderSet } = groupedListing;
  const { item } = sellorderSet.edges[0].node;
  const { cardParallel, grade } = item;
  const {
    slug,
    serialLimit,
    frontSidePhotoSmall,
    parallel,
    playerCard: { cardFamily },
    gradedCardParallel,
  } = cardParallel;
  const gradedCardParallelId = gradedCardParallel
    ? gradedCardParallel.id
    : null;

  return (
    <CardParallelContainer
      slug={slug}
      linkPath={`/marketplace/cards/${slug}`}
      productType={cardParallel.productType}
      photo={frontSidePhotoSmall}
      cardProgram={cardFamily.program.name}
      cardFamily={cardFamily.name}
      cardName={productName(cardParallel)}
      cardSetNumber={productNameSuffix(cardParallel)}
      parallel={parallel}
      serialLimit={serialLimit}
      grade={grade}
      configCard
      configAllowWide
      configLargeMode
    >
      <ListingListGradeItem
        slug={slug}
        gradedCardParallelId={gradedCardParallelId}
        groupedListing={groupedListing}
        groupedListingClose={groupedListingClose}
        acceptOfferRefetches={acceptOfferRefetches}
      />
    </CardParallelContainer>
  );
};

export default ListingListCardItem;
