import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

export const SearchInputSizes = {
  regular: "regular",
  large: "large",
};

const BorderColor = "rgba(0, 0, 0, 0.1)";
const PassiveColor = "rgba(44, 77, 118, 0.5)";
const ActiveColor = "#4e8ad4";

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${(props) => props.configMaxWidth || "30rem"};

  ${(props) =>
    props.configSize === SearchInputSizes.large &&
    css`
      font-size: 1.25rem;
    `}
`;

const SearchInputIcon = styled.label`
  position: absolute;
  top: calc(50% - 0.66rem);
  left: 0.75rem;
  margin: 0;

  // Emphasise search icon when a search term is entered
  color: ${(props) => (props.searchQuery ? ActiveColor : PassiveColor)};

  ${(props) =>
    props.configSize === SearchInputSizes.large &&
    css`
      top: calc(50% - 0.85rem);
      left: 1rem;
    `};
`;

const SearchClearButton = styled.button`
  display: ${(props) => (props.searchQuery ? "inline-block" : "none")};
  position: absolute;
  top: calc(50% - 0.75rem);
  right: 0.5rem;
  color: ${PassiveColor};
  border: none;
  background: transparent;

  ${(props) =>
    props.configSize === SearchInputSizes.large &&
    css`
      top: calc(50% - 1rem);
      right: 0.75rem;
    `};
`;

const StyledSearchInput = styled.input`
  position: relative;
  font-size: 1rem;
  height: 2.5rem;
  line-height: normal;
  padding-left: 2.25rem;
  border-radius: 1.25rem;
  border: 1px solid ${BorderColor};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  // Make space for the delete button when the field is populated
  padding-right: ${(props) => props.value && "2rem"};

  ${(props) =>
    props.configSize === SearchInputSizes.large &&
    css`
      font-size: 1.25rem;
      height: 3rem;
      line-height: normal;
      border-radius: 1.75rem;
      padding-left: 3rem;
      padding-right: ${(props) => props.value && "2.5rem"};
    `};
`;

const SearchInput = ({
  searchQuery,
  setSearchQuery,
  placeholder = "Filter by card, player or team",
  configSize = SearchInputSizes.regular,
  configMaxWidth,
}) => {
  return (
    <SearchInputWrapper configSize={configSize} configMaxWidth={configMaxWidth}>
      <StyledSearchInput
        className="form-control"
        type="text"
        spellcheck="false"
        autocomplete="false"
        placeholder={placeholder}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        configSize={configSize}
      />
      <SearchInputIcon searchQuery={searchQuery} configSize={configSize}>
        <FontAwesomeIcon icon={faSearch} />
      </SearchInputIcon>

      <SearchClearButton
        searchQuery={searchQuery}
        onClick={() => setSearchQuery("")}
        configSize={configSize}
      >
        <FontAwesomeIcon icon={faTimes} />
      </SearchClearButton>
    </SearchInputWrapper>
  );
};

export default SearchInput;
