import { gql } from "@apollo/client";

const EXTEND_LISTING_EXPIRY_DATE = gql`
  mutation EXTEND_LISTING_EXPIRY_DATE(
    $groupedListingId: ID!
    $extendExpiry: ExpiresAfter!
  ) {
    sellOrderExtendExpiryDate(
      groupedListingId: $groupedListingId
      extendExpiry: $extendExpiry
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export default EXTEND_LISTING_EXPIRY_DATE;
