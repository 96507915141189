import moment from "moment";
import styled from "styled-components";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { rgba } from "polished";

const Astronaut = "#2c4d76";
const Havelock = "#4e8ad4";

const HeaderHeight = "3rem";
const RowHeight = "3rem";

const RegularBreakpointWidth = "40rem";
const WideBreakpointWidth = "50rem";
const UltraWideBreakpointWidth = "64rem";

const FixedColumnWidthNarrow = "10rem";
const FixedColumnWidthRegular = "10rem";
const FixedColumnWidthWide = "10rem";
const FixedColumnWidthUltraWide = "11rem";

const ColumnWidthNarrow = "3.25rem";
const ColumnWidthRegular = "3.5rem";
const ColumnWidthWide = "4rem";
const ColumnWidthUltraWide = "5.5rem";

const StyledWrapper = styled.div`
  background: white;
  position: relative;
  overscroll-behavior: contain;
  margin: 0 -15px;
  border-top: 1px solid #00000020;
  border-bottom: 1px solid #00000020;

  @media (min-width: ${WideBreakpointWidth}) {
    border: 1px solid #00000020;
    margin: 0;
  }
`;

const StyledUpperContainer = styled.div`
  height: ${HeaderHeight};
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-between;
  z-index: 2;

  border-bottom: 1px solid #00000020;
  background: linear-gradient(
      to bottom,
      ${rgba(Havelock, 0.0)},
      ${rgba(Havelock, 0.05)}
    ),
    white;
  box-shadow: 0 0.5rem 0.5rem #00000010;
  }
`;

const StyledLowerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTableHeader = styled.div`
  height: 100%;
  display: flex;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  align-items: center;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

const StyledTableBody = styled.div`
  display: relative;
  padding: 1rem;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  @media (max-width: ${RegularBreakpointWidth}) {
    ::after {
      content: "";
      z-index: 2;
      position: absolute;
      right: 0rem;
      top: 0;
      bottom: 0;
      width: 1rem;

      background: linear-gradient(90deg, #00000000, #00000010);
    }
  }
`;

const StyledTableRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  height: ${RowHeight};
`;

const StyledFixedLeftSidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: flex-start;
  position: relative;
  z-index: 1;

  width: ${FixedColumnWidthNarrow};

  @media (min-width: ${RegularBreakpointWidth}) {
    width: ${FixedColumnWidthRegular};
  }
  @media (min-width: ${WideBreakpointWidth}) {
    width: ${FixedColumnWidthWide};
  }
  @media (min-width: ${UltraWideBreakpointWidth}) {
    width: ${FixedColumnWidthUltraWide};
  }

  border: 0;
  border-right: 1px solid #00000010;

  &.top {
    padding: 0.75rem 1rem;

    ::after {
      content: "";
      position: absolute;
      right: -0.25rem;
      top: 0;
      bottom: 0;
      width: 0.25rem;

      background: radial-gradient(farthest-side at 0 50%, #00000010, #00000000);
    }
  }

  &.bottom {
    padding: 1rem;

    ::after {
      content: "";
      position: absolute;
      right: -2rem;
      top: -1rem;
      bottom: -1rem;
      width: 2rem;

      background: radial-gradient(
        farthest-side at 0% 50%,
        #00000010,
        #00000000
      );
    }
  }
`;

const StyledStatCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-grow: 0;
  height: 100%;

  text-align: center;
  color: ${(props) =>
    props.isBlank ? rgba(Astronaut, 0.3) : rgba(Astronaut, 0.8)};

  padding: 0.25rem 0.5rem;
  &:first {
    padding: 10rem;
    background: red;
  }

  flex-grow: 0;
  flex-shrink: 0;

  font-size: 0.875rem;
  width: ${ColumnWidthNarrow};

  @media (min-width: ${RegularBreakpointWidth}) {
    width: ${ColumnWidthRegular};
  }
  @media (min-width: ${WideBreakpointWidth}) {
    font-size: 1rem;
    width: ${ColumnWidthWide};
  }
  @media (min-width: ${UltraWideBreakpointWidth}) {
    width: ${ColumnWidthUltraWide};
  }
`;

const StatCell = ({ fixtureStat }) => {
  const statValue =
    fixtureStat.statValue === "00:00" || fixtureStat.statValue === "0"
      ? "-"
      : fixtureStat.statValue;

  const isBlank = statValue === "-";

  return <StyledStatCell isBlank={isBlank}>{statValue}</StyledStatCell>;
};

const StyledTeamLogoCircle = styled.a`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: lightgrey;
  background: linear-gradient(
    to bottom right,
    #${(props) => props.colorPrimary} 50%,
    #${(props) => props.colorSecondary} 50% 100%
  );
  overflow: hidden;

  @media (min-width: ${WideBreakpointWidth}) {
    margin-right: 1rem;
  }

  box-shadow: inset 0px 0px 0px 1px #00000020;
`;

const FixtureRowHeader = ({ fixtureTeamPlayer }) => {
  const { fixture, teamPlayer } = fixtureTeamPlayer;
  const isHomeGame = fixture.homeTeam.id === teamPlayer.team.id;
  const opponentTeam = isHomeGame ? fixture.awayTeam : fixture.homeTeam;
  const description = isHomeGame
    ? `v ${opponentTeam.abbrName}`
    : `@ ${opponentTeam.abbrName}`;

  return (
    <StyledTableHeader>
      <StyledTeamLogoCircle
        colorPrimary={opponentTeam ? opponentTeam.colorPrimary : "lightgrey"}
        colorSecondary={
          opponentTeam ? opponentTeam.colorSecondary : "lightgrey"
        }
        colorTertiary={opponentTeam ? opponentTeam.colorTertiary : "lightgrey"}
      />
      <div>
        <p className="text-speak mb-0">{description}</p>
        <p className="text-whisper small mb-0 text-nowrap">
          {moment(fixture.startDate).format("MMM DD, YYYY")}
        </p>
      </div>
    </StyledTableHeader>
  );
};

const StyledStatHeader = styled.div`
  font-size: 0.75rem;
  text-align: center;
  width: ${ColumnWidthNarrow};

  @media (min-width: ${RegularBreakpointWidth}) {
    font-size: 0.875rem;
    width: ${ColumnWidthRegular};
  }
  @media (min-width: ${WideBreakpointWidth}) {
    width: ${ColumnWidthWide};
  }
  @media (min-width: ${UltraWideBreakpointWidth}) {
    width: ${ColumnWidthUltraWide};
  }
`;

const FixtureStatRow = ({ fixtureStats }) => {
  return (
    <StyledTableRow>
      {fixtureStats.map((fixtureStat, i) => (
        <StatCell key={i} fixtureStat={fixtureStat} />
      ))}
    </StyledTableRow>
  );
};

const FixtureStatSection = ({ fixtureTeamPlayers }) => {
  const statsNames = fixtureTeamPlayers.edges[0].node.fixtureStats.map(
    (fixtureStat) => fixtureStat.statName,
  );

  return (
    <ScrollSync>
      <StyledWrapper className="shadow">
        <StyledUpperContainer>
          <StyledFixedLeftSidebar className="top">
            Fixture
          </StyledFixedLeftSidebar>
          <ScrollSyncPane>
            <StyledTableHeader className="px-3">
              <StyledTableRow>
                {statsNames.map((statName, i) => (
                  <StyledStatHeader key={i}>{statName}</StyledStatHeader>
                ))}
              </StyledTableRow>
            </StyledTableHeader>
          </ScrollSyncPane>
        </StyledUpperContainer>

        <StyledLowerContainer>
          <StyledFixedLeftSidebar className="bottom">
            {fixtureTeamPlayers.edges.map(({ node }, i) => (
              <FixtureRowHeader key={i} fixtureTeamPlayer={node} />
            ))}
          </StyledFixedLeftSidebar>

          <ScrollSyncPane>
            <StyledTableBody>
              {fixtureTeamPlayers.edges.map(({ node }, i) => (
                <FixtureStatRow key={i} fixtureStats={node.fixtureStats} />
              ))}
            </StyledTableBody>
          </ScrollSyncPane>
        </StyledLowerContainer>
      </StyledWrapper>
    </ScrollSync>
  );
};

export default FixtureStatSection;
