import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";

import { Nav } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";

export const SHIP_HOME_DRAFT_VIEW_TABS = {
  MY_COLLECTION: "MY_COLLECTION",
  BASKET: "BASKET",
};

const ShipHomeNavBar = ({
  activeKey,
  setActiveKey,
  myCollectionCount,
  myBasketCount,
  loading,
}) => {
  return (
    <Nav
      activeKey={activeKey}
      className="secondary-nav-light no-gutters justify-content-left"
    >
      <Nav.Item>
        <Nav.Link
          eventKey={SHIP_HOME_DRAFT_VIEW_TABS.MY_COLLECTION}
          onSelect={() => setActiveKey(SHIP_HOME_DRAFT_VIEW_TABS.MY_COLLECTION)}
        >
          <span className="font-weight-bold">
            <FontAwesomeIcon icon={faLayerGroup} className="mr-2" />
            My Collection
          </span>
          <span className="badge badge-secondary ml-2">
            {loading && <CircularProgress size={16} className="text-primary" />}
            {!loading && myCollectionCount}
          </span>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          eventKey={SHIP_HOME_DRAFT_VIEW_TABS.BASKET}
          onSelect={() => setActiveKey(SHIP_HOME_DRAFT_VIEW_TABS.BASKET)}
        >
          <span className="font-weight-bold">
            <FontAwesomeIcon icon={faShoppingBasket} className="mr-2" />
            Basket
            <span className="badge badge-secondary ml-2">
              {loading && (
                <CircularProgress size={16} className="text-primary" />
              )}
              {!loading && myBasketCount}
            </span>
          </span>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default ShipHomeNavBar;
