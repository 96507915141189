import { useMutation, useQuery } from "@apollo/client";
import {
  faDownload,
  faBox,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NumberFormat from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import styled from "styled-components";
import { useDebounce } from "use-debounce";

import { logAmplitudeEvent } from "../utils/analytics";
import {
  COLLECTION_EVENTS,
  USER_COLLECTION_EVENTS,
} from "../utils/analytics/events";
import { financial, pathWithSearch } from "../utils/common";

import UPDATE_USER_COLLECTION_VISIBILITY from "../api/mutations/UPDATE_USER_COLLECTION_VISIBILITY";
import GET_MY_CARD_PARALLELS from "../api/queries/GET_MY_CARD_PARALLELS";
import GET_PUBLIC_COLLECTION_VISIBILITY from "../api/queries/GET_PUBLIC_COLLECTION_VISIBILITY";

import ErrorNotice from "../components/ErrorNotice";
import CollectionNavBar from "../components/Collection/NavBar";
import ToggleSection from "../components/Collection/ToggleSection";
import Loader from "../components/Loader";
import GridItem from "../components/Collection/GridItem";
import MyCardsModal from "../components/Collection/MyCardsModal";
import EmptySection from "../components/Collection/EmptySection";
import Filters from "../components/Collection/Filters";
import FiltersDrawerButton, {
  FiltersDrawerButtonVariants,
} from "../components/Filters/FiltersDrawerButton";
import LoadMoreButton from "../components/LoadMoreButton";
import CollectionCardParallel from "../components/CollectionCardParallel";
import SummaryDataRow, {
  SummaryDataItemWidths,
} from "../components/SummaryDataRow";
import SearchInput from "../components/Activity/SearchInput";
import ExportModal from "../components/Collection/ExportModal";
import ToggleSwitch from "../components/ToggleSwitch";
import PortfolioValue from "../components/Collection/PortfolioValue";
import LabelTooltip, { LabelTooltipVariants } from "../components/LabelTooltip";
import SortControl, {
  SortControlVariants,
} from "../components/Marketplace/SortControl";

const sortOptions = [
  {
    key: "newest",
    label: "Newest",
    value: "my_item_ownerships_max_started_at_desc,id",
    description: "The latest cards to hit your collection",
  },
  {
    key: "oldest",
    label: "Oldest",
    value: "my_item_ownerships_min_started_at_asc,id",
    description: "The oldest cards in your collection",
  },
  // TODO: This sort order seems off on production, requires investigating
  // {
  //   key: "total-value",
  //   label: "Total value",
  //   value: "my_items_value_desc,id",
  //   description: "Highest combined estimated value"
  // },
  {
    key: "quantity-owned",
    label: "Quantity owned",
    value: "my_items_count_desc,id",
    description: "Cards you own the most of",
  },
  {
    key: "quantity-listed",
    label: "Quantity listed",
    value: "my_listings_count_desc,id",
    description: "Cards you are selling the most of",
  },
  {
    key: "individual-value-desc",
    label: "Individual value (desc)",
    value: "my_items_max_value_desc,id",
    description: "Highest value cards by latest sale",
  },
  {
    key: "individual-value-asc",
    label: "Individual value (asc)",
    value: "my_items_max_value_asc,id",
    description: "Lowest value cards by latest sale",
  },
  {
    key: "my-listing-price-desc",
    label: "Listing price (desc)",
    value: "my_listings_min_price_desc,id",
    description: "Cards with your highest ask price",
  },
  {
    key: "my-listing-price-asc",
    label: "Listing price (asc)",
    value: "my_listings_min_price_asc,id",
    description: "Cards with your lowest ask price",
  },
];

const CollectionSummaryDataRow = ({ data }) => {
  const { myCardParallels } = data;

  let leftItems = [
    {
      label: "Cards owned",
      value: (
        <NumberFormat
          value={myCardParallels.myCardParallelStats.totalItemCount}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
      width: SummaryDataItemWidths.auto,
    },
    {
      label: "For sale",
      value: (
        <NumberFormat
          value={myCardParallels.myCardParallelStats.totalListingCount}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
      width: SummaryDataItemWidths.auto,
    },
  ];

  let rightItems = [
    {
      textAlignment: "text-right",
      label: "Est. value",
      labelSuffix:
        myCardParallels.myCardParallelStats.totalItemCount -
          myCardParallels.myCardParallelStats.totalValuedItemCount >
        0
          ? `(${
              myCardParallels.myCardParallelStats.totalItemCount -
              myCardParallels.myCardParallelStats.totalValuedItemCount
            } unvalued)`
          : "",
      labelTooltip: (
        <>
          Shows the combined estimated value of the items in your collection
          that meet your currently applied filters. If we don‘t have a sale
          price for some items, we will specify how many are unvalued here.
        </>
      ),
      value: myCardParallels.myCardParallelStats.totalItemValue
        ? `$${financial(myCardParallels.myCardParallelStats.totalItemValue)}`
        : "No sales data",
      valueColor: myCardParallels.myCardParallelStats.totalItemValue
        ? "text-shout"
        : "text-deselected",
      width: SummaryDataItemWidths.auto,
    },
  ];

  return (
    <div className="d-flex flex-row justify-content-between">
      <SummaryDataRow
        configValuesFirst
        configAlignItems="justify-content-start"
        configSpacing="1rem"
        summaryDataItems={leftItems}
      />
      <SummaryDataRow
        className="ml-4"
        configValuesFirst
        configAlignItems="justify-content-end"
        configSpacing="1rem"
        summaryDataItems={rightItems}
      />
    </div>
  );
};

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 0.5rem;

  @media (min-width: 64rem) {
    grid-template-columns: 1fr 1fr;
  }
`;

const GridBlock = styled.div`
  background-color: #fdfdfe;
  border: 1px solid #d1c8e2;
  border-radius: 0.5rem;
  padding: 1rem;

  a {
    display: inline-block;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    margin: 0;
  }
`;

const MyCollection = () => {
  // We can't use null as a default useQueryState value, so define an
  // appropriate placeholder and helper to represent a filter being "not set"
  const notSet = "";
  const notSetOrValue = (value) => (value === notSet ? null : value);

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const [searchQuery, setSearchQuery] = useQueryState("search", notSet);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const [sport, setSport] = useQueryState("sport", notSet);
  const [era, setEra] = useQueryState("era", notSet);

  const [productType, setProductType] = useQueryState("product_type", notSet);
  const [myListingsStatus, setMyListingsStatus] = useQueryState(
    "my_listings_status",
    notSet,
  );
  const [grader, setGrader] = useQueryState("grader", notSet);
  const [minSerialLimit, setMinSerialLimit] = useQueryState(
    "min_serial_limit",
    notSet,
  );
  const [maxSerialLimit, setMaxSerialLimit] = useQueryState(
    "max_serial_limit",
    notSet,
  );
  const [yearOption, setYearOption] = useQueryState("season", notSet);
  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [cardSet, setCardSet] = useQueryState("brand", notSet);

  const filtersAreApplied = () =>
    notSetOrValue(productType) ||
    notSetOrValue(myListingsStatus) ||
    notSetOrValue(grader) ||
    notSetOrValue(minSerialLimit) ||
    notSetOrValue(maxSerialLimit) ||
    notSetOrValue(yearOption) ||
    notSetOrValue(cardSet);

  const resetFilters = () => {
    setProductType(notSet);
    setMyListingsStatus(notSet);
    setGrader(notSet);
    setMinSerialLimit(notSet);
    setMaxSerialLimit(notSet);
    setYearOption(notSet);
    setStartYear(null);
    setEndYear(null);
    setCardSet(notSet);
  };

  const [sortOrderKey, setSortOrderKey] = useQueryState(
    "sort",
    sortOptions[0].key,
  );
  const sortOrder =
    sortOptions.find((o) => o.key === sortOrderKey) || sortOptions[0];

  const [view, setView] = useQueryState("view", "card");

  const [cardParallelToDisplay, setCardParallelToDisplay] = useState(null);

  const { data, loading, error, fetchMore } = useQuery(GET_MY_CARD_PARALLELS, {
    variables: {
      searchQuery: debouncedSearchQuery,
      sport: sport,
      era: notSetOrValue(era),
      productType: notSetOrValue(productType.toUpperCase()),
      myListingsStatus: notSetOrValue(myListingsStatus),
      grader: notSetOrValue(grader),
      minSerialLimit: notSetOrValue(minSerialLimit),
      maxSerialLimit: notSetOrValue(maxSerialLimit),
      startYear,
      endYear,
      cardSet,
      orderBy: sortOrder.value,
    },
  });

  const {
    data: accountData,
    loading: accountLoading,
    error: accountError,
  } = useQuery(GET_PUBLIC_COLLECTION_VISIBILITY);

  const [updateUserCollectionVisibility] = useMutation(
    UPDATE_USER_COLLECTION_VISIBILITY,
    {
      refetchQueries: [
        {
          query: GET_PUBLIC_COLLECTION_VISIBILITY,
        },
      ],
    },
  );

  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(
    () =>
      logAmplitudeEvent(COLLECTION_EVENTS.ALL_CARDS.PAGE_VIEWED, {
        Sport: sport,
        Era: era,
        "View Mode": view,
      }),
    [era, sport, view],
  );

  const [showExportModal, setShowExportModal] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>My Collection</title>
      </Helmet>

      <div className="row bg-white shadow border-bottom text-left">
        <div className="col inner-container-wide mx-auto">
          <div className="d-flex flex-column justify-content-center pt-4 pt-md-5">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <h2 className="font-weight-bold mr-2 mb-0">My Collection</h2>
              <div className="d-flex flex-row flex-nowrap justify-content-between align-items-start">
                <button
                  onClick={() => setShowExportModal(true)}
                  className="btn btn-sm btn-outline-primary mr-2"
                >
                  <FontAwesomeIcon icon={faDownload} />
                  <span className="d-none d-sm-inline ml-1">Export data</span>
                </button>

                {showExportModal && (
                  <ExportModal
                    show={showExportModal}
                    setShow={setShowExportModal}
                  />
                )}

                
                <Link
                  to="/ship-home/draft"
                  className="btn btn-sm btn-outline-primary"
                >
                  <FontAwesomeIcon icon={faBox} />
                  <span className="d-none d-sm-inline ml-2">Ship home</span>
                </Link>
              </div>
            </div>

            <Grid>
              <GridBlock>
                <Flex>
                  <div>
                    <p>
                      {!accountLoading && !accountError && accountData ? (
                        <>
                          {accountData.myAccount.userprofile.isCollectionPublic
                            ? "Public collection enabled"
                            : "Public collection disabled"}

                          <LabelTooltip
                            className="ml-1"
                            variant={LabelTooltipVariants.light}
                            tooltipContent={
                              <>
                                <p>
                                  Allow anyone to view your card collection via
                                  a publicly accessible link.
                                </p>

                                <p className="mb-0">
                                  If disabled you can still preview the page,
                                  but other users will not be able to access it.
                                </p>
                              </>
                            }
                          />
                        </>
                      ) : (
                        "Checking public collection…"
                      )}
                    </p>

                    {!accountLoading && !accountError && accountData && (
                      <>
                        <Link
                          className="mt-0"
                          to={`/users/${accountData.myAccount.username.toLowerCase()}`}
                        >{`starstock.com/users/${accountData.myAccount.username.toLowerCase()}`}</Link>
                        {!accountData.myAccount.userprofile
                          .isCollectionPublic && (
                          <span className="text-whisper small"> (preview)</span>
                        )}
                      </>
                    )}
                  </div>

                  {!accountLoading && !accountError && accountData ? (
                    <FormGroup>
                      <ToggleSwitch
                        checked={
                          accountData.myAccount.userprofile.isCollectionPublic
                        }
                        onChange={() => {
                          logAmplitudeEvent(
                            USER_COLLECTION_EVENTS.SETTINGS.UPDATED,
                            {
                              "Public profile enabled":
                                !accountData.myAccount.userprofile
                                  .isCollectionPublic,
                            },
                          );
                          updateUserCollectionVisibility({
                            variables: {
                              isPublic:
                                !accountData.myAccount.userprofile
                                  .isCollectionPublic,
                            },
                          });
                        }}
                        name="isCollectionPublicSwitch"
                      />
                    </FormGroup>
                  ) : (
                    <CircularProgress
                      size={32}
                      className="text-primary mr-2 mb-1"
                    />
                  )}
                </Flex>
              </GridBlock>
            </Grid>

            <CollectionNavBar
              sport={sport}
              era={era}
              setValues={(sport, era) => {
                setEra(era);
                setSport(sport);
                navigate(pathWithSearch());
              }}
            />
          </div>
        </div>
      </div>

      <PortfolioValue sport={sport} era={era} />

      <div className="inner-container-wide mx-auto">
        <div className="row">
          {/* SECTION: Sidebar */}
          <div className="col sidebar d-none d-sm-none d-md-block mt-4">
            <Filters
              productType={productType}
              setProductType={setProductType}
              myListingsStatus={myListingsStatus}
              setMyListingsStatus={setMyListingsStatus}
              grader={grader}
              setGrader={setGrader}
              minSerialLimit={minSerialLimit}
              setMinSerialLimit={setMinSerialLimit}
              maxSerialLimit={maxSerialLimit}
              setMaxSerialLimit={setMaxSerialLimit}
              yearOption={yearOption}
              setYearOption={setYearOption}
              setStartYear={setStartYear}
              setEndYear={setEndYear}
              cardSet={cardSet}
              setCardSet={setCardSet}
            />
          </div>

          {/* SECTION: Main results body */}
          <div className="col mt-4 mt-md-4 mb-3">
            {/* SECTION: Search controls */}
            <div className="d-flex flex-row flex-nowrap justify-content-between mb-3">
              <div className="d-flex flex-row flex-nowrap justify-content-start flex-fill">
                <FiltersDrawerButton
                  className="mr-2"
                  drawerOpen={showFilterDrawer}
                  setDrawerOpen={setShowFilterDrawer}
                  filtersAreApplied={filtersAreApplied()}
                  resetFilters={resetFilters}
                  configVariant={FiltersDrawerButtonVariants.iconOnly}
                >
                  <Filters
                    productType={productType}
                    setProductType={setProductType}
                    myListingsStatus={myListingsStatus}
                    setMyListingsStatus={setMyListingsStatus}
                    grader={grader}
                    setGrader={setGrader}
                    minSerialLimit={minSerialLimit}
                    setMinSerialLimit={setMinSerialLimit}
                    maxSerialLimit={maxSerialLimit}
                    setMaxSerialLimit={setMaxSerialLimit}
                    yearOption={yearOption}
                    setYearOption={setYearOption}
                    setStartYear={setStartYear}
                    setEndYear={setEndYear}
                    cardSet={cardSet}
                    setCardSet={setCardSet}
                  />
                </FiltersDrawerButton>

                <SearchInput
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  placeholder={"Filter by card, player or team"}
                />
              </div>

              <SortControl
                className="ml-2"
                sortOptions={sortOptions}
                selectedSortOptionKey={sortOrderKey}
                setSelectedSortOptionKey={(sortKey) =>
                  setSortOrderKey(sortKey, { method: "push" })
                }
                sortIsApplied={sortOrderKey !== sortOptions[0].key}
                configVariant={SortControlVariants.auto}
              />
            </div>

            {/* SECTION: Search results summary and view mode */}
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <div style={{ fontSize: "0.875rem" }}>
                {!error && !loading ? (
                  <>
                    <NumberFormat
                      value={data.myCardParallels.totalCount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    {(filtersAreApplied() || searchQuery) && " matching "}
                    {data.myCardParallels.totalCount === 1
                      ? " card type "
                      : " card types "}
                    in your collection
                  </>
                ) : (
                  <div className="d-flex flex-row flex-nowrap align-items-center">
                    <CircularProgress
                      size={16}
                      className="text-primary mr-2 mb-1"
                    />
                    Loading…
                  </div>
                )}
              </div>

              <ToggleSection
                showGrid={view === "grid"}
                setShowGrid={(gridEnabled) =>
                  setView(gridEnabled ? "grid" : "card", { method: "push" })
                }
              />
            </div>

            {/* SECTION: Collection-specific summary section */}
            <div className="border-top pt-3 pb-4">
              {!error && !loading ? (
                <CollectionSummaryDataRow data={data} />
              ) : (
                <div />
              )}
            </div>

            {/* SECTION: Search results */}
            {error && <ErrorNotice />}
            {loading && <Loader />}
            {!error && !loading && (
              <>
                {data.myCardParallels.edges.length ? (
                  <>
                    {view === "grid" ? (
                      <div className="row no-gutters row-cols-3 row-cols-sm-6 row-cols-md-4 row-cols-lg-6 row-cols-xl-8 mx-n2 mb-3">
                        {data.myCardParallels.edges.map(({ node }) => (
                          <div key={node.id} className="col px-2 mb-3">
                            <GridItem cardParallel={node} />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row no-gutters row-cols-1 row-cols-xl-2 mx-n2">
                        {data.myCardParallels.edges.map(({ node }) => (
                          <div key={node.id} className="col px-2 mb-2 mb-sm-3">
                            <CollectionCardParallel cardParallel={node} />
                          </div>
                        ))}
                      </div>
                    )}

                    {cardParallelToDisplay !== null ? (
                      <MyCardsModal
                        slug={cardParallelToDisplay.slug}
                        onHide={() => setCardParallelToDisplay(null)}
                        show
                      />
                    ) : null}
                  </>
                ) : (
                  <EmptySection />
                )}

                {data.myCardParallels.pageInfo.hasNextPage && (
                  <LoadMoreButton
                    loadingMore={loadingMore}
                    setLoadingMore={setLoadingMore}
                    onLoadMore={() =>
                      fetchMore({
                        variables: {
                          cursor: data.myCardParallels.pageInfo.endCursor,
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          setLoadingMore(false);
                          if (!fetchMoreResult) return prev;

                          fetchMoreResult.myCardParallels.edges = [
                            ...prev.myCardParallels.edges,
                            ...fetchMoreResult.myCardParallels.edges,
                          ];
                          return fetchMoreResult;
                        },
                      })
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCollection;
