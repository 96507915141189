import { gql } from "@apollo/client";

const SUBMIT_DRAFT_SHIPPING_REQUEST = gql`
  mutation submitDraftShippingRequest(
    $recipient: String!
    $addressLine1: String!
    $addressLine2: String
    $city: String!
    $zipCode: String!
    $state: String!
    $country: String!
  ) {
    submitDraftShippingRequest(
      recipient: $recipient
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      zipCode: $zipCode
      state: $state
      country: $country
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export default SUBMIT_DRAFT_SHIPPING_REQUEST;
