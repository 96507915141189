import { Modal } from "react-bootstrap";

import { AddressValues } from "../../../types";
import { AddressForm } from "./AddressForm/AddressForm";
import { AddressFormContainer, Header } from "./styles";

interface Props {
  isSubmitting: boolean;
  onCreateAddress: (values: AddressValues) => Promise<void>;
  show: boolean;
  setShow(show: boolean): void;
  setSubmitting(isSubmitting: boolean): void;
  serverError: boolean;
}

export const CreateAddressModal = ({
  isSubmitting,
  onCreateAddress,
  show,
  setShow,
  setSubmitting,
  serverError,
}: Props) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <AddressFormContainer>
        <Header>Add a new address</Header>

        <AddressForm
          onSubmit={onCreateAddress}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
          buttonLabel="Create new address"
          serverError={serverError}
        />
      </AddressFormContainer>
    </Modal>
  );
};
