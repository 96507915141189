import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import { ApolloProvider } from "@apollo/client";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import { GlobalStyles } from "./themes/GlobalStyles";
import { apolloClient } from "./utils/apolloConfig";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "react-image-lightbox/style.css";
import "./index.scss";

const Root = () => (
  <BrowserRouter>
    <Helmet defaultTitle="StarStock" titleTemplate="%s | StarStock" />
    <GlobalStyles />

    <ApolloProvider client={apolloClient}>
      <ScrollToTop />
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ApolloProvider>
  </BrowserRouter>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
