import { FormEvent, useEffect, useMemo, useState } from "react";

import LoggedIn from "../../../../components/LoggedIn";
import { LocationLink } from "../../../../components/LocationLink";
import { BidNodeEdge, Error, LotState } from "../../../../generated/schema";
import { useFetchAccountData } from "../../../../hooks/useFetchAccountData";
import { logAmplitudeEvent } from "../../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../../utils/analytics/events";
import { financial } from "../../../../utils/common";

import { useCreateBid } from "../../hooks/useCreateBid";
import { AuctionAnalytics } from "../../types";
import { getStateTitle } from "../../utils/getStateTitle";
import CongratulatoryGif from "../../assets/congratulatory-gif.gif";
import {
  BidAcceptedMessage,
  BidButton,
  BiddingExtendedMessage,
  Congratulations,
  Container,
  Controls,
  ControlsPlaceholder,
  CustomBidEntry,
  ErrorMessage,
  Form,
  Gif,
  HeaderWrapper,
  Input,
  InputWrapper,
  Paragraph,
  SetPriceButton,
  StyledBidHistory,
  StyledCountdown,
  Subtitle,
  SubtitleContainer,
  Title,
  Toast,
  TitleContainer,
} from "./styles";

interface Props {
  auctionAnalytics?: AuctionAnalytics;
  bidPriceIncrement: number | undefined | null;
  bids: (BidNodeEdge | null)[];
  endTime: Date | null;
  lotId: string | undefined;
  lotName: string;
  minimumNextBidPrice: number | undefined | null;
  newBidMinimumDuration?: number;
  openingPrice: number | null;
  state: LotState;
  sticky?: boolean;
}

export const BidPanel = ({
  auctionAnalytics,
  bids = [],
  bidPriceIncrement,
  endTime,
  lotId,
  lotName = "Lot Name",
  minimumNextBidPrice,
  openingPrice,
  state,
  sticky = false,
}: Props) => {
  const { createBidRequest } = useCreateBid(auctionAnalytics);
  const { accountData } = useFetchAccountData();
  const [bidAccepted, setBidAccepted] = useState(false);
  const [bidMessageTimeout, setBidMessageTimeout] = useState(false);
  const [bidValue, setBidValue] = useState<number>();
  const [endTimeChanged, setEndTimeChanged] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isValidBid, setIsValidBid] = useState(false);
  const [lastSecondBid, setLastSecondBid] = useState(false);
  const [showWinningMessage, setShowWinningMessage] = useState(false);
  const [submittingBid, setSubmittingBid] = useState(false);
  const [, setCachedEndTime] = useState(endTime);

  const currentUserWon = useMemo(() => {
    if (state !== LotState.SOLD || !bids.length) return false;

    const sortedBids = bids
      .filter((bid) => bid?.node?.bidPrice)
      .sort((a, b) => b?.node?.bidPrice! - a?.node?.bidPrice!);

    return sortedBids[0]?.node?.user.username === accountData?.username;
  }, [accountData, bids, state]);

  useEffect(() => setInputValue(""), [lotId]);

  useEffect(() => {
    if (currentUserWon) {
      setShowWinningMessage(true);
      setTimeout(() => setShowWinningMessage(false), 4000);
    }
  }, [currentUserWon]);

  useEffect(
    () =>
      setCachedEndTime((prevState) => {
        if (prevState !== null && prevState !== endTime) {
          setEndTimeChanged(true);
        }

        return endTime;
      }),
    [endTime],
  );

  useEffect(() => {
    if (state !== LotState.BIDDING) return;
    if (bids.length === 0) return;

    if (endTimeChanged) {
      setLastSecondBid(true);
      setEndTimeChanged(false);

      setTimeout(() => setLastSecondBid(false), 3000);
    }
  }, [bids, endTimeChanged, state]);

  const lotMissingKeyInfo =
    typeof lotId === "undefined" ||
    typeof bidPriceIncrement === "undefined" ||
    bidPriceIncrement === null ||
    typeof minimumNextBidPrice === "undefined" ||
    minimumNextBidPrice === null;

  if (lotMissingKeyInfo) return null;

  const placeBid = async () => {
    setSubmittingBid(true);

    try {
      const { data } = await createBidRequest({
        variables: { lotId, bidPrice: bidValue },
      });

      const errors = data?.bidCreate?.errors || [];

      if (errors.length > 0) {
        setErrors(errors);
        setBidAccepted(false);
      } else {
        setErrors([]);
        setBidAccepted(true);
      }
    } catch {
      setErrors([
        {
          field: "field",
          message: "could not place bid",
          __typename: "Error",
        },
      ]);
      setBidAccepted(false);
    }

    setBidMessageTimeout(true);
    setInputValue("");
    setIsValidBid(false);
    setSubmittingBid(false);

    setTimeout(() => {
      setBidAccepted(false);
      setBidMessageTimeout(false);
      setErrors([]);
    }, 3000);
  };

  const bidEntryHandler = (value: string) => {
    const parsedValue = parseFloat(value);
    setInputValue(value);

    if (
      typeof minimumNextBidPrice === "number" &&
      parsedValue >= minimumNextBidPrice
    ) {
      setBidValue(parseFloat(parsedValue.toFixed(2)));
      setIsValidBid(true);
    } else {
      setIsValidBid(false);
    }
  };

  const setBidToMinimum = () => {
    bidEntryHandler(`${minimumNextBidPrice}`);
    logAmplitudeEvent(BREAKS_EVENTS.AUCTION.QUICK_SET_BID_PRESSED, {
      "Auction Name": auctionAnalytics?.name,
      "Auction Slug": auctionAnalytics?.slug,
      "Auction State": auctionAnalytics?.state,
      "Bid Price": minimumNextBidPrice,
      "Lot ID": lotId,
      "Lot Name": lotName,
      "Lot State": state,
    });
  };

  const handleCustomBid = (e: FormEvent<HTMLInputElement>) =>
    bidEntryHandler(e.currentTarget.value);

  const openForBids = endTime && state === LotState.BIDDING;
  const showControls = ![
    LotState.CANCELED,
    LotState.SOLD,
    LotState.UNSOLD,
  ].includes(state);

  const renderSubtitle = () => {
    const showBidAccepted = bidAccepted && bidMessageTimeout;
    const showError = errors.length > 0 && bidMessageTimeout;

    if (showBidAccepted)
      return <BidAcceptedMessage>Your bid was accepted!</BidAcceptedMessage>;
    if (showError && errors[0] && errors[0].message) {
      const formattedErrorMessage =
        errors[0].message.charAt(0).toLowerCase() + errors[0].message.slice(1);
      return <ErrorMessage>Sorry, {formattedErrorMessage}.</ErrorMessage>;
    }

    return lotName;
  };

  return (
    <Container sticky={sticky}>
      <HeaderWrapper>
        <TitleContainer>
          <Title>{getStateTitle(state)}</Title>
          {openForBids && <StyledCountdown endTime={endTime} />}
        </TitleContainer>

        <SubtitleContainer>
          <Subtitle>{renderSubtitle()}</Subtitle>
          {lastSecondBid && (
            <BiddingExtendedMessage>⏱ Time extended</BiddingExtendedMessage>
          )}
        </SubtitleContainer>
      </HeaderWrapper>

      <Controls>
        <LoggedIn
          fallback={
            <ControlsPlaceholder>
              Ready to bid? <LocationLink to="/login">Log in</LocationLink> or{" "}
              <LocationLink to={"/signup"}>sign up</LocationLink>
            </ControlsPlaceholder>
          }
        >
          {showControls ? (
            <Form>
              <CustomBidEntry>
                <SetPriceButton
                  onClick={setBidToMinimum}
                  disabled={submittingBid}
                >
                  ${financial(minimumNextBidPrice, 0)}
                </SetPriceButton>
                <InputWrapper hasInput={!!inputValue}>
                  <Input
                    hasInput={!!inputValue}
                    type={"number"}
                    min={minimumNextBidPrice}
                    onChange={handleCustomBid}
                    placeholder={`Enter $${financial(
                      minimumNextBidPrice,
                      0,
                    )} or more`}
                    step={bidPriceIncrement}
                    value={inputValue}
                    prefix={"$"}
                  />
                </InputWrapper>
              </CustomBidEntry>

              <BidButton
                disabled={!isValidBid || submittingBid || !openForBids}
                loading={submittingBid}
                onClick={placeBid}
              >
                {state === LotState.BIDDING ? "Submit bid" : "Wait for it…"}
              </BidButton>
            </Form>
          ) : (
            <ControlsPlaceholder>Bidding is closed</ControlsPlaceholder>
          )}
        </LoggedIn>
      </Controls>

      <StyledBidHistory
        bids={bids}
        currentUser={accountData?.username}
        state={state}
        openingPrice={openingPrice}
        sticky={sticky}
      />

      {showWinningMessage && (
        <Toast>
          <Gif src={CongratulatoryGif} alt="" />

          <Congratulations>Congratulations!</Congratulations>
          <Paragraph>You won this lot</Paragraph>
        </Toast>
      )}
    </Container>
  );
};
