import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const ButtonContainer = styled.div`
  padding: 1.5rem;
`;

export const Button = styled.button`
  background-color: #fff;
  border: 1px dashed rgb(44 77 118 / 20%);
  border-radius: 0.25rem;
  color: ${Colors.Astronaut};
  font-size: 1rem;
  font-weight: bold;
  height: 4rem;
  line-height: 1.5rem;
  text-align: center;
  width: 100%;
`;
