import { financial } from "../../../../utils/common";

import { Amount, Container, Message, Thumbnail } from "./styles";
import { BidState } from "../../../../generated/schema";

interface Props {
  amount: number | null | undefined;
  bidUser: string;
  currentUser?: string;
  ended?: boolean;
  state: BidState;
  thumbnail?: string;
  winning?: boolean;
}

export const Bid = ({
  amount,
  bidUser,
  currentUser,
  ended = false,
  state,
  thumbnail,
  winning = false,
}: Props) => {
  const isCurrentUser = bidUser === currentUser;
  const isCanceled = state === BidState.CANCELED;
  const winningEnded = winning && ended && !isCanceled;
  const winningOngoing = winning && !ended && !isCanceled;

  return (
    <Container ended={ended} winning={winning}>
      {thumbnail && <Thumbnail src={thumbnail} alt="" />}

      {isCurrentUser ? (
        <Message>
          <span>You</span>
          {winningEnded && " won!"}
          {winningOngoing && " are winning!"}
        </Message>
      ) : (
        <Message>
          <span>{bidUser}</span>
          {winningEnded && " won!"}
          {winningOngoing && " is winning!"}
        </Message>
      )}

      <Amount>{isCanceled ? "Canceled" : `$${financial(amount, 0)}`}</Amount>
    </Container>
  );
};
