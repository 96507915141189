const ContentWrapper = ({ extraClass, children }) => {
  return (
    <div className="row bg-light py-md-5">
      <div
        className={`col card shadow p-md-4 mx-auto ${
          (extraClass && extraClass) || "cms-markdown"
        }`}
        style={{ maxWidth: "48rem" }}
      >
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
};

export default ContentWrapper;
