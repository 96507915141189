import styled from "styled-components";
import { Select, FormControl } from "@material-ui/core";

export const StyledFormControl = styled(FormControl)`
  display: block !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  vertical-align: baseline !important;
`;

export const StyledSelect = styled(Select)`
  border: 1px solid #dcddde;
  border-radius: 8px;
  width: 100%;
`;

export const StyledInputLabel = styled.p`
  color: #2c4d76;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
`;
