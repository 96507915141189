import { gql } from "@apollo/client";

const GET_MARKET_INDEX = gql`
  query GET_MARKET_INDEX($slug: String!) {
    marketIndex(slug: $slug) {
      id
      symbol
      name
      description
      latestSnapshot {
        id
        date
        name
        value
        valueChange
        valueChangePercentage
        normalisedValueMultiplier
        normalisedValue
        normalisedValueChange
        normalisedValueChangePercentage
      }
      snapshots {
        totalCount
        edges {
          node {
            id
            date
            name
            value
            valueChange
            valueChangePercentage
            normalisedValueMultiplier
            normalisedValue
            normalisedValueChange
            normalisedValueChangePercentage
          }
        }
      }
      components {
        totalCount
        edges {
          node {
            id
            subcategory
            gradedCardParallel {
              grade {
                id
              }
              cardParallel {
                slug
              }
            }
            player {
              name
              position
              headshotPhoto
              startYear
              currentTeam {
                name
                abbrName
                colorPrimary
                colorSecondary
                colorTertiary
              }
            }
            componentSnapshots(first: 50) {
              totalCount
              edges {
                node {
                  id
                  price
                  priceChange
                  priceChangePercentage
                  fantasyStatus
                  fantasyPoints
                  marketIndexSnapshot {
                    id
                    date
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_MARKET_INDEX;
