export const logAmplitudeEvent = (eventType, eventProperties = {}) => {
  window.dataLayer.push({
    event: "logEvent",
    eventType: eventType,
    eventProperties:
      Object.keys(eventProperties).length > 0 ? eventProperties : undefined,
  });
};

const setAmplitudeUserId = (userId) => {
  window.dataLayer.push({
    event: "setUserId",
    amplitudeUserId: userId,
  });
};

const setAmplitudeUserProperties = (userProperties = {}) => {
  window.dataLayer.push({
    event: "setUserProperties",
    amplitudeUserProperties: userProperties,
  });
};

export const amplitudeIdentify = (payload = {}) => {
  setAmplitudeUserId(payload ? payload.userId : null);

  if (payload) {
    setAmplitudeUserProperties({
      Username: payload.username,
      "Is Staff": payload.isStaff,
      Balance: payload.userBalance,
    });
  }
};
