import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const ItemOwnershipItemCheckbox = ({
  checkboxAction,
  itemOwnership,
  label,
}) => {
  return (
    <FormControlLabel
      className="mr-0"
      control={
        <Checkbox
          onChange={() => checkboxAction(itemOwnership)}
          checked={itemOwnership.inDraftShippingRequest}
        />
      }
      label={label}
    />
  );
};

export default ItemOwnershipItemCheckbox;
