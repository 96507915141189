import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import GET_MY_ACTIVITY_METADATA from "../api/queries/GET_MY_ACTIVITY_METADATA";

import ErrorNotice from "../components/ErrorNotice";
import Loader from "../components/Loader";
import BidSection from "../components/Activity/BidSection";
import BoughtSection from "../components/Activity/BoughtSection";
import ListingSection from "../components/Activity/ListingSection";
import SoldSection from "../components/Activity/SoldSection";
import TradeNavBar from "../components/Activity/NavBar";

const Activity = () => {
  const { category } = useParams();
  const getActiveCategory = () => {
    switch (category) {
      case "listings":
        return "TRADE_LISTINGS";
      case "bought":
        return "BOUGHT_ITEMS";
      case "sold":
        return "SOLD_ITEMS";
      default:
        return "TRADE_BIDS";
    }
  };
  const categoryKey = getActiveCategory();

  const { data, loading, error } = useQuery(GET_MY_ACTIVITY_METADATA);

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;

  const { myGroupedBids, myGroupedListings } = data;

  return (
    <>
      <Helmet>
        <title>Activity</title>
      </Helmet>

      <div className="row bg-white shadow border-bottom text-center">
        <div className="col inner-container mx-auto">
          <div className="d-flex flex-column align-items-start pt-4 pt-md-5">
            <h2 className="font-weight-bold mr-2 mb-3 mb-md-4">Activity</h2>
            <TradeNavBar
              categoryKey={categoryKey}
              groupedBidsCount={myGroupedBids.totalCount}
              groupedListingsCount={myGroupedListings.totalCount}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col inner-container mx-auto">
          {categoryKey === "TRADE_BIDS" && <BidSection />}
          {categoryKey === "TRADE_LISTINGS" && <ListingSection />}
          {categoryKey === "BOUGHT_ITEMS" && <BoughtSection />}
          {categoryKey === "SOLD_ITEMS" && <SoldSection />}
        </div>
      </div>
    </>
  );
};

export default Activity;
