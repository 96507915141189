import { useLocation } from "react-router-dom";

interface State {
  from?: { pathname: string };
}

export const useFromLocation = () => {
  const location = useLocation();
  const state = location?.state as State;
  const from = state?.from?.pathname || state?.from || "/";

  return { from };
};
