import { pluralize } from "../../utils/content";

const ErrorConfirmation = ({ errors, onClose }) => {
  return (
    <div className="p-3">
      <div className="alert alert-danger text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">There was a problem</h3>
        {errors.map((error, i) => (
          <p key={i} className="my-1">
            {error.message}
          </p>
        ))}
      </div>

      <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary px-5" onClick={() => onClose()}>
          Close
        </button>
      </div>
    </div>
  );
};

const SuccessConfirmation = ({
  cardsSoldCount,
  listingsCreatedCount,
  onClose,
}) => {
  return (
    <div className="p-3">
      <div className="alert alert-primary text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">
          {cardsSoldCount > 0
            ? "Congratulations on your sale!"
            : "Thanks, your listing is now live"}
        </h3>
        {cardsSoldCount > 0 && (
          <p className="my-1">
            You sold <strong>{pluralize("item", cardsSoldCount)}</strong>
          </p>
        )}
        {listingsCreatedCount > 0 && (
          <p className="my-1">
            You created{" "}
            <strong>{pluralize("new listing", listingsCreatedCount)}</strong>
          </p>
        )}
      </div>

      <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary px-5" onClick={() => onClose()}>
          Continue
        </button>
      </div>
    </div>
  );
};

const StepComplete = ({ formData, onClose }) => {
  return (
    <>
      {formData.completedErrors && formData.completedErrors.length ? (
        <ErrorConfirmation
          errors={formData.completedErrors}
          onClose={() => onClose()}
        />
      ) : (
        <SuccessConfirmation
          cardsSoldCount={formData.completedSalesCount}
          listingsCreatedCount={formData.completedListingsCount}
          onClose={() => onClose()}
        />
      )}
    </>
  );
};

export default StepComplete;
