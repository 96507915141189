import { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { useQueryState } from "react-router-use-location-state";
import { useDebounce } from "use-debounce";

import { logAmplitudeEvent } from "../../utils/analytics";
import { ACTIVITY_EVENTS, SELLING_EVENTS } from "../../utils/analytics/events";
import { pluralize } from "../../utils/content";

import GET_MY_ACTIVITY_METADATA from "../../api/queries/GET_MY_ACTIVITY_METADATA";
import GET_MY_GROUPED_LISTINGS from "../../api/queries/GET_MY_GROUPED_LISTINGS";
import GROUPED_LISTING_CLOSE from "../../api/mutations/GROUPED_LISTING_CLOSE";

import EmptySection from "./EmptySection";
import ListingListCardItem from "./ListingListCardItem";
import Loader from "../Loader";
import ErrorNotice from "../ErrorNotice";
import LoadMoreButton from "../LoadMoreButton";
import SearchSortQueryControls from "../SearchSortQueryControls";

const sortOptions = [
  {
    key: "newest",
    label: "Newest",
    value: "created_at_desc",
    description: "Your most recently created listings",
  },
  {
    key: "oldest",
    label: "Oldest",
    value: "created_at_asc",
    description: "View your listings made longest ago",
  },
  {
    key: "expiring-soon",
    label: "Expiring soon",
    value: "expires_after_asc,id",
    description: "Sort listings by how close they are to expiring",
  },
  {
    key: "highest-ask-price",
    label: "Highest ask price",
    value: "ask_price_desc,id",
    description: "Your biggest listings by ask price",
  },
  {
    key: "lowest-ask-price",
    label: "Lowest ask price",
    value: "ask_price_asc,id",
    description: "See your most budget friendly listings",
  },
  {
    key: "best-offer-price",
    label: "Best offer price",
    value: "highest_bid_price_desc,id",
    description: "By the highest bid price from sellers",
  },
  {
    key: "closest-offer",
    label: "Closest offer",
    value: "highest_bid_percentage_desc,id",
    description: "Percentage the best offer meets of your ask price",
  },
];

const ListingSection = () => {
  useEffect(() => logAmplitudeEvent(ACTIVITY_EVENTS.LISTINGS.PAGE_VIEWED), []);

  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const [sortOrderKey, setSortOrderKey] = useQueryState(
    "sort",
    sortOptions[0].key,
  );
  const sortOrder =
    sortOptions.find((o) => o.key === sortOrderKey) || sortOptions[0];

  const { data, loading, error, fetchMore } = useQuery(
    GET_MY_GROUPED_LISTINGS,
    {
      variables: {
        searchQuery: debouncedSearchQuery,
        orderBy: sortOrder.value,
      },
      fetchPolicy: "network-only",
    },
  );

  const [loadingMore, setLoadingMore] = useState(false);

  const mutationRefetches = [
    { query: GET_MY_ACTIVITY_METADATA },
    {
      query: GET_MY_GROUPED_LISTINGS,
      variables: {
        searchQuery: debouncedSearchQuery,
        orderBy: sortOrder.value,
      },
      fetchPolicy: "network-only",
    },
  ];

  const [groupedListingClose] = useMutation(GROUPED_LISTING_CLOSE, {
    refetchQueries: mutationRefetches,
    onCompleted({ groupedListingClose }) {
      if (groupedListingClose.errors && groupedListingClose.errors.length > 0) {
        logAmplitudeEvent(SELLING_EVENTS.EXISTING_LISTING.CANCEL.FAILED, {
          Error: groupedListingClose.errors[0],
        });
      } else {
        logAmplitudeEvent(SELLING_EVENTS.EXISTING_LISTING.CANCEL.CANCELED);
      }
    },
  });

  return (
    <div className="mt-4 mb-3">
      <SearchSortQueryControls
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        sortOrderKey={sortOrderKey}
        setSortOrderKey={setSortOrderKey}
        sortOptions={sortOptions}
        trackingLabel={ACTIVITY_EVENTS.LISTINGS.SEARCH_UPDATED}
      />

      {error && <ErrorNotice error={error} />}
      {loading && <Loader />}

      {!error && !loading && (
        <>
          <div className="d-flex flex-row justify-content-between align-items-baseline mt-4 mb-3">
            <span className="font-weight-bold">
              {pluralize("listing", data.myGroupedListings.totalCount)}
            </span>
          </div>

          {data.myGroupedListings.edges.length ? (
            <div className="row">
              {data.myGroupedListings.edges.map(({ node }) => (
                <div className="col-12 mb-3" key={node.id}>
                  <ListingListCardItem
                    groupedListing={node}
                    groupedListingClose={groupedListingClose}
                    acceptOfferRefetches={mutationRefetches}
                  />
                </div>
              ))}
            </div>
          ) : (
            <EmptySection />
          )}

          {data.myGroupedListings.pageInfo.hasNextPage && (
            <LoadMoreButton
              loadingMore={loadingMore}
              setLoadingMore={setLoadingMore}
              onLoadMore={() =>
                fetchMore({
                  variables: {
                    cursor: data.myGroupedListings.pageInfo.endCursor,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    setLoadingMore(false);
                    if (!fetchMoreResult) return prev;

                    fetchMoreResult.myGroupedListings.edges = [
                      ...prev.myGroupedListings.edges,
                      ...fetchMoreResult.myGroupedListings.edges,
                    ];
                    return fetchMoreResult;
                  },
                })
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default ListingSection;
