import { gql } from "@apollo/client";

const UPDATE_USER_COLLECTION_VISIBILITY = gql`
  mutation UPDATE_USER_COLLECTION_VISIBILITY($isPublic: Boolean!) {
    updateUserCollectionVisibility(isPublic: $isPublic) {
      ok
    }
  }
`;

export default UPDATE_USER_COLLECTION_VISIBILITY;
