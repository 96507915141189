import { Navigate, Route, Routes } from "react-router-dom";

import StaffOnly from "../../../components/StaffOnly";
import { NavBarWrapper } from "../../../utils/wrappers";

import { Upcoming } from "./Upcoming";

export const LiveRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/live/upcoming" />} />

    <Route path="upcoming">
      <Route
        path=":date"
        element={
          <NavBarWrapper>
            <StaffOnly>
              <Upcoming />
            </StaffOnly>
          </NavBarWrapper>
        }
      />

      <Route
        path=""
        element={
          <NavBarWrapper>
            <StaffOnly>
              <Upcoming />
            </StaffOnly>
          </NavBarWrapper>
        }
      />
    </Route>
  </Routes>
);
