import React from "react";

import { Carousel } from "./Carousel";

const Astronaut = "#2c4d76";
const DefaultThemeColor = Astronaut;

const CardParallelTallCarousel = ({
  themeColor = DefaultThemeColor,
  children,
}) => {
  return (
    <Carousel
      themeColor={themeColor}
      customSettings={{
        dots: React.Children.count(children) > 5,
        arrows: React.Children.count(children) > 5,
        infinite: React.Children.count(children) > 5,
        speed: 400,
        initialSlide: 0,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
          {
            settings: {
              slidesToShow: 1.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 374,
          },
          {
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 399,
          },
          {
            settings: {
              slidesToShow: 1.75,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 479,
          },
          {
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 699,
          },
          {
            settings: {
              slidesToShow: 3.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 847,
          },
          {
            settings: {
              infinite: React.Children.count(children) > 4,
              arrows: React.Children.count(children) > 4,
              slidesToShow: 4,
              slidesToScroll: 4,
            },
            // This might be too early to go to 5; means just 185px per card
            breakpoint: 1024,
          },
        ],
      }}
    >
      {children}
    </Carousel>
  );
};

export default CardParallelTallCarousel;
