import { Link } from "react-router-dom";

import { positionAndTeamName } from "../utils/content";

import playerOutline from "../images/player-outline.png";

const PlayerListing = ({ player }) => {
  const { slug, name, position, headshotPhoto, currentTeam } = player;
  const imageSrc = headshotPhoto || playerOutline;

  return (
    <div className="card clickable-card d-flex flex-row align-items-center overflow-hidden">
      <Link
        to={`/marketplace/players/${slug}`}
        className="stretched-link flex-grow-0 flex-shrink-0 d-flex"
        style={{ width: "96px", height: "96px" }}
      >
        <img
          className="bg-light flex-grow-0 flex-shrink-0"
          style={{ width: "96px", height: "96px", objectFit: "contain" }}
          src={imageSrc}
          alt="Player headshot"
        />
      </Link>

      <div className="py-2 px-3 flex-fill overflow-hidden">
        <p className="text-truncate mb-1">
          {positionAndTeamName(position, currentTeam)}
        </p>
        <h5 className="font-weight-bold text-shout mb-0">{name}</h5>
      </div>
    </div>
  );
};

export default PlayerListing;
