import { gql } from "@apollo/client";

const GET_PROMOTION = gql`
  query GET_PROMOTION {
    promotion {
      description
      id
    }
  }
`;

export default GET_PROMOTION;
