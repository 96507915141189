import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const TRACK_WIDTH = 56;
const TRACK_HEIGHT = 32;
const TRACK_PADDING = 4;

const THUMB_MIN_TRAVEL = 16;
const THUMB_HEIGHT = TRACK_HEIGHT - 2 * TRACK_PADDING;
const THUMB_WIDTH = Math.min(
  THUMB_HEIGHT,
  TRACK_WIDTH - THUMB_MIN_TRAVEL - 2 * TRACK_PADDING,
);

const TRACK_RADIUS =
  Math.min(THUMB_WIDTH + 2 * TRACK_PADDING, TRACK_HEIGHT) / 2;

const THUMB_RADIUS = Math.min(THUMB_WIDTH, TRACK_HEIGHT) / 2;

const THUMB_TRAVEL = TRACK_WIDTH - THUMB_WIDTH - 2 * TRACK_PADDING;

const ToggleSwitch = withStyles((theme) => ({
  root: {
    width: TRACK_WIDTH,
    height: TRACK_HEIGHT,
    padding: 0,
    margin: 0,
  },
  switchBase: {
    padding: TRACK_PADDING,
    "&$focusVisible + $thumb": {
      color: "#4e8ad4",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: THUMB_WIDTH,
    height: THUMB_HEIGHT,
    boxShadow: "none",
    background: "#ffffff",
    borderRadius: THUMB_RADIUS,
  },
  track: {
    borderRadius: TRACK_RADIUS,
    backgroundColor: "#d0d0d0",
    background: "linear-gradient(to right, #c0c0c0, #d0d0d0)",
    opacity: "1 !important",
  },
  checked: {
    "&$switchBase": {
      transform: `translateX(${THUMB_TRAVEL}px)`,
    },
    "& + $track": {
      background: "linear-gradient(to left, #86beec, #4e8ad4)",
    },
  },
  focusVisible: {
    background: "#2c4d76d0",
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default ToggleSwitch;
