import MarketIndexWinnersAndLosersWidget from "./MarketIndexWinnersAndLosersWidget";

const slug = "nba-emerging-30";

const NBAEmerging30Widget = () => {
  return (
    <MarketIndexWinnersAndLosersWidget
      slug={slug}
      title={
        <div className="d-flex flex-row align-items-top">
          🏀 NBA Emerging 30
        </div>
      }
      subtitle={
        <>
          Our first market index, tracking{" "}
          <a
            href={`/marketplace/indices/${slug}`}
            className="text-primary font-weight-bold"
          >
            Prizm Base RC
          </a>{" "}
          (StarStock A) cards for 30 top NBA prospects from the 2017-2020 draft
          classes
        </>
      }
    />
  );
};

export default NBAEmerging30Widget;
