import { gql } from "@apollo/client";

const DELETE_WISHLIST_ITEM = gql`
  mutation DELETE_WISHLIST_ITEM($id: ID!) {
    wishlistItemDelete(id: $id) {
      errors {
        field
        message
      }
      wishlistItem {
        id
      }
    }
  }
`;

export default DELETE_WISHLIST_ITEM;
