import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

import { Colors } from "../../themes/Default";

import { InfoNoticeState } from ".";

const states: { [key in InfoNoticeState]: string } = {
  negative: "#f7d2d2",
  neutral: "#E3EAF8",
  positive: "#c4face",
};

const fill: { [key in InfoNoticeState]: string } = {
  negative: "#D51E1E",
  neutral: Colors.Blue,
  positive: "#067B1A",
};

export const Icon = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 4.5rem;
  justify-content: center;
  margin-bottom: 1rem;
  width: 4.5rem;
`;

export const Message = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;

  & + & {
    margin-top: 1rem;
  }
`;

export const StyledFAIcon = styled(FontAwesomeIcon)`
  height: 2.5rem;
  width: 2.5rem !important;
`;

export const Container = styled.div<{ state: InfoNoticeState }>`
  align-items: center;
  color: ${Colors.Astronaut};
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  ${Icon} {
    ${({ state }) =>
      css`
        background-color: ${states[state]};
      `}
  }

  ${StyledFAIcon} path {
    ${({ state }) =>
      css`
        fill: ${fill[state]};
      `}
  }
`;

export const Title = styled.h2`
  color: ${Colors.Astronaut};
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
`;
