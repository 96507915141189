import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Colors } from "../../../../themes/Default";

const BREAKPOINT = "60rem";
const BREAKPOINT_LARGE = "78rem";

const Button = css`
  background: none;
  border: 1px solid white;
  display: inline-block;
  line-height: 1;
  padding: 0.5rem;

  :focus,
  :hover {
    /* Temporary Style */
    border: 1px solid ${Colors.Blue};
  }
`;

export const Contents = styled.div`
  box-shadow: 0 1.5px 0 0 #ebf1fb, 0 -1.5px 0 0 #ebf1fb;
`;

export const Controls = styled.div`
  align-items: center;
  background: white;
  box-shadow: 0 4px 8px 0 rgb(41 66 115 / 10%), 0 1px 0 0 #bdc4d4;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-areas: "prev heading next";
  grid-template-columns: auto 1fr auto;
  padding: 1.25rem 1rem;

  @media (min-width: ${BREAKPOINT}) {
    grid-column-gap: 0;
    grid-template-areas: "prev . next . heading";
    grid-template-columns: auto 0.5rem auto 1.75rem 1fr;
    padding: 2rem;
  }

  @media (min-width: ${BREAKPOINT_LARGE}) {
    padding: 2rem 3rem;
  }
`;

export const Heading = styled.h2`
  color: ${Colors.Blue};
  font-size: 1rem;
  font-weight: bold;
  grid-area: heading;
  line-height: 1.5rem;
  margin: 0;
  text-align: center;

  @media (min-width: ${BREAKPOINT}) {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
  }
`;

export const NextButton = styled.button`
  ${Button}

  grid-area: next;
`;

export const PrevButton = styled.button`
  ${Button}

  grid-area: prev;
`;

export const StyledFAIcon = styled(FontAwesomeIcon)`
  height: 1.25rem !important;
  width: 1.25rem !important;

  @media (min-width: ${BREAKPOINT}) {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
`;
