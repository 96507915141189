import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { CREATE_BID } from "../api/CREATE_BID";
import { GET_AUCTION } from "../api/GET_AUCTION";
import { GET_LOT } from "../api/GET_LOT";
import { BidCreatedResponse } from "../types";

export const useUpdateCache = () => {
  const client = useApolloClient();

  const addBid = useCallback(
    ({ bid }: BidCreatedResponse) => {
      client.writeQuery({
        query: CREATE_BID,
        variables: {
          lotId: bid.lot.id,
          bidPrice: bid.bidPrice,
        },
        data: {
          bidCreate: {
            errors: [],
            bid: {
              id: bid.id,
              bidPrice: bid.bidPrice,
              state: "SUBMITTED",
              createdAt: new Date(),
              user: {
                id: bid.user.id,
                username: bid.user.username,
                __typename: "UserType",
              },
              __typename: "BidNode",
            },
            lot: {
              id: bid.lot.id,
              name: bid.lot.name,
              order: bid.lot.order,
              biddingEndsAfter: bid.lot.biddingEndsAfter,
              highestBidPrice: bid.lot.highestBidPrice,
              bidPriceIncrement: bid.lot.bidPriceIncrement,
              minimumNextBidPrice: bid.lot.minimumNextBidPrice,
            },
          },
        },
      });

      const id = client.cache.identify({
        id: bid.lot.id,
        __typename: "LotNode",
      });

      client.cache.modify({
        id,
        fields: {
          bidSet(currentBids = []) {
            const newBidNodeEdge = {
              __typename: "BidNodeEdge",
              node: { __ref: `BidNode:${bid.id}` },
            };

            // Quick safety check - if the new bid is already
            // present in the cache, we don't need to add it again.
            if (
              currentBids.edges.some((ref: any) => {
                return ref.node["__ref"] === `BidNode:${bid.id}`;
              })
            ) {
              return currentBids;
            }

            return {
              ...currentBids,
              edges: [...currentBids.edges, newBidNodeEdge],
            };
          },
          biddingEndsAfter: () => bid.lot.biddingEndsAfter,
          highestBidPrice: () => bid.lot.highestBidPrice,
          minimumNextBidPrice: () => bid.lot.minimumNextBidPrice,
        },
      });
    },
    [client],
  );

  const refetchAuction = useCallback(() => {
    client.refetchQueries({ include: [GET_AUCTION] });
  }, [client]);

  const refetchLot = useCallback(() => {
    client.refetchQueries({ include: [GET_LOT] });
  }, [client]);

  return { addBid, refetchAuction, refetchLot };
};
