import styled from "styled-components";

import { Colors } from "../../themes/Default";

export const SkipLink = styled.a`
  background-color: #ebf1fb;
  border-radius: 0.5rem;
  color: ${Colors.Astronaut};
  display: inline-block;
  font-weight: bold;
  left: -50rem;
  line-height: 1.25rem;
  padding: 1rem;
  position: absolute;
  z-index: -1;

  :focus {
    left: 1rem;
    top: 5.5rem;
    z-index: 1000;

    @media (min-width: 66rem) {
      top: 7rem;
    }
  }
`;
