import styled, { css } from "styled-components";

interface Props {
  cHeight: number;
  background?: string;
  cWidth: number;
}

const baseColor = "#f0f0f0";
const gradientStart = "#f3d7c0";
const gradientEnd = "#dec8ee";

export const Container = styled.div<Props>`
  background: linear-gradient(#ffffffc0, #ffffffc0),
    ${({ background }) => css`
      url(${background}) center / 50% auto no-repeat,
    `}
      linear-gradient(
        135deg,
        ${gradientStart},
        #fff 40%,
        #fff 60%,
        ${gradientEnd}
      ),
    ${baseColor};
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: ${(props) =>
      css`
        ${(props.cHeight / props.cWidth) * 100}%
      `};
  }
`;

export const Image = styled.img`
  height: 100%;
  left: 0;
  line-height: 1;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;
