import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_ID_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
  PARALLEL_FRAGMENT,
  PLAYER_CARD_WITH_CURRENT_TEAM_FRAGMENT,
} from "../fragments";

const GET_CARD_PARALLEL = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_WITH_CURRENT_TEAM_FRAGMENT}
  query GET_CARD_PARALLEL($slug: String!) {
    cardParallel(slug: $slug) {
      ...cardParallelIdFields
      frontSidePhoto
      frontSidePhotoSmall
      frontSidePhotoMedium
      reverseSidePhoto
      boxProductPhoto
      boxProductPhotoSmall
      boxProductPhotoMedium
      itemCount
      sellOrderCount
      parallel {
        ...parallelFields
      }
      playerCard {
        ...playerCardWithCurrentTeamFields
      }
      myWishlistItems {
        id
      }
      recentPurchases {
        date
        price
        source
        myRole
        grade {
          ...gradeFields
        }
      }
      gradedcardparallelSet {
        edges {
          node {
            ...gradedCardParallelFields
            myItemCount
            myOpenSellOrderCount
            myOpenSellOrderMinPrice
            myOpenBuyOrderCount
            mySuspendedBuyOrderCount
            grade {
              ...gradeFields
            }
          }
        }
      }
    }
    grades {
      edges {
        node {
          ...gradeFields
        }
      }
    }
  }
`;

export default GET_CARD_PARALLEL;
