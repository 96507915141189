import { useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";

import IS_LOGGED_IN from "../api/queries/IS_LOGGED_IN";

const PleaseLogOut = ({ children }) => {
  const { data } = useQuery(IS_LOGGED_IN);
  if (!data || !data.isLoggedIn) {
    return <>{children}</>;
  }

  return <Navigate to="/" />;
};

export default PleaseLogOut;
