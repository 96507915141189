import styled from "styled-components";
import { Styles } from "react-modal";

import { Colors } from "../../themes/Default";

export const Header = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #dcddde;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  padding: 1.5rem;
`;

export const Title = styled.h4`
  color: ${Colors.Astronaut};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin-bottom: 0;
  text-align: left;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  line-height: 1;
  margin-left: auto;
  padding: 0;

  svg {
    fill: rgb(220 221 222 / 100%);
    transform: rotate(45deg);
    width: 1.5rem;
  }

  :active,
  :focus,
  :hover {
    svg {
      fill: ${Colors.Astronaut};
    }
  }
`;

export const defaultStyles: Styles = {
  content: {
    bottom: "auto",
    left: "50%",
    margin: "auto",
    maxHeight: "80vh",
    maxWidth: "50rem",
    padding: "0",
    right: "auto",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
  },
  /* stylelint-disable */
  overlay: {
    zIndex: 100,
  },
  /* stylelint-enable */
};
