import OptionPillsFilter from "./OptionPillsFilter";

const MyListingsStatusFilter = ({ selectedValue, setSelectedValue }) => {
  const options = [
    { label: "Any status", value: "" },
    { label: "Listed", value: "listed" },
    { label: "Unlisted", value: "unlisted" },
  ];

  const selectedOption =
    options.find((option) => option.value === selectedValue) || options[0];

  return (
    <div className="mb-4">
      <h6>Listing status</h6>
      <hr className="mt-1" />

      <OptionPillsFilter
        options={options}
        selectedOption={selectedOption}
        setSelectedValue={setSelectedValue}
      />
    </div>
  );
};

export default MyListingsStatusFilter;
