import React from "react";

import { Carousel, CarouselThemes } from "./Carousel";

const ItemSaleCarousel = ({ themeColor, children }) => {
  return (
    <Carousel
      theme={CarouselThemes.dark}
      themeColor={themeColor}
      customSettings={{
        initialSlide: 0,
        slidesToShow: 7,
        slidesToScroll: 7,
        dots: React.Children.count(children) > 7,
        arrows: React.Children.count(children) > 7,
        infinite: React.Children.count(children) > 7,
        speed: 400,
        responsive: [
          {
            settings: {
              slidesToShow: 2.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 374,
          },
          {
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 399,
          },
          {
            settings: {
              slidesToShow: 2.75,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 499,
          },
          {
            settings: {
              slidesToShow: 3.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 599,
          },
          {
            settings: {
              slidesToShow: 3.75,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 699,
          },
          {
            settings: {
              slidesToShow: 4.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 799,
          },
          {
            settings: {
              slidesToShow: 5.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 847,
          },
          {
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
              dots: React.Children.count(children) > 6,
              arrows: React.Children.count(children) > 6,
              infinite: React.Children.count(children) > 6,
            },
            // This might be too early to go to 5; means just 185px per card
            breakpoint: 1024,
          },
        ],
      }}
    >
      {children}
    </Carousel>
  );
};

export default ItemSaleCarousel;
