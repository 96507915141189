import { useEffect } from "react";

import { useMutation } from "@apollo/client";

import SUBMIT_DRAFT_SHIPPING_REQUEST from "../../../api/mutations/SUBMIT_DRAFT_SHIPPING_REQUEST";

import Loader from "../../Loader";
import { logAmplitudeEvent } from "../../../utils/analytics";
import { MANAGE_COLLECTION_EVENTS } from "../../../utils/analytics/events";

const StepSubmitting = ({ formData, setFormDataFields, onComplete }) => {
  const [submitDraftShippingRequestMutation] = useMutation(
    SUBMIT_DRAFT_SHIPPING_REQUEST,
    {
      onCompleted({ submitDraftShippingRequest }) {
        if (
          submitDraftShippingRequest.errors &&
          submitDraftShippingRequest.errors.length > 0
        ) {
          logAmplitudeEvent(
            MANAGE_COLLECTION_EVENTS.SHIP_HOME.SUBMIT_REQUEST.FAILED,
          );
        } else {
          logAmplitudeEvent(
            MANAGE_COLLECTION_EVENTS.SHIP_HOME.SUBMIT_REQUEST.SUCCEEDED,
          );
        }
        setFormDataFields({
          completedErrors: submitDraftShippingRequest.errors || [],
        });
        onComplete(submitDraftShippingRequest.errors);
      },
    },
  );

  // Disable dependencies warning as this is setup to be a one-time useEffect
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    submitDraftShippingRequestMutation({
      variables: {
        recipient: formData.recipient,
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2,
        city: formData.city,
        zipCode: formData.zipCode,
        state: formData.state,
        country: formData.country,
      },
    });
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return <Loader message={"Submitting your request..."} />;
};

export default StepSubmitting;
