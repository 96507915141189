import {
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { logAmplitudeEvent } from "../utils/analytics";
import { SUPPORT_EVENTS } from "../utils/analytics/events";

const Support = () => {
  useEffect(() => logAmplitudeEvent(SUPPORT_EVENTS.SUPPORT.PAGE_VIEWED), []);

  return (
    <>
      <Helmet>
        <title>Support</title>
      </Helmet>

      <div className="row bg-light py-md-5">
        <div
          className="col card shadow p-md-4 mx-auto"
          style={{ maxWidth: "48rem" }}
        >
          <div className="card-body">
            <h1 className="font-weight-bold border-bottom pb-3 mb-4">
              Support
            </h1>
            <h2 className="font-weight-bold mb-3">
              Frequently asked questions
            </h2>
            <p>
              Check out our frequently asked questions, which cover the most
              common topics we&#39;re asked about.
            </p>
            <Link
              to="/faq"
              className="btn btn-lg rounded-pill btn-primary mb-5"
            >
              View our FAQs
            </Link>
            <h2 className="font-weight-bold mb-3">Contact us</h2>
            <p>
              If you didn’t find the answer you needed, let us know how we can help! You can contact us via email or Instagram, and we‘ll get back to you as soon as we can.
            </p>
            <a
              href="mailto:support@starstock.com"
              className="btn btn-lg rounded-pill btn-primary mr-3 mb-3"
            >
              <span>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="lg"
                  className="align-center mr-3"
                />
                support@starstock.com
              </span>
            </a>

          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
