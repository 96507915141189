import { useQuery } from "@apollo/client";

import GET_BOX_BREAKS from "../../../../api/queries/breaks/GET_BOX_BREAKS";
import { BOX_BREAK_STATE_GROUPS } from "../../../../api/types";

import BreakCarouselWidget from "./BreakCarouselWidget";

const RecentBreaksCarouselWidget = ({ sport, era }) => {
  return (
    <BreakCarouselWidget
      id="recent-breaks-widget"
      widgetName="RecentBreaksList"
      title="👀 Recent events"
      subtitle="Check out the hits from our latest NFT reMints and box breaks"
      themeColor={"#2b66a7"}
      query={useQuery(GET_BOX_BREAKS, {
        variables: {
          sport: sport,
          era: era,
          stateGroup: [BOX_BREAK_STATE_GROUPS.CONCLUDED],
          orderBy: "scheduled_start_time_desc",
          first: 24,
        },
      })}
    />
  );
};

export default RecentBreaksCarouselWidget;
