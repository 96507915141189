import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";

import { BackgroundImageWrapper } from "../utils/wrappers";
import { logAmplitudeEvent } from "../utils/analytics";
import { ACCOUNT_EVENTS } from "../utils/analytics/events";

import PASSWORD_RESET_CHANGE from "../api/mutations/PASSWORD_RESET_CHANGE";

import PasswordResetForm from "../components/PasswordResetForm";

import logo from "../images/logo-light.svg";

const PasswordReset = () => {
  useEffect(
    () =>
      logAmplitudeEvent(ACCOUNT_EVENTS.PASSWORD_RESET.SET_PASSWORD.FORM_VIEWED),
    [],
  );

  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { uidb64, token } = useParams();

  const [passwordResetChange] = useMutation(PASSWORD_RESET_CHANGE, {
    onCompleted({ passwordResetChange }) {
      if (passwordResetChange.errors && passwordResetChange.errors.length > 0) {
        logAmplitudeEvent(ACCOUNT_EVENTS.PASSWORD_RESET.SET_PASSWORD.FAILED, {
          Error: passwordResetChange.errors[0],
        });
        setError(passwordResetChange.errors[0]);
        setSubmitting(false);
      } else {
        logAmplitudeEvent(ACCOUNT_EVENTS.PASSWORD_RESET.SET_PASSWORD.SUCCEEDED);
        navigate("/login");
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Password Reset</title>
      </Helmet>

      <div className="container-fluid">
        <BackgroundImageWrapper />

        <div className="row">
          <div className="col">
            <div className="text-center">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid my-5" />
              </Link>
            </div>

            <div className="card mx-auto max-width-26 mb-5">
              <div className="card-body text-center">
                <h1 className="card-title h5 my-3">Change password</h1>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error.message || error.field}
                  </div>
                )}

                <PasswordResetForm
                  passwordResetChange={passwordResetChange}
                  uidb64={uidb64}
                  token={token}
                  isSubmitting={isSubmitting}
                  setSubmitting={setSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
