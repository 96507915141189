import { useQuery } from "@apollo/client";

import GET_BOX_BREAKS from "../../../../api/queries/breaks/GET_BOX_BREAKS";
import { BOX_BREAK_STATE_GROUPS } from "../../../../api/types";
import { EventLiveVideo } from "../../../../components/EventLiveVideo";
import Widget from "../../../../components/Marketplace/Widgets/Widget";
import { BoxBreakNodeConnection } from "../../../../generated/schema";
import { BREAKS_EVENTS } from "../../../../utils/analytics/events";

import { BreakCard } from "../BreakCard";

interface Props {
  sport?: string;
  era?: string;
}

interface QueryResponse {
  boxBreaks: BoxBreakNodeConnection;
}

export const BreaksVideoWidget = ({ sport, era }: Props) => {
  const { data, loading, error } = useQuery<QueryResponse>(GET_BOX_BREAKS, {
    variables: {
      era,
      first: 1,
      sport,
      stateGroup: [BOX_BREAK_STATE_GROUPS.LIVE],
    },
  });

  const boxBreak = data?.boxBreaks.edges[0]?.node;

  return (
    <Widget
      analyticsEventName={BREAKS_EVENTS.STOREFRONT.WIDGET_LINK_CLICKED}
      background={"transparent"}
      id={"live-auction"}
      linkTitle={"View break details"}
      linkUrl={`/breaks/${boxBreak?.slug}`}
      subtitle={
        "Follow along with what the breaking team is ripping right now."
      }
      themeColor={"#e0632d"}
      title="🔥 Breaking now!"
      widgetName={"LiveBreak"}
    >
      <EventLiveVideo
        emptySectionTitle="Nothing breaking right now"
        error={!!error}
        loading={loading}
        twitchChannelName={boxBreak?.twitchChannelName}
        twitchVideoEmbedId="twitch-embed-breaks"
        youtubeVideoId={boxBreak?.youtubeVideoId}
      >
        {boxBreak && <BreakCard boxBreak={boxBreak} key={boxBreak.id} />}
      </EventLiveVideo>
    </Widget>
  );
};
