import { useState } from "react";

import { productName, productNameSuffix } from "../utils/content";

import CardParallelContainer from "../components/CardParallelContainer";
import CardParallelGradeTabContainer from "./CardParallelGradeTabContainer";
import MarketSummaryDataRow from "../components/MarketSummaryDataRow";

const MarketCardParallel = ({
  cardParallel,
  badgeCount,
  gradedCardParallels = [],
  hiddenGradesCount = 0,
  watchlistId,
  removeFromWatchlist,
}) => {
  if (gradedCardParallels.length === 0) {
    const { gradedcardparallelSet } = cardParallel;
    gradedCardParallels = gradedcardparallelSet.edges.map(({ node }) => node);
    hiddenGradesCount = Math.max(
      gradedcardparallelSet.totalCount - gradedCardParallels.length,
      0,
    );
  }

  // Let the user hover (on desktop) or tap (on mobile) to swap grade
  const [selectedGradedCardParallelId, setSelectedGradedCardParallelId] =
    useState((gradedCardParallels[0] && gradedCardParallels[0].id) || null);
  const selectedGradedCardParallel =
    gradedCardParallels.find(
      (gcp) => gcp.id === selectedGradedCardParallelId,
    ) || gradedCardParallels[0];

  const linkPath = selectedGradedCardParallel
    ? `/marketplace/cards/${cardParallel.slug}?grade=${selectedGradedCardParallel.grade.id}`
    : `/marketplace/cards/${cardParallel.slug}`;

  return (
    <CardParallelContainer
      id={cardParallel.id}
      slug={cardParallel.slug}
      linkPath={linkPath}
      productType={cardParallel.productType}
      photo={cardParallel.frontSidePhotoSmall}
      itemCount={badgeCount || cardParallel.sellOrderCount}
      cardProgram={cardParallel.playerCard.cardFamily.program.name}
      cardFamily={cardParallel.playerCard.cardFamily.name}
      cardName={productName(cardParallel)}
      cardSetNumber={productNameSuffix(cardParallel)}
      parallel={cardParallel.parallel}
      serialLimit={cardParallel.serialLimit}
      grade={selectedGradedCardParallel.grade}
      watchlistId={watchlistId}
      removeFromWatchlist={removeFromWatchlist}
      configCard
      configClickableCard
      configLargeMode
      configInsetChildren
    >
      <div className="d-flex flex-column justify-content-end h-100">
        {selectedGradedCardParallel ? (
          <CardParallelGradeTabContainer
            gradedCardParallels={gradedCardParallels}
            selectedGradedCardParallelId={selectedGradedCardParallelId}
            setSelectedGradedCardParallelId={setSelectedGradedCardParallelId}
            hiddenGradesCount={hiddenGradesCount}
            linkPath={linkPath}
          >
            <MarketSummaryDataRow
              listingsCount={selectedGradedCardParallel.openSellOrderCount}
              lowestListingPrice={
                selectedGradedCardParallel.openSellOrderMinPrice
              }
              offersCount={selectedGradedCardParallel.openBuyOrderCount}
              bestOfferPrice={selectedGradedCardParallel.openBuyOrderMaxPrice}
              latestSalePrice={selectedGradedCardParallel.lastSalePrice}
            />
          </CardParallelGradeTabContainer>
        ) : (
          <p className="text-whisper mb-0">No cards in our vault</p>
        )}
      </div>
    </CardParallelContainer>
  );
};

export default MarketCardParallel;
