import { gql } from "@apollo/client";

const GET_PORTFOLIO_VALUE = gql`
  query GET_PORTFOLIO_VALUE(
    $sport: String
    $era: String
    $timePeriodUnit: PortfolioValueTimePeriodUnits
    $timePeriodCount: Int
    $timePeriodOffset: Int
  ) {
    portfolioValue(
      sport: $sport
      era: $era
      timePeriodUnit: $timePeriodUnit
      timePeriodCount: $timePeriodCount
      timePeriodOffset: $timePeriodOffset
    ) {
      openDate
      closeDate
      closeItemCount
      closeSubmittedItemCount
      closePurchasedItemCount
      closeUnvaluedItemCount
      closeValue
      closeInvested
      closeRoiValue
      closeRoiPercent
    }
  }
`;

export default GET_PORTFOLIO_VALUE;
