import { useState, useEffect } from "react";

import { useQueryState } from "react-router-use-location-state";
import { useNavigate, Link } from "react-router-dom";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { Button } from "react-bootstrap";

import GET_SHIPPING_METADATA from "../api/queries/GET_SHIPPING_METADATA";
import REMOVE_ITEMS_FROM_DRAFT_SHIPPING_REQUEST from "../api/mutations/shipping/REMOVE_ITEMS_FROM_DRAFT_SHIPPING_REQUEST";
import ADD_ITEMS_TO_DRAFT_SHIPPING_REQUEST from "../api/mutations/shipping/ADD_ITEMS_TO_DRAFT_SHIPPING_REQUEST";
import ADD_ALL_ITEMS_TO_DRAFT_SHIPPING_REQUEST from "../api/mutations/shipping/ADD_ALL_ITEMS_TO_DRAFT_SHIPPING_REQUEST";
import REMOVE_ALL_ITEMS_FROM_DRAFT_SHIPPING_REQUEST from "../api/mutations/shipping/REMOVE_ALL_ITEMS_FROM_DRAFT_SHIPPING_REQUEST";
import GET_MY_COLLECTION from "../api/queries/GET_MY_COLLECTION";
import GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION from "../api/queries/GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION";

import BasketSection from "../components/ShipHome/BasketSection";
import CollectionNavBar from "../components/Collection/NavBar";
import CollectionSection from "../components/ShipHome/CollectionSection";
import ShipHomeNavBar, {
  SHIP_HOME_DRAFT_VIEW_TABS,
} from "../components/ShipHome/ShipHomeViewNavbar";
import SearchSortQueryControls from "../components/SearchSortQueryControls";

import { logAmplitudeEvent } from "../utils/analytics";
import { MANAGE_COLLECTION_EVENTS } from "../utils/analytics/events";
import { pathWithSearch } from "../utils/common";
import { pluralize } from "../utils/content";

import {
  addItemsToCaches,
  removeItemsFromCaches,
  returnRefetchOptions,
  updateCollectionCacheInDraftStatus,
  sortOptions,
} from "../utils/shipHome";
import ShipHomeSubmitForm from "../components/ShipHome/ShipHomeSubmitForm/ShipHomeSubmitForm";
import { Helmet } from "react-helmet";
import { Alert } from "../components/Alert";

const ShipHomeDraft = () => {
  const client = useApolloClient();
  client.onResetStore(() => setResettingStore(false));

  const [showForm, setShowForm] = useState(false);

  const [activeKey, setActiveKey] = useState(
    SHIP_HOME_DRAFT_VIEW_TABS.MY_COLLECTION,
  );

  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [sport, setSport] = useQueryState("sport", "");
  const [era, setEra] = useQueryState("era", "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [error, setError] = useState(null);
  const [resettingStore, setResettingStore] = useState(false);
  const [sortOrderKey, setSortOrderKey] = useQueryState(
    "sort",
    sortOptions[0].key,
  );
  const sortOrder =
    sortOptions.find((o) => o.key === sortOrderKey) || sortOptions[0];

  const navigate = useNavigate();

  const { data, loading: loadingMetaData } = useQuery(GET_SHIPPING_METADATA, {
    variables: {
      sport: sport,
      era: era,
      searchQuery: debouncedSearchQuery,
      orderBy: sortOrder.value,
    },
    fetchPolicy: "network-only",
  });

  const backgroundQueriesAndMetaData = [
    {
      query: GET_SHIPPING_METADATA,
      variables: {
        sport: sport,
        era: era,
        searchQuery: debouncedSearchQuery,
        orderBy: sortOrder.value,
      },
    },
    ...returnRefetchOptions(
      client.cache,
      GET_MY_COLLECTION,
      GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION,
      {
        sport: sport,
        era: era,
        searchQuery: debouncedSearchQuery,
        orderBy: sortOrder.value,
      },
    ),
  ];

  const [addItemsToDraftShippingRequest] = useMutation(
    ADD_ITEMS_TO_DRAFT_SHIPPING_REQUEST,
    {
      update: (cache, { data: response }) => {
        addItemsToCaches(cache, response, {
          sport: sport,
          era: era,
          searchQuery: debouncedSearchQuery,
          orderBy: sortOrder.value,
        });
      },
      refetchQueries: backgroundQueriesAndMetaData,
    },
  );

  const [removeItemsFromDraftShippingRequest] = useMutation(
    REMOVE_ITEMS_FROM_DRAFT_SHIPPING_REQUEST,
    {
      update: (cache, { data: response }) => {
        removeItemsFromCaches(cache, response, {
          sport: sport,
          era: era,
          searchQuery: debouncedSearchQuery,
          orderBy: sortOrder.value,
        });
      },
      refetchQueries: backgroundQueriesAndMetaData,
    },
  );

  const [addAllItemsToDraftShippingRequest, { loading: addAllItemsLoading }] =
    useMutation(ADD_ALL_ITEMS_TO_DRAFT_SHIPPING_REQUEST, {
      update: (cache) => {
        updateCollectionCacheInDraftStatus(
          cache,
          {
            sport: sport,
            era: era,
            searchQuery: debouncedSearchQuery,
            orderBy: sortOrder.value,
          },
          true,
        );
      },
      onCompleted: () => {
        client.resetStore();
      },
      onError: (err) => {
        setError(err);
      },
    });

  const [
    removeAllItemsFromDraftShippingRequest,
    { loading: removeAllItemsLoading },
  ] = useMutation(REMOVE_ALL_ITEMS_FROM_DRAFT_SHIPPING_REQUEST, {
    update: (cache) => {
      updateCollectionCacheInDraftStatus(
        cache,
        {
          sport: sport,
          era: era,
          searchQuery: debouncedSearchQuery,
          orderBy: sortOrder.value,
        },
        false,
      );
    },
    onCompleted: () => {
      client.resetStore();
    },
    onError: (err) => {
      setError(err);
    },
  });

  const handleItemSelection = (itemOwnership) => {
    if (itemOwnership.inDraftShippingRequest) {
      handleRemoveItem(itemOwnership);
    } else {
      handleAddItem(itemOwnership);
    }
  };

  const handleAddItem = (itemOwnership) => {
    logAmplitudeEvent(MANAGE_COLLECTION_EVENTS.SHIP_HOME.ITEM_ADDED);
    addItemsToDraftShippingRequest({
      variables: {
        itemsToAdd: [itemOwnership.item.id],
      },
      optimisticResponse: {
        addItemsToDraftShippingRequest: {
          __typename: "AddItemsToDraftShippingRequest",
          addedItems: [itemOwnership.item.id],
          errors: [],
        },
      },
    });
  };

  const handleRemoveItem = (itemOwnership) => {
    logAmplitudeEvent(MANAGE_COLLECTION_EVENTS.SHIP_HOME.ITEM_REMOVED);
    removeItemsFromDraftShippingRequest({
      variables: {
        itemsToRemove: [itemOwnership.item.id],
      },
      optimisticResponse: {
        removeItemsFromDraftShippingRequest: {
          removedItems: [itemOwnership.item.id],
          errors: [],
          __typename: "RemoveItemsFromDraftShippingRequest",
        },
      },
    });
  };

  const handleAddAllItems = () => {
    logAmplitudeEvent(MANAGE_COLLECTION_EVENTS.SHIP_HOME.ALL_ITEMS_ADDED);
    setResettingStore(true);
    addAllItemsToDraftShippingRequest({
      optimisticResponse: {
        addAllItemsToDraftShippingRequest: {
          errors: [],
          __typename: "AddAllItemsToDraftShippingRequest",
        },
      },
    });
  };

  const handleRemoveAllItems = () => {
    logAmplitudeEvent(MANAGE_COLLECTION_EVENTS.SHIP_HOME.ALL_ITEMS_REMOVED);
    setResettingStore(true);
    removeAllItemsFromDraftShippingRequest({
      optimisticResponse: {
        removeAllItemsFromDraftShippingRequest: {
          errors: [],
          __typeName: "RemoveAllItemsFromDraftShippingRequest",
        },
      },
    });
  };

  useEffect(() => {
    logAmplitudeEvent(MANAGE_COLLECTION_EVENTS.SHIP_HOME.PAGE_VIEWED);
  }, []);

  const {
    data: collectionData,
    loading: collectionLoading,
    error: collectionError,
    fetchMore: collectionFetchMore,
  } = useQuery(GET_MY_COLLECTION, {
    variables: {
      sport: sport,
      era: era,
      searchQuery: debouncedSearchQuery,
      orderBy: sortOrder.value,
    },
  });

  const {
    data: draftCollectionData,
    loading: draftCollectionLoading,
    error: draftCollectionError,
    fetchMore: draftCollectionFetchMore,
  } = useQuery(GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION, {
    variables: {
      sport: sport,
      era: era,
      searchQuery: debouncedSearchQuery,
      orderBy: sortOrder.value,
    },
  });

  return (
    <>
      <Helmet>
        <title>Ship Home</title>
      </Helmet>

      <div className="row bg-white shadow border-bottom">
        <div className="col inner-container-wide mx-auto">
          <div className="d-flex flex-column justify-content-center pt-4 pt-md-5">
            <div className="flex-col">
              <h2 className="font-weight-bold mr-2 mb-3">Ship Home</h2>
              <p>
                Select the cards or boxes you wish to be shipped home from your
                collection below and hit “Continue to Shipping” to proceed. For
                more info on shipping timescales and prices,{" "}
                <Link
                  to="/faq#shipping-cards-home"
                  className="text-primary text-nowrap "
                >
                  check out our FAQ
                </Link>
                .
              </p>
              <Alert className="mb-4">
                <p className="alert-heading font-weight-bold mb-2">
                  Shipping home your submitted cards only?
                </p>
                Instead of using the form below, please submit a support ticket
                to{" "}
                <a href="mailto:support@starstock.com">support@starstock.com</a>{" "}
                with “Originally Submitted Cards” in the subject line. This is
                only for use when shipping ALL of your unsold submitted cards
                home. Additional cards purchased on our marketplace must go
                through the normal ship-home process and cannot be combined with
                this request. See our{" "}
                <Link to="/faq#shipping-cards-home" className="text-nowrap">
                  FAQ
                </Link>{" "}
                for more details.
              </Alert>
            </div>

            <CollectionNavBar
              sport={sport}
              era={era}
              setValues={(sport, era) => {
                setEra(era);
                setSport(sport);
                navigate(pathWithSearch());
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col inner-container-wide mx-auto">
          <SearchSortQueryControls
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            sortOrderKey={sortOrderKey}
            setSortOrderKey={setSortOrderKey}
            sortOptions={sortOptions}
          />

          <ShipHomeNavBar
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            loading={loadingMetaData}
            myCollectionCount={data?.myCollection.totalCount}
            myBasketCount={data?.myInDraftShippingRequestCollection.totalCount}
          />

          {showForm && (
            <ShipHomeSubmitForm
              show={showForm}
              setShow={setShowForm}
              onComplete={() => window.location.reload()}
            />
          )}

          {activeKey === SHIP_HOME_DRAFT_VIEW_TABS.MY_COLLECTION && (
            <CollectionSection
              data={collectionData}
              loading={
                collectionLoading || addAllItemsLoading || removeAllItemsLoading
              }
              error={collectionError || error}
              fetchMore={collectionFetchMore}
              handleItemSelection={handleItemSelection}
              handleRemoveItem={handleRemoveItem}
            />
          )}
          {activeKey === SHIP_HOME_DRAFT_VIEW_TABS.BASKET && (
            <BasketSection
              data={draftCollectionData}
              loading={
                draftCollectionLoading ||
                addAllItemsLoading ||
                removeAllItemsLoading
              }
              error={draftCollectionError || error}
              fetchMore={draftCollectionFetchMore}
              handleItemSelection={handleItemSelection}
              handleRemoveItem={handleRemoveItem}
            />
          )}
        </div>
      </div>

      <div
        className="row mt-3 border-top shadow bg-white position-sticky"
        style={{ bottom: 0 }}
      >
        <div className="col inner-container-wide mx-auto py-2 py-sm-3">
          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <div className="btn-group mr-auto">
              <Button
                className="btn btn-secondary text-speak font-weight-bold"
                onClick={() => handleAddAllItems()}
                disabled={
                  resettingStore ||
                  collectionLoading ||
                  draftCollectionLoading ||
                  addAllItemsLoading ||
                  removeAllItemsLoading ||
                  loadingMetaData
                }
              >
                Add full collection
              </Button>
              <Button
                className="btn btn-secondary text-speak font-weight-bold ml-1"
                onClick={() => handleRemoveAllItems()}
                disabled={
                  resettingStore ||
                  collectionLoading ||
                  draftCollectionLoading ||
                  addAllItemsLoading ||
                  removeAllItemsLoading ||
                  loadingMetaData ||
                  !data?.entireBasket.totalCount
                }
              >
                Clear basket
              </Button>
            </div>

            <div className="d-flex flex-row align-items-baseline justify-content-between ml-0 ml-sm-3 pt-2 pt-sm-0">
              <p className="mb-0 mr-3">
                {data?.entireBasket.totalCount === undefined ? (
                  <>Loading basket…</>
                ) : (
                  <>
                    {pluralize("item", data?.entireBasket.totalCount)} in basket
                  </>
                )}
              </p>
              <Button
                className="btn btn-primary font-weight-bold"
                onClick={() => setShowForm(true)}
                disabled={
                  resettingStore ||
                  collectionLoading ||
                  draftCollectionLoading ||
                  addAllItemsLoading ||
                  removeAllItemsLoading ||
                  loadingMetaData ||
                  !data?.entireBasket.totalCount
                }
              >
                Continue to shipping
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipHomeDraft;
