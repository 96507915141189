import ItemOwnershipGridItem from "./ItemOwnershipGridItem";

const ItemOwnershipGridContainer = ({
  itemOwnershipCollection,
  actionView = null,
  emptyResultsMessage = "No cards found matching your search criteria",
  showMarketData = true,
}) => {
  return (
    <>
      {itemOwnershipCollection?.edges.length ? (
        <div className="row no-gutters row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 row-cols-xl-8 mx-n2 mb-3">
          {itemOwnershipCollection.edges.map(({ node }) => (
            <div key={node.id} className="col px-2 mb-3">
              <ItemOwnershipGridItem
                itemOwnership={node}
                showMarketData={showMarketData}
              >
                {Boolean(actionView) && actionView}
              </ItemOwnershipGridItem>
            </div>
          ))}
        </div>
      ) : (
        <div className="jumbotron text-center mx-auto">
          <p className="h5 mb-3">{emptyResultsMessage}</p>
        </div>
      )}
    </>
  );
};

export default ItemOwnershipGridContainer;
