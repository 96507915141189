import styled, { css } from "styled-components";

interface ContainerProps {
  ended?: boolean;
  winning?: boolean;
}

export const Amount = styled.span`
  flex-shrink: 0;
  font-weight: bold;
`;

export const Container = styled.div<ContainerProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1rem;

  & + & {
    margin-top: 0.5rem;
  }

  ${({ ended, winning }) =>
    !ended &&
    winning &&
    css`
      color: #a500d6;
    `}
`;

export const Message = styled.p`
  flex-grow: 1;
  line-height: 1.25;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  span {
    font-weight: bold;
  }
`;

export const Thumbnail = styled.img`
  border-radius: 50%;
  height: 2.75rem;
  margin-right: 0.75rem;
  width: 2.75rem;
`;
