import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import styled from "styled-components";

import { logAmplitudeEvent } from "../../utils/analytics";
import { BREAKS_EVENTS } from "../../utils/analytics/events";
import { pathWithSearch } from "../../utils/common";
import BreakSearchInterface from "../../components/Breaks/BreakSearchInterface";
import NavBar from "../../components/Breaks/NavBar";
import Background from "../../images/backgrounds/vector/live-breaks-pattern-light.svg";

// Slightly darkened
const HeaderBaseColor = "#EE9165";
const HeaderGradientStart = "#d5784a";
const HeaderGradientEnd = "#9544cd";

const HeaderContainer = styled.div`
  background: url(${Background}) center bottom repeat-x,
    linear-gradient(135deg, ${HeaderGradientStart}, ${HeaderGradientEnd}),
    ${HeaderBaseColor};
`;

const UpcomingBreaks = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [sport, setSport] = useQueryState("sport", "");
  const [era, setEra] = useQueryState("era", "");

  useEffect(
    () =>
      logAmplitudeEvent(BREAKS_EVENTS.ALL_BREAKS.PAGE_VIEWED, {
        Sport: sport,
        Era: era,
      }),
    [sport, era],
  );

  return (
    <>
      <Helmet>
        <title>Upcoming Breaks</title>
      </Helmet>

      <HeaderContainer className="row text-left pt-4">
        <div className="col inner-container-wide mx-auto d-flex flex-column">
          <p className="small text-white-50 font-weight-bold text-uppercase">
            <Link to="/breaks" className="text-white">
              Breaks
            </Link>{" "}
            / upcoming
          </p>

          <h2 className="font-weight-bold text-white mb-2">Upcoming breaks</h2>

          <p className="text-white mb-3">
            <span className="mr-2" style={{ opacity: 0.8 }}>
              Explore our upcoming schedule and find your perfect break.
            </span>
            <Link
              to="/breaks/faq"
              className="text-white text-nowrap d-block d-sm-inline-block mt-2 mt-sm-0"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
              Learn more
            </Link>
          </p>

          <NavBar
            sport={sport}
            era={era}
            setValues={(sport, era) => {
              setEra(era);
              setSport(sport);
              navigate(pathWithSearch());
            }}
          />
        </div>
      </HeaderContainer>

      <div className="row">
        <div className="col inner-container-wide mx-auto">
          <BreakSearchInterface
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            sport={sport}
            era={era}
            showSearchInput={true}
            showSearchInputPlaceholder={false}
          />
        </div>
      </div>
    </>
  );
};

export default UpcomingBreaks;
