import { useQuery } from "@apollo/client";

import GET_CARD_PARALLEL_SIBLINGS from "../../api/queries/GET_CARD_PARALLEL_SIBLINGS";

import ErrorNotice from "../ErrorNotice";
import Loader from "../Loader";
import MarketCardParallel from "../MarketCardParallel";

const OtherCardParallels = ({ currentParallelId, playerCard }) => {
  const { data, loading, error } = useQuery(GET_CARD_PARALLEL_SIBLINGS, {
    variables: { playerCard: playerCard.id },
  });

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;

  const otherParallels = data.cardParallels.edges.filter(
    ({ node }) => node.id !== currentParallelId,
  );

  return (
    <>
      <h3 className="font-weight-bold mb-4">Other parallels</h3>
      {otherParallels.length > 0 ? (
        <div className="row no-gutters row-cols-1 row-cols-lg-2 mx-n2">
          {otherParallels.map(({ node }) => (
            <div key={node.id} className="col px-2 mb-2 mb-sm-3">
              <MarketCardParallel cardParallel={node} />
            </div>
          ))}
        </div>
      ) : (
        <p className="text-deselected text-center py-5 rounded border">
          No other parallels currently available for this product
        </p>
      )}
    </>
  );
};

export default OtherCardParallels;
