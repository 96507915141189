import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Job from "../components/Contentful/Job";
import ContentWrapper from "../components/ContentWrapper";
import JobListing from "../components/JobList/JobListing";

const JobDetail = () => {
  const { slug } = useParams();

  return (
    <Job slug={slug}>
      {(job) => (
        <ContentWrapper>
          <Helmet>
            <title>{job.title}</title>
          </Helmet>

          <Link to="/careers">&larr; All jobs</Link>
          <JobListing job={job} />

          <div className="d-flex justify-content-center mt-5">
            <a
              href="mailto:careers@starstock.com"
              className="btn btn-lg btn-block btn-success font-weight-bold"
              style={{ maxWidth: "20rem" }}
            >
              Apply by email
              <p
                className="font-weight-normal mt-0 mb-0"
                style={{ fontSize: "1rem" }}
              >
                careers@starstock.com
              </p>
            </a>
          </div>
        </ContentWrapper>
      )}
    </Job>
  );
};

export default JobDetail;
