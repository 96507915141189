import { useState } from "react";

import ErrorNotice from "../ErrorNotice";
import Loader from "../Loader";
import LoadMoreButton from "../LoadMoreButton";

import ItemOwnershipGridContainer from "../ItemOwnership/ItemOwnershipGridContainer";
import ItemOwnershipItemCheckbox from "../ItemOwnership/ItemOwnershipItemCheckbox";

const CollectionSection = ({
  data,
  loading,
  error,
  fetchMore,
  handleItemSelection,
}) => {
  const [loadingMore, setLoadingMore] = useState(false);

  return (
    <div className="inner-container-wide mx-auto">
      <div className="row">
        <div className="col mt-4 mt-md-4 mb-3">
          {error && <ErrorNotice />}
          {loading && <Loader />}
          {!error && !loading && (
            <>
              <ItemOwnershipGridContainer
                itemOwnershipCollection={data?.myCollection}
                showMarketData={false}
                actionView={
                  <ItemOwnershipItemCheckbox
                    checkboxAction={handleItemSelection}
                    label={"Add to basket"}
                  />
                }
              />

              {data?.myCollection?.pageInfo?.hasNextPage && (
                <LoadMoreButton
                  loadingMore={loadingMore}
                  setLoadingMore={setLoadingMore}
                  onLoadMore={() =>
                    fetchMore({
                      variables: {
                        cursor: data?.myCollection?.pageInfo?.endCursor,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        setLoadingMore(false);
                        if (!fetchMoreResult) return prev;

                        fetchMoreResult.myCollection.edges = [
                          ...prev.myCollection.edges,
                          ...fetchMoreResult.myCollection.edges,
                        ];

                        return fetchMoreResult;
                      },
                    })
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectionSection;
