import moment from "moment";

import {
  AuctionState,
  BoxBreakState,
  EventEventType,
} from "../../generated/schema";

import { PageSection, PageSectionTypes } from "../PageSection";
import { TwitchEmbed } from "../TwitchEmbed";
import { YouTubeEmbed } from "../YouTubeEmbed";

import {
  LiveContainer,
  LiveCopy,
  LiveHeading,
  NoReplay,
  WaitingContainer,
  WaitingCopy,
  WaitingHeading,
} from "./styles";

type EventState = AuctionState | BoxBreakState | undefined;

interface EmbedSettings {
  eventState: EventState;
  youtubeVideoId: string | undefined;
  twitchChannelName: string | undefined;
  twitchVideoId?: string | undefined;
}

interface Props {
  autoplay?: boolean;
  backgroundImg?: string;
  color?: {
    base: string;
    gradientStart: string;
    gradientEnd: string;
  };
  event?: EventEventType | null;
  eventState?: EventState;
  scheduledStartTime: Date | null;
  showChat?: boolean;
  twitchVideoId?: string;
  twitchChannelName?: string;
  youtubeVideoId?: string;
}

export const LiveStreamSection = ({
  autoplay = false,
  backgroundImg,
  color = {
    base: "#EE9165",
    gradientStart: "#d5784a",
    gradientEnd: "#9544cd",
  },
  event,
  eventState,
  scheduledStartTime,
  showChat = false,
  twitchVideoId,
  twitchChannelName,
  youtubeVideoId,
}: Props) => {
  const backgroundCss: any = `
    url(${backgroundImg}) center bottom repeat,
      linear-gradient(
        135deg,
        ${color.gradientStart},
        ${color.gradientEnd}
      ),
      ${color.base}
  `;

  const returnSingleEmbeddedPlayer = ({
    eventState,
    twitchChannelName,
    twitchVideoId,
    youtubeVideoId,
  }: EmbedSettings) => {
    switch (eventState) {
      case "PUBLISHED":
      case "PUBLISHED_OPEN":
      case "PUBLISHED_LOCKED":
        return (
          youtubeVideoId && (
            <YouTubeEmbed
              autoplay={autoplay}
              showChat={showChat}
              videoId={youtubeVideoId}
            />
          )
        );

      case "LIVE":
      case "LIVE_OPEN":
      case "LIVE_LOCKED":
        if (twitchChannelName) {
          return (
            <TwitchEmbed
              autoplay={autoplay}
              showChat={showChat}
              channel={twitchChannelName}
            />
          );
        }

        if (youtubeVideoId) {
          return (
            <YouTubeEmbed
              autoplay={autoplay}
              showChat={showChat}
              videoId={youtubeVideoId}
            />
          );
        }

        break;

      case "ENDED":
      case "SETTLED":
        if (twitchVideoId) {
          return (
            <TwitchEmbed
              autoplay={autoplay}
              showChat={showChat}
              vodId={twitchVideoId}
            />
          );
        }

        if (youtubeVideoId) {
          return (
            <YouTubeEmbed
              autoplay={autoplay}
              showChat={showChat}
              videoId={youtubeVideoId}
            />
          );
        }

        return (
          <NoReplay title="Replay not available">
            Replay video not available.
          </NoReplay>
        );
    }
  };

  const hasVideo = ({
    eventState,
    twitchChannelName,
    youtubeVideoId,
  }: EmbedSettings) => {
    switch (eventState) {
      case "PUBLISHED":
      case "PUBLISHED_OPEN":
      case "PUBLISHED_LOCKED":
        return !!youtubeVideoId;
      case "LIVE":
      case "LIVE_OPEN":
      case "LIVE_LOCKED":
        return !!(youtubeVideoId || twitchChannelName);
      case "ENDED":
      case "SETTLED":
        return true;
      default:
        return false;
    }
  };

  const renderHeading = () => {
    if (scheduledStartTime) {
      return (
        <>
          Live stream begins at
          <span>{moment(scheduledStartTime).format("MMM Do - h:mm A")}</span>
        </>
      );
    }

    return event === EventEventType.BREAK ? (
      <>
        Live stream
        <span>Scheduled when filled</span>
      </>
    ) : (
      <>
        Live stream
        <span>Coming Soon</span>
      </>
    );
  };

  return (
    <PageSection type={PageSectionTypes.background} background={backgroundCss}>
      {hasVideo({ eventState, twitchChannelName, youtubeVideoId }) ? (
        <>
          <LiveContainer>
            <LiveHeading>▶️ Live stream</LiveHeading>
            <LiveCopy>
              Follow all the action or join the conversation in the live stream.
            </LiveCopy>
          </LiveContainer>

          {returnSingleEmbeddedPlayer({
            eventState,
            twitchChannelName,
            twitchVideoId,
            youtubeVideoId,
          })}
        </>
      ) : (
        <WaitingContainer>
          <WaitingHeading>{renderHeading()}</WaitingHeading>

          <WaitingCopy>
            The video will appear here shortly before the event begins.
          </WaitingCopy>
        </WaitingContainer>
      )}
    </PageSection>
  );
};
