import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Children, ReactNode } from "react";

import { Event } from "../../types";
import { EventPill } from "../EventPill";

import {
  Container,
  Contents,
  DateHeading,
  Heading,
  StyledInfoNotice,
  Summary,
  Today,
} from "./styles";

interface Props {
  children?: ReactNode;
  date: Date;
  events?: {
    count: number;
    type: Event;
  }[];
}

export const EventLineup = ({ children, date, events = [] }: Props) => {
  const momentDate = moment(date);
  const hasChildren = Children.toArray(children).length;
  const hasEvents = !!events.length;
  const heading = momentDate.format("dddd, MMMM D YYYY");
  const isToday = momentDate.isSame(new Date(), "day");
  const noEventDate = momentDate.format("MMMM D YYYY");

  return (
    <Container>
      <div>
        <Heading>
          {isToday && <Today>Today</Today>}
          <DateHeading>{heading}</DateHeading>
        </Heading>

        {hasEvents && (
          <Summary>
            {events.map(({ count, type }, idx) => (
              <EventPill key={idx} count={count} event={type} />
            ))}
          </Summary>
        )}
      </div>

      <Contents>
        {hasChildren ? (
          <>{children}</>
        ) : (
          <StyledInfoNotice
            icon={faCalendarDay}
            message={[
              `There are currently no events scheduled for ${noEventDate} that match your filters.`,
            ]}
            state="neutral"
            title="No events scheduled"
          />
        )}
      </Contents>
    </Container>
  );
};
