import _ from "lodash";

export const groupRecentPurchasesByGradeName = (recentPurchases) => {
  return _.groupBy(recentPurchases, function (recentPurchase) {
    if (recentPurchase.grade) {
      return [recentPurchase.grade.grader, recentPurchase.grade.name].join(" ");
    } else {
      return "Ungraded";
    }
  });
};

export const groupRecentPurchasesByDate = (recentPurchases) => {
  return _.groupBy(recentPurchases, function (recentPurchase) {
    if (recentPurchase.date) {
      return recentPurchase.date;
    } else {
      return "Unknown date";
    }
  });
};

export const averagePriceFromRecentPurchases = (recentPurchases) => {
  return _.mean(recentPurchases.map((sale) => sale.price));
};
