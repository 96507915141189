import OptionPillsFilter from "./OptionPillsFilter";

const SerialLimitFilter = ({ serialLimitRange, setSerialLimitRange }) => {
  const notSet = "";

  // 2020 Bowman's current highest
  const maxSerialNumber = "2020";

  const options = [
    { label: "All cards", value: [notSet, notSet] },
    { label: "Not numbered", value: [notSet, "0"] },
    { label: "All numbered", value: ["1", maxSerialNumber] },
    { label: "Up to 250", value: ["1", "250"] },
    { label: "Up to 100", value: ["1", "100"] },
    { label: "Up to 10", value: ["1", "10"] },
    { label: "1 of 1", value: ["1", "1"] },
  ];

  const selectedOption =
    options.find(
      (option) =>
        (option.value[0] === serialLimitRange[0]) &
        (option.value[1] === serialLimitRange[1]),
    ) || options[0];

  return (
    <div className="mb-4">
      <h6>Serial numbered</h6>
      <hr className="mt-1" />

      <OptionPillsFilter
        options={options}
        selectedOption={selectedOption}
        setSelectedValue={setSerialLimitRange}
      />
    </div>
  );
};

export default SerialLimitFilter;
