import { LotState } from "../../../generated/schema";

export const getStateTitle = (state: LotState) => {
  switch (state) {
    case LotState.BIDDING:
      return "🔥 Bidding now!";
    case LotState.CANCELED:
      return "❌ Lot canceled";
    case LotState.SOLD:
    case LotState.UNSOLD:
      return "🏁 Just ended!";
    default:
      return "🚨 Starting soon!";
  }
};
