import React from "react";

import { Carousel } from "../Carousel";

const BreakCarousel = ({ themeColor, children }) => {
  return (
    <Carousel
      themeColor={themeColor}
      customSettings={{
        dots: React.Children.count(children) > 4,
        arrows: React.Children.count(children) > 4,
        infinite: React.Children.count(children) > 4,
        speed: 400,
        initialSlide: 0,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            settings: {
              slidesToShow: 1.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 399,
          },
          {
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 499,
          },
          {
            settings: {
              slidesToShow: 1.75,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 649,
          },
          {
            settings: {
              slidesToShow: 2.25,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              infinite: false,
              swipeToSlide: true,
              touchThreshold: 100,
            },
            breakpoint: 799,
          },
          {
            settings: {
              infinite: React.Children.count(children) > 3,
              arrows: React.Children.count(children) > 3,
              slidesToShow: 3,
              slidesToScroll: 3,
            },
            breakpoint: 1199,
          },
          {
            settings: {
              infinite: React.Children.count(children) > 4,
              arrows: React.Children.count(children) > 4,
              slidesToShow: 4,
              slidesToScroll: 4,
            },
            // This might be too early to go to 5; means just 185px per card
            breakpoint: 1200,
          },
        ],
      }}
    >
      {children}
    </Carousel>
  );
};

export default BreakCarousel;
