const CardParallelGradeContainer = ({
  gradeTitle,
  subtitle,
  sidebandColor,
  accessoryView,
  children,
}) => {
  return (
    <div
      className="rounded bg-light p-3"
      style={{
        borderLeft: `0.25em solid ${sidebandColor}`,
      }}
    >
      <div className="d-flex flex-row justify-content-between">
        <div className="flex-shrink-1">
          <h5 className="font-weight-bold mb-0">{gradeTitle}</h5>
          <p className="mb-0">{subtitle}</p>
        </div>

        {Boolean(accessoryView) && (
          <div className="d-flex flex-grow-1 justify-content-end ml-2">
            {accessoryView}
          </div>
        )}
      </div>
      {children && <div className="mt-3">{children}</div>}
    </div>
  );
};

export default CardParallelGradeContainer;
