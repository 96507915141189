import { useQuery } from "@apollo/client";
import { useQueryState } from "react-router-use-location-state";
import { useDebounce } from "use-debounce";

import GET_BOX_BREAKS from "../../api/queries/breaks/GET_BOX_BREAKS";
import { BOX_BREAK_STATE_GROUPS } from "../../api/types";

import {
  PageSection,
  PageSectionTypes,
  PageSectionWidths,
} from "../PageSection";
import BreakListContainer from "./BreakListContainer";
import SortControl, {
  SortControlPositions,
  SortControlVariants,
} from "../Marketplace/SortControl";
import SearchInput from "../Activity/SearchInput";
import styled from "styled-components";

const sortOptions = [
  {
    key: "starting-soon",
    label: "Starting soon",
    value: "scheduled_start_time_asc,id",
    description: "Find the breaks scheduled to start soon",
  },
  {
    key: "new-additions",
    label: "New additions",
    value: "published_at_desc,id",
    description: "The newest breaks added to the site",
  },
  {
    key: "lowest-price",
    label: "Lowest price",
    value: "spots_min_price_asc,id",
    description: "Find a break spot for the lowest price",
  },
  {
    key: "highest-price",
    label: "Highest price",
    value: "spots_max_price_desc,id",
    description: "The most expensive break spots we have open",
  },
  {
    key: "most-filled",
    label: "Most filled",
    value: "spots_available_count_asc,id",
    description: "Breaks with the most spots claimed",
  },
  {
    key: "spots-available",
    label: "Spots available",
    value: "spots_available_count_desc,id",
    description: "Find breaks with plenty of spots remaining",
  },
];

const StyledSearchInputPlaceholder = styled.div`
  // display: none;

  @media (min-width: 48rem) {
    position: relative;
    line-height: 1.75rem;
    display: block;
  }
`;

const SearchInputPlaceholder = ({ sport, era, searchQuery }) => {
  return (
    <StyledSearchInputPlaceholder>
      Showing{" "}
      <span className="text-speak">
        {era} {sport} breaks{" "}
      </span>
      {searchQuery && (
        <>
          matching{" "}
          <span className="text-shout font-weight-bold">{searchQuery}</span>
        </>
      )}
    </StyledSearchInputPlaceholder>
  );
};

const BreakSearchInterface = ({
  searchQuery,
  setSearchQuery,
  sport,
  era,
  showSearchInput = false,
  showSearchInputPlaceholder = true,
}) => {
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const [sortKey, setSortKey] = useQueryState("sort", sortOptions[0].key);
  const sort = sortOptions.find((o) => o.key === sortKey) || sortOptions[0];

  const { data, loading, error, fetchMore } = useQuery(GET_BOX_BREAKS, {
    variables: {
      query: debouncedSearchQuery,
      sport: sport,
      era: era,
      stateGroup: [
        BOX_BREAK_STATE_GROUPS.LIVE,
        BOX_BREAK_STATE_GROUPS.UPCOMING,
      ],
      orderBy: sort.value,
    },
  });

  return (
    <PageSection
      type={PageSectionTypes.background}
      width={PageSectionWidths.wide}
      borderBottom={false}
      removePaddingTop
    >
      <div className="d-flex flex-row justify-content-between align-items-baseline my-3 mb-4">
        {showSearchInput ? (
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={"Search by card brand or format"}
          />
        ) : showSearchInputPlaceholder ? (
          <SearchInputPlaceholder
            sport={sport}
            era={era}
            searchQuery={debouncedSearchQuery}
          />
        ) : (
          <></>
        )}

        <SortControl
          className="mx-n1"
          sortOptions={sortOptions}
          selectedSortOptionKey={sortKey}
          setSelectedSortOptionKey={(sortKey) => {
            setSortKey(sortKey, { method: "push" });
          }}
          configVariant={SortControlVariants.auto}
          configPosition={SortControlPositions.right}
        />
      </div>

      <BreakListContainer
        data={data}
        loading={loading}
        error={error}
        fetchMore={fetchMore}
      />
    </PageSection>
  );
};

export default BreakSearchInterface;
