import { LotNodeEdge, LotState } from "../../../generated/schema";

const filterLots = (lots: (LotNodeEdge | null)[], filter: LotState[]) =>
  lots.filter((lot) => {
    if (!lot?.node) return false;
    return filter.includes(lot.node.state);
  });

export const getCurrentActiveLot = (lots: (LotNodeEdge | null)[]) => {
  const biddingLot = filterLots(lots, [LotState.BIDDING]);
  if (biddingLot.length > 0) return biddingLot[0]?.node;

  const startingLot = filterLots(lots, [LotState.STARTING]);
  if (startingLot.length > 0) return startingLot[0]?.node;

  const endedLots = filterLots(lots, [
    LotState.CANCELED,
    LotState.SOLD,
    LotState.UNSOLD,
  ]).sort((a, b) => {
    const timeA = new Date(a?.node?.biddingOpenedAt).getTime() || -1;
    const timeB = new Date(b?.node?.biddingOpenedAt).getTime() || -1;

    return timeB - timeA;
  });
  if (endedLots.length > 0) return endedLots[0]?.node;

  return null;
};
