import { gql } from "@apollo/client";

const REMOVE_ITEMS_FROM_DRAFT_SHIPPING_REQUEST = gql`
  mutation removeItemsFromDraftShippingRequest($itemsToRemove: [String]!) {
    removeItemsFromDraftShippingRequest(itemsToRemove: $itemsToRemove) {
      removedItems
      itemCount
      errors {
        field
        message
      }
    }
  }
`;

export default REMOVE_ITEMS_FROM_DRAFT_SHIPPING_REQUEST;
