import { gql } from "@apollo/client";

const DELETE_ADDRESS = gql`
  mutation DELETE_ADDRESS($id: ID!) {
    deleteAddress(id: $id) {
      errors {
        field
        message
      }
    }
  }
`;
export default DELETE_ADDRESS;
