import styled from "styled-components";
import { rgba } from "polished";
import { pluralize } from "../../utils/content";

import playerPlaceholder from "../../images/player-outline.png";

const Astronaut = "#2c4d76";

const WideBreakpointWidth = "40rem";

const StyledContainer = styled.div`
  display: flex;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const StyledComponentCircle = styled.a`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: lightgrey;
  background: linear-gradient(
    to bottom right,
    #${(props) => props.colorPrimary} 50%,
    #${(props) => props.colorSecondary} 50% 100%
  );
  overflow: hidden;

  @media (min-width: ${WideBreakpointWidth}) {
    margin-right: 1rem;
    width: 3rem;
    height: 3rem;
  }

  box-shadow: inset 0px 0px 0px 1px #00000020;
`;

const StyledPlayerInfoContainer = styled.div`
  overflow: hidden;
  align-items: center;
  margin: auto 0;
`;

const StyledPlayerNameLabel = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: ${WideBreakpointWidth}) {
    font-size: 1rem;
  }
`;

const StyledPlayerInfoLabel = styled.p`
  font-size: 0.75rem;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MarketIndexInfo = ({ marketIndex }) => {
  return (
    <StyledContainer>
      <StyledComponentCircle
        colorPrimary={"#00000010"}
        colorSecondary={"#00000010"}
      >
        <div className="h-100 d-flex flex-column justify-content-center">
          <p
            className="mb-0 text-center text-uppercase"
            style={{ fontSize: "0.625rem" }}
          >
            {marketIndex.symbol}
          </p>
        </div>
      </StyledComponentCircle>
      <StyledPlayerInfoContainer>
        <StyledPlayerNameLabel>
          <span className="text-speak font-weight-bold">
            {marketIndex.name}
          </span>{" "}
        </StyledPlayerNameLabel>
        <StyledPlayerInfoLabel>
          <span className="text-whisper">
            {pluralize("player", marketIndex.components.totalCount)}
          </span>
        </StyledPlayerInfoLabel>
      </StyledPlayerInfoContainer>
    </StyledContainer>
  );
};

const ComponentInfo = ({ component }) => {
  const { player } = component;
  const { currentTeam } = player;
  const { gradedCardParallel } = component;

  const getCardLink = (cardSlug, gradeId) => {
    return `/marketplace/cards/${cardSlug}?grade=${gradeId}`;
  };

  return gradedCardParallel ? (
    <StyledContainer>
      <StyledComponentCircle
        href={getCardLink(
          gradedCardParallel.cardParallel.slug,
          gradedCardParallel.grade.id,
        )}
        colorPrimary={currentTeam ? currentTeam.colorPrimary : "lightgrey"}
        colorSecondary={currentTeam ? currentTeam.colorSecondary : "lightgrey"}
        colorTertiary={currentTeam ? currentTeam.colorTertiary : "lightgrey"}
      >
        <img
          className="w-100 h-100"
          src={player.headshotPhoto || playerPlaceholder}
          alt=""
        />
      </StyledComponentCircle>
      <StyledPlayerInfoContainer>
        <StyledPlayerNameLabel>
          <a
            href={getCardLink(
              gradedCardParallel.cardParallel.slug,
              gradedCardParallel.grade.id,
            )}
            className="text-primary font-weight-bold"
          >
            {player.name}
          </a>
        </StyledPlayerNameLabel>
        <StyledPlayerInfoLabel>
          <span className="text-whisper font-weight-bold">
            {currentTeam ? currentTeam.abbrName : "No team"}
          </span>
          <span className="px-1" style={{ color: rgba(Astronaut, 0.25) }}>
            /
          </span>
          <span className="text-whisper">{player.position}</span>
          <span className="px-1" style={{ color: rgba(Astronaut, 0.25) }}>
            /
          </span>
          <span className="text-whisper">{player.startYear}</span>
        </StyledPlayerInfoLabel>
      </StyledPlayerInfoContainer>
    </StyledContainer>
  ) : (
    <div>There was a problem loading this player</div>
  );
};

export default ComponentInfo;
