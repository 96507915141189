import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 2rem;
  opacity: 0.8;
`;

export const Gavel = styled(FontAwesomeIcon)`
  color: white;
  font-size: 1.1rem;
`;

export const GavelContainer = styled.div`
  align-items: center;
  background-image: linear-gradient(167.2deg, #e4ab30 0%, #d94827 100%);
  border-radius: 100%;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
`;

export const Message = styled.p`
  color: ${Colors.Astronaut};
  flex-grow: 1;
  font-size: 1rem;
  margin: 0;
  margin-left: 0.5rem;
`;

export const NoBidsMessage = styled(Message)`
  margin: 0;
  text-align: center;
`;

export const Price = styled.span`
  flex-shrink: 0;
  font-weight: bold;
`;
