import { Navigate } from "react-router-dom";

const ErrorNotice = ({ error }) => {
  if (error && error.graphQLErrors) {
    const authIssue = error.graphQLErrors.some(
      ({ message }) =>
        message === "You do not have permission to perform this action",
    );
    if (authIssue) return <Navigate push to="/login" />;
  }

  return <p className="mt-3 mx-auto font-weight-bold">There was an issue</p>;
};

export default ErrorNotice;
