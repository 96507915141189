import { useEffect } from "react";

import { useMutation } from "@apollo/client";

import BUY_BOX_BREAK_SPOTS from "../../../api/mutations/breaks/BUY_BOX_BREAK_SPOTS";
import GET_BOX_BREAK from "../../../api/queries/breaks/GET_BOX_BREAK";

import Loader from "../../Loader";
import { logAmplitudeEvent } from "../../../utils/analytics";
import { BREAKS_EVENTS } from "../../../utils/analytics/events";

const logFailureAmplitude = (formData) => {
  logAmplitudeEvent(BREAKS_EVENTS.BUY_SPOTS.FAILED, {
    "Break Slug": formData.boxBreak.slug,
    "Break Format": formData.boxBreak.format.name,
    "Selected Spots": formData.selectedSpots.map((spot) => spot.name),
  });
};

const logSuccessAmplitude = (formData, buyBoxBreakSpots) => {
  logAmplitudeEvent(BREAKS_EVENTS.BUY_SPOTS.SUCCEEDED, {
    "Break Slug": formData.boxBreak.slug,
    "Break Format": formData.boxBreak.format.name,
    "Selected Spots": formData.selectedSpots.map((spot) => spot.name),
    "Purchased Spots Count": buyBoxBreakSpots.purchasedSpotsCount || 0,
    "Purchased Spots Price": buyBoxBreakSpots.purchasedSpotsCost || 0,
  });
};

const StepSubmitting = ({ formData, setFormDataFields, onComplete }) => {
  const [buyBoxBreakSpotsMutation] = useMutation(BUY_BOX_BREAK_SPOTS, {
    refetchQueries: [
      { query: GET_BOX_BREAK, variables: { id: formData.boxBreakId } },
    ],
    onError(error) {
      logFailureAmplitude(formData);
      setFormDataFields({
        purchasedSpotsCost: 0,
        purchasedSpotsCount: 0,
        completedErrors: [error],
      });
      onComplete([error]);
    },
    onCompleted({ buyBoxBreakSpots }) {
      if (buyBoxBreakSpots.errors && buyBoxBreakSpots.errors.length > 0) {
        logFailureAmplitude(formData);
      } else {
        logSuccessAmplitude(formData, buyBoxBreakSpots);
      }
      setFormDataFields({
        purchasedSpotsCost: buyBoxBreakSpots.purchasedSpotsCost || 0,
        purchasedSpotsCount: buyBoxBreakSpots.purchasedSpotsCount || 0,
        completedErrors: buyBoxBreakSpots.errors || [],
      });
      onComplete(buyBoxBreakSpots.errors);
    },
  });

  // Disable dependencies warning as this is setup to be a one-time useEffect
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    buyBoxBreakSpotsMutation({
      variables: {
        boxBreakId: formData.boxBreakId,
        spotIds: formData.selectedSpots.map((spot) => spot.id),
        preferShipHome: formData.preferShipHome,
        addressId: formData.addressId,
        additionalInstructions: formData.additionalInstructions,
        paymentMethod: formData.paymentMethod,
      },
    });
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return <Loader message={"Grabbing your spot…"} />;
};

export default StepSubmitting;
