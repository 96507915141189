import { pluralize, productQuantityString } from "../../utils/content";
import { financial } from "../../utils/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { prettyExpiresAfter } from "../../utils/forms";

const StepReview = ({ formData, onSubmit, onPrev }) => {
  return (
    <div className="p-3">
      <div className="alert alert-primary text-center p-4" role="alert">
        <h3 className="alert-heading font-weight-bold">Sell order summary</h3>
        <p className="mb-0">
          You are selling{" "}
          <span className="font-weight-bold">
            {productQuantityString(
              formData.quantityToSell,
              formData.cardParallelProductType,
            )}
          </span>{" "}
          for a minimum sale price of{" "}
          <span className="font-weight-bold">
            ${financial(formData.minimumSalePrice)}.
          </span>
          {formData.expiresAfter !== "NO_EXPIRY" ? (
            <p>
              Listings will expire in{" "}
              <span className="font-weight-bold">
                {prettyExpiresAfter(formData.expiresAfter)}.
              </span>
            </p>
          ) : null}
        </p>
      </div>

      <div className="mb-3">
        <div className="row mb-2">
          <div className="col-5 font-weight-bold text-left">Instant sales</div>
          <div className="col-auto font-weight-bold text-left">
            {formData.expectedSalesCount
              ? productQuantityString(
                  formData.expectedSalesCount,
                  formData.cardParallelProductType,
                )
              : "–"}
          </div>
          <div className="col-auto font-weight-bold text-right flex-fill">
            {formData.expectedSalesValue
              ? `$${financial(formData.expectedSalesValue)}`
              : "–"}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-5 text-left">New listings</div>
          <div className="col-auto text-left">
            {formData.expectedListingsCount
              ? pluralize("listing", formData.expectedListingsCount)
              : "–"}
          </div>
          <div className="col-auto text-right flex-fill">
            {formData.expectedListingsValue
              ? `$${financial(formData.expectedListingsValue)}`
              : "–"}
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-7 text-left">Seller transaction fee</div>
          <div className="col-auto text-right flex-fill">
            {formData.expectedTotalValue && formData.expectedTransactionFees
              ? `-$${financial(formData.expectedTransactionFees)}`
              : "–"}
          </div>
        </div>
        <small className="text-whisper">
          Transaction fees automatically deducted when a card sale completes
        </small>
      </div>

      <div className="d-flex flex-row justify-content-between align-items-center">
        <button
          className="btn btn-secondary btn-block text-body"
          onClick={() => onPrev()}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" /> Back
        </button>
        <button
          className="btn btn-primary btn-block font-weight-bold mt-0 ml-3"
          onClick={() => onSubmit()}
        >
          Confirm Sale
        </button>
      </div>
    </div>
  );
};

export default StepReview;
