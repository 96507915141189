import { LegalCheckBox, LegalLink, LegalLinkContainer } from "./styles";

interface Props {
  toggleAgreeTermsAndConditions: () => void;
}

export const LegalCheck = ({ toggleAgreeTermsAndConditions }: Props) => {
  return (
    <LegalLinkContainer>
      <LegalCheckBox
        data-testid={"terms-conditions-check"}
        onChange={toggleAgreeTermsAndConditions}
      />
      <LegalLink to="/terms" target="_blank">
        I agree to the terms and conditions
      </LegalLink>
    </LegalLinkContainer>
  );
};
