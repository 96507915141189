import { CircularProgress } from "@material-ui/core";

const Loader = ({ message = "Loading…", className = "" }) => {
  return (
    <div className={className || "text-center p-5 my-5"}>
      <CircularProgress size={64} className="text-primary" />
      {message && (
        <p className="font-weight-bold text-whisper mt-3">{message}</p>
      )}
    </div>
  );
};

export default Loader;
