import styled from "styled-components";

export const ImageWrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

export const Placeholder = styled.img`
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0.25;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const StyledImage = styled.img`
  left: 0;
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
`;
