import Page from "../components/Contentful/Page";
import { markdown } from "../utils/contentful";

const ContentPage = ({ slug }) => {
  return (
    <Page slug={slug}>
      {(page) => (
        <>
          <h1>{page.title}</h1>
          {markdown(page.body)}
        </>
      )}
    </Page>
  );
};

export default ContentPage;
