import { useEffect } from "react";
import { useMutation } from "@apollo/client";

import { logAmplitudeEvent } from "../../utils/analytics";
import { BUYING_EVENTS, SELLING_EVENTS } from "../../utils/analytics/events";
import EXTEND_OFFER_EXPIRY_DATE from "../../api/mutations/EXTEND_OFFER_EXPIRY_DATE";
import EXTEND_LISTING_EXPIRY_DATE from "../../api/mutations/EXTEND_LISTING_EXPIRY_DATE";

import Loader from "../Loader";

const StepSubmitting = ({
  formData,
  refreshQueries,
  setFormDataFields,
  onComplete,
}) => {
  const [extendOfferExpiryMutation] = useMutation(EXTEND_OFFER_EXPIRY_DATE, {
    refetchQueries: refreshQueries,
    onCompleted({ buyOrderExtendExpiryDate }) {
      if (
        buyOrderExtendExpiryDate.errors &&
        buyOrderExtendExpiryDate.errors.length > 0
      ) {
        logAmplitudeEvent(BUYING_EVENTS.EXISTING_OFFER.EXTEND_EXPIRY.FAILED);
      } else {
        logAmplitudeEvent(BUYING_EVENTS.EXISTING_OFFER.EXTEND_EXPIRY.SUCCESS);
      }
      setFormDataFields({
        completedErrors: buyOrderExtendExpiryDate.errors || [],
      });

      onComplete(buyOrderExtendExpiryDate.errors);
    },
  });

  const [extendListingExpiryMutation] = useMutation(
    EXTEND_LISTING_EXPIRY_DATE,
    {
      refetchQueries: refreshQueries,
      onCompleted({ sellOrderExtendExpiryDate }) {
        if (
          sellOrderExtendExpiryDate.errors &&
          sellOrderExtendExpiryDate.errors.length > 0
        ) {
          logAmplitudeEvent(
            SELLING_EVENTS.EXISTING_LISTING.EXTEND_EXPIRY.FAILED,
          );
        } else {
          logAmplitudeEvent(
            SELLING_EVENTS.EXISTING_LISTING.EXTEND_EXPIRY.SUCCESS,
          );
        }
        setFormDataFields({
          completedErrors: sellOrderExtendExpiryDate.errors || [],
        });

        onComplete(sellOrderExtendExpiryDate.errors);
      },
    },
  );

  // Disable dependencies warning as this is setup to be a one-time useEffect
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (formData.groupedBidId) {
      extendOfferExpiryMutation({
        variables: {
          groupedBidId: formData.groupedBidId,
          extendExpiry: formData.extendExpiry,
        },
      });
    } else if (formData.groupedListingId) {
      extendListingExpiryMutation({
        variables: {
          groupedListingId: formData.groupedListingId,
          extendExpiry: formData.extendExpiry,
        },
      });
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return <Loader />;
};

export default StepSubmitting;
