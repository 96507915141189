import { useState } from "react";

import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";

import { financial } from "../../utils/common";
import { pluralize } from "../../utils/content";

import IS_LOGGED_IN from "../../api/queries/IS_LOGGED_IN";

import LoginModal from "./LoginModal";
import SummaryDataRow from "../SummaryDataRow";
import SellForm, { SellFormSteps } from "../SellForm/SellForm";

const SellSection = ({
  cardParallelId,
  cardParallelProductType,
  gradeId,
  gradedCardParallelId,
  openBuyOrderCount,
  openBuyOrderMaxPrice,
  myItemCount,
  myOpenSellOrderCount,
}) => {
  const [show, setShow] = useState(false);

  const { data: loggedInData } = useQuery(IS_LOGGED_IN);

  const myAvailableItemsCount = myItemCount - myOpenSellOrderCount;

  return (
    <>
      <div className="row no-gutters d-flex align-items-center">
        <div className="col-auto d-none d-sm-block mr-3">
          <FontAwesomeIcon
            icon={faUserFriends}
            size="2x"
            className="text-primary"
            fixedWidth
          />
        </div>

        <div className="col d-flex flex-row justify-content-between align-items-center mr-3">
          <SummaryDataRow
            summaryDataItems={[
              {
                label: openBuyOrderCount
                  ? `${pluralize("offer", openBuyOrderCount)} from`
                  : "0 offers",
                value: openBuyOrderMaxPrice
                  ? `$${financial(openBuyOrderMaxPrice)}`
                  : "–",
                valueColor: openBuyOrderMaxPrice
                  ? "text-primary"
                  : "text-deselected",
              },
            ]}
          />
          <SummaryDataRow
            configAlignItems="justify-content-end"
            summaryDataItems={[
              {
                label: `My listings`,
                value: myItemCount
                  ? `${myOpenSellOrderCount} of ${myItemCount} listed`
                  : "None owned",
                valueColor: myOpenSellOrderCount
                  ? "text-shout"
                  : "text-deselected",
                textAlignment: "text-right",
              },
            ]}
          />
        </div>

        <div className="col-5">
          <button
            className="btn btn-lg btn-primary btn-block text-nowrap shadow font-weight-bold px-2"
            disabled={!openBuyOrderCount && !myAvailableItemsCount}
            onClick={() => setShow(true)}
          >
            {!myAvailableItemsCount && openBuyOrderCount
              ? "View offers"
              : "Sell"}
          </button>
        </div>
      </div>

      {show && loggedInData && loggedInData.isLoggedIn ? (
        <SellForm
          show={show}
          setShow={setShow}
          initialStep={SellFormSteps.edit}
          gradedCardParallelId={gradedCardParallelId}
          cardParallelId={cardParallelId}
          cardParallelProductType={cardParallelProductType}
          gradeId={gradeId}
        />
      ) : (
        <LoginModal show={show} setShow={setShow} />
      )}
    </>
  );
};
export default SellSection;
