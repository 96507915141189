import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import CREATE_WITHDRAWAL_REQUEST from "../api/mutations/CREATE_WITHDRAWAL_REQUEST";

import WithdrawalRequestForm from "../components/WithdrawalRequestForm";

const WithdrawalRequest = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [createWithdrawalRequest] = useMutation(CREATE_WITHDRAWAL_REQUEST, {
    onCompleted({ createWithdrawalRequest }) {
      if (
        createWithdrawalRequest.errors &&
        createWithdrawalRequest.errors.length > 0
      ) {
        setError(createWithdrawalRequest.errors[0]);
        setSubmitting(false);
      } else {
        navigate("/account/withdraw/success");
      }
    },
  });
  return (
    <>
      <Helmet>
        <title>Withdrawal Request Success</title>
      </Helmet>

      <div className="card mx-auto max-width-26 my-5">
        <div className="card-body">
          <h1 className="card-title h5 mt-2 mb-3 font-weight-bold">
            Withdraw Funds
          </h1>
          <p>
            PayPal withdrawals are paid as an item or service and are subject to 
            Paypal fees (i.e. 2.9% + $0.30). These fees are separate from StarStock 
            and out of our control. Please expect 5-10 working days for processing PayPal withdrawals.
          </p>
          <p>
            Checks withdrawals are free, and available for withdrawals of $20 or more. 
            All check requests for under $20 will be rejected. For $4.15, you can receive 
            a tracking number and signature verification with the check via USPS. 
            Please expect around 1-3 weeks for processing check withdrawals.
          </p>

          {error && (
            <div className="alert alert-danger" role="alert">
              {error.message || error.field}
            </div>
          )}
          <WithdrawalRequestForm
            isSubmitting={isSubmitting}
            setSubmitting={setSubmitting}
            createWithdrawalRequest={createWithdrawalRequest}
          />
        </div>
      </div>
    </>
  );
};

export default WithdrawalRequest;
