import { gql } from "@apollo/client";

export const GRADE_FRAGMENT = gql`
  fragment gradeFields on GradeNode {
    id
    name
    grader
    description
  }
`;

export const PLAYER_LISTING_FRAGMENT = gql`
  fragment playerListingFields on PlayerNode {
    id
    name
    slug
    position
    currentTeam {
      id
      name
      abbrName
      colorPrimary
      colorSecondary
    }
  }
`;

export const PLAYER_CARD_FRAGMENT = gql`
  fragment playerCardFields on PlayerCardNode {
    id
    setNumber
    cardFamily {
      id
      name
      program {
        id
        name
      }
    }
    teamPlayers {
      edges {
        node {
          player {
            id
            name
            slug
            position
            headshotPhoto: headshotPhotoSm
          }
        }
      }
    }
  }
`;

export const PLAYER_CARD_WITH_CURRENT_TEAM_FRAGMENT = gql`
  ${PLAYER_LISTING_FRAGMENT}
  fragment playerCardWithCurrentTeamFields on PlayerCardNode {
    id
    setNumber
    cardFamily {
      id
      name
      program {
        id
        name
      }
    }
    teamPlayers {
      edges {
        node {
          player {
            ...playerListingFields
            headshotPhoto: headshotPhotoSm
          }
        }
      }
    }
  }
`;

export const PARALLEL_FRAGMENT = gql`
  fragment parallelFields on CardSetParallelNode {
    id
    name
    badgeBorderColor
    badgeTextColor
    badgeGradientStart
    badgeGradientEnd
    badgeGradientAngle
  }
`;

export const CARD_PARALLEL_ID_FRAGMENT = gql`
  fragment cardParallelIdFields on CardParallelNode {
    id
    slug
    name
    serialLimit
    productType
  }
`;

export const CARD_PARALLEL_MARKET_SUMMARY_FRAGMENT = gql`
  fragment cardParallelMarketSummaryFields on CardParallelNode {
    itemCount
    sellOrderCount
    sellOrderMinPrice
    buyOrderCount
    buyOrderMaxPrice
    lastSaleDate
    firstAddedAt
    salesLast24Hours
  }
`;

export const CARD_PARALLEL_LISTING_FRAGMENT = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${CARD_PARALLEL_MARKET_SUMMARY_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_FRAGMENT}
  fragment cardParallelListingFields on CardParallelNode {
    ...cardParallelIdFields
    ...cardParallelMarketSummaryFields
    frontSidePhotoSmall
    frontSidePhotoMedium
    boxProductPhotoSmall
    boxProductPhotoMedium
    parallel {
      ...parallelFields
    }
    playerCard {
      ...playerCardFields
    }
  }
`;

export const COLLECTION_LISTING_FRAGMENT = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_FRAGMENT}
  fragment collectionListingFields on CardParallelNode {
    ...cardParallelIdFields
    myItemsCount
    myListingsCount
    frontSidePhotoSmall
    boxProductPhotoSmall
    parallel {
      ...parallelFields
    }
    playerCard {
      ...playerCardFields
    }
  }
`;

export const GRADED_CARD_PARALLEL_FIELDS_FRAGMENT = gql`
  fragment gradedCardParallelFields on GradedCardParallelNode {
    id
    itemCount
    itemFirstCreatedAt
    openSellOrderCount
    openSellOrderMinPrice
    openSellOrderMaxCreatedAt
    openBuyOrderCount
    openBuyOrderMaxPrice
    openBuyOrderMaxCreatedAt
    lastSaleDate
    lastSalePrice
  }
`;

export const GROUPED_BID_FRAGMENT = gql`
  fragment groupedBidFields on GroupedBidNode {
    id
    createdAt
    openBuyOrderCount
    suspendedBuyOrderCount
    buyPrice
    lowestAskCount
    lowestAskPrice
    lowestAskPercentage
  }
`;

export const GROUPED_LISTING_FRAGMENT = gql`
  fragment groupedListingFields on GroupedListingNode {
    id
    createdAt
    openSellOrderCount
    askPrice
    highestBidCount
    highestBidPrice
    highestBidPercentage
  }
`;

export const SEARCH_CARD_PARALLEL_ID_FRAGMENT = gql`
  fragment searchCardParallelIdFields on CardParallelSearchResult {
    id
    slug
    name
    serialLimit
    productType
  }
`;

export const SEARCH_CARD_PARALLEL_MARKET_SUMMARY_FRAGMENT = gql`
  fragment searchCardParallelMarketSummaryFields on CardParallelSearchResult {
    itemCount
    sellOrderCount
    sellOrderMinPrice
    buyOrderCount
    buyOrderMaxPrice
    lastSaleDate
    firstAddedAt
    salesLast24Hours
  }
`;

export const SEARCH_CARD_PARALLEL_LISTING_FIELDS = gql`
  ${SEARCH_CARD_PARALLEL_ID_FRAGMENT}
  ${SEARCH_CARD_PARALLEL_MARKET_SUMMARY_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_FRAGMENT}
  fragment searchCardParallelListingFields on CardParallelSearchResult {
    ...searchCardParallelIdFields
    ...searchCardParallelMarketSummaryFields
    frontSidePhotoSmall
    frontSidePhotoMedium
    boxProductPhotoSmall
    boxProductPhotoMedium
    parallel {
      ...parallelFields
    }
    playerCard {
      ...playerCardFields
    }
  }
`;

export const BOX_BREAK_FIELDS = gql`
  fragment boxBreakFields on BoxBreakNode {
    id
    name
    slug
    featuredRank
    description
    scheduledStartTime
    allowPersonalBreakRequests
    state
    createdAt
    maxSpotsPerUser
    publishedAt
    twitchChannelName
    twitchVideoId
    youtubeVideoId
    purchaseOverrideUrl
  }
`;

export const BOX_BREAK_AGGREGATE_FIELDS = gql`
  fragment boxBreakAggregateFields on BoxBreakNode {
    spotsTotalCount
    spotsAvailableCount
    spotsFilledCount
    spotsMinPrice
    spotsMaxPrice
    spotsCurrency
  }
`;

export const FORMAT_FIELDS = gql`
  fragment formatFields on FormatNode {
    id
    name
    description
    breakCategory
  }
`;

export const SPOT_FIELDS = gql`
  fragment spotFields on SpotNode {
    id
    rank
    name
    price
    priceCurrency
    purchasedAt
  }
`;

export const PARTICIPANT_FIELDS = gql`
  fragment participantFields on ParticipantNode {
    preferShipHome
    additionalInstructions
    address {
      id
    }
  }
`;

export const HIT_FIELDS = gql`
  fragment hitFields on HitNode {
    id
    photo
    photoMd
    createdAt
    spot {
      id
      rank
      name
    }
    gradedCardParallel {
      ...gradedCardParallelFields
      cardParallel {
        ...cardParallelListingFields
      }
      grade {
        ...gradeFields
      }
    }
  }
`;
