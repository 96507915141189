import styled, { css } from "styled-components";

import { PageSectionTypes, PageSectionWidths } from "./PageSection";

export const OuterSection = styled.section<{
  addMarginBottom: boolean;
  background?: string;
  backgroundColor?: string;
  borderBottom: boolean;
  borderTop: boolean;
  type: string;
}>`
  background: ${(props) => props.background};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  ${({ addMarginBottom }) =>
    addMarginBottom &&
    css`
      margin-bottom: 1rem;
    `}

  ${({ borderBottom }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid rgb(0 0 0 / 10%);
    `}

  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 1px solid rgb(0 0 0 / 10%);
    `}

  ${({ type }) =>
    type === PageSectionTypes.foreground &&
    css`
      background-color: #fff;
      box-shadow: 0 0.25rem 0.5rem rgb(0 0 32 / 10%);
    `}
`;

const handleWidthType = (width: string) => {
  switch (width) {
    case PageSectionWidths.narrow:
      return css`
        max-width: 60rem;
      `;
    case PageSectionWidths.regular:
      return css`
        max-width: 80em;
      `;
    case PageSectionWidths.wide:
      return css`
        max-width: 90em;
      `;
    default:
      break;
  }
};

export const InnerDiv = styled.div<{
  removePaddingBottom: boolean;
  removePaddingTop: boolean;
  width: string;
}>`
  flex-basis: 0;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  min-width: 0;
  /* stylelint-disable */
  padding-bottom: ${(props) => (props.removePaddingBottom ? "0rem" : "2rem")};
  padding-left: 15px;
  padding-right: 15px;
  padding-top: ${(props) => (props.removePaddingTop ? "0rem" : "2rem")};
  /* stylelint-enable */
  position: relative;
  width: 100%;

  ${({ width }) => width && handleWidthType(width)}
`;
