import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSlidersH,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Drawer from "@material-ui/core/Drawer";
import { rgba } from "polished";

export const FiltersDrawerButtonVariants = {
  iconOnly: "iconOnly",
  iconAndText: "iconAndText",
};

const BorderColor = rgba(0, 0, 0, 0.1);
const PassiveColor = "rgba(44, 77, 118, 0.8)";
const ActiveColor = "#4e8ad4";

const StyledButton = styled.button`
  height: 2.5rem;
  background: white;
  color: ${PassiveColor};
  border: 1px solid ${BorderColor};
  border-radius: 2rem;
  padding: 0 0.75rem;
  font-size: 0.875rem;

  ${(props) =>
    props.configVariant === FiltersDrawerButtonVariants.iconOnly &&
    css`
      width: 2.5rem;
      padding: 0;
    `}

  ${(props) =>
    props.filtersAreApplied &&
    css`
      color: ${ActiveColor};
      border-color: ${ActiveColor};
    `}
`;

const FiltersDrawerButton = ({
  className,
  drawerOpen,
  setDrawerOpen,
  filtersAreApplied,
  resetFilters,
  configVariant = FiltersDrawerButtonVariants.iconAndText,
  children,
}) => {
  return (
    <>
      <StyledButton
        className={`btn text-nowrap d-block d-md-none flex-shrink-0 ${className}`}
        onClick={() => setDrawerOpen(!drawerOpen)}
        filtersAreApplied={filtersAreApplied}
        configVariant={configVariant}
      >
        <FontAwesomeIcon icon={faSlidersH} className="fa-fw" />
        {configVariant === FiltersDrawerButtonVariants.iconAndText && (
          <span className="ml-2">Filters</span>
        )}
      </StyledButton>

      <Drawer
        anchor={"left"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div
          className="bg-light p-4"
          style={{ width: "20rem" }}
          role="presentation"
        >
          <div className="d-flex flex-row flex-nowrap justify-content-start align-items-baseline">
            <h3 className="font-weight-bold flex-fill">Filters</h3>
            {filtersAreApplied && resetFilters && (
              <button
                onClick={() => resetFilters()}
                className="btn btn-link flex-grow-0"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )}
            <button
              onClick={() => setDrawerOpen(false)}
              className="btn btn-link flex-grow-0"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <hr className="mt-1 mb-4" />

          {children}

          <button
            onClick={() => setDrawerOpen(false)}
            className="btn btn-block btn-outline-primary"
          >
            Close
          </button>
        </div>
      </Drawer>
    </>
  );
};

export default FiltersDrawerButton;
