import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useState } from "react";

import { Refetch } from "../../types";
import { AddCardModal } from "../AddCardModal";

import { Button, ButtonContainer } from "./styles";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface Props {
  firstPaymentMethod: boolean;
  handleModalClose: () => void;
  handleAddModalShow: () => void;
  refetch: Refetch;
  show: boolean;
}
export const ManagePaymentAddNewMethod = ({
  firstPaymentMethod,
  handleAddModalShow,
  handleModalClose,
  refetch,
  show,
}: Props) => {
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <>
      <ButtonContainer>
        <Button onClick={handleAddModalShow}>+ Add new payment method</Button>
      </ButtonContainer>

      <Elements stripe={stripePromise}>
        <AddCardModal
          firstPaymentMethod={firstPaymentMethod}
          isOpen={show}
          handleModalClose={handleModalClose}
          refetchQuery={refetch}
          showSuccess={showSuccess}
          setShowSuccess={setShowSuccess}
        />
      </Elements>
    </>
  );
};
