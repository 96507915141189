import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_ID_FRAGMENT,
  GRADE_FRAGMENT,
  PARALLEL_FRAGMENT,
  PLAYER_CARD_FRAGMENT,
} from "../fragments";

const GET_MY_PAST_PURCHASES = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_FRAGMENT}
  query GET_MY_PAST_PURCHASES($cursor: String, $searchQuery: String) {
    myPastPurchases(first: 100, after: $cursor, searchQuery: $searchQuery) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          price
          saleDate
          cardParallel {
            ...cardParallelIdFields
            frontSidePhotoSmall
            parallel {
              ...parallelFields
            }
            playerCard {
              ...playerCardFields
            }
          }
          grade {
            ...gradeFields
          }
        }
      }
    }
  }
`;

export default GET_MY_PAST_PURCHASES;
