import styled from "styled-components";
import { LoadingSpinner } from "../LoadingSpinner";

export const Button = styled.button`
  align-items: center;
  border: none;
  border-radius: 0.5rem;
  display: flex;
  flex-shrink: 0;
  font-weight: bold;
  justify-content: center;
  padding: 0.75rem;

  &:disabled {
    opacity: 0.25;
  }
`;

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
`;
