import styled from "styled-components";

import HeroBackground from "../../images/breaks-logo-color.svg";
import { Colors } from "../../themes/Default";

import { TimeBadge } from "../TimeBadge";

const bannerGradientStart = "#EE9165";
const bannerGradientEnd = "#B56CE7";
const HeroBaseColor = "#f0f0f0";
const HeroGradientStart = "#f3d7c0";
const HeroGradientEnd = "#dec8ee";

export const Content = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 1rem;
`;

export const Container = styled.div`
  background: white;
  border: 1px solid #00000020;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  padding: 0;
  text-align: inherit;
  transition: all 200ms ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border-color: ${Colors.Havelock};
    cursor: pointer;
    outline: 0;
    transform: translateY(-2px);
  }
`;

export const FeaturedBanner = styled.div`
  background: linear-gradient(
    135deg,
    ${bannerGradientStart},
    ${bannerGradientEnd}
  );
  border-radius: 0 0 0.375rem;
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
  left: 0;
  margin-bottom: 0;
  padding: 0.1875rem 0.5rem;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  z-index: 1;
`;

export const HeroImage = styled.img`
  height: 100%;
  left: 0;
  line-height: 0;
  object-fit: cover;
  object-position: center top;
  position: absolute;
  top: 0;
  width: 100%;
`;

interface HeroImageContainerProps {
  backgroundImage?: string | null | undefined;
}

export const HeroImageContainer = styled.div<HeroImageContainerProps>`
  align-items: center;
  background: linear-gradient(#ffffffc0, #ffffffc0),
    url(${(props) => props.backgroundImage || HeroBackground}) center / 50% auto
      no-repeat,
    linear-gradient(
      135deg,
      ${HeroGradientStart},
      #fff 40%,
      #fff 60%,
      ${HeroGradientEnd}
    ),
    ${HeroBaseColor};
  border-bottom: 1px solid #00000010;
  display: flex;
  flex: 0;
  justify-content: center;
  padding: 0;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 66.66%; /* 3:2 aspect ratio */
  }
`;

export const LowerContent = styled.div`
  border-top: 1px solid #00000010;
  flex: 0;
  padding-top: 0.5rem;

  @media (min-width: 36rem) {
    padding-top: 1rem;
  }
`;

export const UpperContent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding-bottom: 0.5rem;

  @media (min-width: 36rem) {
    padding-bottom: 1rem;
  }
`;

export const StyledTimeBadge = styled(TimeBadge)`
  align-self: self-start;
  margin-top: -2rem;
  z-index: 1;
`;

export const Heading = styled.h3`
  color: ${Colors.Astronaut};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;

  @media (min-width: 66rem) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  span {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    opacity: 0.8;
  }
`;
