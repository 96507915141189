import styled from "styled-components";

import { InfoNotice } from "../../../../components/InfoNotice";
import { Borders, Colors } from "../../../../themes/Default";

const BREAKPOINT = "60rem";
const BREAKPOINT_LARGE = "78rem";

export const Container = styled.section`
  background: white;
  padding: 1.5rem 1rem;

  @media (min-width: ${BREAKPOINT}) {
    padding: 2.5rem 2rem;
  }

  @media (min-width: ${BREAKPOINT_LARGE}) {
    display: grid;
    grid-column-gap: 3.5rem;
    grid-template-columns: 1fr 2fr;
    padding: 3rem;
  }

  & + & {
    border-top: 1px solid #ebf1fb;
  }

  :nth-child(2n) {
    background: rgb(249 251 255);
  }
`;

export const Contents = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  margin-top: 1.75rem;

  @media (min-width: ${BREAKPOINT_LARGE}) {
    grid-row-gap: 1.5rem;
    margin-top: 0;
  }
`;

export const DateHeading = styled.span`
  color: ${Colors.Blue};
  display: block;
  font-size: 1.5rem;
  line-height: 1.875rem;

  @media (min-width: ${BREAKPOINT_LARGE}) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

export const Heading = styled.h2`
  font-weight: bold;
  margin-bottom: 0;
`;

export const StyledInfoNotice = styled(InfoNotice)`
  background: white;
  border: ${Borders.Live};
  border-radius: 0.5rem;

  @media (min-width: ${BREAKPOINT_LARGE}) {
    padding: 3rem;
  }
`;

export const Summary = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  margin-top: 1.25rem;

  @media (min-width: ${BREAKPOINT_LARGE}) {
    margin-top: 2rem;
  }
`;

export const Today = styled.span`
  color: #5d7097;
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINT_LARGE}) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;
