import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { AddressNode } from "../../../../generated/schema";
import Loader from "../../../../components/Loader";

import { AddressValues, FetchResposeType } from "../../types";
import { AddressList } from "./components/AddressList";
import { CreateAddressModal } from "./components/CreateAddressModal";
import {
  AddAddressContainer,
  AddressButton,
  Card,
  Container,
  InnerContainer,
  InnerCard,
  Row,
} from "./styles";

interface Props {
  addresses: (AddressNode | null)[] | null;
  loading: boolean;
  onCreateAddress: (values: AddressValues) => FetchResposeType;
  onDeleteAddress: (id: string | undefined) => FetchResposeType;
  onEditAddress: (id: string, values: AddressValues) => FetchResposeType;
  onMakeDefaultAddress: (id: string | undefined) => FetchResposeType;
}

export const AddressBook = ({
  addresses,
  loading,
  onCreateAddress,
  onDeleteAddress,
  onMakeDefaultAddress,
  onEditAddress,
}: Props) => {
  const [createAddressShow, createAddressSetShow] = useState(false);
  const [createSubmitting, setCreateSubmitting] = useState(false);
  const [serverError, setServerError] = useState(false);

  const createAddressAndCloseModal = (values: AddressValues) => {
    return onCreateAddress(values)
      .then((result) => {
        const errors = result.data.createAddress.errors;
        if (errors && errors.length > 0) {
          setServerError(true);
        } else {
          createAddressSetShow(false);
        }
      })
      .finally(() => {
        setCreateSubmitting(false);
      });
  };

  return (
    <Container>
      <InnerContainer>
        <h2>Address Book</h2>

        <Row>
          {loading ? (
            <Loader />
          ) : (
            <>
              <CreateAddressModal
                onCreateAddress={createAddressAndCloseModal}
                show={createAddressShow}
                setShow={createAddressSetShow}
                isSubmitting={createSubmitting}
                setSubmitting={setCreateSubmitting}
                serverError={serverError}
              />

              <AddressList
                addresses={addresses}
                onDeleteAddress={onDeleteAddress}
                onMakeDefaultAddress={onMakeDefaultAddress}
                onEditAddress={onEditAddress}
              />

              <AddAddressContainer addressLength={addresses?.length! % 2 === 1}>
                <Card>
                  <InnerCard>
                    <FontAwesomeIcon
                      icon={faPlus}
                      aria-hidden={true}
                      size="3x"
                    />
                    <AddressButton onClick={() => createAddressSetShow(true)}>
                      {addresses?.length! > 0 ? (
                        <>Add another address</>
                      ) : (
                        <>Add your first address</>
                      )}
                    </AddressButton>
                  </InnerCard>
                </Card>
              </AddAddressContainer>
            </>
          )}
        </Row>
      </InnerContainer>
    </Container>
  );
};
