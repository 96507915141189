import OptionPillsFilter from "./OptionPillsFilter";

const GraderFilter = ({ selectedGrader, setSelectedGrader }) => {
  const options = [
    { label: "All grades", value: "" },
    { label: "Ungraded", value: "STARSTOCK" },
    { label: "Graded", value: "PSA,BGS,SGC,CSG" },
    { label: "PSA", value: "PSA" },
    { label: "BGS", value: "BGS" },
    { label: "SGC", value: "SGC" },
    { label: "CSG", value: "CSG" },
  ];

  const selectedOption =
    options.find((graderOption) => graderOption.value === selectedGrader) ||
    options[0];

  return (
    <div className="mb-4">
      <h6>Grade type</h6>
      <hr className="mt-1" />

      <OptionPillsFilter
        options={options}
        selectedOption={selectedOption}
        setSelectedValue={setSelectedGrader}
      />
    </div>
  );
};

export default GraderFilter;
