import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

const EmptySection = () => {
  return (
    <div className="jumbotron text-center mx-auto">
      <h1 className="display-4">No cards found</h1>
      <p className="h5 mb-3">Want to see some?</p>
      <ul className="list-group h5 mb-5 text-left max-width-30 mx-auto">
        <li className="list-group-item d-flex align-items-center">
          <FontAwesomeIcon icon={faPaperPlane} className="mr-3 fa-w-18" />
          Send us your cards
        </li>
        <li className="list-group-item d-flex align-items-center">
          <FontAwesomeIcon icon={faEdit} className="mr-3" />
          Adjust your search filters
        </li>
      </ul>
      <Link to="/faq">
        <button className="btn btn-primary">Learn about send ins</button>
      </Link>
    </div>
  );
};

export default EmptySection;
