import { gql } from "@apollo/client";

const CREATE_ADDRESS = gql`
  mutation CREATE_ADDRESS(
    $description: String!
    $name: String!
    $addressLines: String!
    $locality: String!
    $region: String!
    $postalCode: String!
    $country: String!
    $role: String!
  ) {
    createAddress(
      description: $description
      name: $name
      addressLines: $addressLines
      locality: $locality
      region: $region
      postalCode: $postalCode
      country: $country
      role: $role
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export default CREATE_ADDRESS;
