import _ from "lodash";
import { linearGradient, rgba } from "polished";

const CardParallelBadge = ({
  name,
  textColor, // text-speak,
  borderColor, // border-color,
  gradientStart, // porcelain #f6f7f8,
  gradientEnd, // porcelain #f6f7f8,
  gradientAngle,
}) => {
  // Convert our badge config to CSS styles
  const style = _.extend(
    {
      color: textColor || rgba("#2c4d76", 0.8),
    },
    linearGradient({
      colorStops: [
        `${gradientStart || "#f6f7f8"} 0%`, // porcelain #f6f7f8
        `${gradientEnd || "#f6f7f8"} 100%`, // porcelain #f6f7f8
      ],
      fallback: gradientStart || "#f6f7f8", // porcelain #f6f7f8
      toDirection: `${gradientAngle || 135}deg`, // top left > bottom right
    }),
    borderColor ? { boxShadow: `inset 0px 0px 0px 1px ${borderColor}` } : {},
  );

  return (
    <div className="badge badge-light" style={style}>
      <span className="text-uppercase" style={{ fontSize: "0.75rem" }}>
        {name}
      </span>
    </div>
  );
};

export default CardParallelBadge;
