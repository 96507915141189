import { Helmet } from "react-helmet";

import NavBar, { NavBarModes } from "../components/NavBar";
import Footer from "../components/Footer";
import { SkipToContent } from "../components/SkipToContent";
import background from "../images/background.png";

export const NavBarWrapper = ({ children, mode = NavBarModes.regular }) => {
  return (
    <div className="d-flex flex-column h-100">
      <SkipToContent />

      <NavBar className="mb-5" mode={mode} />

      <div id="content" className="container-fluid">
        {children}
      </div>

      <Footer />
    </div>
  );
};

export const BackgroundImageWrapper = () => {
  return (
    <Helmet>
      <style type="text/css">
        {`body {
          height: 100vh;
          background: url(${background}) no-repeat center fixed;
          background-size: cover;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
        }`}
      </style>
    </Helmet>
  );
};

export const ContentPageHelmet = () => {
  return (
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;700&family=Barlow:ital,wght@1,900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};
