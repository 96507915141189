import { useQuery } from "@apollo/client";

import { displayNameForGrade } from "../../utils/grades";
import { displayNameForProductType } from "../../utils/content";

import { PRODUCT_TYPES } from "../../api/types";
import GET_GRADED_CARD_PARALLEL from "../../api/queries/GET_GRADED_CARD_PARALLEL";

import SellSection from "./SellSection";
import BuySection from "./BuySection";
import ItemListing from "../ItemListing";
import MyBidsSection from "./MyBidsSection";
import MyListingsSection from "./MyListingsSection";
import Loader from "../Loader";
import ErrorNotice from "../ErrorNotice";

const CardAvailabilitySectionBody = ({
  slug,
  cardParallelId,
  cardParallelProductType,
  cardParallelImage,
  parallelName,
  serialLimit,
  playerCard,
  grade,
  gradedCardParallelId,
}) => {
  // If we don't have a gradedCardParallelId then skip making a query
  const { data, loading, error } = useQuery(GET_GRADED_CARD_PARALLEL, {
    variables: { id: gradedCardParallelId },
    skip: !gradedCardParallelId,
  });

  if (loading) return <Loader />;
  if (error) return <ErrorNotice />;

  // Grab the newly loaded gradedCardParallel if getting from server, else
  // use the placeholder passed in nested in the grade
  const { gradedCardParallel } = data ? data : grade;

  // Find cards that haven't got any listings and thus are "available" to sell
  const myAvailableItems = gradedCardParallel.myItems.filter(
    (myItem) => myItem.openSellOrderCount === 0,
  );

  return (
    <>
      <div className="card-detail-page-section">
        <h3 className="h5 font-weight-bold">
          {cardParallelProductType === PRODUCT_TYPES.BOX
            ? `Trade ${displayNameForProductType(
                cardParallelProductType,
                true,
              )}`
            : `Trade ${displayNameForGrade(grade)} cards`}
        </h3>
        <hr />
        <BuySection
          slug={slug}
          playerCard={playerCard}
          cardParallelId={cardParallelId}
          cardParallelProductType={cardParallelProductType}
          parallelName={parallelName}
          serialLimit={serialLimit}
          grade={grade}
          gradedCardParallelId={gradedCardParallel.id}
          openSellOrders={gradedCardParallel.openSellOrders}
          openSellOrderMinPrice={gradedCardParallel.openSellOrderMinPrice}
          myOpenBuyOrderCount={gradedCardParallel.myOpenBuyOrderCount}
        />
        <SellSection
          cardParallelId={cardParallelId}
          cardParallelProductType={cardParallelProductType}
          gradeId={grade.id}
          gradedCardParallelId={gradedCardParallel.id}
          openBuyOrderCount={gradedCardParallel.openBuyOrderCount}
          openBuyOrderMaxPrice={gradedCardParallel.openBuyOrderMaxPrice}
          myItemCount={gradedCardParallel.myItemCount}
          myOpenSellOrderCount={gradedCardParallel.myOpenSellOrderCount}
        />
      </div>

      {myAvailableItems.length > 0 && (
        <div className="card-detail-page-section mt-4">
          <h3 className="h5 font-weight-bold">
            My unlisted{" "}
            {displayNameForProductType(cardParallelProductType, true)}
          </h3>

          <hr />

          <div className="d-flex flex-row flex-wrap mt-n3">
            {myAvailableItems.map((item) => (
              <ItemListing
                key={item.id}
                item={item}
                image={cardParallelImage}
              />
            ))}
          </div>
        </div>
      )}

      {gradedCardParallel.myGroupedListings.length > 0 && (
        <div className="card-detail-page-section mt-4">
          <h3 className="h5 font-weight-bold">My listings</h3>
          <hr className="mb-0" />
          <MyListingsSection
            slug={slug}
            gradedCardParallelId={gradedCardParallelId}
            groupedListings={gradedCardParallel.myGroupedListings}
          />
        </div>
      )}

      {gradedCardParallel.myGroupedBids.length > 0 && (
        <div className="card-detail-page-section mt-4">
          <h3 className="h5 font-weight-bold">My offers</h3>
          <hr className="mb-0" />
          <MyBidsSection
            slug={slug}
            gradedCardParallelId={gradedCardParallelId}
            groupedBids={gradedCardParallel.myGroupedBids}
          />
        </div>
      )}
    </>
  );
};

export default CardAvailabilitySectionBody;
