import { useState } from "react";

import { Formik, Form } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { boolean, object, string } from "yup";

const recaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

const CaptchaWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
`;

const SignUpForm = ({ userCreate, isSubmitting, setSubmitting }) => {
  const [captcha, setCaptcha] = useState(false);

  const canSubmit = captcha && !isSubmitting;

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        terms: false,
        marketing: false,
      }}
      validationSchema={object({
        firstName: string()
          .required("Required")
          .max(30, "First name must be at most 30 characters")
          .trim(),
        lastName: string()
          .required("Required")
          .max(150, "Last name must be at most 150 characters")
          .trim(),
        email: string()
          .email("Email must be a valid email")
          .required("Required")
          .max(254, "Email must be at most 254 characters")
          .trim(),
        username: string()
          .required("Required")
          .max(150, "Username must be at most 150 characters")
          .matches(
            /^[A-Za-z\d]\w*$/,
            "Username can have letters, numbers and underscores. An underscore cannot be the first character",
          )
          .trim(),
        password: string()
          .required()
          .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d^&$£!%#*@?-]{8,128}$/),
        terms: boolean().oneOf([true], "Accept Terms & Conditions is required"),
        marketing: boolean(),
        promocCode: string()
          .max(100, "Promo code must be at most 100 characters")
          .trim(),
      })}
      onSubmit={(values) => {
        setSubmitting(true);
        userCreate({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            username: values.username,
            password: values.password,
            marketingEmails: values.marketing,
            promoCode: values.promoCode,
          },
        });
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <Form>
          <div className="row">
            <div className="col pr-2">
              <TextField
                id="firstName"
                name="firstName"
                label="First name"
                autoComplete="given-name"
                value={values.firstName}
                error={errors.firstName && touched.firstName}
                helperText={
                  errors.firstName && touched.firstName && errors.firstName
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                margin="dense"
                className="mb-2"
              />
            </div>

            <div className="col pl-2">
              <TextField
                id="lastName"
                name="lastName"
                label="Last name"
                autoComplete="family-name"
                value={values.lastName}
                error={errors.lastName && touched.lastName}
                helperText={
                  errors.lastName && touched.lastName && errors.lastName
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                margin="dense"
                className="mb-2"
              />
            </div>
          </div>

          <TextField
            id="email"
            name="email"
            type="email"
            label="Email"
            autoComplete="email"
            value={values.email}
            error={errors.email && touched.email}
            helperText={errors.email && touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-2"
          />

          <TextField
            id="username"
            name="username"
            label="Username"
            autoComplete="username"
            value={values.username}
            error={errors.username && touched.username}
            helperText={errors.username && touched.username && errors.username}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-2"
          />

          <TextField
            id="password"
            name="password"
            type="password"
            label="Password"
            autoComplete="new-password"
            value={values.password}
            error={errors.password && touched.password}
            helperText={
              "Requires 8 or more characters including letters and numbers"
            }
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-3"
          />

          <TextField
            id="promoCode"
            name="promoCode"
            label="Promo code or referral username"
            value={values.promoCode}
            error={errors.promoCode && touched.promoCode}
            helperText={""}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            margin="dense"
            className="mb-3"
          />

          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={values.terms}
                name="terms"
                required
              />
            }
            label={
              <small>
                I agree to the{" "}
                <Link to="/terms" target="_blank">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>{" "}
                <span className="font-weight-bold">(Required)</span>
              </small>
            }
            className="mb-3 text-left"
            error={errors.terms && touched.terms}
          />

          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={values.marketing}
                name="marketing"
              />
            }
            label={
              <small>
                I’d like to receive emails including offers, special events and
                other marketing content from StarStock
              </small>
            }
            className="mb-3 text-left"
          />

          <CaptchaWrapper>
            <ReCAPTCHA
              sitekey={recaptchaSiteKey}
              onChange={(value) => setCaptcha(value)}
            />
          </CaptchaWrapper>

          <button
            type="submit"
            className="btn btn-success btn-block btn-lg mb-3"
            disabled={!canSubmit}
          >
            Create account
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
