import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import ErrorNotice from "../../../components/ErrorNotice";
import { LiveStreamSection } from "../../../components/LiveStreamSection";
import Loader from "../../../components/Loader";
import { PageSection } from "../../../components/PageSection";
import { AuctionState } from "../../../generated/schema";
import Background from "../../../images/backgrounds/vector/live-breaks-pattern-light.svg";

import { ActiveLotSection } from "../components/ActiveLotSection";
import { AuctionHeader } from "../components/AuctionHeader";
import { LotsSchedule } from "../components/LotsSchedule";
import { useFetchAuction } from "../hooks/useFetchAuction";
import { usePusher } from "../hooks/usePusher";
import { getCurrentActiveLot } from "../utils/getCurrentActiveLot";

export const Auction = () => {
  const { slug } = useParams();
  const { data, error, loading } = useFetchAuction(slug || "");
  const { pusherNotConnected } = usePusher(data?.auction?.pusherChannelId);

  if (loading) return <Loader />;
  if (error || !data?.auction) return <ErrorNotice error={error} />;

  const {
    auction: {
      auctionphotoSet,
      description,
      lotCount,
      lotMaxOpeningPrice,
      lotMinOpeningPrice,
      lotSet: { edges: lots },
      name,
      scheduledStartTime,
      state,
      twitchChannelName,
      twitchVideoId,
      youtubeVideoId,
    },
  } = data;
  const currentActiveLot = getCurrentActiveLot(lots);
  const showActiveLot = currentActiveLot && state === AuctionState.LIVE;
  const images = auctionphotoSet.edges.map((node) => node?.node);
  const auctionAnalytics = {
    name: name,
    slug: slug,
    state: state,
  };

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>

      <AuctionHeader
        description={description}
        heading={name}
        images={images}
        lotCount={lotCount}
        maxPrice={lotMaxOpeningPrice}
        minPrice={lotMinOpeningPrice}
        startTime={scheduledStartTime}
        state={state}
      />

      <LiveStreamSection
        autoplay={true}
        backgroundImg={Background}
        eventState={state}
        scheduledStartTime={scheduledStartTime}
        showChat={state === AuctionState.LIVE}
        youtubeVideoId={youtubeVideoId}
        twitchVideoId={twitchVideoId}
        twitchChannelName={twitchChannelName}
      />

      {showActiveLot && (
        <>
          {/* Placeholder component */}
          {pusherNotConnected && (
            <div>Failed to connect to live update server.</div>
          )}
          <ActiveLotSection
            auctionAnalytics={auctionAnalytics}
            lotId={currentActiveLot.id}
          />
        </>
      )}

      <PageSection id="lots">
        <LotsSchedule lots={lots} />
      </PageSection>
    </>
  );
};
