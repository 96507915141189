import { useState } from "react";

import classNames from "classnames";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { number } from "yup";

import { CustomCurrencyFormat } from "../utils/formatters";

const DepositPrices = ({ amount, setAmount }) => {
  const [showCustomAmount, setShowCustomAmount] = useState(false);

  const amountSchema = number().positive().min(5).max(1000000);

  const castAndSetAmount = (value) => {
    amountSchema.isValid(value).then((valid) => {
      if (valid) {
        setAmount(amountSchema.cast(value));
      }
    });
  };

  return (
    <>
      <div className="row no-gutters mx-n2 mb-4">
        <div className="col px-2">
          <button
            type="button"
            className={classNames(
              "btn btn-outline-primary btn-block rounded-pill py-2",
              {
                active: !showCustomAmount && amount === 25,
              },
            )}
            onClick={() => {
              setAmount(25);
              setShowCustomAmount(false);
            }}
          >
            $25
          </button>
        </div>

        <div className="col px-2">
          <button
            type="button"
            className={classNames(
              "btn btn-outline-primary btn-block rounded-pill py-2",
              {
                active: !showCustomAmount && amount === 100,
              },
            )}
            onClick={() => {
              setAmount(100);
              setShowCustomAmount(false);
            }}
          >
            $100
          </button>
        </div>

        <div className="col px-2">
          <button
            type="button"
            className={classNames(
              "btn btn-outline-primary btn-block rounded-pill py-2",
              {
                active: showCustomAmount,
              },
            )}
            onClick={() => setShowCustomAmount(true)}
          >
            Custom
          </button>
        </div>
      </div>

      {showCustomAmount && (
        <TextField
          id="amount"
          label="Enter your deposit amount"
          value={amount}
          onChange={(event) => castAndSetAmount(event.target.value)}
          helperText="Minimum deposit is $5.00"
          variant="outlined"
          fullWidth
          margin="dense"
          className="mb-3"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="text-speak">
                $
              </InputAdornment>
            ),
            inputComponent: CustomCurrencyFormat,
          }}
        />
      )}
    </>
  );
};

export default DepositPrices;
