import { Modal } from "react-bootstrap";
import { AddressNode } from "../../../../../generated/schema";
import { AddressValues } from "../../../types";

import { AddressForm } from "./AddressForm/AddressForm";
import { AddressFormContainer, Header } from "./styles";

interface Props {
  address: AddressNode | null;
  isSubmitting: boolean;
  onEditAddress: (id: string, values: AddressValues) => Promise<void>;
  show: boolean;
  setShow(show: boolean): void;
  setSubmitting(isSubmitting: boolean): void;
  serverError: boolean;
}

export const EditAddressModal = ({
  address,
  isSubmitting,
  onEditAddress,
  show,
  setShow,
  setSubmitting,
  serverError,
}: Props) => {
  const lines = address?.addressLines.split("\n");
  while (lines?.length! < 2) {
    lines?.push("");
  }

  const values = {
    id: address?.id!,
    description: address?.description!,
    name: address?.name!,
    line1: lines![0],
    line2: lines![1]!,
    locality: address?.locality!,
    region: address?.region!,
    postalCode: address?.postalCode!,
    country: address?.country!,
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <AddressFormContainer>
        <Header>Edit address</Header>

        <AddressForm
          values={values}
          onSubmit={(values) => onEditAddress(values.id!, values)}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
          buttonLabel="Save"
          serverError={serverError}
        />
      </AddressFormContainer>
    </Modal>
  );
};
