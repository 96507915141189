import { useState, useEffect } from "react";

import { useMutation } from "@apollo/client";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEnvelopeOpenText,
} from "@fortawesome/free-solid-svg-icons";

import SEND_USER_OWNERSHIPS_CSV from "../../api/mutations/SEND_USER_OWNERSHIPS_CSV";
import { logAmplitudeEvent } from "../../utils/analytics";
import { COLLECTION_EVENTS } from "../../utils/analytics/events";

const ExportModalStates = {
  form: "form",
  submitted: "submitted",
};

const ExportModalForm = ({ setShow, setState }) => {
  const [sendUserOwnershipsCsv] = useMutation(SEND_USER_OWNERSHIPS_CSV);

  return (
    <>
      <Modal.Body>
        <p>
          Use this tool to generate a CSV file of your StarStock collection
          history. CSV files can be opened in most spreadsheet tools, such as
          Excel, Numbers, or Google Sheets.
        </p>
        <p className="mb-0">
          It’ll take a moment to generate, so we will send you an email when
          it’s ready. If you have any issues, contact us on{" "}
          <a href="mailto:support@starstock.com">support@starstock.com</a>.
        </p>
      </Modal.Body>

      <Modal.Footer className="justify-content-between px-2 py-2">
        <button
          className="btn btn-secondary text-dark"
          onClick={() => {
            setShow(false);
            setState(ExportModalStates.form);
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            logAmplitudeEvent(COLLECTION_EVENTS.EXPORT_DATA.CSV_REQUESTED);
            sendUserOwnershipsCsv();
            setState(ExportModalStates.submitted);
          }}
        >
          <FontAwesomeIcon icon={faDownload} className="mr-2" />
          Email my CSV export
        </button>
      </Modal.Footer>
    </>
  );
};

const ExportModalSubmitted = ({ setShow, setState }) => {
  return (
    <>
      <Modal.Body className="text-center">
        <FontAwesomeIcon
          icon={faEnvelopeOpenText}
          className="text-primary fa-4x mb-3"
        />
        <h4 className="text-center font-weight-bold">
          We are preparing your data export
        </h4>
        <p>Check out your emails, you should receive it shortly!</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center px-2 py-2">
        <button
          className="btn btn-primary px-4"
          onClick={() => {
            setShow(false);
            setState(ExportModalStates.form);
          }}
        >
          Done
        </button>
      </Modal.Footer>
    </>
  );
};

const ExportModal = ({ show, setShow }) => {
  const [state, setState] = useState(ExportModalStates.form);

  useEffect(() => {
    logAmplitudeEvent(COLLECTION_EVENTS.EXPORT_DATA.FORM_VIEWED);
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => {
        setState(ExportModalStates.form);
        setShow(false);
      }}
      size="lg"
      aria-labelledby="my-card-parallel-cards-modal-title"
      scrollable
      centered
    >
      <Modal.Header
        id="my-card-parallel-cards-modal-title"
        className="d-flex flex-row justify-content-between"
        closeButton
      >
        <h4 className="font-weight-bold flex-grow-1 flex-shrink-0 mb-0">
          Export collection data
        </h4>
      </Modal.Header>

      {state === ExportModalStates.form ? (
        <ExportModalForm setShow={setShow} setState={setState} />
      ) : state === ExportModalStates.submitted ? (
        <ExportModalSubmitted setShow={setShow} setState={setState} />
      ) : null}
    </Modal>
  );
};

export default ExportModal;
