import { Link } from "react-router-dom";

import styled, { css } from "styled-components";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { rgba } from "polished";

import GET_CARD_PARALLEL from "../api/queries/GET_CARD_PARALLEL";
import GET_MY_WISHLIST_METADATA from "../api/queries/GET_MY_WISHLIST_METADATA";
import GET_MY_WISHLIST_PLAYERS from "../api/queries/GET_MY_WISHLIST_PLAYERS";

import CardParallelBadges from "./CardParallelBadges";
import CardParallelProductPhoto from "./CardParallelProductPhoto";

import { displayNameForGrade } from "../utils/grades";

import { Colors } from "../themes/Default";

const StyledCardParallelContainer = styled.div`
  // Make cards full width on smaller devices
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;

  // 15px is the current bootstrap gutter size we're counteracting
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: 36rem) {
    border-left-width: 1px;
    border-right-width: 1px;
    border-radius: 0.25rem;
    margin-left: 0;
    margin-right: 0;
  }
`;

const StyledToggleWatchlistButton = styled.button`
  position: relative;
  z-index: 1;
  background: transparent;
  color: ${(props) =>
    props.watching ? rgba(Colors.Havelock, 0.5) : "#00000020"};
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 1rem;

  &:hover,
  &:focus {
    color: ${Colors.Havelock};
    outline: 0;

    ::after {
      position: absolute;
      top: -11px;
      right: -4px;
      color: Havelock;
      content: "${(props) => (props.watching ? "-" : "+")}";
    }
  }
`;

const StyledProductPhotoContainer = styled.div`
  width: 64px;
  height: 90px;

  @media (min-width: 36rem) {
    width: 72px;
    height: 100px;
  }

  ${(props) =>
    props.largeMode &&
    css`
      width: 80px;
      height: 114px;

      @media (min-width: 36rem) {
        width: 120px;
        height: 168px;
      }
    `};
`;

const CardGrade = styled.p`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${rgba(Colors.Astronaut, 1)};
  white-space: nowrap;
  overflow-y: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
`;

const CardParallelContainer = ({
  id,
  slug,
  linkPath,
  onClick,
  productType,
  photo,
  cardProgram,
  cardFamily,
  cardName,
  cardSetNumber,
  itemCount,
  parallel,
  serialLimit,
  grade,
  watchlistId = false,
  addToWatchlist,
  removeFromWatchlist,
  configCard = false,
  configClickableCard = false,
  configLargeMode = false,
  configAllowWide = false,
  configInsetChildren = false,
  configTopBorder = false,
  overflow = false,
  showGrade = false,
  customClasses = "",
  children,
}) => {
  const {
    name: parallelName,
    badgeTextColor: parallelBadgeTextColor,
    badgeBorderColor: parallelBadgeBorderColor,
    badgeGradientStart: parallelBadgeGradientStart,
    badgeGradientEnd: parallelBadgeGradientEnd,
    badgeGradientAngle: parallelBadgeGradientAngle,
  } = parallel;

  const toggleWatchlistStatus = () => {
    if (watchlistId) {
      removeFromWatchlist({
        refetchQueries: [
          { query: GET_CARD_PARALLEL, variables: { slug } },
          { query: GET_MY_WISHLIST_METADATA },
          { query: GET_MY_WISHLIST_PLAYERS },
        ],
        variables: {
          id: watchlistId,
        },
      });
    } else {
      addToWatchlist({
        refetchQueries: [
          { query: GET_CARD_PARALLEL, variables: { slug } },
          { query: GET_MY_WISHLIST_METADATA },
          { query: GET_MY_WISHLIST_PLAYERS },
        ],
        variables: {
          cardParallelId: id,
        },
      });
    }
  };

  const productPhoto = (
    <StyledProductPhotoContainer
      productType={productType}
      largeMode={configLargeMode}
    >
      <CardParallelProductPhoto
        productType={productType}
        image={photo}
        grade={grade}
        overflow={overflow}
      />
    </StyledProductPhotoContainer>
  );

  return (
    <StyledCardParallelContainer
      className={classNames(`bg-white p-3 h-100 ${customClasses}`, {
        card: configCard,
        "clickable-card": configCard && configClickableCard,
        "border-top": !configCard && configTopBorder,
        "border-bottom": !configCard,
      })}
    >
      {linkPath && configCard && configClickableCard && (
        <Link
          to={linkPath}
          className={classNames({
            "stretched-link": configCard && configClickableCard,
          })}
        />
      )}

      {!linkPath && onClick && configCard && configClickableCard && (
        <button
          onClick={onClick}
          className="btn btn-link rounded-0 border-0 stretched-link p-0 m-0"
        />
      )}

      <div className="row no-gutters h-100">
        <div className="col-auto mr-3">
          <div className="overlap-item">
            {linkPath ? (
              <Link to={linkPath}>{productPhoto}</Link>
            ) : (
              productPhoto
            )}
            {itemCount > 1 && (
              <span className="badge badge-primary overlap-badge">
                {itemCount}x
              </span>
            )}
          </div>
        </div>

        <div className="col">
          <div className="row no-gutters h-100">
            <div className="col">
              <div className="d-flex flex-row justify-content-between">
                <p className="text-speak mb-1" style={{ fontSize: "0.75rem" }}>
                  {cardProgram} <span className="text-deselected px-1">•</span>{" "}
                  {cardFamily}
                </p>
                {(addToWatchlist || removeFromWatchlist) && (
                  <StyledToggleWatchlistButton
                    watching={watchlistId}
                    onClick={toggleWatchlistStatus}
                  >
                    <FontAwesomeIcon icon={faStar} aria-hidden={true} />
                    <span className="sr-only">
                      {watchlistId
                        ? "Remove from watchlist"
                        : "Add to watchlist"}
                    </span>
                  </StyledToggleWatchlistButton>
                )}
              </div>

              {linkPath ? (
                <Link to={linkPath}>
                  <h5 className="mb-2" style={{ fontSize: "1.25rem" }}>
                    <span className="font-weight-bold">{cardName}</span>{" "}
                    {cardSetNumber}
                  </h5>
                </Link>
              ) : (
                <h5 className="mb-2">
                  <span className="font-weight-bold">{cardName}</span>{" "}
                  {cardSetNumber}
                </h5>
              )}
              <CardParallelBadges
                name={parallelName}
                badgeTextColor={parallelBadgeTextColor}
                badgeBorderColor={parallelBadgeBorderColor}
                badgeGradientStart={parallelBadgeGradientStart}
                badgeGradientEnd={parallelBadgeGradientEnd}
                badgeGradientAngle={parallelBadgeGradientAngle}
                serialLimit={serialLimit}
              />
              {showGrade && <CardGrade>{displayNameForGrade(grade)}</CardGrade>}
            </div>

            {children && configInsetChildren && (
              <div
                className={classNames(
                  "col-12 mt-3 d-flex flex-column justify-content-end",
                  {
                    "col-lg-6 ml-lg-3 mt-lg-0": configAllowWide,
                  },
                )}
              >
                {children}
              </div>
            )}
          </div>
        </div>

        {children && !configInsetChildren && (
          <div
            className={classNames("col-12 mt-3", {
              "col-lg-6 ml-lg-3 mt-lg-0": configAllowWide,
            })}
          >
            {children}
          </div>
        )}
      </div>
    </StyledCardParallelContainer>
  );
};

export default CardParallelContainer;
