import styled from "styled-components";

export const CardContainer = styled.div`
  display: none;
  padding: 0 0.5rem;

  @media (min-width: 48rem) {
    display: block;
  }
`;

export const Grid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 -0.5rem;

  @media (min-width: 48rem) {
    grid-template-columns: 2fr 1fr;
  }

  @media (min-width: 75rem) {
    grid-template-columns: 3fr 1fr;
  }
`;

export const VideoContainer = styled.div`
  padding: 0 0.5rem;
`;
