import { useState } from "react";

import { Modal } from "react-bootstrap";
import classNames from "classnames";

import StepEdit from "./StepEdit";
import StepReview from "./StepReview";
import StepSubmitting from "./StepSubmitting";
import StepComplete from "./StepComplete";

export const ExtendExpiryFormSteps = {
  EDIT: "EDIT",
  REVIEW: "REVIEW",
  SUBMITTING: "SUBMITTING",
  COMPLETE: "COMPLETE",
};

const defaultFormData = {
  groupedBidId: null,
  groupedListingId: null,
  extendExpiry: "THIRTY_DAYS",
};

const Header = ({ title, showClose }) => {
  return (
    <Modal.Header className="border-bottom pb-0" closeButton={showClose}>
      <div className={classNames("container-fluid", { "mr-n4": showClose })}>
        <div className="text-center">
          <h3 className="font-weight-bold">{title}</h3>
        </div>
      </div>
    </Modal.Header>
  );
};

const ExtendExpiryForm = ({
  show,
  setShow,
  initialStep = ExtendExpiryFormSteps.EDIT,
  groupedBidId,
  groupedListingId,
  refreshQueries,
}) => {
  const initialFormData = {
    ...defaultFormData,
    groupedBidId: groupedBidId,
    groupedListingId: groupedListingId,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [step, setStep] = useState(initialStep);

  // Merges given form field values into the existing form data
  const setFormDataFields = (updatedFields) =>
    setFormData((current) => ({ ...current, ...updatedFields }));

  const closeAndResetForm = () => {
    setStep(ExtendExpiryFormSteps.EDIT);
    setFormData(initialFormData);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={closeAndResetForm}
      onExited={closeAndResetForm}
      backdrop={"static"}
      keyboard={step !== ExtendExpiryFormSteps.SUBMITTING}
      centered
    >
      <Header
        title={"Extend Expiry Date"}
        showClose={step !== ExtendExpiryFormSteps.SUBMITTING}
      />

      {step === ExtendExpiryFormSteps.EDIT && (
        <StepEdit
          formData={formData}
          setFormDataFields={setFormDataFields}
          onNext={() => {
            setStep(ExtendExpiryFormSteps.REVIEW);
          }}
          onCancel={() => closeAndResetForm()}
        />
      )}
      {step === ExtendExpiryFormSteps.REVIEW && (
        <StepReview
          formData={formData}
          setFormDataFields={setFormDataFields}
          onSubmit={() => setStep(ExtendExpiryFormSteps.SUBMITTING)}
          onPrev={() => setStep(ExtendExpiryFormSteps.EDIT)}
        />
      )}
      {step === ExtendExpiryFormSteps.SUBMITTING && (
        <StepSubmitting
          formData={formData}
          refreshQueries={refreshQueries}
          setFormDataFields={setFormDataFields}
          onComplete={() => setStep(ExtendExpiryFormSteps.COMPLETE)}
        />
      )}
      {step === ExtendExpiryFormSteps.COMPLETE && (
        <StepComplete formData={formData} onClose={closeAndResetForm} />
      )}
    </Modal>
  );
};

export default ExtendExpiryForm;
