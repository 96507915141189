import { NavLink } from "react-router-dom";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrophy,
  faBars,
  faTag,
  faVideo,
  faArchive,
  faStar,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  // Get rid of Bootstrap's downward caret from the menu toggles
  :after {
    display: none;
  }
`;

const StyledNav = styled(Nav)`
  font-size: 1rem;

  @media (max-width: 28rem) {
    font-size: 0.825rem;
    margin-right: 0.1rem;
  }
`;

const PrimaryNav = () => {
  return (
    <>
      <Navbar.Collapse>
        <StyledNav className="justify-content-end">
          <NavLink
            to="/marketplace"
            className={({ isActive }) =>
              "nav-link mr-2 mr-lg-4" + (isActive ? " active" : "")
            }
          >
            <span className="font-weight-bold text-nowrap">
              <FontAwesomeIcon
                icon={faTag}
                className="d-none d-xl-inline-block mr-2"
              />
              Marketplace
            </span>
          </NavLink>
          <NavLink
            to="/breaks"
            className={({ isActive }) =>
              "nav-link mr-2 mr-lg-4" + (isActive ? " active" : "")
            }
          >
            <span className="font-weight-bold text-nowrap">
              <FontAwesomeIcon
                icon={faVideo}
                className="d-none d-xl-inline-block mr-2"
              />
              Breaks
            </span>
          </NavLink>
          <NavLink
            to="/collection/me"
            className={({ isActive }) =>
              "nav-link mr-2 mr-lg-4" + (isActive ? " active" : "")
            }
          >
            <span className="font-weight-bold text-nowrap">
              <FontAwesomeIcon
                icon={faArchive}
                className="d-none d-xl-inline-block mr-2"
              />
              Collection
            </span>
          </NavLink>
          <NavLink
            to="/watchlist/me"
            className={({ isActive }) =>
              "nav-link mr-2 mr-lg-4" + (isActive ? " active" : "")
            }
          >
            <span className="font-weight-bold text-nowrap">
              <FontAwesomeIcon
                icon={faStar}
                className="d-none d-xl-inline-block mr-2"
              />
              Watchlist
            </span>
          </NavLink>
          <NavLink
            to="/activity/me"
            className={({ isActive }) =>
              "nav-link mr-2 mr-lg-4" + (isActive ? " active" : "")
            }
          >
            <span className="font-weight-bold text-nowrap">
              <FontAwesomeIcon
                icon={faExchangeAlt}
                className="d-none d-xl-inline-block mr-2"
              />
              Activity
            </span>
          </NavLink>
          <a href="https://www.collectiblexchange.com" target="_blank"  rel="noreferrer" className="nav-link mr-2 mr-lg-4">
            <span className="font-weight-bold text-nowrap">
              <FontAwesomeIcon
                icon={faTrophy}
                className="d-none d-xl-inline-block mr-2"
              />
              CollectibleXchange
            </span>
          </a>
        </StyledNav>
      </Navbar.Collapse>

      <Dropdown className="d-block d-lg-none">
        <StyledDropdownToggle
          id="nav-primary-dropdown-toggle"
          variant="none"
          className="border-right rounded-0 px-3 px-md-4"
        >
          <FontAwesomeIcon
            icon={faBars}
            className="fa-2x align-text-bottom text-primary"
          />
        </StyledDropdownToggle>

        <Dropdown.Menu
          id="nav-primary-dropdown-menu"
          className="shadow overflow-y ml-2"
          style={{
            minWidth: "12rem",
            maxHeight: "80vh",
          }}
        >
          <Dropdown.Item
            as={NavLink}
            to="/marketplace"
            className={({ isActive }) =>
              "text-speak py-3" + (isActive ? " active" : "")
            }
          >
            <FontAwesomeIcon icon={faTag} className="mr-3" />
            Marketplace
          </Dropdown.Item>
          <Dropdown.Item
            as={NavLink}
            to="/breaks"
            className={({ isActive }) =>
              "text-speak py-3" + (isActive ? " active" : "")
            }
          >
            <FontAwesomeIcon icon={faVideo} className="mr-3" />
            Breaks
          </Dropdown.Item>
          <Dropdown.Item
            as={NavLink}
            to="/collection/me"
            className={({ isActive }) =>
              "text-speak py-3" + (isActive ? " active" : "")
            }
          >
            <FontAwesomeIcon icon={faArchive} className="mr-3" />
            Collection
          </Dropdown.Item>
          <Dropdown.Item
            as={NavLink}
            to="/watchlist/me"
            className={({ isActive }) =>
              "text-speak py-3" + (isActive ? " active" : "")
            }
          >
            <FontAwesomeIcon icon={faStar} className="mr-3" />
            Watchlist
          </Dropdown.Item>
          <Dropdown.Item
            as={NavLink}
            to="/activity/me"
            className={({ isActive }) =>
              "text-speak py-3" + (isActive ? " active" : "")
            }
          >
            <FontAwesomeIcon icon={faExchangeAlt} className="mr-3" />
            Activity
          </Dropdown.Item>
          <a href="https://www.collectiblexchange.com" target="_blank"  rel="noreferrer" className="dropdown-item">
            <FontAwesomeIcon icon={faTrophy} className="mr-3" />
            CollectibleXchange
          </a>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default PrimaryNav;
