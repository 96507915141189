import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_ID_FRAGMENT,
  GRADE_FRAGMENT,
  PARALLEL_FRAGMENT,
  PLAYER_CARD_FRAGMENT,
} from "../fragments";

const GET_MY_CARD_PARALLEL_ITEMS = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  ${PLAYER_CARD_FRAGMENT}
  query GET_CARD_PARALLEL_FOR_ITEMS($slug: String!) {
    cardParallel(slug: $slug) {
      ...cardParallelIdFields
      frontSidePhotoSmall
      parallel {
        ...parallelFields
      }
      playerCard {
        ...playerCardFields
      }
      myItems {
        id
        openSellOrderCount
        frontSidePhoto
        frontSidePhotoSmall
        reverseSidePhoto
        reverseSidePhotoThumbnail
        grade {
          ...gradeFields
        }
      }
    }
  }
`;

export default GET_MY_CARD_PARALLEL_ITEMS;
