import ReactModal, { Styles } from "react-modal";
import { ReactNode } from "react";

import { CloseButton, defaultStyles, Header, Title } from "./styles";

interface Props {
  children: ReactNode;
  handleModalClose: () => void;
  isOpen: boolean;
  title: string;
  customStyles?: Styles;
}

export const Modal = ({
  children,
  handleModalClose,
  isOpen,
  title,
  customStyles,
}: Props) => {
  const modalStyles: Styles = {
    content: { ...defaultStyles.content, ...customStyles?.content },
    /* stylelint-disable */
    overlay: { ...defaultStyles.overlay, ...customStyles?.overlay },
    /* stylelint-enable */
  };

  return (
    <ReactModal
      onAfterClose={handleModalClose}
      isOpen={isOpen}
      style={modalStyles}
      contentLabel={title}
      appElement={document.body}
    >
      <Header>
        <Title>{title}</Title>
        <CloseButton onClick={handleModalClose} aria-label="close">
          <svg viewBox="0 0 10 10" aria-hidden="true" focusable="false">
            <rect height="8" width="2" y="1" x="4" />
            <rect height="2" width="8" y="4" x="1" />
          </svg>
        </CloseButton>
      </Header>
      {children}
    </ReactModal>
  );
};
