import MarketIndexWinnersAndLosersWidget from "./MarketIndexWinnersAndLosersWidget";

const slug = "nfl-emerging-30";

const NFLEmerging30Widget = () => {
  return (
    <MarketIndexWinnersAndLosersWidget
      slug={slug}
      title={
        <div className="d-flex flex-row align-items-top">
          🏈 NFL Emerging 30
        </div>
      }
      subtitle={
        <>
          Our latest index, tracking{" "}
          <a
            href={`/marketplace/indices/${slug}`}
            className="text-primary font-weight-bold"
          >
            Prizm Base RC
          </a>{" "}
          (PSA 10 and StarStock A) cards for 30 top NFL prospects from the
          2018-2020 draft classes
        </>
      }
    />
  );
};

export default NFLEmerging30Widget;
