import { ReactNode } from "react";

import { InnerDiv, OuterSection } from "./styles";

export const PageSectionTypes = {
  background: "background",
  foreground: "foreground",
};

export const PageSectionWidths = {
  full: "full",
  narrow: "narrow",
  regular: "regular",
  wide: "wide",
};

interface Props {
  addMarginBottom?: boolean;
  background?: string;
  backgroundColor?: string;
  borderBottom?: boolean;
  borderTop?: boolean;
  children?: ReactNode;
  className?: string;
  id?: string;
  removePaddingBottom?: boolean;
  removePaddingTop?: boolean;
  type?: string;
  width?: string;
}

export const PageSection = ({
  addMarginBottom = false,
  background,
  backgroundColor,
  borderBottom = true,
  borderTop = false,
  children,
  className,
  id,
  removePaddingBottom = false,
  removePaddingTop = false,
  type = PageSectionTypes.foreground,
  width = PageSectionWidths.regular,
}: Props) => {
  return (
    <OuterSection
      addMarginBottom={addMarginBottom}
      background={background}
      backgroundColor={backgroundColor}
      borderBottom={borderBottom}
      borderTop={borderTop}
      className={className}
      id={id}
      type={type}
    >
      <InnerDiv
        className={className}
        removePaddingBottom={removePaddingBottom}
        removePaddingTop={removePaddingTop}
        width={width}
      >
        {children}
      </InnerDiv>
    </OuterSection>
  );
};
