import { useState } from "react";

import styled from "styled-components";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faMinusSquare,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";

import { financial } from "../../utils/common";
import { displayNameForGrade } from "../../utils/grades";

const StyledTable = styled.table`
  table-layout: fixed;

  @media (max-width: 28rem) {
    font-size: 0.825rem;
  }
`;

const SalesTable = ({ sales }) => {
  const pageSize = 10;
  const [offset, setOffset] = useState(0);
  const salesToDisplay = sales.slice(offset, offset + pageSize);

  const sourceLabels = {
    auction: "auction",
    ebay: "eBay",
    marketplace: null,
    starstock: null,
  };

  const thisYear = new Date().getFullYear();

  return (
    <>
      <StyledTable className="table table-sm table-borderless table-striped border-bottom">
        <thead className="border-bottom">
          <tr>
            <th scope="col" style={{ width: "26%" }}>
              Sale date
            </th>
            <th scope="col" style={{ width: "28%" }}>
              Grade
            </th>
            <th scope="col" style={{ width: "19%" }}>
              My trade
            </th>
            <th scope="col" className="text-right" style={{ width: "27%" }}>
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          {sales.length
            ? salesToDisplay.map((sale, index) => (
                <tr key={index}>
                  <td className="text-truncate align-baseline">
                    {moment(sale.date).format(
                      new Date(sale.date).getFullYear() === thisYear
                        ? "MMM DD"
                        : "MMM DD, YYYY",
                    )}
                  </td>

                  <td className="align-baseline">
                    {displayNameForGrade(sale.grade)}{" "}
                    {sourceLabels[sale.source] && (
                      <small className="d-inline-block text-whisper">
                        ({sourceLabels[sale.source]})
                      </small>
                    )}
                  </td>

                  <td className="align-baseline">
                    {sale.myRole === "SELLER" ? (
                      <>
                        <FontAwesomeIcon
                          icon={faMinusSquare}
                          className="text-primary mr-1"
                        />
                        Sell
                      </>
                    ) : sale.myRole === "BUYER" ? (
                      <>
                        <FontAwesomeIcon
                          icon={faPlusSquare}
                          className="text-success mr-1"
                        />
                        Buy
                      </>
                    ) : null}
                  </td>

                  <td
                    className="text-nowrap font-weight-bold text-shout align-baseline text-right pl-2"
                    style={{ overflowX: "hidden" }}
                  >
                    ${financial(sale.price)}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </StyledTable>

      {sales.length > pageSize ? (
        <div className="d-flex flex-row">
          {offset > 0 ? (
            <button
              className="btn btn-link p-2 mr-auto"
              onClick={() => setOffset(offset - pageSize)}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
              Newer
            </button>
          ) : null}
          {pageSize + offset < sales.length ? (
            <button
              className="btn btn-link p-2 ml-auto"
              onClick={() => setOffset(offset + pageSize)}
            >
              Older
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </button>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default SalesTable;
