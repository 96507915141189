import { financial } from "./common";
import { PRODUCT_TYPES } from "../api/types";

export const teamName = (team) => (team && team.name) || "No team";

export const abbrTeamName = (team) => (team && team.abbrName) || "No team";

export const positionAndTeamName = (position, team) => {
  const hasValidTeam = team && team.name !== "UNKNOWN";

  if (!hasValidTeam && position === "UNKNOWN") return;

  return hasValidTeam ? `${position} | ${teamName(team)}` : position;
};

export const fullCardFamilyName = (playerCard) =>
  `${playerCard.cardFamily.program.name} ${playerCard.cardFamily.name}`;

export const playerNames = (playerCard) =>
  playerCard.teamPlayers.edges
    .map((teamPlayer) => teamPlayer.node.player.name)
    .join(", ");

export const displayNameForProductType = (productType, plural = false) => {
  // Assume "CARD" to cover legacy uses not yet updated to support boxes
  if (productType === PRODUCT_TYPES.BOX) {
    return plural ? "boxes" : "box";
  } else {
    return plural ? "cards" : "card";
  }
};

export const productQuantityString = (quantity, productType, prefix = null) => {
  const plural = quantity !== 1;
  return !prefix
    ? `${quantity || 0} ${displayNameForProductType(productType, plural)}`
    : `${quantity || 0} ${prefix} ${displayNameForProductType(
        productType,
        plural,
      )}`;
};

export const productName = (cardParallel) =>
  // Assume "CARD" to cover legacy uses not yet updated to support boxes
  cardParallel.productType === PRODUCT_TYPES.BOX
    ? cardParallel.playerCard.cardFamily.program.name
    : playerNames(cardParallel.playerCard);

export const productNameSuffix = (cardParallel) =>
  // Assume "CARD" to cover legacy uses not yet updated to support boxes
  cardParallel.productType === PRODUCT_TYPES.BOX
    ? cardParallel.parallel.name
    : "#" + cardParallel.playerCard.setNumber;

export const cardInfo = (cardParallelName, playerCard, serialLimit) =>
  serialLimit
    ? `#${playerCard.setNumber} - ${cardParallelName} #/${serialLimit}`
    : `#${playerCard.setNumber} - ${cardParallelName}`;

export const detailedCardInfo = (parallelName, playerCard, serialLimit) => {
  const names = playerNames(playerCard);
  return `${names} ${cardInfo(parallelName, playerCard, serialLimit)}`;
};

export const pluralize = (word, count, customPlural) => {
  return customPlural
    ? count === 1
      ? `${count} ${word}`
      : `${count} ${customPlural}`
    : count === 1
    ? `${count} ${word}`
    : `${count} ${word}s`;
};

export const gradeMaxBidPrice = (gradeId, maxBidPriceByGrade) => {
  return gradeId in maxBidPriceByGrade
    ? `$${financial(maxBidPriceByGrade[gradeId])}`
    : "–";
};

export const gradeMinAskPrice = (gradeId, minAskPriceByGrade) => {
  return gradeId in minAskPriceByGrade
    ? `$${financial(minAskPriceByGrade[gradeId])}`
    : "–";
};
