import { useState } from "react";

import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { useQueryState } from "react-router-use-location-state";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GET_CARD_PARALLELS from "../../api/queries/GET_CARD_PARALLELS";
import SEARCH_CARD_PARALLELS from "../../api/queries/SEARCH_CARD_PARALLELS";

import SortControl, { SortControlVariants } from "./SortControl";
import Filters from "./Filters";
import FiltersDrawerButton, {
  FiltersDrawerButtonVariants,
} from "../../components/Filters/FiltersDrawerButton";
import SummaryDataRow, {
  SummaryDataItemWidths,
} from "../../components/SummaryDataRow";
import SearchInput from "../Activity/SearchInput";
import SearchResults from "./SearchResults";

export const sortOptions = [
  {
    key: "featured",
    label: "Featured",
    value: "trending_rank_desc,sell_order_count_desc,buy_order_count_desc,id",
    description: "A mix of the hottest, latest and most traded cards",
  },
  {
    key: "latest-sales",
    label: "Latest sales",
    value: "last_sale_date_desc,id",
    description: "Discover what other users are buying right now",
  },
  {
    key: "sales-last-24-hours",
    label: "Most traded (24 hours)",
    value: "sales_last_24_hours_desc,last_sale_date_desc,id",
    description: "Today’s most frequently traded cards",
  },
  {
    key: "new-arrivals",
    label: "New arrivals",
    value: "first_added_at_desc,id",
    description: "See the latest additions to the StarStock vault",
  },
  {
    key: "latest-listings",
    label: "Latest listings",
    value: "last_listed_at_desc,id",
    description: "Find cards with new listings for sale",
  },
  {
    key: "listing-price-asc",
    label: "Lowest listing price",
    value:
      "sell_order_min_price_asc,trending_rank_desc,sell_order_count_desc,id",
    description: "Seek out bargains with the lowest sale price",
  },
  {
    key: "listing-price-desc",
    label: "Highest listing price",
    value:
      "sell_order_min_price_desc,trending_rank_desc,sell_order_count_desc,id",
    description: "Find cards at the high-end of the market",
  },
  {
    key: "offer-price-desc",
    label: "Best offer price",
    value:
      "buy_order_max_price_desc,trending_rank_desc,buy_order_count_desc,id",
    description: "In-demand cards with high-value offers to buy",
  },
  {
    key: "quantity-available",
    label: "Quantity available",
    value: "sell_order_count_desc,trending_rank_desc,buy_order_count_desc,id",
    description: "Cards with large volumes of stock for sale",
  },
];

const StyledSearchInputPlaceholder = styled.div`
  display: none;

  @media (min-width: 48rem) {
    position: relative;
    line-height: 1.75rem;
    display: block;
  }
`;

const SearchInputPlaceholder = ({
  sport,
  era,
  searchQuery,
  filtersAreApplied,
  resetFilters,
}) => {
  const ResetFiltersButton = () => (
    <button
      onClick={() => resetFilters()}
      className="btn btn-secondary rounded-pill text-speak mx-1 text-uppercase font-weight-bold"
      style={{
        fontSize: "0.875rem",
        padding: "0.125rem 0.5rem",
      }}
    >
      filtered <FontAwesomeIcon icon={faTimes} className="mt-1 ml-1" />
    </button>
  );

  return (
    <StyledSearchInputPlaceholder>
      Showing{" "}
      <span className="text-speak">
        {filtersAreApplied ? <ResetFiltersButton /> : "all "}
        {era} {sport} cards{" "}
        {!searchQuery && !filtersAreApplied && !sport && !era && "in the vault"}
      </span>
      {searchQuery && (
        <>
          matching{" "}
          <span className="text-shout font-weight-bold">{searchQuery}</span>
        </>
      )}
    </StyledSearchInputPlaceholder>
  );
};

const SearchInterface = ({
  sport,
  era,
  searchQuery,
  setSearchQuery,
  showSearchInput = false,
  showSearchInputPlaceholder = false,
  filterDrawerButtonVariant = FiltersDrawerButtonVariants.iconOnly,
  sortControlVariant = SortControlVariants.auto,
}) => {
  // We can't use null as a default useQueryState value, so define an
  // appropriate placeholder and helper to represent a filter being "not set"
  const notSet = "";
  const notSetOrValue = (value) => (value === notSet ? null : value);

  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const [productType, setProductType] = useQueryState("product_type", notSet);

  const [grader, setGrader] = useQueryState("grader", notSet);

  const [minSerialLimit, setMinSerialLimit] = useQueryState(
    "min_serial_limit",
    notSet,
  );
  const [maxSerialLimit, setMaxSerialLimit] = useQueryState(
    "max_serial_limit",
    notSet,
  );
  const [minItems] = useQueryState("min_items", notSet);
  const [maxItems] = useQueryState("max_items", notSet);
  const [minListings, setMinListings] = useQueryState("min_listings", notSet);
  const [maxListings] = useQueryState("max_listings", notSet);
  const [minOffers, setMinOffers] = useQueryState("min_offers", notSet);
  const [maxOffers] = useQueryState("max_offers", notSet);
  const [yearOption, setYearOption] = useQueryState("season", notSet);
  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [cardSet, setCardSet] = useQueryState("brand", notSet);

  const filtersAreApplied = () =>
    notSetOrValue(productType) ||
    notSetOrValue(minItems) ||
    notSetOrValue(maxItems) ||
    notSetOrValue(minListings) ||
    notSetOrValue(maxListings) ||
    notSetOrValue(minOffers) ||
    notSetOrValue(maxOffers) ||
    notSetOrValue(grader) ||
    notSetOrValue(minSerialLimit) ||
    notSetOrValue(maxSerialLimit) ||
    notSetOrValue(yearOption) ||
    notSetOrValue(cardSet);

  const resetFilters = () => {
    setProductType(notSet);
    setGrader(notSet);
    setMinSerialLimit(notSet);
    setMaxSerialLimit(notSet);
    setMinListings(notSet);
    setMinOffers(notSet);
    setYearOption(notSet);
    setStartYear(null);
    setEndYear(null);
    setCardSet(notSet);
  };

  const [sortOrderKey, setSortOrderKey] = useQueryState(
    "sort",
    sortOptions[0].key,
  );
  const sortOrder =
    sortOptions.find((o) => o.key === sortOrderKey) || sortOptions[0];

  let query = SEARCH_CARD_PARALLELS;
  let variables = {
    sport,
    era: notSetOrValue(era),
    query: debouncedSearchQuery,
    productType: notSetOrValue(productType.toUpperCase()),
    grader: notSetOrValue(grader),
    minSerialLimit: notSetOrValue(minSerialLimit),
    maxSerialLimit: notSetOrValue(maxSerialLimit),
    minItems: notSetOrValue(minItems) || 1, // Require 1+ card in vault
    maxItems: notSetOrValue(maxItems),
    minListings: notSetOrValue(minListings),
    maxListings: notSetOrValue(maxListings),
    minOffers: notSetOrValue(minOffers),
    maxOffers: notSetOrValue(maxOffers),
    startYear,
    endYear,
    cardSet,
    orderBy: sortOrder.value,
  };

  // TODO: Replace when we can filter by grade more efficiently (ElasticSearch)
  // We conditionally use different queries here in order to support the grader
  // filter. The newer SEARCH_CARD_PARALLELS is faster but as it precalculates
  // aggregates for _all_ grades it is incompatible with filtering by grades.
  // Instead fall back to older, slower query if a grade filter is set.
  if (notSetOrValue(grader)) {
    query = GET_CARD_PARALLELS;
    variables["searchQuery"] = debouncedSearchQuery;
  }

  const { data, loading, error, fetchMore } = useQuery(query, {
    variables: variables,
  });

  return (
    <div className="row">
      <div className="col sidebar d-none d-sm-none d-md-block mt-4">
        <Filters
          productType={productType}
          setProductType={setProductType}
          minListings={minListings}
          setMinListings={setMinListings}
          minOffers={minOffers}
          setMinOffers={setMinOffers}
          grader={grader}
          setGrader={setGrader}
          minSerialLimit={minSerialLimit}
          setMinSerialLimit={setMinSerialLimit}
          maxSerialLimit={maxSerialLimit}
          setMaxSerialLimit={setMaxSerialLimit}
          yearOption={yearOption}
          setYearOption={setYearOption}
          setStartYear={setStartYear}
          setEndYear={setEndYear}
          cardSet={cardSet}
          setCardSet={setCardSet}
        />
      </div>

      <div className="col mt-4 mb-3">
        {/* SECTION: Search controls */}
        <div className="d-flex flex-row flex-nowrap justify-content-between align-items-baseline mb-3">
          <div className="d-flex flex-row flex-nowrap justify-content-start align-items-baseline flex-fill flex-shrink-1">
            <FiltersDrawerButton
              className="mr-2"
              drawerOpen={showFilterDrawer}
              setDrawerOpen={setShowFilterDrawer}
              filtersAreApplied={filtersAreApplied()}
              resetFilters={resetFilters}
              configVariant={filterDrawerButtonVariant}
            >
              <Filters
                productType={productType}
                setProductType={setProductType}
                minListings={minListings}
                setMinListings={setMinListings}
                minOffers={minOffers}
                setMinOffers={setMinOffers}
                grader={grader}
                setGrader={setGrader}
                minSerialLimit={minSerialLimit}
                setMinSerialLimit={setMinSerialLimit}
                maxSerialLimit={maxSerialLimit}
                setMaxSerialLimit={setMaxSerialLimit}
                yearOption={yearOption}
                setYearOption={setYearOption}
                setStartYear={setStartYear}
                setEndYear={setEndYear}
                cardSet={cardSet}
                setCardSet={setCardSet}
              />
            </FiltersDrawerButton>

            {showSearchInput ? (
              <SearchInput
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                placeholder={"Filter by card, player or team"}
              />
            ) : showSearchInputPlaceholder ? (
              <SearchInputPlaceholder
                sport={sport}
                era={era}
                searchQuery={debouncedSearchQuery}
                filtersAreApplied={filtersAreApplied()}
                resetFilters={resetFilters}
              />
            ) : null}
          </div>

          <SortControl
            className="ml-2"
            sortOptions={sortOptions}
            selectedSortOptionKey={sortOrderKey}
            setSelectedSortOptionKey={(sortKey) =>
              setSortOrderKey(sortKey, { method: "push" })
            }
            sortIsApplied={sortOrderKey !== sortOptions[0].key}
            configVariant={sortControlVariant}
          />
        </div>

        <div className="col-12 col-sm-auto order-3 order-sm-1 d-flex align-items-center px-0 my-3 mt-sm-0">
          {!error && !loading ? (
            <SummaryDataRow
              configValuesFirst
              configAlignItems="justify-content-end"
              configSpacing="1rem"
              summaryDataItems={[
                {
                  label: "Card types",
                  value: (
                    <NumberFormat
                      value={data.cardParallels.totalCount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  ),
                  width: SummaryDataItemWidths.auto,
                },
                {
                  label: "Cards in vault",
                  value: (
                    <NumberFormat
                      value={
                        data.cardParallels.cardParallelStats.totalItemCount
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  ),
                  width: SummaryDataItemWidths.auto,
                },
                {
                  label: "For sale",
                  value: (
                    <NumberFormat
                      value={
                        data.cardParallels.cardParallelStats.totalListingCount
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  ),
                  width: SummaryDataItemWidths.auto,
                },
              ]}
            />
          ) : null}
        </div>

        <div className="row">
          <div className="col mb-3">
            <SearchResults
              error={error}
              loading={loading}
              data={data}
              fetchMore={fetchMore}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInterface;
