import { gql } from "@apollo/client";

const GET_MY_WISHLIST_METADATA = gql`
  query GET_MY_WISHLIST_METADATA {
    myWishlistCards(first: 0) @connection(key: "myWishlistCardsMetadata") {
      totalCount
    }
    myWishlistPlayers(first: 0) @connection(key: "myWishlistPlayersMetadata") {
      totalCount
    }
  }
`;

export default GET_MY_WISHLIST_METADATA;
