import { useEffect } from "react";

import { Formik, Form } from "formik";

import { Modal } from "react-bootstrap";
import { TextField } from "@material-ui/core";

import { object, string } from "yup";

import { financial } from "../../../utils/common";
import { logAmplitudeEvent } from "../../../utils/analytics";
import { MANAGE_COLLECTION_EVENTS } from "../../../utils/analytics/events";

const StepEdit = ({
  formData,
  setFormDataFields,
  draftShippingRequest,
  onBack,
  onNext,
}) => {
  useEffect(() => {
    logAmplitudeEvent(
      MANAGE_COLLECTION_EVENTS.SHIP_HOME.SUBMIT_REQUEST.FORM_VIEWED,
    );
  }, []);

  const handleErrorWithNoField = (formData) => {
    if (formData.errors) {
      return formData.errors.filter(
        (error) => !Object.keys(formData).includes(error.field),
      );
    }
  };

  return (
    <Formik
      initialValues={{ ...formData }}
      validationSchema={object({
        recipient: string()
          .max(100, "Must be less than 100 characters")
          .required("Required")
          .trim(),
        addressLine1: string()
          .max(100, "Must be less than 100 characters")
          .required("Required")
          .trim(),
        addressLine2: string()
          .max(100, "Must be less than 100 characters")
          .trim(),
        city: string()
          .max(100, "Must be less than 100 characters")
          .required("Required")
          .trim(),
        zipCode: string()
          .max(100, "Must be less than 100 characters")
          .required("Required")
          .trim(),
        state: string()
          .max(100, "Must be less than 100 characters")
          .required("Required")
          .trim(),
        country: string()
          .max(100, "Must be less than 100 characters")
          .required("Required")
          .trim(),
      })}
      onSubmit={(values) => {
        setFormDataFields({ ...values });
        onNext();
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <>
          <Modal.Body className="py-0">
            <Form>
              <div className="row bg-light">
                <div className="col p-3 pb-0">
                  <p className="mb-0">
                    Current turnaround times are approximately 1 week for
                    Graded Cards, 1-2 weeks for Sealed Wax, and 1-3 weeks for Raw
                    Cards.
                  </p>
                  {handleErrorWithNoField(formData) && (
                    <div
                      className="alert alert-danger text-center p-4"
                      role="alert"
                    >
                      <h3 className="alert-heading font-weight-bold">
                        There was a problem
                      </h3>
                      {handleErrorWithNoField(formData).map((error, i) => (
                        <p key={i} className="my-1">
                          {error.message}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="container-fluid px-0">
                <div className="py-3">
                  <TextField
                    id="recipient"
                    label="Recipient"
                    value={values.recipient}
                    error={errors.recipient && touched.recipient}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="Who is receiving this?"
                  />
                  <TextField
                    id="address-line-1"
                    name="addressLine1"
                    label="Address Line 1"
                    value={values.addressLine1}
                    error={errors.addressLine1 && touched.addressLine1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="Address line 1"
                  />
                  <TextField
                    id="address-line-2"
                    name="addressLine2"
                    label="Address Line 2"
                    value={values.addressLine2}
                    error={errors.addressLine2 && touched.addressLine2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="Address line 2"
                  />
                  <TextField
                    id="city"
                    name="city"
                    label="City"
                    value={values.city}
                    error={errors.city && touched.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="City"
                  />
                  <TextField
                    id="zip-code"
                    name="zipCode"
                    label="Zip Code"
                    value={values.zipCode}
                    error={errors.zipCode && touched.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="Zip code"
                  />
                  <TextField
                    id="state"
                    name="state"
                    label="State"
                    value={values.state}
                    error={errors.state && touched.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="State"
                  />
                  <TextField
                    id="country"
                    name="country"
                    label="Country"
                    value={values.country}
                    error={errors.country && touched.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="Country"
                  />
                </div>
              </div>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <div className="container-fluid px-0">
              <div className="mb-3">
                <p className="mb-2">
                  The cost for this shipping request is{" "}
                  <span className="font-weight-bold">
                    ${financial(draftShippingRequest.shippingRequestFee)}
                  </span>
                  .
                </p>
                <p>
                  <span className="font-weight-bold">Note:</span> If the
                  delivery is outside the US then further charges may be applied
                  to cover international shipping fees.
                </p>
              </div>
              <Form>
                <div className="d-flex flex-row justify-content-between align-items-stretch">
                  <button
                    className="btn btn-secondary flex-grow btn-block text-body"
                    type="button"
                    onClick={() => {
                      setFormDataFields({ ...values });
                      onBack();
                    }}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-primary btn-block flex-grow font-weight-bold mt-0 ml-3"
                    type="submit"
                    disabled={!draftShippingRequest.shippingRequestFee > 0}
                  >
                    Submit request
                  </button>
                </div>
              </Form>
            </div>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};

export default StepEdit;
