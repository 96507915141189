import { ListGroup } from "react-bootstrap";

const OptionPillsFilter = ({ options, selectedOption, setSelectedValue }) => {
  return (
    <ListGroup
      defaultActiveKey={options[0].label}
      className="d-flex flex-row flex-wrap mx-n1"
    >
      {options.map((option) => (
        <div key={option.label} className="px-1 flex-fill">
          <ListGroup.Item
            action
            active={selectedOption.value === option.value}
            onClick={() => setSelectedValue(option.value)}
            style={{ outline: 0, padding: "0.5rem 0.625rem" }}
            className="btn-sm text-speak rounded-pill text-center mb-2"
          >
            {option.label}
          </ListGroup.Item>
        </div>
      ))}
    </ListGroup>
  );
};

export default OptionPillsFilter;
