import { useQuery } from "@apollo/client";
import { useQueryState } from "react-router-use-location-state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import { financial } from "../../utils/common";
import { pluralize } from "../../utils/content";

import GET_PORTFOLIO_VALUE from "../../api/queries/GET_PORTFOLIO_VALUE";

import ErrorNotice from "../ErrorNotice";
import Loader from "../Loader";
import PortfolioValueChart from "./PortfolioValueChart";
import SummaryDataRow, { SummaryDataItemThemes } from "../SummaryDataRow";
import LabelTooltip, { LabelTooltipVariants } from "../LabelTooltip";

const StyledPortfolioValueContainer = styled.div`
  padding: 1rem 0;

  height: 20rem;
  @media (min-width: 36rem) {
    padding: 1.5rem 0;
    height: 23rem;
  }
  @media (min-width: 48rem) {
    height: 28rem;
  }

  background: linear-gradient(180deg, #2c3848, #386192);
`;

const StyledPortfolioValueLabel = styled.p`
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
`;

const StyledPortfolioValueUnvaluedLabel = styled.small`
  margin-bottom: 0;
  opacity: 0.75;
`;

const PortfolioSummary = ({ latestPortfolioData }) => {
  return (
    <div className="mb-3 mb-lg-4">
      <div className="mb-2">
        <StyledPortfolioValueLabel>
          ${financial(latestPortfolioData.closeValue)}
        </StyledPortfolioValueLabel>
        {latestPortfolioData.closeItemCount > 0 ? (
          latestPortfolioData.closeUnvaluedItemCount > 0 ? (
            <StyledPortfolioValueUnvaluedLabel className="text-warning">
              {`${latestPortfolioData.closeUnvaluedItemCount} of ${pluralize(
                "item",
                latestPortfolioData.closeItemCount,
              )} unvalued`}
            </StyledPortfolioValueUnvaluedLabel>
          ) : (
            <StyledPortfolioValueUnvaluedLabel className="text-success">
              All {latestPortfolioData.closeItemCount} items valued
            </StyledPortfolioValueUnvaluedLabel>
          )
        ) : (
          <StyledPortfolioValueUnvaluedLabel className="text-white-50">
            No items owned
          </StyledPortfolioValueUnvaluedLabel>
        )}
      </div>

      <SummaryDataRow
        configTheme={SummaryDataItemThemes.dark}
        configAlignItems="justify-content-start"
        configSpacing="1rem"
        summaryDataItems={[
          {
            label: "Currently invested",
            labelTooltip: (
              <>
                The combined purchase price of the items currently in your
                collection. Only items purchased on StarStock are reflected
                here. Historic chart values reflect purchase price of items
                owned on that date.
              </>
            ),
            value: `$${financial(latestPortfolioData.closeInvested)}`,
            textAlignment: "text-left",
          },
          {
            label: "Est. unrealised ROI",
            labelTooltip: (
              <>
                The estimated unrealised return on investment (ROI) for your
                currently owned cards, based on their estimated value minus the
                price paid for those items.
              </>
            ),
            valuePrefix: Math.abs(latestPortfolioData.closeRoiValue) > 0 && (
              <FontAwesomeIcon
                icon={
                  latestPortfolioData.closeRoiValue > 0
                    ? faCaretUp
                    : faCaretDown
                }
                style={{
                  color:
                    latestPortfolioData.closeRoiValue > 0
                      ? "#58B55C"
                      : "#A93B3B",
                }}
              />
            ),
            value: `$${financial(Math.abs(latestPortfolioData.closeRoiValue))}`,
            valueSuffix: `(${financial(
              latestPortfolioData.closeRoiPercent * 100,
              1,
            )}%)`,
            textAlignment: "text-left",
          },
        ]}
      />
    </div>
  );
};

const PortfolioValuePopulated = ({ data, timePeriodUnit }) => {
  const { portfolioValue } = data;
  const latestPortfolioData = portfolioValue[portfolioValue.length - 1];

  return (
    <>
      <PortfolioSummary latestPortfolioData={latestPortfolioData} />
      <div
        className="d-flex flex-row justify-content-between text-center"
        // Offset row padding since chart brings in its own
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <PortfolioValueChart
          portfolioValues={data.portfolioValue}
          timePeriodUnit={timePeriodUnit}
        />
      </div>
    </>
  );
};

const StyledTimePeriodButton = styled.button`
  font-size: 0.75rem;
  color: ${(props) => (props.active ? "#ffffffa0" : "#4e8ad4d0")};
  background: transparent;
  border: none;

  &:hover,
  &:active {
    background: ${(props) => (props.active ? "#4e8ad4a0" : "#4e8ad420")};
  }

  background: ${(props) => (props.active ? "#4e8ad460" : "transparent")};
  padding: 0.25rem 0.5rem;
  margin: 0;

  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
`;

const PortfolioValue = ({ sport, era }) => {
  const TimePeriodUnits = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
  };

  const [chartUnit, setChartUnit] = useQueryState(
    "chart_unit",
    TimePeriodUnits.DAY,
  );
  const [chartCount, setChartCount] = useQueryState("chart_count", 32);
  const [chartOffset] = useQueryState("chart_offset", 0);

  const { data, loading, error } = useQuery(GET_PORTFOLIO_VALUE, {
    variables: {
      sport,
      era,
      timePeriodUnit: chartUnit.toUpperCase(),
      // Enforce a maximum of 90 time periods
      // (should be server enforced, but this gives quick protection!)
      timePeriodCount: Math.min(chartCount, 90),
      timePeriodOffset: -Math.abs(chartOffset),
    },
  });

  const TimePeriodButton = ({
    label,
    timePeriodUnit,
    timePeriodCount,
    active,
  }) => (
    <StyledTimePeriodButton
      className="rounded"
      active={active}
      onClick={() => {
        setChartUnit(timePeriodUnit);
        setChartCount(timePeriodCount);
      }}
    >
      {label}
    </StyledTimePeriodButton>
  );

  return (
    <StyledPortfolioValueContainer className="row">
      <div className="col inner-container-wide mx-auto">
        <div className="d-flex flex-row justify-content-between align-items-baseline">
          <h6
            className="text-white mb-0"
            style={{ fontSize: "0.875rem", opacity: 0.6 }}
          >
            {loading ? (
              "Analyzing portfolio…"
            ) : (
              <>
                Est. portfolio value
                <LabelTooltip
                  className="ml-1"
                  tooltipContent={
                    <>
                      Estimated value of your current collection of items, based
                      on the latest sale price of items of the same type and
                      grade. If we don‘t have a sale price for some items in
                      your collection, we will specify how many are unvalued.
                      The value includes purchased and submitted cards. Historic
                      chart values reflect value of items owned on that date.
                    </>
                  }
                  variant={LabelTooltipVariants.dark}
                />
              </>
            )}
          </h6>

          <div className="d-flex flex-row justify-content-end">
            <TimePeriodButton
              label={"1MO"}
              timePeriodUnit={TimePeriodUnits.DAY}
              // 32 day gives same-day-last-month range (e.g. Apr 1st > May 1st)
              // plus current today-in-progress
              timePeriodCount={32}
              active={chartUnit === TimePeriodUnits.DAY}
            />
            <TimePeriodButton
              label={"6MO"}
              timePeriodUnit={TimePeriodUnits.WEEK}
              // 27 weeks covers just 6 months, plus current week-in-progress
              timePeriodCount={27}
              active={chartUnit === TimePeriodUnits.WEEK}
            />
            <TimePeriodButton
              label={"1YR"}
              timePeriodUnit={TimePeriodUnits.MONTH}
              // 14 month gives same month last year (e.g. Apr 2020 to Apr 2021)
              // plus current month-in-progress
              timePeriodCount={14}
              active={chartUnit === TimePeriodUnits.MONTH}
            />
          </div>
        </div>

        {loading ? (
          <Loader
            className="d-flex h-100 align-items-center justify-content-center"
            message=""
          />
        ) : error ? (
          <ErrorNotice />
        ) : data.portfolioValue.length === 0 ? (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <h4 className="text-center text-white-50 mb-0">
              No collection data for this sport / era
            </h4>
          </div>
        ) : (
          <PortfolioValuePopulated data={data} timePeriodUnit={chartUnit} />
        )}
      </div>
    </StyledPortfolioValueContainer>
  );
};

export default PortfolioValue;
