import { Navbar } from "react-bootstrap";
import styled, { css } from "styled-components";

import logo from "../images/logo.svg";

import PrimaryNav from "./NavBar/PrimaryNav";
import AccountDropdown from "./NavBar/AccountDropdown";

export const NavBarModes = {
  compact: "compact",
  regular: "regular",
};

const StyledNavbar = styled(Navbar)`
  z-index: 100;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  background: white;
  border-bottom: 1px solid #00000020;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 32, 0.1);

  height: 4rem;
  padding: 0;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 48rem) {
    height: 5rem;
  }

  ${(props) =>
    props.mode === NavBarModes.regular &&
    css`
      @media (min-width: 80rem) {
        height: 6rem;

        // Drop navbar items to sit along baseline of the taller navbar
        align-items: flex-end;
        padding: 0rem 0rem 0.5rem 0rem;
      }
    `}
`;

const StyledNavbarBrand = styled.img`
  width: auto;
  height: 3rem;
  margin: 0.2rem 1rem 0.3rem 1rem;

  @media (min-width: 48rem) {
    height: 3rem;

    // Slightly more padding below to center logo text
    margin: 0.4rem 1rem 0.85rem 1rem;
  }

  ${(props) =>
    props.mode === NavBarModes.regular &&
    css`
      @media (min-width: 80rem) {
        height: 6rem;

        // Negative margin to let logo tail overlap navbar baseline
        margin: 0 1rem -1rem 1rem;

        // Let left-aligned logo claim space and push navbar content to right
        flex-grow: 1;
      }
    `}
`;

const NavBar = ({ mode = NavBarModes.regular }) => {
  return (
    <StyledNavbar expand="lg" mode={mode}>
      <div className="order-lg-1">
        <PrimaryNav />
      </div>

      <div className="order-lg-0 flex-grow-0 flex-lg-grow-1">
        <a href="/">
          <StyledNavbarBrand src={logo} alt="StarStock logo" mode={mode} />
        </a>
      </div>

      <div className="order-lg-2">
        <AccountDropdown />
      </div>
    </StyledNavbar>
  );
};

export default NavBar;
