import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

import { TimeBadge } from "../../../../components/TimeBadge";

export const HeroImageDiv = styled.div`
  background: ${Colors.Porcelain};
  margin: -2rem -1rem 0;
  position: relative;

  @media (min-width: 48rem) {
    margin: 0;
  }
`;

export const ThumbnailList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0.5rem 0 0;
  padding: 0;
`;

export const ThumbnailListItem = styled.li`
  background: ${Colors.Porcelain};
  height: 6rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  width: 6rem;

  :last-child {
    margin-bottom: 0;
    margin-right: 0;
  }
`;

export const ThumbnailImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const StyledTimeBadge = styled(TimeBadge)`
  margin: 1rem;
  position: absolute;
  z-index: 3;
`;
