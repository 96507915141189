import { useEffect } from "react";

import { useQueryState } from "react-router-use-location-state";
import { useMutation, useQuery } from "@apollo/client";

import { pluralize } from "../../utils/content";
import { logAmplitudeEvent } from "../../utils/analytics";
import { WATCHLIST_EVENTS } from "../../utils/analytics/events";

import GET_MY_WISHLIST_CARDS from "../../api/queries/GET_MY_WISHLIST_CARDS";
import DELETE_WISHLIST_ITEM from "../../api/mutations/DELETE_WISHLIST_ITEM";

import CardResults from "./CardResults";
import Loader from "../Loader";
import ErrorNotice from "../ErrorNotice";
import SortControl, { SortControlVariants } from "../Marketplace/SortControl";
import { sortOptions as marketSortOptions } from "../Marketplace/SearchInterface";

const sortOptions = [
  {
    key: "recently-watched",
    label: "Recently watched",
    value: "", // Default API sort order
    description: "The cards you most recently added to your watchlist",
  },
].concat(marketSortOptions);

const CardSection = () => {
  useEffect(() => {
    logAmplitudeEvent(WATCHLIST_EVENTS.CARDS.PAGE_VIEWED);
  }, []);

  const [sortOrderKey, setSortOrderKey] = useQueryState(
    "sort",
    sortOptions[0].key,
  );
  const sortOrder =
    sortOptions.find((o) => o.key === sortOrderKey) || sortOptions[0];

  const { data, loading, error, fetchMore } = useQuery(GET_MY_WISHLIST_CARDS, {
    variables: { orderBy: sortOrder.value },
  });

  const [deleteWishlistItem] = useMutation(DELETE_WISHLIST_ITEM, {
    onCompleted({ _response }) {
      logAmplitudeEvent(WATCHLIST_EVENTS.CARDS.CARD_REMOVED);
    },
    update: (cache, { data: { wishlistItemDelete } }) => {
      if (wishlistItemDelete.wishlistItem === null) return;

      // Apollo caches data for each sort order our query might have used.
      // We'll remove the deleted item from each one of these caches, if present.
      sortOptions.forEach((sortOption) => {
        // Try to load existing cached data for this query
        const cachedData = (() => {
          try {
            return cache.readQuery({
              query: GET_MY_WISHLIST_CARDS,
              variables: { orderBy: sortOption.value },
            });
          } catch (e) {
            return null;
          }
        })();
        if (!cachedData) return;

        // Create a copy of the cached data to avoid in-memory edit
        const cachedDataCopy = JSON.parse(JSON.stringify(cachedData));
        const { myWishlistCards } = cachedDataCopy;

        // Modify the cached data to reflect the deleted item
        myWishlistCards.totalCount -= 1;
        myWishlistCards.edges = myWishlistCards.edges.filter(
          ({ node: myWishlistCard }) =>
            myWishlistCard.myWishlistItems[0].id !==
            wishlistItemDelete.wishlistItem.id,
        );

        // Write the edits back out to the cache
        cache.writeQuery({
          query: GET_MY_WISHLIST_CARDS,
          variables: { orderBy: sortOption.value },
          data: cachedDataCopy,
        });
      });
    },
  });

  return (
    <div className="mt-4 mb-3">
      <div className="d-flex flex-row justify-content-between align-items-baseline mb-3">
        <span style={{ fontSize: "0.875rem" }}>
          {!error && !loading
            ? `${pluralize("card", data.myWishlistCards.totalCount)} watched`
            : null}
        </span>

        <SortControl
          sortOptions={sortOptions}
          selectedSortOptionKey={sortOrderKey}
          setSelectedSortOptionKey={(sortKey) => {
            logAmplitudeEvent(WATCHLIST_EVENTS.CARDS.SEARCH_UPDATED, {
              "Sort Order": sortKey,
            });
            setSortOrderKey(sortKey, { method: "push" });
          }}
          sortIsApplied={sortOrderKey !== sortOptions[0].key}
          configVariant={SortControlVariants.full}
        />
      </div>

      {error ? (
        <ErrorNotice error={error} />
      ) : loading ? (
        <Loader />
      ) : (
        <CardResults
          cardParallels={data.myWishlistCards}
          deleteWishlistItem={deleteWishlistItem}
          fetchMore={fetchMore}
        />
      )}
    </div>
  );
};

export default CardSection;
