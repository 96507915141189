import { useQuery } from "@apollo/client";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Avatar from "@material-ui/core/Avatar";
import styled from "styled-components";

import IS_LOGGED_IN from "../../api/queries/IS_LOGGED_IN";

import AccountMenu from "../AccountMenu";

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  :after {
    display: none;
  }
`;

const AccountDropdown = () => {
  const { data: loginData } = useQuery(IS_LOGGED_IN);

  const icon =
    loginData && loginData.isLoggedIn ? (
      <Avatar
        style={{ width: "2rem", height: "2rem", background: "#f6c144" }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faUserCircle}
        className="fa-2x align-text-top text-whisper"
      />
    );

  return (
    <Dropdown id="nav-account-dropdown">
      <StyledDropdownToggle
        id="nav-account-dropdown-toggle"
        variant="none"
        className="border-left rounded-0 px-3 px-md-4"
      >
        {icon}
      </StyledDropdownToggle>

      <Dropdown.Menu
        alignRight
        id="nav-account-dropdown-menu"
        className="bg-light shadow overflow-auto mr-2"
        style={{
          minWidth: "18rem",
          maxHeight: "80vh",
        }}
      >
        <AccountMenu />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AccountDropdown;
