import MarketIndexWinnersAndLosersWidget from "./MarketIndexWinnersAndLosersWidget";

const slug = "mlb-emerging-20";

const MLBEmerging20Widget = () => {
  return (
    <MarketIndexWinnersAndLosersWidget
      slug={slug}
      title={
        <div className="d-flex flex-row align-items-top">
          ⚾ MLB Emerging 20
        </div>
      }
      subtitle={
        <>
          Our MLB index, tracking{" "}
          <a
            href={`/marketplace/indices/${slug}`}
            className="text-primary font-weight-bold"
          >
            Topps RC
          </a>{" "}
          (PSA 10) cards for 20 top MLB prospects from the 2017-2020 rookie
          classes
        </>
      }
    />
  );
};

export default MLBEmerging20Widget;
