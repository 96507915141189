import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { Colors } from "../../themes/Default";

export const Container = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  & + & {
    margin-top: 1rem;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
`;

export const Description = styled(ReactMarkdown)`
  color: ${Colors.Astronaut};
  margin-bottom: 1rem;
  opacity: 0.8;
`;

export const Heading = styled.h1`
  color: ${Colors.Astronaut};
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;
