import styled from "styled-components";
import BillboardChart from "react-billboardjs";
import "billboard.js/dist/billboard.css";
import "./IndicesChart/billboard-overrides.css";

import { financial } from "../../utils/common";

const ChartContainer = styled.div`
  position: relative;
  width: 100%;

  height: 16rem;
  @media (min-width: 992px) {
    height: 16rem;
  }
`;

const groupSnapshotsByIndexSymbol = (indices) => {
  let groupedSnapshotsByIndexSymbol = {};
  indices.forEach(
    (index) =>
      (groupedSnapshotsByIndexSymbol[index.symbol] = index.snapshots.edges.map(
        ({ node }) => node,
      )),
  );
  return groupedSnapshotsByIndexSymbol;
};

const IndicesChart = ({ indices }) => {
  // Charts are currently using a package called Billboard.js.
  // Examples: https://naver.github.io/billboard.js/demo/
  // API docs: https://naver.github.io/billboard.js/release/latest/doc/index.html

  // Transform our index data into the format Billboard charts requires.
  // Billboard requires X values and Y values in distinct arrays, with a map
  // defining how they pair up.
  const snapshotsByIndexSymbol = groupSnapshotsByIndexSymbol(indices);
  const indexSymbols = Object.keys(snapshotsByIndexSymbol);

  // Build array of normalised value for each index, prefixed by a name
  const normalisedValuesForIndices = indexSymbols.map((indexSymbol) =>
    [indexSymbol].concat(
      snapshotsByIndexSymbol[indexSymbol].map(
        (snapshot) => snapshot.normalisedValue,
      ),
    ),
  );

  // Build array of snapshot dates for each index, prefixed by a name
  const datesSuffix = "dates";
  const datesForIndices = indexSymbols.map((indexSymbol) =>
    [`${indexSymbol} ${datesSuffix}`].concat(
      snapshotsByIndexSymbol[indexSymbol].map(
        (snapshot) => new Date(snapshot.date),
      ),
    ),
  );

  // Map index symbols to their associated date (X value) arrays
  const indexSymbolsToDatesMap = Object.fromEntries(
    indexSymbols.map((indexSymbol) => [
      indexSymbol,
      `${indexSymbol} ${datesSuffix}`,
    ]),
  );

  // Map index symbols to a y axis (all use the y2 (right hand side))
  const indexSymbolsToYAxisMap = Object.fromEntries(
    indexSymbols.map((indexSymbol) => [indexSymbol, "y2"]),
  );

  // Determine the highest/lowest value we need to display on the chart
  const allValues = Object.values(normalisedValuesForIndices)
    .map((normalisedValuesForIndex) => normalisedValuesForIndex.slice(1))
    .flat();
  const lowestValue = allValues.reduce(function (prev, curr) {
    return curr < prev ? curr : prev;
  });
  const highestValue = allValues.reduce(function (prev, curr) {
    return curr > prev ? curr : prev;
  });

  // Determine the earliest date we need to display on the chart
  const allDates = datesForIndices
    .map((datesForIndex) => datesForIndex.slice(1))
    .flat();
  const earliestDate = allDates.reduce(function (prev, curr) {
    return curr < prev ? curr : prev;
  });
  const latestDate = allDates.reduce(function (prev, curr) {
    return curr > prev ? curr : prev;
  });

  const dateRange = {
    min: Math.min(earliestDate, new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
    max: latestDate,
  };

  return (
    <ChartContainer>
      <BillboardChart
        className="position-absolute w-100 h-100"
        style={{ top: 0, left: 0 }}
        data={{
          columns: normalisedValuesForIndices.concat(datesForIndices),
          xs: indexSymbolsToDatesMap,
          type: "area",
          axes: indexSymbolsToYAxisMap,
        }}
        axis={{
          x: {
            type: "timeseries",
            min: dateRange["min"],
            max: dateRange["max"],
            tick: {
              fit: true,
              rotate: 30,
              format: function (x) {
                return x.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                });
              },
            },
          },
          // Use the right-hand axis rather than left
          y: { show: false },
          y2: {
            show: true,
            min: lowestValue - 200,
            max: highestValue + 200,
            padding: {
              top: 0,
              bottom: 0,
            },
            tick: {
              format: function (value) {
                return `${financial(value)}`;
              },
            },
          },
        }}
        grid={{
          y: { show: true },
          focus: { y: true },
        }}
        legend={{
          show: false,
        }}
        tooltip={{
          grouped: false,
          format: {
            value: function (value) {
              return `${financial(value)}`;
            },
          },
        }}
        point={{ r: 4, type: "circle" }}
        area={{
          linearGradient: {
            y: [0.2, 1.2],
          },
        }}
      />
    </ChartContainer>
  );
};

export default IndicesChart;
