import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import Loader from "../../Loader";

import GET_MY_DRAFT_SHIPPING_REQUEST from "../../../api/queries/GET_MY_DRAFT_SHIPPING_REQUEST";

const StepLoading = ({ setDraftShippingRequest, onLoaded, onFailed }) => {
  const { data, loading, error } = useQuery(GET_MY_DRAFT_SHIPPING_REQUEST, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (error) {
      onFailed(error);
    } else if (data) {
      setDraftShippingRequest(data.myDraftShippingRequest);
      onLoaded(data);
    }
  });

  return loading ? <Loader message={"Loading your draft request..."} /> : <></>;
};

export default StepLoading;
