import _ from "lodash";

import GET_MY_COLLECTION from "../api/queries/GET_MY_COLLECTION";
import GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION from "../api/queries/GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION";

import { copyQueryCache, writeQueryCache } from "./cache";

// Refetching helper functions:

const extractVariablesFromRootQueryString = (query) => {
  return JSON.parse(query.split("(")[1].split(")")[0]);
};

const accessCachedQueryVariables = (cache, currentVariables) => {
  const rootVariables = [];
  if (cache.data.data.ROOT_QUERY) {
    const rootQueryKeys = Object.keys(cache.data.data.ROOT_QUERY);
    rootQueryKeys.forEach((query) => {
      if (query.includes("myCollection" || "myInDraftShippingRequest")) {
        const variables = extractVariablesFromRootQueryString(query);
        // eslint-disable-next-line no-prototype-builtins
        if (variables.hasOwnProperty("first")) {
          delete variables.first;
        }
        if (!_.some([...rootVariables, currentVariables], variables)) {
          rootVariables.push(variables);
        }
      }
    });
  }
  return rootVariables;
};

export const returnRefetchOptions = (
  cache,
  collectionQuery,
  draftCollectionQuery,
  currentVariables,
) => {
  const variables = accessCachedQueryVariables(cache, currentVariables);
  const queriesList = [];

  variables.forEach((variableObject) => {
    queriesList.push({
      query: collectionQuery,
      variables: variableObject,
    });
    queriesList.push({
      query: draftCollectionQuery,
      variables: variableObject,
    });
  });
  return queriesList;
};

// Cache reading helper functions:

const copyCollectionCache = (cache, variables) => {
  return copyQueryCache(cache, GET_MY_COLLECTION, variables);
};

const copyDraftCache = (cache, variables) => {
  return copyQueryCache(
    cache,
    GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION,
    variables,
  );
};

// Cache writing helper functions:

const writeCollectionCache = (cache, variables, dataToWrite) => {
  writeQueryCache(cache, GET_MY_COLLECTION, variables, dataToWrite);
};

const writeDraftCache = (cache, variables, dataToWrite) => {
  writeQueryCache(
    cache,
    GET_MY_IN_DRAFT_SHIPPING_REQUEST_COLLECTION,
    variables,
    dataToWrite,
  );
};

// Cache modification helper functions:

const updateSelectedCollectionItemInDraftState = (
  itemIds,
  collection,
  state,
) => {
  itemIds.forEach((itemId) => {
    const itemOwnershipToUpdate = collection?.edges.find(
      ({ node }) => node.item.id === itemId,
    );
    if (
      itemOwnershipToUpdate &&
      itemOwnershipToUpdate.inDraftShippingRequest !== state
    ) {
      itemOwnershipToUpdate.node.inDraftShippingRequest = state;
    }
  });
};

const addItemsToDraftCache = (
  addedItems,
  collectionCopy,
  draftCollectionCopy,
) => {
  addedItems.forEach((itemId) => {
    const correspondingOwnership = collectionCopy?.edges.find(
      ({ node }) => node.item.id === itemId,
    );
    if (
      correspondingOwnership &&
      draftCollectionCopy &&
      draftCollectionCopy.edges
    ) {
      delete correspondingOwnership.inDraftShippingRequest;
      if (!_.some(draftCollectionCopy.edges, correspondingOwnership)) {
        draftCollectionCopy.edges.push(correspondingOwnership);
      }
    }
  });
};

const bulkUpdateDraftCollection = (
  inDraftShippingRequest,
  state,
  collectionCopy,
) => {
  if (
    inDraftShippingRequest &&
    inDraftShippingRequest?.edges &&
    collectionCopy &&
    collectionCopy?.edges
  ) {
    if (!state) {
      inDraftShippingRequest.edges = [];
    } else {
      collectionCopy.edges.map(
        ({ node }) => delete node.inDraftShippingRequest,
      );
      inDraftShippingRequest.edges = collectionCopy.edges;
    }
  }
};

const removeItemsFromDraftCache = (removedItemIds, draftCollectionCopy) => {
  if (draftCollectionCopy && draftCollectionCopy.edges) {
    draftCollectionCopy.edges = draftCollectionCopy?.edges.filter(
      ({ node }) => !removedItemIds.includes(node.item.id),
    );
  }
};

export const addItemsToCaches = (cache, response, variables) => {
  const addedItems = response?.addItemsToDraftShippingRequest?.addedItems;

  // Read collection cache and return a deep copy.
  const cachedCollectionCopy = copyCollectionCache(cache, variables);

  // Access the myCollection list of edges.
  const myCollection = cachedCollectionCopy?.myCollection;

  // Modify the state of the inDraftShippingRequest property of the approriate items to true as this is an add.
  updateSelectedCollectionItemInDraftState(addedItems, myCollection, true);

  // Overwrite the cached data with the modified results.
  writeCollectionCache(cache, variables, cachedCollectionCopy);

  // Read the draft collection and return a deep copy.
  const cachedDraftCopy = copyDraftCache(cache, variables);

  // Access the myInDraftShippingRequest list of edges.
  const myInDraftShippingRequestCollection =
    cachedDraftCopy?.myInDraftShippingRequestCollection;

  if (myCollection) {
    // Deep copy of the updated collection.
    const collectionCopy = JSON.parse(JSON.stringify(myCollection));
    // Find the items in the updated collection and add them to the draft cache.
    addItemsToDraftCache(
      addedItems,
      collectionCopy,
      myInDraftShippingRequestCollection,
    );
  }

  // Overwrite the cached data with the modified results.
  writeDraftCache(cache, variables, cachedDraftCopy);
};

export const removeItemsFromCaches = (cache, response, variables) => {
  const removedItems =
    response?.removeItemsFromDraftShippingRequest?.removedItems;

  // Read collection cache and return a deep copy.
  const cachedCollectionCopy = copyCollectionCache(cache, variables);

  // Access the myCollection list of edges.
  const myCollection = cachedCollectionCopy?.myCollection;

  // Modify the state of the inDraftShippingRequest property of the approriate items to true as this is an add.
  updateSelectedCollectionItemInDraftState(removedItems, myCollection, false);

  // Overwrite the cached data with the modified results.
  writeCollectionCache(cache, variables, cachedCollectionCopy);

  // Read the draft collection and return a deep copy.
  const cachedDraftCopy = copyDraftCache(cache, variables);

  // Access the myInDraftShippingRequest list of edges.
  const myInDraftShippingRequestCollection =
    cachedDraftCopy?.myInDraftShippingRequestCollection;

  removeItemsFromDraftCache(removedItems, myInDraftShippingRequestCollection);

  // Overwrite the cached data with the modified results.
  writeDraftCache(cache, variables, cachedDraftCopy);
};

export const updateCollectionCacheInDraftStatus = (cache, variables, state) => {
  // Read collection cache and return a deep copy.
  const cachedCollectionCopy = copyCollectionCache(cache, variables);

  // Access the myCollection list of edges.
  const myCollection = cachedCollectionCopy?.myCollection;

  if (myCollection && myCollection?.edges) {
    myCollection.edges.forEach(({ node }) => {
      node.inDraftShippingRequest = state;
    });

    // Overwrite the cached data with the modified results.
    writeCollectionCache(cache, variables, cachedCollectionCopy);

    // Read the draft collection and return a deep copy.
    const cachedDraftCopy = copyDraftCache(cache, variables);

    // Access the myInDraftShippingRequest list of edges.
    const inDraftShippingRequest =
      cachedDraftCopy?.myInDraftShippingRequestCollection;

    // Deep copy of the updated collection.
    if (myCollection) {
      const collectionCopy = JSON.parse(JSON.stringify(myCollection));
      // Bulk update the draft collection.
      bulkUpdateDraftCollection(inDraftShippingRequest, state, collectionCopy);
    }

    // Overwrite the cached data with the modified results.
    writeDraftCache(cache, variables, cachedDraftCopy);
  }
};

export const sortOptions = [
  {
    key: "newest",
    label: "Newest cards",
    value: "newest,id",
    description: "The latest cards added to your collection",
  },
  {
    key: "oldest",
    label: "Oldest cards",
    value: "oldest,id",
    description: "The oldest cards in your collection",
  },
];
