import { useEffect, useState } from "react";

import { useInterval } from "../../../../hooks/useInterval";
import { timeRemaining } from "../../../../utils/timeRemaining";

import { Label } from "./styles";

interface Props {
  className?: string;
  endTime: Date;
}

export const Countdown = ({ className, endTime }: Props) => {
  const [timeLeft, setTimeLeft] = useState(timeRemaining(endTime));
  const noTimeLeft =
    timeLeft.seconds <= 0 && timeLeft.minutes <= 0 && timeLeft.hours <= 0;

  useEffect(() => setTimeLeft(timeRemaining(endTime)), [endTime]);

  useInterval(
    () => setTimeLeft(timeRemaining(endTime)),
    noTimeLeft ? null : 1000,
  );

  if (noTimeLeft) return null;

  const { hours, minutes, seconds } = timeLeft;

  return (
    <Label aria-label="Time left on lot" className={className} role="timer">
      {hours > 0 && `${hours}h `}
      {minutes > 0 && `${String(minutes).padStart(hours ? 2 : 1, "0")}m `}
      {`${String(seconds).padStart(2, "0")}s`}
    </Label>
  );
};
