import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NavBar = ({ categoryKey, groupedBidsCount, groupedListingsCount }) => {
  const navigate = useNavigate();

  return (
    <Nav
      activeKey={categoryKey}
      className="secondary-nav-light bg-white justify-content-start no-gutters"
    >
      <Nav.Item>
        <Nav.Link
          eventKey="TRADE_BIDS"
          onSelect={() => navigate("/activity/me/bids")}
        >
          <span className="font-weight-bold">Offers</span>
          {groupedBidsCount > 0 && (
            <span className="badge badge-secondary ml-2">
              {groupedBidsCount}
            </span>
          )}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          eventKey="TRADE_LISTINGS"
          onSelect={() => navigate("/activity/me/listings")}
        >
          <span className="font-weight-bold">
            Listings
            {groupedListingsCount > 0 && (
              <span className="badge badge-secondary ml-2">
                {groupedListingsCount}
              </span>
            )}
          </span>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          eventKey="BOUGHT_ITEMS"
          onSelect={() => navigate("/activity/me/bought")}
        >
          <span className="font-weight-bold">Bought</span>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          eventKey="SOLD_ITEMS"
          onSelect={() => navigate("/activity/me/sold")}
        >
          <span className="font-weight-bold">Sold</span>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default NavBar;
