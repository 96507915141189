import { useState } from "react";

import { BreakCard } from "../../features/breaks";

import BreakList from "./BreakList";
import EmptySection from "./EmptySection";
import LoadMoreButton from "../LoadMoreButton";
import ErrorNotice from "../ErrorNotice";
import Loader from "../Loader";

const BreakListContainer = ({ error, loading, data, fetchMore }) => {
  const [loadingMore, setLoadingMore] = useState(false);

  if (error) return <ErrorNotice error={error} />;
  if (loading) return <Loader />;

  return (
    <>
      {data.boxBreaks.edges.length ? (
        <BreakList>
          {data.boxBreaks.edges.map(({ node: boxBreak }) => (
            <BreakCard boxBreak={boxBreak} key={boxBreak.id} />
          ))}
        </BreakList>
      ) : (
        <EmptySection />
      )}

      {data.boxBreaks.pageInfo.hasNextPage && (
        <LoadMoreButton
          loadingMore={loadingMore}
          setLoadingMore={setLoadingMore}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: data.boxBreaks.pageInfo.endCursor,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                setLoadingMore(false);
                if (!fetchMoreResult) return prev;

                fetchMoreResult.boxBreaks.edges = [
                  ...prev.boxBreaks.edges,
                  ...fetchMoreResult.boxBreaks.edges,
                ];
                return fetchMoreResult;
              },
            })
          }
        />
      )}
    </>
  );
};

export default BreakListContainer;
