import styled from "styled-components";

import placeholder from "../images/placeholder.png";
import { displayNameForGrade } from "../utils/grades";

const BorderColor = "rgba(0, 0, 0, 0.1)";
const ShadowColor = "rgba(44, 77, 118, 0.1)";

const StyledItemListing = styled.div`
  margin-top: 1rem;
  margin-right: 1rem;

  @media (max-width: 26rem) {
    margin-top: 0.875rem;
    margin-right: 0.875rem;
  }
`;

const StyledImage = styled.img`
  width: 100px;
  height: 140px;
  margin-bottom: 0.5rem;
  border: 1px solid ${BorderColor};
  box-shadow: 0 0.125rem 0.25rem, ${ShadowColor};

  @media (max-width: 26rem) {
    width: 80px;
    height: 120px;
  }
`;

const ItemListing = ({ item, image }) => {
  const imageSrc = image || placeholder;

  return (
    <StyledItemListing>
      <StyledImage src={imageSrc} alt="Product frontside" />

      <p className="text-center mb-0">{displayNameForGrade(item.grade)}</p>
    </StyledItemListing>
  );
};

export default ItemListing;
