import { gql } from "@apollo/client";

const BUY_ITEMS = gql`
  mutation buyItems(
    $cardParallelId: ID!
    $gradeId: ID!
    $maximumBuyPrice: Float!
    $quantity: Int!
    $cancelBidsPriced: Float
    $affiliateCode: String
    $expiresAfter: ExpiresAfter
  ) {
    buyItems(
      cardParallelId: $cardParallelId
      gradeId: $gradeId
      maximumBuyPrice: $maximumBuyPrice
      quantity: $quantity
      cancelBidsPriced: $cancelBidsPriced
      affiliateCode: $affiliateCode
      expiresAfter: $expiresAfter
    ) {
      boughtCount
      listedCount
      errors {
        field
        message
      }
    }
  }
`;

export default BUY_ITEMS;
