import { useEffect } from "react";

import OptionPillsFilter from "./OptionPillsFilter";

const YearFilter = ({
  yearOption,
  setYearOption,
  setStartYear,
  setEndYear,
}) => {
  useEffect(() => {
    const years = yearOption ? yearOption.split("-") : [];
    const startYear = years.length > 0 ? years[0] : null;
    const endYear = years.length > 1 ? years[1] : startYear;

    setStartYear(startYear);
    setEndYear(endYear);
  }, [yearOption, setStartYear, setEndYear]);

  const options = [
    { label: "All years", value: null },
    { label: "2021", value: "2021" },
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
    { label: "2017", value: "2017" },
    { label: "2016", value: "2016" },
    { label: "2010-15", value: "2010-2015" },
    { label: "2000s", value: "2000-2009" },
    { label: "1990s", value: "1990-1999" },
    { label: "1980s", value: "1980-1989" },
    { label: "1970s", value: "1970-1979" },
    { label: "1960s", value: "1960-1969" },
    { label: "1950s", value: "1950-1959" },
  ];

  const selectedOption =
    options.find((option) => option.value === yearOption) || options[0];

  return (
    <div className="mb-4">
      <h6>Release year</h6>
      <hr className="mt-1" />

      <OptionPillsFilter
        options={options}
        selectedOption={selectedOption}
        setSelectedValue={setYearOption}
      />
    </div>
  );
};

export default YearFilter;
