import { rgba } from "polished";
import styled, { css } from "styled-components";

import { PRODUCT_TYPES } from "../api/types";
import placeholderBox from "../images/placeholder-box.png";
import placeholderCard from "../images/placeholder-card.png";
import { slabImageForGrade } from "../utils/grades";

import LazyLoadImage from "./LazyLoadImage";

const Astronaut = "#2c4d76";
const ShadowColor = rgba(Astronaut, 0.1);
const BorderColor = rgba(Astronaut, 0.1);

const StyledSlabContainer = styled.div`
  position: relative;
  background: url(${(props) => props.slabImage}) no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;

  // Appropriate rounding to match slab graphic so shadow sits properly
  border-radius: 2.5%;
  box-shadow: 0 0.25rem 0.5rem ${ShadowColor};

  // Magic numbers to sit card in correct position of slab graphic
  padding-top: 31%;
  padding-bottom: 8%;
  padding-left: 13%;
  padding-right: 13%;
`;

// Necessary hack to position ::after pseudoelement above img in most browsers
// (see: https://impossiblue.github.io/log/170326/index.html)
const StyledProductPseudoWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.productType === PRODUCT_TYPES.CARD &&
    css`
      box-shadow: 0 0.25rem 0.5rem ${ShadowColor};

      ::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid ${BorderColor};
        pointer-events: none;
      }
    `}
`;

const CardParallelProductPhoto = ({
  productType,
  image,
  grade,
  overflow = false,
  scrollContainer = null,
}) => {
  const slabImage = slabImageForGrade(grade);
  const placeholder =
    productType === PRODUCT_TYPES.BOX ? placeholderBox : placeholderCard;

  const productPhoto = (
    <StyledProductPseudoWrapper productType={productType}>
      <LazyLoadImage
        src={image || placeholder}
        placeholder={placeholder}
        alt=""
        scrollContainer={scrollContainer}
        overflow={overflow}
      />
    </StyledProductPseudoWrapper>
  );

  return slabImage ? (
    <StyledSlabContainer slabImage={slabImage}>
      {productPhoto}
    </StyledSlabContainer>
  ) : (
    productPhoto
  );
};

export default CardParallelProductPhoto;
