import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import styled from "styled-components";

import { logAmplitudeEvent } from "../utils/analytics";
import { MARKETPLACE_EVENTS } from "../utils/analytics/events";
import { pathWithSearch } from "../utils/common";

import background from "../images/background.png";

import NavBar from "../components/Marketplace/NavBar";
import SearchInterface from "../components/Marketplace/SearchInterface";

const HeaderContainer = styled.div`
  background: linear-gradient(
      180deg,
      rgba(11, 31, 60, 0.8) 0%,
      rgba(11, 31, 60, 0.33) 33%,
      rgba(11, 31, 60, 0.33) 75%,
      rgba(11, 31, 60, 0.8) 100%
    ),
    url(${background}) no-repeat center fixed;
  background-size: cover;
`;

const AllCards = () => {
  const [sport, setSport] = useQueryState("sport", "");
  const [era, setEra] = useQueryState("era", "");
  const [searchQuery, setSearchQuery] = useQueryState("search", "");

  const navigate = useNavigate();

  useEffect(
    () =>
      logAmplitudeEvent(MARKETPLACE_EVENTS.ALL_CARDS.PAGE_VIEWED, {
        Sport: sport,
        Era: era,
      }),
    [era, sport],
  );

  return (
    <>
      <Helmet>
        <title>Browse Cards</title>
      </Helmet>

      <HeaderContainer className="row text-left pt-4">
        <div className="col inner-container-wide mx-auto">
          <p className="small text-white-50 font-weight-bold text-uppercase">
            <a href="/marketplace">Marketplace</a> / cards
          </p>
          <h2 className="font-weight-bold text-white mb-0">Browse cards</h2>
          <p className="text-white mb-3" style={{ opacity: "0.6" }}>
            Explore every card in the StarStock vault
          </p>

          <div className="d-flex">
            <NavBar
              sport={sport}
              era={era}
              setValues={(sport, era) => {
                setEra(era);
                setSport(sport);
                navigate(pathWithSearch());
              }}
            />
          </div>
        </div>
      </HeaderContainer>

      <div className="row">
        <div className="col inner-container-wide mx-auto">
          <SearchInterface
            sport={sport}
            era={era}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            showSearchInput={true}
          />
        </div>
      </div>
    </>
  );
};

export default AllCards;
