import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { financial } from "../../utils/common";

const ItemOwnershipMarketDataDisplay = ({ itemOwnership }) => {
  const { gradedCardParallel } = itemOwnership.item;

  return (
    <>
      {itemOwnership.askPrice &&
      itemOwnership.askPrice === gradedCardParallel.openSellOrderMinPrice ? (
        <p
          className="text-success font-weight-bold mt-2 mb-0"
          style={{ fontSize: "0.75rem" }}
        >
          <FontAwesomeIcon icon={faCheck} className="text-success mr-1" />
          MARKET LOW
        </p>
      ) : gradedCardParallel.openSellOrderMinPrice ? (
        <p className="text-whisper mt-2 mb-0" style={{ fontSize: "0.75rem" }}>
          <span className="text-primary font-weight-bold">
            ${financial(gradedCardParallel.openSellOrderMinPrice)}
          </span>{" "}
          market low
        </p>
      ) : (
        <p className="text-whisper mt-2 mb-0" style={{ fontSize: "0.75rem" }}>
          No market listings
        </p>
      )}
      {gradedCardParallel.lastSalePrice ? (
        <p className="text-whisper mb-0" style={{ fontSize: "0.75rem" }}>
          <span className="text-speak font-weight-bold">
            ${financial(gradedCardParallel.lastSalePrice)}
          </span>{" "}
          latest sale
        </p>
      ) : (
        <p className="text-whisper mb-0" style={{ fontSize: "0.75rem" }}>
          No sales history
        </p>
      )}
    </>
  );
};

export default ItemOwnershipMarketDataDisplay;
