import { useReducer } from "react";
import { Action, State } from "../types";

const INITIAL_STATE: State = {
  errors: [],
  formComplete: false,
  form: {
    addressId: null,
    additionalInstructions: null,
    boxBreakId: null,
    breakFee: 0,
    preferShipHome: false,
    itemOwnershipId: null,
    requestDetails: null,
  },
  result: {
    purchasedSpotsCost: 0,
  },
  step: "LOADING",
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "loading":
      return { ...state, step: "LOADING" };
    case "error": {
      if (action.payload) {
        return { ...state, step: "ERROR", errors: action.payload };
      }

      return { ...state, step: "ERROR", errors: [] };
    }
    case "form":
      return { ...state, step: "FORM" };
    case "submit":
      return { ...state, step: "SUBMIT" };
    case "success":
      return {
        ...state,
        step: "SUCCESS",
        result: { purchasedSpotsCost: action.payload },
      };
    case "update": {
      const form = { ...state.form, ...action.payload };
      const requiredFormFieldValues = { ...form };

      delete requiredFormFieldValues["additionalInstructions"];
      delete requiredFormFieldValues["requestDetails"];

      const formComplete = Object.values(requiredFormFieldValues).every(
        (v) => v !== null && v !== "",
      );

      return {
        ...state,
        form,
        formComplete,
      };
    }
    case "reset":
      return { ...INITIAL_STATE, step: "FORM" };
    default:
      return state;
  }
}

const useRequestForm = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return { state, dispatch };
};

export default useRequestForm;
