import { gql } from "@apollo/client";
import {
  COLLECTION_LISTING_FRAGMENT,
  GRADE_FRAGMENT,
  GRADED_CARD_PARALLEL_FIELDS_FRAGMENT,
} from "../fragments";

const GET_MY_CARD_PARALLELS = gql`
  ${COLLECTION_LISTING_FRAGMENT}
  ${GRADED_CARD_PARALLEL_FIELDS_FRAGMENT}
  ${GRADE_FRAGMENT}
  query GET_MY_CARD_PARALLELS(
    $searchQuery: String!
    $sport: String
    $era: String
    $productType: String
    $myListingsStatus: String
    $grader: String
    $minSerialLimit: Decimal
    $maxSerialLimit: Decimal
    $startYear: Decimal
    $endYear: Decimal
    $cardSet: String
    $orderBy: String!
    $cursor: String
  ) {
    myCardParallels(
      first: 48
      searchQuery: $searchQuery
      sport: $sport
      era: $era
      productType: $productType
      myListingsStatus: $myListingsStatus
      grader: $grader
      minSerialLimit: $minSerialLimit
      maxSerialLimit: $maxSerialLimit
      startYear: $startYear
      endYear: $endYear
      cardSet: $cardSet
      orderBy: $orderBy
      after: $cursor
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      myCardParallelStats {
        totalItemCount
        totalListingCount
        totalValuedItemCount
        totalItemValue
      }
      edges {
        node {
          ...collectionListingFields
          gradedcardparallelSet(first: 3) {
            totalCount
            edges {
              node {
                ...gradedCardParallelFields
                myItemCount
                myOpenSellOrderCount
                myOpenSellOrderMinPrice
                grade {
                  ...gradeFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_MY_CARD_PARALLELS;
