import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import Loader from "../../Loader";
import GET_BOX_BREAK from "../../../api/queries/breaks/GET_BOX_BREAK";

const StepLoading = ({ formData, setFormDataFields, onLoaded, onFailed }) => {
  const { data, loading, error } = useQuery(GET_BOX_BREAK, {
    variables: { id: formData.boxBreakId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (error) {
      onFailed(error);
    } else if (data) {
      setFormDataFields({
        boxBreak: data.boxBreak,
        availableSpots: data.boxBreak.spotSet.edges.map(
          ({ node: spot }) => spot,
        ),
        participant: data.boxBreak.myParticipant,
        preferShipHome:
          data.boxBreak.myParticipant &&
          data.boxBreak.myParticipant.preferShipHome,
        addressId:
          data.boxBreak.myParticipant &&
          data?.boxBreak?.myParticipant?.address?.id
            ? data?.boxBreak?.myParticipant?.address?.id
            : "",
        ...(data.boxBreak.myParticipant && {
          additionalInstructions:
            data.boxBreak.myParticipant.additionalInstructions,
        }),
      });
      onLoaded(data);
    }
  });

  return loading ? <Loader message={"Checking the break…"} /> : <></>;
};

export default StepLoading;
