import { gql } from "@apollo/client";
import {
  CARD_PARALLEL_ID_FRAGMENT,
  GRADE_FRAGMENT,
  PARALLEL_FRAGMENT,
} from "../fragments";

const GET_SALES = gql`
  ${CARD_PARALLEL_ID_FRAGMENT}
  ${GRADE_FRAGMENT}
  ${PARALLEL_FRAGMENT}
  query GET_SALES($sport: String, $era: String, $first: Int = 24) {
    sales(sport: $sport, era: $era, first: $first) {
      cardParallel {
        ...cardParallelIdFields
        frontSidePhotoSmall
        boxProductPhotoSmall
        parallel {
          ...parallelFields
        }
      }
      grade {
        ...gradeFields
      }
      saleDate
      price
      quantity
    }
  }
`;

export default GET_SALES;
