import React from "react";

import { PageSection } from "../PageSection";

import { Container, Description, Heading, Row } from "./styles";

interface Props {
  children: React.ReactNode;
  description?: string;
  heading: string;
}

export const EventDescriptionSection = ({
  children,
  description,
  heading,
}: Props) => {
  // Unsure how to type these properly, displayName exists
  // on child.type for sure here so feel safe to just use any
  const mediaBlock = React.Children.map(children, (child) =>
    (child as any).type.displayName.includes("MediaBlock") ? child : null,
  );

  const descriptionBlock = React.Children.map(children, (child) =>
    (child as any).type.displayName.includes("DescriptionBlock") ? child : null,
  );

  return (
    <PageSection>
      <Row>
        {mediaBlock?.length !== 0 && <Container>{mediaBlock}</Container>}

        <Container>
          <Heading>{heading}</Heading>
          {description && (
            <Description
              disallowedElements={[
                "br",
                "h1",
                "h2",
                "h3",
                "h4",
                "h5",
                "h6",
                "hr",
                "img",
              ]}
              linkTarget="_blank"
            >
              {description}
            </Description>
          )}

          {descriptionBlock}
        </Container>
      </Row>
    </PageSection>
  );
};

const MediaBlock = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);
MediaBlock.displayName = "MediaBlock";
EventDescriptionSection.MediaBlock = MediaBlock;

const DescriptionBlock = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);
DescriptionBlock.displayName = "DescriptionBlock";
EventDescriptionSection.DescriptionBlock = DescriptionBlock;
