import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { WarningAlert } from "../../../../components/WarningAlert";
import { AuctionState } from "../../../../generated/schema";
import { financial } from "../../../../utils/common";

import { InfoPanel } from "../InfoPanel";
import {
  Description,
  FormatContainer,
  FormatDescription,
  FormatHeader,
  FormatName,
  LotLink,
} from "./styles";

interface Props {
  description?: string;
  lotCount?: number | null;
  maxPrice?: number | null | undefined;
  minPrice?: number | null | undefined;
  state: AuctionState;
}

export const AuctionHeaderDetail = ({
  description,
  lotCount,
  maxPrice,
  minPrice,
  state,
}: Props) => (
  <div>
    {state === AuctionState.CANCELED && (
      <WarningAlert status="Danger">
        This auction has been canceled - why not head back to our{" "}
        <Link to={"/breaks"}>breaks page</Link> and find another?
      </WarningAlert>
    )}

    {state === AuctionState.ENDED && (
      <WarningAlert status="Warning">
        This auction has now closed - why not head back to our{" "}
        <Link to={"/breaks"}>breaks page</Link> and find another?
      </WarningAlert>
    )}

    <InfoPanel
      rows={[
        { label: "Lots in auction", value: `${lotCount}` || "Coming soon…" },
        {
          label: "Opening prices",
          value:
            minPrice === maxPrice
              ? `$${financial(minPrice, 0)}`
              : `$${financial(minPrice, 0)} - $${financial(maxPrice, 0)}`,
        },
      ]}
    />

    {description && (
      <Description
        disallowedElements={[
          "br",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "hr",
          "img",
        ]}
        linkTarget="_blank"
      >
        {description}
      </Description>
    )}

    <LotLink href={"#lots"}>
      View the lots <FontAwesomeIcon icon={faChevronDown} className="fa-fw" />
    </LotLink>

    <FormatContainer>
      <FormatHeader>Event Format</FormatHeader>
      <FormatName>Live Auctions</FormatName>
      <FormatDescription>
        Back-to-back flash auctions! Auctions only last a minute or two, meaning
        you&apos;re never far from another shot at scoring a bargain. With low
        opening prices who knows what deals you might land! Bids near the end of
        the period extend the timer to avoid sniping, keeping things fair and
        square!
      </FormatDescription>
    </FormatContainer>
  </div>
);
