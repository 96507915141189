import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const CardListContainer = styled.div`
  align-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1rem;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const IconContainer = styled.div`
  align-items: center;
  background-color: #d0e0f3;
  border-radius: 2.25rem;
  display: flex;
  height: 4.5rem;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 4.5rem;
`;

export const Text = styled.span`
  color: ${Colors.Astronaut};
  font-size: 1.125rem;
  line-height: 2rem;
  text-align: center;
`;
