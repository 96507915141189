import TagManager from "react-gtm-module";

const gtmId = "GTM-W8J86FB";

export function initGTM() {
  TagManager.initialize({
    gtmId: gtmId,
    dataLayer: {
      environment: process.env.REACT_APP_GTM_ENVIRONMENT,
      amplitudeAPIKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
    },
  });
}
