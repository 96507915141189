import { useState } from "react";

import { useMutation } from "@apollo/client";
import moment from "moment";
import { Link } from "react-router-dom";

import { financial } from "../../utils/common";
import { displayNameForGrade } from "../../utils/grades";
import {
  displayNameForProductType,
  productQuantityString,
} from "../../utils/content";
import { logAmplitudeEvent } from "../../utils/analytics";
import { BUYING_EVENTS } from "../../utils/analytics/events";

import BUY_ITEMS from "../../api/mutations/BUY_ITEMS";

import CardParallelGradeContainer from "../CardParallelGradeContainer";
import SummaryDataRow from "../SummaryDataRow";
import BidListGradeItemMenu from "./BidListGradeItemMenu";
import BestOffer, { variants as bestOfferVariants } from "./BestOffer";
import ConfirmationDialog from "./ConfirmationDialog";
import { getValidAffiliateCode } from "../../utils/affiliateTracking";

const SUSPENDED_STATE = "SUSPENDED";

const BidListGradeItem = ({
  groupedBid,
  groupedBidClose,
  acceptListingRefetches,
}) => {
  const {
    id,
    productType,
    openBuyOrderCount,
    suspendedBuyOrderCount,
    createdAt,
    buyorderSet,
    lowestAskPrice,
    lowestAskCount,
  } = groupedBid;
  const { state, buyPrice, cardParallel, grade, expiresAfter } =
    buyorderSet.edges[0].node;

  const buyOrderCount = openBuyOrderCount + suspendedBuyOrderCount;
  const affiliateCode = getValidAffiliateCode();

  const [showAcceptOfferDialog, setShowAcceptOfferDialog] = useState(false);
  const [acceptingOffer, setAcceptingOffer] = useState(false);
  const acceptOffer = () => {
    setShowAcceptOfferDialog(false);
    setAcceptingOffer(true);
    buyItemsMutation({
      variables: {
        cardParallelId: cardParallel.id,
        gradeId: grade.id,
        maximumBuyPrice: lowestAskPrice,
        quantity: 1,
        expiresAfter: "NO_EXPIRY",
        cancelBidsPriced: buyPrice,
        affiliateCode: affiliateCode,
      },
    });
  };

  const [buyItemsMutation] = useMutation(BUY_ITEMS, {
    refetchQueries: acceptListingRefetches,
    onCompleted({ buyItems }) {
      if (buyItems.errors && buyItems.errors.length > 0) {
        logAmplitudeEvent(BUYING_EVENTS.EXISTING_OFFER.BUY_LISTING.FAILED, {
          Error: buyItems.errors[0],
        });
      } else {
        logAmplitudeEvent(
          BUYING_EVENTS.EXISTING_OFFER.BUY_LISTING.LISTING_BOUGHT,
        );
      }
      setAcceptingOffer(false);
    },
  });

  return (
    <>
      <CardParallelGradeContainer
        gradeTitle={displayNameForGrade(grade)}
        subtitle={`Offer made ${moment(createdAt).format("MM/DD/YYYY")}`}
        sidebandColor={"#58B55c"}
        accessoryView={
          groupedBidClose ? (
            <BidListGradeItemMenu
              groupedBidId={id}
              groupedBidClose={groupedBidClose}
              groupedBidQuantity={buyOrderCount}
              expiresAfter={expiresAfter}
              refreshQueries={acceptListingRefetches}
            />
          ) : null
        }
      >
        {state === SUSPENDED_STATE && (
          <div className="alert alert-warning mx-n3 rounded-0">
            <span className="font-weight-bold">Offer suspended:</span> Your
            balance is too low. Please{" "}
            <Link to="/account/deposit" className="font-weight-bold">
              add funds
            </Link>{" "}
            to restore the offer.
          </div>
        )}
        <div className="d-flex flex-row flex-wrap align-items-end justify-content-between mt-n2">
          <div className="mt-2" style={{ flex: "2 1 8rem" }}>
            <SummaryDataRow
              summaryDataItems={[
                {
                  label: "Quantity",
                  value: productQuantityString(
                    buyOrderCount,
                    cardParallel.productType,
                  ),
                  valueColor: "text-shout",
                },
                {
                  label: "Offer expires",
                  value: `${
                    expiresAfter ? moment(expiresAfter).fromNow() : "No expiry"
                  }`,
                  valueColor: "text-shout",
                },
                {
                  label: "Offer price",
                  value: `$${financial(buyPrice)}`,
                  valueColor: "text-success",
                },
              ]}
            />
          </div>

          <div className="mt-2" style={{ flex: "1 0 8rem" }}>
            {!!lowestAskPrice && !!lowestAskCount ? (
              <BestOffer
                variant={bestOfferVariants.listing}
                quantity={lowestAskCount}
                productType={productType}
                price={lowestAskPrice}
                onClick={() => setShowAcceptOfferDialog(true)}
                isAccepting={acceptingOffer}
              />
            ) : (
              <div className="text-whisper text-center border rounded px-3 py-2">
                None for sale
              </div>
            )}
          </div>
        </div>
      </CardParallelGradeContainer>

      <ConfirmationDialog
        show={showAcceptOfferDialog}
        titleText={"Buy lowest listing"}
        bodyText={`Increase 1 offer from $${financial(
          buyPrice,
        )} to $${financial(
          lowestAskPrice,
        )} to buy this ${displayNameForProductType(cardParallel.productType)}?`}
        confirmText={`Buy ${displayNameForProductType(
          cardParallel.productType,
        )} for $${financial(lowestAskPrice)}`}
        onConfirm={acceptOffer}
        onCancel={() => setShowAcceptOfferDialog(false)}
      />
    </>
  );
};

export default BidListGradeItem;
