import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { logAmplitudeEvent } from "../utils/analytics";
import { WALLET_EVENTS } from "../utils/analytics/events";

import { PaymentCardDepositSection } from "../features/payments";
import DepositPrices from "../components/DepositPrices";

const PaymentCardDeposit = () => {
  const [amount, setAmount] = useState(25);

  useEffect(() => {
    logAmplitudeEvent(WALLET_EVENTS.DEPOSIT.PAGE_VIEWED);
  }, []);

  return (
    <>
      <Helmet>
        <title>Deposit</title>
      </Helmet>

      <div className="card mx-auto max-width-26 my-5">
        <div className="card-body text-center">
          <h1 className="card-title h5 font-weight-bold my-3 text-left">
            Add funds
          </h1>
          <p className="text-left mb-4">
            StarStock is offering free deposits as a special launch promotion!
            You can withdraw funds for free at any time.
          </p>

          <DepositPrices amount={amount} setAmount={setAmount} />

          <PaymentCardDepositSection
            amount={amount}
            successPath={"/marketplace"}
            depositSuccessEventName={WALLET_EVENTS.DEPOSIT.SUCCEEDED}
            depositFailureEventName={WALLET_EVENTS.DEPOSIT.FAILED}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentCardDeposit;
