// prettier-ignore
export const ONBOARDING_EVENTS = {
  SIGN_UP: {
    PAGE_VIEWED:            "Onboarding - Sign Up Page Viewed",
    FAILED:                 "Onboarding - Sign Up Failed",
    SUCCEEDED:              "Onboarding - Sign Up Succeeded",
  },
  DEPOSIT: {
    PAGE_VIEWED:            "Onboarding - Deposit Page Viewed",
    FAILED:                 "Onboarding - Deposit Failed",
    SKIPPED:                "Onboarding - Deposit Skipped",
    SUCCEEDED:              "Onboarding - Deposit Succeeded",
  },
}

// prettier-ignore
export const ANNOUNCEMENT_EVENTS = {
  BANNER: {
      VIEWED: "Announcement - Banner Viewed",
      CLICKED: "Announcement - Banner Clicked",
  },
}

// prettier-ignore
export const MARKETPLACE_EVENTS = {
  STOREFRONT: {
    PAGE_VIEWED:            "Marketplace - Storefront Page Viewed",
    WIDGET_ITEM_CLICKED:    "Marketplace - Storefront Widget Item Clicked",
    WIDGET_LINK_CLICKED:    "Marketplace - Storefront Widget Link Clicked",
  },
  SEARCH: {
    PAGE_VIEWED:            "Marketplace - Search Page Viewed",
  },
  ALL_CARDS: {
    PAGE_VIEWED:            "Marketplace - All Cards Page Viewed",
  },
  CARD: {
    PAGE_VIEWED:            "Marketplace - Card Page Viewed",
  },
  PLAYER: {
    PAGE_VIEWED:            "Marketplace - Player Page Viewed",
  },
  MARKET_INDEXES: {
    PAGE_VIEWED:            "Marketplace - Market Indexes Page Viewed",
  },
  MARKET_INDEX: {
    PAGE_VIEWED:            "Marketplace - Market Index Page Viewed",
  },
};

// prettier-ignore
export const BREAKS_EVENTS = {
  STOREFRONT: {
    PAGE_VIEWED: "Breaks - Storefront Page Viewed",
    WIDGET_ITEM_CLICKED: "Breaks - Storefront Widget Item Clicked",
    WIDGET_LINK_CLICKED: "Breaks - Storefront Widget Link Clicked",
  },
  SEARCH: {
    PAGE_VIEWED: "Breaks - Search Page Viewed",
  },
  ALL_BREAKS: {
    PAGE_VIEWED: "Breaks - All Breaks Page Viewed",
  },
  BREAK: {
    PAGE_VIEWED: "Breaks - Break Page Viewed",
  },
  PERSONALS_REQUEST: {
    FORM_VIEWED: "Breaks - Personal Break Request Form Viewed",
    FAILED: "Breaks - Personal Break Request Failed",
    SUCCEEDED: "Breaks - Personal Break Request Succeeded",
  },
  BUY_SPOTS: {
    FORM_VIEWED: "Breaks - Buy Spots Form Viewed",
    FAILED: "Breaks - Buy Spots Failed",
    SUCCEEDED: "Breaks - Buy Spots Succeeded",
  },
  FAQ: {
    PAGE_VIEWED: "Breaks - FAQ Page Viewed",
  },
  AUCTION: {
    BID_FAILED: "Auctions - Bid Failed",
    BID_SUCCEEDED: "Auctions - Bid Succeeded",
    FORM_VIEWED: "Auctions - Submit Items For Auction Form Viewed",
    LOT_VIEWED: "Auctions - Lot Viewed",
    PAGE_VIEWED: "Auctions - Auction Viewed",
    QUICK_SET_BID_PRESSED: "Auctions - Quick Set Bid Pressed",
  },
}

// prettier-ignore
export const COLLECTION_EVENTS = {
  ALL_CARDS: {
    PAGE_VIEWED:            "Collection - All Cards Page Viewed",
  },
  EXPORT_DATA: {
    FORM_VIEWED:            "Collection - Export Data Form Viewed",
    CSV_REQUESTED:          "Collection - Export Data CSV Requested",
  },
};

// prettier-ignore
export const USER_COLLECTION_EVENTS = {
  ALL_CARDS: {
    PAGE_VIEWED:            "User Collection - All Cards Page Viewed",
    SEARCH_UPDATED:         "User Collection - Cards Search Updated",
  },
  SETTINGS: {
    UPDATED:                "User Collection - Settings Updated",
  },
};

// prettier-ignore
export const WATCHLIST_EVENTS = {
  CARDS: {
    PAGE_VIEWED:            "Watchlist - Cards Page Viewed",
    SEARCH_UPDATED:         "Watchlist - Cards Search Updated",
    CARD_ADDED:             "Watchlist - Card Added",
    CARD_REMOVED:           "Watchlist - Card Removed",
  },
  PLAYERS: {
    PAGE_VIEWED:            "Watchlist - Players Page Viewed",
  },
}

// prettier-ignore
export const ACTIVITY_EVENTS = {
  OFFERS: {
    PAGE_VIEWED:            "Activity - Offers Page Viewed",
    SEARCH_UPDATED:         "Activity - Offers Search Updated",
  },
  LISTINGS: {
    PAGE_VIEWED:            "Activity - Listings Page Viewed",
    SEARCH_UPDATED:         "Activity - Listings Search Updated",
  },
  PURCHASES: {
    PAGE_VIEWED:            "Activity - Purchases Page Viewed",
  },
  SALES: {
    PAGE_VIEWED:            "Activity - Sales Page Viewed",
  },
};

// prettier-ignore
export const BUYING_EVENTS = {
  NEW_BUY_ORDER: {
    FORM_VIEWED:            "Buying - New Buy Order Form Viewed",
    FAILED:                 "Buying - New Buy Order Failed",
    CREATED:                "Buying - New Buy Order Created",
  },
  EXISTING_OFFER: {
    EXTEND_EXPIRY: {
      FORM_VIEWED:          "Buying - Existing Offer Extend Expiry Form Viewed",
      FORM_SUBMITED:        "Buying - Existing Offer Extend Expiry Form Submitted",
      FAILED:               "Buying - Existing Offer Extend Expiry Failed",
      SUCCESS:              "Buying - Existing Offer Extend Expiry Success",
    },
    CANCEL: {
      FAILED:               "Buying - Existing Offer Cancel Failed",
      CANCELED:             "Buying - Existing Offer Canceled",
    },
    BUY_LISTING: {
      FAILED:               "Buying - Existing Offer Buy Listing Failed",
      LISTING_BOUGHT:       "Buying - Existing Offer Listing Bought",
    },
  },
}

// prettier-ignore
export const SELLING_EVENTS = {
  NEW_SELL_ORDER: {
    FORM_VIEWED:            "Selling - New Sell Order Form Viewed",
    FORM_CONFIRM_VIEWED:    "Selling - New Sell Order Form Confirm Viewed",
    FAILED:                 "Selling - New Sell Order Failed",
    CREATED:                "Selling - New Sell Order Created",
  },
  EXISTING_LISTING: {
    EXTEND_EXPIRY: {
      FORM_VIEWED:          "Selling - Existing Listing Extend Expiry Form Viewed",
      FORM_SUBMITED:
                            "Selling - Existing Listing Extend Expiry Form Submitted",
      FAILED:               "Selling - Existing Listing Extend Expiry Failed",
      SUCCESS:              "Selling - Existing Listing Extend Expiry Success",
    },
    CANCEL: {
      FAILED:               "Selling - Existing Listing Cancel Failed",
      CANCELED:             "Selling - Existing Listing Canceled",
    },
    ACCEPT_OFFER: {
      FAILED:               "Selling - Existing Listing Accept Offer Failed",
      OFFER_ACCEPTED:       "Selling - Existing Listing Offer Accepted",
    },
  },
}

// prettier-ignore
export const ACCOUNT_EVENTS = {
  SIGN_IN: {
    FORM_VIEWED:            "Account - Sign In Form Viewed",
    FAILED:                 "Account - Sign In Failed",
    SUCCEEDED:              "Account - Signed In",
  },
  SIGN_OUT: {
    FAILED:                 "Account - Sign Out Failed",
    SUCCEEDED:              "Account - Signed Out",
  },
  PASSWORD_RESET: {
    REQUEST: {
      FORM_VIEWED:          "Account - Password Reset Request Form Viewed",
      SUBMITTED:            "Account - Password Reset Request Submitted",
    },
    SET_PASSWORD: {
      FORM_VIEWED:          "Account - Password Reset Set Password Form Viewed",
      FAILED:               "Account - Password Reset Failed",
      SUCCEEDED:            "Account - Password Reset",
    },
  },
  NOTIFICATION_PREFERENCES: {
    PAGE_VIEWED:            "Account - Notification Preferences Page Viewed",
    UPDATED:                "Account - Notification Preferences Updated",
  },
}

// prettier-ignore
export const WALLET_EVENTS = {
  DEPOSIT: {
    PAGE_VIEWED:            "Wallet - Deposit Page Viewed",
    FAILED:                 "Wallet - Deposit Failed",
    SUCCEEDED:              "Wallet - Deposit Succeeded",
  },
  WITHDRAWAL: {
    EXTERNAL_FORM_VIEWED:   "Wallet - Withdrawal External Form Viewed",
    FORM_VIEWED:            "Wallet - Withdrawal Form Viewed",
  },
};

// prettier-ignore
export const CRYPTO_WALLET_EVENTS = {
  CONNECT: {
    FAILED:                 "Crypto Wallet - Account Connect Failed",
    SUCCEEDED:              "Crypto Wallet - Account Connect Succeeded",
  },
  DISCONNECT: {
    FAILED:                 "Crypto Wallet - Account Disconnect Failed",
    SUCCEEDED:              "Crypto Wallet - Account Disconnect Succeeded",
  },
};

// prettier-ignore
export const MANAGE_COLLECTION_EVENTS = {
  SEND_IN: {
    EXTERNAL_FORM_VIEWED:   "Manage Collection - Send In External Form Viewed",
  },
  SHIP_HOME: {
    PAGE_VIEWED:            "Manage Collection - Ship Home Page Viewed",
    ITEM_ADDED:             "Manage Collection - Item Added",
    ITEM_REMOVED:           "Manage Collection - Item Removed",
    ALL_ITEMS_ADDED:        "Manage Collection - All Items Added",
    ALL_ITEMS_REMOVED:      "Manage Collection - All Items Removed",
    SUBMIT_REQUEST: {
      SUMMARY_VIEWED:       "Manage Collection - Ship Home Summary Viewed",
      FORM_VIEWED:          "Manage Collection - Ship Home Submit Form Viewed",
      FAILED:               "Manage Collection - Ship Home Submit Failed",
      SUCCEEDED:            "Manage Collection - Ship Home Submit Succeeded",
    },
  },
  SUBMIT_FOR_GRADING: {
    PAGE_VIEWED:            "Manage Collection - Submit For Grading Page Viewed",
    EXTERNAL_FORM_VIEWED:   "Manage Collection - Submit For Grading External Form Viewed",
  },
};

// prettier-ignore
export const SUPPORT_EVENTS = {
  FAQ: {
    PAGE_VIEWED:            "Support - FAQ Page Viewed",
  },
  ABOUT_US: {
    PAGE_VIEWED:            "Support - About Us Page Viewed",
  },
  SUPPORT: {
    PAGE_VIEWED:            "Support - Support Page Viewed",
  },
};

// prettier-ignore
export const CONTENT_EVENTS = {
  INVITATIONAL_CHAMPIONSHIP: {
    PAGE_VIEWED:            "Content - Invitational Championship Page Viewed",
  },
};
